import {api} from '../../helpers';

function updateApp(id, params) {
  return api.put(`/apps/${id}`, params).then(res => res.data);
}
function createApp(data) {
  return api.post(`/apps`, data).then(res => res.data);
}

export {updateApp, createApp};
