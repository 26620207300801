import React from 'react';

export default function Radio({
  label,
  value,
  name,
  handleChecked,
  checked,
  otherText = '',
}) {
  return (
    <div className="px-4 " onChange={handleChecked}>
      <label
        htmlFor={value}
        className="relative  border-b border-b-white80 py-2 flex flex-row items-center gap-2 font-normal transition ease-in-out duration-500 cursor-pointer hover:bg-white80 "
      >
        <input
          type="radio"
          value={value}
          id={value}
          name={name}
          className="w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue"
          checked={checked}
          onChange={handleChecked}
        />
        <div className="w-full flex justify-between">
          <span className="text-sm text-grey cursor-pointer">{label}</span>
          {otherText && process.env.REACT_APP_APP_ENV_MODE !== 'production' && (
            <p className="text-xs mr-[80px] leading-[18px] text-grey20">
              {otherText}
            </p>
          )}
        </div>
      </label>
    </div>
  );
}
