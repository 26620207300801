import React, {useState} from 'react';
import Layout from '../Layout';
import {Accordion, EmptyState} from '../../../components';
import {
  corruptedFile,
  downloadGrey,
  enlargeIc,
  nigeriaIc,
  placeholderLiveness,
} from '../../../assets/images/images';
import {useLocation, useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {fetchBusinessID} from '../../../requests/queries/business360';

const ID_DATA = [
  {
    field_name: 'company name',
    value: 'Adekunle Inc.',
  },
  {
    field_name: 'ID Number',
    value: '9201990328992',
  },
  {
    field_name: 'date of registration',
    value: '12, August 1990',
  },
  {
    field_name: 'business email',
    value: 'Adebayoalaoakala@gail.com',
  },
  {
    field_name: 'state of registration',
    value: 'Oyo state',
  },
  {
    field_name: 'business address',
    value: 'No 22, Ajibode road, UI road, Agbowo, Ibadan',
  },
];

const ImageComponent = ({imageUrl}) => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const toggleImageSize = () => {
    setIsEnlarged(prevState => !prevState);
  };

  const {slug} = useParams();
  const {state} = useLocation();

  const {data: businessEntities, isLoading: entityLoading} = useQuery(
    ['business-id', {slug, id: state?.businessID}],
    () => fetchBusinessID(slug, state?.businessID),
  );

  return (
    <div className="relative w-full">
      <img
        src={imageUrl}
        alt=""
        className={`mx-auto w-full ${
          isEnlarged ? 'h-full' : 'max-h-[192px]'
        } object-cover rounded-lg`}
      />
      {imageUrl && (
        <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex items-center justify-center gap-2">
          <div className="flex gap-2">
            <button
              className="flex items-start gap-2 p-2 text-xs font-medium rounded text-grey bg-white80"
              onClick={toggleImageSize}
            >
              <span className="-mb-[2px]">
                {isEnlarged ? 'Shrink image' : 'Enlarge image'}
              </span>
              <img src={enlargeIc} alt="" className="-mt-[2px]" />
            </button>
          </div>
          <div className="flex gap-2">
            <a
              href={imageUrl}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-start gap-2 p-2 text-xs font-medium rounded text-grey bg-white80"
            >
              <span className="-mb-[2px]">Download image</span>
              <img src={downloadGrey} alt="" className="-mt-[2px]" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default function BusinessId() {
  const isEmpty = false;
  return (
    <Layout>
      {isEmpty ? (
        <div className="bg-white80 w-full">
          <EmptyState
            body="There is no information to display here at the moment"
            src={corruptedFile}
            height={45}
            imageHeight={48}
            imageWidth={48}
            bodyMaxWidth="max-w-[179px]"
            buttonText="Validate document"
          />
        </div>
      ) : (
        <div className="space-y-4">
          <Accordion
            title="CAC"
            border
            customClasses="rounded-xl"
            customChildrenClasses="mt-2 border-t border-grey-50"
            customPadding="px-6 py-5"
            customComponent={
              <div className="flex items-center gap-2 rounded-full px-2 py-1 outline outline-1 outline-grey-50 bg-white80">
                <p className="text-deepBlue text-xs font-medium uppercase -mb-1">
                  Nigeria
                </p>
                <div className="w-5 h-5 flex items-center justify-center bg-blue-50 outline outline-1 outline-blue-100 rounded-full">
                  <img src={nigeriaIc} alt="" width={12} height={8} />
                </div>
              </div>
            }
            noChildPadding
            customTitleSize="text-lg"
            customTitleColor="text-grey"
          >
            <div className="pl-8 pr-10 pt-6 pb-6">
              <div className="ml-auto w-fit flex items-center gap-2 rounded-full px-2 py-1 bg-grey60 text-grey text-xs font-medium uppercase">
                <span className="-mb-[2px]">95% image match</span>
              </div>
              <div className="mt-6 flex items-center gap-14">
                <ImageComponent imageUrl={placeholderLiveness} />
              </div>

              <div className="mt-16 w-full inline-grid grid-cols-[auto_auto_auto] items-center gap-10 justify-between">
                {ID_DATA.map((item, index) => (
                  <div key={index} className="flex flex-col gap-2">
                    <p className="text-grey-700 text-xs font-medium uppercase">
                      {item.field_name}
                    </p>
                    <p className="text-grey text-sm font-medium">
                      {item.value}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </Accordion>
        </div>
      )}
    </Layout>
  );
}
