import React, {useEffect} from 'react';
import {
  abandoned,
  failed,
  inProgress,
  noSearchResults,
  successful,
  toolTip,
} from '../../assets/images/images';
import {mapToOptionsArray, parseUrlParams} from '../../helpers';
import {shortenLargeNumbers} from '../../helpers/shortenLargeNumbers';
import {SkeletonLoader} from '../../components';

const defaultFilterOptions = [
  {
    title: 'Apps',
    name: 'app_ids',
    values: [],
  },
  {
    title: 'Status',
    name: 'status',
    values: [],
  },
  {
    title: 'Verification type',
    name: 'type',
    values: [],
  },
  {
    title: 'Verification mode',
    name: 'mode',
    values: [],
  },
];

function useClearSearchOnBackButton(clearSearch) {
  useEffect(() => {
    const handleBackButton = () => {
      clearSearch();
    };
    window.addEventListener('popstate', handleBackButton);
    return () => window.removeEventListener('popstate', handleBackButton);
  }, [clearSearch]);
}

function NoSearchResults() {
  return (
    <div className="flex flex-col gap-4 items-center justify-center mt-[13%]">
      <img
        src={noSearchResults}
        alt="no search results"
        width={145}
        height={116}
      />
      <p className="text-body">No result found</p>
    </div>
  );
}

const getFilterOptions = userApps => {
  if (!userApps) {
    return defaultFilterOptions;
  }

  return [
    {
      title: 'Apps',
      name: 'app_ids',
      values: mapToOptionsArray(userApps, 'value'),
    },
    {
      title: 'Status',
      name: 'status',
      values: [
        {label: 'Successful', value: 'Completed', name: 'Completed'},
        {label: 'Failed', value: 'Failed', name: 'Failed'},
        {label: 'Pending', value: 'Pending', name: 'Pending'},
        {label: 'Ongoing', value: 'Ongoing', name: 'Ongoing'},
        {label: 'Started', value: 'Started', name: 'Started'},
        {label: 'Abandoned', value: 'Abandoned', name: 'Abandoned'},
      ],
    },
    {
      title: 'Verification type',
      name: 'type',
      values: [
        {label: 'BVN', value: 'BVN', name: 'BVN'},
        {label: 'NIN', value: 'NIN', name: 'NIN'},
        {label: 'Mobile', value: 'MOBILE', name: 'MOBILE'},
        {label: "Driver's License", value: 'DL', name: 'DL'},
        {label: 'Passport ID', value: 'PASSPORT_ID', name: 'PASSPORT_ID'},
        {label: "Driver's License ID", value: 'DL_ID', name: 'DL_ID'},
      ],
    },
    {
      title: 'Verification mode',
      name: 'mode',
      values: [
        {label: 'Liveness', value: 'LIVENESS', name: 'Liveness'},
        {label: 'OTP', value: 'OTP', name: 'OTP'},
      ],
    },
  ];
};

const handleSetIds = verification => {
  localStorage.setItem('referenceId', verification?.verification_id);
  localStorage.setItem('verificationId', verification?.verification_id);
  localStorage.setItem('verificationId', verification?.verification_id);
  localStorage.setItem('appId', verification?.app_id);
  localStorage.setItem('sessionId', verification?.session_id);
  localStorage.setItem(
    'refId',
    (verification?.verification_id && verification?.verification_id) || '-',
  );
};
const getParams = ({
  limit,
  setCurrentPage,
  currentPage,
  appIds,
  setSearchText,
  setFiltersApplied,
}) => {
  const apiParams = parseUrlParams();
  apiParams.limit = limit;
  apiParams.status = apiParams.status
    ?.split(',')
    ?.map(s => s?.charAt(0)?.toUpperCase() + s?.slice(1))
    ?.toString();

  const searchParams = new URLSearchParams(document.location.search);
  const pageParam = searchParams.get('page');
  if (pageParam) {
    setCurrentPage(parseInt(pageParam));
    apiParams.page = parseInt(pageParam);
  } else {
    apiParams.page = currentPage;
  }

  const appIdsFromUrl = searchParams.get('app_ids');
  if (appIdsFromUrl) {
    apiParams.appIds = appIdsFromUrl;
  } else {
    apiParams.appIds = appIds;
  }

  const searchTermFromUrl = searchParams.get('search');
  if (searchTermFromUrl) {
    setSearchText(searchTermFromUrl);
    apiParams.term = searchTermFromUrl;
  } else {
    apiParams.term = '';
  }
  if (
    Object.keys(apiParams).length === 2 &&
    'limit' in apiParams &&
    'page' in apiParams
  ) {
    setFiltersApplied(false);
  } else {
    setFiltersApplied(true);
  }
  return apiParams;
};
const getAnalysisPercentages = stats => [
  {
    color: '#87D4DB',
    label: 'successful',
    percentage: `${stats?.Completed || 0}%`,
  },
  {color: '#F15D5F', label: 'failed', percentage: `${stats?.Failed || 0}%`},
  {
    color: '#2A5392',
    label: 'abandoned',
    percentage: `${stats?.Abandoned || 0}%`,
  },
  {
    color: '#73739B',
    label: 'In progress',
    percentage: `${stats?.Pending + stats?.Ongoing || 0}%`,
  },
];

const getFlows = (card = {}) => [
  {
    icon: successful,
    alt: 'successful',
    title: 'Successful',
    value: shortenLargeNumbers(card?.Completed || 0, null, {
      startAt: 'M',
      decimals: 0,
    }),
  },
  {
    icon: failed,
    alt: 'failed',
    title: 'Failed',
    value: shortenLargeNumbers(card?.Failed || 0, null, {
      startAt: 'M',
      decimals: 0,
    }),
  },
  {
    icon: inProgress,
    alt: 'in-progress',
    title: 'In Progress',
    value: shortenLargeNumbers(card?.Pending + card?.Ongoing || 0, null, {
      startAt: 'M',
      decimals: 0,
    }),
  },
  {
    icon: abandoned,
    alt: 'abandoned',
    title: 'Abandoned',
    value: shortenLargeNumbers(card?.Abandoned || 0, null, {
      startAt: 'M',
      decimals: 0,
    }),
  },
];

const renderFlowCards = ({cards, isLoading: overviewLoading}) => {
  const flows = getFlows(cards);
  return flows.map(({icon, alt, title, value}) => (
    <div
      key={title}
      className="bg-white relative lg:py-6 lg:pl-6 lg:pr-2 p-3 rounded-lg flex items-center gap-2 xl:min-w-[270px] w-full"
    >
      <img src={icon} alt={alt} />
      <div className="flex flex-col gap-1">
        <p className="text-small text-body">{title}</p>
        {overviewLoading || !value ? (
          <SkeletonLoader style={{width: 150, height: 25, marginBottom: 10}} />
        ) : (
          <h1 className="text-base lg:text-md font-semibold text-grey">
            {value}
          </h1>
        )}
      </div>
      {title === 'In Progress' && (
        <div className="absolute bottom-[14px] right-[24px] group">
          <img src={toolTip} alt="tooltip" className="cursor-pointer" />
          <div className="hidden group-hover:block w-[229px] absolute bottom-full text-center top-[25px] right-0 bg-[#F9F9F9] text-[#5C688E] text-[12px] rounded p-2">
            This is a combination of Pending and Ongoing Verifications
          </div>
        </div>
      )}
    </div>
  ));
};
const renderStatusDistribution = ({stats = {}}) => {
  const analysisPercentages = getAnalysisPercentages(stats);
  return analysisPercentages.map(({color, label, percentage}) => (
    <div key={label} className="flex items-center justify-between w-full">
      <div className="flex items-center gap-3">
        <div
          style={{backgroundColor: color}}
          className="w-3 h-3 rounded-[3px]"
        />{' '}
        <p className="text-body text-xs font-medium uppercase">{label}</p>
      </div>
      <p className="text-base text-grey font-semibold">{percentage}</p>
    </div>
  ));
};

const processData = data =>
  data?.map(item => ({
    date: item?.verification_date,
    Completed: item.Completed,
    Failed: item.Failed,
    Pending: item.Pending,
    Abandoned: item.Abandoned,
  }));
const processBAASData = data =>
  data?.map(item => ({
    date: item?.transaction_date,
    inFlow: item?.deposit,
    outFlow: item?.transfer,
  }));
function getAlphabet(number) {
  if (number < 1 || number > 26 || !Number.isInteger(number)) {
    return number;
  }

  // Convert the number to the corresponding alphabet
  // 'A' has ASCII code 65, so we add (number - 1) to get the desired letter
  return String.fromCharCode(64 + number);
}

export {
  processData,
  processBAASData,
  getFlows,
  defaultFilterOptions,
  useClearSearchOnBackButton,
  NoSearchResults,
  getFilterOptions,
  handleSetIds,
  getParams,
  renderFlowCards,
  renderStatusDistribution,
  getAlphabet,
};
