import store from '../store';

export const getPageCost = (screen, pricing = {}) => {
  const {page, config} = screen;
  let pricingSchema = pricing;
  pricingSchema = {
    id: {
      custom: pricing?.id?.services.base,
      dl: pricing?.id?.services.base,
      voter: pricing?.id?.services.base,
      passport: pricing?.id?.services.base,
      permit: pricing?.id?.services.base,
      national: pricing?.id?.services.base,
    },
    'government-data': {
      nin: pricing && pricing['government-data']?.services['ng_nin'],
      vnin: pricing && pricing['government-data']?.services['ng_vnin'],
      bvn: pricing && pricing['government-data']?.services['ng_bvn'],
      dl: pricing && pricing['government-data']?.services['ng_dl'],
      'ke-kra': pricing && pricing['government-data']?.services['ke_kra'],
      'ke-id': pricing && pricing['government-data']?.services['ke_id'],
      'ke-passport':
        pricing && pricing['government-data']?.services['ke-passport'],
      'ke-dl': pricing && pricing['government-data']?.services['ke_dl'],
      'gh-dl': pricing && pricing['government-data']?.services['gh_dl'],
      'gh-voter': pricing && pricing['government-data']?.services['gh_voter'],
      selfie: pricing && pricing['government-data']?.services['liveness'],
      otp: pricing && pricing['government-data']?.services['otp'],
      bvnAdvance:
        pricing && pricing['government-data']?.services['ng_bvn_advance'],
      'ao-nin': pricing && pricing['government-data']?.services['ao_nin'],
      'za-id': pricing && pricing['government-data']?.services['za_id'],
      emailOtp: pricing && pricing['government-data']?.services['email_otp'],
      whatsappOtp: pricing && pricing['government-data']?.services?.whatsappOtp,
    },
    'business-data': {
      cac: pricing && pricing['business-data']?.services?.cac,
    },
    'business-id': {
      base: pricing && pricing['business-id']?.services?.base,
    },
    'phone-number': {
      verification: pricing && pricing['phone-number']?.services?.verification,
      whatsappVerification:
        pricing && pricing['phone-number']?.services?.whatsappVerification,
    },
    email: {
      verification: pricing && pricing['email']?.services?.verification,
    },
    address: {
      verification: pricing && pricing['address']?.services?.verification,
    },
    liveness: {
      selfie: pricing && pricing['selfie']?.services?.selfie,
      video: pricing && pricing['selfie']?.services?.video,
    },
    aml_screening: {
      aml: pricing && pricing['aml']?.services?.aml,
    },
  };

  let min;
  let max;
  let data;
  let verification;

  switch (page) {
    case 'selfie':
      min =
        config?.version === 2
          ? pricingSchema['liveness']['video']
          : pricingSchema['liveness']['selfie'];
      max = min;

      break;
    case 'business-id':
      min = pricingSchema['business-id']['base'];
      max = min;
      break;
    case 'government-data':
      if (config?.version === 2) {
        pricingSchema['government-data'].selfie =
          pricingSchema['government-data']['liveness_video'];
      }
      data = Math.min(
        ...Object.keys(config || {})
          .filter(i => !['selfie', 'otp'].includes(i))
          .filter(j => config[j])
          .filter(k => pricingSchema[page]?.[k])
          .map(l => pricingSchema[page][l]),
      );
      data = isFinite(data) ? data : 0;

      verification = Math.min(
        ...Object.keys(config || {})
          .filter(i => ['selfie', 'otp'].includes(i))
          .filter(j => config[j])
          .filter(k => pricingSchema[page]?.[k])
          .map(l => pricingSchema[page][l]),
      );
      verification = isFinite(verification) ? verification : 0;

      min = data + verification;

      data = Math.max(
        ...Object.keys(config || {})
          .filter(i => !['selfie', 'otp'].includes(i))
          .filter(j => config[j])
          .filter(k => pricingSchema[page]?.[k])
          .map(l => pricingSchema[page][l]),
      );
      data = isFinite(data) ? data : 0;

      verification = Math.max(
        ...Object.keys(config || {})
          .filter(i => ['selfie', 'otp'].includes(i))
          .filter(j => config[j])
          .filter(k => pricingSchema[page]?.[k])
          .map(l => pricingSchema[page][l]),
      );
      verification = isFinite(verification) ? verification : 0;

      max = data + verification;
      break;
    default:
      min = config
        ? Math.min(
            ...Object.keys(config || {})
              .filter(i => config[i])
              .filter(j => pricingSchema[page]?.[j])
              .map(k => pricingSchema[page][k]),
          )
        : 0;
      min = isFinite(min) ? min : 0;

      max = config
        ? Math.max(
            ...Object.keys(config || {})
              .filter(i => config[i])
              .filter(j => pricingSchema[page]?.[j])
              .map(k => pricingSchema[page][k]),
          )
        : 0;
      max = isFinite(max) ? max : 0;
      break;
  }
  return {min, max, pricingSchema};
};

export function calculateWidgetCost() {
  const {widgets} = store.getState();
  const pricingData = widgets?.widgetPricing;
  const VERIFICATION_PAGES =
    (widgets?.widgetPages && widgets?.widgetPages) || [];
  const FRAUD_PAGES = (widgets?.fraudRules && widgets?.fraudRules) || [];
  const isAmlActive = FRAUD_PAGES.find(p => p.page === 'aml_screening');
  const WIDGET_PAGES = [
    ...VERIFICATION_PAGES,
    ...(isAmlActive ? [isAmlActive] : []),
  ];
  return (
    WIDGET_PAGES &&
    WIDGET_PAGES?.map(p => getPageCost(p, pricingData)).reduce(
      (acc, curr) => ({
        min: acc.min + curr.min,
        max: acc.max + curr.max,
      }),
      {
        min: 0,
        max: 0,
      },
    )
  );
}
