import React, {useContext, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {auditLogsIc, eye} from '../../../assets/images/images';
import {
  EmptyState,
  Filter,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../../components';
import SettingsLayout from '../SettingsLayout';
import AuditLogsModal from './AuditLogsModal';
import * as Actions from '../../../store/actions';
import {
  filterOutEmptyValues,
  generateQueryParams,
  parseUrlParams,
} from '../../../helpers';
import {PageContext} from '../../../helpers/context';

const defaultFilterOptions = [
  {
    title: 'Member',
    name: 'user',
    values: [],
  },
];

function SettingsAuditLogs({
  auth: {auditTrail, loading, userDetails},
  apps: {mAppLoading},
  getAuditTrails,
  exportAuditLogs,
  getTeamMembers,
  team: {members},
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [auditData, setAuditData] = useState({});
  const [currentPage, setCurrentPage] = useState('1');
  const [limit, setLimit] = useState(10);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('auditlogs');
  }, [setPageName]);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    getTeamMembers(`status=active`);
  }, [getTeamMembers]);

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(parseInt(pageParam));
      apiParams.page = parseInt(pageParam);
    } else {
      apiParams.page = currentPage;
    }

    if (
      Object.keys(apiParams).length === 2 &&
      'page' in apiParams &&
      'limit' in apiParams
    ) {
      setFiltersApplied(false);
    } else {
      setFiltersApplied(true);
    }

    getAuditTrails(apiParams);
  }, [currentPage, getAuditTrails, limit]);

  const {viewAndDownloadAudit: canDownloadAudit} =
    useMemo(
      () => userDetails?.permissions?.settingsPermissions,
      [userDetails],
    ) || {};

  const filterOptions = useMemo(() => {
    if (!members) {
      return defaultFilterOptions;
    }

    const formattedTeam =
      members &&
      members?.team?.map(member => {
        const {first_name, last_name, email} = member?.user || {};

        // Display the email if either first name or last name is unavailable
        const label =
          first_name && last_name ? `${first_name} ${last_name}` : email;

        return {
          label,
          value: member?.user?._id,
          name: 'user',
        };
      });

    return [
      {
        title: 'Member',
        name: 'user',
        values: formattedTeam,
      },
    ];
  }, [members]);

  const handleFilter = selectedOptions => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);

    const {user} = filteredOptions;

    const filteredUserId = user && user?.map(u => u);
    setSelectedUserId(filteredUserId);

    setCurrentPage(1);
    navigate(`?${queryParams}&page=1`);

    getAuditTrails({
      page: currentPage,
      limit,
      ...filteredOptions,
    });
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);
    getAuditTrails({limit, page: 1});
    setOpenFilter(true);
  };

  return (
    <SettingsLayout
      pageTitle="Audit Logs"
      pageIcon={auditLogsIc}
      action={
        <PrimaryButton
          disabled={mAppLoading || !canDownloadAudit}
          buttonText="Download"
          className="hidden sm:flex"
          onClick={() => {
            if (selectedUserId) {
              exportAuditLogs(selectedUserId);
            } else {
              exportAuditLogs();
            }
          }}
          loading={mAppLoading}
        />
      }
    >
      <AuditLogsModal selectedLog={auditData} open={open} setOpen={setOpen} />
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
      />

      {loading ? (
        <Loader />
      ) : !auditTrail?.records?.length && !filtersApplied ? (
        <EmptyState noBtn height={55} body="No logs yet" />
      ) : !auditTrail?.records?.length && filtersApplied ? (
        <EmptyState
          body="This filter did not return any data, try using different values."
          noBtn
          height={55}
          customBtn={
            <button
              onClick={handleResetFilter}
              className="text-brandBlue p-4 text-sm font-medium"
            >
              Update preferences
            </button>
          }
        />
      ) : (
        <section className="mt-6 opacity-1">
          <div className="flex flex-wrap items-center justify-between gap-4 mb-6 sm:flex-nowrap sm:gap-0">
            <p className="text-sm font-medium text-grey">Activity history</p>

            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
            />
          </div>

          <TableLayout
            negativeMargins
            negativeRightMargin="-mr-4 xl:-mr-[76px]"
          >
            <thead className="text-xs font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-4 text-xs font-medium text-left sm:pl-6 xl:pl-12 text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>member</span>
                  </div>
                </th>
                <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>activity</span>
                  </div>
                </th>
                <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>date</span>
                  </div>
                </th>
                <th className="w-[17.6%]"></th>
              </tr>
            </thead>
            <tbody className="text-sm bg-white">
              {auditTrail &&
                auditTrail?.records?.map((trail, i) => (
                  <tr
                    className="border-b cursor-pointer border-grey60"
                    key={i}
                    onClick={() => {
                      setOpen(!open);
                      setAuditData(trail);
                    }}
                  >
                    <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                      {trail?.actor_name}
                    </td>
                    <td className="p-4 pl-0 text-sm text-body whitespace-nowrap capitalize">
                      {trail?.action
                        .replace(/user\./g, '')
                        .replace(/\./g, ' ')
                        .replace(/([a-z])([A-Z])/g, '$1 $2')}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {moment(trail?.createdAt).format(
                        'Do MMM, YYYY, hh:mmA',
                      ) || '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <button title="View" className="h-4 aspect-square">
                        <img src={eye} alt="" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableLayout>

          {!loading && auditTrail?.records?.length !== 0 && (
            <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
              <div className="flex items-center text-body text-tiny">
                <PageLimit
                  onLimitChange={setLimit}
                  // className="mt-3 mt-sm-0"
                  total={auditTrail && auditTrail?.totalRecords}
                  length={auditTrail?.records?.length}
                  limit={limit}
                />
              </div>

              <div className="mb-8 sm:mb-0">
                <Pagination
                  total={
                    auditTrail && Math.ceil(auditTrail?.totalRecords / limit)
                  }
                  current={+currentPage}
                  onPageChange={activePage => {
                    pageClick(activePage);
                    const searchParams = new URLSearchParams(
                      document.location.search,
                    );
                    searchParams.set('page', activePage);
                    const newSearchParams = searchParams.toString();
                    navigate(`?${newSearchParams}`);
                  }}
                />
              </div>
            </div>
          )}

          <PrimaryButton
            buttonText="Download"
            className="block w-full mt-6 sm:hidden"
          />
        </section>
      )}
    </SettingsLayout>
  );
}

export default connect(state => state, Actions)(SettingsAuditLogs);
