import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {connect} from 'react-redux';
import moment from 'moment';
import * as Actions from '../../../store/actions';
import {DashboardLayout, Filter, FilterButton} from '../../../components';
import {apiReportBreadcrumb} from '../../../assets/images/images';
import {filterOutEmptyValues, mapToOptionsArray} from '../../../helpers';
import {fetchReport} from '../../../requests/queries/report';
import {PageContext} from '../../../helpers/context';
import {useApps} from '../../../hooks/useAllApps';

const defaultFilterOptions = [
  {
    title: 'App name',
    name: 'app_id',
    values: [],
  },
];

function ApiReport({auth: {userDetails}}) {
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const {allAppsData} = useApps();

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('apireport');
  }, [setPageName]);

  const {data, isLoading} = useQuery(
    [
      'api-report',
      {query: 'overview', company_id: userDetails?.company?.id, ...filters},
    ],
    () =>
      fetchReport({
        query: 'overview',
        company_id: userDetails?.company?.id,
        ...filters,
      }),
  );

  const filterOptions = useMemo(() => {
    if (!isLoading && !allAppsData) {
      return defaultFilterOptions;
    }

    return [
      {
        title: 'Apps',
        name: 'app_id',
        values: mapToOptionsArray(allAppsData),
      },
    ];
  }, [allAppsData, isLoading]);

  const handleFilter = selectedOptions => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const {app_id, start, end} = filteredOptions;

    // Remove default date filter and other empty values
    delete filteredOptions.start;
    delete filteredOptions.end;

    // Validate and format start and end dates
    const startDate = start
      ? moment(start).startOf('day').format('YYYY-MM-DD')
      : '';
    const endDate = end ? moment(end).endOf('day').format('YYYY-MM-DD') : '';

    if (startDate && endDate) {
      filteredOptions.date_filter = `${startDate}~${endDate}`;
    } else {
      delete filteredOptions.date_filter;
    }

    const appIds = app_id && app_id.join(',');

    const newFilters = {
      ...filteredOptions,
      app_id: appIds,
    };

    setFilters(newFilters);
  };

  const viewApiReport = userDetails?.permissions?.analyticsPermissions?.viewApi;

  const exportApiReport =
    userDetails?.permissions?.analyticsPermissions?.exportApi;

  const resetFilters = () => {
    setFilters({});
  };

  return (
    <DashboardLayout
      xlLeftMargin="xl:ml-12"
      xlRightPadding="xl:pr-[76px]"
      overFlow="overflow-none"
      breadCrumbs={
        <div className="flex items-center">
          <img src={apiReportBreadcrumb} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1">API report</p>
        </div>
      }
    >
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={resetFilters}
        filterOptions={filterOptions}
      />
      <div className="flex flex-col gap-6">
        <div className="flex items-center gap-4">
          <FilterButton
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            className="ml-auto w-fit"
          />
          {!viewApiReport ||
            (exportApiReport && (
              <a
                download
                href="/Api Report.pdf"
                target="_blank"
                rel="noreferrer"
                className="text-sm text-white font-medium bg-brandBlue h-12 hover:opacity-90 duration-200 transition rounded px-4 pt-[18px] pb-[14px] w-20 flex items-center justify-center"
              >
                Export
              </a>
            ))}
        </div>

        <iframe
          id="api-report-iframe"
          title="Api Report"
          className="w-full min-h-screen"
          src={data?.url}
        ></iframe>
      </div>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(ApiReport);
