import React, {useEffect} from 'react';

export const OTPCountdownTimer = ({
  timeLeft,
  setTimeLeft,
  resendOtp,
  initialTime = 300,
  loading,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(prevTime => (prevTime !== 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearTimeout(timer);
  }, [initialTime, setTimeLeft, timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div className=" mt-6">
      {timeLeft === 0 ? (
        <div className=" flex items-center w-full justify-center gap-2 font-inter text-base text-[#858DAA]">
          <p>Didn&apos;t get a code?</p>
          <button
            onClick={() => {
              resendOtp && resendOtp();
            }}
            className="flex items-center gap-3 text-dojahBlue font-medium"
          >
            {loading ? 'Resending...' : 'Resend OTP'}
          </button>
        </div>
      ) : (
        <>
          <span className="text-sm mr-2">OTP expires in</span>
          <span
            className={`text-[16px]  ${
              minutes === 0 ? 'text-status-404' : 'text-dojahBlue'
            }`}
          >
            {minutes.toString().padStart(1, '0')}:
            {seconds.toString().padStart(2, '0')}
          </span>
        </>
      )}
    </div>
  );
};
