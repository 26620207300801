import React, {useContext} from 'react';
// import Application from './Application';
import Configuration from './Configuration';
import VerificationPages from './VerificationPages';
import FraudCheckAccordion from './FraudCheck/FraudCheckAccordion';
import ConfirmationPage from './ConfirmationPage';
// import GenerateLink from './GenerateLink';
import Integration from './Integration';
import CostBreakdown from './CostBreakdown';
import {EasyOnboardContext} from '../../helpers/context/EasyOnboardContext';

function ConfigPanel({setSelectedApp, setCurrentPage, setPages, pages}) {
  const {widgetData, setWidgetData} = useContext(EasyOnboardContext);

  return (
    <>
      <div className="flex flex-col gap-4 mt-8 relative ">
        {/*<Application setApp={setSelectedApp} widgetData={widgetData} />*/}
        <Configuration setApp={setSelectedApp} widgetData={widgetData} />
        <VerificationPages
          setPage={setCurrentPage}
          setPages={setPages}
          pages={pages}
        />
        <FraudCheckAccordion setPage={setCurrentPage} />
        <ConfirmationPage
          setUrl={e => {
            const url =
              e.target.value.startsWith('https://') ||
              e.target.value.startsWith('http://')
                ? e.target.value
                : `https://${e.target.value}`;
            setWidgetData({...widgetData, redirect_url: url});
          }}
        />
        {/* <GenerateLink /> */}
        <CostBreakdown />
        <Integration setPage={setCurrentPage} />
      </div>
    </>
  );
}

export default ConfigPanel;
