import React, {useContext, useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import Layout from './Layout';
import {RowData, Loader, GoogleMap} from '../../components';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import EmptyState from '../../components/EmptyState';
import {
  base_address_marker,
  corruptedFile,
  false_address_marker,
  true_address_marker,
} from '../../assets/images/images';
import {PageContext} from '../../helpers/context';

function Address({
  getAddress,
  getIpDeviceCheck,
  verifications: {address, loading},
}) {
  const {slug} = useParams();

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('verifications');
  }, [setPageName]);

  useEffect(() => {
    const sessionId = localStorage.getItem('sessionId');
    const verificationId = localStorage.getItem('verificationId');

    getIpDeviceCheck(slug || verificationId);
    getAddress(slug || sessionId);
  }, [getAddress, getIpDeviceCheck, slug]);

  const ADDRESS_DATA = useMemo(
    () => [
      {leftText: 'Inputted Address', rightText: address?.data?.base?.name},
      {
        leftText: 'Tracked Address',
        rightText: address?.data?.total,
      },
    ],
    [address],
  );

  const baseAddress = address?.data?.base;
  const subAddresses = address?.data?.sub_addresses;

  const formatAddresses = () => {
    const addresses = subAddresses && [...subAddresses, baseAddress];
    return addresses?.map(address => ({
      lat: +address?.latitude,
      lng: +address?.longitude,
      name: address?.name ? address?.name : 'No address name specified',
      markerImage: address.name
        ? base_address_marker
        : address.match
        ? true_address_marker
        : false_address_marker,
    }));
  };

  const center = {
    lat: Number(address?.data?.base?.longitude || 0),
    lng: Number(address?.data?.base?.latitude || 0),
  };

  return (
    <Layout>
      {
        <div>
          <p className="font-medium text-grey">Address</p>

          {!loading &&
          address &&
          Object.keys(address?.data?.base).length === 0 ? (
            <>
              <EmptyState
                body={'We did not find any address information on this person'}
                src={corruptedFile}
                noBtn
                height={45}
              />
            </>
          ) : loading ? (
            <Loader />
          ) : (
            <>
              <div className="flex flex-col mt-4">
                {ADDRESS_DATA.map(({leftText, rightText}, i) => (
                  <RowData key={i} leftText={leftText} rightText={rightText} />
                ))}
              </div>
              <div className="mt-10">
                <div className="flex items-center justify-between">
                  <p className="font-medium text-grey">Map</p>
                  <div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px]">
                    {address?.data?.percent}% match
                  </div>
                </div>
                <div className="relative">
                  <GoogleMap
                    center={center}
                    zoom={8}
                    multipleMarkers={formatAddresses()}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      }
    </Layout>
  );
}

export default connect(state => state, Actions)(Address);
