import React, {useState} from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {toast} from 'react-toastify';
import {
  Modal,
  DropdownIndicator,
  customStyles,
  PrimaryButton,
  MultiValueRemove,
} from '../../../components';
import useDragAndDrop from '../../../hooks/useDragAndDrop';
import {batchUpload} from '../../../assets/images/images';
import {handleFileInput} from '../../../helpers';
import {connect} from 'react-redux';
import * as Actions from '../../../store/actions';
import sampleList from './list_sample.csv';

const LIST_TYPES = [
  {
    label: 'Countries',
    value: 'countries',
  },
  {
    label: 'Others',
    value: 'others',
  },
];

function CreateListModal({
  open,
  setOpen,
  caseLists: {mutateLoading},
  auth: {user},
  addList,
  setListItems,
  listItems,
}) {
  const [type, setType] = useState(null);
  const [currentName, setCurrentName] = useState('');
  const [listValues, setListValues] = useState([]);
  const [name, setName] = useState('');
  const [fileData, setFileData] = useState(null);

  const handleKeyDown = event => {
    if (!currentName) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (listValues.includes(currentName.toLowerCase()))
          return toast.error(`${currentName} already exist in the list`);
        // eslint-disable-next-line no-case-declarations
        const updatedNames = [...listValues, currentName.toLowerCase()];
        setListValues(updatedNames);
        setCurrentName('');
        event.preventDefault();
    }
  };

  const {dragOver, setDragOver, onDragOver, onDragLeave} = useDragAndDrop();

  const onDrop = e => {
    e.preventDefault();
    setDragOver(false);
    const selectedFile = e.dataTransfer.files[0];
    if (
      ![
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ].includes(selectedFile.type)
    )
      return toast.error('Unsupported file type');
    handleFileInput(selectedFile, setFileData);
  };

  const handleChange = e => {
    const selectedFile = e.target.files[0];
    if (
      ![
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ].includes(selectedFile.type)
    )
      return toast.error('Unsupported file type');

    handleFileInput(selectedFile, setFileData);
  };

  const handleCreateList = async () => {
    const data = {
      name,
      ...(listValues.length && {items: listValues}),
      // ...(type && {type: type.value}),
      ...(user && {author: `${user.first_name} ${user.last_name}`}),
      ...(fileData && {base64: fileData?.file?.split(',')[1]}),
    };
    if (!data) return;
    setListItems([
      {
        ...data,
        _id: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        count: listValues?.length || 0,
      },
      ...listItems,
    ]);
    await addList(data);
    setOpen(false);
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Create a list"
    >
      <form className="mt-6 text-left">
        <label htmlFor="list_name" className="mt-6">
          <span className="text-sm">List name</span>
          <input
            type="text"
            id="list_name"
            className="mt-2"
            placeholder="Input value"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </label>

        <label htmlFor="list_type" className="mt-6 mb-2 relative">
          Type
        </label>
        <Select
          defaultValue={type}
          onChange={setType}
          options={LIST_TYPES}
          placeholder="Select type"
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          id="list_type"
          aria-label="List type"
        />

        <label htmlFor="list_description" className="mt-6 relative">
          <p>
            Description <span className="text-grey30">(optional)</span>
          </p>
          <textarea
            id="list_description"
            className="mt-2 min-h-[73px] h-full"
            placeholder="Add description"
            aria-label="Add description"
          />
        </label>

        <div className="mt-6 rounded bg-white80 p-4 flex flex-col gap-4">
          <div>
            <label
              htmlFor="add_to_list"
              className="relative flex flex-row items-end justify-between"
            >
              <p>Add to list</p>
              <p className="text-xs font-medium text-grey20">
                Click enter to add individuals
              </p>
            </label>

            <CreatableSelect
              components={{
                DropdownIndicator: null,
                MultiValueRemove,
              }}
              inputValue={currentName}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={newValue => {
                if (Array.isArray(newValue)) {
                  setListValues(newValue.map(val => val.value));
                }
              }}
              onInputChange={newValue => setCurrentName(newValue)}
              onKeyDown={handleKeyDown}
              value={listValues.map((name, index) => ({
                label: name,
                value: name,
                key: `${name}-${index}`,
              }))}
              placeholder="Add name"
              className="mt-2 creatable-multi-select basic-multi-select"
              classNamePrefix="react-select"
              styles={customStyles}
              aria-label="Add name"
              id="add_to_list"
            />
          </div>
          <p className="-mb-1 text-small tracking-tight text-body text-center">
            OR BULK UPLOAD
          </p>

          <div
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            style={{border: '1px dashed #d9e5f8'}}
            className="py-7 flex flex-col items-center text-center rounded-xl bg-white w-full"
          >
            <div className="flex items-center gap-5 text-small tracking-tight">
              <img src={batchUpload} alt="" width={44} height={44} />
              <div>
                <div className="text-body flex gap-[3px] items-baseline">
                  <input
                    id="bulk_upload"
                    name="bulk_upload"
                    type="file"
                    onChange={handleChange}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  <label
                    htmlFor="bulk_upload"
                    className="text-brandBlue bg-brandBlue40 rounded-full px-[5.5px] pt-[5px] pb-[3px]"
                  >
                    {fileData && fileData?.filename
                      ? 'Change file'
                      : 'Select a file'}
                  </label>
                  <span className="text-body ml-1">or drag and drop</span>
                </div>
                <div className="flex-col items-center hidden sm:flex mt-2">
                  <p className="text-body">
                    {dragOver ? (
                      'Please drop file here'
                    ) : (
                      <span>
                        .XLSX file. Download a sample{' '}
                        <a
                          href={sampleList}
                          download
                          className="text-brandBlue underline"
                        >
                          here
                        </a>
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            {fileData ? (
              <p className="mt-2 text-body text-sm flex gap-[3px] items-baseline">
                File:
                <span className="font-bold text-grey">
                  {fileData?.filename}
                </span>
              </p>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="flex items-center mt-6">
          <PrimaryButton
            onClick={handleCreateList}
            buttonText="Save"
            className="w-full"
            disabled={
              mutateLoading || !(name && (fileData?.file || listValues?.length))
            }
            loading={mutateLoading}
          />
        </div>
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(CreateListModal);
