import React, {useCallback, useMemo} from 'react';
import {
  DashboardLayout,
  PrimaryButton,
  DropdownIndicator,
  customStyles,
  SearchIndicator,
} from '../../../components';
import {baasMobile} from '../../../assets/images/images';
import Select from 'react-select';
import {useMutation, useQueryClient} from 'react-query';
import {
  createBAASSettings,
  updateBAASSettings,
} from '../../../requests/queries/baas';
import {settlementType} from './constants';
import {toast} from 'react-toastify';
import {UpdatePin} from './components/UpdatePin';
import {useRetrieveUserBankName} from './hooks/useRetrieveUserBankName';
import {useBaasSettings} from './hooks/useBaasSettings';

function BaasSettings() {
  const {
    banks,
    userDetails,
    settlement,
    currentBank,
    userSettings,
    selectedBank,
    accountNumber,
    setSettlement,
    setAccountNumber,
    setSelectedBank,
    currentSettlement,
  } = useBaasSettings({type: 'payout'});

  const queryClient = useQueryClient();
  const {userBankDetails, isBankDetailsLoading} = useRetrieveUserBankName({
    accountNumber: accountNumber,
    bankCode: selectedBank?.value,
  });

  const {mutateAsync, isLoading: createLoading} = useMutation(
    'create-baas-settings',
    createBAASSettings,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('baas-settings');
        toast.success('Payout account added successfully');
        setSettlement({});
        setAccountNumber('');
        setSelectedBank({});
      },
    },
  );
  const {mutateAsync: updateSettings, isLoading: updateLoading} = useMutation(
    'update-baas-settings',
    updateBAASSettings,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('baas-settings');
        toast.success('Payout account updated successfully');
      },
    },
  );

  const handleSaveSettings = useCallback(async () => {
    if (!accountNumber || accountNumber.length !== 10) {
      toast.error('Account number must be exactly 10 characters long');
      return;
    }

    if (!selectedBank?.value) {
      toast.error('Please select your bank');
      return;
    }

    if (!settlement?.value) {
      toast.error('Please select your settlement type');
      return;
    }
    try {
      const data = {
        company_id: userDetails && userDetails?.company?.id,
        settlement_options: settlement?.value,
        account_number: accountNumber,
        bank_code: selectedBank?.value,
      };

      if (!userSettings?.id) {
        await mutateAsync(data);
      } else {
        await updateSettings({...userSettings, ...data});
      }
    } catch (e) {
      console.log(e);
    }
  }, [
    accountNumber,
    mutateAsync,
    selectedBank?.value,
    settlement?.value,
    updateSettings,
    userDetails,
    userSettings,
  ]);

  const isEnabled = useMemo(
    () =>
      accountNumber?.length === 10 &&
      userBankDetails?.entity?.account_name &&
      (selectedBank?.value || currentBank) &&
      (settlement?.value || currentSettlement) &&
      userDetails?.company?.id,
    [
      accountNumber?.length,
      currentBank,
      currentSettlement,
      selectedBank?.value,
      settlement?.value,
      userBankDetails?.entity?.account_name,
      userDetails?.company?.id,
    ],
  );

  return (
    <DashboardLayout
      bg="bg-white80"
      topPadding="pt-8"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={baasMobile} alt="" />
          <p className="ml-2 -mb-1">Settings</p>
        </div>
      }
    >
      <div className="flex items-start lg:items-center lg:flex-row flex-col gap-5 justify-between p-5 lg:p-0">
        <p className="text-grey font-medium text-[18px]">Settings</p>
      </div>

      <div className="flex flex-col lg:flex-row items-start justify-between mt-9 gap-6">
        <UpdatePin />
        <div className="w-full lg:w-1/2">
          <p className="text-body text-xs font-medium mb-6 uppercase">
            payout settings
          </p>{' '}
          <div className="bg-white p-6 rounded flex flex-col gap-2">
            <label htmlFor="account number">
              <span className="text-sm">Account number</span>
              <input
                type="number"
                id="account number"
                name="account number"
                className="mt-2"
                placeholder="Eg. 82810092900"
                value={accountNumber}
                onChange={e => setAccountNumber(e.target.value)}
              />
            </label>

            <p className="font-medium text-sm text-dojahBlue  mb-4 text-start">
              {isBankDetailsLoading ? (
                <div className="spinner "></div>
              ) : (
                (userBankDetails && userBankDetails?.entity?.account_name) || ''
              )}
            </p>

            <label htmlFor="country" className="relative block">
              <p className="mb-2">
                Select Bank{' '}
                <span className="text-grey-200 italic">
                  (Search with bank name)
                </span>
              </p>
              <Select
                placeholder="Select your bank"
                components={{DropdownIndicator: SearchIndicator}}
                classNamePrefix="react-select"
                styles={customStyles}
                options={banks}
                value={selectedBank || currentBank}
                onChange={data => setSelectedBank(data)}
              />
            </label>
          </div>
          <div className="bg-white px-6 pb-6 rounded flex flex-col gap-6">
            <label htmlFor="wallet_balance" className="relative block">
              <p className="mb-2">Choose where to settle account</p>
              <Select
                value={settlement || currentSettlement}
                placeholder="Select your settlement type..."
                components={{DropdownIndicator}}
                classNamePrefix="react-select"
                styles={customStyles}
                options={settlementType}
                onChange={data => setSettlement(data)}
              />
            </label>

            <div className="flex items-center gap-2">
              <PrimaryButton
                onClick={handleSaveSettings}
                buttonText="Save Changes"
                className="w-[131px]"
                loading={createLoading || updateLoading}
                disabled={!isEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default BaasSettings;
