import React, {useState} from 'react';
import {connect} from 'react-redux';
import PrimaryButton from './PrimaryButton';
import * as Actions from '../store/actions';
import {toast} from 'react-toastify';
import {handleImage} from '../helpers';
import {useMutation, useQueryClient} from 'react-query';
import {createApp} from '../requests/queries/apps';

function CreateAppForm({setOpen}) {
  const defaultFilename = 'Click here to upload your app logo';
  const [state, setState] = useState({
    color_code: '',
    name: '',
  });
  const [uploading, setLoading] = useState(null);
  const [icon_url, setIcon] = useState('');
  const [filename, setFilename] = useState(defaultFilename);

  const queryClients = useQueryClient();

  const {mutate, isLoading} = useMutation(
    'create-app',
    data => createApp(data),
    {
      onSuccess: () => {
        queryClients.invalidateQueries('all-apps');
        toast.success('App Created successfully');
        setOpen(false);
      },
    },
  );

  const handleChange = e => {
    e.persist();
    setState(prev => ({...prev, [e.target?.id]: e.target?.value}));
  };

  const handleCallback = ({loading, icon, name}) => {
    setLoading(loading);
    setIcon(icon);
    setFilename(name);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (state.name.length < 3) {
      return toast.error(
        'The name of the app must be at least 3 characters long.',
      );
    }

    if (icon_url.length) {
      mutate({...state, icon_url});
    } else {
      mutate({...state});
    }
  };

  return (
    <div className="mt-6 text-left">
      <label htmlFor="app_name_create" className="mt-6">
        <span className="text-sm ">Name of App</span>
        <input
          type="email"
          id="name"
          className="mt-2"
          onChange={e => handleChange(e)}
        />
      </label>

      <label htmlFor="upload_logo" className="mt-6">
        Logo
        <input
          type="file"
          id="upload_logo"
          onChange={e =>
            handleImage(e.target.files[0], handleCallback, 'App Logo')
          }
          accept="image/png, image/gif, image/jpeg, image/jpg"
        />
        <div className="cursor-pointer bg-white80 mt-2 py-[14px] text-center border border-brandBlue border-dashed rounded w-full font-normal">
          <p className={`text-body ${uploading ? 'italic' : 'text-black'} `}>
            {uploading ? 'Uploading icon...' : filename}
          </p>
        </div>
      </label>

      <label htmlFor="primary_color" className="mt-6">
        Primary Color
        <div className="flex gap-4 w-full">
          <input
            type="color"
            id="color_code"
            className="mt-2 p-0 max-w-[48px]"
            onChange={e => handleChange(e)}
            value={state.color_code?.toLocaleUpperCase()}
          />
          <input
            value={state.color_code?.toLocaleUpperCase()}
            type="text"
            id="color_code"
            className="mt-2"
            placeholder="#00000"
            onChange={e => handleChange(e)}
          />
        </div>
      </label>

      <div className="flex items-center mt-6">
        <PrimaryButton
          buttonText="Create App"
          className="w-full"
          onClick={handleSubmit}
          disabled={state.name.length === 0}
          loading={uploading || isLoading}
        />
      </div>
    </div>
  );
}

export default connect(state => state, Actions)(CreateAppForm);
