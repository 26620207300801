import React, {useState} from 'react';
import {DashboardLayout} from '../../../../components';
import {
  chevronRight,
  customerMail,
  customerPhone,
  customersMobileNav,
  overviewInflow,
  overviewOutflow,
  transactionCount,
} from '../../../../assets/images/images';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Constants, getInitials} from '../../../../helpers';
import {TransactionsTable} from '../TransactionsTable';
import {useQuery} from 'react-query';
import {fetchCustomer} from '../../../../requests/queries/reconciliations';
import TransactionDetailsModal from '../TransactionDetailsModal';
import {GoBack} from '../components/GoBack';
import {useOverview, useTransactions} from '../hooks/useReconQueries';
import {OverviewCards} from '../components/OverviewCards';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';

function CustomerDetails() {
  const [transactionId, setTransactionId] = useState('');
  const [transactionDetails, setTransactionDetails] = useState(false);
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  const navigate = useNavigate();
  const {slug} = useParams();
  const {overviewLoading, inFlow, outFlow} = useOverview({
    entity_id: 'customer_id',
    id: slug,
  });

  const {tableData, isLoading} = useTransactions({
    entity_id: 'customer_id',
    id: slug,
    tabType: 'internal',
    ...query,
  });

  const {data: customerDetails, isLoading: customerDetailsLoading} = useQuery(
    ['customer-details', slug],
    () => fetchCustomer(slug),
  );

  const customerData =
    !customerDetailsLoading && customerDetails && customerDetails?.entity;

  const closeTransactionDetails = () => {
    setTransactionDetails(false);
  };

  const openTransactionDetails = () => {
    setTransactionDetails(true);
  };

  const flows = [
    {
      icon: overviewInflow,
      alt: 'in-flow',
      title: 'Total Inflow',
      count: inFlow?.count || '0',
      value: shortenLargeNumbers(inFlow?.amount, true, {startAt: 'B'}),
    },
    {
      icon: overviewOutflow,
      alt: 'out-flow',
      title: 'Total Outflow',
      count: outFlow?.count || '0',
      value: shortenLargeNumbers(outFlow?.amount, true, {startAt: 'B'}),
    },
    {
      icon: transactionCount,
      alt: 'transaction-count',
      title: 'Total Transaction Count',
      value: shortenLargeNumbers(outFlow?.count + inFlow?.count, null, {
        decimals: 0,
      }),
    },
  ];

  return (
    <DashboardLayout
      bg="bg-white"
      overFlow="!pr-0"
      leftMargin="!ml-0"
      breadCrumbs={
        <div className="flex items-center">
          <img src={customersMobileNav} alt="" width={18} height={18} />
          <Link
            className="ml-2 -mb-1"
            to={Constants.PAGES_URL.PROJECT_CUSTOMERS?.replace(
              ':slug',
              'add-project',
            )}
          >
            Customers
          </Link>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1 cursor-pointer">Customer details</p>
        </div>
      }
    >
      <TransactionDetailsModal
        show={transactionDetails}
        onClose={closeTransactionDetails}
        transactionId={transactionId}
        isCustomer={true}
      />
      <div className="pl-11 pr-8">
        <div className="flex items-center justify-between">
          <GoBack goBack={() => navigate(-1)} />
        </div>

        <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-6 mt-4 mb-8">
          <h2 className=" text-lg font-medium text-grey">Customer Details</h2>
        </div>

        <div className="bg-white80 rounded-xl py-5 px-6">
          <div className="flex flex-col lg:flex-row lg:items-center gap-4 lg:gap-0">
            <div className="bg-white rounded-full mr-4  w-[68px] h-[68px] border border-[#ECECEC] flex items-center justify-center">
              <p className="text-deepBlue font-medium text-xl">
                {getInitials(customerData?.name, 2) || '--'}
              </p>
            </div>

            <h3 className="text-deepBlue font-semibold text-xl mr-28">
              {customerData?.name || '-'}
            </h3>

            <div className="flex items-center px-[10px] gap-1 mr-1 py-[6px] bg-white rounded-full text-deepBlue font-medium text-tiny">
              <img src={customerMail} alt="mail" />
              <p className="mt-[3px]">{customerData?.email || '-'}</p>
            </div>

            <div className="flex items-center px-[10px] py-[6px] gap-2 bg-white rounded-full text-deepBlue font-medium text-tiny">
              <img src={customerPhone} alt="mail" width={18} />
              <p>{customerData?.phone || '-'}</p>
            </div>
          </div>
          <OverviewCards
            innerClassName=" relative bg-white py-6 pl-6 pr-2 rounded-lg flex items-center gap-2 w-full"
            className=" grid lg:grid-cols-3 grid-cols-1 gap-4 flex-1 mt-4"
            overviewLoading={overviewLoading}
            cardData={flows}
            loaderWidth={200}
          />
        </div>

        {/*<div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">*/}
        {/*  <div className="bg-white80 rounded-xl p-6 flex items-center justify-between">*/}
        {/*    <p className="text-[#868C98] text-tiny">First Transaction Date</p>*/}
        {/*    <p className="text-black text-tiny font-medium">*/}
        {/*      9th, May 2022, 5:09pm*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*  <div className="bg-white80 rounded-xl p-6 flex items-center justify-between">*/}
        {/*    <p className="text-[#868C98] text-tiny">Last Transaction Date</p>*/}
        {/*    <p className="text-black text-tiny font-medium">*/}
        {/*      4th, June 2024, 5:09pm*/}
        {/*    </p>*/}
        {/*  </div>{' '}*/}
        {/*</div>*/}

        <div className="flex items-center mt-10">
          <p className="text-left">Transactions</p>
        </div>
        <TransactionsTable
          transactionsLoading={isLoading}
          transactions={tableData}
          openTransactionDetails={openTransactionDetails}
          tabType="internal"
          component="customer"
          setId={setTransactionId}
          setQuery={setQuery}
          query={query}
        />
      </div>
    </DashboardLayout>
  );
}

export default CustomerDetails;
