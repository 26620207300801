import {api} from '../../helpers';
import {GET_ALL_APPS} from '../../store/types';

export function allApps(data) {
  return api
    .get('/admin/logs/summary', {
      headers: {
        Environment: data?.env,
      },
    })
    .then(res => res.data);
}

export function fetchApiLogsGraph(params) {
  return api.get('/logs/v2/services/graph', {params}).then(res => res.data);
}
export function fetchAllApps({params, dispatch}) {
  return api.get(`/apps`, {params}).then(res => {
    const {apps} = res.data;
    const appData = apps?.length === 0 ? null : res.data;
    const appIds = (appData && apps?.map(app => app?._id).toString()) || '';
    localStorage.setItem('appIds', appIds);
    dispatch({payload: appData, type: GET_ALL_APPS});
    return res.data;
  });
}
export function fetchOnboardFlowApps({params}) {
  return api.get(`/apps/easy-onboard`, {params}).then(res => res.data);
}

export function fetchApiLogsFilters() {
  return api.get(`/logs/services/filters`).then(res => res.data);
}
