/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Accordion,
  customStyles,
  DropdownIndicator,
  formatGroupLabel,
  SecondaryButton,
} from '../../../../components';
import Select from 'react-select';
import {
  BANK_GROUP,
  BANK_TRANSACTION_PARENT,
  CARD_GROUP,
  CARD_TRANSACTION_PARENT,
  CONDITION_TYPE,
  OPERATORS,
  IS_TRUE_OR_FALSE,
} from './transactions';
import {trashDarkRed} from '../../../../assets/images/images';
import {CreditCheckContext} from '../../../../helpers/context/CreditCheckContext';
import {connect} from 'react-redux';
import * as Actions from '../../../../store/actions';

const ConditionInputs = ({
  flowConditions,
  action,
  flows,
  condition,
  listLoading,
  lists,
}) => {
  const {
    set: {setRuleState},
    get: {
      ruleState: {actionName, actionValue, currentRule, selectedParent},
    },
    handleDeleteCondition,
    handleUpdateCondition,
  } = useContext(CreditCheckContext);
  const CUSTOM_LIST = useMemo(
    () =>
      (!listLoading &&
        lists?.lists?.map(list => ({
          value: list?.name,
          label: list?.name,
        }))) ||
      [],
    [listLoading, lists],
  );

  // const CURRENT_CONDITION = flowConditions[condition?.key];
  const CURRENT_CONDITION = currentRule.configurations[condition?.key];
  const [selectedLists, setSelectedLists] = useState(CUSTOM_LIST[0] || []);
  const [selectedTrueOrFalse, setSelectedTrueOrFalse] = useState(
    IS_TRUE_OR_FALSE[0] || [],
  );

  const PARENT_FIELD =
    flows?.flow?.flow?.use_case === 'bank transaction'
      ? BANK_TRANSACTION_PARENT
      : CARD_TRANSACTION_PARENT;

  const CHILD_FIELD =
    flows?.flow?.flow?.use_case === 'bank transaction'
      ? BANK_GROUP
      : CARD_GROUP;

  const currentParent = useMemo(
    () =>
      [...((PARENT_FIELD?.length && PARENT_FIELD) || [])]?.find(
        parent =>
          parent.label.toLowerCase() ===
          CURRENT_CONDITION?.parent?.toLowerCase(),
      ),
    [condition?.key],
  );

  const currentFiled = useMemo(
    () =>
      CHILD_FIELD[currentParent?.value]?.length &&
      CHILD_FIELD[currentParent?.value]?.find(
        field => field?.value === CURRENT_CONDITION?.field,
      ),
    [condition?.key],
  );
  const currentOperator = useMemo(
    () =>
      OPERATORS.find(
        operator => operator.value === CURRENT_CONDITION?.operator,
      ),
    [condition?.key],
  );
  const currentConditionType = useMemo(
    () => CONDITION_TYPE.find(type => type.value === CURRENT_CONDITION?.type),
    [condition?.key],
  );
  const currentList = useMemo(
    () => CUSTOM_LIST?.find(item => item.value === CURRENT_CONDITION?.value),
    [condition?.key],
  );

  const isPepOrSanctionList = ['is_pep', 'is_sanction_list']?.includes(
    CURRENT_CONDITION?.field,
  );
  const isInOrNotIn = ['in', 'not_in']?.includes(CURRENT_CONDITION?.operator);

  useEffect(() => {
    setRuleState(prev => ({
      ...prev,
      selectedParent: {...currentParent, [action]: currentParent},
      selectedField: {...currentFiled, [action]: currentFiled},
      actionValue: {
        ...actionValue,
        [action]: CURRENT_CONDITION?.value,
      },
      actionScore: {[action]: currentRule?.score},
    }));

    setSelectedLists(currentList || CUSTOM_LIST[0]);
  }, [
    setRuleState,
    setSelectedLists,
    setSelectedTrueOrFalse,
    currentParent,
    action,
    currentFiled,
  ]);

  const allData = {
    actionValue: actionValue[action],
    selectedParent: selectedParent[action],
  };

  const filteredOperators = isPepOrSanctionList
    ? OPERATORS.filter(
        operator =>
          operator.value === 'equals_to' || operator.value === 'not_equals_to',
      )
    : OPERATORS;

  const valueObject = useCallback(() => {
    if (isPepOrSanctionList && !isInOrNotIn) {
      return {
        value: selectedTrueOrFalse,
        func: setSelectedTrueOrFalse,
        option: IS_TRUE_OR_FALSE,
      };
    } else if (isInOrNotIn) {
      return {
        value: currentList || selectedLists,
        func: setSelectedLists,
        option: CUSTOM_LIST,
      };
    }
  }, [
    isPepOrSanctionList,
    isInOrNotIn,
    selectedTrueOrFalse,
    IS_TRUE_OR_FALSE,
    selectedLists,
    CUSTOM_LIST,
  ]);

  return (
    <>
      <Accordion
        key={condition?.key}
        isOpen={Boolean(!actionName[action])}
        title={
          condition?.key === 0
            ? 'Condition'
            : `Condition - ${condition?.key + 1}`
        }
      >
        {condition?.key === 0 && (
          <p className="text-start text-[11px] mt-2 text-body">
            Set customizable rules and conditions to spot fraud through your
            user’s journey
          </p>
        )}
        <div className="mt-4">
          {condition?.key > 0 && (
            <>
              <label htmlFor="end_process" className="mt-4 mb-2 relative">
                Condition Type
              </label>
              <Select
                defaultValue={currentConditionType}
                onChange={data => {
                  handleUpdateCondition('type', data?.value, condition?.key);

                  setRuleState(prev => ({
                    ...prev,
                    conditionType: {
                      ...prev.conditionType,
                      [action]: data,
                    },
                  }));
                }}
                options={CONDITION_TYPE}
                placeholder="And/Or"
                className="basic-multi-select white-select"
                classNamePrefix="react-select"
                components={{DropdownIndicator}}
                styles={customStyles}
                menuPlacement="auto"
              />
            </>
          )}

          <label htmlFor="end_process" className="mt-4 mb-2 relative">
            Parent Field
          </label>
          <Select
            defaultValue={currentParent}
            onChange={data => {
              handleUpdateCondition('parent', data?.label, condition?.key);

              setRuleState(prev => ({
                ...prev,
                selectedField: {},
                selectedParent: {
                  [action]: data,
                },
              }));
              setSelectedTrueOrFalse({});
              setSelectedLists({});
            }}
            options={PARENT_FIELD}
            placeholder="Select an Parent Field"
            className="basic-multi-select white-select"
            classNamePrefix="react-select"
            components={{DropdownIndicator}}
            styles={customStyles}
            menuPlacement="auto"
          />
          <label htmlFor="end_process" className="mb-2 mt-4 relative">
            Field
          </label>
          <Select
            defaultValue={currentFiled}
            onChange={data => {
              handleUpdateCondition('field', data?.value, condition?.key);

              setRuleState(prev => ({
                ...prev,
                selectedField: {
                  [action]: data,
                },
              }));
              setSelectedTrueOrFalse({});
              setSelectedLists({});
            }}
            options={
              CHILD_FIELD[allData?.selectedParent?.value || currentFiled?.value]
            }
            formatGroupLabel={formatGroupLabel}
            placeholder="Select field"
            className="basic-multi-select white-select"
            classNamePrefix="react-select"
            components={{DropdownIndicator}}
            styles={customStyles}
            menuPlacement="auto"
          />
          <label htmlFor="end_process" className="mt-4 mb-2 relative">
            Operator
          </label>
          <Select
            defaultValue={currentOperator}
            onChange={data => {
              handleUpdateCondition('operator', data?.value, condition?.key);

              setRuleState(prev => ({
                ...prev,
                selectedOperator: {
                  ...prev.selectedOperator,
                  [action]: data,
                },
              }));
              setSelectedTrueOrFalse({});
              setSelectedLists({});
            }}
            options={filteredOperators}
            placeholder="Select an operator"
            className="basic-multi-select white-select"
            classNamePrefix="react-select"
            components={{DropdownIndicator}}
            styles={customStyles}
            menuPlacement="auto"
          />

          {/*********** VALUE should be dynamic ************/}

          <label htmlFor="value" className="mt-4">
            Value
          </label>
          {isInOrNotIn || isPepOrSanctionList ? (
            <Select
              defaultValue={valueObject()?.value}
              onChange={data => {
                handleUpdateCondition('value', data?.value, condition?.key);
                valueObject()?.func(data);

                setRuleState(prev => ({
                  ...prev,
                  actionValue: {
                    ...prev.actionValue,
                    [action]: data?.value,
                  },
                }));
              }}
              options={valueObject()?.option}
              placeholder="Select list"
              className="basic-multi-select white-select"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              menuPlacement="auto"
            />
          ) : (
            <input
              data-bg="white"
              type="text"
              id="value"
              className="mt-2 placeholder-black text-sm"
              required
              value={CURRENT_CONDITION?.value || condition?.value}
              placeholder={condition?.value}
              onChange={e => {
                handleUpdateCondition('value', e.target.value, condition?.key);
                setRuleState(prev => ({
                  ...prev,
                  actionValue: {
                    ...prev.actionValue,
                    [action]: e.target.value,
                  },
                }));
              }}
            />
          )}
        </div>
        {condition?.key >= 1 && (
          <div className="mt-5">
            <SecondaryButton
              src={trashDarkRed}
              buttonText="Delete condition"
              className="text-[#A13E3F] bg-white outline outline-1 ml-auto outline-[#A13E3F] flex items-center justify-center gap-5 outline-none"
              leftImg
              width={15}
              onClick={() => {
                handleDeleteCondition(condition?.key);
                flowConditions.splice(+condition?.key, 1);
              }}
              noOutline
            />
          </div>
        )}
      </Accordion>
    </>
  );
};
export default connect(state => state, Actions)(ConditionInputs);
