import React, {useCallback, useContext, useState} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import {
  Modal,
  DropdownIndicator,
  customStyles,
  PrimaryButton,
} from '../../components';
import * as Actions from '../../store/actions';
import {getPages} from '../../helpers/getPages';
import {DEFAULT_PAGES} from './defaultPages';
import {EasyOnboardContext} from '../../helpers/context/EasyOnboardContext';

function NewPageModal({
  widgets: {defaultPages},
  mutateWidgetPages,
  ...othersProps
}) {
  const {open, setOpen} = othersProps;
  const [selectedPage, setSelectedPage] = useState({});
  const {setTrackChanges} = useContext(EasyOnboardContext);

  const UPDATE_DEFAULT_PAGES = DEFAULT_PAGES?.map((page, index) => ({
    ...page,
    pageId: index,
    value: page?.page,
    label: getPages(page?.page),
  })).filter(page => page.value !== 'indemnity');

  const index = defaultPages?.length - 1;
  const handleAddPage = useCallback(
    e => {
      e.preventDefault();
      if (selectedPage) {
        delete selectedPage.value;
        delete selectedPage.label;
        const newPages = {
          page: {
            ...selectedPage,
            pageId: `${index + 1}-${selectedPage?.page}`,
          },
          type: 'add',
        };
        mutateWidgetPages(newPages);
        setOpen(false);
        setTrackChanges(true);
      }
    },
    [setTrackChanges, selectedPage, mutateWidgetPages, index, setOpen],
  );

  return (
    <Modal
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[339px] overflow-y-auto h-auto max-h-[45%] sm:max-h-[45%] rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Add a new page"
    >
      <form className="mt-6 text-left">
        <label htmlFor="app" className="relative mb-2 text-xs">
          Select a page to add
        </label>
        <Select
          defaultValue={selectedPage}
          value={selectedPage}
          onChange={setSelectedPage}
          options={UPDATE_DEFAULT_PAGES}
          placeholder="Select app"
          components={{DropdownIndicator}}
          classNamePrefix="react-select-new-page"
          styles={customStyles}
          isSearchable={false}
        />
        <PrimaryButton
          onClick={handleAddPage}
          buttonText="Add page"
          className="w-full mt-4"
          disabled={!selectedPage.label}
        />
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(NewPageModal);
