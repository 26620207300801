import React from 'react';

const Answer = ({answer, ...props}) => (
  <p
    {...props}
    className="text-body capitalize text-[13px] text-left max-w-[350px] break-words mb-2"
  >
    <span className="h-[5px] w-[5px] rounded-full bg-grey-500 inline-block relative right-1 bottom-[2px]" />
    {answer || '-'}
  </p>
);
const QuestionAnswer = ({answer, type}) => (
  <div className="flex items-start gap-[137px] justify-between mt-8">
    <p className="text-xs font-medium uppercase text-grey">answer</p>
    {type === 'text' ? (
      <p className="text-body text-[13px] text-right max-w-[350px] break-words">
        {answer || '-'}
      </p>
    ) : (
      <div className="flex flex-col">
        {Array.isArray(answer) ? (
          answer.map((option, idx) => (
            <Answer key={option + idx} answer={option} />
          ))
        ) : (
          <Answer answer={answer} />
        )}
      </div>
    )}
  </div>
);

const ComplianceQuestions = ({QUESTIONS}) => {
  if (!QUESTIONS?.question || !Array.isArray(QUESTIONS?.question)) {
    return null;
  }

  return (
    <div className="mt-9">
      <p className="font-medium text-black mb-6">Compliance Questions</p>
      {QUESTIONS.question.map((question, index) => (
        <div
          key={`${question.text}-${index}`}
          className="pt-3 pb-6 border-[0.5px] border-transparent border-t-grey60 last:border-b-grey60 px-1"
        >
          <div className="bg-blue-50 px-2 py-4 rounded-lg">
            <p className="text-dojahBlue-900 text-[13px] font-medium capitalize">
              {question?.text || '-'}
            </p>
          </div>
          <QuestionAnswer answer={question.answer} type={question.type} />
        </div>
      ))}
    </div>
  );
};

export default React.memo(ComplianceQuestions);
