const mode = [
  {value: 'api_call', label: 'API call', name: 'mode'},
  {value: 'easy_lookup', label: 'Easy Lookup', name: 'mode'},
  {value: 'widget', label: 'Widget', name: 'widget'},
];

const services = [
  {value: 'phone_number', label: 'Phone number', name: 'services'},
  {value: 'nin', label: 'NIN', name: 'services'},
  {value: 'aml', label: 'AML screening', name: 'services'},
];

const status = [
  {value: '200', label: '200', name: 'status_codes'},
  {value: '202', label: '202', name: 'status_codes'},
  {value: '400', label: '400', name: 'status_codes'},
  {value: '401', label: '401', name: 'status_codes'},
  {value: '404', label: '404', name: 'status_codes'},
  {value: '405', label: '405', name: 'status_codes'},
  {value: '424', label: '424', name: 'status_codes'},
  {value: '500', label: '500', name: 'status_codes'},
  {value: 'pending', label: 'pending', name: 'status_codes'},
];

export const logsFilter = {
  mode,
  services,
  status,
};
