import React, {useState} from 'react';
import {css} from '@emotion/react';
import {connect} from 'react-redux';
import PuffLoader from 'react-spinners/PuffLoader';
import {useNavigate} from 'react-router-dom';
import {closeModal, plus, times} from '../../assets/images/images';
import {customStyles, DropdownIndicator, Loader, Modal} from '../../components';
import * as Actions from '../../store/actions';
import NumberSlider from './NumberSlider';
import {AMLToggle} from '../../components/Toggle';
import Select from 'react-select';
import CreateAppForm from '../../components/CreateAppForm';
import {toast} from 'react-toastify';
import NoInfoModal from './NoInfoModal';
import {Constants} from '../../helpers';

function BusinessLookup({
  businessOpen,
  setBusinessOpen,
  setStep,
  step = 1,
  apps: {allApps},
  performBusinessAMLLookup,
  aml: {amlHistory, lookupLoading, loading},
}) {
  const navigate = useNavigate();

  const userApps =
    allApps &&
    allApps?.apps?.map(
      app =>
        ({
          value: app._id,
          label: app.name,
          name: app.name,
        } || []),
    );

  const initialData = {
    app_id: userApps && userApps[0]?.value,
    entity_name: '',
    match_score: 80,
    ongoing_monitoring: false,
  };
  const [newAppForm, setNewAppForm] = useState(false);
  const [appData, setSelectApp] = useState({
    ...(userApps?.length && userApps[0]),
  });
  const [businessData, setBusinessData] = useState(initialData);
  const [noInfoDialog, setNoInfoDialog] = useState(true);

  const onChange = e => {
    const {name, value} = e.target;
    setBusinessData({...businessData, [name]: value});
  };

  const handlePerform = e => {
    const {entity_name, app_id} = businessData;
    e.preventDefault();
    if (entity_name === '')
      return toast.error('Opps!! sorry, Business name is required');
    localStorage.setItem('aml_app_id', app_id);
    performBusinessAMLLookup(businessData, () => {
      localStorage.setItem('organisation', 'organisation');
      setStep(2);
      setBusinessOpen(false);
    });
    setBusinessData(initialData);
  };

  const override = css`
    border-color: #ffffff;
    position: absolute;
    background: transparent;
  `;
  if (step === 1) {
    return (
      <Modal
        show={businessOpen}
        onClose={setBusinessOpen}
        extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
        modalPosition="justify-center sm:justify-end"
      >
        <div className="py-6 sm:py-12 px-[19px] sm:px-6">
          <header className="flex items-center justify-between">
            <h3 className="text-grey text-base font-medium">Lookup business</h3>
            <button onClick={() => setBusinessOpen(false)}>
              <img src={closeModal} alt="" />
            </button>
          </header>
          {lookupLoading ? (
            <Loader />
          ) : (
            <form className="text-left">
              <label htmlFor="app" className="relative mt-6 mb-2">
                Select app
              </label>
              <Select
                defaultValue={appData}
                value={appData}
                onChange={app => {
                  setSelectApp(app);
                  setBusinessData({...businessData, app_id: app?.value});
                }}
                options={userApps}
                placeholder="Select app"
                components={{DropdownIndicator}}
                classNamePrefix="react-select"
                styles={customStyles}
                isSearchable={false}
              />
              <button
                className="flex items-center gap-[2px] ml-auto mt-2"
                onClick={() => setNewAppForm(prev => !prev)}
                type="button"
              >
                <img
                  src={newAppForm ? times : plus}
                  alt=""
                  width={12}
                  height={12}
                />
                <span className="text-xs font-medium text-body">
                  {newAppForm ? 'Close' : 'Create a new app'}
                </span>
              </button>
              {newAppForm && (
                <div className="p-6 mt-2 rounded bg-white80 animate-dropdown">
                  <h3 className="text-base font-medium text-grey">
                    Create App
                  </h3>
                  <CreateAppForm setOpen={() => setNewAppForm(prev => !prev)} />
                </div>
              )}

              <label htmlFor="entity_name" className="mt-6">
                <span className="text-sm">Business name</span>
                <input
                  type="text"
                  name="entity_name"
                  className="mt-2"
                  value={businessData?.entity_name}
                  onChange={onChange}
                />
              </label>

              <label htmlFor="name_match" className="mt-6">
                <span className="text-sm mb-3">Match score</span>

                <NumberSlider
                  onChange={value =>
                    setBusinessData({...businessData, match_score: value})
                  }
                  value={businessData?.match_score}
                />
              </label>

              <div className="flex items-center gap-2  pr-2 py-[10px]  mt-6 bg-white  rounded">
                <AMLToggle
                  isChecked={businessData.ongoing_monitoring}
                  onCheck={v =>
                    setBusinessData({...businessData, ongoing_monitoring: v})
                  }
                />
                <span className="text-sm leading-[18px] text-brandBlue50">
                  enable ongoing monitoring
                </span>
              </div>

              <div className="mt-6 flex items-center gap-4">
                <button
                  onClick={handlePerform}
                  className={` ${
                    loading
                      ? 'flex items-center justify-center relative h-12 px-4 py-6'
                      : ''
                  } text-white text-sm font-medium bg-brandBlue py-4 px-[81px] rounded w-full`}
                >
                  {loading ? (
                    <PuffLoader css={override} size={35} color="#ffffff" />
                  ) : (
                    'Look up'
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    );
  } else if (step === 2) {
    if (!amlHistory?.entity?.profile_ids?.length) {
      setBusinessOpen(false);
      return (
        <NoInfoModal
          open={noInfoDialog}
          setOpen={() => {
            setNoInfoDialog(false);
            setStep(1);
          }}
          type="business"
          name="business"
        />
      );
    }
    navigate(
      `${Constants.PAGES_URL.AML_BUSINESS_SCREENING_SEARCH_RESULTS.replace(
        ':slug',
        amlHistory?.entity?.lookup_id,
      )}`,
    );
    return null;
  }
}

export default connect(state => state, Actions)(BusinessLookup);
