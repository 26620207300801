import React from 'react';

function TransactionTab({tabType, setTabType, setQuery, query, count}) {
  return (
    <div>
      <div className="border w-fit font-inter p-1 border-[#E1E4EA] rounded-xl bg-[#F7F7F7] flex items-center gap-2 text-xs lg:text-tiny font-medium">
        <div
          className={`${
            tabType === 'internal'
              ? 'bg-white text-black'
              : 'bg-transparent text-[#868C98]'
          } cursor-pointer  rounded-lg px-4 py-[10px] transition hover:bg-white text-black`}
          onClick={() => {
            setTabType('internal');
            setQuery({...query, page: 1});
          }}
        >
          Expected Payment{' '}
          <span className="text-grey-200 ml-2 font-light ">{`(${
            count?.internalCount || 0
          })`}</span>
        </div>
        <div
          className={`${
            tabType === 'external'
              ? 'bg-white text-black'
              : 'bg-transparent text-[#868C98]'
          } cursor-pointer  rounded-lg px-4 py-[10px] transition hover:bg-white text-black`}
          onClick={() => setTabType('external')}
        >
          Transactions{' '}
          <span className="text-grey-200 ml-2 font-light">{`(${
            count?.externalCount || 0
          })`}</span>
        </div>
      </div>
    </div>
  );
}

const TransactionTabs = React.memo(TransactionTab);

export {TransactionTabs};
