import React from 'react';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';

function Ghana({lookupInputs, setLookupInputs}) {
  return (
    <>
      <div
        className={`${
          lookupInputs.type === null ? 'hidden' : 'block'
        } mt-8 sm:bg-white80 sm:p-6 rounded`}
      >
        <label htmlFor="idType" className="relative">
          {lookupInputs?.type?.label}
          <input
            id="idType"
            className="mt-2"
            placeholder={`Enter ${lookupInputs?.type?.label} here`}
            type="text"
            onChange={e =>
              setLookupInputs({
                ...lookupInputs,
                lookupValue: e.target.value,
              })
            }
          />
        </label>
        {/* <label htmlFor="first_name" className="relative mt-5">
          First name
          <input
            id="first_name"
            name="first_name"
            className="mt-2"
            type="text"
            onChange={e =>
              setLookupInputs({
                ...lookupInputs,
                firstname: e.target.value,
              })
            }
          />
        </label>

        <label htmlFor="middle_name" className="relative mt-5">
          Middle name
          <input
            id="middle_name"
            name="middle_name"
            className="mt-2"
            type="text"
            onChange={e =>
              setLookupInputs({
                ...lookupInputs,
                middlename: e.target.value,
              })
            }
          />
        </label>

        <label htmlFor="last_name" className="relative mt-5">
          Last name
          <input
            id="last_name"
            name="last_name"
            className="mt-2"
            type="text"
            onChange={e =>
              setLookupInputs({
                ...lookupInputs,
                lastname: e.target.value,
              })
            }
          />
        </label>
        {lookupInputs?.type?.value === 'passport' ? (
          <>
            <label
              htmlFor="ghana_passport_date_of_birth"
              className="relative mt-5"
            >
              Date of birth
              <input
                id="ghana_passport_date_of_birth"
                name="ghana_passport_date_of_birth"
                className="mt-2"
                type="date"
                onChange={e =>
                  setLookupInputs({
                    ...lookupInputs,
                    ghanaPassportDob: e.target.value,
                  })
                }
              />
            </label>
          </>
        ) : null} */}
      </div>
    </>
  );
}

export default connect(state => state, Actions)(Ghana);
