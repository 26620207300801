import {corruptedFile} from '../assets/images/images';

import React from 'react';

export const CreditCheckEmptyState = ({bodyText}) => (
  <div className="flex flex-col items-center justify-center py-12  bg-white80">
    <img src={corruptedFile} alt="" width={48} height={48} />
    <p className="max-w-[179px] mt-2 text-sm text-center text-body">
      {bodyText}
    </p>
  </div>
);
