import React from 'react';
import {Link} from 'react-router-dom';

function QuickActionsChecklist({
  image,
  heading,
  paragraph,
  link = '/quick-actions',
  imageComponent,
}) {
  return (
    <Link
      to={{pathname: link}}
      className="flex items-start sm:items-center gap-2"
    >
      {imageComponent ?? <img src={image} alt="" width="24" height="24" />}

      <div>
        <h5 className="text-grey font-medium">{heading}</h5>
        <p className="mt-1 text-base sm:text-sm leading-[21.9px] sm:leading-[18px] text-body">
          {paragraph}
        </p>
      </div>
    </Link>
  );
}

export default QuickActionsChecklist;
