/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useMemo, useEffect} from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import {
  Option,
  MultiValueRemove,
  DropdownIndicator,
  customStyles,
  CreateAppForm,
} from '../../../components';
import {plus, times} from '../../../assets/images/images';
import * as Actions from '../../../store/actions';

function AssignApps({
  getAllApps,
  setAppsToAssign,
  memberApps,
  apps: {allApps},
}) {
  const userApps = useMemo(
    () =>
      allApps
        ? allApps?.apps?.map(app => ({value: app._id, label: app.name}))
        : [],
    [allApps],
  );
  const memberAssignedApps = useMemo(
    () =>
      memberApps
        ? memberApps?.map(app => ({value: app._id, label: app.name}))
        : [],
    [allApps],
  );

  const [selectedApps, setSelectedApps] = useState([]);
  const [newAppForm, setNewAppForm] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const DATA = [
    {label: 'Select All', value: 'all', isActive},
    ...(userApps || []),
  ];

  useEffect(() => {
    getAllApps({limit: '500'});
  }, [getAllApps]);
  useEffect(() => {
    setSelectedApps(memberAssignedApps);
  }, [memberAssignedApps]);

  function onChange(value, event) {
    const pushData = value.map(app => app.value);
    const pullData = userApps.map(app => app.value);

    if (
      (!selectedApps.length || selectedApps.length !== DATA.slice(1).length) &&
      event.action === 'select-option' &&
      event.option.value === 'all'
    ) {
      setSelectedApps(DATA.slice(1));
      setAppsToAssign({
        pushApps: DATA.slice(1).map(app => app.value),
        pullApps: [],
      });
      setIsActive(!isActive);
    } else if (event.option?.value === 'all') {
      setSelectedApps([]);
      setAppsToAssign({pushApps: [], pullApps: pullData});
      setIsActive(false);
    } else {
      setSelectedApps(value);
      setAppsToAssign({
        pushApps: value.map(o => o.value),
        pullApps: pullData.filter(item => pushData.indexOf(item) === -1),
      });
      setSelectedApps(value);
      setAppsToAssign({
        pushApps: value.map(o => o.value),
        pullApps: pullData.filter(item => pushData.indexOf(item) === -1),
      });
      setIsActive(value.length === DATA.slice(1).length);
    }
  }

  return (
    <>
      <label htmlFor="app" className="mt-6 mb-2">
        <p>
          App
          <span className="text-grey20">{''} (optional)</span>
        </p>
      </label>
      <Select
        onChange={onChange}
        options={DATA}
        value={selectedApps}
        isMulti
        multiValueLabel
        placeholder="Select app(s)"
        className="basic-multi-select apps-multi-select"
        classNamePrefix="react-select"
        components={{
          DropdownIndicator,
          MultiValueRemove,
          Option,
        }}
        styles={customStyles}
        menuPlacement="auto"
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        allowSelectAll
      />

      <button
        className="flex items-center gap-[2px] ml-auto mt-2"
        onClick={() => setNewAppForm(prev => !prev)}
        type="button"
      >
        <img src={newAppForm ? times : plus} alt="" width={12} height={12} />
        <span className="text-xs font-medium text-body mt-1">
          {newAppForm ? 'Close' : 'Create a new app'}
        </span>
      </button>

      {newAppForm && (
        <div className="p-6 mt-2 rounded bg-white80 animate-dropdown">
          <h3 className="text-base font-medium text-grey">Create App</h3>
          <CreateAppForm setOpen={() => setNewAppForm(prev => !prev)} />
        </div>
      )}
    </>
  );
}

export default connect(state => state, Actions)(AssignApps);
