import {api} from '../../helpers';
import {WIDGETS} from '../../store/types';

export function fetchAllWidgets(params, dispatch) {
  return api.get('/users/widget/kyc', {params}).then(res => {
    const noFilter = !params?.status && !params?.start && !params?.end;
    const widgetData =
      !noFilter && res.data?.widgets.length === 0 ? undefined : res.data;
    dispatch({payload: widgetData, type: WIDGETS});
    return res.data;
  });
}

export function deleteAWidget(id) {
  return api.delete(`/users/widget/kyc/${id}`);
}
