import React from 'react';
import {CreateAppForm, Modal} from '../../components';
import {closeModal} from '../../assets/images/images';

export default function CreateAppModal({open, setOpen}) {
  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-base font-medium text-grey">Create App</h3>
          <button onClick={() => setOpen(false)}>
            <img src={closeModal} alt="" width={24} height={24} />
          </button>
        </header>

        <CreateAppForm setOpen={setOpen} />
      </div>
    </Modal>
  );
}
