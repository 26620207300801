import React from 'react';
import {dummy_id, info} from '../../../assets/images/images';

export const GovernmentID = () => (
  <>
    <header className="flex flex-col items-center my-4">
      <div className="flex items-center gap-2 p-2 mt-2 bg-white80">
        <img src={info} alt="" width={13} height={13} className="" />
        <p className="text-sm rounded-lg text-grey">
          Please make sure you are in a well lit environment
        </p>
      </div>
    </header>
    <div className="max-w-sm flex flex-col justify-center items-center ">
      <img src={dummy_id} alt="" />
      <p className="mt-4 text-center text-grey">
        Make sure the card is properly placed, and hold the card still for a few
        seconds
      </p>
    </div>{' '}
  </>
);
