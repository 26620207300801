import {api} from '../../helpers';
import {getCookieValue} from '../../helpers/getCookie';
const env = getCookieValue('env');
const environment = env === 'Production' ? 'live' : env;
const subUrl = '/reconciliation';

const uploadHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

api.interceptors.request.use(
  config => {
    const isReconcilo = config?.url?.startsWith(subUrl);
    const authDetails = JSON.parse(localStorage.getItem('state'))?.auth;
    const user = authDetails?.user;
    const rest = authDetails?.userDetails;
    const fullName = (user && `${user?.first_name} ${user?.last_name}`) || '-';
    const userId = (user && user?.id) || '-';
    const userEmail = (user && user?.email) || '-';
    const userCompany = rest?.company?.name || '-';
    const userCompanyID = rest?.company?.id || '-';
    if (isReconcilo && authDetails) {
      config.headers.name = fullName;
      config.headers['user-id'] = userId;
      config.headers.email = userEmail;
      config.headers['company-name'] = userCompany;
      config.headers['company-id'] = userCompanyID;
    }
    return config;
  },
  error => Promise.reject(error),
);

function fetchProjects(params) {
  return api
    .get(`${subUrl}/project`, {...params, env: environment?.toLowerCase()})
    .then(res => res.data);
}
function fetchProject(project_id) {
  return api.get(`${subUrl}/project/${project_id}`).then(res => res.data);
}

function addProject(data) {
  return api
    .post(`${subUrl}/project`, {...data, env: environment?.toLowerCase()})
    .then(res => res.data);
}

function fetchAccount(account_id) {
  return api.get(`${subUrl}/account/${account_id}`).then(res => res.data);
}
function relinkAccount(data) {
  return api.put(`${subUrl}/account/relink`, {...data}).then(res => res.data);
}
function fetchLinkableAccounts() {
  return api.get(`${subUrl}/account/linkable`).then(res => res.data);
}
function fetchAccounts(params) {
  return api.get(`${subUrl}/account`, {...params}).then(res => res.data);
}
function addAccount(data) {
  return api.post(`${subUrl}/account`, {...data}).then(res => res.data);
}
function linkAccount(data) {
  return api.post(`${subUrl}/account/link`, {...data}).then(res => res.data);
}

function fetchTransactions(params) {
  return api.get(`${subUrl}/transaction`, {...params}).then(res => res.data);
}
function fetchTransaction(transaction_id) {
  return api
    .get(`${subUrl}/transaction/${transaction_id}`)
    .then(res => res.data);
}
function fetchExternalTransactions(params) {
  return api
    .get(`${subUrl}/transaction/ext`, {...params})
    .then(res => res.data);
}

function addTransaction(data) {
  return api.post(`${subUrl}/transaction`, {...data}).then(res => res.data);
}
function reconcileTransaction(data) {
  return api.post(`${subUrl}/reconcile`, {...data}).then(res => res.data);
}
function uploadTransaction(formData) {
  return api
    .post(`${subUrl}/transactions/upload`, formData, {
      ...uploadHeaders,
    })
    .then(res => res.data);
}
function uploadExternalTransaction(formData) {
  return api
    .post(`${subUrl}/external-transactions/upload`, formData, {
      ...uploadHeaders,
    })
    .then(res => res.data);
}

function fetchCustomers(params) {
  return api.get(`${subUrl}/customer`, {...params}).then(res => res.data);
}
function fetchCustomer(customer_id) {
  return api.get(`${subUrl}/customer/${customer_id}`).then(res => res.data);
}
function fetchAnalysisOverview(params) {
  return api
    .get(`${subUrl}/account/analysis/overview`, {...params})
    .then(res => res.data);
}
function fetchExternalAnalysisOverview(params) {
  return api
    .get(`${subUrl}/account/analysis/overview/external`, {...params})
    .then(res => res.data);
}
function fetchAnalysisChart(params) {
  return api
    .get(`${subUrl}/account/analysis/chart`, {...params})
    .then(res => res.data);
}
function fetchAnalysisReconciliationOverview(params) {
  return api
    .get(`${subUrl}/account/analysis/recon_overview`, {...params})
    .then(res => res.data);
}
function fetchSubscriptionInfo(company_id) {
  return api.get(`${subUrl}/company/${company_id}`).then(res => res.data);
}
function fetchSubscriptionPaymentHistory(company_id) {
  return api
    .get(`${subUrl}/company/payments/${company_id}`)
    .then(res => res.data);
}

function createSubscription(data) {
  return api.post(`/subscriptions/create`, {...data}).then(res => res.data);
}
function cancelSubscription(data) {
  return api.post(`/subscriptions/cancel`, {...data}).then(res => res.data);
}
function uploadEncryptedFile(formData) {
  return api
    .post(`${subUrl}/identity/statement/headers`, formData, {
      ...uploadHeaders,
    })
    .then(res => res.data);
}
export {
  addProject,
  addAccount,
  linkAccount,
  fetchProject,
  fetchAccount,
  fetchProjects,
  relinkAccount,
  fetchCustomer,
  fetchAccounts,
  addTransaction,
  fetchCustomers,
  fetchTransaction,
  uploadTransaction,
  fetchTransactions,
  fetchAnalysisChart,
  cancelSubscription,
  createSubscription,
  uploadEncryptedFile,
  reconcileTransaction,
  fetchSubscriptionInfo,
  fetchLinkableAccounts,
  fetchAnalysisOverview,
  uploadExternalTransaction,
  fetchExternalTransactions,
  fetchExternalAnalysisOverview,
  fetchSubscriptionPaymentHistory,
  fetchAnalysisReconciliationOverview,
};
