import {Constants} from '../../helpers';
import {
  discrepanciesNav,
  projectsNav,
  settingsNav,
  transactionsNav,
  usersNav,
} from '../../assets/images/images';

const reconciloNavLinks = [
  {
    url: Constants.PAGES_URL.PROJECT_ACCOUNTS,
    imgSrc: projectsNav,
    label: 'Accounts',
  },
  {
    url: Constants.PAGES_URL.PROJECT_TRANSACTIONS,
    imgSrc: transactionsNav,
    label: 'Transactions',
  },
  {
    url: Constants.PAGES_URL.PROJECT_RECONCILIATION,
    imgSrc: discrepanciesNav,
    label: 'Reconciliation',
  },
  {
    url: Constants.PAGES_URL.PROJECT_CUSTOMERS,
    imgSrc: usersNav,
    label: 'Customers',
  },
  {
    url: Constants.PAGES_URL.PROJECT_SETTINGS,
    imgSrc: settingsNav,
    label: 'Project Settings',
  },
];

export {reconciloNavLinks};
