import React, {useMemo, useState} from 'react';
import {
  EmptyState,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  SearchInput,
  TableLayout,
} from '../../../../components';
import {profileIc} from '../../../../assets/images/images';
import Layout from '../Layout';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import {Constants} from '../../../../helpers';
import {useQuery} from 'react-query';
import {fetchAccountTransactions} from '../../../../requests/queries/flows';
import {useSelector} from 'react-redux';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';

function Accounts() {
  const {userDetails} = useSelector(state => state?.auth);
  const [currentPage, setCurrentPage] = useState('1');
  const [limit, setLimit] = useState(10);
  const {state} = useLocation();
  const {slug} = useParams();
  const navigate = useNavigate();

  const params = useMemo(
    () => ({company_id: userDetails?.company?.id}),
    [userDetails?.company?.id],
  );

  const {data: accountTransactionsData, isLoading} = useQuery(
    ['users-accounts-transactions', {params}],
    () => fetchAccountTransactions({account_id: slug, params}),
  );
  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const ACCOUNTS_TRANSACTIONS = useMemo(
    () =>
      (!isLoading &&
        accountTransactionsData &&
        accountTransactionsData?.rows) ||
      [],
    [accountTransactionsData, isLoading],
  );

  return (
    <Layout pageTitle="Account - transactions" pageIcon={profileIc}>
      <section>
        <div className="flex items-center justify-between py-7 bg-white -ml-12 pl-12 -mr-4 xl:mr-[-76px] pr-7">
          <h2 className="hidden capitalize text-lg font-medium text-grey40 sm:block">
            Transactions <span className="lowercase">for:</span>{' '}
            <span className="text-grey">{(state && state.name) || '-'}</span>
          </h2>
          <div className="flex items-center gap-4">
            <SearchInput onChange={() => []} className="outline-white80" />
            <PrimaryButton
              buttonText="View Cases"
              onClick={() =>
                navigate(Constants.PAGES_URL.FLOWS_CASES.replace(':slug', slug))
              }
            />
          </div>
        </div>
        {isLoading ? (
          <Loader height={65} />
        ) : !isLoading && ACCOUNTS_TRANSACTIONS?.length === 0 ? (
          <EmptyState
            body="No Transaction(s) found for this user"
            noBtn
            height={65}
          />
        ) : (
          <TableLayout
            negativeMargins
            negativeRightMargin="-mr-4 xl:-mr-[76px]"
          >
            <thead className="text-xs font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>Transaction ID</span>
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>Amount</span>
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>Gateway</span>
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>Date created</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm bg-white">
              {ACCOUNTS_TRANSACTIONS.map((transaction, i) => (
                <tr className="border-b border-grey60" key={i}>
                  <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                    {transaction?.id || '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {shortenLargeNumbers(transaction?.amount, true, {
                      startAt: 'B',
                    }) || '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {transaction?.transaction_type || '-'}
                  </td>{' '}
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {moment(transaction?.createdAt).format(
                      'Do MMM YYYY hh:mm',
                    ) || '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </TableLayout>
        )}

        {!isLoading && ACCOUNTS_TRANSACTIONS.length > 0 && (
          <div className="flex items-center justify-between flex-wrap gap-4 sm:gap-0 mt-8">
            <div className="flex items-center text-body text-tiny">
              <PageLimit
                onLimitChange={setLimit}
                total={ACCOUNTS_TRANSACTIONS.length}
                length={ACCOUNTS_TRANSACTIONS.length}
                limit={limit}
              />
            </div>

            <div className="mb-8 sm:mb-0">
              <Pagination
                total={Math.ceil(ACCOUNTS_TRANSACTIONS.length / limit)}
                current={+currentPage}
                onPageChange={activePage => {
                  localStorage.setItem('page', String(activePage));
                  pageClick(activePage);
                }}
              />
            </div>
          </div>
        )}
      </section>
    </Layout>
  );
}

export default Accounts;
