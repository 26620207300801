/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {Modal, VerificationToggle} from '../../../components';

import * as Actions from '../../../store/actions';
import {toast} from 'react-toastify';
import {Constants} from '../../../helpers';
import CountryInput from '../../../components/CountryInput';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../../helpers/formatNumberToCurrency';

function BusinessData({
  widgets: {widgetScreen, widgetPages, widgetPricing},
  auth: {userDetails, userSettings},
  setWidgetScreen,
  setWidgetPages,
  setWidgetCurrentPricing,
  ...otherProps
}) {
  const {open, setOpen} = otherProps;
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [update, setUpdate] = useState(true);
  const [pricing, setPricing] = useState({
    services: {},
  });

  const [config, setConfig] = useState({
    cac: true,
    // tin: true,
  });

  const displayPrice = price =>
    userDetails && userDetails.company && userDetails.company.currency === 'USD'
      ? formatUsd(price || 0)
      : formatNumberToCurrency(
          price || 0,
          userDetails && userDetails.company && userDetails.company.currency,
          userSettings &&
            userSettings.settings &&
            userSettings.settings.dollar_exchange_rate,
        );

  useEffect(() => {
    const isActive =
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.page === 'business-data' &&
          data[`${data?.page}-${data?.pageId}`],
      );
    if (isActive) {
      for (const key in config) {
        if (typeof config[key] === 'boolean') {
          setPricing(prev => {
            const price =
              widgetPricing && widgetPricing['business-data']?.services[key];
            if (config[key] === true) {
              return {
                services: {
                  ...{
                    ...prev.services,
                    [key]: price / 100,
                  },
                },
              };
            } else {
              delete prev.services[key];
              return prev;
            }
          });
        }
      }
      setWidgetCurrentPricing({
        'business-data': pricing,
      });
    } else {
      setWidgetCurrentPricing({
        'business-data': {},
      });
    }
    return () =>
      setWidgetCurrentPricing({
        'business-data': {},
      });
  }, [update, widgetPages, setWidgetCurrentPricing, config]);

  const foundWidget = useMemo(
    () =>
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.pageId === widgetScreen?.pageId &&
          data?.page === 'business-data',
      ),
    [widgetPages, widgetScreen],
  );

  const ALL_PAGES = JSON.parse(localStorage.getItem('pages'));
  const updateConfig = newConfig => {
    const newPages = ALL_PAGES.map(page => {
      if (page?.page === foundWidget?.page) {
        return {
          ...page,
          config: newConfig,
        };
      }
      return page;
    });

    localStorage.setItem('pages', JSON.stringify(newPages));
  };

  useEffect(() => {
    if (foundWidget) {
      if (Object.values(foundWidget?.config || {}).length === 0) {
        setConfig({...config});
        setUpdate(false);
        setWidgetPages({
          ...foundWidget,
          config: {...config},
        });
        updateConfig({...config});
      } else {
        setConfig({...config, ...foundWidget?.config});
        setWidgetPages({
          ...foundWidget,
          config: {...config, ...foundWidget?.config},
        });
        updateConfig({...config, ...foundWidget?.config});
      }
    }
  }, [widgetScreen, update]);

  const handleUpdateConfig = (key, value) => {
    const newData =
      widgetPages &&
      widgetPages?.find(data => data?.pageId === widgetScreen?.pageId);
    if (!newData) {
      return toast.error(
        'Ops! Please toggle ON before configuring this widget',
      );
    } else {
      setConfig({...config, ...widgetScreen?.config, [key]: value});
      setWidgetScreen({
        ...widgetScreen,
        config: {...widgetScreen.config, [key]: value},
      });

      setWidgetPages({
        ...newData,
        config: {...newData?.config, [key]: value},
      });
    }
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[339px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Configuration"
    >
      <p className="text-left text-body text-xs mt-8 font-medium uppercase">
        Select Business Data to display
      </p>

      <CountryInput
        defaultValue={selectedCountry}
        options={[Constants.lookups.countries[0]]}
        onChange={country => setSelectedCountry(country)}
        label="Select country"
      />

      <div className="mt-5 flex flex-col justify-between">
        {Constants.lookups?.businessData &&
          Constants.lookups?.businessData?.map(type => (
            <div
              key={type.value}
              className="flex items-center gap-2 pl-4 pr-2 py-[10px] cursor-pointer"
            >
              <VerificationToggle
                isChecked={config[type.value]}
                onCheck={value => handleUpdateConfig(type.value, value)}
              />
              <div className="flex justify-between w-40">
                <p className="text-xs leading-[18px] text-body">
                  {type?.label}
                </p>
                <p className="text-xs ml-4 leading-[18px] text-grey20">
                  {displayPrice(
                    widgetPricing &&
                      widgetPricing['business-data']?.services[type.value] /
                        100,
                  )}
                </p>
              </div>
            </div>
          ))}
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(BusinessData);
