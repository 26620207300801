import moment from 'moment';
import {toast} from 'react-toastify';
import reduxApi from '../../helpers/reduxApi';
import {transformLogsGraphData} from '../../helpers/transforApiLogsChart';
import {
  API_LOGS,
  API_LOGS_SUMMARY,
  API_LOGS_LOADING,
  API_LOGS_GRAPH,
  API_LOGS_FILTERS,
  EXPORT_LOADING,
} from '../types';

export const start = `${moment(
  new Date().setDate(new Date().getDate() - 31),
).format('YYYY-MM-DD')} 00:00:00`;
export const end = `${moment(new Date()).format('YYYY-MM-DD')} 23:59:59`;

export const getApiLogs = (data, others) =>
  reduxApi(
    `/logs/services?page=${data?.page || 1}&limit=${data?.limit || 10}${
      data?.start ? `&start=${data.start}` : ''
    }${data?.end ? `&end=${data.end}` : ''}${
      data?.apps ? `&apps=${data?.apps}` : ''
    }&${others || ''}`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: API_LOGS_LOADING, responder: API_LOGS},
  );

export const getApiLogsFilters = () =>
  reduxApi(
    `/logs/services/filters`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: API_LOGS_LOADING, responder: API_LOGS_FILTERS},
  );
export const getApiLogsSummary = (data, others) =>
  reduxApi(
    `/logs/services/summary?${data?.start ? `start=${data.start}` : ''}${
      data?.end ? `&end=${data.end}&` : ''
    }${data?.apps ? `&apps=${data.apps}&` : ''}${others || ''}`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: API_LOGS_LOADING, responder: API_LOGS_SUMMARY},
  );

export const exportApiLogs = ({apps, services, start, end, priced}) =>
  reduxApi(
    `/logs/v3/services/export?page=1${apps ? `&apps=${apps}` : ''}${
      services ? `&services=${services}` : ''
    }${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}${
      priced ? '&priced=true' : ''
    }`,
    'GET',
    null,
    {
      report: true,
      success: data => {
        toast.success(data?.message);
      },
    },
    {error: 'ERROR', loading: EXPORT_LOADING, responder: 'API_LOGSS'},
  );
export const getApiLogsChart = (data, others) =>
  reduxApi(
    `/logs/v2/services/graph?start=${data?.start || start}&end=${
      data?.end || end
    }&${others || ''}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        const resData = transformLogsGraphData(
          data.logs,
          data?.start || start,
          data?.end || end,
        );

        dispatch({payload: resData, type: API_LOGS_GRAPH});
      },
    },
    {error: 'ERROR', loading: API_LOGS_LOADING, responder: 'API_LOGSS'},
  );
