import React from 'react';
import {Link} from 'react-router-dom';

export default function QuickActionsCard({
  image,
  heading,
  paragraph,
  linkText,
  link = '/',
  disabled = false,
  imageComponent,
}) {
  return (
    <Link
      to={{pathname: link}}
      className={`p-4 basis-full bg-white rounded cursor-pointer grow transition-all duration-200 border border-white border-opacity-0 active:border-opacity-10 active:border-brandBlue hover:border-opacity-70 hover:border-brandBlue ${
        disabled ? 'opacity-50 pointer-events-none' : ''
      }`}
    >
      <div className="basis-1/3">
        {imageComponent ?? <img src={image} alt="" width="32" height="32" />}
        <h3 className="mt-4 text-grey sm:text-body font-medium">{heading}</h3>
        <p className="h-9 max-h-full mt-1 text-base sm:text-sm text-body">
          {paragraph}
        </p>
        <p className="mt-4 text-base sm:text-sm text-brandBlue font-medium">
          {linkText}
        </p>
      </div>
    </Link>
  );
}
