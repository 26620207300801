import React from 'react';
import {Link} from 'react-router-dom';
import {notFound} from '../assets/images/images';
import {
  DashboardLayout,
  PrimaryButton,
  SecondaryButton,
  Sidebar,
} from '../components';

export default function NotFound() {
  return (
    <>
      <Sidebar />
      <DashboardLayout topPadding="0" xlLeftMargin="0" xlRightPadding="0">
        <div
          className="flex flex-col items-center justify-center gap-4 m-auto text-center"
          style={{
            height: 'calc(100vh - 64px)',
          }}
        >
          <img src={notFound} alt="" width={144} height={144} />
          <h3 className="text-xxl text-grey">Aish! Something is broken</h3>

          <div className="flex items-center gap-4">
            <Link to="/">
              <PrimaryButton buttonText="Go back to Dashboard" />
            </Link>
            <Link to="/">
              <SecondaryButton buttonText="Contact support" />
            </Link>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}
