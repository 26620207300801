import React from 'react';

export const Address = () => (
  <>
    <header className="flex flex-col items-center mt-2">
      <h4 className="mt-4 font-medium text-grey">Address information</h4>
    </header>
    <form className="w-full max-w-sm">
      <label htmlFor="address" className="mt-4 mb-1">
        Address
        <input type="text" id="address" className="mt-2 text-sm" />
      </label>
    </form>
  </>
);
