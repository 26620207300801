/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {Modal, VerificationToggle} from '../../../components';
import * as Actions from '../../../store/actions';

function BusinessID({
  widgets: {widgetScreen, widgetPages},
  setWidgetCurrentPricing,
  setWidgetScreen,
  setWidgetPages,
  ...otherProps
}) {
  const {open, setOpen} = otherProps;
  const [update, setUpdate] = useState(true);

  const [config, setConfig] = useState({
    hideUpload: false,
  });

  const foundWidget = useMemo(
    () =>
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.pageId === widgetScreen?.pageId && data?.page === 'business-id',
      ),
    [widgetPages, widgetScreen],
  );
  const updateConfig = useCallback(
    newConfig => {
      const ALL_PAGES = JSON.parse(localStorage.getItem('pages'));
      const newPages = ALL_PAGES.map(page => {
        if (page?.page === foundWidget?.page) {
          return {
            ...page,
            config: newConfig,
          };
        }
        return page;
      });

      localStorage.setItem('pages', JSON.stringify(newPages));
    },
    [foundWidget],
  );

  useEffect(() => {
    if (foundWidget) {
      if (Object.values(foundWidget?.config || {})?.length === 0) {
        setConfig({...config});
        setUpdate(false);
        setWidgetPages({
          ...foundWidget,
          config: {...config},
        });
        updateConfig({...config});
      } else {
        setConfig({...config, ...foundWidget?.config});
        setWidgetPages({
          ...foundWidget,
          config: {...config, ...foundWidget?.config},
        });
        updateConfig({...config, ...foundWidget?.config});
      }
    }
  }, [widgetScreen, update]);

  const checkConfig = (type, value, newData) => {
    const {config: currentConfig} = widgetScreen;
    const updatedConfig = {
      ...currentConfig,
      [type]: value,
    };

    setConfig(updatedConfig);
    setWidgetScreen({...widgetScreen, config: updatedConfig});
    setWidgetPages({...newData, config: updatedConfig});
  };

  const handleUpdateConfig = useCallback(
    (type, value) => {
      const newData = widgetPages?.find(
        data => data?.pageId === widgetScreen?.pageId,
      );
      if (!newData) {
        return toast.error(
          'Ops! Please toggle ON before configuring this widget',
        );
      } else {
        checkConfig(type, value, newData);
      }
    },
    [config],
  );

  return (
    <Modal
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[339px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Configuration"
    >
      <div>
        <p className="text-left text-body text-xs mt-8 mb-2 font-medium uppercase">
          FILE UPLOAD
        </p>

        <div className="flex items-center gap-2 pl-4 pr-2 py-[10px] cursor-pointer">
          <VerificationToggle
            isChecked={!config?.hideUpload}
            onCheck={value => handleUpdateConfig('hideUpload', !value)}
          />
          <div className="flex justify-between w-48">
            <p className="text-xs leading-[18px] text-body">
              Enable file upload
            </p>
            {/*<p className="text-xs ml-4 leading-[18px] text-grey20">*/}
            {/*  {displayPrice(*/}
            {/*    widgetPricing && widgetPricing?.id?.services?.base / 100,*/}
            {/*  )}*/}
            {/*</p>*/}
          </div>
        </div>
        <p className="text-left text-grey40 text-xs mt-2 mb-2 leading-5 ">
          Please note that when toggled off user will ONLY be able to capture
          ID/Document with device camera
        </p>
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(BusinessID);
