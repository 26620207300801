import React from 'react';
import {corruptedFile} from '../../../../../assets/images/images';

export const LowBalance = () => (
  <div className="mt-6">
    <div className="w-full flex justify-center">
      <img src={corruptedFile} alt="low-balance" />
    </div>
    <h2 className="text-grey font-medium text-base text-center">
      Insufficient balance
    </h2>
    <p className="text-body text-sm max-w-[242px] m-auto my-2">
      Your current balance is low, transfer is NOT successful
    </p>
  </div>
);
