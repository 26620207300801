import {
  LOADING,
  LOOKUP_HISTORY,
  BANK_CODES,
  LOOKUP,
  LOOKUP_PAGE,
  LOOKUP_APP_ID,
  BATCH_LOOKUP,
  DOWNLOADING,
  L_LOADING,
  H_LOADING,
  UPDATED,
  SINGLE_BATCH,
  BUSINESS_LOOKUP_HISTORY,
  BATCH_BUSINESS_LOOKUP,
} from '../types';

const initialState = {
  banks: null,
  loading: false,
  history: null,
  businessLookup: null,
  batchBusinessLookup: null,
  lookup: null,
  batchLookup: null,
  singleBatch: null,
  appId: '',
  lookupPage: 1,
  updated: false,
};

export default function lookup(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {...state, loading: action.payload};
    case L_LOADING:
      return {...state, l_loading: action.payload};
    case H_LOADING:
      return {...state, h_loading: action.payload};
    case UPDATED:
      return {...state, updated: !state.updated};
    case LOOKUP:
      return {...state, lookup: action.payload};
    case SINGLE_BATCH:
      return {...state, singleBatch: action.payload};

    case LOOKUP_PAGE:
      return {
        ...state,
        lookupPage: action.payload
          ? action.payload
          : state.lookupPage === 3
          ? 3
          : state.lookupPage + 1,
      };

    case LOOKUP_HISTORY:
      return {...state, history: action.payload};

    case BUSINESS_LOOKUP_HISTORY:
      return {...state, businessLookup: action.payload};

    case BATCH_LOOKUP:
      return {...state, batchLookup: action.payload};

    case BATCH_BUSINESS_LOOKUP:
      return {...state, batchBusinessLookup: action.payload};

    case BANK_CODES:
      return {
        ...state,
        banks: action.payload?.entity?.sort((a, b) =>
          a?.name?.localeCompare(b?.name),
        ),
      };

    case LOOKUP_APP_ID:
      return {...state, appId: action.payload};

    case DOWNLOADING:
      return {...state, loading: action.payload};

    default:
      return state;
  }
}
