/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {isObjectEmpty} from '../../../helpers/isObjectEmpty';

const GovernmentData = ({
  widgets: {widgetPages, singleWidget, widgetScreen},
}) => {
  const widgetData =
    widgetPages &&
    widgetPages?.find(
      widget =>
        widget?.page === 'government-data' &&
        widget?.pageId === widgetScreen?.pageId,
    );

  const data = useMemo(
    () => ({
      ...widgetData?.config,
      mobile: widgetData?.config?.mobile,
    }),
    [
      widgetData?.config?.mobile,
      widgetData?.config?.selfie,
      widgetData?.config?.otp,
      widgetData?.config?.bvn,
      widgetData?.config?.vnin,
      widgetData?.config?.dl,
      widgetData?.config?.type,
    ],
  );

  const color = singleWidget?.widget?.app?.color_code;

  return (
    <>
      <header className="flex flex-col items-center mt-2">
        <h4 className="mt-4 font-medium text-grey">Government Data</h4>
      </header>
      <form className="w-full max-w-sm mt-10">
        <label htmlFor="address" className="mb-1">
          Government data
        </label>
        <select name="" id="" className="text-sm font-medium text-body">
          {data?.bvn && <option value="">Bank Verification Number</option>}
          {data?.nin && (
            <option value="">National Identification Number</option>
          )}
          {data?.dl && <option value="">Driver&apos;s License</option>}
          {data?.mobile && <option value="">Phone Number</option>}
        </select>
        <label htmlFor="id_type" className="mt-10 mb-1">
          ID Number
          <input
            type="text"
            id="id_type"
            value="10253665114"
            readOnly
            className="mt-2 text-sm"
          />
        </label>
        <label htmlFor="verification_type" className="mt-10 mb-4">
          Verify with
        </label>
        <div className="flex items-center w-full justify-between">
          {!data || isObjectEmpty(data) ? (
            <>
              <input
                type="checkbox"
                name="type"
                id="otp"
                checked={true}
                className="widget-radios"
                onChange={() => []}
              />
              <label
                style={{
                  border: data?.otp && `1px solid ${color}`,
                }}
                htmlFor="otp"
              >
                OTP (One-time Password)
              </label>
            </>
          ) : (
            data &&
            (data?.otp || data?.bvnAdvance) && (
              <>
                <input
                  type="checkbox"
                  name="type"
                  id="otp"
                  checked={true}
                  className="widget-radios"
                  onChange={() => []}
                />
                <label
                  style={{
                    border: data?.otp && `1px solid ${color}`,
                  }}
                  htmlFor="otp"
                  className="text-xs"
                >
                  OTP (One-time Password)
                </label>
              </>
            )
          )}

          {!data || isObjectEmpty(data) ? (
            <>
              <input
                type="checkbox"
                name="type"
                id="selfie"
                checked={true}
                className="widget-radios text-xs"
                onChange={() => []}
              />
              <label
                style={{
                  border: data?.type && `1px solid ${color}`,
                }}
                htmlFor="selfie"
                className="text-xs"
              >
                Take a {data.type === 'basic' ? 'selfie' : 'video'}
              </label>
            </>
          ) : (
            data &&
            data?.type && (
              <>
                <input
                  type="checkbox"
                  name="type"
                  id="selfie"
                  checked={true}
                  className="widget-radios text-sm"
                  onChange={() => []}
                />
                <label
                  style={{
                    border: data?.type && `1px solid ${color}`,
                  }}
                  htmlFor="selfie"
                  className="text-xs"
                >
                  Take a {data.type === 'basic' ? 'selfie' : 'video'}
                </label>
              </>
            )
          )}
        </div>
      </form>
    </>
  );
};

export default connect(state => state, null)(GovernmentData);
