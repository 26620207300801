import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {toggleArrayValue} from '../helpers/customDateFilters';
import OptionAccordion from './OptionAccordion';
import DateAccordion from './DateAccordion';
import FiltersWrapper from './FiltersWrapper';
import {renderServices} from '../helpers/renderServicies';

export default function Filter({
  openFilter,
  setOpenFilter,
  handleFilter,
  resetUrl,
  filterOptions = [],
  hideDateFilter,
  customClasses,
}) {
  const [selectedTimeOption, setSelectedTimeOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(() =>
    filterOptions.reduce((acc, option) => {
      acc[option?.name] = [];
      return acc;
    }, {}),
  );
  const [accordions, setAccordions] = useState(() =>
    filterOptions.reduce(
      (acc, option) => {
        acc[option.name] = false;
        return acc;
      },
      {date: false},
    ),
  );
  const [dateFilter, setDateFilter] = useState({
    start: '',
    end: '',
  });

  const handleToggleOption = (optionName, value) => {
    setSelectedOptions(prevSelected => ({
      ...prevSelected,
      [optionName]: toggleArrayValue(prevSelected[optionName] || [], value),
    }));
  };

  const toggleAccordion = accordionName => {
    setAccordions(prevAccordions => ({
      ...Object.keys(prevAccordions || {})?.reduce((acc, name) => {
        acc[name] = name === accordionName;
        return acc;
      }, {}),
    }));
  };

  const handleApplyClick = () => {
    const selectedOptionsToSend = {
      ...dateFilter,
      ...filterOptions.reduce((acc, option) => {
        acc[option.name] = selectedOptions[option.name] || [];
        return acc;
      }, {}),
    };

    const allValuesEmpty = Object.values(selectedOptionsToSend).every(value => {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else if (typeof value === 'string') {
        return value.trim() === '';
      }
      return false;
    });

    if (allValuesEmpty) {
      toast.error('Select an item to filter by');
      return;
    }

    handleFilter(selectedOptionsToSend);
  };

  const handleResetFilters = () => {
    setSelectedOptions(() =>
      filterOptions.reduce((acc, option) => {
        acc[option.name] = [];
        return acc;
      }, {}),
    );
    setAccordions(() =>
      filterOptions.reduce(
        (acc, option) => {
          acc[option.name] = false;
          return acc;
        },
        {date: false},
      ),
    );
    setDateFilter({
      start: '',
      end: '',
    });
    setSelectedTimeOption(null);
    resetUrl();
  };

  const labelClassName =
    'relative flex flex-row gap-2 font-normal transition duration-500 ease-in-out cursor-pointer';
  const inputClassName =
    'w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue';

  return (
    <FiltersWrapper
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      onResetFilters={handleResetFilters}
      handleFilter={handleApplyClick}
      customClasses={customClasses}
    >
      <DateAccordion
        accordions={accordions}
        toggleAccordion={toggleAccordion}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        selectedTimeOption={selectedTimeOption}
        setSelectedTimeOption={setSelectedTimeOption}
        hideDateFilter={hideDateFilter}
      />

      {filterOptions?.map(option => (
        <OptionAccordion
          key={option?.name}
          title={option?.title}
          count={(selectedOptions[option?.name] || [])?.length}
          isOpen={accordions[option?.name]}
          onAccordionClick={() => toggleAccordion(option?.name)}
        >
          {option?.values &&
            Array.isArray(option?.values) &&
            option?.values?.map(value => (
              <label
                key={`${option.name}-${value.value}`}
                className={labelClassName}
              >
                <input
                  className={inputClassName}
                  type="checkbox"
                  name={option.name}
                  value={value.value}
                  checked={selectedOptions[option.name]?.includes(value.value)}
                  onChange={() => handleToggleOption(option.name, value.value)}
                />
                {option.name === 'services' || option.name === 'service'
                  ? renderServices(value.label)
                  : value.label}
              </label>
            ))}
        </OptionAccordion>
      ))}
    </FiltersWrapper>
  );
}
