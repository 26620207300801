import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {
  Modal,
  DropdownIndicator,
  customStyles,
  PrimaryButton,
} from '../../../components';

const LIST_TYPES = [
  {
    label: 'Country',
    value: 'country',
  },
  {
    label: 'Others',
    value: 'others',
  },
];

export default function EditListModal({
  updateList,
  loading,
  open,
  setOpen,
  list = {},
  listItems,
  setListItems,
}) {
  const [type, setType] = useState(null);
  const [currentList, setCurrentList] = useState({...list});

  // eslint-disable-next-line no-unused-vars
  const {_id, __v, company, createdAt, count, updatedAt, ...data} =
    currentList || {};

  useEffect(() => {
    setCurrentList(list);
  }, [list]);

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Edit list information"
    >
      <form className="mt-6 text-left">
        <label htmlFor="name" className="mt-6">
          <span className="text-sm ">List name</span>
          <input
            type="text"
            id="name"
            className="mt-2"
            placeholder="Input value"
            value={currentList?.name}
            onChange={e =>
              setCurrentList({...currentList, name: e.target.value})
            }
          />
        </label>

        <label htmlFor="edit_list_type" className="mt-6 mb-2 relative">
          Type
        </label>
        <Select
          defaultValue={type}
          onChange={data => {
            setType(data);
            setCurrentList({...currentList, type: data?.value});
          }}
          options={LIST_TYPES}
          placeholder="Select type"
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          id="edit_list_type"
          aria-label="Edit List type"
        />

        <label htmlFor="edit_list_description" className="mt-6 relative">
          <p>
            Description <span className="text-grey30">(optional)</span>
          </p>
          <textarea
            id="edit_list_description"
            className="mt-2 min-h-[73px] h-full"
            placeholder="Edit description"
            aria-label="Edit description"
            onChange={e => {
              setCurrentList({...currentList, description: e.target.value});
            }}
          />
        </label>

        <div className="flex items-center mt-6">
          <PrimaryButton
            onClick={async () => {
              const updatedList = listItems.map(item => {
                if (item._id === _id) {
                  return {
                    ...item,
                    ...currentList,
                  };
                }
                return item;
              });
              setListItems([...updatedList]);
              await updateList({listId: _id, ...data});
              setOpen(false);
            }}
            buttonText="Save"
            className="w-full"
            loading={loading}
            disabled={loading}
          />
        </div>
      </form>
    </Modal>
  );
}
