import React, {useState, useMemo} from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import axios from 'axios';
import {toast} from 'react-toastify';
import {Buffer} from 'buffer';
import {Constants, signUpIndustry, token} from '../../helpers';
import * as Actions from '../../store/actions';
import SettingsLayout from './SettingsLayout';
import {
  DropdownIndicator,
  PrimaryButton,
  CountryInput,
  DragAndDrop,
} from '../../components';
import {complianceIc, verifiedBadge} from '../../assets/images/images';

const SettingsCompliance = ({
  auth: {userDetails, loading},
  updateCompanyInfo,
}) => {
  const defaultIndustry = useMemo(
    () =>
      signUpIndustry.find(c => c.value === userDetails?.company?.industry) ||
      signUpIndustry[0],
    [userDetails?.company.industry],
  );

  const defaultCountry = useMemo(
    () =>
      Constants.lookups.countries.find(
        c => c.value === userDetails?.company?.country,
      ) || Constants.lookups.countries[0],
    [userDetails?.company?.country],
  );

  const [uploading, setUploading] = useState(false);
  const [state, setState] = useState({
    company_name: userDetails?.company?.name || '',
    rc_number: userDetails?.company?.rc_number || '',
    rc_number_upload: userDetails?.company?.rc_number_upload || '',
    rc_number_upload_filename:
      userDetails?.company?.rc_number_upload_filename || '',
    company_address: userDetails?.company?.address || '',
    industry: defaultIndustry || signUpIndustry[0],
    website: userDetails?.company?.website || '',
    country: defaultCountry || Constants.lookups.countries[0],
    state: userDetails?.company?.state || '',
    sla: userDetails?.company?.sla || '',
    sla_id: userDetails?.company?.sla_id || '',
    sla_id_filename: userDetails?.company?.sla_id_filename || '',
  });
  const [missingFields, setMissingFields] = useState(false);

  const handleSubmit = () => {
    setMissingFields(false);
    const formComplete = Object.keys(state).every(
      k =>
        ['website', 'rc_number_upload', 'sla_id'].includes(k) ||
        Boolean(state[k]),
    );
    if (!formComplete) {
      setMissingFields(true);
      toast.error('Please complete all mandatory fields');
      return;
    }

    updateCompanyInfo(
      Object.entries({
        ...state,
        ...(state.country && {country: state.country.value}),
        ...(state.industry && {industry: state.industry.value}),
      }).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {}),
    );
  };

  const handleChange = e => {
    const {name, value} = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileUpload = (e, name, fileName, type) => {
    const {name: targetName} = e.target;
    const reader = new FileReader();
    const file = e?.dataTransfer?.files[0] || e.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = function () {
      setUploading(true);

      const decoded = Buffer.from(reader.result.split(',')[1], 'base64');
      if (decoded.length >= 1024 ** 2) {
        setUploading(false);
        return toast.error('Your file should not be greater than 1MB');
      }

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/users/upload`,
          {
            ...(file.type.includes('pdf')
              ? {pdf: reader.result}
              : {image: reader.result}),
            type,
          },
          {
            headers: {
              Authorization: token.get(),
            },
          },
        )
        .then(res => {
          setUploading(false);
          setState(prevState => ({
            ...prevState,
            ...(name
              ? {
                  [name]: res.data.image || res.data.pdf,
                  [`${name}_filename`]: fileName,
                }
              : {
                  [targetName]: res.data.image || res.data.pdf,
                  [`${targetName}_filename`]: fileName,
                }),
          }));
        });
    };
  };

  const {updateCompliance: canUpdateCompliance} =
    useMemo(
      () => userDetails?.permissions?.settingsPermissions,
      [userDetails?.permissions],
    ) || {};

  return (
    <SettingsLayout
      pageTitle="Compliance"
      pageIcon={complianceIc}
      action={
        !userDetails?.company?.verified ? (
          <PrimaryButton
            buttonText={
              userDetails?.company?.hasRequestedVerification
                ? 'Update verification'
                : 'Request verification'
            }
            xPadding="px-4"
            className="hidden sm:flex"
            loading={loading || uploading}
            onClick={handleSubmit}
            disabled={!canUpdateCompliance}
            style={{cursor: !canUpdateCompliance && 'not-allowed'}}
            title={
              canUpdateCompliance
                ? 'View Live Keys'
                : 'You do not have permission to update compliance'
            }
          />
        ) : (
          <div className="bg-brandBlue flex items-end gap-2 px-2 py-1 rounded-full">
            <img src={verifiedBadge} alt="" width={14} height={14} />
            <span className="text-white text-xs font-medium uppercase">
              account verified
            </span>
          </div>
        )
      }
    >
      <section className="mt-8 md:mt-10">
        <form>
          <div className="flex items-start flex-wrap md:flex-nowrap gap-8 md:gap-6 w-full">
            <div className="w-full flex flex-col">
              <div className="w-full">
                <h3 className="text-body text-xs font-medium uppercase">
                  company address
                </h3>

                <div className="mt-4 md:mt-6 p-4 md:p-6 bg-white rounded">
                  <CountryInput
                    onChange={value => {
                      setState(prevState => ({
                        ...prevState,
                        country: value,
                      }));
                    }}
                    label="Country"
                    value={state.country}
                    defaultValue={defaultCountry}
                    isDisabled={userDetails?.company?.verified}
                    styles={{
                      control: base => ({
                        ...base,
                        backgroundColor: '#F9F9F9',
                        border: `1px solid ${
                          missingFields && !state.country
                            ? '#F3787A'
                            : '#CECED2'
                        }`,
                        boxShadow: 'none',
                      }),
                    }}
                  />
                  <label htmlFor="state" className="mt-6 mb-6">
                    State
                    <input
                      type="text"
                      id="state"
                      name="state"
                      className={`mt-2 ${
                        missingFields && !state.state ? 'outline-red' : ''
                      }`}
                      placeholder="Lagos"
                      value={state.state}
                      onChange={handleChange}
                      disabled={userDetails?.company?.verified}
                    />
                  </label>
                  <label htmlFor="address" className="mt-6">
                    Address
                    <input
                      type="text"
                      id="address"
                      name="company_address"
                      className={`mt-2 ${
                        missingFields && !state.company_address
                          ? 'outline-red'
                          : ''
                      }`}
                      placeholder="3, Olorunfunmi Street, Off Association Avenue"
                      value={state.company_address}
                      onChange={handleChange}
                      disabled={userDetails?.company?.verified}
                    />
                  </label>
                </div>
              </div>

              <div className="w-full mt-8 md:mt-12">
                <h3 className="text-body text-xs font-medium uppercase">
                  SLA Details
                </h3>

                <div className="mt-4 md:mt-6 p-4 md:p-6 bg-white rounded">
                  <label htmlFor="name">
                    Name
                    <input
                      type="text"
                      id="name"
                      name="sla"
                      className={`mt-2 ${
                        missingFields && !state.sla ? 'outline-red' : ''
                      }`}
                      placeholder="Name of person to sign SLA"
                      value={state.sla}
                      onChange={handleChange}
                      disabled={userDetails?.company?.verified}
                    />
                  </label>

                  <label htmlFor="signee_id_file" className="mt-6">
                    ID of SLA signee
                  </label>
                  <DragAndDrop
                    name="sla_id"
                    accept="image/jpeg, image/png, application/pdf"
                    text="an image or a PDF"
                    mt={2}
                    handleFileUpload={handleFileUpload}
                    error={
                      missingFields &&
                      !state.sla_id &&
                      !userDetails?.company?.sla_id_filename
                    }
                    disabled={userDetails?.company?.verified}
                    fileName={state.sla_id_filename}
                  />
                </div>
              </div>
            </div>

            <div className="w-full">
              <h3 className="text-body text-xs font-medium uppercase">
                Company information
              </h3>

              <div className="mt-4 md:mt-6 p-4 md:p-6 bg-white rounded">
                <label htmlFor="company_name">
                  Company name
                  <input
                    type="text"
                    id="company_name"
                    name="company_name"
                    className={`mt-2 ${
                      missingFields && !state.company_name ? 'outline-red' : ''
                    }`}
                    placeholder="Dojah Inc."
                    value={state.company_name}
                    onChange={handleChange}
                    disabled={userDetails?.company?.verified}
                  />
                </label>
                <label htmlFor="registration_proof" className="mt-6 w-full">
                  Proof of registration
                  <input
                    type="number"
                    id="registration_proof"
                    name="rc_number"
                    className={`mt-2 ${
                      missingFields && !state.rc_number ? 'outline-red' : ''
                    }`}
                    placeholder="7682534789238462345383"
                    value={state.rc_number}
                    onChange={handleChange}
                    disabled={userDetails?.company?.verified}
                  />
                </label>

                <DragAndDrop
                  name="rc_number_upload"
                  accept="image/jpeg, image/png, application/pdf"
                  text="an image or a PDF"
                  handleFileUpload={handleFileUpload}
                  error={
                    missingFields &&
                    !state.rc_number_upload &&
                    !userDetails?.company.rc_number_upload_filename
                  }
                  disabled={userDetails?.company?.verified}
                  fileName={state.rc_number_upload_filename}
                />

                <label htmlFor="website" className="mt-6 w-full">
                  Website (optional)
                  <input
                    type="text"
                    id="website"
                    name="website"
                    className="mt-2"
                    placeholder="dojah.io"
                    value={state.website}
                    onChange={handleChange}
                    disabled={userDetails?.company?.verified}
                  />
                </label>

                <label htmlFor="industry" className="mt-6 mb-2 relative">
                  Industry
                </label>
                <Select
                  onChange={value => {
                    setState(prevState => ({
                      ...prevState,
                      industry: value,
                    }));
                  }}
                  options={signUpIndustry}
                  placeholder="Select industry"
                  components={{DropdownIndicator}}
                  classNamePrefix="react-select"
                  styles={{
                    control: base => ({
                      ...base,
                      backgroundColor: '#F9F9F9',
                      border: `1px solid ${
                        missingFields && !state.industry ? '#F3787A' : '#CECED2'
                      }`,
                      boxShadow: 'none',
                    }),
                  }}
                  name="industry"
                  value={state.industry}
                  defaultValue={defaultIndustry}
                  isDisabled={userDetails?.company?.verified}
                />
              </div>
            </div>
          </div>
          <PrimaryButton
            buttonText={
              userDetails?.company.hasRequestedVerification
                ? 'Update verification'
                : 'Request verification'
            }
            xPadding="block sm:hidden w-full mt-6"
            loading={loading || uploading}
            onClick={handleSubmit}
          />
        </form>
      </section>
    </SettingsLayout>
  );
};

export default connect(state => state, Actions)(SettingsCompliance);
