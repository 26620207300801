import moment from 'moment';
import React, {useCallback, useMemo, useState} from 'react';
import Chart from 'react-apexcharts';
import {connect} from 'react-redux';
import {calendar, dropdownGrey} from '../../../assets/images/images';
import {
  Checkboxes,
  CustomDateRadio,
  Dropdown,
  Loader,
  Radios,
} from '../../../components';
import {Constants} from '../../../helpers';
import {CheckboxContextProvider} from '../../../helpers/context/CheckboxContext';
import {customDateFilters} from '../../../helpers/customDateFilters';
import * as Actions from '../../../store/actions';

function LineChart({...otherProps}) {
  const {
    setFilters,
    filters: currentFilters,
    graphContent = [],
    loading,
    flows = {},
  } = otherProps;

  const FLOW_DATA = flows?.flows?.flows?.map(flow => ({
    label: flow?.name,
    value: flow._id,
    name: flow?.name,
  }));

  const DATE =
    Array.isArray(graphContent) && graphContent?.length !== 0
      ? graphContent?.map(date => moment(date.created_at).format('D MMM h:m'))
      : ['0'];

  const chartData = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      colors: ['#141738', '#3F7CDB'],
      fill: {
        opacity: 1,
      },
      grid: {
        borderColor: '#CECED2',
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        color: '#5022c3',
        fontFamily: 'Atakk',
        fontSize: '10px',
        fontWeight: 500,
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          width: 14,
          height: 14,
          radius: 4,
        },
      },
      stroke: {
        curve: 'smooth',
        width: 1,
      },
      tooltip: {
        marker: {
          show: false,
        },
        theme: false,
        x: {
          show: false,
        },
      },
      xaxis: {
        categories: DATE,
        labels: {
          style: {
            colors: '#A2A3A5',
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#A2A3A5',
          },
        },
      },
    },
    series: [
      {
        data:
          graphContent?.length > 0
            ? graphContent?.map(date => date.pending)
            : [0],
        name: 'ALLOWED',
      },
      {
        data:
          graphContent?.length > 0
            ? graphContent?.map(date => date.settled)
            : [0],
        name: 'BLOCKED',
      },
    ],
  };

  const {options, series} = chartData;

  const [date, setDate] = useState('');
  const [startDate, setStartDate] = useState(new Date('01/08/2021'));
  const [endDate, setEndDate] = useState(new Date());
  const [customDate, setCustomDate] = useState(false);
  const [count, setCount] = useState('');
  const [dateCount, setDateCount] = useState(0);
  const [dateOpen] = useState(false);
  const maxDate = new Date();

  const filterCount = useMemo(
    () => (count !== '' ? count.split(',')?.length : 0),
    [count],
  );
  const onCustomDateCheck = e => {
    const {checked} = e.target;
    if (checked) {
      setDate(1);
      setCustomDate(true);
    }
  };

  const onDateCheck = useCallback(
    e => {
      const {value} = e.target;
      customDateFilters({
        value,
        appFilters: currentFilters,
        onCheck: setFilters,
      });

      setDate(value);
      setDateCount(1);

      setCustomDate(false);
    },
    [currentFilters, setFilters],
  );

  return (
    <CheckboxContextProvider>
      <div className="hidden py-4 rounded-lg md:block bg-white80">
        <div className="flex items-center justify-end gap-4 mb-4 mr-8">
          <div className="relative">
            <Dropdown
              dropdownBtn={
                <div
                  className={`flex gap-8 items-center border border-grey4 py-[11px] transition-all ease-in-out ${
                    dateCount > 0 ? 'bg-brandBlue' : 'bg-white80'
                  }  px-4 rounded cursor-pointer`}
                >
                  <span
                    className={`text-xs font-medium ${
                      dateCount > 0 && 'text-white'
                    } uppercase text-body`}
                  >
                    Date
                  </span>
                  {dateCount > 0 ? (
                    <span className="flex items-center justify-center text-xs text-brandBlue w-[18px]  h-[18px] bg-white rounded-full">
                      {dateCount}
                    </span>
                  ) : (
                    <img src={calendar} alt="" />
                  )}
                </div>
              }
            >
              <div className="absolute z-10 w-full mt-2">
                <Radios
                  className="absolute z-10 w-64 mt-2 shadow-sm"
                  data={Constants.lookups.dates}
                  filterName="date"
                  onCheck={onDateCheck}
                  selectedCount={date === '' ? 0 : 1}
                  onClick={onDateCheck}
                  activeIndex={dateOpen}
                  radioValue={date}
                >
                  <label
                    htmlFor="custom_date"
                    className="relative flex flex-row items-center gap-2 pl-4 font-normal transition duration-500 ease-in-out"
                  >
                    <input
                      type="radio"
                      value="custom_date"
                      id="custom_date"
                      name="date"
                      className="w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue"
                      onClick={onCustomDateCheck}
                    />
                    <span className="text-sm text-grey">
                      Specific date range
                    </span>
                  </label>
                  {customDate && (
                    <CustomDateRadio
                      selectedStart={startDate}
                      selectedEnd={endDate}
                      onChangeStart={date => {
                        const start = moment(date).format(
                          'YYYY-MM-DD HH:mm:ss',
                        );
                        setFilters({...currentFilters, start});
                        setStartDate(date);
                      }}
                      onChangeEnd={date => {
                        const end = moment(date).format('YYYY-MM-DD HH:mm:ss');
                        setFilters({...currentFilters, end});
                        setEndDate(date);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={maxDate}
                      minDate={startDate}
                    />
                  )}
                </Radios>
              </div>
            </Dropdown>
          </div>

          <div className="relative">
            <Dropdown
              dropdownBtn={
                <div
                  className={`flex items-end gap-12 bg-white py-[11px] px-4 ${
                    filterCount > 0 && 'bg-brandBlue'
                  }  rounded cursor-pointer`}
                >
                  <span
                    className={`text-xs ${
                      filterCount > 0 && 'text-white'
                    } font-medium uppercase text-body`}
                  >
                    flow{filterCount > 1 && 's'}
                  </span>
                  {filterCount > 0 ? (
                    <span className="flex items-center justify-center text-xs text-brandBlue w-[18px] h-[18px] bg-white rounded-full">
                      {filterCount}
                    </span>
                  ) : (
                    <img src={dropdownGrey} alt="" />
                  )}
                </div>
              }
            >
              <div className="absolute z-10 w-full mt-2">
                <Checkboxes
                  className="rounded"
                  data={FLOW_DATA}
                  values={FLOW_DATA?.map(d => d.value) || []}
                  filterName="flow"
                  onCheck={data => {
                    const flow = Object.values(data).toString();
                    setCount(flow);
                    setFilters({...currentFilters, flow});
                  }}
                />
              </div>
            </Dropdown>
          </div>
        </div>
        {loading ? (
          <Loader height={25} />
        ) : (
          <Chart options={options} series={series} type="line" height={319} />
        )}
      </div>
    </CheckboxContextProvider>
  );
}

export default connect(state => state, Actions)(LineChart);
