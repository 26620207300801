import React from 'react';
import {useQuery} from 'react-query';
import {api, truncate, url} from '../../helpers';

function getLatestBlogPost() {
  return api.get('/blog?status=published&limit=1').then(res => res.data);
}

export default function QuickActionsDynamicCard() {
  const {data} = useQuery('latest-blog-post', getLatestBlogPost);

  return (
    <div className="rounded-t-[4px] relative inline-flex basis-[32.8%]">
      {/* blog card */}
      <a
        href={`${url}/blog/${data?.posts[0]?.slug}`}
        target="_blank"
        rel="noreferrer"
        className="flex flex-col grow sm:flex-grow-0 lg:grow"
      >
        <img
          src={data?.posts[0]?.cover_img}
          alt=""
          className="rounded-t-[4px] flex-1 object-cover w-full"
          width="315"
          height="201"
        />
        <div className="bg-white py-[14.5px] px-4 rounded-b-[4px]">
          <article>
            <p className="text-xs font-medium uppercase text-brandBlue">
              blog post
            </p>
            <p className="mt-1 text-sm text-grey font-medium leading-[15.23px]">
              {data?.posts?.[0]?.title}
            </p>
            <p className="mt-1 text-sm text-body">
              {truncate(data?.posts?.[0]?.summary, 130)}
            </p>
          </article>
        </div>
      </a>
    </div>
  );
}
