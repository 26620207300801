import React, {useState, useMemo} from 'react';
import validator from 'validator';
import Select from 'react-select';
import {connect} from 'react-redux';
import {
  Modal,
  PrimaryButton,
  DropdownIndicator,
  customStyles,
} from '../../components';
import {performVerification} from '../../requests/queries/address-verification';
import {useMutation, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Actions from '../../store/actions';

const GENDER = [
  {value: 'Male', label: 'Male'},
  {value: 'Female', label: 'Female'},
];

const STATES = [
  {value: 'Abia', label: 'Abia'},
  {value: 'Adamawa', label: 'Adamawa'},
  {value: 'Akwa Ibom', label: 'Akwa Ibom'},
  {value: 'Anambra', label: 'Anambra'},
  {value: 'Bauchi', label: 'Bauchi'},
  {value: 'Bayelsa', label: 'Bayelsa'},
  {value: 'Benue', label: 'Benue'},
  {value: 'Borno', label: 'Borno'},
  {value: 'Cross River', label: 'Cross River'},
  {value: 'Delta', label: 'Delta'},
  {value: 'Ebonyi', label: 'Ebonyi'},
  {value: 'Edo', label: 'Edo'},
  {value: 'Ekiti', label: 'Ekiti'},
  {value: 'Enugu', label: 'Enugu'},
  {value: 'FCT - Abuja', label: 'FCT - Abuja'},
  {value: 'Gombe', label: 'Gombe'},
  {value: 'Imo', label: 'Imo'},
  {value: 'Jigawa', label: 'Jigawa'},
  {value: 'Kaduna', label: 'Kaduna'},
  {value: 'Kano', label: 'Kano'},
  {value: 'Katsina', label: 'Katsina'},
  {value: 'Kebbi', label: 'Kebbi'},
  {value: 'Kogi', label: 'Kogi'},
  {value: 'Kwara', label: 'Kwara'},
  {value: 'Lagos', label: 'Lagos'},
  {value: 'Nasarawa', label: 'Nasarawa'},
  {value: 'Niger', label: 'Niger'},
  {value: 'Ogun', label: 'Ogun'},
  {value: 'Ondo', label: 'Ondo'},
  {value: 'Osun', label: 'Osun'},
  {value: 'Oyo', label: 'Oyo'},
  {value: 'Plateau', label: 'Plateau'},
  {value: 'Rivers', label: 'Rivers'},
  {value: 'Sokoto', label: 'Sokoto'},
  {value: 'Taraba', label: 'Taraba'},
  {value: 'Yobe', label: 'Yobe'},
  {value: 'Zamfara', label: 'Zamfara'},
];

const initialState = {
  app_id: '',
  first_name: '',
  last_name: '',
  mobile: '',
  street: '',
  lga: '',
  state: null,
  landmark: '',
  dob: '',
  gender: null,
};

function VerificationFormModal({
  open,
  setOpen,
  apps: {allApps},
  onVerificationSuccess,
}) {
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState(initialState);
  const [selectedApps, setSelectedApps] = useState(null);

  const userApps = useMemo(
    () =>
      allApps &&
      allApps?.apps?.map(app => ({
        label: app.name,
        value: app._id,
        name: app.name,
      })),
    [allApps],
  );

  const PHONE_NUMBER_LENGTH = 13;
  const phoneNumber =
    formData.mobile?.length > PHONE_NUMBER_LENGTH
      ? formData.mobile?.slice(0, 3) + formData.mobile?.slice(4)
      : formData.mobile;

  const isValidPhoneNumber =
    phoneNumber && validator.isMobilePhone(phoneNumber, 'any');

  const isNotEmpty =
    formData.app_id &&
    formData.first_name &&
    formData.last_name &&
    formData.street &&
    formData.lga &&
    formData.state;

  const {isLoading, mutate} = useMutation(data => performVerification(data));

  const handleInputChange = e => {
    const {value, name} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleSelectChange = (value, name) => {
    setFormData({...formData, [name]: value});
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (isNotEmpty) {
      if (!formData.mobile || isValidPhoneNumber) {
        const {mobile, landmark, state, gender, dob, ...dataToSend} = formData;

        if (mobile) {
          dataToSend.mobile = phoneNumber;
        }

        if (landmark) {
          dataToSend.landmark = landmark;
        }

        if (state) {
          dataToSend.state = state.value;
        }

        if (gender) {
          dataToSend.gender = gender.value;
        }

        if (dob) {
          dataToSend.dob = dob;
        }

        mutate(dataToSend, {
          onSuccess: () => {
            toast.success('Verification successful');
            setOpen(false);
            setFormData(initialState);
            queryClient.invalidateQueries(['address-verifications']);
            if (onVerificationSuccess) {
              onVerificationSuccess();
            }
          },
          onError: error => {
            toast.error(error.response.data.error);
          },
        });
        toast.error('Please enter a valid phone number');
      }
    } else {
      toast.error('Please fill all the non-optional fields');
    }
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Submit Address"
    >
      <form onSubmit={handleSubmit}>
        <fieldset>
          <label htmlFor="first_name" className="mt-6">
            <span className="text-sm">First name</span>
            <input
              type="text"
              id="first_name"
              className="mt-2"
              onChange={handleInputChange}
              name="first_name"
            />
          </label>
          <label htmlFor="last_name" className="mt-6">
            <span className="text-sm">Last name</span>
            <input
              type="text"
              id="last_name"
              className="mt-2"
              onChange={handleInputChange}
              name="last_name"
            />
          </label>
          <label htmlFor="mobile" className="mt-6">
            <span className="text-sm">Phone number (Optional)</span>
            <input
              type="tel"
              id="mobile"
              className="mt-2"
              onChange={handleInputChange}
              name="mobile"
            />
          </label>
          <label htmlFor="street" className="mt-6">
            <span className="text-sm">Street</span>
            <input
              type="text"
              id="street"
              className="mt-2"
              onChange={handleInputChange}
              name="street"
            />
          </label>
          <label htmlFor="lga" className="mt-6">
            <span className="text-sm">LGA</span>
            <input
              type="text"
              id="lga"
              className="mt-2"
              onChange={handleInputChange}
              name="lga"
            />
          </label>
          <label htmlFor="state" className="relative mt-6 mb-2">
            State
          </label>
          <Select
            defaultValue={STATES[0]}
            value={formData.state}
            onChange={value => handleSelectChange(value, 'state')}
            options={STATES}
            placeholder="Select state"
            components={{DropdownIndicator}}
            classNamePrefix="react-select"
            styles={customStyles}
            className="text-start"
            menuPlacement="auto"
          />
          <label htmlFor="landmark" className="mt-6">
            <span className="text-sm">Landmark (Optional)</span>
            <input
              type="text"
              id="landmark"
              className="mt-2"
              onChange={handleInputChange}
              name="landmark"
            />
          </label>
          <label htmlFor="dob" className="mt-6">
            <span className="text-sm">Date of birth (Optional)</span>
            <input
              type="date"
              name="dob"
              className="mt-2"
              onChange={handleInputChange}
              max={new Date().toISOString().split('T')[0]}
            />
          </label>

          <label htmlFor="flow_app" className="mt-6 mb-2 relative">
            Select app
          </label>
          <Select
            defaultValue={selectedApps}
            onChange={data => {
              setSelectedApps(data);
              setFormData({...formData, app_id: data?.value});
            }}
            options={userApps}
            placeholder="Select an app"
            classNamePrefix="react-select"
            components={{DropdownIndicator}}
            styles={customStyles}
            className="text-start"
            menuPlacement="auto"
          />

          <label htmlFor="gender" className="relative mt-6 mb-2">
            Gender (Optional)
          </label>
          <Select
            defaultValue={GENDER[0]}
            value={formData.gender}
            onChange={value => handleSelectChange(value, 'gender')}
            options={GENDER}
            placeholder="Select gender"
            components={{DropdownIndicator}}
            classNamePrefix="react-select"
            styles={customStyles}
            className="text-start"
            menuPlacement="auto"
          />
          <div className="flex items-center mt-6">
            <PrimaryButton
              buttonText="Submit Address"
              className="w-full"
              loading={isLoading}
              aria-disabled={isLoading}
              type="submit"
            />
          </div>
        </fieldset>
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(VerificationFormModal);
