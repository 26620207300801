import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {loadState} from '../helpers';

import reducers from './reducers';

const persistedState = loadState();
let middleware = [];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, thunk, require('redux-logger').default];
} else {
  middleware = [...middleware, thunk];
}

const store = createStore(
  reducers,
  persistedState,
  applyMiddleware(...middleware),
);

export default store;
