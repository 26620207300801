/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-indent */
import React, {useEffect, useMemo, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import * as Actions from '../../../store/actions';
import EasyLookupModals from '../EasyLookupModals';
import {
  DashboardLayout,
  Filter,
  FilterButton,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
  EmptyState,
  Loader,
} from '../../../components';
import {
  backNofill,
  corruptedFile,
  easyLookupIc,
  eye,
} from '../../../assets/images/images';
import {
  capitalizeEveryWord,
  checkStatus,
  Constants,
  filterOutEmptyValues,
  generateQueryParams,
  mapToOptionsArray,
  parseUrlParams,
} from '../../../helpers';
import {getCookieValue} from '../../../helpers/getCookie';
import {PAGES_URL} from '../../../helpers/constants';
import {COUNTRIES, getIDType, tableHeader} from '../LookupUtils';
import {useApps} from '../../../hooks/useAllApps';

const defaultFilterOptions = [
  {
    title: 'Apps',
    name: 'apps',
    values: [],
  },
  //   {
  //     title: 'Model type',
  //     name: 'model_type',
  //     values: [],
  //   },
  {
    title: 'Status',
    name: 'status',
    values: [],
  },
  {
    title: 'Country',
    name: 'country',
    values: [],
  },
];

function BusinessBatchLookup({
  children,
  getBusinessBatchLookup,
  fetchLookup,
  changeLookupPage,
  getApiLogs,
  lookup: {batchBusinessLookup, h_loading, loading},
  auth: {userDetails},
  apiLogs: {logs, loading: apiLoading},
}) {
  const navigate = useNavigate();
  const {allAppsData} = useApps();

  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [openBatchLookup, setOpenBL] = useState(false);

  const env = getCookieValue('env');
  const storedCountries = JSON.parse(localStorage.getItem('selectedCountry'));
  const finalValue = Constants.lookups.getMultipleIDType(
    storedCountries || ['nigeria'],
  );

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    if (env === 'Sandbox') {
      getApiLogs({page: currentPage, limit});
    } else {
      const apiParams = parseUrlParams();
      apiParams.limit = limit;
      apiParams.company_id = userDetails?.company?.id;
      localStorage.setItem('company_id', userDetails?.company?.id);

      const searchParams = new URLSearchParams(document.location.search);
      const pageParam = searchParams.get('page');
      if (pageParam) {
        setCurrentPage(parseInt(pageParam));
        apiParams.page = parseInt(pageParam);
      } else {
        apiParams.page = currentPage;
      }

      const appIdsFromUrl = searchParams.get('app_id');
      if (appIdsFromUrl) {
        const appIdsArray = appIdsFromUrl.split(',');
        apiParams.apps = appIdsArray?.map(app => app).join('&app_id=');
      } else if (allAppsData.length > 0) {
        apiParams.apps = allAppsData?.map(app => app._id).join('&app_id=');
      }

      allAppsData?.length > 0 && getBusinessBatchLookup(apiParams);

      if (
        Object.keys(apiParams).length === 5 &&
        'page' in apiParams &&
        'apps' in apiParams &&
        'limit' in apiParams &&
        'company_id' in apiParams &&
        'model_type' in apiParams
      ) {
        setFiltersApplied(false);
      } else {
        setFiltersApplied(true);
      }
    }
  }, [
    currentPage,
    allAppsData?.length,
    env,
    getApiLogs,
    getBusinessBatchLookup,
    limit,
    userDetails?.company?.id,
  ]);

  const SANDBOX_DATA = useMemo(
    () =>
      !apiLoading &&
      logs &&
      logs.api_logs?.map((log, index) => ({
        first_name: 'John',
        last_name: 'Doe',
        model_type: 'phone_number',
        model_type_id: index,
        status: 'complete',
        date_created: log?.createdAt,
      })),
    [logs && logs.api_logs.length],
  ) || [logs];

  useEffect(() => {
    if (env === 'Sandbox' && !apiLoading && logs) {
      setData(SANDBOX_DATA);
    } else {
      !h_loading &&
        batchBusinessLookup &&
        setData([...(batchBusinessLookup?.records || [])]);
    }
  }, [env, batchBusinessLookup, apiLoading, SANDBOX_DATA.length]);

  const filterOptions = useMemo(() => {
    if (!allAppsData) {
      return defaultFilterOptions;
    }

    return [
      {
        title: 'Apps',
        name: 'apps',
        values: mapToOptionsArray(allAppsData),
      },
      {
        title: 'Status',
        name: 'status',
        values: Constants.lookups.status,
      },
      {
        title: 'Country',
        name: 'country',
        values: COUNTRIES,
      },
    ];
  }, [allAppsData, finalValue]);

  const handleFilter = (selectedOptions, actionType) => {
    const {apps, status, model_type, country, ...filteredOptions} =
      filterOutEmptyValues(selectedOptions);
    const appIds =
      filteredOptions.app_id || allAppsData?.map(app => app._id).join(',');

    const appsForApiCall = apps
      ? apps
          .map((app, index) => (index === 0 ? app : `app_id=${app}`))
          .join('&')
      : '';

    const formattedArrayParam = (array, paramName) =>
      array
        ? array.map((value, index) =>
            index === 0 ? value : `${paramName}=${value}`,
          )
        : [];

    const formattedAppIds = appIds
      ? appIds.split(',').map(appId => `app_id=${appId}`)
      : [];
    const formattedStatus = formattedArrayParam(status, 'status');
    const formattedType = formattedArrayParam(model_type, 'model_type');
    const formattedCountry = formattedArrayParam(country, 'country');

    const modifiedStatus = status ? status.join(',') : '';
    const modifiedType = model_type ? model_type.join(',') : '';
    const modifiedCountry = country ? country.join(',') : '';

    const appIdArray = appsForApiCall.split('&');

    const appIdsUrl = appIdArray
      .filter(appId => appId !== 'app_id=')
      .map(appId => appId.replace('app_id=', ''));

    const formattedAppIdsUrl = `app_id=${appIdsUrl.join(',')}`;
    const queryParamsArray = [
      appsForApiCall && formattedAppIdsUrl,
      modifiedStatus && `status=${modifiedStatus}`,
      modifiedType && `model_type=${modifiedType}`,
      modifiedCountry && `country=${modifiedCountry}`,
      actionType === 'download' ? '' : `page=1`,
    ];

    const customDateParams = generateQueryParams(filteredOptions);
    if (customDateParams && actionType !== 'download') {
      queryParamsArray.push(customDateParams);
    }

    const queryParams = queryParamsArray.filter(Boolean).join('&');

    setCurrentPage(1);
    navigate(`?${queryParams}`);

    const apiParams = {
      company_id: userDetails?.company?.id,
      page: 1,
      limit,
      model_type: formattedType.join('&'),
      apps: formattedAppIds.join('&'),
      status: formattedStatus.join('&'),
      country: formattedCountry.join('&'),
      ...filteredOptions,
    };

    if (actionType === 'download') {
      apiParams.download = true;
    }

    if (appsForApiCall) {
      apiParams.apps = appsForApiCall;
    } else if (appIds) {
      if (formattedAppIds?.length > 0) {
        formattedAppIds[0] = formattedAppIds?.[0].replace('app_id=', '');
      }
      apiParams.apps = formattedAppIds.join('&');
    }

    getBusinessBatchLookup(apiParams);
    actionType !== 'download' && setFiltersApplied(true);

    setOpenFilter(false);
  };

  const isSearchOrFilter = !h_loading && batchBusinessLookup === undefined;

  const handleResetFilter = () => {
    const app_ids = allAppsData?.map(app => app._id).join('&app_id=');

    const apiParams = {
      company_id: userDetails?.company?.id,
      apps: app_ids,
      page: 1,
    };

    navigate('');
    setCurrentPage(1);
    getBusinessBatchLookup(apiParams);
    setOpenFilter(true);
  };

  const {
    performIndividualLookupAndViewHistory: canPerformAndViewLookup,
    downloadIndividualHistory: canDownloadIndividualLookup,
  } =
    useMemo(() => userDetails?.permissions?.lookupPermissions, [userDetails]) ||
    {};

  const performRight =
    userDetails?.permissions?.role === 'Owner'
      ? false
      : !canPerformAndViewLookup;

  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyLookupIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Easy lookup</p>
        </div>
      }
    >
      <EasyLookupModals
        open={openBatchLookup}
        setOpen={setOpenBL}
        lookupType="Batch"
        lookupCategory="Business"
        batch={false}
      />

      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={options => handleFilter(options, 'filter')}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
      />

      <div className="flex items-center justify-between">
        <Link
          to={PAGES_URL.INDIVIDUAL_LOOKUP}
          className="flex items-center gap-2"
        >
          <img src={backNofill} alt="" />
          <span className="text-small text-grey80 font-medium -mb-1">Back</span>
        </Link>
      </div>

      {children ?? (
        <>
          {h_loading || loading || (env === 'Sandbox' && apiLoading) ? (
            <Loader height={50} />
          ) : isSearchOrFilter ? (
            !filtersApplied || data.length === 0 ? (
              <EmptyState
                body="No historical record available"
                onClick={() => setOpenBL(!openBatchLookup)}
                src={corruptedFile}
                disabled={!userDetails?.company?.verified || performRight}
                style={{
                  cursor:
                    (!userDetails?.company?.verified || performRight) &&
                    'not-allowed',
                }}
                buttonText="Perform batch lookup"
                imageHeight={48}
                imageWidth={48}
              />
            ) : (
              <EmptyState
                body="This filter did not return any data, try using different values."
                src={corruptedFile}
                noBtn
                customBtn={
                  <button
                    onClick={handleResetFilter}
                    className="text-brandBlue p-4 text-sm font-medium"
                  >
                    Update preferences
                  </button>
                }
              />
            )
          ) : env === 'Sandbox' && !apiLoading && data.length === 0 ? (
            <EmptyState
              body="No historical record available"
              onClick={() => setOpenBL(!openBatchLookup)}
              src={corruptedFile}
              disabled={!userDetails?.company?.verified || performRight}
              style={{
                cursor:
                  (!userDetails?.company?.verified || performRight) &&
                  'not-allowed',
              }}
              buttonText="Perform batch lookup"
              imageHeight={48}
              imageWidth={48}
            />
          ) : (
            <>
              <div className="flex pt-10 flex-col items-start justify-between gap-6 mt-4 mb-6 md:flex-row md:items-center md:gap-0 sm:mt-0">
                <h2 className="text-base font-medium text-grey sm:text-lg">
                  Batch Lookup history
                </h2>

                <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
                  {env !== 'Sandbox' && (
                    <FilterButton
                      openFilter={openFilter}
                      setOpenFilter={setOpenFilter}
                    />
                  )}
                  <div className="flex flex-wrap items-center gap-4 ">
                    <PrimaryButton
                      buttonText="Export"
                      className="w-[100px]"
                      onClick={options => handleFilter(options, 'download')}
                      disabled={!canDownloadIndividualLookup || loading}
                      title={
                        !canDownloadIndividualLookup
                          ? 'You do not have permission to perform this action'
                          : ''
                      }
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
              {h_loading || (env === 'Sandbox' && apiLoading) ? (
                <Loader height={50} />
              ) : (
                <TableLayout negativeMargins>
                  <thead className="text-xs font-semibold uppercase">
                    <tr className="bg-white80">
                      {tableHeader.map(({label, key}) => (
                        <th
                          key={key}
                          className={`${
                            label === 'Name'
                              ? 'p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap'
                              : 'p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap'
                          }`}
                        >
                          <div className="flex items-end gap-1 cursor-pointer">
                            <span>{label}</span>
                          </div>
                        </th>
                      ))}

                      <th></th>
                    </tr>
                  </thead>

                  <tbody className="text-sm">
                    {!h_loading &&
                      data &&
                      data.map((record, i) => (
                        <tr
                          onClick={() => {
                            if (record.status !== 'complete') return;
                            setOpenBL(!openBatchLookup);
                            changeLookupPage(2);
                            env !== 'Sandbox' &&
                              fetchLookup({
                                id: record?.model_type_id,
                                type: record?.model_type,
                                history_id: record?.history_id,
                              });
                          }}
                          className="border-b cursor-pointer border-grey60"
                          key={i}
                        >
                          <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                            {` ${record?.first_name?.toUpperCase() || '-'} ${
                              record?.last_name?.toUpperCase() || '-'
                            }`}
                          </td>
                          <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                            {`${Constants.lookups.getCountryAcronym(
                              record?.country,
                            )} ${capitalizeEveryWord(
                              getIDType(
                                record?.model_type || record?.lookup_type,
                              ),
                            )}`}
                          </td>
                          <td className="p-4 pl-0 whitespace-nowrap">
                            <div
                              className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit ${
                                record?.status === 'complete'
                                  ? 'text-success20 bg-success'
                                  : record?.status === 'failed'
                                  ? 'text-white bg-status-404'
                                  : 'text-white bg-brandBlue'
                              }`}
                            >
                              {checkStatus(record?.status) || '-'}
                            </div>
                          </td>
                          <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                            {moment(record?.date_created).format(
                              'MMM DD, YYYY hh:mmA',
                            ) || '-'}
                          </td>
                          {record.status === 'complete' && (
                            <td className="p-4 pl-0 whitespace-nowrap">
                              <div className="flex items-center gap-4">
                                <button
                                  title="View"
                                  onClick={() => {
                                    setOpenBL(!openBatchLookup);
                                    changeLookupPage(2);
                                    env !== 'Sandbox' &&
                                      fetchLookup({
                                        id: record?.model_type_id,
                                        type: record?.model_type,
                                        history_id: record?.history_id,
                                      });
                                  }}
                                  className="h-4 aspect-square"
                                >
                                  <img src={eye} alt="" />
                                </button>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </TableLayout>
              )}
              {!h_loading && data?.length !== 0 && (
                <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
                  <div className="flex items-center text-body text-tiny">
                    <PageLimit
                      onLimitChange={setLimit}
                      total={
                        batchBusinessLookup && batchBusinessLookup?.totalRecords
                      }
                      length={batchBusinessLookup?.records?.length}
                      limit={limit}
                    />
                  </div>

                  <div className="mb-8 sm:mb-0">
                    <Pagination
                      total={
                        batchBusinessLookup &&
                        Math.ceil(batchBusinessLookup?.totalRecords / limit)
                      }
                      current={+currentPage}
                      onPageChange={activePage => {
                        pageClick(activePage);
                        const searchParams = new URLSearchParams(
                          document.location.search,
                        );
                        searchParams.set('page', activePage);
                        const newSearchParams = searchParams.toString();
                        navigate(`?${newSearchParams}`);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(BusinessBatchLookup);
