import React from 'react';
import Radio from './Radio';

export default function Radios({
  data,
  onCheck,
  children,
  className = '',
  bg = 'bg-white80',
  padding = 'px-6',
  radioValue,
  flexDirection,
}) {
  return (
    <div
      className={`flex flex-col ${padding} ${bg}  relative -left-6 lookup-filter ${className}`}
    >
      <div
        className={` bg-white relative flex ${
          flexDirection ? flexDirection : 'flex-col'
        }  py-4 animate-dropdown`}
      >
        {data?.map(({value, label, name, price}) => (
          <Radio
            key={value}
            handleChecked={onCheck}
            label={label}
            value={value}
            name={name}
            checked={radioValue === value}
            otherText={price}
          />
        ))}
        {children}
      </div>
    </div>
  );
}
