import React from 'react';
import {infoLg} from '../../../assets/images/images';

export default function Mobile() {
  return (
    <div className="pt-16 max-w-[443px] mx-auto">
      <div className="flex items-center gap-3 bg-white rounded-lg pl-5 pr-11 py-7">
        <img src={infoLg} alt="" width={43} height={43} />
        <p className="text-body font-medium">
          For mobile integration kindly refer to the{' '}
          <a
            href="https://api-docs.dojah.io/docs/libraries"
            target="_blank"
            rel="noreferrer"
            className="text-[#3F7CDB]"
          >
            API documentation
          </a>
        </p>
      </div>
    </div>
  );
}
