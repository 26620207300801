import React from 'react';
import PhoneInput from 'react-phone-input-2';

export default function PhoneNumberInput({
  phone,
  valid,
  setPhone,
  optional = false,
}) {
  return (
    <label htmlFor="phone_number">
      {optional ? (
        <span className="text-sm">Phone number (Optional)</span>
      ) : (
        <span className="text-sm">Phone number</span>
      )}

      <PhoneInput
        placeholder="Enter phone number"
        country={'ng'}
        value={phone}
        onChange={data => setPhone(data)}
        className="mt-2"
        inputProps={{
          name: 'phone',
          required: true,
        }}
        containerClass={`${
          phone && valid ? 'phone-no-container' : 'phone-no-container-error'
        }`}
        inputClass="phone-no-input"
        enableSearch
        disableSearchIcon
        searchPlaceholder="Search for country"
        searchNotFound="Country not found"
        searchStyle={{
          fontSize: '13px',
          fontFamily: 'Atakk, sans-serif',
          marginLeft: '0',
        }}
      />
    </label>
  );
}
