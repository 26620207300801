import React from 'react';

export const PhoneNumber = () => (
  <>
    <header className="flex flex-col items-center mt-2">
      <h4 className="mt-4 font-medium text-grey">Input mobile number</h4>
    </header>
    <form className="w-full max-w-sm">
      <label htmlFor="phone_no" className="mt-4 mb-1">
        Phone number
        <input type="number" id="phone_no" className="mt-2 text-sm" />
      </label>
    </form>{' '}
  </>
);
