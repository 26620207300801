import {
  VERIFICATIONS_LOADING,
  VERIFICATIONS,
  SESSION_SUMMARY,
  KYC_CUSTOMER,
  KYC_BUSINESS,
  TIMELINE,
  DEVICE_IP,
  ADDRESS,
  EXPORT_VERIFICATIONS_LOADING,
} from '../types';

const initialState = {
  verifications: null,
  loading: false,
  session_summary: null,
  KYC_Customer: null,
  KYC_Business: null,
  timeline: null,
  deviceIp: null,
  address: null,
  exportLoading: null,
};

export default function verifications(state = initialState, action) {
  switch (action.type) {
    case VERIFICATIONS_LOADING:
      return {...state, loading: action.payload};

    case VERIFICATIONS:
      return {...state, verifications: action.payload};
    case SESSION_SUMMARY:
      return {...state, session_summary: action.payload};
    case KYC_CUSTOMER:
      return {...state, KYC_Customer: action.payload};
    case KYC_BUSINESS:
      return {...state, KYC_Business: action.payload};
    case TIMELINE:
      return {...state, timeline: action.payload};
    case DEVICE_IP:
      return {...state, deviceIp: action.payload};
    case ADDRESS:
      return {...state, address: action.payload};
    case EXPORT_VERIFICATIONS_LOADING:
      return {...state, exportLoading: action.payload};

    default:
      return state;
  }
}
