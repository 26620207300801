import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import Select from 'react-select';
import {useNavigate} from 'react-router-dom';
import * as Actions from '../../../store/actions';
import useDragAndDrop from '../../../hooks/useDragAndDrop';
import {uploadCsv} from '../../../assets/images/images';
import {
  Modal,
  DropdownIndicator,
  customStyles,
  PrimaryButton,
} from '../../../components';
import {handleFileInput} from '../../../helpers';
import {getDownloadUrl} from '../../../helpers/batchTemplateDownloadUrl';

function BatchAmlModal({
  open,
  setOpen,
  apps: {allApps},
  aml: {lookupLoading},
  performAmlBatchLookup,
}) {
  const navigate = useNavigate();
  const {dragOver, setDragOver, onDragOver, onDragLeave} = useDragAndDrop();
  const [file, setFileData] = useState(null);
  const [selectedApp, setSelectedApp] = useState(null);

  const userApps =
    allApps &&
    allApps?.apps?.map(app => ({
      value: app._id,
      label: app.name,
      name: app.name,
    }));

  const onDrop = e => {
    e.preventDefault();
    setDragOver(false);
    const selectedFile = e?.dataTransfer?.files[0];
    if (selectedFile.type.split('/')[0] !== 'application') {
      return toast.error('Please provide a valid file type (.xlsx, .csv)');
    }
    handleFileInput(selectedFile, setFileData);
  };

  const handleLookup = e => {
    e.preventDefault();

    if (!file || !selectedApp) {
      return toast.error('Please select a file and an app');
    }

    const formData = {
      file: file.file,
      filename: file.filename,
      app_id: selectedApp.value,
    };

    performAmlBatchLookup(formData, navigate, setOpen);
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Batch Lookup"
    >
      <form onSubmit={handleLookup}>
        <label htmlFor="app" className="relative mt-6 mb-2">
          Select app
        </label>
        <Select
          defaultValue={selectedApp}
          value={selectedApp}
          onChange={setSelectedApp}
          options={userApps}
          placeholder="Select app"
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          className="text-start"
          styles={customStyles}
          menuPlacement="auto"
        />
        <div className="mt-8 sm:bg-white80 sm:p-6 rounded">
          <div
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            style={{
              border: `${dragOver ? '3px dashed #33333322' : ''}`,
            }}
            className="mt-6 py-[22px] flex flex-col items-center gap-[2px] text-center border border-brandBlue border-dashed rounded"
          >
            <div className="flex-col items-center hidden sm:flex">
              {!file && <img src={uploadCsv} alt="" width={48} height={48} />}
              <p className="text-body">
                {dragOver ? (
                  'Please drop file here'
                ) : (
                  <span style={{color: file ? '#00000032' : ''}}>
                    Drag and drop Excel file here
                  </span>
                )}
              </p>
            </div>
            {file ? (
              <div className="text-body text-sm flex my-2 gap-[3px] items-baseline">
                <input
                  type="file"
                  id="file"
                  onChange={e => {
                    handleFileInput(e.target.files[0], setFileData);
                  }}
                  accept=".xlsx"
                />
                <label htmlFor="file">Change file</label>
              </div>
            ) : (
              <div className="text-body text-sm flex gap-[3px] items-baseline">
                <span className="hidden sm:block">or</span>
                <input
                  type="file"
                  id="file"
                  onChange={e => {
                    handleFileInput(e.target.files[0], setFileData);
                  }}
                  accept=".xlsx"
                />
                <label htmlFor="file">click here to select</label>
                from your device
              </div>
            )}
            <p className="text-body text-sm flex gap-[3px] items-baseline">
              {file ? (
                <>
                  File:{' '}
                  <span className="font-bold text-black ">{file.filename}</span>
                </>
              ) : (
                ''
              )}
            </p>
          </div>

          <div className="mt-2 text-xs text-right">
            <a
              href={getDownloadUrl('batch-aml')}
              target="_blank"
              rel="noreferrer"
              download
              className="font-medium text-body sm:text-brandBlue"
            >
              Click here{' '}
              <span className="font-medium text-body">
                to download template for batch upload
              </span>
            </a>
          </div>
        </div>
        <PrimaryButton
          type="submit"
          className="mt-6 w-full"
          disabled={!file || !selectedApp}
          buttonText="Lookup"
          loading={lookupLoading}
        />
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(BatchAmlModal);
