import moment from 'moment/moment';
import {toast} from 'react-toastify';
import {capitalizeEveryWord} from './capitalizeEveryWord';

export const watermark = {
  text: 'CONFIDENTIAL REPORT',
  color: '#dedddd',
  opacity: 0.4,
  bold: true,
  italics: false,
  fontSize: 60,
};

export const horizontalLine = (mb = 20) => ({
  margin: [0, 0, 0, mb],
  canvas: [
    {
      type: 'line',
      x1: 0,
      y1: 0,
      x2: 515,
      y2: 0,
      lineWidth: 1,
      color: '#4A4A68',
    },
  ],
});

export const styles = {
  header: {
    bold: true,
    fontSize: 10,
    margin: [0, 10, 0, 5],
    alignment: 'left',
  },
  footer: {
    fontSize: 10,
  },
  type: {
    bold: true,
    alignment: 'center',
    fontSize: 15,
    margin: [0, 0, 0, 10],
  },
  columnStyle: {
    margin: [0, 5, 0, 0],
  },
};
export const generatePDFStructure = generateDocInfo => {
  if (generateDocInfo?.length === 0) {
    return toast.error('No data to generate PDF');
  }

  const finalContentStructure = [];
  generateDocInfo.forEach(data => {
    const dataRows = [];
    Object.entries(data).forEach(([key, value]) => {
      if (
        ![
          'photo',
          'image',
          'picture',
          'caller name',
          'caller email',
          'date created',
        ].includes(key) &&
        value &&
        typeof value === 'string'
      ) {
        const pair = [
          {
            width: 200,
            bold: true,
            margin: [0, 0, 20, 10],
            style: {fontSize: 10},
            text: capitalizeEveryWord(
              `${key}:`.replaceAll('_', ' '),
            )?.toUpperCase(),
          },
          {
            width: '*',
            margin: [0, 0, 20, 0],
            style: {color: '#4A4A68', fontSize: 11},
            text: value && value?.toUpperCase()?.replaceAll(',', ' '),
          },
        ];

        dataRows.push(pair);
      }
    });
    if (dataRows.length > 0) {
      finalContentStructure.push({
        style: 'columnStyle',
        layout: 'noBorders',
        table: {widths: [100, 150], body: [...dataRows]},
      });
    }
  });

  return finalContentStructure;
};
export const generateFooter = footerData => [
  {
    alignment: 'center',
    style: 'footer',
    columns: [
      {
        text: `Generated by: ${footerData?.name}`.toUpperCase(),
      },

      {
        text: `Created: ${moment(footerData?.generated || new Date()).format(
          'Do MMM YYYY hh:mma',
        )} `.toUpperCase(),
      },

      {
        text: `Downloaded: ${moment(new Date()).format(
          'Do MMM YYYY hh:mma',
        )}`.toUpperCase(),
      },
    ],
  },

  // {
  //   columns: [
  //     {
  //       text: 'Powered by',
  //       margin: [240, 5, 0, 0],
  //       style: {alignment: 'center', fontSize: 9},
  //     },
  //     {
  //       image: footerData?.logo,
  //       fit: [30, 30],
  //       margin: [0, 5, 0, 0],
  //     },
  //   ],
  // },
];
