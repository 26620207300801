import React from 'react';
import {Modal} from '../../components';
import {closeModal, corruptedFile} from '../../assets/images/images';

export default function NoInfoModal({
  open,
  setOpen,
  type = 'person',
  name = 'individual',
}) {
  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-grey text-base font-medium">Lookup {name}</h3>
          <button onClick={() => setOpen(false)}>
            <img src={closeModal} alt="" />
          </button>
        </header>

        <div className="flex flex-col items-center justify-center py-12 mt-6 bg-white80">
          <img src={corruptedFile} alt="" width={48} height={48} />
          <p className="max-w-[179px] mt-2 text-sm text-center text-body">
            We did not find any information on this {type}
          </p>
        </div>
      </div>
    </Modal>
  );
}
