import {api} from '../../helpers';

function fetchApiTokens(params) {
  return api.get('/users/company/api-token', {params}).then(res => res.data);
}

function createApiToken(params) {
  return api.post('/users/company/api-token', params).then(res => res.data);
}

function editApiToken(id, params) {
  return api
    .put(`/users/company/api-token/${id}`, params)
    .then(res => res.data);
}

function deleteApiToken(id, params) {
  return api
    .delete(`/users/company/api-token/${id}`, params)
    .then(res => res.data);
}

export {fetchApiTokens, createApiToken, editApiToken, deleteApiToken};
