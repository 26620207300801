import React from 'react';
import {EmptyState} from './index';
import {corruptedFile} from '../assets/images/images';

export const EmptyFilterState = ({
  onClick = () => [],
  body = 'Opps No history found',
  heading = 'Opps No history found',
  colSpan = 8,
}) => (
  <tbody className="text-sm text-body">
    <tr>
      <td colSpan={colSpan}>
        <EmptyState
          noBtn
          className="w-full"
          src={corruptedFile}
          heading={heading}
          body={body}
          maxWidth="max-w-[500px]"
          height={35}
          customBtn={
            <button
              onClick={onClick}
              className="text-brandBlue cursor-pointer p-2 px-3 rounded-lg border text-sm font-medium"
            >
              Try Again
            </button>
          }
        />
      </td>
    </tr>
  </tbody>
);
