/* eslint-disable react/jsx-indent */
import React, {useEffect, useState} from 'react';
import ReconciliationLayout from '../ReconciliationLayout';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';

import {
  corruptedFile,
  projectsMobileNav,
} from '../../../../assets/images/images';
import {
  // PrimaryButton,
  // SettingsToggle,
  // MultiValueRemove,
  // SecondaryButton,
  Loader,
  SkeletonLoader,
  EmptyState,
} from '../../../../components';
// import CreatableSelect from 'react-select/creatable';
import {useQuery} from 'react-query';
import {fetchProject} from '../../../../requests/queries/reconciliations';
import {CopyIcon} from '../../../../components/images';
import {useAccounts} from '../hooks/useAccounts';

function ProjectSettings() {
  const {slug} = useParams();
  const projectId = slug !== 'add-project' ? slug : '';
  const {data: project, isLoading} = useQuery(
    ['project-overview', projectId],
    () => fetchProject(projectId),
    {enabled: !!projectId},
  );

  const projectData = project && project?.entity;
  // const [inputValue, setInputValue] = useState('');
  const [inputValues, setInputValues] = useState({});
  // const [noEmail] = useState(false);

  const {accountSelectionData, isLoading: accountLoading} = useAccounts({slug});

  useEffect(() => {
    if (projectData) {
      const currentUserData = {
        desc: projectData?.desc,
        name: projectData?.name,
      };

      setInputValues(currentUserData);
    }
  }, [projectData]);

  const copyText = text => {
    navigator.clipboard.writeText(text);
    toast.success(`${text?.toUpperCase()}, copied!`);
  };

  return (
    <ReconciliationLayout
      bg="bg-white80"
      pageTitle="Project A"
      pageIcon={projectsMobileNav}
    >
      {isLoading ? (
        <Loader height={45} />
      ) : (
        <div className="pl-11 pr-8">
          <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-7">
            <div>
              <p className="uppercase text-body font-medium text-xs">
                Edit Project
              </p>

              <div className="mt-6 bg-white p-6 rounded-md">
                <label htmlFor="project_name">
                  <span className="text-sm text-body font-medium">
                    Name of project
                  </span>
                  <input
                    type="text"
                    name="name"
                    className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
                    id="name"
                    placeholder="Input project name"
                    value={inputValues?.name}
                    onChange={e =>
                      setInputValues(p => ({...p, name: e.target.value}))
                    }
                  />
                </label>

                {/*<label*/}
                {/*  htmlFor="project_type"*/}
                {/*  className="relative mt-6 text-body font-medium"*/}
                {/*>*/}
                {/*  Project type*/}
                {/*</label>*/}
                {/*<Select*/}
                {/*  defaultValue={projectType}*/}
                {/*  onChange={data => setProjectType(data)}*/}
                {/*  options={projectTypeOptions}*/}
                {/*  placeholder="Select project type"*/}
                {/*  classNamePrefix="react-select"*/}
                {/*  components={{DropdownIndicator}}*/}
                {/*  styles={customStyles}*/}
                {/*  className="text-start mt-2 add_project_select"*/}
                {/*  menuPlacement="auto"*/}
                {/*/>*/}

                <label htmlFor="project_description" className="mt-6">
                  <span className="text-sm text-body font-medium">
                    Project description{' '}
                    <span className="text-[#858DAA]">(Optional)</span>{' '}
                  </span>
                  <textarea
                    id="desc"
                    className="mt-2 h-40 placeholder:text-grey-200 placeholder:font-normal"
                    name="desc"
                    placeholder="Input project description"
                    value={inputValues?.desc}
                    onChange={e =>
                      setInputValues(p => ({...p, desc: e.target.value}))
                    }
                  />
                </label>
              </div>
            </div>
            {/*<div>*/}
            {/*  <p className="uppercase text-body font-medium text-xs mb-6">*/}
            {/*    Email Notifications*/}
            {/*  </p>*/}

            {/*  <div className="mt-6 bg-white p-6 rounded-md receive_email">*/}
            {/*    <label*/}
            {/*      className="font-medium text-tiny text-body"*/}
            {/*      htmlFor="receive_email"*/}
            {/*    >*/}
            {/*      Opt to receive email notifications whenever a new case emerges*/}
            {/*      <div className="mt-4 flex items-center gap-2 p-4 rounded bg-white80 w-full">*/}
            {/*        <SettingsToggle title="receive_email" />*/}
            {/*        <p className="text-body text-xs font-medium -mb-1">Email</p>*/}
            {/*      </div>*/}
            {/*    </label>*/}

            {/*    <>*/}
            {/*      <label*/}
            {/*        htmlFor="emails"*/}
            {/*        className="mt-4 mb-1 relative text-xs font-medium text-body"*/}
            {/*      >*/}
            {/*        Add email address*/}
            {/*      </label>*/}
            {/*      <CreatableSelect*/}
            {/*        components={{*/}
            {/*          DropdownIndicator: null,*/}
            {/*          MultiValueRemove,*/}
            {/*        }}*/}
            {/*        inputValue={inputValue}*/}
            {/*        isClearable*/}
            {/*        isMulti*/}
            {/*        menuIsOpen={false}*/}
            {/*        // onChange={newValue => {*/}
            {/*        //   if (newValue) {*/}
            {/*        //     setUpdate({*/}
            {/*        //       ...updateData,*/}
            {/*        //       emails: newValue.map(v => v.label),*/}
            {/*        //     });*/}
            {/*        //     sectCheck({*/}
            {/*        //       ...checkUpdate,*/}
            {/*        //       emails: newValue.map(v => v.label),*/}
            {/*        //     });*/}
            {/*        //   }*/}
            {/*        // }}*/}
            {/*        onInputChange={newValue => {*/}
            {/*          setInputValue(newValue);*/}
            {/*        }}*/}
            {/*        // onKeyDown={handleKeyDown}*/}
            {/*        placeholder="Type emails here and press enter or tab to add"*/}
            {/*        // value={*/}
            {/*        //   updateData?.emails.map(v => ({*/}
            {/*        //     label: v,*/}
            {/*        //     value: v,*/}
            {/*        //   })) || []*/}
            {/*        // }*/}
            {/*        className={`creatable-multi-select rounded ${*/}
            {/*          noEmail ? 'border border-danger ' : ''*/}
            {/*        }`}*/}
            {/*        classNamePrefix="react-select"*/}
            {/*        multiValueLabel*/}
            {/*      />*/}
            {/*    </>*/}
            {/*  </div>*/}

            {/*  <div className="flex flex-col lg:flex-row items-center gap-6 mt-9">*/}
            {/*    <SecondaryButton*/}
            {/*      buttonText="Cancel"*/}
            {/*      className="lg:px-[63px] rounded-lg"*/}
            {/*    />*/}
            {/*    <PrimaryButton*/}
            {/*      buttonText="Save Changes"*/}
            {/*      className="lg:px-[40px] rounded-lg"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          <div className="mt-7 w-[49%]">
            <p className="uppercase text-body font-medium text-xs mb-6">
              Account Configuration
            </p>
            <div
              className={`relative transition-all duration-300 ease-in-out bg-white p-6 rounded-md max-h-[500px] overflow-auto`}
            >
              <div className={`transition-all duration-500 ease-in-out`}>
                {accountLoading ? (
                  [1, 2, 3].map(d => (
                    <div
                      key={d}
                      className={`flex transition-all mt-4 duration-500 ease-linear  border-b border-b-1  border-b-black25 justify-between last:border-none `}
                    >
                      <SkeletonLoader
                        style={{
                          height: 20,
                          width: `${30}%`,
                        }}
                      />
                      <SkeletonLoader
                        style={{
                          height: 20,
                          width: `${40}%`,
                        }}
                      />
                    </div>
                  ))
                ) : !accountLoading && accountSelectionData?.length === 0 ? (
                  <EmptyState
                    noBtn
                    body="There is no Account Info to display here at the moment"
                    src={corruptedFile}
                    height={25}
                    imageHeight={48}
                    imageWidth={48}
                    bodyMaxWidth="max-w-[179px]"
                    buttonText="Validate document"
                  />
                ) : (
                  accountSelectionData.map((account, idx) => (
                    <div
                      key={account?.label + idx}
                      className={`flex flex-col py-4 transition-all duration-500 ease-linear  border-b border-b-1  border-b-black25 justify-between last:border-none `}
                    >
                      <p className=" pb-2  text-left text-sm text-grey-500  whitespace-nowrap">
                        <span className="uppercase text-grey">
                          Account name
                        </span>{' '}
                        : {account?.label}
                      </p>
                      <p
                        title={'Copy id'}
                        onClick={() => copyText(account?.value)}
                        className="hover:text-brandBlue uppercase flex items-center gap-1 cursor-pointer  text-left text-sm text-grey-500 whitespace-nowrap"
                      >
                        <span className="uppercase text-grey">Account ID</span>{' '}
                        : {account?.value}
                        <CopyIcon />
                      </p>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </ReconciliationLayout>
  );
}

export default ProjectSettings;
