import React, {useState} from 'react';

const NumberSlider = ({value, onChange}) => {
  const [sliderValue, setSliderValue] = useState(value || 10);

  const handleSliderChange = newValue => {
    setSliderValue(newValue);
    onChange(newValue);
  };

  const sliderWidth = (+sliderValue / 100) * 100;

  return (
    <div className="liveness_slider h-3 relative w-full">
      <input
        type="range"
        min="0"
        max="100"
        value={sliderValue}
        onChange={e => handleSliderChange(parseInt(e.target.value))}
        className="opacity-0 z-30 accent-[#3F7CDB]  cursor-pointer"
      />
      <p
        className="h-5 w-10 font-thin bg-brandBlue z-10 text-white text-xs rounded-full flex items-center justify-center absolute top-1 -mt-2"
        style={{
          left: `${sliderWidth}%`,
          transform: 'translateX(-50%)',
        }}
      >
        {sliderValue}%
      </p>
      <div className="h-3 bg-brandBlue4 z-0 rounded-full absolute top-1/2 left-0 transform -translate-y-1/2 w-full"></div>
    </div>
  );
};

export default NumberSlider;
