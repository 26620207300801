/* eslint-disable react-hooks/exhaustive-deps */
// noinspection HtmlUnknownTarget

import React, {useContext, useEffect, useMemo} from 'react';
import Select from 'react-select';
import {
  Modal,
  PrimaryButton,
  DropdownIndicator,
  customStyles,
  SecondaryButton,
} from '../../../../components';
import {closeModal, plus} from '../../../../assets/images/images';
import {COUNTS, PERIOD} from './transactions';
import {connect} from 'react-redux';
import {convertToDurationObject} from '../../../../helpers/convertToDurationObject';
import {CreditCheckContext} from '../../../../helpers/context/CreditCheckContext';
import * as Actions from '../../../../store/actions';
import ConditionInputs from './_ConditionInputs';

const RuleEditorModal = ({
  flows,
  action,
  disabled,
  caseLists: {listLoading, lists},
  //////////// FUNCTIONS //////////////ß
  onClick,
  getLists,
  handleAddCondition,
}) => {
  const taskId = localStorage.getItem('taskId') || '';
  const {
    set: {setOpenModals, setRuleState},
    get: {
      ruleState: {actionName, actionValue, actionScore, currentRule},
      openModals: {openRuleEditorModal},
    },
    resetFlow,
  } = useContext(CreditCheckContext);

  useEffect(() => {
    getLists({page: 1, limit: 1000});
  }, [getLists]);

  const period = convertToDurationObject(currentRule?.period);

  const flowConditions = useMemo(
    () =>
      currentRule &&
      currentRule?._id === taskId &&
      currentRule?.configurations &&
      Array.isArray(currentRule?.configurations)
        ? currentRule?.configurations
        : // eslint-disable-next-line no-unsafe-optional-chaining
          currentRule?.configurations && [...currentRule?.configurations],
    [currentRule, taskId],
  );

  const currentCount = COUNTS.find(count => count.value === period?.count);
  const currentPeriod = PERIOD.find(data => data.value === period?.period);

  useEffect(() => {
    setRuleState(prev => ({
      ...prev,
      selectedPeriod: {...currentPeriod, [action]: currentPeriod},
      selectedCount: {...currentCount, [action]: currentCount},
      actionValue: {
        ...actionValue,
        [action]: currentRule?.configurations?.value,
      },
      actionScore: {[action]: currentRule?.score},
    }));
  }, [
    setRuleState,
    currentCount,
    currentPeriod,
    action,
    currentRule?.configurations?.value,
    currentRule?.score,
  ]);

  const allData = {
    actionName: actionName[action],
    actionValue: actionValue[action],
    actionScore: actionScore[action],
  };

  return (
    <Modal
      show={openRuleEditorModal}
      onClose={value => setOpenModals({openRuleEditorModal: value})}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-base font-medium text-grey">Rule editor</h3>
          <button onClick={() => setOpenModals({openRuleEditorModal: false})}>
            <img src={closeModal} alt="" width={24} height={24} />
          </button>
        </header>
        <div className={`mt-6 `}>
          <label htmlFor="action_name" className="mt-6">
            <span className="text-sm ">Action name</span>
            <input
              type="text"
              id="action_name"
              className={`mt-2 ${
                !allData.actionName && 'outline-danger border-danger'
              }`}
              required
              placeholder={allData.actionName}
              value={allData.actionName}
              onChange={e =>
                setRuleState(prev => ({
                  ...prev,
                  actionName: {...prev.actionName, [action]: e.target.value},
                }))
              }
            />
          </label>

          <div className={`mt-4 `}>
            <div className={`flex flex-col gap-5 h-[95%]  shadow-inner`}>
              {/*  CONDITIONS HERE  */}
              {flowConditions &&
                flowConditions?.map((condition, idx) => (
                  <ConditionInputs
                    flowConditions={flowConditions}
                    key={idx}
                    action={action}
                    flows={flows}
                    condition={{...condition, key: idx}}
                    listLoading={listLoading}
                    lists={lists}
                  />
                ))}
            </div>

            {flowConditions?.length <= 3 && (
              <div className="mt-6">
                <SecondaryButton
                  src={plus}
                  buttonText="Add new condition"
                  className="w-full flex items-center justify-center gap-5"
                  leftImg
                  width={25}
                  onClick={handleAddCondition}
                />
              </div>
            )}
            <div>
              <label
                htmlFor="end_process"
                className="mt-4 w-full mb-2 relative text-left"
              >
                <div>
                  Over time period
                  <span className="text-grey5 inline"> (optional)</span>
                </div>
              </label>
              <div className="flex w-full">
                <Select
                  defaultValue={currentCount}
                  onChange={data => {
                    setRuleState(prev => ({
                      ...prev,
                      selectedCount: {
                        ...prev.selectedCount,
                        [action]: data,
                      },
                    }));
                  }}
                  options={COUNTS}
                  placeholder="00"
                  className="basic-multi-select white-select mr-2"
                  classNamePrefix="react-select"
                  components={{DropdownIndicator}}
                  styles={customStyles}
                  menuPlacement="auto"
                />
                <Select
                  defaultValue={currentPeriod}
                  onChange={data => {
                    setRuleState(prev => ({
                      ...prev,
                      selectedPeriod: {
                        ...prev.selectedPeriod,
                        [action]: data,
                      },
                    }));
                  }}
                  options={PERIOD}
                  placeholder="Days"
                  className="basic-multi-select white-select flex-1"
                  classNamePrefix="react-select"
                  components={{DropdownIndicator}}
                  styles={customStyles}
                  menuPlacement="auto"
                />
              </div>
            </div>

            <label htmlFor="value" className="mt-4">
              Score
              <input
                data-bg="white"
                type="number"
                id="value"
                className="mt-2 placeholder-black"
                required
                value={String(currentRule.score) || allData?.actionScore}
                placeholder={allData.actionScore}
                onChange={e => {
                  const inputValue = e.target.value;
                  if (
                    inputValue === '' ||
                    (inputValue >= 0 && inputValue <= 100)
                  ) {
                    setRuleState(prev => ({
                      ...prev,
                      actionScore: {
                        ...prev.actionScore,
                        [action]: inputValue,
                      },
                      currentRule: {
                        ...prev.currentRule,
                        score: +inputValue,
                      },
                    }));
                  }
                }}
              />
            </label>
          </div>
        </div>
        <div className="flex items-center sm:justify-center gap-2 mt-6">
          <PrimaryButton
            buttonText={taskId ? 'Update' : 'Apply'}
            className="w-full"
            onClick={onClick}
            // disabled={
            //   disabled ||
            //   !isNestedObjectTruthy({
            //     ...allData,
            //     actionValue: actionValue[action]?.toString(),
            //   })
            // }
            loading={disabled}
          />
          <SecondaryButton
            buttonText="Reset"
            onClick={resetFlow}
            className="whitespace-nowrap"
          />
        </div>
      </div>
    </Modal>
  );
};

export default connect(state => state, Actions)(RuleEditorModal);
