/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Select from 'react-select';
import moment from 'moment';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {closeModal, plus, times, uploadCsv} from '../../assets/images/images';
import * as Actions from '../../store/actions';
import {Constants, handleFileInput, capitalizeEveryWord} from '../../helpers';
import {
  customStyles,
  DropdownIndicator,
  CountryInput,
  PrimaryButton,
  TableLayout,
  Modal,
  CreateAppForm,
} from '../../components';
import useDragAndDrop from '../../hooks/useDragAndDrop';
import EasyLookupDetails from './EasyLookupDetails';
import Nigeria from '../../components/CountryLookups/Nigeria';
import Ghana from '../../components/CountryLookups/Ghana';
import Uganda from '../../components/CountryLookups/Uganda';
import Kenya from '../../components/CountryLookups/Kenya';
import SouthAfrica from '../../components/CountryLookups/SouthAfrica';
import Zimbabwe from '../../components/CountryLookups/Zimbabwe';
import Loader from '../../components/Loader';
import {getDownloadUrl} from '../../helpers/batchTemplateDownloadUrl';
import GlobalSearchResultsModal from './GlobalSearchResultsModal';
import {useNavigate} from 'react-router-dom';
import {useDefaultCountry} from '../../hooks/useDefaultCountry';
import {useAllApps} from '../../hooks/useApps';
import {useQueryClient} from 'react-query';
import Zambia from '../../components/CountryLookups/Zambia';
import {customer360LookupDropdownOptions} from '../Customer360/LayoutConstants';

const CountryComponentMap = ({lookupInputs, ...props}) => {
  const country = lookupInputs?.country?.value;

  const countryComponentMap = {
    ghana: Ghana,
    uganda: Uganda,
    kenya: Kenya,
    zimbabwe: Zimbabwe,
    zambia: Zambia,
    'south-africa': SouthAfrica,
  };
  const Country = countryComponentMap[country] || Nigeria;

  return <Country lookupInputs={lookupInputs} {...props} />;
};

function EasyLookupModals({
  performLookup,
  performBatchLookup,
  changeLookupPage,
  downloadBatchLookup,
  lookupCategory = 'Individual',
  auth: {userDetails},
  lookup: {lookupPage, loading, l_loading, singleBatch},
  ...otherProps
}) {
  const {
    open,
    setOpen,
    lookupType,
    batch,
    customer360,
    customer_id,
    customer360Dropdown,
  } = otherProps;
  const navigate = useNavigate();
  const {userDefaultCountry, isLoading: defaultLoading} = useDefaultCountry();

  const {userApps, isLoading} = useAllApps();

  const [lookupInputs, setLookupInputs] = useState({
    ...Constants?.lookups?.initialLookUpState,
    app: (!isLoading && userApps && userApps[0]) || {},
    country: userDefaultCountry,
  });
  const [step, setStep] = useState(lookupPage !== undefined ? lookupPage : 1);
  const [file, setFilData] = useState(null);
  const [enable, setEnable] = useState(true);
  const [newAppForm, setNewAppForm] = useState(false);
  const {dragOver, setDragOver, onDragOver, onDragLeave} = useDragAndDrop();
  const queryClient = useQueryClient();

  localStorage.setItem('lookupInputs', JSON.stringify(lookupInputs));
  useEffect(() => {
    const currentInputs = JSON.parse(localStorage.getItem('lookupInputs'));
    setLookupInputs({
      ...currentInputs,
      country: currentInputs?.country || userDefaultCountry,
      app: (!isLoading && userApps && userApps[0]) || {},
    });
  }, [userDefaultCountry, userApps, isLoading]);

  useEffect(() => {
    setStep(lookupPage !== undefined ? lookupPage : 1);
  }, [setStep, lookupPage, step]);

  const {
    country,
    type,
    lookupValue = '',
    dob,
    ghanaDlDob,
    state,
    firstname,
    lastname,
    middlename,
    bank_code,
    company_name,
    mode,
    app,
    ghanaPassportDob,
    surname,
    date_of_birth,
    mobile,
    gender,
    marital_status,
    cac_type,
    country_code,
    company,
  } = lookupInputs;

  const handleLookup = useCallback(() => {
    const url = Constants.lookups.checkLookupType(
      country?.value || 'nigeria',
      type?.value,
      lookupValue,
    );
    // if (!live) {
    //   return toast.error('Please enable live mode');
    // }
    if (!app.value) {
      return toast.error('Please select an app');
    }
    if (file) {
      const path = Constants.lookups.pathUrlTypeCheck(type.value);
      performBatchLookup(
        path,
        {...file, app_id: app.value},
        app.value,
        setOpen,
      );
      return;
    }

    const queryParams = {
      app_id: app?.value,
      dob: dob || undefined,
      state: state || undefined,
      full_name:
        country?.value === 'ghana'
          ? undefined
          : country?.value === 'uganda' ||
            (country?.value === 'zimbabwe' && type?.value === 'fcb')
          ? firstname
          : undefined,
      first_name:
        country?.value === 'ghana' ||
        country?.value === 'uganda' ||
        (country?.value === 'zimbabwe' && type?.value === 'fcb')
          ? firstname
          : undefined,
      last_name:
        country?.value === 'ghana'
          ? undefined
          : country?.value === 'uganda' ||
            (country?.value === 'zimbabwe' && type?.value === 'fcb')
          ? lastname
          : undefined,
      middle_name: country?.value === 'ghana' ? undefined : undefined,
      surname: surname || undefined,
      gh_dl: type?.value === 'gh-dl' ? ghanaDlDob : undefined,
      date_of_birth:
        country?.value === 'ghana' && type?.value === 'passport'
          ? undefined
          : country?.value === 'zimbabwe' && type?.value === 'fcb'
          ? dob || date_of_birth
          : undefined,
      bank_code: bank_code?.value || undefined,
      mode: mode || undefined,
      mobile: mobile || undefined,
      marital_status: marital_status?.value || undefined,
      gender: gender?.value || undefined,
      type: cac_type?.value || undefined,
      company_name: company_name
        ? company_name?.replace('%', '%25')
        : undefined,
      company: company ? company?.replace('%', '%25') : undefined,
      country_code: country_code?.value?.toLowerCase() || undefined,
    };

    // Converting the query parameters object to a string
    const queryParamString = Object.entries(queryParams || {})
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    // Combining with the base URL
    let lookupParams = `${url}${
      queryParamString ? `&${queryParamString}` : ''
    }`;

    // Removing the country_code=& from the query string

    if (lookupParams?.includes('country_code=&')) {
      lookupParams = lookupParams?.replace('country_code=&', '');
    }

    performLookup({
      data: lookupParams.replace(/\s/g, ''),
      type: type?.value,
      appId: app?.value.replace(/\s/g, ''),
      ...(type.value === 'nuban' && {account_number: lookupValue}),
      customer360,
      navigate,
      queryClient,
      setOpen,
      customer_id,
    });
    changeLookupPage(1);

    if (!loading && type?.value === 'global_search') {
      return setLookupInputs({...Constants.lookups.initialLookUpState, type});
    }

    !loading &&
      setLookupInputs({
        ...Constants.lookups.initialLookUpState,
        type: {},
        app: {},
        country: userDefaultCountry,
      });
  }, [
    app?.value,
    bank_code?.value,
    cac_type?.value,
    company,
    company_name,
    country?.value,
    country_code?.value,
    date_of_birth,
    dob,
    file,
    firstname,
    gender?.value,
    ghanaDlDob,
    ghanaPassportDob,
    lastname,
    loading,
    lookupValue,
    marital_status?.value,
    middlename,
    mobile,
    mode,
    performBatchLookup,
    performLookup,
    setOpen,
    state,
    surname,
    type,
    userDefaultCountry,
  ]);

  const ngPass =
    lookupInputs?.type?.value === 'passport' &&
    lookupInputs?.country?.value === 'nigeria';

  const onDrop = e => {
    e.preventDefault();
    setDragOver(false);
    const selectedFile = e?.dataTransfer?.files[0];
    if (selectedFile.type.split('/')[0] !== 'application') {
      return toast.error('Please provide a valid file type (.xlsx, .csv)');
    }
    handleFileInput(selectedFile, setFilData);
  };

  useEffect(() => {
    if (file) {
      setEnable(false);
    } else if (ngPass && !!surname && lookupValue) {
      setEnable(false);
    } else if (!ngPass && lookupValue) {
      setEnable(false);
    } else if (country_code && company) {
      setEnable(false);
    } else {
      setEnable(true);
    }
  }, [
    file?.filename,
    lookupValue,
    file,
    ngPass,
    surname,
    country_code,
    company,
  ]);

  const batchUploadProgressPercentage = Math.round(
    (singleBatch?.processed_records / singleBatch?.total_records || 0) * 100,
  );

  const {downloadIndividualLookup: canDownloadHistory} =
    useMemo(() => userDetails?.permissions?.lookupPermissions, [userDetails]) ||
    {};

  const businessIds = [
    'cac',
    ...(country?.value === 'nigeria' && batch
      ? []
      : ['cac_advance', 'global_search']),
  ];

  const getCountryIDs = useCallback(
    key =>
      Constants.lookups.getIDType(
        key || (!defaultLoading && userDefaultCountry?.value) || 'nigeria',
      ),
    [userDefaultCountry, defaultLoading],
  );

  return (
    <>
      <Modal
        show={open}
        onClose={setOpen}
        extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
        modalPosition="justify-center sm:justify-end"
      >
        {step === 1 && (
          <div className="py-6 sm:py-12 px-[19px] sm:px-6">
            <header className="flex items-center justify-between">
              <h3 className="text-base font-medium text-grey">
                {lookupType || 'Easy'} Lookup
              </h3>
              <button onClick={() => setOpen(false)}>
                <img src={closeModal} alt="" />
              </button>
            </header>

            {l_loading ? (
              <Loader />
            ) : (
              <form
                className="mt-6 text-left"
                style={{opacity: l_loading && 0.4}}
              >
                {lookupCategory === 'Business' ? null : (
                  <CountryInput
                    defaultValue={
                      userDefaultCountry ||
                      lookupInputs?.country ||
                      Constants?.lookups?.countries[0]
                    }
                    onChange={country =>
                      setLookupInputs({
                        ...lookupInputs,
                        ...Constants?.lookups?.initialLookUpState,
                        country: country || userDefaultCountry,
                      })
                    }
                    label="Select country"
                    lookupType={lookupType}
                  />
                )}
                <label htmlFor="app" className="relative mt-6 mb-2">
                  Select app
                </label>
                <Select
                  defaultValue={
                    lookupInputs?.app || (!isLoading && userApps[0])
                  }
                  value={lookupInputs?.app || (!isLoading && userApps[0])}
                  onChange={app => setLookupInputs({...lookupInputs, app})}
                  options={userApps}
                  placeholder="Select app"
                  components={{DropdownIndicator}}
                  classNamePrefix="react-select"
                  styles={customStyles}
                  isDisabled={isLoading}
                />
                <button
                  className="flex items-center gap-[2px] ml-auto mt-2"
                  onClick={() => setNewAppForm(prev => !prev)}
                  type="button"
                >
                  <img
                    src={newAppForm ? times : plus}
                    alt=""
                    width={12}
                    height={12}
                  />
                  <span className="text-xs font-medium text-body">
                    {newAppForm ? 'Close' : 'Create a new app'}
                  </span>
                </button>
                {newAppForm && (
                  <div className="p-6 mt-2 rounded bg-white80 animate-dropdown">
                    <h3 className="text-base font-medium text-grey">
                      Create App
                    </h3>
                    <CreateAppForm
                      setOpen={() => setNewAppForm(prev => !prev)}
                    />
                  </div>
                )}
                <label htmlFor="industry" className="relative mt-6 mb-2">
                  Select ID Type
                </label>
                <Select
                  defaultValue={lookupInputs?.type}
                  value={lookupInputs?.type}
                  onChange={type =>
                    setLookupInputs({
                      ...lookupInputs,
                      type,
                    })
                  }
                  options={
                    customer360Dropdown
                      ? customer360LookupDropdownOptions
                      : lookupCategory === 'Business'
                      ? getCountryIDs(country?.value)?.filter(option =>
                          businessIds.includes(option?.value),
                        )
                      : lookupType === 'Batch' && lookupType !== 'Business'
                      ? getCountryIDs(country?.value)?.filter(
                          option =>
                            lookupType === 'Batch' &&
                            lookupCategory !== 'Business' &&
                            ![
                              'global_search',
                              'cac_advance',
                              'passport',
                            ].includes(option?.value),
                        )
                      : getCountryIDs(country?.value)?.filter(
                          option => !businessIds.includes(option?.value),
                        )
                  }
                  placeholder="Select an ID type"
                  components={{DropdownIndicator}}
                  classNamePrefix="react-select"
                  styles={customStyles}
                  menuPlacement="auto"
                  isSearchable={false}
                />
                <CountryComponentMap
                  lookupInputs={lookupInputs}
                  setLookupInputs={setLookupInputs}
                  batch={country?.value === 'nigeria' ? batch : undefined}
                />
                {/*{lookupInputs?.country?.value === 'ghana' ? (*/}
                {/*  <Ghana*/}
                {/*    lookupInputs={lookupInputs}*/}
                {/*    setLookupInputs={setLookupInputs}*/}
                {/*  />*/}
                {/*) : lookupInputs?.country?.value === 'uganda' ? (*/}
                {/*  <Uganda*/}
                {/*    lookupInputs={lookupInputs}*/}
                {/*    setLookupInputs={setLookupInputs}*/}
                {/*  />*/}
                {/*) : lookupInputs?.country?.value === 'kenya' ? (*/}
                {/*  <Kenya*/}
                {/*    lookupInputs={lookupInputs}*/}
                {/*    setLookupInputs={setLookupInputs}*/}
                {/*  />*/}
                {/*) : (*/}
                {/*  <Nigeria*/}
                {/*    lookupInputs={lookupInputs}*/}
                {/*    setLookupInputs={setLookupInputs}*/}
                {/*    batch={batch}*/}
                {/*  />*/}
                {/*)}*/}
                {country?.value === 'nigeria' && batch && (
                  <div
                    className={`${
                      lookupInputs.type === null ? 'hidden' : 'block'
                    } mt-8 sm:bg-white80 sm:p-6 rounded`}
                  >
                    <div
                      onDragOver={onDragOver}
                      onDragLeave={onDragLeave}
                      onDrop={onDrop}
                      style={{
                        border: `${dragOver ? '3px dashed #33333322' : ''}`,
                      }}
                      className="mt-6 py-[22px] flex flex-col items-center gap-[2px] text-center border border-brandBlue border-dashed rounded"
                    >
                      <div className="flex-col items-center hidden sm:flex">
                        {!file && (
                          <img src={uploadCsv} alt="" width={48} height={48} />
                        )}
                        <p className="text-body">
                          {dragOver ? (
                            'Please drop file here'
                          ) : (
                            <span style={{color: file ? '#00000032' : ''}}>
                              Drag and drop Excel file here
                            </span>
                          )}
                        </p>
                      </div>
                      {file ? (
                        <div className="text-body text-sm flex my-2 gap-[3px] items-baseline">
                          <input
                            type="file"
                            id="file"
                            onChange={e => {
                              handleFileInput(e.target.files[0], setFilData);
                            }}
                            accept=".xlsx, .csv"
                          />
                          <label htmlFor="file">Change file</label>
                        </div>
                      ) : (
                        <div className="text-body text-sm flex gap-[3px] items-baseline">
                          <span className="hidden sm:block">or</span>
                          <input
                            type="file"
                            id="file"
                            onChange={e => {
                              handleFileInput(e.target.files[0], setFilData);
                            }}
                            accept=".xlsx, .csv"
                          />
                          <label htmlFor="file">click here to select</label>
                          from your device
                        </div>
                      )}
                      <p className="text-body text-sm flex gap-[3px] items-baseline">
                        {file ? (
                          <>
                            File:{' '}
                            <span className="font-bold text-black ">
                              {file.filename}
                            </span>
                          </>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                    {[
                      'bvn',
                      'dl',
                      'vnin',
                      'phone_number',
                      'cac',
                      'account_number',
                      'basic',
                      'nin',
                      'advance',
                    ].includes(type?.value) && (
                      <div className="mt-2 text-xs text-right">
                        <a
                          href={getDownloadUrl(type?.value)}
                          target="_blank"
                          rel="noreferrer"
                          download
                          className="font-medium text-body sm:text-brandBlue"
                        >
                          Click here{' '}
                          <span className="font-medium text-body">
                            to download template for batch upload
                          </span>
                        </a>
                      </div>
                    )}
                  </div>
                )}
                <PrimaryButton
                  disabled={enable}
                  buttonText="Lookup"
                  xPadding="p-[35px]"
                  onClick={handleLookup}
                  className="w-full mt-6"
                  loading={loading}
                />
              </form>
            )}
          </div>
        )}
      </Modal>

      {step === 2 && lookupInputs?.type?.value === 'global_search' ? (
        <GlobalSearchResultsModal open={open} setOpen={setOpen} />
      ) : step === 2 && lookupInputs?.type?.value !== 'global_search' ? (
        <EasyLookupDetails open={open} setOpen={setOpen} />
      ) : null}

      {step === 3 && (
        <Modal
          show={open}
          onClose={setOpen}
          extraModalClasses="max-w-[581px] rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative h-fit max-h-full"
          modalPosition="justify-center sm:justify-end"
        >
          <div className="px-4 pt-10 pb-4 sm:p-10">
            <header className="flex items-center justify-between">
              <div className="flex flex-wrap items-center gap-2">
                <h3 className="text-base font-medium text-grey">
                  {capitalizeEveryWord(
                    singleBatch?.lookup_type.replace('_', ' '),
                  )}{' '}
                  Verification
                </h3>
                <div className="px-2 py-1 text-xs font-medium uppercase rounded-full text-brandBlue50 bg-brandBlue40 w-fit">
                  Batch upload
                </div>
                <div
                  className={`px-2 py-1 text-xs font-medium uppercase rounded-full  w-fit ${
                    batchUploadProgressPercentage === 100
                      ? 'text-success20 bg-success'
                      : 'text-white bg-status-404'
                  }`}
                >
                  {batchUploadProgressPercentage === 100
                    ? 'Successful'
                    : 'Processing'}
                </div>
              </div>
              <button
                onClick={() => {
                  changeLookupPage(1);
                  setOpen(false);
                }}
                className="hidden sm:block"
              >
                <img src={closeModal} alt="" />
              </button>
            </header>

            <div
              className={`relative h-4 mt-4 mb-8 text-xs font-medium ${
                batchUploadProgressPercentage > 50 && 'text-white'
              }  rounded-full bg-brandBlue40`}
            >
              <span className="absolute left-0 right-0 top-1">
                {(!l_loading && batchUploadProgressPercentage) || 0}%
              </span>
              <progress
                id="file"
                value={(!l_loading && batchUploadProgressPercentage) || 0}
                max="100"
              ></progress>
            </div>

            {l_loading ? (
              <Loader height={30} />
            ) : (
              <TableLayout>
                <thead className="text-xs font-semibold uppercase">
                  <tr className="bg-white80">
                    <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                      number of id
                    </th>
                    <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                      ID type
                    </th>
                    <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                      Last checked
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm">
                  <tr className="text-left border-b cursor-pointer border-grey60">
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {singleBatch?.total_records}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {singleBatch?.lookup_type}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {moment(singleBatch?.last_checked).format(
                        'DD MMM YYYY HH:mm',
                      )}
                    </td>
                  </tr>
                </tbody>
              </TableLayout>
            )}
            {!l_loading && (
              <div className=" print:flex print:items-center print:justify-between text-xs text-body uppercase mt-8 mb-3 px-[5.88%]">
                <span>
                  Performed by:{' '}
                  {singleBatch?.caller_name || singleBatch?.caller_email
                    ? singleBatch?.caller_name || singleBatch?.caller_email
                    : '-'}
                </span>
                <span className="ml-8">
                  Generated:{' '}
                  {singleBatch?.date_created
                    ? moment(singleBatch?.date_created).format(
                        'MMM DD, YYYY hh:mmA',
                      )
                    : moment(new Date()).format('MMM DD, YYYY hh:mmA') || '-'}
                </span>
              </div>
            )}
            <div className="flex flex-col items-center gap-4 mt-6 sm:flex-row">
              {/* <Select
                defaultValue={lookupInputs?.bank_code}
                value={lookupInputs?.bank_code}
                onChange={bank_code =>
                  setLookupInputs({...lookupInputs, bank_code})
                }
                options={[
                  {
                    value: 'xlsx',
                    label: 'XLSX',
                    name: 'XLSX',
                  },
                  {
                    value: 'pdf',
                    label: 'PDF',
                    name: 'PDF',
                  },
                ]}
                placeholder="Select Type"
                components={{DropdownIndicator}}
                classNamePrefix="react-select"
                styles={customStyles}
                isSearchable={false}
                menuPlacement="top"
              /> */}
              <button className="flex items-center justify-between sm:justify-start gap-4 py-[14px] px-4 text-tiny text-grey80 bg-white80 outline outline-1 outline-grey60 rounded w-full sm:w-fit focus-visible:outline-brandBlue">
                XLSX
                {/* <img src={dropdown} alt="" width={16} height={16} /> */}
              </button>
              <PrimaryButton
                buttonText="Download"
                className="flex-1 w-full py-4 rounded sm:w-fit"
                onClick={() => {
                  downloadBatchLookup(singleBatch?.id, () => {
                    setOpen(false);
                    changeLookupPage(1);
                  });
                }}
                loading={loading}
                disabled={!canDownloadHistory}
                style={{cursor: !canDownloadHistory && 'not-allowed'}}
                title={
                  canDownloadHistory
                    ? 'Download history'
                    : 'You do not have permission to download lookup history'
                }
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default connect(state => state, Actions)(EasyLookupModals);
