import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import Select from 'react-select';
import {useMutation} from 'react-query';
import * as Actions from '../../store/actions';
import useDragAndDrop from '../../hooks/useDragAndDrop';
import {
  Modal,
  DropdownIndicator,
  customStyles,
  PrimaryButton,
} from '../../components';
import {Constants, api, handleFileInput} from '../../helpers';

import {performSingleDocumentAnalysis} from '../../requests/queries/document-analysis';
import CaptureModal from './CaptureModal';
import ResultsModal from './ResultsModal';
import {useNavigate} from 'react-router-dom';
import {useAllApps} from '../../hooks/useApps';

function SingleAnalysisModal({
  open,
  setOpen,
  refetch,
  customer360,
  customer_id,
}) {
  const {dragOver, setDragOver, onDragLeave} = useDragAndDrop();
  const [file, setFileData] = useState(null);
  const [selectedApp, setSelectedApp] = useState(null);
  const [frontFile, setFrontFileData] = useState(null);
  const [backFile, setBackFileData] = useState(null);
  const [isGeneratingImgUrl, setIsGeneratingImgUrl] = useState(false);
  const [uploadType, setUploadType] = useState('upload');
  const [capturedFrontImage, setCapturedFrontImage] = useState(null);
  const [capturedBackImage, setCapturedBackImage] = useState(null);
  const [results, setResults] = useState(null);
  const [resultsModal, setResultsModal] = useState(false);
  const {userApps, isLoading: allAppsLoading} = useAllApps();

  const navigate = useNavigate();
  const MAX_FILE_SIZE = 5;

  const [lookupInputs, setLookupInputs] = useState({
    ...Constants?.lookups?.initialLookUpState,
    app: (!allAppsLoading && userApps && userApps[0]) || {},
  });

  const onDrop = (e, setFileData) => {
    e.preventDefault();
    setDragOver(false);
    const selectedFile = e?.dataTransfer?.files[0];
    if (
      selectedFile.type.split('/')[0] !== 'image' &&
      selectedFile.type !== 'application/pdf'
    ) {
      return toast.error('Please provide a valid file type (JPEG, PNG, PDF)');
    }

    handleFileInput(selectedFile, setFileData, MAX_FILE_SIZE);
  };

  const {isLoading, mutate} = useMutation(data =>
    performSingleDocumentAnalysis(data),
  );

  const uploadImage = async base64String => {
    setIsGeneratingImgUrl(true);
    try {
      const response = await api.post('/blog/upload', {image: base64String});
      const res = response.data.image;
      setIsGeneratingImgUrl(false);
      return res;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsGeneratingImgUrl(false);
      return null;
    }
  };

  const handleAnalysis = async () => {
    try {
      let frontImageUrl = null;
      let backImageUrl = null;

      if (uploadType === 'capture') {
        if (capturedFrontImage) {
          frontImageUrl = await uploadImage(capturedFrontImage);
        }

        if (capturedBackImage) {
          backImageUrl = await uploadImage(capturedBackImage);
        }
      } else {
        frontImageUrl = await uploadImage(frontFile?.file);

        if (backFile?.file) {
          backImageUrl = await uploadImage(backFile.file);
        }
      }

      const formData = {
        imageFrontSide: frontImageUrl,
        ...(backImageUrl && {imageBackSide: backImageUrl}),
        app_id: selectedApp.value,
        input_type: 'url',
        customer_id,
      };
      mutate(formData, {
        onSuccess: data => {
          if (customer360 && data?.entity?.customer) {
            return navigate(
              `/customer-360/government-lookup/${data?.entity?.customer}`,
            );
          }
          toast.success('Analysis successful');
          setOpen(false);
          setResultsModal(true);
          setResults(data);
          refetch?.();
          setSelectedApp(null);
          setLookupInputs({
            ...Constants?.lookups?.initialLookUpState,
            type: null,
          });
          setFileData(null);
          setFrontFileData(null);
          setBackFileData(null);
        },
        onError: error => {
          toast.error(error.response.data.error);
        },
      });
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const disabled =
    (!file && !frontFile && !backFile) || !selectedApp || isGeneratingImgUrl;

  const handleModalClose = () => {
    setOpen(false);
    setSelectedApp(null);
    setLookupInputs({
      ...Constants?.lookups?.initialLookUpState,
      type: null,
    });
    setFileData(null);
    setFrontFileData(null);
    setBackFileData(null);
  };

  return (
    <>
      <ResultsModal
        open={resultsModal}
        setOpen={setResultsModal}
        results={results}
      />
      {uploadType === 'capture' ? (
        <CaptureModal
          open={open}
          setOpen={setOpen}
          setUploadType={setUploadType}
          capturedFrontImage={capturedFrontImage}
          setCapturedFrontImage={setCapturedFrontImage}
          capturedBackImage={capturedBackImage}
          setCapturedBackImage={setCapturedBackImage}
          lookupInputs={lookupInputs}
          handleAnalysis={handleAnalysis}
        />
      ) : (
        <Modal
          show={open}
          onClose={handleModalClose}
          extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
          modalPosition="justify-center sm:justify-end"
          modalTitle="Upload documents"
        >
          <form className="mt-6">
            <label htmlFor="app" className="relative mt-6 mb-2">
              Select app
            </label>
            <Select
              defaultValue={selectedApp}
              value={selectedApp}
              onChange={setSelectedApp}
              options={(!allAppsLoading && userApps) || []}
              placeholder="Select app"
              components={{DropdownIndicator}}
              classNamePrefix="react-select"
              className="text-start"
              styles={customStyles}
              menuPlacement="auto"
            />

            {uploadType === 'upload' ? (
              <div className="mt-8 sm:bg-white80 sm:p-6 rounded">
                <p className="text-sm font-medium text-body text-start">
                  Please upload both front and back of your document
                </p>

                <p className="uppercase text-start text-xs font-medium text-grey mt-3">
                  front
                </p>
                <div
                  onDragOver={e => onDrop(e, setFrontFileData)}
                  onDragLeave={onDragLeave}
                  onDrop={e => onDrop(e, setFrontFileData)}
                  style={{
                    border: `${dragOver ? '3px dashed #33333322' : ''}`,
                  }}
                  className="mt-1 py-2 flex flex-col items-center gap-[2px] text-center border border-brandBlue border-dashed rounded"
                >
                  <div className="flex-col items-center hidden sm:flex">
                    <p className="text-body">
                      {dragOver ? (
                        'Please drop file here'
                      ) : (
                        <span
                          className="text-sm"
                          style={{color: frontFile ? '#00000032' : ''}}
                        >
                          Drag and drop file here
                        </span>
                      )}
                    </p>
                  </div>
                  {frontFile ? (
                    <div className="text-body text-sm flex my-2 gap-[3px] items-baseline">
                      <input
                        type="file"
                        id="front_file"
                        onChange={e => {
                          handleFileInput(
                            e.target.files[0],
                            setFrontFileData,
                            MAX_FILE_SIZE,
                          );
                        }}
                        accept="image/jpeg,image/png,application/pdf,"
                      />
                      <label htmlFor="front_file">Change file</label>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center gap-3">
                      <div className="text-body text-sm flex gap-[3px] items-baseline">
                        <span className="hidden sm:block">or</span>
                        <input
                          type="file"
                          id="front_file"
                          onChange={e => {
                            handleFileInput(
                              e.target.files[0],
                              setFrontFileData,
                              MAX_FILE_SIZE,
                            );
                          }}
                          accept="image/jpeg,image/png,application/pdf,"
                        />
                        <label htmlFor="front_file">click here to select</label>
                        from your device
                      </div>
                    </div>
                  )}
                  <p className="text-body text-sm flex gap-[3px] items-baseline">
                    {frontFile ? (
                      <>
                        File:{' '}
                        <span className="font-bold text-black ">
                          {frontFile.filename}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                </div>

                <p className="uppercase text-start text-xs font-medium text-grey mt-4">
                  back{' '}
                  <span className="text-grey40 capitalize italic">
                    (optional)
                  </span>
                </p>
                <div
                  onDragOver={e => onDrop(e, setBackFileData)}
                  onDragLeave={onDragLeave}
                  onDrop={e => onDrop(e, setBackFileData)}
                  style={{
                    border: `${dragOver ? '3px dashed #33333322' : ''}`,
                  }}
                  className="mt-1 py-2 flex flex-col items-center gap-[2px] text-center border border-brandBlue border-dashed rounded"
                >
                  <div className="flex-col items-center hidden sm:flex">
                    <p className="text-body">
                      {dragOver ? (
                        'Please drop file here'
                      ) : (
                        <span
                          className="text-sm"
                          style={{color: backFile ? '#00000032' : ''}}
                        >
                          Drag and drop file here
                        </span>
                      )}
                    </p>
                  </div>
                  {backFile ? (
                    <div className="text-body text-sm flex my-2 gap-[3px] items-baseline">
                      <input
                        type="file"
                        id="back_file"
                        onChange={e => {
                          handleFileInput(
                            e.target.files[0],
                            setBackFileData,
                            MAX_FILE_SIZE,
                          );
                        }}
                        accept="image/jpeg,image/png,application/pdf,"
                      />
                      <label htmlFor="back_file">Change file</label>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center gap-3">
                      <div className="text-body text-sm flex gap-[3px] items-baseline">
                        <span className="hidden sm:block">or</span>
                        <input
                          type="file"
                          id="back_file"
                          onChange={e => {
                            handleFileInput(
                              e.target.files[0],
                              setBackFileData,
                              MAX_FILE_SIZE,
                            );
                          }}
                          accept="image/jpeg,image/png,application/pdf,"
                        />
                        <label htmlFor="back_file">click here to select</label>
                        from your device
                      </div>
                    </div>
                  )}
                  <p className="text-body text-sm flex gap-[3px] items-baseline">
                    {backFile ? (
                      <>
                        File:{' '}
                        <span className="font-bold text-black ">
                          {backFile.filename}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                </div>
              </div>
            ) : null}

            <div className="mt-6 flex items-center gap-2 w-full">
              <PrimaryButton
                type="submit"
                className="w-full"
                disabled={disabled}
                buttonText="Upload"
                loading={isLoading || isGeneratingImgUrl}
                onClick={handleAnalysis}
              />
              {/* <SecondaryButton
                type="button"
                className="w-[39.9%]"
                buttonText="Use camera instead"
                onClick={handleUseCamera}
              /> */}
            </div>
          </form>
        </Modal>
      )}
    </>
  );
}

export default connect(state => state, Actions)(SingleAnalysisModal);
