import React from 'react';
import Chart from 'react-apexcharts';
import {shortenLargeNumbers} from '../../helpers/shortenLargeNumbers';

function DashboardPieChartLeft({data = {}}) {
  const options = {
    colors: ['#A13E3F', '#6FCBD4'],
    dataLabels: {
      enabled: false,
    },
    labels: ['Failed API calls', 'Successful API calls'],
    legend: false,
    stroke: {
      width: 0,
    },
    tooltip: {
      theme: false,
    },
  };

  const series =
    data && data?.total !== 0
      ? [Number(data?.failed), Number(data?.successful)]
      : [1];

  return (
    <div
      style={{maxWidth: '100%', width: '234px'}}
      className="relative  flex justify-center items-center"
    >
      <Chart
        options={options}
        series={series}
        type="donut"
        height={264}
        width="100%"
      />
      <div
        className="absolute flex flex-col items-center w-fit"
        style={{top: '34%'}}
      >
        <h1 className="font-semibold text-xxl text-grey">
          {shortenLargeNumbers(data?.total || 0) || 0}
        </h1>
        <span className="text-xs font-medium uppercase text-grey">
          Total API calls
        </span>
      </div>
    </div>
  );
}

function DashboardPieChartRight({totalFailedApiCalls, breakdown, remainder}) {
  const labels = breakdown?.length
    ? [
        ...(breakdown?.map(({label}) => label) || []),
        ...(remainder ? ['Others'] : []),
      ]
    : remainder
    ? ['Others']
    : ['No api calls'];
  const series = breakdown?.length
    ? [
        ...(breakdown?.map(({count}) => count) || []),
        ...(remainder ? [remainder] : []),
      ]
    : remainder
    ? [remainder]
    : [1];
  const colors = breakdown?.length
    ? [
        ...(breakdown?.map(({color}) => color) || []),
        ...(remainder ? ['#999999'] : []),
      ]
    : remainder
    ? ['#999999']
    : ['#F4F7FC'];

  const options = {
    colors,
    dataLabels: {
      enabled: false,
    },
    labels,
    legend: false,
    stroke: {
      width: 0,
    },
    tooltip: {
      theme: false,
    },
  };

  return (
    <div
      style={{maxWidth: '100%', width: '234px'}}
      className="relative  flex justify-center items-center"
    >
      <Chart
        options={options}
        series={series}
        type="donut"
        height={264}
        width="100%"
      />
      <div
        className="absolute flex flex-col items-center justify-center w-fit"
        style={{top: '32%'}}
      >
        <h1 className="font-semibold text-xxl text-grey">
          {shortenLargeNumbers(totalFailedApiCalls || 0)}
        </h1>
        <span className="text-xs font-medium uppercase text-grey">
          Failed API calls
        </span>
      </div>
    </div>
  );
}

export {DashboardPieChartLeft, DashboardPieChartRight};
