import moment from 'moment';
import React, {useCallback, useMemo, useState} from 'react';
import Chart from 'react-apexcharts';
import {connect} from 'react-redux';
import {calendar, dropdownGrey} from '../../assets/images/images';
import {Checkboxes, CustomDateRadio, Dropdown, Radios} from '../../components';
import {Constants} from '../../helpers';
import {CheckboxContextProvider} from '../../helpers/context/CheckboxContext';
import {customDateFilters} from '../../helpers/customDateFilters';
import {transformLogsGraphData} from '../../helpers/transforApiLogsChart';
import * as Actions from '../../store/actions';
import {useAllApps} from '../../hooks/useApps';

function LineChart({...otherProps}) {
  const {
    graphData,
    start,
    end,
    setFilters,
    filters: currentFilters,
    isLoading,
    appFilters,
  } = otherProps;

  const allLogsData = transformLogsGraphData(graphData?.logs || [], start, end);

  const {options, series} = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      colors: ['#3F7CDB', '#F15D5F'],
      fill: {
        opacity: 1,
      },
      grid: {
        borderColor: '#CECED2',
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        color: '#5022c3',
        fontFamily: 'Atakk',
        fontSize: '10px',
        fontWeight: 500,
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          width: 14,
          height: 14,
          radius: 4,
        },
      },
      stroke: {
        curve: 'smooth',
        width: 1,
      },
      tooltip: {
        marker: {
          show: false,
        },
        theme: false,
        x: {
          show: false,
        },
      },
      xaxis: {
        categories: allLogsData?.period || [
          'Financial',
          'General',
          'KYC',
          'wallet',
          'messaging',
          'ML',
          'Webhook',
          'Purchase',
        ],
        labels: {
          style: {
            colors: '#A2A3A5',
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#A2A3A5',
          },
        },
      },
    },
    series: allLogsData?.newFormatted || [
      {
        data: [23, 34, 12, 54, 32, 43, 22, 65],
        name: 'SUCCESSFUL',
      },
      {
        data: [10, 84, 41, 30, 12, 26, 56, 32],
        name: 'FAILED',
      },
    ],
  };

  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date('01/08/2021'));
  const [endDate, setEndDate] = useState(new Date());
  const [customDate, setCustomDate] = useState(false);
  const [dateOpen] = useState(false);
  const maxDate = new Date();

  const onCustomDateCheck = e => {
    const {checked} = e.target;
    if (checked) {
      setDate(1);
      setCustomDate(true);
    }
  };

  const onDateCheck = useCallback(
    e => {
      const {value} = e.target;
      customDateFilters({
        value,
        appFilters: currentFilters,
        onCheck: setFilters,
      });
      setDate(value);

      setCustomDate(false);
    },
    [currentFilters, setFilters],
  );

  const {apps, modes, services} =
    (!isLoading && appFilters && appFilters) || {};

  const {userApps} = useAllApps();
  const getAppNameById = useCallback(
    appId => userApps && userApps?.find(app => app?.value === appId),
    [userApps],
  );

  const APP_DATA = useMemo(
    () =>
      apps?.map(app => ({
        label: getAppNameById(app)?.label,
        value: app,
        name: getAppNameById(app)?.name,
      })),
    [apps, getAppNameById],
  );

  const MODE_DATA = useMemo(
    () =>
      modes?.map(mode => ({
        label: mode,
        value: mode,
        name: mode,
      })),
    [modes],
  );
  const SERVICES_DATA = useMemo(
    () =>
      services?.map(service => ({
        label: service.split('_').join(' '),
        value: service,
        name: service,
      })),
    [services],
  );

  return (
    <CheckboxContextProvider>
      <div className="hidden py-4 rounded-lg md:block bg-white80">
        <div className="flex items-center justify-end gap-4 mb-4 mr-8">
          <div className="relative">
            <Dropdown
              dropdownBtn={
                <div className="flex items-end gap-12 bg-white py-[11px] px-4 rounded cursor-pointer">
                  <span className="text-xs font-medium uppercase text-body">
                    Date
                  </span>
                  <img src={calendar} alt="" />
                </div>
              }
            >
              <Radios
                className="absolute z-10 w-64 mt-2 shadow-sm"
                data={Constants.lookups.dates}
                filterName="date"
                onCheck={onDateCheck}
                selectedCount={date === '' ? 0 : 1}
                onClick={onDateCheck}
                activeIndex={dateOpen}
                radioValue={date}
              >
                <label
                  htmlFor="custom_date"
                  className="relative flex flex-row items-center gap-2 pl-4 font-normal transition duration-500 ease-in-out"
                >
                  <input
                    type="radio"
                    value="custom_date"
                    id="custom_date"
                    name="date"
                    className="w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue"
                    onClick={onCustomDateCheck}
                  />
                  <span className="text-sm text-grey">Specific date range</span>
                </label>
                {customDate && (
                  <CustomDateRadio
                    selectedStart={startDate}
                    selectedEnd={endDate}
                    onChangeStart={date => {
                      const start = moment(date).format('YYYY-MM-DD HH:mm:ss');
                      setFilters({...currentFilters, start});
                      setStartDate(date);
                    }}
                    onChangeEnd={date => {
                      const end = moment(date).format('YYYY-MM-DD HH:mm:ss');
                      setFilters({...currentFilters, end});
                      setEndDate(date);
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={maxDate}
                    minDate={startDate}
                  />
                )}
              </Radios>
            </Dropdown>
          </div>

          <div className="relative">
            <Dropdown
              dropdownBtn={
                <div className="flex items-end gap-12 bg-white py-[11px] px-4 rounded cursor-pointer">
                  <span className="text-xs font-medium uppercase text-body">
                    mode
                  </span>
                  <img src={dropdownGrey} alt="" />
                </div>
              }
            >
              <div className="absolute z-10 w-full mt-2 shadow-sm">
                <Checkboxes
                  className="rounded"
                  data={MODE_DATA}
                  onCheck={data => {
                    const mode = Object.values(data).toString();
                    setFilters({...currentFilters, mode});
                  }}
                  values={MODE_DATA?.map(d => d.value) || []}
                  // onCheck={newData => {
                  //   const filterValues = Object.values(newData)[0]?.split(',');
                  //   onCheck(newData);
                  //   setCount({
                  //     ...count,
                  //     [filterName]: filterValues,
                  //   });
                  // }}
                  filterName="mode"
                />
              </div>
            </Dropdown>
          </div>

          <div className="relative">
            <Dropdown
              dropdownBtn={
                <div className="flex items-end gap-12 bg-white py-[11px] px-4 rounded cursor-pointer">
                  <span className="text-xs font-medium uppercase text-body">
                    app name
                  </span>
                  <img src={dropdownGrey} alt="" />
                </div>
              }
            >
              <div className="absolute z-10 w-full mt-2 shadow-sm">
                <Checkboxes
                  className="rounded"
                  data={APP_DATA}
                  values={APP_DATA?.map(d => d.value) || []}
                  onCheck={data => {
                    const apps = Object.values(data).toString();
                    setFilters({...currentFilters, apps});
                  }}
                  filterName="apps"
                />
              </div>
            </Dropdown>
          </div>

          <div className="relative">
            <Dropdown
              dropdownBtn={
                <div className="flex items-end gap-12 bg-white py-[11px] px-4 rounded cursor-pointer">
                  <span className="text-xs font-medium uppercase text-body">
                    service
                  </span>
                  <img src={dropdownGrey} alt="" />
                </div>
              }
            >
              <div className="absolute z-10 w-full mt-2">
                <Checkboxes
                  className="rounded"
                  data={SERVICES_DATA}
                  values={SERVICES_DATA?.map(d => d.value) || []}
                  onCheck={data => {
                    const services = Object.values(data).toString();
                    setFilters({...currentFilters, services});
                  }}
                  filterName="services"
                />
              </div>
            </Dropdown>
          </div>
        </div>
        <Chart options={options} series={series} type="line" height={319} />
      </div>
    </CheckboxContextProvider>
  );
}

export default connect(state => state, Actions)(LineChart);
