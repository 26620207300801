import React from 'react';
import moment from 'moment';
import {useQuery} from 'react-query';
import platform from 'platform';
import {Loader, Modal, RowData} from '../../../components';
import {getAuditLog} from '../../../requests/queries/audit-logs';

export default function AuditLogsModal({selectedLog, open, setOpen}) {
  const {data, isLoading} = useQuery(
    ['auditLogs', {id: selectedLog?.id}],
    () => getAuditLog(selectedLog?.id),
    {
      enabled: !!selectedLog?.id,
    },
  );

  const userAgentString = data?.device;
  const parsedPlatform = platform.parse(userAgentString);

  const IP_DATA = [
    {leftText: 'Company', rightText: data?.company || '-'},
    {
      leftText: 'action',
      rightText: data?.action
        ?.replace(/user\./g, '')
        .replace(/\./g, ' ')
        .replace(/([a-z])([A-Z])/g, '$1 $2'),
    } || '-',
    {leftText: 'OS', rightText: parsedPlatform.os.toString() || '-'},
    {
      leftText: 'Model',
      rightText: `${parsedPlatform.product || '-'} +${
        parsedPlatform.architecture || '-'
      }`,
    },
    {leftText: 'Browser', rightText: parsedPlatform.name || '-'},
    {leftText: 'IP', rightText: data?.source_ip || '-'},
    {leftText: 'name', rightText: data?.actor_name || '-'},
    {leftText: 'email', rightText: data?.actor_email || '-'},
    {
      leftText: 'Time created',
      rightText: moment(data?.createdAt).format('Do MMM, YYYY, hh:mmA') || '-',
    },
    {
      leftText: 'Time updated',
      rightText: moment(data?.updatedAt).format('Do MMM, YYYY, hh:mmA') || '-',
    },
  ];

  return (
    <Modal
      modalTitle={
        isLoading ? '-' : moment(data?.createdAt).format('Do MMM, YYYY, hh:mmA')
      }
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      {isLoading ? (
        <Loader height={30} />
      ) : (
        <div className="px-0 py-6 sm:pb-0 sm:py-12">
          <div className="mt-2">
            <p className="font-medium text-grey text-start">Device Info</p>
            <div className="flex flex-col mt-4">
              {IP_DATA.map(({leftText, rightText}, i) => (
                <RowData key={i} leftText={leftText} rightText={rightText} />
              ))}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}
