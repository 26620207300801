import {toast} from 'react-toastify';
import reduxApi from '../../helpers/reduxApi';
import {
  VERIFICATIONS_LOADING,
  VERIFICATIONS,
  SESSION_SUMMARY,
  KYC_CUSTOMER,
  TIMELINE,
  DEVICE_IP,
  ADDRESS,
  UPDATED,
  DO_NOTHING,
  KYC_BUSINESS,
  EXPORT_VERIFICATIONS_LOADING,
} from '../types';

export const getVerifications = ({
  appIds,
  limit,
  start,
  end,
  mode,
  page,
  status,
  type,
  term,
  pending,
}) =>
  reduxApi(
    `/users/kyc-dashboard?app_ids=${appIds || ''}&limit=${limit || 10}&start=${
      start || ''
    }&end=${end || ''}&type=${type || ''}&page=${page || 1}&mode=${
      mode || ''
    }&status=${status || ''}&term=${term || ''}&pending=${pending || false}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        const noFilter = !status && !mode && !start && !end;
        const verificationData =
          noFilter && data?.data.length === 0 ? undefined : data;

        dispatch({payload: verificationData, type: VERIFICATIONS});
      },
    },
    {
      error: 'ERROR',
      loading: VERIFICATIONS_LOADING,
      responder: VERIFICATIONS,
    },
  );
export const getSessionSummary = sessionId =>
  reduxApi(
    `/users/kyc-dashboard/session/${sessionId}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: null, type: SESSION_SUMMARY});
        dispatch({payload: data, type: SESSION_SUMMARY});
        // if (
        //   data?.data?.government_data?.customer_id &&
        //   data?.data?.government_data?.customer_id !== 'undefined'
        // ) {
        //   data?.data?.Verification?.country?.toLowerCase() === 'angola'
        //     ? dispatch(
        //         getKYCCustomer(
        //           data?.data?.government_data?.customer_id,
        //           'angola',
        //         ),
        //       )
        //     : dispatch(
        //         getKYCCustomer(data?.data?.government_data?.customer_id),
        //       );
        // }
        if (data?.data?.business_data?.customer_id) {
          dispatch(getKYCBusiness(data?.data?.business_data?.customer_id));
        }
      },
    },
    {
      error: 'ERROR',
      loading: VERIFICATIONS_LOADING,
      responder: SESSION_SUMMARY,
    },
  );
export const getKYCCustomer = (customerId, country) =>
  reduxApi(
    `/users/kyc-dashboard/customer?customer_id=${customerId}${
      country && country.toLowerCase() === 'angola' ? '&country=angola' : ''
    }`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: null, type: KYC_CUSTOMER});
        dispatch({payload: data, type: KYC_CUSTOMER});
      },
    },
    {
      error: 'ERROR',
      loading: DO_NOTHING,
      responder: KYC_CUSTOMER,
    },
  );
export const getKYCBusiness = customerId =>
  reduxApi(
    `/users/kyc-dashboard/customer?customer_id=${customerId}&country=nigeria_business`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: null, type: KYC_BUSINESS});
        dispatch({payload: data, type: KYC_BUSINESS});
      },
    },
    {
      error: 'ERROR',
      loading: DO_NOTHING,
      responder: KYC_BUSINESS,
    },
  );
export const getTimeline = verificationId =>
  reduxApi(
    `/users/kyc-dashboard/session/${verificationId}/timeline`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: TIMELINE});
      },
    },
    {
      error: 'ERROR',
      loading: VERIFICATIONS_LOADING,
      responder: TIMELINE,
    },
  );
export const getIpDeviceCheck = verificationId =>
  reduxApi(
    `/users/kyc-dashboard/session/${verificationId}/device-ip`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: null, type: DEVICE_IP});
        dispatch({payload: data, type: DEVICE_IP});
      },
    },
    {
      error: 'ERROR',
      loading: VERIFICATIONS_LOADING,
      responder: DEVICE_IP,
    },
  );
export const getAddress = sessionId =>
  reduxApi(
    `/users/kyc-dashboard/session/${sessionId}/address`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: null, type: ADDRESS});
        dispatch({payload: data, type: ADDRESS});
      },
    },
    {
      error: 'ERROR',
      loading: VERIFICATIONS_LOADING,
      responder: ADDRESS,
    },
  );
export const reviewSession = ({reviewData, referenceId, success}) =>
  reduxApi(
    `/users/kyc-dashboard/session/${referenceId}/review`,
    'POST',
    {...reviewData},
    {
      report: true,
      success: (data, dispatch) => {
        toast.success(data.message);
        dispatch(getSessionSummary(referenceId));
        success();
      },
    },
    {
      error: 'ERROR',
      loading: VERIFICATIONS_LOADING,
      responder: UPDATED,
    },
  );
export const exportVerifications = ({
  appIds,
  term,
  end,
  type,
  start,
  page,
  mode,
  status,
}) =>
  reduxApi(
    `/users/kyc-dashboard/export?app_ids=${appIds || ''}&start=${
      start || ''
    }&end=${end || ''}&type=${type || ''}&page=${page || 1}&mode=${
      mode || ''
    }&status=${status || ''}&term=${term || ''}`,
    'GET',
    null,
    {
      report: true,
      success: data => {
        toast.success(data?.message);
      },
    },
    {
      error: 'ERROR',
      loading: EXPORT_VERIFICATIONS_LOADING,
      responder: 'VERIFICATIONS_EXPORT',
    },
  );
