import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {Link, NavLink, useParams} from 'react-router-dom';
import {chevronRight, customer360Ic} from '../../assets/images/images';
import {
  DashboardLayout,
  PrimaryButton,
  SecondaryButton,
  SkeletonLoader,
} from '../../components';
import {Constants} from '../../helpers';
import {useQueryClient, useMutation, useQuery} from 'react-query';
import {
  addComment,
  deleteComment,
  fetchBusinessDetails,
  fetchComments,
} from '../../requests/queries/business360';
import {toast} from 'react-toastify';
import moment from 'moment';
import {ROUTES, getInitials} from './LayoutConstants';
import EasyLookupModals from '../EasyLookup/EasyLookupModals';

function Layout({children}) {
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState(3);

  const [comment, setComment] = useState('');
  const [comment_id, setCommentId] = useState('');

  const queryClient = useQueryClient();
  const {slug} = useParams();

  const {data: comments, isLoading: commentsLoading} = useQuery(
    'business-comments',
    () => fetchComments(slug),
  );
  const {data: businessDetails, isLoading: userDetailsLoading} = useQuery(
    'business-details',
    () => fetchBusinessDetails(slug),
  );

  const BUSINESS_PROFILE =
    !userDetailsLoading && businessDetails && businessDetails?.entity;

  const PROFILE = [
    {
      field_name: 'Business name',
      value: BUSINESS_PROFILE?.company_name || '-',
    },
    {
      field_name: 'Business number',
      value: BUSINESS_PROFILE?.id_number || '-',
    },
  ];

  const {isLoading: addCommentLoading, mutate: addUserComment} = useMutation(
    'add-business-comments',
    data => addComment(slug, data),
    {
      onSuccess: () => {
        toast.success('Comment added successfully');
        queryClient.invalidateQueries('business-comments');
      },
    },
  );

  const {isLoading: deleteCommentLoading, mutate: deleteUserComment} =
    useMutation(
      'delete-business-comments',
      commentId => deleteComment(slug, commentId),
      {
        onSuccess: () => {
          toast.success('Comment deleted successfully');
          queryClient.invalidateQueries('business-comments');
        },
      },
    );

  const handleAddComment = useCallback(() => {
    if (!comment) {
      return toast.error('Please enter a comment');
    }
    if (comment.length) {
      addUserComment({comment});
      setComment('');
    }
  }, [comment, addUserComment]);

  const handleLoadMore = () => {
    setNext(next + 3);
  };

  return (
    <DashboardLayout
      xlLeftMargin="xl:ml-12"
      xlRightPadding="xl:pr-0"
      overFlow="overflow-none"
      breadCrumbs={
        <div className="flex items-center">
          <img src={customer360Ic} alt="" width={18} height={18} />
          <Link className="ml-2 -mb-1" to={Constants.PAGES_URL.BUSINESS_360}>
            Business 360
          </Link>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1 cursor-pointer">
            {BUSINESS_PROFILE?.company_name || ''}
          </p>
        </div>
      }
    >
      <EasyLookupModals
        open={open}
        setOpen={setOpen}
        lookupType="Single"
        batch={false}
        lookupCategory="Business"
        business360={true}
        businessData={{
          id_number: BUSINESS_PROFILE?.id_number,
          name: BUSINESS_PROFILE?.company_name,
          id: slug,
        }}
      />

      <div className="flex items-center justify-between gap-6 mt-6 mb-6 md:gap-0 sm:mt-0 mr-[76px]">
        <h2 className="hidden text-lg font-medium text-grey sm:block">
          {BUSINESS_PROFILE?.company_name || ''}
        </h2>
        <h2 className="block text-lg font-medium text-grey sm:hidden">
          {BUSINESS_PROFILE?.company_name || ''}
        </h2>

        <PrimaryButton
          buttonText="Perform Business Lookup"
          onClick={() => setOpen(true)}
        />
      </div>

      <div className="mt-4 rounded-xl bg-white p-1 border border-[#E1E4EA] flex items-center gap-2 overflow-x-auto mr-[76px]">
        {ROUTES.map((route, index) => (
          <NavLink
            key={index}
            // end
            to={route.path.replace(':slug', slug)}
            state={{businessID: BUSINESS_PROFILE.id_number}}
            className={({isActive}) =>
              `pt-[13px] pb-[7px] px-4 text-sm  flex items-center justify-center rounded-lg shadow-[0px_4px_32px_-4px,rgba(199,196,196,0.18)] ${
                isActive
                  ? 'text-dojahBlue bg-blue-100'
                  : 'text-grey-500 bg-transparent'
              }`
            }
          >
            {route.name}
          </NavLink>
        ))}
      </div>

      <div className="mt-4 flex w-full">
        <div className="w-[65%]">{children}</div>
        <div className="w-[35%] p-6 bg-white rounded-xl flex flex-col gap-4 border border-grey-50">
          <div className="p-6 flex flex-col items-center gap-2 bg-white80 rounded">
            {userDetailsLoading ? (
              <SkeletonLoader
                style={{
                  height: 85,
                  width: 85,
                  borderRadius: '50%',
                }}
              />
            ) : (
              <div className="rounded-full w-[89px] h-[89px] flex items-center justify-center text-xxl font-medium text-blue bg-brandBlue40">
                <span className="-mb-2">
                  {getInitials(BUSINESS_PROFILE?.company_name, 2)}
                </span>
              </div>
            )}
            {userDetailsLoading ? (
              [1, 2].map(d => (
                <div
                  key={d}
                  className="py-[14px] flex items-center justify-between border-b border-grey-100"
                >
                  <SkeletonLoader />
                </div>
              ))
            ) : (
              <div className="rounded w-full mt-3">
                {PROFILE?.map((item, index) => (
                  <div
                    key={index}
                    className="py-[14px] flex items-center justify-between border-b border-grey-100"
                  >
                    <p className="text-xs text-grey font-medium uppercase text-left">
                      {item.field_name}
                    </p>

                    <p className="text-sm text-body w-100 text-right">
                      {item?.value}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="bg-white80 rounded p-4 flex items-start gap-6 justify-between">
            <div className="flex flex-col gap-2">
              <p className="text-xs font-medium uppercase text-grey-500">
                date/time created
              </p>
              <p className="font-medium text-grey text-small">
                {moment(BUSINESS_PROFILE.date_created).format(
                  'Do MMMM YYYY, h:mmA',
                ) || '-'}
              </p>
            </div>
            {/*<div className="flex flex-col gap-2">*/}
            {/*  <p className="text-xs font-medium uppercase text-grey-500">*/}
            {/*    date/time updated*/}
            {/*  </p>*/}
            {/*  <p className="font-medium text-grey text-small">*/}
            {/*    22nd April 2023, 10:00AM*/}
            {/*  </p>*/}
            {/*</div>*/}
          </div>

          <div className="p-4 rounded bg-white80 grow xl:grow-0">
            <div className="flex items-center gap-1">
              <p className="text-xs font-medium uppercase text-grey">
                Comments
              </p>
              <div className="flex items-center justify-center w-4 h-4 pt-[2px] text-xs text-white rounded-full bg-brandBlue">
                {comments && comments?.comments?.length}
              </div>
            </div>
            <div className="relative mt-4">
              <textarea
                onChange={e => setComment(e.target.value)}
                value={comment}
                name="comment"
                id="comment"
                data-smallertextarea={''}
              />
              <PrimaryButton
                buttonText={addCommentLoading ? 'sending...' : 'Send'}
                fontSize="text-xs"
                xPadding="px-2"
                yPadding="pt-[9px] pb-[7px]"
                className="absolute right-2 bottom-4"
                onClick={handleAddComment}
                disabled={!comment || addCommentLoading}
              />
            </div>

            <div
              style={{
                opacity: addCommentLoading || commentsLoading ? 0.4 : 1,
              }}
            >
              {(!addCommentLoading || !commentsLoading) &&
                comments &&
                comments?.comments
                  ?.slice(0, next)
                  ?.map((comment, index, data) => (
                    <div
                      key={comment?._id}
                      className={`flex flex-col gap-1 mt-3 pb-2 ${
                        index === data.length - 1
                          ? ''
                          : 'border-b border-b-[#e9e9e9]'
                      }`}
                      onMouseEnter={() => setCommentId(comment?._id)}
                      onMouseLeave={() => setCommentId('')}
                    >
                      <div className="flex items-center gap-2">
                        <div className="w-6 h-6 bg-brandBlue40 text-brandBlue flex items-center justify-center rounded-full text-xs font-medium">
                          {getInitials(
                            `${comment?.author.first_name} ${comment?.author.last_name}`,
                          ) || '-'}
                        </div>
                        <p className="font-medium text-sm text-grey">
                          {`${comment?.author.first_name || '-'}
                          ${comment?.author.last_name || ''}`}
                        </p>
                      </div>
                      <p className="text-sm  text-body">
                        {comment?.comment || '-'}
                      </p>
                      <div className="relative">
                        <p className="text-[9px] text-grey40 font-medium uppercase">
                          {moment(comment.createdAt).fromNow() || ''}
                        </p>
                        {comment_id === comment._id && (
                          <SecondaryButton
                            buttonText={
                              comment_id === comment._id && deleteCommentLoading
                                ? 'deleting...'
                                : 'delete'
                            }
                            fontSize="text-[9px]"
                            btnHeight="h-7"
                            xPadding="px-2"
                            yPadding="pt-[4px] pb-[5px]"
                            className="absolute right-2 bottom-2 text-danger"
                            onClick={() => {
                              setCommentId(comment?._id);
                              deleteUserComment(comment?._id);
                            }}
                            disabled={
                              comment_id === comment._id && deleteCommentLoading
                            }
                          />
                        )}
                      </div>
                    </div>
                  ))}
            </div>
            {!commentsLoading && next < comments?.comments?.length && (
              <SecondaryButton
                className="flex items-start gap-2 mt-4"
                fontSize="text-xs"
                xPadding="px-2"
                yPadding="pt-[9px] pb-[7px]"
                btnHeight="h-7"
                buttonText="Load more"
                onClick={handleLoadMore}
              />
            )}
          </div>

          {/*<div className="p-4 rounded bg-white80 grow lg:grow-0 text-start">*/}
          {/*  <p className="text-xs font-medium uppercase text-grey">*/}
          {/*    Activity log*/}
          {/*  </p>*/}
          {/*  <div className="mt-4">*/}
          {/*    <div className="grid">*/}
          {/*      {TIMELINE?.slice(0, next)?.map((time, index) => (*/}
          {/*        <div*/}
          {/*          key={index}*/}
          {/*          className="activity-parent grid gap-[6px] grid-rows-[2rem] h-full py-[4px]"*/}
          {/*        >*/}
          {/*          <span className="relative grid items-center text-xs font-medium text-body activity-time after:content-[''] after:absolute after:border-r after:border-dashed after:border-grey60 after:h-[40px] after:right-[-3px]">*/}
          {/*            {time.datetime.split(' ').pop()?.replace('+00:00', '')}*/}
          {/*          </span>*/}
          {/*          <span className="text-sm lowercase  veri text-body activity-description leading-[12px]  relative grid items-center pl-2 before:content-[''] before:absolute before:bg-brandBlue before:rounded-full before:w-[5px] before:h-[5px] before:top-1/2 before:left-[-3px] before:-mt-[1px]">*/}
          {/*            {time?.event_value ?? '-'}*/}
          {/*          </span>*/}
          {/*        </div>*/}
          {/*      ))}*/}
          {/*    </div>*/}

          {/*    {next < TIMELINE?.length && (*/}
          {/*      <SecondaryButton*/}
          {/*        className="flex items-start gap-2 mt-4"*/}
          {/*        fontSize="text-xs"*/}
          {/*        xPadding="px-2"*/}
          {/*        yPadding="pt-[9px] pb-[7px]"*/}
          {/*        btnHeight="h-7"*/}
          {/*        buttonText="Load more"*/}
          {/*        onClick={handleLoadMore}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default connect(state => state, null)(Layout);
