import React, {useCallback, useMemo, useState} from 'react';
import Select from 'react-select';
import {
  Modal,
  customStyles,
  DropdownIndicator,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import {useAllApps} from '../../hooks/useApps';
import {useDispatch} from 'react-redux';
import {webhookSubscription} from '../../store/actions';
// aml, kyc_widget, sms, ngn_wallet, address
const SERVICES = [
  {label: 'SMS', value: 'sms'},
  {label: 'NG Wallet', value: 'ngn_wallet'},
  {label: 'KYC Widget', value: 'kyc_widget'},
  {label: 'Aml Monitoring', value: 'aml'},
  {label: 'Address', value: 'address'},
];

export default function WebhookSubscribeModal({open, setOpen}) {
  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState('');
  const {userApps: ALL_APPS} = useAllApps();
  const dispatch = useDispatch();

  const data = useMemo(
    () => ({
      appId: selectedApp?.value,
      service: selectedService?.value,
      webhook: webhookUrl,
    }),
    [selectedApp?.value, selectedService?.value, webhookUrl],
  );

  const handeSubscribe = useCallback(async () => {
    setLoading(true);
    if (data) {
      await dispatch(webhookSubscription(data, setOpen));
      setLoading(false);
    }
  }, [dispatch, setOpen, data]);

  return (
    <Modal
      show={open}
      modalTitle="Subscribe"
      onClose={setOpen}
      extraModalClasses="max-w-[448px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <p className="my-6 text-left text-sm text-body">
        Set when and how you would like to get latest information about your
        user(s).
      </p>
      <form className="text-left">
        <label htmlFor="app_name" className="mt-6 mb-2 relative">
          App name
        </label>
        <Select
          defaultValue={selectedApp}
          value={selectedApp}
          onChange={app => setSelectedApp(app)}
          options={ALL_APPS}
          placeholder="Select an app..."
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="auto"
        />

        <label htmlFor="webhook_url" className="mt-6">
          <span className="text-sm ">Webhook URL</span>
          <input
            type="url"
            id="webhook_url"
            className="mt-2"
            value={webhookUrl}
            onChange={e => setWebhookUrl(e.target.value)}
            placeholder="Add a webhook URL"
            required
          />
        </label>

        <label htmlFor="service_name" className="mt-6 mb-2 relative">
          Services name
        </label>
        <Select
          defaultValue={selectedService}
          value={selectedService}
          onChange={service => setSelectedService(service)}
          options={SERVICES}
          placeholder="Select a service..."
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="auto"
        />

        <div className="flex items-center gap-2 mt-6 basis-full">
          <PrimaryButton
            buttonText="Subscribe"
            className="basis-[61.52%]"
            loading={loading}
            onClick={handeSubscribe}
            disabled={!Object.values(data).every(d => d)}
          />
          <SecondaryButton
            buttonText="Cancel"
            onClick={() => setOpen(false)}
            className="basis-[38.48%]"
          />
        </div>
      </form>
    </Modal>
  );
}
