import React, {useRef, useState, useCallback, useMemo} from 'react';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {useMutation, useQueryClient} from 'react-query';
import {Modal, PrimaryButton} from '../../../../components';
import {baasLock} from '../../../../assets/images/images';
import {CreatePinInput} from './CreatePinInput';
import {createBAASPin} from '../../../../requests/queries/baas';
import {GoBack} from './GoBack';

const PIN_LENGTH = 4;
const PIN_ARRAY = Array(PIN_LENGTH).fill('');
export const CreatePinModal = ({show, onClose}) => {
  const {company} = useSelector(state => state?.auth?.userDetails || {});
  const queryClient = useQueryClient();
  const [pin, setPin] = useState(PIN_ARRAY);
  const [confirmPin, setConfirmPin] = useState(PIN_ARRAY);
  const [step, setStep] = useState('create');
  const pinRefs = useRef([]);

  const handleInputChange = useCallback(
    (e, index, currentPin, setCurrentPin) => {
      const value = e.target.value.replace(/\D/g, '');
      if (value.length <= 1) {
        const newPin = [...currentPin];
        newPin[index] = value;
        setCurrentPin(newPin);

        if (value && index < PIN_LENGTH - 1) {
          pinRefs.current[index + 1]?.focus();
        }
      }
    },
    [],
  );

  const createPinMutation = useMutation(createBAASPin, {
    onSuccess: () => {
      queryClient.invalidateQueries('baas-settlement');
      toast.success('Transaction PIN created successfully');
    },
  });

  const isValid = useMemo(
    () =>
      pin.join('').length === PIN_LENGTH &&
      confirmPin.join('').length === PIN_LENGTH &&
      pin.join('').length === confirmPin.join('').length,
    [confirmPin, pin],
  );

  const handleKeyDown = useCallback((e, index, currentPin) => {
    if (e.key === 'Backspace' && !currentPin[index] && index > 0) {
      pinRefs.current[index - 1]?.focus();
    }
  }, []);
  const resetPins = useCallback(error => {
    setStep('create');
    setPin(PIN_ARRAY);
    setConfirmPin(PIN_ARRAY);
    if (error) {
      toast.error(error);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    const newPin = pin.join('');
    const confirmNewPin = confirmPin.join('');
    if (step === 'create') {
      if (newPin.length === PIN_LENGTH) {
        setStep('confirm');
        pinRefs.current[0]?.focus();
        return setConfirmPin(PIN_ARRAY);
      } else {
        return toast.error('Please enter a 4-digit PIN');
      }
    } else if (newPin !== confirmNewPin) {
      return resetPins('PINs do not match. Please try again.');
    }

    try {
      await createPinMutation.mutateAsync({
        pin: newPin,
        confirm_pin: confirmNewPin,
        company_id: company?.id,
      });
    } catch (error) {
      return resetPins(error.response?.data?.error || 'Failed to create PIN');
    }
  }, [pin, confirmPin, step, createPinMutation, company?.id, resetPins]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        {step === 'confirm' && <GoBack goBack={() => setStep('create')} />}
        <div className="mt-6">
          <div className="flex flex-col items-center gap-3">
            <img src={baasLock} alt="lock icon" />
            <p className="font-inter text-base text-[#4F555F] text-center tracking-[-1%] max-w-[385px] m-auto">
              {step === 'create' ? 'Create' : 'Confirm'} your 4-digit
              transaction PIN
            </p>
          </div>

          <CreatePinInput
            pin={step === 'create' ? pin : confirmPin}
            onChange={(e, index) =>
              handleInputChange(
                e,
                index,
                step === 'create' ? pin : confirmPin,
                step === 'create' ? setPin : setConfirmPin,
              )
            }
            onKeyDown={(e, index) =>
              handleKeyDown(e, index, step === 'create' ? pin : confirmPin)
            }
            pinRefs={pinRefs}
          />

          <div className="flex items-center mt-6">
            <PrimaryButton
              buttonText={step === 'create' ? 'Next' : 'Submit'}
              className="w-full rounded-lg"
              onClick={handleSubmit}
              disabled={
                (step === 'confirm' && !isValid) ||
                pin.join('').length !== PIN_LENGTH
              }
              loading={step === 'confirm' && createPinMutation?.isLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
