import {useCallback, useState} from 'react';

export const useSelectedIds = (initialIds = [], initialData = []) => {
  const [selectedIds, setSelectedIds] = useState(initialIds);
  const [selectedData, setSelectedData] = useState(initialData);

  const toggleSelection = useCallback(id => {
    setSelectedIds(prevIds =>
      prevIds.includes(id) ? prevIds.filter(t => t !== id) : [...prevIds, id],
    );
  }, []);
  const toggleSelectData = useCallback(data => {
    setSelectedData(prevDatas =>
      prevDatas.find(t => t?.id === data?.id)
        ? prevDatas.filter(t => t?.id !== data?.id)
        : [...prevDatas, data],
    );
  }, []);

  return [
    selectedIds,
    toggleSelection,
    setSelectedIds,
    selectedData,
    toggleSelectData,
    setSelectedData,
  ];
};
