/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {
  Modal,
  PrimaryButton,
  DropdownIndicator,
  customStyles,
} from '../../../../components';

const STATUSES = [
  {value: 'block', label: 'Block'},
  {value: 'allow', label: 'Allow'},
];

export default function ReviewProcessModal({
  open,
  setOpen,
  setData,
  data,
  onClick,
  loading,
}) {
  const [selectedStatus, setSelectedStatus] = useState(STATUSES[0]);

  useEffect(
    () => setData({...data, action: selectedStatus?.value}),
    [selectedStatus.value],
  );

  return (
    <Modal
      modalBg="bg-white80"
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Review"
    >
      <div className="mt-4 text-start">
        <label htmlFor="status" className="mt-6 mb-2 relative">
          Status
        </label>
        <Select
          defaultValue={selectedStatus}
          onChange={status => {
            setSelectedStatus(status);
            setData({...data, action: status?.value});
          }}
          options={STATUSES}
          className="basic-multi-select"
          classNamePrefix="react-select"
          components={{DropdownIndicator}}
          styles={customStyles}
          menuPlacement="auto"
        />
      </div>

      {selectedStatus.value === 'allow' && (
        <p className="mt-3 text-sm text-body py-5 text-center bg-brandBlue4">
          By clicking allow, you cannot reverse this action
        </p>
      )}

      {selectedStatus?.value === 'block' && (
        <div className="p-4 mt-4 rounded bg-white80 xl:bg-white grow xl:grow-0">
          <div className="flex items-center gap-1 mb-4">
            <p className="text-xs font-medium uppercase text-grey">Reason</p>
          </div>
          <textarea
            name="reason"
            id=""
            placeholder="Specify reasons for blocking this case..."
            data-smallertextarea=""
            value={data?.reason}
            onChange={e => setData({...data, reason: e.target.value})}
          />
        </div>
      )}

      <PrimaryButton
        onClick={onClick}
        buttonText={selectedStatus?.label}
        className="w-full mt-4"
        disabled={
          selectedStatus?.value === 'block' && data?.reason?.length === 0
        }
        loading={loading}
      />
    </Modal>
  );
}
