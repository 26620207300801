import {useEffect, useState} from 'react';
import {Constants} from '../helpers';
export const useDefaultCountry = () => {
  const [country, setCountry] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(payload => {
        setIsLoading(false);
        setCountry(payload);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const supportedCountries = Constants.lookups.countries?.map(
    country => country.label,
  );

  const userDefaultCountry =
    !isLoading && supportedCountries.includes(country?.country_name)
      ? Constants.lookups.countries.find(
          sCountry => sCountry.label === country?.country_name,
        )
      : null;

  return {userDefaultCountry, isLoading};
};
