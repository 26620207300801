import {
  GET_WEBHOOK_SUBSCRIPTIONS,
  UPDATED,
  WEBHOOK_LOADING,
  WEBHOOK_PORTAL_URL,
} from '../types';
import reduxApi from '../../helpers/reduxApi';
import {toast} from 'react-toastify';

export const getWebhookSubscriptions = ({
  page,
  start,
  end,
  app_id,
  environment,
  service,
  subscriptionIds,
}) =>
  reduxApi(
    `/users/webhook-subscriptions?page=${page}${
      app_id ? `&app_id=${app_id}` : ''
    }${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}${
      environment ? `&environment=${environment}` : ''
    }${subscriptionIds ? `&subscriptionIds=${subscriptionIds}` : ''}${
      service ? `&service=${service}` : ''
    }`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_WEBHOOK_SUBSCRIPTIONS});
      },
    },
    {
      error: 'ERROR',
      loading: WEBHOOK_LOADING,
      responder: GET_WEBHOOK_SUBSCRIPTIONS,
    },
  );

export const getPortalUrl = (convoyAppId, convoySubId) =>
  reduxApi(
    `/users/webhook-subscriptions/generate-portal-url?convoy_app_id=${convoyAppId}&convoy_subscription_id=${convoySubId}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: WEBHOOK_PORTAL_URL});
      },
    },
    {
      error: 'ERROR',
      loading: WEBHOOK_LOADING,
      responder: WEBHOOK_PORTAL_URL,
    },
  );

export const deleteWebhookSubscription = ({appId, service}, callback) =>
  reduxApi(
    `/users/webhook-subscriptions?app_id=${appId}&service=${service}`,
    'DELETE',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        toast.success(data.entity);
        callback(false);
        dispatch({type: UPDATED});
      },
      error: data => {
        callback(false);
        toast.error(data.message);
      },
    },
    {
      error: 'ERROR',
      loading: 'WEBHOOK_LOADINGFF',
      responder: 'UPDATEDDDD',
    },
  );
export const webhookSubscription = ({appId, ...rest}, callback) =>
  reduxApi(
    `/users/webhook-subscriptions?app_id=${appId}`,
    'POST',
    {app_id: appId, ...rest},
    {
      report: true,
      success: (data, dispatch) => {
        toast.success(data.entity);
        callback(false);
        dispatch({type: UPDATED});
      },
      error: data => {
        callback(false);
        toast.error(data.message);
      },
    },
    {
      error: 'ERROR',
      loading: 'WEBHOOK_LOADINGHH',
      responder: 'UPDATEDDD',
    },
  );
