import {api} from '../../helpers';
import {toast} from 'react-toastify';

function fetchCreditChecks(params) {
  return api
    .get('/users/widget/lookup/individual/credit', {params})
    .then(res => res.data);
}
function performAssessment(params) {
  return api.get(`/users/widget/lookup/credit`, {params}).then(res => res.data);
}

function performAffordability(params) {
  return api
    .get(`/users/widget/lookup/credit/affordability`, {params})
    .then(res => res.data);
}
function fetchKYCInformation(params) {
  return api
    .get(`/users/kyc-dashboard/customer`, {params})
    .then(res => res.data);
}

function fetchChecksByEntity(params) {
  return api
    .get(`/users/kyc-dashboard/customer_entity`, {params})
    .then(res => res.data);
}
function fetchAccountAnalysis(params) {
  return api
    .get(`/users/widget/lookup/account-analysis`, {params})
    .then(res => res.data);
}
function performStatement(data) {
  return api
    .post(`/users/widget/lookup/statement`, {...data})
    .then(res => res.data)
    .catch(e => toast.error(e.response.data.error));
}

export {
  fetchCreditChecks,
  performAssessment,
  performStatement,
  fetchKYCInformation,
  fetchAccountAnalysis,
  fetchChecksByEntity,
  performAffordability,
};
