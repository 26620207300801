import React, {useCallback, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import moment from 'moment/moment';
import ReconciliationLayout from '../ReconciliationLayout';
import {
  customStyles,
  DropdownIndicator,
  EmptyState,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  SkeletonLoader,
  TableLayout,
} from '../../../../components';
import {
  corruptedFile,
  overviewInflow,
  projectsMobileNav,
  reconTransact,
} from '../../../../assets/images/images';
import Select from 'react-select';
import ReconciliationExpectedPayments from '../ReconciliationExpectedPayments';
import {useAccounts} from '../hooks/useAccounts';
import {useTransactions} from '../hooks/useReconQueries';
import {getStatusColor} from '../../../../helpers';
import {useSelectedIds} from '../hooks/useSelectedIds';
import {useMutation, useQueryClient} from 'react-query';
import {reconcileTransaction} from '../../../../requests/queries/reconciliations';
import {toast} from 'react-toastify';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';
import {
  initialFilters,
  sumTransactionsAmount,
  transactionType,
} from '../constants';
import {ReconFilters} from '../components/ReconFilters';

const statusFilters = [{title: 'Type', name: 'type', values: transactionType}];

function ProjectReconciliation() {
  const [filters, setFilters] = useState(initialFilters);
  const [openFilter, setOpenFilter] = useState(false);
  const {slug} = useParams();
  const {accountSelectionData, isLoading} = useAccounts({slug});
  const [selectedAccount, setSelectedAccount] = useState({
    label: 'Please select an account',
    value: '',
  });
  const [
    transactionIds,
    toggleTransaction,
    resetTransaction,
    selectedData,
    toggleSelectData,
    resetSelectedData,
  ] = useSelectedIds();
  const [
    externalTransactionIds,
    toggleExternalTransaction,
    resetExternalTransaction,
    externalSelectedData,
    toggleExternalSelectData,
    resetExternalSelectedData,
  ] = useSelectedIds();

  const queryClient = useQueryClient();
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });
  const totalTransactions = sumTransactionsAmount(selectedData);
  const totalExternalTransactions = sumTransactionsAmount(externalSelectedData);
  const difference = useMemo(
    () => Math.abs(totalTransactions - totalExternalTransactions),
    [totalTransactions, totalExternalTransactions],
  );

  const filterParams = useMemo(
    () => ({
      ...(filters.date_from?.length > 0 && {date_from: filters?.date_from}),
      ...(filters?.date_to?.length > 0 && {date_to: filters?.date_to}),
      ...(filters?.type?.length > 0 && {
        transaction_type: filters?.type?.toString(),
      }),
    }),
    [filters.date_from, filters?.date_to, filters?.type],
  );

  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };
  const {
    tableData,
    isLoading: transactionLoading,
    isFetching,
  } = useTransactions({
    entity_id: 'account_id',
    id: selectedAccount?.value || '',
    tabType: 'external',
    transaction_recon: 'Unreconciled',
    ...query,
    ...filterParams,
  });

  const {mutate, isLoading: reconcileLoading} = useMutation(
    ['reconcile'],
    reconcileTransaction,
  );

  const handleReconcile = useCallback(async () => {
    if (!transactionIds.length || !externalTransactionIds?.length) return;

    try {
      const data = {
        transactions: transactionIds,
        account_id: selectedAccount?.value,
        expected_payments: externalTransactionIds,
      };

      await mutate(data, {
        onSuccess: () => {
          resetTransaction([]);
          resetSelectedData([]);
          resetExternalTransaction([]);
          resetExternalSelectedData([]);
          queryClient.invalidateQueries();
          toast.success('Account reconciliation was successful');
        },
        onError: error => {
          console.error('Reconciliation failed:', error);
          toast.error('Account reconciliation failed. Please try again.');
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [
    transactionIds,
    externalTransactionIds,
    selectedAccount?.value,
    mutate,
    resetTransaction,
    resetSelectedData,
    resetExternalTransaction,
    resetExternalSelectedData,
    queryClient,
  ]);

  const isEmpty = useMemo(
    () =>
      !transactionLoading &&
      (tableData?.rows?.length === 0 || accountSelectionData.length === 0),
    [accountSelectionData.length, tableData?.rows?.length, transactionLoading],
  );

  const flows = [
    {
      icon: reconTransact,
      alt: 'transactions',
      title: 'Transactions',
      count: shortenLargeNumbers(transactionIds?.length || 0, null),
      value: shortenLargeNumbers(totalTransactions, true, {
        startAt: 'B',
      }),
    },
    {
      icon: overviewInflow,
      alt: 'Expected-Payments',
      title: 'Expected Payments',
      count: shortenLargeNumbers(externalTransactionIds?.length || 0, null),
      value: shortenLargeNumbers(totalExternalTransactions, true, {
        startAt: 'B',
      }),
    },
    {
      alt: 'Difference',
      title: 'Difference',
      value: shortenLargeNumbers(difference, true, {startAt: 'B'}),
    },
  ];

  return (
    <ReconciliationLayout pageTitle="Project A" pageIcon={projectsMobileNav}>
      <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center bg-sidebarBlue  gap-4 mb-6  pl-11 py-4 lg:py-[4px] pr-8 mt-10">
        <div>
          <Select
            defaultValue={selectedAccount}
            value={selectedAccount}
            placeholder="Select an account"
            classNamePrefix="react-select"
            components={{DropdownIndicator}}
            styles={customStyles}
            className="text-start rounded-lg my-2 add_project_select md:w-[250px]"
            menuPlacement="auto"
            options={accountSelectionData || []}
            onChange={data => {
              setSelectedAccount(data);
              setQuery({limit: 10, page: 1});
              resetTransaction([]);
              resetExternalTransaction([]);
              resetSelectedData([]);
              resetExternalSelectedData([]);
            }}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-end lg:ml-auto xl:pr-16">
          {flows.map(({icon, alt, title, value, count}) => (
            <div
              key={title}
              className={
                'relative rounded-lg flex lg:flex-col xl:flex-row items-center xl:items-center lg:items-start  gap-2 w-auto'
              }
            >
              {icon && <img src={icon} alt={alt} width={32} />}
              <div className="flex flex-col ">
                <p className="text-[11px] text-grey5">
                  {title}{' '}
                  {count &&
                    `(${shortenLargeNumbers(count, null, {decimals: 0})})`}
                </p>

                {transactionLoading ? (
                  <SkeletonLoader
                    style={{
                      width: 100,
                      height: 20,
                      marginBottom: 4,
                    }}
                  />
                ) : (
                  <h1 className={`text-[16px]  font-semibold text-white`}>
                    {value}
                  </h1>
                )}
              </div>
            </div>
          ))}
        </div>
        {/*<h1 className="text-[#080917] font-medium font-inter ml-auto">*/}
        {/*  Reconcile transaction*/}
        {/*</h1>*/}
        <PrimaryButton
          buttonText="Reconcile"
          className="rounded-lg h-[30px] w-[120px] flex items-center justify-center"
          disabled={
            transactionIds.length === 0 || externalTransactionIds.length === 0
          }
          onClick={handleReconcile}
          loading={reconcileLoading}
        />
      </div>
      <div className="pl-11 pr-8 mt-10">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="bg-white relative border  border-[#ececec] rounded-xl md:h-[750px] flex flex-col ">
            <div className="flex relative items-center gap-4 py-5 px-4 ">
              <p className="font-inter text-[#080917] font-medium text-tiny">
                Transactions
              </p>
              {selectedAccount?.value && (
                <FilterButton
                  openFilter={openFilter}
                  setOpenFilter={setOpenFilter}
                  className="h-[30px]"
                />
              )}
            </div>
            <ReconFilters
              filters={filters}
              setFilters={setFilters}
              limit={query?.limit}
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              setQuery={setQuery}
              query={query}
              initialFilters={initialFilters}
              slug={slug}
              noAccount
              otherFilterOptions={statusFilters}
              customClasses={{
                modalPosition: 'items-center justify-center ',
                extraModalClasses:
                  'overflow-y-auto h-fit max-h-[95%] sm:max-h-full max-w-full sm:max-w-[352px] rounded-b-none sm:rounded-b-lg absolute -top-[90px]  right-[240px] sm:relative lookup-modal',
              }}
            />

            {transactionLoading ? (
              <Loader height={35} />
            ) : isEmpty ? (
              <EmptyState
                body="Ops! sorry, No Transaction found"
                src={corruptedFile}
                imageHeight={48}
                imageWidth={48}
                noBtn
                height={34}
              />
            ) : (
              <div
                style={{opacity: isFetching ? 0.3 : 1}}
                className="md:h-[660px] overflow-auto"
              >
                <TableLayout className="mb-20 w-full font-inter h-full overflow-auto">
                  <thead className="text-small font-medium uppercase">
                    <tr className="bg-white80 border border-transparent border-y-[#ececec]">
                      <th></th>
                      <th className="p-3 pl-4 font-medium text-left text-xs text-grey whitespace-nowrap w-[27%]">
                        transaction date
                      </th>
                      <th className="p-3 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap w-[19%]">
                        ref id
                      </th>
                      <th className="p-3 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap w-[22%]">
                        amount
                      </th>
                      <th className="p-3 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap w-[14%]">
                        type
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm text-body bg-white">
                    {!transactionLoading &&
                      tableData &&
                      tableData?.rows?.map(transaction => (
                        <tr
                          key={transaction?.id}
                          className={`text-left border-b-[0.5px] cursor-pointer border-[#ECECEC] ${
                            transactionIds.includes(transaction?.id)
                              ? 'bg-white80 font-bold'
                              : ''
                          }`}
                          onClick={() => {
                            if (
                              externalTransactionIds.length > 1 &&
                              transactionIds.length === 1
                            ) {
                              resetTransaction([]);
                              resetSelectedData([]);
                              return;
                            }
                            toggleTransaction(transaction?.id);
                            toggleSelectData(transaction);
                          }}
                        >
                          <td className="p-4 pl-0 truncate max-w-[30px] w-[2%]">
                            <input
                              checked={transactionIds.includes(transaction?.id)}
                              type="checkbox"
                              className="w-[15px] h-[15px] rounded-sm ml-3 focus-visible:outline-1 focus-visible:outline-brandBlue"
                            />
                          </td>
                          <td className="p-4 pl-4 w-[27%] truncate max-w-[130px]">
                            {moment(transaction?.transaction_time).format(
                              'Do MMM YYYY h:m a',
                            ) || '-'}
                          </td>
                          <td className="p-4 pl-0 truncate max-w-[90px] w-[19%]">
                            {transaction?.transaction_ref || '-'}
                          </td>
                          <td className="p-4 pl-0 truncate max-w-[100px] w-[22%]">
                            {shortenLargeNumbers(
                              transaction?.transaction_amount,
                              transaction?.account?.currency,
                              {startAt: 'B'},
                            )}
                          </td>
                          <td className="p-4 pl-0 whitespace-nowrap w-[14%]">
                            <span
                              className={`uppercase w-fit ${getStatusColor(
                                transaction?.transaction_type,
                              )} h-5 flex items-center justify-center  font-medium text-xs rounded-full px-2 py-1`}
                            >
                              {transaction?.transaction_type || '-'}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </TableLayout>
              </div>
            )}

            {!isLoading && tableData?.rows?.length > 0 && (
              <div className="flex mt-auto bg-white80 md:h-[60px]  rounded-b-[10px] items-center justify-between absolute bottom-0 w-full flex-wrap gap-4 px-3 ">
                <PageLimit
                  onLimitChange={limit => {
                    setQuery(prevState => ({
                      ...prevState,
                      limit,
                      page: 1,
                    }));
                  }}
                  limit={query?.limit}
                  total={tableData?.page}
                  length={tableData?.rows?.length}
                />

                <Pagination
                  total={tableData?.pages}
                  current={Number(tableData?.page)}
                  onPageChange={activePage => {
                    pageClick(activePage);
                    const searchParams = new URLSearchParams(
                      document.location.search,
                    );
                    searchParams.set('page', activePage);
                    // const newSearchParams = searchParams.toString();
                    // navigate(`?${newSearchParams}`);
                  }}
                />
              </div>
            )}
          </div>

          <ReconciliationExpectedPayments
            selectedAccount={selectedAccount}
            handleSelectTransactions={toggleExternalTransaction}
            toggleExternalSelectData={toggleExternalSelectData}
            resetExternalSelectedData={resetExternalSelectedData}
            transactionIds={externalTransactionIds}
            setOpenFilter={setOpenFilter}
            statusFilters={statusFilters}
            internalTransactionIds={transactionIds}
            accountSelectionData={accountSelectionData}
            resetExternalTransaction={resetExternalTransaction}
          />
        </div>
      </div>
    </ReconciliationLayout>
  );
}

export default ProjectReconciliation;
