/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useMemo, useEffect} from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import {
  DropdownIndicator,
  customStyles,
  PrimaryButton,
  Modal,
} from '../../../components';
import {closeModal} from '../../../assets/images/images';
import * as Actions from '../../../store/actions';
import AssignApps from './AssignApps';

function InviteMemberModal({
  open,
  setOpen,
  roles,
  inviteTeamMember,
  loading,
  getAllApps,
  apps: {allApps},
}) {
  const roleOptions = useMemo(
    () =>
      roles
        ? roles
            .filter(r => r.role !== 'Owner')
            .map(r => ({value: r.role, label: r.role}))
        : null,
    [roles],
  );

  const userApps = useMemo(
    () =>
      allApps
        ? allApps?.apps?.map(app => ({value: app._id, label: app.name}))
        : [],
    [roles],
  );

  const DATA = [{label: 'Select All', value: 'all'}, ...(userApps || [])];
  const [selectedRole, setSelectedRole] = useState();
  const [selectedApps, setSelectedApps] = useState({});
  const [email, setEmail] = useState('');

  useEffect(() => {
    getAllApps({limit: '500'});
  }, [getAllApps, DATA.length]);

  const handleSubmit = async e => {
    e.preventDefault();
    await inviteTeamMember(
      {
        email,
        role: selectedRole.value,
        ...(selectedApps?.pullApps?.length !== 0 && {
          apps: selectedApps?.pushApps,
        }),
      },
      () => setOpen(false),
    );
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-grey text-base font-medium">Invite a member</h3>
          <button onClick={() => setOpen(false)}>
            <img src={closeModal} alt="" />
          </button>
        </header>

        <form className="mt-6 text-left">
          <label htmlFor="dui" className="mt-6">
            <span className="text-sm ">Email address</span>
            <input
              type="email"
              id="dui"
              className="mt-2"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="example@gmail.com"
              required
            />
          </label>

          <label htmlFor="role" className="mt-6 mb-2">
            Role
          </label>
          <Select
            defaultValue={selectedRole}
            onChange={setSelectedRole}
            options={roleOptions}
            components={{DropdownIndicator}}
            classNamePrefix="react-select"
            styles={customStyles}
            menuPlacement="auto"
          />
          <AssignApps
            setAppsToAssign={data => {
              setSelectedApps(data);
            }}
          />

          <PrimaryButton
            buttonText="Send invite"
            className="w-full mt-6"
            loading={loading}
            onClick={handleSubmit}
          />
        </form>
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(InviteMemberModal);
