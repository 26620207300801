import React from 'react';
import {Modal, PrimaryButton} from '../../../../components';
import {
  autoLink,
  closeModal,
  manualLink,
} from '../../../../assets/images/images';

function AddAccountModal({
  show,
  onClose,
  openManuallyAddAccountModal,
  openAutomaticallyAddBankModal,
}) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <h3 className="font-medium text-base text-grey">Add account</h3>

          <button
            onClick={() => {
              onClose(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>{' '}
        <div className="flex flex-col mt-9">
          <div className="flex items-center justify-center px-12 py-8 rounded-2xl">
            <div className="flex flex-col items-center gap-2">
              <img src={manualLink} alt="manual" className="w-14 h-14" />
              <h1 className="text-secondaryBlue font-medium text-[18px]">
                Manually add account
              </h1>
              <p className="text-grey40 text-sm">
                Add an account by manually entering information about your
                financial accounts here.
              </p>
              <PrimaryButton
                buttonText="Add Manually"
                className="px-[40px] rounded-lg"
                onClick={openManuallyAddAccountModal}
              />
            </div>
          </div>
          <div className="flex items-center justify-center px-12 py-8 bg-batchBlue20 rounded-2xl">
            <div className="flex flex-col items-center gap-2">
              <img src={autoLink} alt="auto" className="w-14 h-14" />
              <h1 className="text-secondaryBlue font-medium text-[18px]">
                Automatically add account{' '}
              </h1>
              <p className="text-grey40 text-sm">
                Automate the process of adding your financial accounts by
                linking your bank account here
              </p>
              <PrimaryButton
                buttonText="Link Account"
                className="px-[40px] rounded-lg"
                onClick={openAutomaticallyAddBankModal}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddAccountModal;
