import React from 'react';
import PrimaryButton from './PrimaryButton';
import {emptyState} from '../assets/images/images';

function EmptyState({
  noBtn,
  src,
  body,
  heading,
  buttonText,
  height,
  lottie,
  onClick,
  bodyMaxWidth = '',
  className = '',
  customBtn,
  maxWidth = 'max-w-sm',
  imageHeight,
  imageWidth,
  ...otherProps
}) {
  return (
    <div
      className={`${maxWidth} flex flex-col items-center justify-center m-auto text-center ${className}`}
      style={{
        height: height ? `${height}vh` : 'calc(100vh - 64px)',
      }}
    >
      {lottie ? (
        lottie
      ) : (
        <img
          src={src ?? emptyState}
          alt=""
          width={imageWidth ?? 94}
          height={imageHeight ?? 94}
        />
      )}
      {heading ? (
        <h4 className="mt-2 font-medium text-grey">{heading}</h4>
      ) : null}
      {body ? (
        <p
          className={`mt-2 ${
            customBtn ? 'mb-2' : 'mb-6'
          } text-sm text-body ${bodyMaxWidth}`}
        >
          {body}
        </p>
      ) : null}

      {!noBtn ? (
        <PrimaryButton
          buttonText={buttonText ?? 'Perform lookup'}
          xPadding="px-[35px]"
          onClick={onClick}
          {...otherProps}
        />
      ) : customBtn ? (
        customBtn
      ) : null}
    </div>
  );
}

export default EmptyState;
