import React, {useState} from 'react';
import Layout from '../Layout';
import {Accordion, EmptyState, Loader} from '../../../components';
import {corruptedFile} from '../../../assets/images/images';
import {useQuery} from 'react-query';
import {fetchIp} from '../../../requests/queries/customer360';
import {useParams} from 'react-router-dom';
import SingleAnalysisModal from '../../DocumentAnalysis/SingleAnalysisModal';
import {fullDetails, getIPDeviceData} from '../LayoutConstants';

export default function IPDeviceInfo() {
  const {slug} = useParams();

  const {data: ipData, isLoading: ipDataLoading} = useQuery(
    ['ip-data', slug],
    () => fetchIp(slug),
    {
      enabled: !!slug,
    },
  );
  const IP_DATA = !ipDataLoading && ipData && ipData?.data;

  const [openDocumentAnalysisModal, setOpenDAM] = useState(false);

  return (
    <Layout pageTitle="IP & Device Info">
      <SingleAnalysisModal
        open={openDocumentAnalysisModal}
        setOpen={setOpenDAM}
        customer_id={slug}
      />
      {ipDataLoading ? (
        <Loader />
      ) : !ipDataLoading && IP_DATA?.length === 0 ? (
        <div className="bg-white80 w-full">
          <EmptyState
            body="There is no information to display here at the moment"
            src={corruptedFile}
            height={45}
            imageHeight={48}
            imageWidth={48}
            bodyMaxWidth="max-w-[179px]"
            buttonText="Validate document"
            onClick={() => {
              setOpenDAM(true);
            }}
            noBtn
          />
        </div>
      ) : (
        <div className="space-y-4">
          {!ipDataLoading &&
            IP_DATA?.map((ip, index) => {
              const {ipData, deviceData, ipDate} = getIPDeviceData(ip);

              return (
                <Accordion
                  key={index}
                  title="IP & Device Info"
                  border
                  customClasses="rounded-xl"
                  customComponent={
                    <div className="flex items-center gap-2 rounded-full px-2 py-1 bg-grey60 text-grey text-xs font-medium uppercase">
                      <span className="-mb-[2px]">{ipDate}</span>
                    </div>
                  }
                  customChildrenClasses="mt-2 border-t border-grey-50"
                  customPadding="px-6 py-5"
                  noChildPadding
                  customTitleSize="text-lg"
                  customTitleColor="text-grey"
                  isOpen={index >= 1}
                >
                  <div className="w-full inline-grid grid-cols-[auto_auto_auto_auto] items-center gap-10 justify-between pl-8 pr-10 pt-6 pb-6">
                    {fullDetails(ipData)?.map(({title, value}, index) => (
                      <div key={index} className="flex flex-col gap-2">
                        <p className="text-grey-700 text-xs font-medium uppercase">
                          {title}
                        </p>
                        <p className="text-grey text-sm font-medium capitalize">
                          {value}
                        </p>
                      </div>
                    ))}
                  </div>

                  <div className="w-full inline-grid grid-cols-[auto_auto_auto_auto] items-center gap-10 justify-between pl-8 pr-10 pt-6 pb-14 border-t border-grey-50">
                    {fullDetails(deviceData)?.map(({title, value}, index) => (
                      <div key={index} className="flex flex-col gap-2">
                        <p className="text-grey-700 text-xs font-medium uppercase">
                          {title}
                        </p>
                        <p className="text-grey text-sm font-medium">{value}</p>
                      </div>
                    ))}
                  </div>
                </Accordion>
              );
            })}
        </div>
      )}
    </Layout>
  );
}
