import React, {useContext, useState} from 'react';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import {
  amlScreeningIc,
  compass,
  developersIc,
  easyLookupIc,
  easyOnboardIc,
  teamIc,
  verificationsIc,
} from '../../assets/images/images';
import {Modal} from '../../components';
import {TourContext} from '../../helpers/context';

const options = [
  {
    id: 'aml_screening_tour',
    img: amlScreeningIc,
    title: 'AML Screening',
  },
  {
    id: 'developers_tour',
    img: developersIc,
    title: 'Developers',
  },
  {
    id: 'easy_lookup_tour',
    img: easyLookupIc,
    title: 'EasyLookup',
  },
  {
    id: 'easy_onboard_tour',
    img: easyOnboardIc,
    title: 'EasyOnboard',
  },
  {
    id: 'verifications_tour',
    img: verificationsIc,
    title: 'Verifications',
  },
  {
    id: 'team_tour',
    img: teamIc,
    title: 'Team',
  },
];

function TourMenu({auth: {userDetails}, open, setOpen}) {
  const [selectedOptions, setSelectedOptions] = useState({});

  const {updateTourState} = useContext(TourContext);

  const handleOptionClick = option => {
    const isEasyOnboardTour = option.id === 'easy_onboard_tour';
    const updatedState = {
      ...selectedOptions,
      [option.id]: !selectedOptions[option.id],
      ...(isEasyOnboardTour && {
        easy_onboard_tour: !selectedOptions.easy_onboard_tour,
        easy_onboard_tour_2: !selectedOptions.easy_onboard_tour_2,
      }),
    };
    setSelectedOptions(updatedState);
    updateTourState(updatedState);
  };

  const handleAllTourClick = () => {
    const updatedState = options.reduce((acc, option) => {
      if (option.id === 'easy_onboard_tour') {
        return {
          ...acc,
          easy_onboard_tour: true,
          easy_onboard_tour_2: true,
        };
      } else {
        return {
          ...acc,
          [option.id]: true,
        };
      }
    }, {});
    setSelectedOptions(updatedState);
    updateTourState(updatedState);
    setOpen(false);
  };

  const tourText =
    userDetails?.company?.logo && userDetails?.company?.color_code
      ? 'Tour'
      : 'Dojah Tour';

  return (
    <Modal
      modalBg="bg-white80"
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[395px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center items-center"
    >
      <div className="p-6 flex flex-col gap-6 items-center">
        <img src={compass} alt="" width={32} height={32} />
        <div className="flex flex-col gap-4">
          <p className="text-grey font-medium">{tourText}</p>
          <p className="text-sm text-body">
            You can select a specific tour from the list below or click “Begin
            all tour” to launch all tour.
          </p>
        </div>
        <div className="flex flex-col gap-4 w-full overflow-y-auto">
          {options.map((option, index) => (
            <button
              key={index}
              className={`p-4 flex items-center gap-2 rounded bg-white80 transition-all duration-300 border ${
                selectedOptions[option.id]
                  ? 'border-brandBlue'
                  : 'border-white80'
              }`}
              onClick={() => handleOptionClick(option)}
            >
              <img src={option.img} alt="" width={18} height={18} />
              <p className="text-body text-sm">{option.title}</p>
            </button>
          ))}
        </div>
        <button
          className="text-sm text-brandBlue font-medium p-4 w-full"
          onClick={handleAllTourClick}
        >
          Begin all tour instead
        </button>
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(TourMenu);
