import {MODULE} from '../types';

const initialState = {
  modularization: null,
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case MODULE:
      return {...state, modularization: action.payload};
    default:
      return state;
  }
}
