/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from 'react';
import {brightness, glassOn} from '../../../assets/images/images';
import {VerificationToggle} from '../../../components';
import {Card, Wrapper} from '../FraudCheckScreens';
import {EasyOnboardContext} from '../../../helpers/context/EasyOnboardContext';
import {useDispatch, useSelector} from 'react-redux';
import {setWidgetPages} from '../../../store/actions';
import {useFraudCheck} from '../../../hooks/useFraudCheck';

export const DetectWithLivenessMatch = () => {
  const {widgetPages} = useSelector(state => state?.widgets);
  const dispatch = useDispatch();
  const {detectWithLiveness, setDetectWithLiveness} =
    useContext(EasyOnboardContext);
  const rangeTextPosition =
    (detectWithLiveness.brightnessThreshold / 100) * 100;

  const foundLivenessOrSelfie = widgetPages?.filter(
    page =>
      page?.page === 'selfie' ||
      (page?.page === 'government-data' && page?.config?.selfie === true),
  );

  const {setConfig, config} = useFraudCheck({
    brightnessThreshold:
      foundLivenessOrSelfie[0]?.config?.brightnessThreshold ||
      detectWithLiveness?.brightnessThreshold,
    glassesCheck:
      foundLivenessOrSelfie[0]?.config?.glassesCheck ||
      detectWithLiveness?.glassesCheck,
  });

  useEffect(() => {
    setDetectWithLiveness({...config});
  }, [config]);

  useEffect(() => {
    if (foundLivenessOrSelfie.length > 0) {
      foundLivenessOrSelfie.map(page => {
        if (page?.page === 'selfie') {
          dispatch(
            setWidgetPages({
              ...page,
              config: {
                ...page?.config,
                brightnessThreshold:
                  detectWithLiveness?.brightnessThreshold ||
                  config.brightnessThreshold,
                glassesCheck:
                  detectWithLiveness?.glassesCheck || config?.glassesCheck,
              },
            }),
          );
        }
        if (page?.page === 'government-data') {
          dispatch(
            setWidgetPages({
              ...page,
              config: {
                ...page?.config,
                brightnessThreshold:
                  detectWithLiveness?.brightnessThreshold ||
                  config.brightnessThreshold,
                glassesCheck:
                  detectWithLiveness?.glassesCheck || config?.glassesCheck,
              },
            }),
          );
        }
      });
    }
  }, [config]);

  return (
    <Wrapper>
      <Card
        extension={
          <div className="flex flex-col gap-1">
            <p className="text-grey font-medium">
              Detect with brightness level
            </p>
            <p className="text-body text-sm gap-1">
              Adjust the camera brightness level
            </p>
          </div>
        }
        src={brightness}
      >
        <div className="liveness_slider w-full">
          <input
            type="range"
            min="0"
            max="100"
            value={config?.brightnessThreshold?.toString() || '40'}
            onChange={e => {
              setDetectWithLiveness({
                ...detectWithLiveness,
                brightnessThreshold: +e.target.value,
              });
              setConfig({...config, brightnessThreshold: +e.target.value});
            }}
            className="accent-[#3F7CDB]"
          />
        </div>
        <div className="relative flex justify-between items-center w-full">
          <p
            className={`text-body font-medium text-xs
            ${config.brightnessThreshold < 1 ? 'invisible' : ''}`}
          >
            0
          </p>
          <p
            className="text-body font-medium text-xs absolute"
            style={{
              left: `${rangeTextPosition}%`,
              transform: 'translateX(-50%)',
            }}
          >
            {config.brightnessThreshold}
          </p>
          <p
            className={`text-body font-medium text-xs ml-auto
            ${config.brightnessThreshold > 96 ? 'invisible' : ''}
          `}
          >
            100
          </p>
        </div>
      </Card>
      <Card
        extension={
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col">
              <p className="text-grey font-medium">
                Detect liveness with glasses on
              </p>
              <p className="text-body text-sm gap-1">
                Allow images without glasses on
              </p>
            </div>
            <div className="flex items-center gap-2">
              <VerificationToggle
                isChecked={config?.glassesCheck}
                onCheck={value => {
                  setDetectWithLiveness({
                    ...detectWithLiveness,
                    glassesCheck: value,
                  });
                  setConfig({...config, glassesCheck: value});
                }}
              />
              <span
                className={`-mb-1 text-sm font-medium
                 ${config.glassesCheck ? 'text-brandBlue50' : 'text-grey40'}
              `}
              >
                {config.glassesCheck ? 'Yes' : 'No'}
              </span>
            </div>
          </div>
        }
        src={glassOn}
      />
    </Wrapper>
  );
};
