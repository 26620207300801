import React from 'react';
import {connect} from 'react-redux';
import {Modal} from '../../components';
import * as Actions from '../../store/actions';

function CreditHistoryModal({open, setOpen, details, title}) {
  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle={`SUMMARY - ${title?.toUpperCase()}`}
    >
      <div className="mt-8 px-4 py-4 sm:p-6 bg-white80 -mx-4 sm:mx-0 rounded">
        {details &&
          details.map((item, index) => (
            <React.Fragment key={index}>
              <div className="py-4 px-4 sm:px-6 flex items-center justify-between border-b border-grey60">
                <p className="text-xs text-grey font-medium uppercase text-left">
                  {item.title}
                </p>
                {item.title?.toLowerCase() === 'performance status' ||
                item.title?.toLowerCase() === 'account status' ? (
                  <div
                    className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit ${
                      item.value?.toLowerCase() === 'performing'
                        ? 'text-success bg-success20'
                        : item.value?.toLowerCase() === 'lost'
                        ? 'text-danger bg-statusBg-404'
                        : 'text-grey80 bg-grey60'
                    }`}
                  >
                    {item.value}
                  </div>
                ) : (
                  <p className="text-small text-body w-100 text-right">
                    {item.value}
                  </p>
                )}
              </div>
            </React.Fragment>
          ))}
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(CreditHistoryModal);
