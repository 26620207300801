import React from 'react';

const QuickActionsTopup = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <circle
      cx={16}
      cy={16}
      r={16}
      style={{
        fill: 'var(--color-primary)',
      }}
    />
    <path
      fill="#fff"
      d="M22.667 13.333h-6A.667.667 0 0 0 16 14v4a.667.667 0 0 0 .667.667h6v2.666A.666.666 0 0 1 22 22H10a.666.666 0 0 1-.667-.667V10.667A.667.667 0 0 1 10 10h12a.666.666 0 0 1 .667.667v2.666Zm-4.667 2h2v1.334h-2v-1.334Z"
    />
  </svg>
);

const QuickActionsLookup = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <circle
      cx={16}
      cy={16}
      r={16}
      style={{
        fill: 'var(--color-primary)',
      }}
    />
    <path
      fill="#fff"
      d="m20.02 19.078 2.856 2.855-.943.943-2.855-2.855a6.002 6.002 0 0 1-9.745-4.687c0-3.313 2.688-6 6-6a6.002 6.002 0 0 1 4.688 9.744Zm-1.337-.495a4.665 4.665 0 0 0-3.35-7.917 4.665 4.665 0 0 0-4.666 4.667A4.665 4.665 0 0 0 15.333 20a4.652 4.652 0 0 0 3.25-1.317l.1-.1Z"
    />
  </svg>
);

const QuickActionsOnboard = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <circle
      cx={16}
      cy={16}
      r={16}
      style={{
        fill: 'var(--color-primary)',
      }}
    />
    <path
      fill="#fff"
      d="M17.414 14.987a.667.667 0 0 0-.943 0l-.471.47a1.331 1.331 0 0 1-1.902.017 1.333 1.333 0 0 1 .017-1.902l3.753-3.755a4.333 4.333 0 0 1 4.251 7.046l-1.405 1.423-3.3-3.3Zm-7.307-4.008a4.335 4.335 0 0 1 5.34-.626L13.17 12.63a2.667 2.667 0 0 0 3.676 3.863l.096-.091 2.828 2.828-2.828 2.828a1.333 1.333 0 0 1-1.886 0l-4.95-4.95a4.333 4.333 0 0 1 0-6.128Z"
    />
  </svg>
);

const Unchecked = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      style={{
        fill: 'var(--color-primary)',
      }}
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20Z"
    />
  </svg>
);

const Checked = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      style={{
        fill: 'var(--color-primary)',
      }}
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm-.997-6 7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16Z"
    />
  </svg>
);

export {
  QuickActionsTopup,
  QuickActionsLookup,
  QuickActionsOnboard,
  Unchecked,
  Checked,
};
