import React, {useState} from 'react';
import {Modal, PrimaryButton} from '../../components';
import {closeModal} from '../../assets/images/images';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Constants} from '../../helpers';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import {encryptData} from '../../helpers/encryptData';

function GenerateKeysModal({
  auth: {loading},
  regenerateApiKey,
  open,
  setOpen,
  viewSecret,
}) {
  const [password, setPassword] = useState('');

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    if (!password) return toast.error('Please enter a password');
    setPasswordShown(!passwordShown);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (password) {
      const encryptedLoginData = encryptData(
        JSON.stringify({
          password,
        }),
      );
      await viewSecret({payload: encryptedLoginData}, () => {
        setOpen(false);
        regenerateApiKey();
        toast.success('API Key Regenerated successfully');
      });
    }
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-base font-medium text-grey">Generate New Keys</h3>
          <button onClick={() => setOpen(false)}>
            <img src={closeModal} alt="" />
          </button>
        </header>

        <p className="mt-4 text-sm text-left text-body">
          Please note that by regenerating keys, your previous key will no
          longer be functional.
        </p>

        <form className="mt-6">
          <label htmlFor="aw" className="relative mt-6 mb-2">
            Enter Password
            <input
              type={passwordShown ? 'text' : 'password'}
              id="aw"
              className="mt-2"
              onChange={e => setPassword(e.target.value)}
            />
            <span
              role="button"
              onClick={togglePassword}
              className="absolute text-xs font-medium uppercase cursor-pointer text-grey40 right-3 bottom-4"
            >
              {passwordShown ? 'hide password' : 'show password'}
            </span>
          </label>
          <Link
            to={Constants.PAGES_URL.FORGOT_PASSWORD}
            className="flex justify-end mt-2 mb-6 text-xs font-medium uppercase text-body"
          >
            forgot password?
          </Link>

          <PrimaryButton
            onClick={handleSubmit}
            buttonText="Generate keys"
            className="w-full"
            loading={loading}
          />
        </form>
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(GenerateKeysModal);
