import React, {useContext, useEffect} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Loader, Modal, PrimaryButton, TableLayout} from '../../../components';
import {capitalizeEveryWord} from '../../../helpers';
import {closeModal} from '../../../assets/images/images';
import * as Actions from '../../../store/actions';
import {PageContext} from '../../../helpers/context';

function BatchAmlResultModal({
  aml: {lookupLoading, loading},
  open,
  setOpen,
  selectedHistory,
  downloadAmlBatchLookup,
}) {
  const totalRecords = selectedHistory?.total_records || 0;
  const processedRecords = selectedHistory?.processed_records || 0;
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('amllookup');
  }, [setPageName]);

  const percentage =
    totalRecords !== 0 ? (processedRecords / totalRecords) * 100 : 0;

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      {loading ? (
        <Loader height={30} />
      ) : (
        <div className="px-4 pt-10 pb-4 sm:p-10">
          <header className="flex items-center justify-between">
            <div className="flex flex-wrap items-center gap-2">
              <h3 className="text-base font-medium text-grey">
                {capitalizeEveryWord(
                  selectedHistory?.lookup_type?.replace('_', ' '),
                )}{' '}
                Verification
              </h3>
              <div className="px-2 py-1 text-xs font-medium uppercase rounded-full text-brandBlue50 bg-brandBlue40 w-fit">
                Batch upload
              </div>
              <div
                className={`px-2 py-1 text-xs font-medium uppercase rounded-full  w-fit ${
                  percentage === 100
                    ? 'text-success20 bg-success'
                    : 'text-white bg-status-404'
                }`}
              >
                {percentage === 100 ? 'Successful' : 'Processing'}
              </div>
            </div>
            <button
              onClick={() => {
                setOpen(false);
              }}
              className="hidden sm:block"
            >
              <img src={closeModal} alt="" />
            </button>
          </header>

          <div
            className={`relative h-4 mt-4 mb-8 text-xs font-medium ${
              percentage > 50 && 'text-white'
            }  rounded-full bg-brandBlue40`}
          >
            <span className="absolute left-0 right-0 top-1">
              {(!lookupLoading && percentage) || 0}%
            </span>
            <progress
              id="file"
              value={(!lookupLoading && percentage) || 0}
              max="100"
            ></progress>
          </div>

          {lookupLoading ? (
            <Loader height={30} />
          ) : (
            <TableLayout>
              <thead className="text-xs font-semibold uppercase">
                <tr className="bg-white80">
                  <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                    number of id
                  </th>
                  <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                    ID type
                  </th>
                  <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                    Last checked
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm">
                <tr className="text-left border-b cursor-pointer border-grey60">
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {selectedHistory?.total_records}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {selectedHistory?.lookup_type}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {moment(selectedHistory?.date_updated).format(
                      'DD MMM YYYY HH:mm',
                    )}
                  </td>
                </tr>
              </tbody>
            </TableLayout>
          )}
          {!lookupLoading && (
            <div className=" print:flex print:items-center print:justify-between text-xs text-body uppercase mt-8 mb-3 px-[5.88%]">
              <span>
                Performed by:{' '}
                {selectedHistory?.caller_name || selectedHistory?.caller_email
                  ? selectedHistory?.caller_name ||
                    selectedHistory?.caller_email
                  : '-'}
              </span>
              <span className="ml-8">
                Generated:{' '}
                {selectedHistory?.date_created
                  ? moment(selectedHistory?.date_created).format(
                      'MMM DD, YYYY hh:mmA',
                    )
                  : moment(new Date()).format('MMM DD, YYYY hh:mmA') || '-'}
              </span>
            </div>
          )}
          <div className="flex flex-col items-center gap-4 mt-6 sm:flex-row">
            <button className="flex items-center justify-between sm:justify-start gap-4 py-[14px] px-4 text-tiny text-grey80 bg-white80 outline outline-1 outline-grey60 rounded w-full sm:w-fit focus-visible:outline-brandBlue">
              XLSX
            </button>
            <PrimaryButton
              buttonText="Download"
              className="flex-1 w-full py-4 rounded sm:w-fit"
              onClick={() => {
                downloadAmlBatchLookup(selectedHistory?.id, () => {
                  setOpen(false);
                });
              }}
              loading={lookupLoading}
              disabled={lookupLoading || percentage !== 100}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}

export default connect(state => state, Actions)(BatchAmlResultModal);
