import React, {useContext, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {
  batchLookupImg,
  individualLookupImg,
} from '../../../assets/images/images';
import * as Actions from '../../../store/actions';
import {PAGES_URL} from '../../../helpers/constants';
import EasyLookupModals from '../EasyLookupModals';
import {PageContext} from '../../../helpers/context';
import Layout from '../HomeLayout';
import {PerformBtn, ViewLink} from '../../../components/lookupComponents';

function IndividualLookup({auth: {userDetails}}) {
  const [openIndividualLookup, setOpenIL] = useState(false);
  const [openBatchLookup, setOpenBL] = useState(false);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('lookup');
  }, [setPageName]);

  const {
    performSingleIndividualLookup,
    viewSingleIndividualLookup,
    performBatchIndividualLookup,
    viewBatchIndividualLookup,
  } =
    useMemo(() => userDetails?.permissions?.lookupPermissions, [userDetails]) ||
    {};

  const isCompanyVerified = userDetails?.company?.verified;

  return (
    <Layout>
      <EasyLookupModals
        open={openIndividualLookup}
        setOpen={setOpenIL}
        lookupType="Single"
        batch={false}
      />

      <EasyLookupModals
        open={openBatchLookup}
        setOpen={setOpenBL}
        lookupType="Batch"
        batch={true}
      />

      <div className="mt-4">
        <h1 className="text-grey text-xl font-medium">Individual Lookup</h1>
        <p className="mt-2 text-body text-sm">
          Lookup information about your users or businesses
        </p>
      </div>

      <div className="mt-12 flex flex-col sm:flex-row items-stretch gap-10 sm:gap-4 lg:gap-10">
        <div className="bg-white rounded-3xl px-4 lg:px-6 pt-8 pb-0 flex flex-col items-start sm:max-w-[406px] w-full">
          <h2 className="text-grey text-lg font-semibold">
            Perform Single lookup
          </h2>
          <p className="mt-2 text-grey20 tracking-tight">
            Verify your user identity with ease
          </p>
          <div className="w-full mt-10 flex flex-col lg:flex-row items-start lg:items-center gap-2">
            <PerformBtn
              btnText="Perform Single lookup"
              permitted={performSingleIndividualLookup}
              setOpen={setOpenIL}
              isCompanyVerified={isCompanyVerified}
            />
            <ViewLink
              permitted={viewSingleIndividualLookup}
              route={PAGES_URL.INDIVIDUAL_LOOKUP_SINGLE}
            />
          </div>
          <div className="mt-auto lg:mt-14 flex justify-center w-full">
            <img src={individualLookupImg} alt="" width={347} height={224} />
          </div>
        </div>

        <div className="bg-white rounded-3xl px-4 lg:px-6 pt-8 pb-0 flex flex-col items-start sm:max-w-[406px] w-full">
          <h2 className="text-grey text-lg font-semibold">
            Perform Batch lookup
          </h2>
          <p className="mt-2 text-grey20 tracking-tight">
            Upload and verify multiple users seamlessly
          </p>
          <div className="w-full mt-10 flex flex-col lg:flex-row items-start lg:items-center gap-2">
            <PerformBtn
              btnText="Perform Batch lookup"
              permitted={performBatchIndividualLookup}
              setOpen={setOpenBL}
              isCompanyVerified={isCompanyVerified}
            />
            <ViewLink
              permitted={viewBatchIndividualLookup}
              route={PAGES_URL.INDIVIDUAL_LOOKUP_BATCH}
            />
          </div>
          <div className="mt-14 flex justify-center w-full">
            <img src={batchLookupImg} alt="" width={329} height={236} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default connect(state => state, Actions)(IndividualLookup);
