export function getStatusColor(status) {
  const lowercaseStatus = status?.toLowerCase();

  switch (lowercaseStatus) {
    case 'verified':
    case 'complete':
    case 'completed':
    case 'deposit':
    case 'credit':
    case 'successful':
      return 'bg-success20 text-success';
    case 'pending':
      return 'bg-[#FFD9512B] text-[#E4B303]';
    case 'not verified':
    case 'not_verified':
      return 'text-white bg-status-404';
    case 'debit':
    case 'failed':
    case 'withdraw':
    case 'withdrawal':
      return 'bg-[#A13E3F2B] text-status-404';
    default:
      return '';
  }
}

export function getSubCategoriesColor(subCategory) {
  const lowercaseSubCategory = subCategory?.toLowerCase();

  switch (lowercaseSubCategory) {
    case 'pep':
      return 'text-success bg-success20';
    case 'adverse-media':
      return 'text-brandBlue50 bg-batchBlue20';
    default:
      return '';
  }
}
export function getReconColor(reconType) {
  const lowercaseSubCategory = reconType?.toLowerCase();

  switch (lowercaseSubCategory) {
    case 'reconciled':
      return 'bg-[#3F7CDB]';
    case 'unreconciled':
      return 'bg-[#242424]';
    default:
      return '';
  }
}
