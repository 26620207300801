import {JSEncrypt} from 'jsencrypt';

export function encryptData(data) {
  const jsEncrypt = new JSEncrypt();

  process.env.REACT_APP_PUBLIC_KEY &&
    jsEncrypt.setPublicKey(process.env.REACT_APP_PUBLIC_KEY);

  return jsEncrypt.encrypt(data);
}
