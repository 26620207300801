import React, {useState} from 'react';
import {
  DashboardLayout,
  EmptyState,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  TableLayout,
} from '../../../../components';
import {
  corruptedFile,
  customersMobileNav,
} from '../../../../assets/images/images';
import {useNavigate, useParams} from 'react-router-dom';
import {Constants} from '../../../../helpers';
import moment from 'moment/moment';
import {useQuery} from 'react-query';
import {fetchCustomers} from '../../../../requests/queries/reconciliations';

function Customers() {
  const {slug} = useParams();
  const [openFilter, setOpenFilter] = useState(false);
  const navigate = useNavigate();
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });
  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };

  const params = {
    project_id: slug,
  };
  const {data: customers, isLoading} = useQuery(
    ['customers', {...params}],
    () => fetchCustomers({params}),
  );

  return (
    <DashboardLayout
      bg="bg-white"
      overFlow="!pr-0"
      leftMargin="!ml-0"
      breadCrumbs={
        <div className="flex items-center">
          <img src={customersMobileNav} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Customers</p>
        </div>
      }
    >
      <div className="pl-11 pr-8">
        <div className="flex items-center justify-between w-full">
          <h3 className="font-medium text-lg text-grey">Customers</h3>

          <div className="items-center hidden gap-4 sm:flex">
            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : !isLoading && customers?.rows?.length === 0 ? (
        <EmptyState
          noBtn
          src={corruptedFile}
          className="!h-[calc(100vh-200px)]"
          maxWidth="max-w-[289px]"
          body={`No customer(s) list was found for this company`}
        />
      ) : (
        <TableLayout className="w-full mt-6 font-inter">
          <thead className="text-small font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">full name</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">customer id</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">email</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">phone</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">last trans. date</span>
              </th>
            </tr>
          </thead>
          <tbody className="text-sm text-body bg-white">
            {customers &&
              customers?.rows.map(customer => (
                <tr
                  key={customer?.id}
                  onClick={() =>
                    navigate(
                      Constants.PAGES_URL.RECONCILIATION_CUSTOMER_DETAILS.replace(
                        ':slug',
                        customer?.id,
                      ),
                    )
                  }
                  className="text-left border-b cursor-pointer border-grey60"
                >
                  <td className="p-4 pl-12 whitespace-nowrap ">
                    {customer?.name || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap uppercase">
                    {customer?.customer_id || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {customer?.email || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {customer?.phone || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {moment(customer?.createdAt).format('DD MMM YYYY h:m a')}
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>
      )}
      {!isLoading && customers?.rows?.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
          <PageLimit
            onLimitChange={limit => {
              setQuery(prevState => ({
                ...prevState,
                limit,
                page: 1,
              }));
            }}
            limit={query?.limit}
            total={customers?.page}
            length={customers?.rows?.length}
          />

          <Pagination
            total={customers?.pages}
            current={Number(customers?.page)}
            onPageChange={activePage => {
              pageClick(activePage);
              const searchParams = new URLSearchParams(
                document.location.search,
              );
              searchParams.set('page', activePage);
            }}
          />
        </div>
      )}
    </DashboardLayout>
  );
}

export default Customers;
