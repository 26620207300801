import {useEffect, useState} from 'react';

function useTour(key, loading, data, updateTourState) {
  const [tourModal, setTourModal] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.keyCode === 27 && !isDismissed) {
        setTimeout(() => {
          updateTourState({[key]: false});
          setIsDismissed(true);
        }, 300);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isDismissed, key, updateTourState]);

  const handleTourComplete = () => {
    setTourModal(false);
    setTimeout(() => {
      updateTourState({[key]: false});
      setIsDismissed(true);
    }, 300);
  };

  useEffect(() => {
    if (!loading && data?.length > 0) {
      setTourModal(true);
    }
  }, [data?.length, loading]);

  return {tourModal, setTourModal, handleTourComplete};
}

export {useTour};
