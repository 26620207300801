import React from 'react';
import Chart from 'react-apexcharts';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';

function AccountDetailsPieChart({totalRecon = 0, recon = 0, unrecon = 0}) {
  const options = {
    labels: ['Reconciled', 'Unreconciled'],
    colors: ['#6FCBD4', '#A13E3F'],
    dataLabels: {
      enabled: false,
    },
    legend: false,
    stroke: {
      width: 0,
    },
    tooltip: {
      theme: false,
    },
  };

  const series = [recon, unrecon];

  return (
    <div className="relative  flex justify-center items-center">
      <Chart type="donut" options={options} series={series} />
      <div
        className="absolute flex flex-col items-center w-fit"
        style={{top: '34%'}}
      >
        <h1 className="font-semibold text-xxl text-grey">
          {shortenLargeNumbers(totalRecon, null, {startAt: 'M', decimals: 0})}
        </h1>
        <span className="text-xs font-medium uppercase text-grey">
          Total reconciliation
        </span>
      </div>
    </div>
  );
}

export default AccountDetailsPieChart;
