export const DEFAULT_PAGES = Object.freeze(
  [
    {
      page: 'indemnity',
      config: {},
      'indemnity-0': true,
    },
    {
      page: 'government-data',
      config: {},
    },
    {
      page: 'business-data',
      config: {},
    },
    {
      page: 'user-data',
    },
    {
      page: 'phone-number',
      config: {},
    },
    {
      page: 'email',
      config: {},
    },
    {
      page: 'business-id',
      config: {},
    },
    {
      page: 'address',
      config: {},
    },
    {
      page: 'id',
      config: {},
    },
    {
      page: 'selfie',
      config: {type: 'basic'},
    },
    {
      page: 'additional-document',
      config: {},
    },
    {
      page: 'signature',
      config: {},
    },
    {
      page: 'custom-questions',
      config: {},
    },
  ].map((page, index) => ({
    ...page,
    pageId: index,
  })),
);

export const DEFAULT_FRAUD_PAGES = [
  {
    page: 'user_data',
    user_data: {
      fields: ['first_name'],
      action: 'pending',
    },
  },
  {
    page: 'age_limit',
    age_limit: {
      limit: 18,
      action: 'pending',
    },
  },
  {
    page: 'liveness_match',
    liveness_match: {
      glassesCheck: true,
      brightnessThreshold: 40,
    },
  },
  {
    page: 'aml_screening',
    aml_screening: {
      action_returned: 'pending',
    },
  },
  // {
  //   page: 'duplicate_check',
  //   duplicate_check: {
  //     action_returned: 'blocked',
  //   },
  // },
  {
    page: 'ip_screening',
    ip_screening: {
      action_blacklisted: 'pending',
    },
  },
];
