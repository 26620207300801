import React, {useState} from 'react';
import {
  DashboardLayout,
  EmptyState,
  // FilterButton,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  // SearchInput,
  SecondaryButton,
  TableLayout,
} from '../../../components';
import {corruptedFile, projectsMobileNav} from '../../../assets/images/images';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import PaymentInformationModal from './PaymentInformationModal';
import {Constants} from '../../../helpers';
import {useBillingInfo} from './hooks/useBillingInfo';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import {getStatusTextAndStyles} from '../../../helpers/getStatusTextAndStyles';
import {useCancelSubscription} from './hooks/useCancelSubscription';

function BillingPaymentHistory() {
  const navigate = useNavigate();
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });
  const [isPaymentInfoModal, setIsPaymentInfoModal] = useState(false);

  const {billingInfo, billingHistory, historyLoading} = useBillingInfo({
    isInfo: false,
  });
  const {handleCancelSubscription, cancelSubscriptionMutation} =
    useCancelSubscription({billingInfo});

  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };
  const togglePaymentInfoModal = () =>
    setIsPaymentInfoModal(prevState => !prevState);

  return (
    <DashboardLayout
      bg="bg-white80"
      topPadding="pt-8"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={projectsMobileNav} alt="Projects Mobile Navigation" />
          <p className="ml-2 -mb-1">Billing {'>'} History</p>
        </div>
      }
    >
      <p className="text-grey font-medium text-lg mt-10 lg:mt-0">
        Billing History
      </p>

      <div className="mt-7 bg-white border border-[#EFF2F5] rounded-2xl py-9 px-6 flex flex-col lg:flex-row lg:items-center justify-between">
        <div className="flex flex-col">
          <p className="text-body font-medium text-sm">Current Plan</p>
          <h3 className="text-grey capitalize font-semibold text-xxl">
            {billingInfo?.plan === 'Basic'
              ? 'Base'
              : billingInfo?.plan || 'Base'}{' '}
            tier
          </h3>
        </div>

        <div className="flex items-center gap-2">
          <PrimaryButton
            buttonText="Change Plan"
            className="text-xs !py-2 !px-8 rounded-lg"
            onClick={() => navigate(Constants.PAGES_URL.RECONCILIATION_BILLING)}
          />
          {billingInfo?.billing_status && (
            <SecondaryButton
              buttonText="Cancel Subscription"
              className="text-xs !py-2 !px-3 h-fit rounded-lg font-inter text-error !outline-[#d7dde4]"
              loading={cancelSubscriptionMutation?.isLoading}
              onClick={handleCancelSubscription}
            />
          )}

          <PaymentInformationModal
            show={isPaymentInfoModal}
            onClose={togglePaymentInfoModal}
          />
        </div>
      </div>

      {/*<div className="flex items-start lg:items-center lg:flex-row flex-col gap-5 justify-between p-5 lg:p-0 mt-14">*/}
      {/*  <p className="text-grey font-medium text-[18px]">Payment history</p>*/}
      {/*  <div className="flex items-center gap-2">*/}
      {/*    <SearchInput className="sm:w-[277px] rounded-lg outline-[#eceff3]" />*/}
      {/*    <FilterButton*/}
      {/*      openFilter={openFilter}*/}
      {/*      setOpenFilter={setOpenFilter}*/}
      {/*      className="h-[48px] justify-center w-[130px]"*/}
      {/*    />*/}
      {/*    <PrimaryButton*/}
      {/*      onClick={togglePaymentInfoModal}*/}
      {/*      buttonText="Export"*/}
      {/*      className="w-[121px]"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {historyLoading ? (
        <Loader height={45} />
      ) : !historyLoading && billingHistory?.rows?.length === 0 ? (
        <EmptyState
          src={corruptedFile}
          buttonText="Choose Plan"
          heading="No Payment history found"
          body="There is no Payment history to display here at the moment."
          height={55}
          onClick={() => navigate(Constants.PAGES_URL.RECONCILIATION_BILLING)}
        />
      ) : (
        <div className="mt-9">
          <TableLayout negativeMargins className="mt-6 w-full">
            <thead className="text-small font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                  amount
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  payment date
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  subscription type
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  ref number
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  status
                </th>{' '}
              </tr>
            </thead>
            <tbody className="text-sm text-body">
              {!historyLoading &&
                billingHistory?.rows.map(history => (
                  <tr
                    key={history?.id}
                    className="text-left border-b  bg-white  border-grey60"
                  >
                    <td className="p-4 pl-12 whitespace-nowrap">
                      {shortenLargeNumbers(history?.amount, true, {
                        startAt: 'B',
                      })}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {(history?.createdAt &&
                        moment(history?.createdAt).format(
                          'Do MMM YYYY h:m a',
                        )) ||
                        '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {history?.plan || '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      {history?.reference || '-'}
                    </td>
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <span
                        className={`uppercase w-fit rounded-full px-2 py-1 h-5 font-inter flex items-center justify-center  text-xs ${
                          getStatusTextAndStyles(history.status)?.solid
                        }`}
                      >
                        {history?.status || '-'}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableLayout>
        </div>
      )}

      {!historyLoading && billingHistory?.rows?.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
          <PageLimit
            onLimitChange={limit => {
              setQuery(prevState => ({
                ...prevState,
                limit,
                page: 1,
              }));
            }}
            limit={query?.limit}
            total={billingHistory?.page}
            length={billingHistory?.rows?.length}
          />

          <Pagination
            total={billingHistory?.pages}
            current={Number(query?.page)}
            onPageChange={activePage => {
              pageClick(activePage);
              const searchParams = new URLSearchParams(
                document.location.search,
              );
              searchParams.set('page', activePage);
            }}
          />
        </div>
      )}
    </DashboardLayout>
  );
}

export default BillingPaymentHistory;
