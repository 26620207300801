import React, {useMemo, useState} from 'react';
import {
  corruptedFile,
  eye,
  projectsMobileNav,
} from '../../../../assets/images/images';
import AddProjectsModal from './AddProjectsModal';
import {useNavigate} from 'react-router-dom';
import {Constants} from '../../../../helpers';
import {
  DashboardLayout,
  EmptyState,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../../../components';
import {useQuery} from 'react-query';
import {fetchProjects} from '../../../../requests/queries/reconciliations';
import {useSelector} from 'react-redux';
import moment from 'moment';

function Projects() {
  const [isAddProject, setIsAddProject] = useState(false);
  const {userDetails} = useSelector(state => state?.auth);
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  const params = useMemo(
    () => ({
      company_id: userDetails && userDetails?.company?.id,
      ...query,
    }),
    [query, userDetails],
  );

  const navigate = useNavigate();

  const {data: projects, isLoading} = useQuery(['projects', {...params}], () =>
    fetchProjects({params}),
  );

  const openAddProjectModal = () => {
    setIsAddProject(true);
  };

  const closeAddProjectModal = () => {
    setIsAddProject(false);
  };

  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };

  return (
    <DashboardLayout
      bg="bg-white"
      overFlow="!pr-0"
      leftMargin="!ml-0"
      breadCrumbs={
        <div className="flex items-center">
          <img src={projectsMobileNav} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Projects</p>
        </div>
      }
    >
      <div className="flex items-center justify-between w-full pl-11 pr-8">
        <h3 className="font-medium text-lg text-grey">Projects</h3>

        <PrimaryButton
          buttonText="Add Project"
          className="lg:py-[14px] lg:px-[47px] rounded-lg"
          onClick={openAddProjectModal}
        />
      </div>

      <AddProjectsModal show={isAddProject} onClose={closeAddProjectModal} />

      {isLoading ? (
        <Loader height={45} />
      ) : !isLoading && projects?.rows?.length === 0 ? (
        <EmptyState
          noBtn
          src={corruptedFile}
          className="!h-[calc(100vh-200px)]"
          maxWidth="max-w-[289px]"
          body="Create a project to determine the type of transaction tied to an account"
          customBtn={
            <PrimaryButton
              buttonText="Add Project"
              className="py-[14px] px-[47px] rounded-lg mt-6"
              onClick={openAddProjectModal}
            />
          }
        />
      ) : (
        <TableLayout className="mt-4 w-full font-inter">
          <thead className="text-small font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap w-[28.07%]">
                project name
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap w-[23.58%]">
                project type
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap w-[21.59%]">
                account
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap w-[22.42%]">
                date created
              </th>
              <th className="w-[4.31%]" />
            </tr>
          </thead>
          <tbody className="text-sm text-body">
            {projects &&
              projects?.rows?.map(project => (
                <tr
                  key={project?.id}
                  className="text-left border-b cursor-pointer border-grey60"
                >
                  <td className="p-4 pl-12 whitespace-nowrap w-[28.07%]">
                    {project?.name || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap w-[23.58%]">
                    {project?.type || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap w-[21.59%]">
                    {project?.desc || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap w-[22.42%]">
                    {moment(project?.createdAt).format('Do MMM YYYY h:m a') ||
                      '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap w-[4.31%]">
                    <button
                      className="cursor-pointer"
                      onClick={() => {
                        navigate(
                          Constants.PAGES_URL.PROJECT_OVERVIEW.replace(
                            ':slug',
                            project?.id,
                          ),
                        );
                      }}
                    >
                      <img src={eye} alt="" width={16} height={16} />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>
      )}

      {!isLoading && projects?.rows?.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
          <PageLimit
            onLimitChange={limit => {
              setQuery(prevState => ({
                ...prevState,
                limit,
                page: 1,
              }));
            }}
            limit={query?.limit}
            total={projects?.page}
            length={projects?.rows?.length}
          />

          <Pagination
            total={projects?.pages}
            current={Number(projects?.page)}
            onPageChange={activePage => {
              pageClick(activePage);
              const searchParams = new URLSearchParams(
                document.location.search,
              );
              searchParams.set('page', activePage);
              const newSearchParams = searchParams.toString();
              navigate(`?${newSearchParams}`);
            }}
          />
        </div>
      )}
    </DashboardLayout>
  );
}

export default Projects;
