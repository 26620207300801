import {supportingDoc} from '../assets/images/images';
import React from 'react';

export function DocumentListItem({doc, openFileInNewTab, editDoc, deleteDoc}) {
  const {fileTitle, fileExtension, fileSize} = (doc && doc) || {};

  return (
    <div className="flex w-full justify-between items-center flex-wrap">
      <div className="flex flex-1 items-end gap-4 p-4 rounded-xl bg-white border border-[#F0F0F0] items-center">
        <img src={supportingDoc} alt="" width={24} height={24} />
        <div className="flex items-center justify-between w-full flex-wrap">
          <div className="flex items-center sm:gap-2 flex-wrap">
            <p className="text-grey text-sm font-medium">
              {fileTitle}{' '}
              <span className="text-[#B8B8B8] uppercase">.{fileExtension}</span>
            </p>
            <div className="w-[3px] h-[3px] bg-[#767676] invisible sm:visible rounded-full mb-1" />
            {openFileInNewTab ? (
              <a
                onClick={openFileInNewTab}
                className="text-small text-brandBlue -mb-[2px] cursor-pointer"
              >
                View document
              </a>
            ) : (
              editDoc
            )}
          </div>
          <div>
            <p className="uppercase text-xs font-medium text-body">
              {fileSize}
            </p>
          </div>
        </div>
      </div>
      {deleteDoc && deleteDoc}
    </div>
  );
}
