import {useDispatch} from 'react-redux';
import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {
  fetchAllApps,
  fetchOnboardFlowApps,
} from '../requests/queries/dashboard';

export const useAllApps = () => {
  const dispatch = useDispatch();

  const {data, isLoading, isFetching} = useQuery(['all-apps'], () =>
    fetchAllApps({params: {limit: 10000}, dispatch}),
  );

  const userApps = useMemo(
    () =>
      (!isLoading &&
        data &&
        data?.apps?.map(app => ({
          value: app._id,
          label: app.name,
          name: app.name,
          color: app.color_code,
          logo: app.logo,
        }))) ||
      [],
    [data, isLoading],
  );

  const appIds = useMemo(() => userApps?.map(app => app?.value), [userApps]);

  return {userApps, appIds, isLoading: isLoading || isFetching};
};

export const useOnboardFlowApps = () => {
  const {data, isLoading, isFetching} = useQuery(['onboard-flow-apps'], () =>
    fetchOnboardFlowApps({params: {limit: 10000}}),
  );

  const userApps = useMemo(
    () =>
      (!isLoading &&
        data &&
        data?.map(app => ({
          value: app._id,
          label: app.name,
          name: app.name,
          color: app.color_code,
          logo: app.logo,
        }))) ||
      [],
    [data, isLoading],
  );

  return {userApps, isLoading: isLoading || isFetching};
};
