import React from 'react';
import {verificationsNav} from '../../assets/images/images';
import AttentionLottie from '../../assets/lotties/AttentionLottie';
import {useTour} from '../../hooks';
import Modal from '../Modal';

export default function VerificationsTour({updateTourState, loading, DATA}) {
  const {tourModal, setTourModal, handleTourComplete} = useTour(
    'verifications_tour',
    loading,
    DATA,
    updateTourState,
  );
  return (
    <Modal
      show={tourModal}
      onClose={setTourModal}
      extraModalClasses="max-w-fit overflow-y-auto h-fit max-h-[95%] sm:max-h-full absolute bottom-0 sm:relative text-left"
      modalPosition="justify-center sm:justify-start items-end"
      modalPadding="p-6 pt-[108px] pb-[36vh]"
      noBottomPadding
      borderRadius="rounded"
      modalBg="bg-transparent"
      pointerEventsNone
      overlayBackground
    >
      <div className="flex items-center relative outline-none" tabIndex={0}>
        <button className="rounded flex items-center gap-2 pl-4 py-2 relative bg-[#1c1d31] w-fit pr-20">
          <img src={verificationsNav} alt="" width={18} height={18} />
          <span className="-mb-1 text-sm lg:text-white text-grey">
            ID Verifications
          </span>
        </button>
        <AttentionLottie
          style={{
            width: '48px',
            height: '48px',
            transform: 'matrix(0.71, -0.71, -0.71, -0.71, 0, 0)',
          }}
          className="absolute top-4 right-[257px]"
        />
        <div className="p-2 mt-5 flex flex-col items-start gap-2 bg-white ml-[55px] rounded">
          <p className="text-sm font-medium text-grey">Verifications</p>
          <p className="text-sm text-body max-w-[238px]">
            View the verifications of your easy onboard flow
          </p>
          <button
            className="text-brandBlue text-xs font-medium focus-visible:outline-brandBlue"
            onClick={handleTourComplete}
          >
            Dismiss
          </button>
        </div>
      </div>
    </Modal>
  );
}
