// components/SideNavigation/Components.js
import React from 'react';
import {NavLink} from 'react-router-dom';
import {dropdownFill, dropdownGrey} from '../../assets/images/images';

const SubNavigationDropdown = ({toggleSubRoute, src, media, open, label}) => (
  <button
    className="flex items-center gap-2 pl-4 py-2 hover:bg-brandBlue40 lg:hover:bg-[#1c1d31] rounded cursor-pointer relative"
    onClick={toggleSubRoute}
  >
    <img src={src} alt="" width={18} height={18} />
    <span className="-mb-1 text-sm lg:text-white text-grey">{label}</span>
    <img
      src={media ? dropdownFill : dropdownGrey}
      alt=""
      className={`absolute right-4 ${
        open
          ? '-rotate-180 ease-in duration-300 transition'
          : 'rotate-0 ease-out duration-300 transition'
      }`}
      width={14}
      height={14}
    />
  </button>
);

const NavigationItem = ({
  mobileIcon,
  webIcon,
  media,
  title,
  to,
  active,
  inner,
}) => {
  const icon = media ? webIcon : mobileIcon;
  return (
    <NavLink
      to={to}
      className={`nav-item rounded flex items-center gap-2 ${
        inner ? 'pl-8' : 'pl-4'
      } py-2 relative ${active ? 'active' : ''}`}
      aria-current="page"
    >
      <img src={icon} alt="" width={18} height={18} />
      <span className="-mb-1 text-sm lg:text-white text-grey">{title}</span>
    </NavLink>
  );
};

export {NavigationItem, SubNavigationDropdown};
