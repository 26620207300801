export const convertToPercentage = (value, total) => {
  if (!isNaN(value) && !isNaN(total)) {
    const convertedValue = Math.round((value / total) * 100);
    return `${
      convertedValue === Infinity ||
      convertedValue === -Infinity ||
      isNaN(convertedValue)
        ? 0
        : convertedValue
    }%`;
  }
  return '0%';
};
