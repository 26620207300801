import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Loader, Modal, PrimaryButton} from '../../../../components';
import * as Actions from '../../../../store/actions';
import Layout from './Layout';
import FileDropZone from '../../../../components/FileDropZone';
import {useParams} from 'react-router-dom';
import {calculateFileSize} from '../../../../helpers';
import {DocumentListItem} from '../../../../components/DocumentListItem';
import {trashRed} from '../../../../assets/images/images';

function SupportingDoc({
  flows: {loading, cases_documents, uploadDocsLoading, deleteDocsLoading},
  auth: {user},
  getCasesDocuments,
  uploadCasesDocuments,
  deleteCasesDocument,
}) {
  const {slug} = useParams();
  const [files, setFilesData] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [file, setFile] = useState('');

  const DOCS = (cases_documents && cases_documents?.documents) || [];

  useEffect(() => {
    getCasesDocuments(slug);
    setUploaded(false);
  }, [getCasesDocuments, slug]);

  const uploadDocuments = useCallback(() => {
    if (!files || files.length === 0) return;
    files.map(file => {
      const data = {
        document: file.file,
        // name: file.filename,
        author:
          (user && user?.first_name
            ? `${user?.first_name} ${user?.last_name}`
            : user?.email) || 'Author',
      };
      uploadCasesDocuments({id: slug, data}, setUploaded);
    });
  }, [files, user, uploadCasesDocuments, slug]);

  function extractFileInfo(dataURI) {
    const mimeTypeIndex = dataURI.indexOf(';base64,');
    if (mimeTypeIndex === -1) {
      throw new Error(
        "Invalid data URI format. It should start with 'data:image/png;base64,'.",
      );
    }
    const mimeType = dataURI.substring(5, mimeTypeIndex);
    const fileExtension = mimeType.split('/')[1];
    const base64Data = dataURI.substring(mimeTypeIndex + 8);
    const fileSize = Math.ceil((base64Data.length * 3) / 4);

    const fileSizeString = calculateFileSize(fileSize);
    return {
      fileTitle: 'Supporting doc',
      fileExtension,
      fileSize: fileSizeString,
    };
  }

  const ShowDocs = () => {
    const isImage = file.startsWith('data:image/');

    const isPdf = file.startsWith('data:application/pdf');
    return (
      <div className="w-full h-auto">
        {isImage ? (
          <img
            src={file}
            alt={file.name}
            className="w-auto h-auto object-cover"
          />
        ) : (
          <div
            className={`w-full h-full object-contain ${
              isPdf ? 'pdf-thumbnail' : ''
            }`}
          >
            {isPdf && (
              <object width="100%" height="1000px" data={file}></object>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <Layout>
      <div className=" xl:ml-4 xl:basis-[43.91%] mt-6 xl:mt-0 px-4 grow xl:grow-0 xl:p-8 pt-4 xl:pt-[36px] bg-white80 xl:bg-white">
        <>
          <div className="flex justify-between">
            <h2 className="font-medium">Upload supporting documents</h2>
          </div>

          <div className="relative w-full">
            <Modal
              show={Boolean(file)}
              onClose={() => setFile('')}
              extraModalClasses="max-w-[728px] z-10  h-fit max-h-[95%] sm:max-h-full  absolute bottom-0 sm:relative"
              noHidden
            >
              <div
                onClick={() => setFile('')}
                className="flex cursor-pointer items-center justify-center absolute -top-10 -right-10 rounded-full z-50 bg-white h-8 w-8"
              >
                <div className="w-5 h-5 border rounded-full flex items-center justify-center">
                  <span className="text-xs">x</span>
                </div>
              </div>
              <ShowDocs />
            </Modal>
          </div>

          <div className="mt-4 flex flex-col gap-6 bg-white80 rounded py-6 pl-4 pr-7">
            {loading ? (
              <Loader height={20} />
            ) : (
              <div
                className="flex flex-col gap-4 "
                style={{opacity: deleteDocsLoading ? 0.4 : 1}}
              >
                <p className="text-sm text-body font-medium">File added</p>
                {DOCS ? (
                  DOCS?.map(doc => (
                    <DocumentListItem
                      key={doc?._id}
                      doc={extractFileInfo(doc?.document || '')}
                      openFileInNewTab={() => {
                        setFile(doc?.document);
                      }}
                      url={doc?.document}
                      deleteDoc={
                        <div
                          onClick={() =>
                            deleteCasesDocument({id: slug, docId: doc?._id})
                          }
                          className="ml-3 cursor-pointer"
                        >
                          <img src={trashRed} alt="Delete" />
                        </div>
                      }
                    />
                  ))
                ) : (
                  <p className="text-md text-center text-batchBlue font-medium">
                    No file added yet.
                  </p>
                )}
              </div>
            )}
            <div className="w-full h-[1px] bg-grey60" />
            <div>
              <div className="flex justify-between mb-3">
                <p className="text-body text-sm mb-2 font-medium">
                  Upload documents
                </p>
              </div>

              <FileDropZone
                uploadFiles={setFilesData}
                uploaded={uploaded}
                uploadBtn={
                  <PrimaryButton
                    buttonText={'Confirm upload'}
                    fontSize="text-sm"
                    className="w-full"
                    onClick={uploadDocuments}
                    disabled={!files.length || uploadDocsLoading}
                    loading={uploadDocsLoading}
                  />
                }
              />
            </div>
          </div>
        </>
      </div>
    </Layout>
  );
}

export default connect(state => state, Actions)(SupportingDoc);
