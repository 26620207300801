import React from 'react';

export const DocumentAnalysis2 = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={355}
    height={109}
    fill="none"
    {...props}
  >
    <rect
      width={324.38}
      height={59.326}
      x={0.957}
      y={3.243}
      fill="#fff"
      style={{
        stroke: 'var(--color-primary)',
        strokeOpacity: 0.2,
      }}
      strokeWidth={1.914}
      rx={3.827}
    />
    <g filter="url(#a)">
      <g clipPath="url(#b)">
        <rect
          width={36.361}
          height={36.361}
          x={30.619}
          y={14.725}
          style={{
            fill: 'var(--color-primary)',
          }}
          rx={18.181}
        />
        <circle cx={48.799} cy={31.949} r={6.379} fill="url(#c)" />
        <path
          fill="url(#d)"
          d="M61.559 50.29c0 3.963 0 7.176-12.759 7.176-12.758 0-12.758-3.213-12.758-7.177 0-3.963 5.712-7.177 12.758-7.177 7.047 0 12.759 3.214 12.759 7.177Z"
          opacity={0.5}
        />
      </g>
      <rect
        width={36.361}
        height={36.361}
        x={30.619}
        y={14.725}
        stroke="#fff"
        strokeWidth={1.914}
        rx={18.181}
      />
    </g>
    <rect
      width={138.747}
      height={11.482}
      x={77.507}
      y={27.164}
      fill="#F2F4F7"
      rx={5.741}
    />
    <rect
      width={324.38}
      height={59.326}
      x={15.309}
      y={23.337}
      fill="#fff"
      style={{
        stroke: 'var(--color-primary)',
        strokeOpacity: 0.2,
      }}
      strokeWidth={1.914}
      rx={3.827}
    />
    <g filter="url(#e)">
      <g clipPath="url(#f)">
        <rect
          width={36.361}
          height={36.361}
          x={44.973}
          y={34.819}
          style={{
            fill: 'var(--color-primary)',
          }}
          rx={18.181}
        />
        <circle cx={63.154} cy={52.043} r={6.379} fill="url(#g)" />
        <path
          fill="url(#h)"
          d="M75.912 70.383c0 3.964 0 7.177-12.758 7.177-12.759 0-12.759-3.213-12.759-7.177 0-3.963 5.713-7.176 12.759-7.176s12.758 3.213 12.758 7.176Z"
          opacity={0.5}
        />
      </g>
      <rect
        width={36.361}
        height={36.361}
        x={44.973}
        y={34.819}
        stroke="#fff"
        strokeWidth={1.914}
        rx={18.181}
      />
    </g>
    <rect
      width={138.747}
      height={11.482}
      x={91.859}
      y={47.259}
      fill="#F2F4F7"
      rx={5.741}
    />
    <rect
      width={324.38}
      height={59.326}
      x={29.663}
      y={43.431}
      fill="#fff"
      style={{
        stroke: 'var(--color-primary)',
        strokeOpacity: 0.2,
      }}
      strokeWidth={1.914}
      rx={3.827}
    />
    <g filter="url(#i)">
      <g clipPath="url(#j)">
        <rect
          width={36.361}
          height={36.361}
          x={59.326}
          y={54.914}
          style={{
            fill: 'var(--color-primary)',
          }}
          rx={18.181}
        />
        <circle cx={77.506} cy={72.137} r={6.379} fill="url(#k)" />
        <path
          fill="url(#l)"
          d="M90.266 90.478c0 3.963 0 7.176-12.759 7.176-12.758 0-12.758-3.213-12.758-7.176 0-3.964 5.712-7.177 12.758-7.177 7.047 0 12.759 3.213 12.759 7.177Z"
          opacity={0.5}
        />
      </g>
      <rect
        width={36.361}
        height={36.361}
        x={59.326}
        y={54.914}
        stroke="#fff"
        strokeWidth={1.914}
        rx={18.181}
      />
    </g>
    <rect
      width={138.747}
      height={11.482}
      x={106.213}
      y={67.353}
      fill="#F2F4F7"
      rx={5.741}
    />
    <defs>
      <linearGradient
        id="c"
        x1={47.278}
        x2={60.755}
        y1={35.774}
        y2={17.804}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={48.8}
        x2={48.8}
        y1={43.112}
        y2={54.586}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="g"
        x1={61.632}
        x2={75.11}
        y1={55.868}
        y2={37.898}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="h"
        x1={63.154}
        x2={63.154}
        y1={63.207}
        y2={74.68}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="k"
        x1={75.985}
        x2={89.462}
        y1={75.962}
        y2={57.992}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="l"
        x1={77.507}
        x2={77.507}
        y1={83.301}
        y2={94.775}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <clipPath id="b">
        <rect
          width={36.361}
          height={36.361}
          x={30.619}
          y={14.725}
          fill="#fff"
          rx={18.181}
        />
      </clipPath>
      <clipPath id="f">
        <rect
          width={36.361}
          height={36.361}
          x={44.973}
          y={34.819}
          fill="#fff"
          rx={18.181}
        />
      </clipPath>
      <clipPath id="j">
        <rect
          width={36.361}
          height={36.361}
          x={59.326}
          y={54.914}
          fill="#fff"
          rx={18.181}
        />
      </clipPath>
      <filter
        id="a"
        width={66.981}
        height={66.981}
        x={15.309}
        y={0.851}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1.435} />
        <feGaussianBlur stdDeviation={7.177} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.675 0 0 0 0 0.675 0 0 0 0 0.675 0 0 0 0.35 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5427_65619"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5427_65619"
          result="shape"
        />
      </filter>
      <filter
        id="e"
        width={66.981}
        height={66.981}
        x={29.663}
        y={20.945}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1.435} />
        <feGaussianBlur stdDeviation={7.177} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.675 0 0 0 0 0.675 0 0 0 0 0.675 0 0 0 0.35 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5427_65619"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5427_65619"
          result="shape"
        />
      </filter>
      <filter
        id="i"
        width={66.981}
        height={66.981}
        x={44.016}
        y={41.039}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1.435} />
        <feGaussianBlur stdDeviation={7.177} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.675 0 0 0 0 0.675 0 0 0 0 0.675 0 0 0 0.35 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5427_65619"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5427_65619"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
