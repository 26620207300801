import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Select from 'react-select';
import {toast} from 'react-toastify';

import {
  customStyles,
  SearchIndicator,
  PrimaryButton,
} from '../../../../../components';
import {useMutation} from 'react-query';
import {initiateBAASTransfer} from '../../../../../requests/queries/baas';
import {useRetrieveUserBankName} from '../../hooks/useRetrieveUserBankName';
import {useBaasSettings} from '../../hooks/useBaasSettings';
import {useNavigate} from 'react-router-dom';

const ACCOUNT_NUMBER_MAX = 10;

export const BankDetailsForm = ({
  userBalance = 0,
  setWithdrawalSteps,
  type = 'transfer',
}) => {
  const [userAccount, setUserAccount] = useState({
    amount: '',
    account_number: '',
  });
  const [amount, setAmount] = useState('');

  const navigate = useNavigate();
  const {
    user,
    banks,
    userDetails,
    accountNumber,
    banksLoading,
    selectedBank,
    setSelectedBank,
  } = useBaasSettings({type: 'transfer'});
  const initiateWithdrawalMutation = useMutation(initiateBAASTransfer, {
    onSuccess: data => {
      sessionStorage.setItem(
        'withdrawal_id',
        String(data?.entity?.withrawal_id),
      );
      setSelectedBank({});
      setUserAccount({});
      setWithdrawalSteps('pin');
    },
  });

  const isPayout = type !== 'transfer';

  const {userBankDetails, isBankDetailsLoading} = useRetrieveUserBankName({
    accountNumber: userAccount?.account_number,
    bankCode: selectedBank?.value,
  });

  const handleSubmit = useCallback(async () => {
    const userAccountNumber = userAccount?.account_number;
    if (
      !isPayout &&
      (!userAccountNumber || userAccountNumber?.length < ACCOUNT_NUMBER_MAX)
    ) {
      return toast.error('Account number MUST be 10 digits.');
    }
    try {
      const payoutData = {
        email: user?.email,
        withdrawal_type: type,
        company_id: userDetails?.company?.id,
        amount: userAccount?.amount,
      };
      const transferData = {
        bank_code: selectedBank?.value,
        ...payoutData,
        ...userAccount,
      };

      const dataType = type === 'transfer' ? transferData : payoutData;

      if (Object.values(dataType || {}).every(e => e)) {
        sessionStorage.setItem('w_amount', String(userAccount?.amount));
        await initiateWithdrawalMutation.mutateAsync(dataType);
      }
    } catch (error) {
      setSelectedBank({});
      setUserAccount({});
      toast.error(error.response?.data?.error || 'Failed to update PIN');
    }
  }, [
    initiateWithdrawalMutation,
    isPayout,
    selectedBank?.value,
    setSelectedBank,
    type,
    user?.email,
    userAccount,
    userDetails?.company?.id,
  ]);

  const handleInput = e => {
    const {name, value} = e.target;
    setUserAccount(prev => ({...prev, [name]: value}));
  };

  useEffect(() => {
    const cleanNumber = Number(amount?.replaceAll(',', '')) || 0;
    if (!isNaN(cleanNumber)) {
      const formattedNumber = cleanNumber.toLocaleString('en-US');

      formattedNumber !== '0' && setAmount(String(formattedNumber));
    }
  }, [amount]);

  const isBalanceError = useMemo(
    () => +userBalance < Number(userAccount?.amount?.replaceAll(',', '')),
    [userAccount?.amount, userBalance],
  );
  const isNubanError = useMemo(
    () => userAccount?.account_number?.length > ACCOUNT_NUMBER_MAX,
    [userAccount?.account_number?.length],
  );
  const isAccountDetailsValid = useMemo(
    () =>
      !isNubanError &&
      !isBalanceError &&
      userAccount?.account_number &&
      userAccount?.amount &&
      !isBankDetailsLoading &&
      userBankDetails?.entity?.account_name &&
      selectedBank?.value,
    [
      isBalanceError,
      isBankDetailsLoading,
      isNubanError,
      selectedBank?.value,
      userAccount?.account_number,
      userAccount?.amount,
      userBankDetails?.entity?.account_name,
    ],
  );

  const isPayoutActive = useMemo(
    () => userAccount?.amount,
    [userAccount?.amount],
  );

  return (
    <div className="mt-6">
      <label htmlFor="amount">
        <span className="text-sm text-body font-medium">Amount</span>
        <input
          type="text"
          // inputMode="numeric"
          name="amount"
          className={`mt-2 placeholder:text-grey-200 placeholder:font-normal ${
            isBalanceError
              ? 'outline outline-1 outline-danger focus:outline-status-404'
              : ''
          }`}
          id="amount"
          placeholder="Input amount to withdraw"
          value={amount}
          onChange={e => {
            const cleanValue = e.target.value?.replaceAll(',', '');
            if (!isNaN(Number(cleanValue))) {
              handleInput(e);
              setAmount(cleanValue);
            }
          }}
        />
      </label>
      {isBalanceError && (
        <p className="text-xs text-status-404 text-left mt-1">
          Amount MUST be less than or equal to your current balance.
        </p>
      )}

      {type === 'transfer' && (
        <div className="relative">
          <span className="relative w-full inline-flex  mt-4 text-body text-sm font-medium">
            {isPayout && banksLoading ? 'Fetching bank name....' : 'Bank name'}{' '}
            <span className="ml-1 text-grey-200 italic">
              {' '}
              {!isPayout && '(Search with bank name)'}
            </span>
          </span>
          <Select
            placeholder={
              isPayout && banksLoading
                ? 'Fetching bank name....'
                : 'Select your bank'
            }
            components={{DropdownIndicator: SearchIndicator}}
            classNamePrefix="react-select"
            styles={customStyles}
            options={banks}
            value={selectedBank}
            onChange={data => setSelectedBank(data)}
            menuPlacement="auto"
            className={`text-left ${
              type !== 'transfer'
                ? 'text-grey-200 opacity-40 cursor-not-allowed'
                : ''
            }`}
            isDisabled={type !== 'transfer'}
          />
        </div>
      )}
      {type === 'transfer' && (
        <>
          <label htmlFor="account_number">
            <span className="text-sm text-body font-medium mt-6">
              Account number
            </span>
            <input
              type="number"
              inputMode="numeric"
              name="account_number"
              className={`mt-2 placeholder:text-grey-200 placeholder:font-normal ${
                type !== 'transfer'
                  ? 'text-grey-200 opacity-50 cursor-not-allowed'
                  : ''
              }`}
              id="account_number"
              placeholder={isPayout ? accountNumber : 'Input Account number'}
              value={userAccount?.account_number}
              onChange={handleInput}
              disabled={type !== 'transfer'}
            />
          </label>
          {isNubanError && (
            <p className="text-xs text-status-404 text-left mt-1">
              Account number MUST be 10 digits.
            </p>
          )}
          <p className="font-medium text-sm text-dojahBlue mt-2 text-start">
            {isBankDetailsLoading ? (
              <span className="spinner "></span>
            ) : (
              (userBankDetails && userBankDetails?.entity?.account_name) || ''
            )}
          </p>
        </>
      )}

      <div className="flex flex-col gap-5 items-center mt-6">
        {type !== 'transfer' && (
          <p className="text-sm self-start text-left mt-1">
            Click{' '}
            <span
              onClick={() => navigate('/baas/settings')}
              className="text-brandBlue  underline cursor-pointer"
            >
              here
            </span>{' '}
            to add pay out account
          </p>
        )}
        <PrimaryButton
          buttonText="Submit"
          className="w-full rounded-lg"
          onClick={handleSubmit}
          loading={initiateWithdrawalMutation?.isLoading}
          disabled={isPayout ? !isPayoutActive : !isAccountDetailsValid}
        />
      </div>
    </div>
  );
};
