import React from 'react';
import Lottie from 'lottie-react';
import attentionLottie from './attention.json';

export default function AttentionLottie({style, className}) {
  return (
    <Lottie
      loop={true}
      animationData={attentionLottie}
      style={style}
      className={className}
    />
  );
}
