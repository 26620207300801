import React, {createContext, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {defaultFlowProcess} from '../../routes/FraudDetection/Flows/EditProcess/defaultProcess';
import {createFlowProcess, getLists} from '../../store/actions';
import {toast} from 'react-toastify';

export const CreditCheckContext = createContext(null);
function processArrayWithTypes(data, reverse) {
  return data.map((item, index, array) => {
    delete item.id;
    if (reverse) {
      if (index > 0 && 'type' in array[index - 1]) {
        return {...item, type: array[index - 1].type};
      }
    } else {
      if (index < array.length - 1 && 'type' in array[index + 1]) {
        return {...item, type: array[index + 1].type};
      }
    }

    const {type, ...rest} = item;
    return rest;
  });
}

const flowDataReset = {
  type: 'RULE',
  name: 'Rule name',
  configurations: {
    value: 'value',
    field: 'field, ',
    operator: 'operators',
    parent: 'Transactions',
    period: 0,
    score: 0,
  },
};
function CreditCheckContextProvider({children}) {
  const flowIndex = localStorage.getItem('ruleIndex');
  const [tempFlow, setTempFlow] = useState([]);
  const [rangeValue, setRangeValue] = useState([25, 76]);
  const {
    flows: {flowProcess},
    caseLists: {listLoading, lists},
  } = useSelector(state => state);

  const [ruleState, setRuleState] = useState({
    selectedField: {},
    selectedCount: {},
    selectedPeriod: {},
    selectedParent: {},
    actionName: {},
    selectedOperator: {},
    actionValue: {},
    actionScore: {},
    conditionType: {},
    currentRule: {},
  });
  const [openModals, setOpenModals] = useState({
    openDeleteAction: false,
    openEndProcessModal: false,
    openRuleEditorModal: false,
    actionRule: false,
    lastRule: false,
    steps: false,
  });

  const handleDeleteCondition = useCallback(
    index => {
      const newProcess = [...tempFlow];
      newProcess[1].forEach(element => {
        if (element.key === ruleState?.currentRule.key) {
          newProcess[1][flowIndex]?.configurations?.splice(+index, 1);
        }
      });
      setTempFlow(newProcess);
    },
    [flowIndex, ruleState?.currentRule.key, tempFlow],
  );
  const handleUpdateCondition = useCallback(
    (key, value, configIdx) => {
      const newProcess = [...tempFlow];
      const newData = {...ruleState?.currentRule};

      newProcess[1]?.forEach(element => {
        if (element.key === ruleState?.currentRule.key) {
          if (newProcess[1]?.[flowIndex]?.configurations) {
            const initialConfig =
              newProcess[1][flowIndex].configurations[configIdx];
            newProcess[1][flowIndex].configurations[configIdx] = {
              ...initialConfig,
              [key]: String(value),
            };
          }
          if (newData.configurations) {
            const initialConfig = newData.configurations[configIdx];
            newData.configurations[configIdx] = {
              ...initialConfig,
              [key]: String(value),
            };
          }
        }
      });

      setTempFlow(newProcess);
    },
    [flowIndex, ruleState?.currentRule, tempFlow],
  );

  const clearFlow = useCallback(() => {
    createFlowProcess(defaultFlowProcess);
    setTempFlow(defaultFlowProcess?.process);
    toast.success('Process cleared!');
    localStorage.removeItem('ruleIndex');
    // setTimeout(() => {
    //   window.location.reload();
    // }, 500);
  }, []);

  const resetFlow = useCallback(() => {
    const newProcess = [...tempFlow];
    newProcess[1].forEach((element, index) => {
      if (element.key === ruleState?.currentRule.key) {
        newProcess[1][index] = flowDataReset;
      }
    });

    setRuleState(prev => ({
      ...prev,
      actionName: {},
      actionValue: {},
      selectedField: {},
      selectedOperator: {},
    }));
    setTempFlow(newProcess);
    createFlowProcess({process: newProcess});

    toast.success('Rule cleared successfully');
  }, [ruleState?.currentRule.key, tempFlow]);

  useEffect(() => {
    flowProcess == null && createFlowProcess(defaultFlowProcess);
    !lists && getLists({page: 1, limit: 1000});
  }, [flowProcess, listLoading, lists]);

  useEffect(() => {
    const threshold = tempFlow[2]?.threshold;
    threshold && setRangeValue([threshold?.review, threshold?.block]);
  }, [tempFlow]);

  const contextState = {
    get: {tempFlow, ruleState, openModals, rangeValue},
    set: {setTempFlow, setRuleState, setOpenModals, setRangeValue},
    handleDeleteCondition,
    handleUpdateCondition,
    processArrayWithTypes,
    clearFlow,
    resetFlow,
  };
  return (
    <CreditCheckContext.Provider value={contextState}>
      {children}
    </CreditCheckContext.Provider>
  );
}

export default CreditCheckContextProvider;
