/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable-next-line no-unused-vars */
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {
  Checkbox,
  CustomRadio,
  Modal,
  VerificationToggle,
} from '../../../components';
import * as Actions from '../../../store/actions';
import {Constants} from '../../../helpers';
import CountryInput from '../../../components/CountryInput';
import {infoRedSm} from '../../../assets/images/images';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../../helpers/formatNumberToCurrency';
import {EasyOnboardContext} from '../../../helpers/context/EasyOnboardContext';
import OTPVerificationMode from '../../../components/OTPVerificationMode';

const CountryType = country => {
  switch (country?.value) {
    case 'nigeria':
      return 'ng';
    case 'kenya':
      return 'ke';
    case 'ghana':
      return 'gh';
    case 'zimbabwe':
      return 'zw';
    case 'angola':
      return 'ao';
    case 'southafrica':
      return 'za';
  }
};
function ToggleCountriesData({
  selectedCountry = 'nigeria',
  config,
  handleUpdateConfig,
  setPricing,
  widgetPricing,
  displayPrice,
}) {
  const DATA = country => {
    switch (country?.value) {
      case 'nigeria':
        return Constants.lookups?.nigeriaId_Onboard;
      case 'kenya':
        return Constants.lookups?.kenyaId_Onboard;
      case 'ghana':
        return Constants.lookups?.ghanaId_Onboard;
      case 'zimbabwe':
        return Constants.lookups?.zimbabweId_Onboard;
      case 'angola':
        return Constants.lookups?.angolaId_Onboard;
      case 'southafrica':
        return Constants.lookups?.southAfricaId_Onboard;
    }
  };
  return (
    <>
      <p className="text-left text-body text-xs mt-8 font-medium uppercase">
        Select Government Data to display
      </p>
      <div className="mt-2 flex flex-col justify-between">
        {DATA(selectedCountry)?.map(type => {
          const isBvnAdvancePriceKey = type?.value === 'bvnAdvance';
          const price = isBvnAdvancePriceKey
            ? widgetPricing['government-data']?.services?.ng_bvn_advance
            : widgetPricing &&
              widgetPricing['government-data']?.services[
                `${CountryType(selectedCountry)}_${type?.value}`
                  .replace('ke-', '')
                  .replace('gh-', '')
                  .replace('zw-', '')
                  .replace('ao-', '')
                  .replace('za-', '')
              ];
          return (
            <div
              key={type?.value}
              className="flex items-center gap-2 pl-4 pr-2 py-[10px] cursor-pointer"
            >
              <VerificationToggle
                isChecked={config[type?.value]}
                onCheck={value => {
                  handleUpdateConfig(type?.value, value);
                  setPricing(prev => {
                    if (config[type?.value] === false) {
                      return {
                        ...prev,
                        services: {
                          ...{...prev.services, [type?.value]: price / 100},
                        },
                      };
                    } else {
                      delete prev.services[type?.value];
                      return prev;
                    }
                  });
                }}
              />
              <div className="flex justify-between w-40">
                <p className="text-xs leading-[18px] text-body">
                  {type?.label}
                </p>
                <p className="text-xs ml-4 leading-[18px] text-grey20">
                  {displayPrice(price / 100)}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex gap-2 items-start p-2 bg-statusBg-404 rounded">
        <img src={infoRedSm} alt="" width={11} height={11} />
        <p className="text-[8px] text-start text-grey -mb-1 leading-normal">
          The BVN Basic and BVN advance data cannot be added to your
          verification flow simultaneously
        </p>
      </div>
    </>
  );
}
function GovernmentData({
  widgets: {widgetScreen, widgetPages, widgetPricing},
  auth: {userDetails, userSettings},
  setWidgetCurrentPricing,
  setWidgetScreen,
  setWidgetPages,
  ...otherProps
}) {
  const {open, setOpen} = otherProps;
  const [selectedCountry, setSelectedCountry] = useState(
    Constants.lookups.countries2[0],
  );
  const [update, setUpdate] = useState(true);
  const [config, setConfig] = useState({});
  const [pricing, setPricing] = useState({
    services: {},
    verification: {},
  });
  const {detectWithLiveness, setDetectWithLiveness} =
    useContext(EasyOnboardContext);
  const displayPrice = price =>
    userDetails && userDetails.company && userDetails.company.currency === 'USD'
      ? formatUsd(price || 0)
      : formatNumberToCurrency(
          price || 0,
          userDetails && userDetails.company && userDetails.company.currency,
          userSettings &&
            userSettings.settings &&
            userSettings.settings.dollar_exchange_rate,
        );
  const foundWidget =
    useMemo(
      () =>
        widgetPages &&
        widgetPages?.find(
          data =>
            data?.pageId === widgetScreen?.pageId &&
            data?.page === 'government-data',
        ),
      [widgetPages, widgetScreen],
    ) || null;
  const ngDefaultConfig = {
    bvn: true,
    dl: true,
    // mobile: true,
    vnin: true,
    nin: true,
    otp: true,
    whatsappOtp: false,
    bvnAdvance: false,
    selfie: false,
    version: 3,
    brightnessThreshold: 40,
    glassesCheck: true,
    flipCamera: false,
  };
  useEffect(() => {
    switch (selectedCountry.value) {
      case 'nigeria':
        setConfig(ngDefaultConfig);
        break;
      case 'ghana':
        setConfig({
          'gh-dl': true,
          'gh-voter': true,
        });
        break;
      case 'zimbabwe':
        setConfig({
          'zw-id': true,
        });
        break;
      case 'kenya':
        setConfig({
          'ke-dl': true,
          'ke-passport': true,
          'ke-kra': true,
        });
        break;
      case 'uganda':
        setConfig({
          'ug-id': true,
          'ug-telco': true,
        });
        break;
      case 'angola':
        setConfig({
          'ao-nin': true,
        });
        break;
      case 'southafrica':
        setConfig({
          'za-id': true,
        });
        break;
      default:
        setConfig(ngDefaultConfig);
        break;
    }
  }, [selectedCountry.value]);
  useEffect(() => {
    const isActive =
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.page === 'government-data' &&
          data[`${data?.page}-${data?.pageId}`],
      );
    if (isActive) {
      for (const key in config) {
        if (typeof config[key] === 'boolean') {
          setPricing(prev => {
            const price =
              !['otp', 'selfie', 'liveness'].includes(key) &&
              widgetPricing &&
              widgetPricing['government-data']?.services[
                `${CountryType(selectedCountry)}_${key}`
                  .replace('ke-', '')
                  .replace('gh-', '')
                  .replace('zw-', '')
                  .replace('ao-', '')
                  .replace('za-', '')
              ];
            if (config[key] === true) {
              return {
                services: {
                  ...{
                    ...prev.services,
                    [key]: price / 100,
                    ...(key === 'bvnAdvance' && {
                      [key]:
                        widgetPricing &&
                        widgetPricing['government-data']?.services
                          ?.ng_bvn_advance / 100,
                    }),
                    ...(key === 'emailOtp' && {
                      [key]:
                        widgetPricing &&
                        widgetPricing['government-data']?.services?.email_otp /
                          100,
                    }),
                  },
                },
                verification: {
                  ...{
                    ...prev.verification,
                    ...(key === 'otp' && {
                      [key]:
                        widgetPricing &&
                        widgetPricing['government-data']?.services?.otp / 100,
                    }),
                    ...(key === 'selfie' &&
                      config.liveness &&
                      (config.version === 2
                        ? {
                            [key]:
                              widgetPricing &&
                              widgetPricing['government-data']?.services
                                ?.liveness_video / 100,
                          }
                        : {
                            [key]:
                              widgetPricing &&
                              widgetPricing['government-data']?.services
                                ?.liveness / 100,
                          })),
                  },
                },
              };
            } else {
              delete prev.services[key];
              delete prev.verification[key];
              return prev;
            }
          });
        }
      }
      setWidgetCurrentPricing({
        'government-data': pricing,
      });
    } else {
      setWidgetCurrentPricing({
        'government-data': {},
      });
    }
    return () => {
      setWidgetCurrentPricing({'government-data': {}});
      setPricing({services: {}, verification: {}});
    };
  }, [
    widgetScreen,
    update,
    widgetPages,
    selectedCountry.value,
    setWidgetCurrentPricing,
    config,
  ]);
  const ALL_PAGES = JSON.parse(localStorage.getItem('pages'));
  function deleteTypeIfLivenessFalse(obj) {
    if (
      obj?.liveness === false ||
      !Object.hasOwn(obj, 'liveness') ||
      obj?.liveness === undefined
    ) {
      const {
        type,
        version,
        liveness,
        brightnessThreshold,
        glassesCheck,
        ...updatedObj
      } = obj;
      return {...updatedObj, selfie: false, flipCamera: false};
    }
    return {
      ...obj,
      selfie: true,
      type: obj.type || 'basic',
      version: obj.version || 3,
      flipCamera: obj?.flipCamera,
    };
  }
  const updateConfig = newConfig => {
    const newPages = ALL_PAGES?.map(page => {
      if (page?.page === foundWidget?.page) {
        return {
          ...page,
          config: newConfig,
        };
      }
      return page;
    });
    localStorage.setItem('pages', JSON.stringify(newPages));
  };
  useEffect(() => {
    if (foundWidget) {
      if (Object.values(foundWidget?.config || {}).length === 0) {
        setConfig({...deleteTypeIfLivenessFalse(config)});
        setUpdate(false);
        setWidgetPages({
          ...foundWidget,
          config: {...deleteTypeIfLivenessFalse(config)},
        });
        updateConfig({...deleteTypeIfLivenessFalse(config)});
      } else {
        const data = foundWidget.config.type
          ? {
              ...config,
              ...foundWidget?.config,
              liveness: foundWidget?.config?.liveness || true,
              type: foundWidget?.config?.type,
            }
          : {
              ...config,
              ...foundWidget?.config,
              liveness: foundWidget?.config?.liveness,
            };
        setConfig({
          ...deleteTypeIfLivenessFalse(data),
          ...(data?.bvnAdvance ? {bvn: false} : {emailOtp: false}),
        });
        const {brightnessThreshold, glassesCheck} = data;
        setDetectWithLiveness({
          brightnessThreshold:
            (brightnessThreshold !== undefined && brightnessThreshold) ||
            detectWithLiveness?.brightnessThreshold,
          glassesCheck:
            (glassesCheck !== undefined && glassesCheck) ||
            detectWithLiveness?.glassesCheck,
        });
        setWidgetPages({
          ...foundWidget,
          config: {
            ...deleteTypeIfLivenessFalse(data),
            ...(data?.bvnAdvance ? {bvn: true} : {emailOtp: false}),
          },
        });
        updateConfig({
          ...deleteTypeIfLivenessFalse(data),
          ...(data?.bvnAdvance ? {bvn: true} : {emailOtp: false}),
        });
      }
    }
  }, [widgetScreen, update]);
  const handleUpdateConfig = (key, value) => {
    const newData =
      widgetPages &&
      widgetPages?.find(data => data?.pageId === widgetScreen?.pageId);
    if (!newData) {
      return toast.error(
        'Ops! Please toggle ON before configuring this widget',
      );
    } else {
      const updatedConfig = deleteTypeIfLivenessFalse({
        ...config,
        ...widgetScreen?.config,
        ...(value === 'advanced' ? {version: 2} : {version: 3}),
        ...(key === 'bvnAdvance' &&
          value && {bvnAdvance: value, bvn: !value, emailOtp: value}),
        ...(key === 'bvn' &&
          value && {bvn: value, bvnAdvance: !value, emailOtp: !value}),
        [key]: value,
      });
      setConfig(updatedConfig);
      setWidgetScreen({
        ...widgetScreen,
        config: {
          ...updatedConfig,
          ...(updatedConfig.bvnAdvance && {bvn: true}),
        },
      });
      setWidgetPages({
        ...newData,
        config: {
          ...updatedConfig,
          ...(updatedConfig.bvnAdvance && {bvn: true}),
        },
      });
    }
  };
  return (
    <Modal
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[339px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Configuration"
    >
      <div className="mt-8">
        <CountryInput
          defaultValue={selectedCountry}
          value={selectedCountry}
          options={Constants.lookups.countries2}
          onChange={country => setSelectedCountry(country)}
          label="Select country"
        />
        <ToggleCountriesData
          selectedCountry={selectedCountry}
          config={config}
          handleUpdateConfig={handleUpdateConfig}
          setPricing={setPricing}
          widgetPricing={widgetPricing}
          userDetails={userDetails}
          userSettings={userSettings}
          displayPrice={displayPrice}
        />
        <div className="mt-6">
          <p className="text-left text-body text-xs mb-2 font-medium uppercase">
            Mode of verification
          </p>
          <div className="bg-white80 rounded-lg p-2">
            <Checkbox
              label="OTP Verification"
              className="mb-2 !pl-0"
              labelStyle="text-sm text-body leading-5"
              align="items-start"
              isChecked={config?.otp}
              onChange={e => {
                handleUpdateConfig('otp', e.target.checked);
                setPricing(prev => {
                  if (e.target.checked) {
                    return {
                      ...prev,
                      verification: {
                        ...prev.verification,
                        ...{
                          otp:
                            widgetPricing['government-data']?.services?.otp /
                            100,
                        },
                      },
                    };
                  } else {
                    delete prev.verification?.otp;
                    return {...prev};
                  }
                });
                setWidgetCurrentPricing({
                  'government-data': pricing,
                });
              }}
            />
            {config?.bvnAdvance && (
              <Checkbox
                isChecked={config?.emailOtp}
                label="Email OTP"
                onChange={e => {
                  handleUpdateConfig('emailOtp', e.target.checked);
                  setPricing(prev => {
                    if (e.target.checked) {
                      return {
                        ...prev,
                        services: {
                          ...prev.services,
                          ...{
                            email_otp:
                              widgetPricing['government-data']?.services
                                ?.email_otp / 100,
                          },
                        },
                      };
                    } else {
                      delete prev.services?.email_otp;
                      return {...prev};
                    }
                  });
                  setWidgetCurrentPricing({
                    'government-data': pricing,
                  });
                }}
                className="py-[10px]"
                labelStyle="text-sm text-body leading-5"
                align="items-start"
              />
            )}

            <div className="relative">
              <OTPVerificationMode
                handleUpdateConfig={handleUpdateConfig}
                config={config}
                isConfigurable={!foundWidget}
                whatsapp={'whatsappOtp'}
                action={
                  <div className="flex gap-2 items-start p-2 mb-2 bg-statusBg-404 rounded">
                    <img src={infoRedSm} alt="" width={11} height={11} />
                    <p className="text-[8px] text-start text-grey -mb-1 leading-normal">
                      Driver’s License{' '}
                      {selectedCountry.value === 'kenya' && 'and Passport'}{' '}
                      cannot be used with OTP Verification mode
                    </p>
                  </div>
                }
                pricing={{
                  sms: displayPrice(
                    widgetPricing &&
                      widgetPricing['government-data']?.services?.otp / 100,
                  ),
                  whatsapp: displayPrice(
                    widgetPricing &&
                      widgetPricing['government-data']?.services?.whatsappOtp /
                        100,
                  ),
                }}
              />
            </div>
          </div>

          <Checkbox
            label="Liveness Verification"
            otherText=" "
            className="py-[10px]"
            labelStyle="text-sm text-body leading-5"
            align="items-start"
            isChecked={config?.liveness}
            onChange={e => {
              handleUpdateConfig('liveness', e.target.checked);
              setPricing(prev => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    verification: {
                      ...prev.verification,
                      ...{
                        selfie:
                          widgetPricing &&
                          widgetPricing['government-data']?.services?.liveness /
                            100,
                      },
                    },
                  };
                } else {
                  delete prev.verification?.selfie;
                  return {...prev};
                }
              });
              setWidgetCurrentPricing({
                'government-data': pricing,
              });
            }}
          />
        </div>
        {config?.liveness && (
          <>
            <div className="mt-6">
              <p className="text-left text-body text-xs mb-2 font-medium uppercase">
                Liveliness Check
              </p>
              <CustomRadio
                options={[
                  {
                    label: 'Selfie Verification',
                    value: 'basic',
                    price: displayPrice(
                      widgetPricing &&
                        widgetPricing['government-data']?.services?.liveness /
                          100,
                    ),
                  },
                ]}
                onChange={type => {
                  if (config.liveness) {
                    handleUpdateConfig('type', type);
                    setPricing(prev => {
                      if (config.liveness === true) {
                        return {
                          ...prev,
                          verification: {
                            ...prev.verification,
                            ...{
                              selfie:
                                widgetPricing &&
                                widgetPricing['government-data']?.services
                                  ?.liveness / 100,
                            },
                          },
                        };
                      } else {
                        delete prev.verification?.liveness;
                        return {...prev};
                      }
                    });
                    setWidgetCurrentPricing({
                      'government-data': pricing,
                    });
                  }
                }}
                value={config?.type}
              />
            </div>
            <div>
              <p className="text-left text-body text-xs mt-8 mb-2 font-medium uppercase">
                CAMERA FLIP OPTION
              </p>
              <div className="flex items-center gap-2 pl-4 pr-2 py-[10px] cursor-pointer">
                <VerificationToggle
                  isChecked={config?.flipCamera}
                  onCheck={value => handleUpdateConfig('flipCamera', value)}
                />
                <div className="flex justify-between w-48">
                  <p className="text-xs leading-[18px] text-body">
                    Flip to back Camera
                  </p>
                </div>
              </div>
              <p className="text-left text-grey40 text-xs mt-2 mb-2 leading-5 ">
                Toggle on to add camera icon to the widget and easily flip to
                back camera
              </p>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
export default connect(state => state, Actions)(GovernmentData);
