import {APP_FILTERS, LOCATION} from '../types';

export const setAppFilters = data => dispatch => {
  dispatch({payload: null, type: APP_FILTERS});
  const pages = ['lookup', 'apiLogs', 'verifications', 'billings', 'onboard'];
  const currentPage = pages.find(page => data.page === page);

  if (currentPage === data.page) {
    const filterData = {
      [currentPage]: {
        ...data.filters,
      },
    };
    dispatch({payload: filterData, type: APP_FILTERS});
  }
};

export const getLocation = location => dispatch => {
  dispatch({payload: location, type: LOCATION});
};
