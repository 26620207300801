import React, {useCallback, useState} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {useDragControls} from 'framer-motion';
import {ReorderIcon} from '../routes/EasyOnboard/ReorderIcon';
import {VerificationToggle} from './Toggle';

function OTPVerificationMode({
  handleUpdateConfig,
  action,
  isConfigurable,
  config,
  whatsapp,
  pricing,
}) {
  const [items, setItems] = useState([
    {id: 'otp', label: 'SMS', enabled: true, price: pricing?.sms},
    {id: whatsapp, label: 'WhatsApp', enabled: false, price: pricing?.whatsapp},
  ]);

  const dragControls = useDragControls();

  const handleDragDropPages = result => {
    if (!result.destination) return;
    const NUMBER_OF_ITEM_TO_REMOVE = 1;
    const NEW_ITEM_POSITION = 0;
    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(
      result.source.index,
      NUMBER_OF_ITEM_TO_REMOVE,
    );
    newItems.splice(result.destination.index, NEW_ITEM_POSITION, reorderedItem);
    setItems(newItems);
  };

  const handleCheck = useCallback(
    (value, index) => {
      const updatedItems = [...items];
      handleUpdateConfig(updatedItems[index]?.id, value);
      if (isConfigurable) return;
      updatedItems[index].enabled = value;
      setItems(updatedItems);
    },
    [handleUpdateConfig, isConfigurable, items],
  );

  return (
    <div className="mt-2 relative ">
      <p className="text-xs text-left text-body my-3 leading-4">
        Choose your preferred mode of delivery. If you choose multiple options,
        you can specify the order in which you want your users to receive them.
        This way, your users will never miss an OTP again!
      </p>

      {action ?? null}

      <DragDropContext onDragEnd={handleDragDropPages}>
        <Droppable droppableId="options" type="otp-mode">
          {provided => (
            <ul
              className="flex flex-col gap-2 options"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items?.map((item, index) => (
                <Draggable
                  key={item.id + index}
                  draggableId={item.id + index}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        left: 'auto !important',
                        top: 'auto !important',
                        ...(index === 1 &&
                          snapshot.isDragging && {marginTop: '48px'}),
                      }}
                    >
                      <div
                        tabIndex={0}
                        className="px-4 h-10 flex items-center justify-between bg-white rounded cursor-pointer"
                      >
                        <div className="flex items-center gap-2 w-28">
                          <VerificationToggle
                            isChecked={config[item.id]}
                            onCheck={value => handleCheck(value, index)}
                          />
                          <p className="text-xs leading-[12px] text-body font-medium">
                            {item.label}
                          </p>
                        </div>

                        <div className="flex items-end gap-2">
                          <p className="text-xs uppercase italic text-grey40">
                            {item?.price}
                          </p>
                          <ReorderIcon dragControls={dragControls} />
                        </div>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default OTPVerificationMode;
