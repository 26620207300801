import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import {css} from '@emotion/react';

const Loader = ({height}) => {
  const override = css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #0b061a;
    margin-top: 20px;
    margin-bottom: 20px;
    height: ${height ? `${height}` : 75}vh;
  `;
  return <BeatLoader css={override} size={12} color="#0b061a" />;
};

export default Loader;
