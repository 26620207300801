import {useMemo} from 'react';
import {useAllApps} from '../../../../hooks/useApps';
import {useSelector} from 'react-redux';
import {useQuery} from 'react-query';
import {fetchBAASWalletSettlement} from '../../../../requests/queries/baas';

export const useBaasSettlement = () => {
  const {userApps, isLoading: appsLoading} = useAllApps();
  const {userDetails} = useSelector(state => state?.auth);

  const appIds = useMemo(
    () => (!appsLoading && userApps.length > 0 ? userApps[0]?.value : ''),
    [appsLoading, userApps],
  );

  const settlementParams = useMemo(
    () => ({
      company_id: userDetails && userDetails?.company?.id,
      ...(appIds && {app_id: appIds}),
    }),
    [appIds, userDetails],
  );

  const {
    data: settlement,
    isLoading: isSettlementLoading,
    isFetching,
  } = useQuery(
    [
      'baas-settlement',
      {params: {...settlementParams, ...(appIds && {app_id: appIds})}},
    ],
    () =>
      fetchBAASWalletSettlement({
        params: {...settlementParams, ...(appIds && {app_id: appIds})},
      }),
    {
      meta: {preventGlobalError: true},
      enabled: appIds?.length > 0,
    },
  );

  const user_account = useMemo(
    () => !isSettlementLoading && settlement && settlement?.entity,
    [isSettlementLoading, settlement],
  );
  return {user_account, isSettlementLoading, isFetching};
};
