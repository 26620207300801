import React from 'react';
import {useParams} from 'react-router-dom';
import Layout from '../Layout';
import {Accordion, EmptyState} from '../../../components';
import {
  corruptedFile,
  nigeriaIc,
  southAfricaIc,
} from '../../../assets/images/images';
import {useQuery} from 'react-query';
import {fetchBusinessEntity} from '../../../requests/queries/business360';
import {getInitials, IGNORE_KEYS} from '../LayoutConstants';
import moment from 'moment';
import {getIDType} from '../../Customer360/LayoutConstants';

const getCountryLogo = country =>
  ({
    nigeria: nigeriaIc,
    'south-africa': southAfricaIc,
  }[country || 'nigeria']);

export default function BusinessLookup() {
  const isEmpty = false;
  const {slug} = useParams();

  const {
    data: businessEntities,
    isLoading: entityLoading,
    isFetching,
  } = useQuery('business-entities', () => fetchBusinessEntity(slug));

  const GOVERNMENT_DATA =
    !entityLoading && businessEntities && businessEntities.entity;

  const getCountryName = data => {
    if (data) {
      const firstUnderscoreIndex = Object.keys(data)[0]?.indexOf('_');
      if (firstUnderscoreIndex !== -1) {
        return Object.keys(data)[0].substring(0, firstUnderscoreIndex);
      }
    }
    return 'Country';
  };

  const countryName = getCountryName(GOVERNMENT_DATA);

  return (
    <Layout>
      {isEmpty ? (
        <div className="bg-white80 w-full">
          <EmptyState
            body="There is no information to display here at the moment"
            src={corruptedFile}
            height={45}
            imageHeight={48}
            imageWidth={48}
            bodyMaxWidth="max-w-[179px]"
            buttonText="Validate document"
          />
        </div>
      ) : (
        <div className="space-y-4">
          {GOVERNMENT_DATA &&
            Object.entries(GOVERNMENT_DATA || {}).map(([key, value], index) => {
              const fieldName = getIDType(key);

              return (
                <Accordion
                  key={index}
                  title={
                    entityLoading || isFetching
                      ? '...'
                      : fieldName.toUpperCase()
                  }
                  border
                  customClasses="rounded-xl"
                  customComponent={
                    <div className="flex items-center gap-2 rounded-full px-2 py-1 outline outline-1 outline-grey-50 bg-white80">
                      <p className="text-deepBlue text-xs font-medium uppercase -mb-1">
                        {countryName}
                      </p>
                      <div className="w-5 h-5 flex items-center justify-center bg-blue-50 outline outline-1 outline-blue-100 rounded-full">
                        <img
                          src={getCountryLogo(countryName.toLowerCase())}
                          alt=""
                          width={12}
                          height={8}
                        />
                      </div>
                    </div>
                  }
                  customChildrenClasses="mt-2 border-t border-grey-50"
                  customPadding="px-6 py-5"
                  noChildPadding
                  customTitleSize="text-lg"
                  customTitleColor="text-grey"
                >
                  <div className="w-full inline-grid grid-cols-[auto_auto_auto_auto] items-center gap-10 justify-between pl-8 pr-10 pt-6 pb-14">
                    <div className="rounded-full w-[89px] h-[89px] flex items-center justify-center text-xxl font-medium text-blue bg-brandBlue40">
                      <span className="-mb-2">
                        {getInitials(
                          value?.Name_of_Company || value?.company_name || '',
                          2,
                        )}
                      </span>
                    </div>

                    {Object.entries(value || {}).map(
                      ([key, value], index) =>
                        !IGNORE_KEYS.includes(key) &&
                        value && (
                          <div key={index} className="flex flex-col gap-2">
                            {!['image', 'photo'].includes(key) && (
                              <p className="text-grey-700 text-xs font-medium uppercase">
                                {key.replace(/_/g, ' ')}
                              </p>
                            )}

                            <p className="text-grey text-sm font-medium">
                              {[
                                'date_of_registration',
                                'last_updated_date',
                              ].includes(key.toLowerCase())
                                ? moment(value).format('DD MMM YYYY h:m') || '-'
                                : value.replace(/_/g, ' ') || '-'}
                            </p>
                          </div>
                        ),
                    )}
                  </div>
                </Accordion>
              );
            })}
        </div>

        // <Accordion
        //   title="CAC Information"
        //   border
        //   customClasses="rounded-xl"
        //   customComponent={
        //     <div className="flex items-center gap-2 rounded-full px-2 py-1 outline outline-1 outline-grey-50 bg-white80">
        //       <p className="text-deepBlue text-xs font-medium uppercase -mb-1">
        //         Nigeria
        //       </p>
        //       <div className="w-5 h-5 flex items-center justify-center bg-blue-50 outline outline-1 outline-blue-100 rounded-full">
        //         <img src={nigeriaIc} alt="" width={12} height={8} />
        //       </div>
        //     </div>
        //   }
        //   customChildrenClasses="mt-2 border-t border-grey-50"
        //   customPadding="px-6 py-5"
        //   noChildPadding
        //   customTitleSize="text-lg"
        //   customTitleColor="text-grey"
        // >
        //   <div className="w-full inline-grid grid-cols-[auto_auto_auto_auto] items-center gap-10 justify-between pl-8 pr-10 pt-6 pb-14">
        //     <div className="rounded-full w-[89px] h-[89px] flex items-center justify-center text-xxl font-medium text-blue bg-brandBlue40">
        //       <span className="-mb-2">
        //         {getInitials(companyData?.company_name || '', 2)}
        //       </span>
        //     </div>
        //     {Object.entries(companyData || {}).map(
        //       ([key, value], index) =>
        //         !['business', 'app_id'].includes(key) && (
        //           <div key={index} className="flex flex-col gap-2">
        //             <p className="text-grey-700 text-xs font-medium uppercase">
        //               {key.replaceAll('_', ' ')}
        //             </p>
        //             <p className="text-grey text-sm font-medium">
        //               {['date_of_registration'].includes(key)
        //                 ? moment(value).format('DD MMM YYYY h:m') || '-'
        //                 : value || '-'}
        //             </p>
        //           </div>
        //         ),
        //     )}
        //   </div>
        // </Accordion>
      )}
    </Layout>
  );
}
