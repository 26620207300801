import React, {useContext, useRef, useState} from 'react';
// import {PDFDownloadLink} from '@react-pdf/renderer'; // Import PDFViewer

import {useQuery} from 'react-query';
import {toast} from 'react-toastify';
import uppercase from 'lodash/upperCase';
import {Loader, Modal, PrimaryButton} from '../../components';
import {
  back,
  closeModal,
  downloadGrey,
  enlargeIc,
  sandbox_dummy,
} from '../../assets/images/images';
import {LogoContext} from '../../helpers/context';
import {getStatusColor} from '../../helpers';
import {fetchAddressVerification} from '../../requests/queries/address-verification';
import {useAllApps} from '../../hooks/useApps';
// import AddressVerificationPdf from './AddressPDF';

export default function ViewVerificationModal({open, setOpen, selectedData}) {
  const [enlargedImageIndex, setEnlargedImageIndex] = useState(null);
  const ref = useRef();
  const logo = useContext(LogoContext);

  function handleEnlargeImage(index) {
    if (enlargedImageIndex === index) {
      setEnlargedImageIndex(null);
    } else {
      setEnlargedImageIndex(index);
    }
  }

  const {userApps} = useAllApps();

  const {data: addressDetails, isLoading: isLoadingAddressDetails} = useQuery(
    [
      'address-verification',
      {id: selectedData?.id, app_id: userApps?.[0]?.value},
    ],
    () =>
      fetchAddressVerification(selectedData?.id, {
        app_id: userApps?.[0]?.value,
      }),
    {
      onError: error => {
        toast.error(error.response.data.error);
      },
      enabled: !!selectedData?.id && !!userApps?.[0]?.value && open,
    },
  );

  const addressInfo = addressDetails?.entity?.data;

  const addressLongLat = addressInfo?.location;
  const latitude = addressLongLat?.split(',')[0];
  const longitude = addressLongLat?.split(',')[1];
  const googleMapsLink = `https://www.google.com/maps?q=${latitude},${longitude}`;
  const addressPhotos =
    addressInfo?.photos.filter(Boolean)?.length > 0 && addressInfo?.photos;

  const KEYS = {
    fullName: `${addressInfo?.applicant?.first_name ?? ''} ${
      addressInfo?.applicant?.middle_name ?? ''
    } ${addressInfo?.applicant?.last_name ?? ''}`,
    gender: addressInfo?.applicant?.gender,
    dateOfBirth: addressInfo?.applicant?.dob,
    phoneNumber: addressInfo?.applicant?.phone,
    street: addressInfo?.street,
    city: addressInfo?.city,
    state: addressInfo?.state,
    lga: addressInfo?.lga,
    country: addressInfo?.country,
    linkToMap: addressLongLat && googleMapsLink,
    refId: addressDetails?.entity?.reference_id,
    multiplePhotosOfAddress: addressPhotos,
    neighbourName: addressInfo?.neighbor?.name || addressInfo?.neighbour?.name,
    neighbourComment:
      addressInfo?.neighbor?.comment || addressInfo?.neighbour?.comment,
    neighbourPhoneNumber:
      addressInfo?.neighbor?.phone || addressInfo?.neighbour?.phone,
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[581px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      fullScreenModal
    >
      {isLoadingAddressDetails ? (
        <Loader />
      ) : (
        <>
          <div className="p-4 sm:p-10 sm:pb-6">
            <div className="hidden print:flex print:items-center print:justify-between">
              <img src={logo} alt="" style={{maxHeight: '40px'}} />
              <h3 className="text-grey text-base font-medium">
                Address verification
              </h3>
            </div>
            <button
              onClick={() => {
                setOpen(false);
              }}
              className="flex items-center gap-2 sm:hidden bg-white80 p-2 pl-0 print:hidden"
            >
              <img src={back} alt="" />
              <span className="text-small text-grey80 font-medium">
                Back to Address Verification
              </span>
            </button>
            <header className="mt-6 sm:mt-0 flex items-center justify-center sm:justify-between print:hidden">
              <div className="flex items-center gap-2 flex-col sm:flex-row">
                <h3 className="text-grey text-base font-medium">
                  Address verification
                </h3>
                <div className="flex items-center gap-2">
                  <div className="uppercase py-1 px-2 text-xs text-success font-medium bg-success20 rounded-full w-fit">
                    individual
                  </div>
                  <div
                    className={`uppercase py-1 px-2 text-xs font-medium  rounded-full w-fit ${getStatusColor(
                      selectedData?.status,
                    )}`}
                  >
                    {selectedData?.status}
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  setOpen(false);
                }}
                className="hidden sm:block"
              >
                <div>
                  <img src={closeModal} alt="" />
                </div>
              </button>
            </header>
            <div ref={ref}>
              <div className="mt-8 flex flex-col items-center">
                {addressInfo?.applicant?.photo ? (
                  <img
                    src={sandbox_dummy}
                    alt="Photo"
                    width={80}
                    height={80}
                    className="text-small"
                  />
                ) : (
                  <div className="w-20 h-20 bg-batchBlue20 text-[#3F7CDB] rounded-full flex items-center justify-center text-lg font-medium">
                    {addressInfo?.applicant?.first_name?.charAt(0)}
                    {'.'}
                    {addressInfo?.applicant?.last_name?.charAt(0)}
                  </div>
                )}

                <p className="mt-4 text-sm text-grey font-medium">
                  {selectedData?.first_name} {selectedData?.last_name}
                </p>
              </div>

              <div className="mt-8 px-4 py-4 sm:p-6 bg-white80 -mx-4 sm:mx-0 rounded">
                {KEYS &&
                  Object.keys(KEYS)?.map(key => (
                    <div key={key}>
                      {key &&
                      (key?.toLowerCase() === 'caller_name' ||
                        key?.toLowerCase() === 'caller_email' ||
                        key?.toLowerCase() === 'date_created') ? null : (
                        // eslint-disable-next-line react/jsx-indent
                        <>
                          {key && KEYS[key] ? (
                            <div
                              className={`py-4 px-4 sm:px-6 flex justify-between border-b border-grey60 ${
                                key === 'multiplePhotosOfAddress'
                                  ? 'flex-col items-start'
                                  : 'flex-row items-center'
                              }`}
                            >
                              {key === 'multiplePhotosOfAddress' ? (
                                <p className="text-xs text-grey font-medium uppercase text-left">
                                  Photos of Address
                                </p>
                              ) : (
                                <p className="text-xs text-grey font-medium uppercase text-left">
                                  {uppercase(key)}
                                </p>
                              )}

                              {key === 'linkToMap' ? (
                                addressLongLat ? (
                                  <a
                                    href={googleMapsLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-small text-brandBlue w-100 text-right underline"
                                  >
                                    Link to address
                                  </a>
                                ) : (
                                  <p className="text-small text-body w-100 text-right">
                                    No link to address
                                  </p>
                                )
                              ) : key === 'multiplePhotosOfAddress' ? (
                                <div
                                  className={`mt-9 flex items-center gap-4 flex-wrap relative ${
                                    enlargedImageIndex !== null
                                      ? 'flex-col'
                                      : 'flex-row'
                                  } ${
                                    KEYS[key] &&
                                    Array.isArray(KEYS[key]) &&
                                    KEYS[key].filter(
                                      photoUrl => photoUrl !== null,
                                    ).length === 2
                                      ? 'justify-start'
                                      : 'justify-center'
                                  }    ${
                                    KEYS[key] &&
                                    Array.isArray(KEYS[key]) &&
                                    KEYS[key].filter(
                                      photoUrl => photoUrl !== null,
                                    ).length === 1
                                      ? 'w-full mx-0'
                                      : '-mx-[26px]'
                                  }`}
                                >
                                  {Array.isArray(KEYS[key]) &&
                                    KEYS[key].map((photoUrl, index) =>
                                      !photoUrl ? null : (
                                        <div
                                          key={index}
                                          className={`relative flex items-center justify-center ${
                                            !photoUrl ? 'hidden' : ''
                                          }`}
                                        >
                                          <img
                                            src={photoUrl}
                                            alt={`Image ${index + 1}`}
                                            width={
                                              enlargedImageIndex === index
                                                ? 436
                                                : 218
                                            }
                                            height={
                                              enlargedImageIndex === index
                                                ? 508
                                                : 254
                                            }
                                          />
                                          <div className="absolute bottom-2 flex gap-2">
                                            <button
                                              onClick={() =>
                                                handleEnlargeImage(index)
                                              }
                                              className="flex items-start gap-2 px-2 py-2 text-xs font-medium rounded text-grey bg-white80 w-fit"
                                            >
                                              <span className="-mb-[2px]">
                                                {enlargedImageIndex === index
                                                  ? 'Shrink'
                                                  : 'Enlarge'}{' '}
                                                image
                                              </span>
                                              <img
                                                src={enlargeIc}
                                                alt=""
                                                className="-mt-[2px]"
                                              />
                                            </button>
                                            <a
                                              href={photoUrl}
                                              download="file"
                                              rel="noreferrer"
                                              className="flex items-start gap-2 px-2 py-2 text-xs font-medium rounded text-grey bg-white80 w-fit"
                                            >
                                              <span>Download</span>
                                              <img
                                                src={downloadGrey}
                                                alt=""
                                                className="-mt-[2px]"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      ),
                                    )}
                                </div>
                              ) : (
                                <p className="text-small text-body w-100 text-right">
                                  {(KEYS && KEYS[key]) || '-'}
                                </p>
                              )}
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {addressInfo?.pdf_report && (
            <footer
              className="rounded-b-xl py-2 sm:py-6 px-4 sm:px-0 bg-white80 w-full sticky bottom-0 print:hidden"
              style={{boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)'}}
            >
              <a
                href={addressInfo?.pdf_report}
                target="_blank"
                rel="noreferrer"
              >
                <PrimaryButton
                  buttonText="Download as PDF"
                  className="w-full sm:max-w-sm"
                  disabled={!addressInfo?.pdf_report}
                />
              </a>
            </footer>
          )}
        </>
      )}
    </Modal>
  );
}
