/* eslint-disable react-hooks/exhaustive-deps */

import React, {useContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Accordion, Checkbox} from '../../components';
import * as Actions from '../../store/actions';
import {EasyOnboardContext} from '../../helpers/context/EasyOnboardContext';

function ConfirmationPage({
  isChecked,
  onCheck,
  setUrl,
  widgets: {singleWidget},
}) {
  const [checked, setChecked] = useState(true);
  const {widgetData, setWidgetData} = useContext(EasyOnboardContext);

  useEffect(() => {
    setChecked(!singleWidget?.widget?.redirect_url);
    setWidgetData({
      ...widgetData,
      redirect_url: singleWidget?.widget?.redirect_url,
      name: singleWidget?.widget?.name,
    });
  }, [setWidgetData, singleWidget?.widget, singleWidget?.widget?.redirect_url]);

  const handleChange = e => {
    setChecked(e.target.checked);
    setWidgetData({...widgetData, redirect_url: e.target.checked && null});
    onCheck && onCheck(e.target.checked);
  };
  const checkedValue = isChecked ? isChecked : checked;

  return (
    <Accordion title="Confirmation page">
      <p className="text-xs text-body leading-[18px]">
        Confirm verification process
      </p>

      <div className="mt-4">
        <Checkbox
          onChange={handleChange}
          noLeftPadding
          label="Use default confirmation page"
          isChecked={checked}
        />

        <label
          data-smallerlabel=""
          htmlFor="confidence_level"
          className="mt-4 mb-2"
        >
          Redirect to
          <input
            disabled={checkedValue}
            style={{cursor: checkedValue && 'not-allowed'}}
            onChange={setUrl}
            type="text"
            id="confidence_level"
            className="mt-2 text-sm"
            placeholder={widgetData?.redirect_url || 'https://dojah.io'}
          />
        </label>
      </div>
    </Accordion>
  );
}

export default connect(state => state, Actions)(ConfirmationPage);
