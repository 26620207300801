/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import {useDragControls} from 'framer-motion';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {settings, trash} from '../../assets/images/images';
import {ReorderIcon} from './ReorderIcon';
import {Accordion, SecondaryButton, VerificationToggle} from '../../components';
import NewPageModal from './NewPageModal';
import Email from './SettingsModals/Email';
import Address from './SettingsModals/Address';
import GovernmentData from './SettingsModals/GovernmentData';
import PhoneNumber from './SettingsModals/PhoneNumber';
import Selfie from './SettingsModals/Selfie';
import GovernmentId from './SettingsModals/GovernmentId';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import {getPages} from '../../helpers/getPages';
import {toast} from 'react-toastify';
import BusinessData from './SettingsModals/BusinessData';
import UploadOtherIds from './SettingsModals/UploadOtherIds';
import {EasyOnboardContext} from '../../helpers/context/EasyOnboardContext';
import SignaturePage from './SettingsModals/SignaturePage';
import BusinessID from './SettingsModals/BusinessID';
import ConfigDisclaimerModal from './SettingsModals/ConfigDisclaimerModal';
import QuestionsModal from './SettingsModals/QuestionsModal';

function Page({
  title,
  id,
  openSettings,
  currentWidget,
  widgetScreen,
  activateWidget,
  isChecked,
  deleteWidget,
}) {
  const dragControls = useDragControls();
  const isIndemnity = title === 'indemnity';

  return (
    <div
      className={`bg-white80 pl-4 pr-2 py-[10px] flex justify-between rounded cursor-pointer hover:outline hover:outline-1 ${
        (id === widgetScreen?.pageId || isIndemnity) &&
        'outline outline-1 outline-brandBlue'
      }`}
      tabIndex={0}
      onClick={currentWidget}
    >
      <div className="flex items-center gap-2">
        <VerificationToggle
          isChecked={isChecked}
          onCheck={isIndemnity ? () => {} : activateWidget}
        />
        <span className="text-xs leading-[18px] text-brandBlue50">
          {getPages(title)}
        </span>
      </div>
      <div className="flex items-center gap-2">
        {openSettings && (
          <button onClick={openSettings}>
            <img src={settings} alt="" width={16} height={16} />
          </button>
        )}
        {!isIndemnity && deleteWidget && (
          <button onClick={deleteWidget}>
            <img src={trash} alt="" width={16} height={16} />
          </button>
        )}
        {!isIndemnity && <ReorderIcon dragControls={dragControls} />}
      </div>
    </div>
  );
}

function VerificationPages({
  widgets: {widgetScreen, screenType, widgetPages},
  setWidgetScreen,
  mutateWidgetPages,
  setWidgetDefaultPages,
  setWidgetPages,
  setScreenType,
  setPage,
}) {
  const [newPageModal, setNewPageModal] = useState(false);
  const [, setDefaultWidgetsPages] = useState([]);
  const initialRender = useRef(true);
  const [pageIndex, setPageIndex] = useState(0);
  const {
    items,
    settings: {
      question,
      governmentData,
      businessData,
      phoneNumber,
      selfie,
      email,
      address,
      governmentId,
      uploadOtherIds,
      signatureModal,
      businessID,
      configDisclaimerModal,
    },
    setItems,
    setQuestion,
    setGovernmentData,
    setBusinessData,
    setPhoneNumber,
    setSelfie,
    setEmail,
    setAddress,
    setGovernmentId,
    setUploadOtherIds,
    setSignatureModal,
    setBusinessID,
    setTrackChanges,
    setConfigDisclaimerModal,
  } = useContext(EasyOnboardContext);
  const openNewPageModal = useCallback(() => {
    setNewPageModal(prev => !prev);
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      const indemnityPage = {
        page: 'indemnity',
        config: {},
        'indemnity-0': true,
      };
      setWidgetScreen(indemnityPage);
      setWidgetPages(indemnityPage);
      initialRender.current = false;
    }
  }, [setWidgetScreen]);

  useEffect(() => {
    setDefaultWidgetsPages([...items]);
    setScreenType('verifications');
  }, [items?.length]);

  const handleActivate = useCallback(
    (value, page) => {
      if (page.page !== 'indemnity') {
        setWidgetScreen({...page, [`${page?.page}-${page?.pageId}`]: value});
        setPage(page?.pageId);

        setWidgetPages({...page, [`${page?.page}-${page?.pageId}`]: value});

        const newData =
          items?.length &&
          items?.map(data => {
            if (page?.pageId === data?.pageId) {
              return {
                ...data,
                [`${data?.page}-${data?.pageId}`]: value,
              };
            } else {
              return data;
            }
          });

        setWidgetDefaultPages(newData);
        value && setTrackChanges(true);
      }
    },
    [
      setWidgetScreen,
      setTrackChanges,
      setPage,
      items,
      setWidgetDefaultPages,
      setWidgetPages,
    ],
  );

  const handleSet = useCallback(
    page => {
      if (widgetScreen?.pageId !== page?.pageId) {
        setWidgetScreen({
          ...page,
          ...(widgetPages?.find(widget => widget?.pageId === page?.pageId) ||
            {}),
        });
        setPage(page?.pageId);
        if (page?.page === 'indemnity') {
          setWidgetPages(page);
        }
      }
    },
    [widgetScreen, widgetPages, setWidgetScreen, setPage],
  );

  const handleDeleteWidget = useCallback(
    page => {
      const checkPage =
        widgetPages &&
        widgetPages?.find(widget => widget?.pageId === page?.pageId);
      if (checkPage === undefined || checkPage === null) {
        mutateWidgetPages({page, type: 'delete'});
        setTrackChanges(true);
      } else {
        toast.error('Ops! Please toggle page OFF before deleting');
      }
    },
    [widgetPages, mutateWidgetPages],
  );

  const handleDragDropPages = result => {
    if (!result.destination) return;
    const NUMBER_OF_ITEM_TO_REMOVE = 1;
    const NEW_ITEM_POSITION = 0;
    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(
      result.source.index,
      NUMBER_OF_ITEM_TO_REMOVE,
    );
    if (reorderedItem.page === 'user-data') {
      toast.error('USER DATA position cannot be changed');
    }
    newItems.splice(result.destination.index, NEW_ITEM_POSITION, reorderedItem);
    setWidgetDefaultPages(newItems);
    localStorage.setItem('pages', JSON.stringify(newItems));
    setItems(newItems);
    setTrackChanges(true);
  };

  useEffect(() => {
    const itemIndex = items.findIndex(data => data?.page === 'indemnity');
    const NOT_IN_ARRAY = -1;
    const NUMBER_OF_ITEM_TO_REMOVE = 1;
    if (screenType === 'verifications') {
      handleSet(items[pageIndex]);
    }

    if (itemIndex === NOT_IN_ARRAY) return;
    if (itemIndex > 0) {
      const [reorderedItem] = items.splice(itemIndex, NUMBER_OF_ITEM_TO_REMOVE);
      const disclaimer = {...reorderedItem, 'indemnity-0': true, pageId: 0};
      items.unshift(disclaimer);
      setWidgetPages(disclaimer);
      setWidgetDefaultPages(items);
      localStorage.setItem('pages', JSON.stringify(items));

      setItems(items);
    }
  }, [items, screenType]);

  return (
    <Accordion
      title="Verification pages"
      onClick={() => {
        setScreenType('verifications');
      }}
    >
      {/************************  CONFIG PAGES  **************************/}
      <NewPageModal open={newPageModal} setOpen={setNewPageModal} />
      <GovernmentData open={governmentData} setOpen={setGovernmentData} />
      <BusinessData open={businessData} setOpen={setBusinessData} />
      <PhoneNumber open={phoneNumber} setOpen={setPhoneNumber} />
      <Selfie open={selfie} setOpen={setSelfie} />
      <Email open={email} setOpen={setEmail} />
      <Address open={address} setOpen={setAddress} />
      <GovernmentId open={governmentId} setOpen={setGovernmentId} />
      <UploadOtherIds open={uploadOtherIds} setOpen={setUploadOtherIds} />
      <SignaturePage open={signatureModal} setOpen={setSignatureModal} />
      <BusinessID open={businessID} setOpen={setBusinessID} />
      <QuestionsModal open={question} onClose={setQuestion} />
      <ConfigDisclaimerModal
        open={configDisclaimerModal}
        setOpen={setConfigDisclaimerModal}
      />

      <p className="text-xs text-body leading-[18px]">
        Arrange and configure your onboarding flow for easy verification
      </p>

      <div className="flex flex-col gap-2 mt-4">
        <DragDropContext
          onDragEnd={result => {
            handleDragDropPages(result);
          }}
        >
          <Droppable droppableId="verification" type="user-data">
            {provided => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex flex-col gap-2 verification"
              >
                {items?.map((page, index) => (
                  <Draggable
                    key={page?.pageId}
                    draggableId={`${page?.pageId}-${page.page}`}
                    index={index}
                    isDragDisabled={
                      (page.page === 'user-data' &&
                        page[`${page?.page}-${page?.pageId}`]) ||
                      page.page === 'indemnity'
                    }
                    type="user-data"
                  >
                    {provided => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Page
                          key={page?.pageId}
                          title={page?.page}
                          id={page?.pageId}
                          openSettings={page?.settings}
                          currentWidget={() => {
                            handleSet(page);
                            setPageIndex(index);
                          }}
                          activateWidget={data => {
                            handleActivate(data, page);
                          }}
                          isChecked={
                            page.page === 'indemnity' ||
                            items[index][`${page?.page}-${page?.pageId}`]
                          }
                          widgetScreen={widgetScreen}
                          deleteWidget={
                            page.page !== 'indemnity'
                              ? () => handleDeleteWidget(page)
                              : undefined
                          }
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        <SecondaryButton
          buttonText="Add a new page"
          onClick={openNewPageModal}
        />
      </div>
    </Accordion>
  );
}

export default connect(state => state, Actions)(VerificationPages);
