import {api} from '../../helpers';

function fetchAddressVerifications(params) {
  return api.get('/users/address-lookup', {params}).then(res => res.data);
}

function fetchAddressVerification(id, params) {
  const queryParams = new URLSearchParams(params);
  return api
    .get(`/users/address-lookup/${id}?${queryParams}`)
    .then(res => res.data);
}

function performVerification(params) {
  return api.post('/users/address-lookup', params).then(res => res.data);
}

function fetchBatchAddressVerifications(params) {
  return api.get('/users/address-lookup/batch', {params}).then(res => res.data);
}

function performBatchVerification(params) {
  return api.post('/users/address-lookup/batch', params).then(res => res.data);
}

function fetchBatchVerificationCount(id) {
  return api.get(`/users/address-lookup/batch/${id}`).then(res => res.data);
}

function fetchAddressVerificationSummary() {
  return api.get(`/users/address-lookup/summary`).then(res => res.data);
}

export {
  fetchAddressVerifications,
  performVerification,
  fetchBatchAddressVerifications,
  performBatchVerification,
  fetchBatchVerificationCount,
  fetchAddressVerification,
  fetchAddressVerificationSummary,
};
