import React from 'react';
import {logo, noAccountFound} from '../assets/images/images';
import PrimaryButton from './PrimaryButton';

export default function FailedGoogleLogin() {
  return (
    <div className="bg-white80">
      <section className="w-full flex flex-col gap-[30px] items-center text-center px-4 md:px-0 pt-28 pb-20 max-w-[544px] mx-auto">
        <img
          src={logo}
          alt="Dojah logo"
          width={64}
          height={29}
          className="sm:w-[80px] sm:h-[36px]"
        />
        <h3 className="text-xl text-grey font-medium">
          You do not have an account
        </h3>
        <img src={noAccountFound} alt="" width={490} height={275.98} />

        <p className="text-body text-center max-w-[351px]">
          You currently do not have a dojah account, To create an account click
          on Get started below
        </p>
        <PrimaryButton
          buttonText="Get started"
          onClick={() => window.open('https://dojah.io', '_blank')}
          className="w-full"
        />
      </section>
    </div>
  );
}
