import React from 'react';
import {search} from '../assets/images/images';
import {debounceFunction} from '../helpers';

export default function SearchInput({
  onChange,
  placeholder,
  defaultValue,
  className = '',
  disabled = false,
}) {
  const debounceChange = debounceFunction(e => {
    onChange(e.target.value);
  }, 1000);

  return (
    <label htmlFor="search" className="relative label flex-wrap items-center">
      <input
        type="search"
        name="search"
        placeholder={placeholder || 'Search'}
        className={`input max-w-full w-full sm:w-[277px] placeholder:text-tiny ${className}`}
        onChange={debounceChange}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      <img
        src={search}
        alt=""
        className="absolute left-4 top-[13px]"
        width={24}
        height={24}
      />
    </label>
  );
}
