import React from 'react';
import Select from 'react-select';
import {idCheck, userRed} from '../../../assets/images/images';
import {customStyles, DropdownIndicator} from '../../../components';
import {useFraudCheck, usePersistFraudData} from '../../../hooks/useFraudCheck';
import {Card, Wrapper} from '../FraudCheckScreens';

export const DetectWithDuplicateID = () => {
  const {setSelectedStatus, selectedStatus, setConfig, config, STATUSES} =
    useFraudCheck({
      action_returned: 'blocked',
    });

  const statusData = usePersistFraudData({
    config,
    selectedStatus,
    actionKey: 'action_returned',
    COLLECTION: STATUSES,
  });

  return (
    <Wrapper>
      <Card
        src={idCheck}
        extension={
          <div className="flex flex-col gap-1">
            <p className="text-grey font-medium">Duplicate ID Check</p>
            <p className="text-body text-sm gap-1">
              Resume verifications using unique email address or reference ID
              and Detect multiple onboarded IDs
            </p>
          </div>
        }
      />

      <Card
        extension={
          <p className="text-grey font-medium">If multiple IDs detected</p>
        }
        src={userRed}
      >
        <div className="text-start w-full">
          <label htmlFor="status" className="mb-2 relative">
            Set status to
          </label>
          {statusData !== undefined && (
            <Select
              defaultValue={statusData}
              value={statusData || STATUSES[0]}
              onChange={status => {
                setSelectedStatus(status);
                setConfig({...config, action_returned: status.value});
              }}
              options={STATUSES}
              className="basic-multi-select"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              menuPlacement="auto"
              isSearchable={false}
            />
          )}
        </div>
      </Card>
    </Wrapper>
  );
};
