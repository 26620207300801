import React, {useState} from 'react';
import Layout from '../Layout';
import {
  Accordion,
  EmptyState,
  Loader,
  SkeletonLoader,
} from '../../../components';
import {corruptedFile, placeholder} from '../../../assets/images/images';
import {useQuery} from 'react-query';
import {
  fetchImageMatch,
  fetchUserData,
} from '../../../requests/queries/customer360';
import {IGNORE_KEYS} from '../LayoutConstants';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import SingleAnalysisModal from '../../DocumentAnalysis/SingleAnalysisModal';
import {ImageComponent} from '../../../components/ImageComponent';

export default function LivenessCheck() {
  const {slug} = useParams();

  const {data: imageData, isLoading: imageLoading} = useQuery(
    'image-match',
    () => fetchImageMatch(slug),
    {
      enabled: !!slug,
    },
  );
  const {data: userData, isLoading: userDataLoading} = useQuery(
    'user-data',
    () => fetchUserData(slug),
    {
      enabled: !!slug,
    },
  );

  const [openDocumentAnalysisModal, setOpenDAM] = useState(false);
  const noData =
    !imageLoading &&
    imageData?.data.length === 0 &&
    !userDataLoading &&
    userData?.data?.length === 0;

  return (
    <Layout pageTitle="Validation Info">
      <SingleAnalysisModal
        open={openDocumentAnalysisModal}
        setOpen={setOpenDAM}
        customer_id={slug}
      />
      {userDataLoading || imageLoading ? (
        <Loader />
      ) : noData ? (
        <div className="bg-white80 w-full">
          <EmptyState
            body="There is no information to display here at the moment"
            src={corruptedFile}
            height={45}
            imageHeight={48}
            imageWidth={48}
            bodyMaxWidth="max-w-[179px]"
            buttonText="Validate document"
            onClick={() => {
              setOpenDAM(true);
            }}
            noBtn
          />
        </div>
      ) : (
        <div className="space-y-4">
          <Accordion
            title="Image Match"
            border
            customClasses="rounded-xl"
            customChildrenClasses="mt-2 border-t border-grey-50"
            customPadding="px-6 py-5"
            noChildPadding
            customTitleSize="text-lg"
            customTitleColor="text-grey"
            isOpen={false}
          >
            {imageLoading ? (
              <div className=" px-10 mt-6 grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 items-center gap-1">
                {[1, 2, 3, 4].map(d => (
                  <SkeletonLoader
                    key={d}
                    style={{
                      height: 80,
                      width: 200,
                      borderRadius: '10px',
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className="pl-8 pr-10 pt-6 pb-6">
                <div className="mt-6 grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 items-center gap-4 ">
                  {!imageLoading &&
                    imageData &&
                    imageData?.data?.map((img, index) => (
                      <div key={index} className="relative">
                        <div
                          className={`ml-auto absolute top-1  z-10 px-2 py-1 text-white right-1 rounded-full  ${
                            parseFloat(img?.confidence || 0).toFixed(2) > 50
                              ? 'bg-green-700'
                              : 'bg-red-800'
                          } text-grey text-xs font-medium uppercase`}
                        >
                          <span className="-mb-[2px]">
                            {Math.floor(parseFloat(img?.confidence || 0)) || 0}%
                            image match
                          </span>
                        </div>
                        <ImageComponent
                          space={'2'}
                          imageUrl={img?.id_image_url || placeholder}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
          </Accordion>

          <Accordion
            title="User Data Match"
            border
            customClasses="rounded-xl"
            customChildrenClasses="mt-2 border-t border-grey-50"
            customPadding="px-6 py-5"
            noChildPadding
            customTitleSize="text-lg"
            customTitleColor="text-grey"
          >
            <div className="w-full inline-grid grid-cols-[auto_auto] items-center gap-10 justify-normal pl-8 pr-10 pt-6 pb-6">
              {!userDataLoading &&
                userData &&
                userData.data.map((user, index) => (
                  <div key={user?.id || index} className="space-y-3">
                    <div
                      className={`w-fit flex items-center gap-2 rounded-full px-2 py-1 ${
                        parseFloat(user?.confidence || 0).toFixed(2) > 50
                          ? 'bg-green-700'
                          : 'bg-red-800'
                      } text-white text-xs font-medium uppercase`}
                    >
                      <span className="-mb-[2px]">
                        {Math.floor(parseFloat(user?.confidence || 0)) || 0}%
                        name match
                      </span>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-1gap-1">
                      {Object.entries(user || {}).map(
                        ([key, value], index) =>
                          !IGNORE_KEYS.includes(key) && (
                            <div
                              key={index}
                              className="flex w-full  items-center gap-2"
                            >
                              <p className="text-grey-700  text-xs font-medium uppercase">
                                {key.replaceAll('_', ' ')}:
                              </p>
                              {key === 'datetime' ? (
                                <p className="text-grey text-sm font-medium">
                                  {moment(value || new Date()).format(
                                    'Do MMM YYYY h:m',
                                  )}
                                </p>
                              ) : (
                                <p className="text-grey text-sm font-medium">
                                  {value}
                                </p>
                              )}
                            </div>
                          ),
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </Accordion>
        </div>
      )}
    </Layout>
  );
}
