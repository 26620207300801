import {loadStripe} from '@stripe/stripe-js';

const appearance = {
  theme: 'stripe',
  variables: {
    fontFamily: '"Inter", sans-serif',
    colorText: '#4A4A68',
    colorDanger: '#F15D5F',
    colorPrimary: '#3f7cdb',
  },
  rules: {
    '.Input': {
      border: 'none',
      backgroundColor: '#f9f9f9',
      outline: '1px solid #CECED2',
      padding: '10px 16px',
      boxShadow: 'none',
      height: '48px',
      borderColor: 'transparent',
    },
    '.Input:focus': {
      height: '48px',
      padding: '10px 16px',
      border: 'none',
      borderColor: 'transparent',
      boxShadow: 'none',
      outline: '1px solid var(--colorPrimary)',
    },
    '.Input, .Label, .Input--invalid, .Label--invalid': {
      fontFamily: 'Atakk',
      fontSize: '13px',
    },
    '.Input::placeholder': {
      color: '#8E90A9',
    },
  },
};

const stripeApiKey =
  process.env.REACT_APP_APP_ENV_MODE !== 'production'
    ? process.env.REACT_APP_STRIPE_TEST_KEY
    : process.env.REACT_APP_STRIPE_LIVE_KEY;

const stripePromise = loadStripe(stripeApiKey);

export {stripeApiKey, appearance, stripePromise};
