import React from 'react';
import {Loader, Modal} from '../../../components';
import {useQuery} from 'react-query';
import moment from 'moment';
import {fetchMessageDetail} from '../../../requests/queries/report';

function LogDetailsModal({open, setOpen, message_id}) {
  const {data, isLoading} = useQuery(
    ['messageDetail', message_id],
    () => fetchMessageDetail({message_id}),
    {enabled: !!message_id},
  );

  return (
    <Modal
      show={open}
      onClose={() => setOpen(false)}
      setOpen={setOpen}
      extraModalClasses="max-w-[528px] rounded-t-none sm:rounded-lg absolute bottom-0 sm:relative h-fit max-h-full overflow-y-auto"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Messaging Log"
    >
      <div className="pt-5">
        <div>
          {isLoading ? (
            <Loader height={10} />
          ) : (
            <>
              <div className="bg-[#F7F7F7] rounded-xl p-6">
                <div className="flex items-center justify-between">
                  <p className="text-[#868C98] font-inter text-tiny">
                    Delivery Status
                  </p>
                  <div
                    className={`uppercase w-fit ${
                      data?.entity?.delivery_status === 'sent'
                        ? 'bg-[#E2F5F6] text-[#6FCBD4]'
                        : 'bg-[#EEF3FC] text-[#3F7CDB]'
                    } text-xs rounded-full px-2 py-1 mt-1 h-5 flex items-center justify-center`}
                  >
                    <p>{data?.entity?.delivery_status}</p>
                  </div>
                </div>
                <div className="h-[1px] bg-[#E1E4EA] w-full my-4" />
                <div className="flex items-center justify-between">
                  <p className="text-[#868C98] font-inter text-tiny">Date</p>
                  <p className="font-medium text-tiny font-inter text-black">
                    {moment(data?.entity?.created).format(
                      'MMMM D, YYYY, h:mm a',
                    )}
                  </p>
                </div>
              </div>

              <div className="mt-5 text-start">
                <p className="text-[#727272] font-medium text-base">
                  Message content
                </p>
                <div className="mt-2 rounded-xl bg-[#F7F7F7] p-6">
                  <p className="text-black font-inter font-medium text-tiny">
                    {data
                      ? data?.entity?.message
                      : 'No message content available'}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default LogDetailsModal;
