import React from 'react';

export const DocumentAnalysis1 = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={341}
    height={71}
    fill="none"
    {...props}
  >
    <rect
      width={339}
      height={62}
      x={1}
      y={3}
      fill="#fff"
      style={{
        stroke: 'var(--color-primary)',
        strokeOpacity: 0.2,
      }}
      strokeWidth={2}
      rx={4}
    />
    <g filter="url(#a)">
      <g clipPath="url(#b)">
        <rect
          width={38}
          height={38}
          x={32}
          y={15}
          rx={19}
          style={{
            fill: 'var(--color-primary)',
          }}
        />
        <circle cx={51} cy={33} r={6.667} fill="url(#c)" />
        <path
          fill="url(#d)"
          d="M64.334 52.167c0 4.142 0 7.5-13.334 7.5-13.333 0-13.333-3.358-13.333-7.5 0-4.142 5.97-7.5 13.333-7.5 7.364 0 13.334 3.358 13.334 7.5Z"
          opacity={0.5}
        />
      </g>
      <rect
        width={38}
        height={38}
        x={32}
        y={15}
        stroke="#fff"
        strokeWidth={2}
        rx={19}
      />
    </g>
    <rect width={145} height={12} x={81} y={28} fill="#F2F4F7" rx={6} />
    <defs>
      <linearGradient
        id="c"
        x1={49.41}
        x2={63.495}
        y1={36.997}
        y2={18.217}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={51}
        x2={51}
        y1={44.667}
        y2={56.657}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <clipPath id="b">
        <rect width={38} height={38} x={32} y={15} fill="#fff" rx={19} />
      </clipPath>
      <filter
        id="a"
        width={70}
        height={70}
        x={16}
        y={0.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1.5} />
        <feGaussianBlur stdDeviation={7.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.675 0 0 0 0 0.675 0 0 0 0 0.675 0 0 0 0.35 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5427_65604"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5427_65604"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
