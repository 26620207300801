import React, {memo} from 'react';
import {NavLink} from 'react-router-dom';
import {PAGES_URL} from '../../helpers/constants';
import {DashboardLayout} from '../../components';
import {documentAnalysisIc} from '../../assets/images/images';
import {useGovtLookupHomeLayout} from '../../hooks/useGovtLookupHomeLayout';

const ROUTES = [
  {
    name: 'Individual Lookup',
    path: PAGES_URL.INDIVIDUAL_LOOKUP,
    key: 'individual',
  },
  {
    name: 'Business Lookup',
    path: PAGES_URL.BUSINESS_LOOKUP,
    key: 'business',
  },
];

function Layout({children}) {
  const getPermission = useGovtLookupHomeLayout();

  return (
    <DashboardLayout
      bg="bg-white80"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={documentAnalysisIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Government Lookup</p>
        </div>
      }
    >
      <div className="mb-6 rounded-xl bg-white p-1 border border-[#E1E4EA] flex items-center gap-2 overflow-x-auto mr-[76px] w-fit">
        {ROUTES.map((route, index) => (
          <NavLink
            key={index}
            end
            to={route.path}
            className={({isActive}) =>
              `pt-[13px] pb-[7px] px-4 text-sm  flex items-center justify-center rounded-lg shadow-[0px_4px_32px_-4px,rgba(199,196,196,0.18)] ${
                isActive
                  ? 'text-dojahBlue bg-blue-100'
                  : 'text-grey-500 bg-transparent'
              }`
            }
            style={{
              pointerEvents: getPermission(route?.key) ? 'auto' : 'none',
            }}
          >
            {route.name}
          </NavLink>
        ))}
      </div>
      {children}
    </DashboardLayout>
  );
}

export default memo(Layout);
