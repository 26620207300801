import React, {useState} from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import {useMutation, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import {
  Modal,
  PrimaryButton,
  DropdownIndicator,
  customStyles,
} from '../../components';
import * as Actions from '../../store/actions';
import {editApiToken} from '../../requests/queries/tokens';

function EditTokenModal({open, setOpen, selectedToken, userApps}) {
  const queryClient = useQueryClient();

  const [tokenName, setTokenName] = useState('');
  const [selectedApp, setSelectedApp] = useState(null);

  const selectedAppValue =
    userApps && userApps?.find(app => app.value === selectedToken?.app);

  const {isLoading, mutate} = useMutation(data =>
    editApiToken(selectedToken?._id, data),
  );

  const handleSubmit = e => {
    e.preventDefault();

    const tokenNameToUse = tokenName || selectedToken?.name;
    const selectedAppToUse = selectedApp || selectedAppValue;

    if (!tokenNameToUse || !selectedAppToUse) {
      toast.error('Please fill all fields');
      return;
    }

    mutate(
      {name: tokenNameToUse, app: selectedAppToUse.value},
      {
        onSuccess: () => {
          toast.success('Token updated successfully');
          setOpen(false);
          setTokenName('');
          setSelectedApp(null);
          queryClient.invalidateQueries(['api-tokens']);
        },
        onError: error => {
          toast.error(error.response.data.error);
        },
      },
    );
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Edit token"
    >
      <form onSubmit={handleSubmit} className="mt-6 text-left">
        <label htmlFor="token_name" className="mt-6">
          <span className="text-sm">Token name</span>
          <input
            type="text"
            id="token_name"
            name="token_name"
            className="mt-2"
            defaultValue={tokenName || selectedToken?.name || ''}
            onChange={e => setTokenName(e.target.value)}
          />
        </label>

        <label htmlFor="app" className="relative mt-6 mb-2">
          Select app
        </label>
        <Select
          defaultValue={selectedApp || selectedAppValue}
          value={selectedApp || selectedAppValue}
          onChange={setSelectedApp}
          options={userApps}
          placeholder="Select app"
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="auto"
        />

        <div className="flex items-center mt-6">
          <PrimaryButton
            buttonText="Update token"
            className="w-full"
            loading={isLoading}
            aria-disabled={isLoading}
            type="submit"
          />
        </div>
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(EditTokenModal);
