/* eslint-disable react-hooks/exhaustive-deps */

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Modal, VerificationToggle} from '../../../components';
import {connect} from 'react-redux';
import * as Actions from '../../../store/actions';
import {toast} from 'react-toastify';
import {infoRedSm} from '../../../assets/images/images';

function UploadOtherIds({
  widgets: {widgetScreen, widgetPages, widgetPricing},
  setWidgetScreen,
  setWidgetPages,
  setWidgetCurrentPricing,
  ...otherProps
}) {
  const {open, setOpen} = otherProps;

  const defaultInstruction =
    'Make sure your document is properly placed, and hold it still for a few seconds';
  const [error, setError] = useState(false);
  const [config, setConfig] = useState({hideUpload: true});
  const [pricing, setPricing] = useState({
    services: {},
  });

  const filteredConfig = useCallback(
    data =>
      Object.fromEntries(
        Object.entries({
          ...data,
        }).filter(([, value]) => !!value),
      ),
    [config],
  );

  useEffect(() => {
    const isActive =
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.page === 'additional-document' &&
          data[`${data?.page}-${data?.pageId}`],
      );
    if (isActive) {
      setPricing(prev => {
        const price =
          (widgetPricing &&
            widgetPricing['additional-document']?.services?.base) ||
          0;

        return {
          services: {
            ...{
              ...prev.services,
              docs: price / 100,
            },
          },
        };
      });

      setWidgetCurrentPricing({
        'additional-document': pricing,
      });
    } else {
      setWidgetCurrentPricing({
        'additional-document': {},
      });
    }
    return () =>
      setWidgetCurrentPricing({
        'additional-document': {},
      });
  }, [widgetScreen, Object.keys(pricing.services).length > 0]);

  const newData = useMemo(
    () =>
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.pageId === widgetScreen?.pageId &&
          data?.page === 'additional-document',
      ),
    [widgetPages, widgetScreen],
  );

  const ALL_PAGES = JSON.parse(localStorage.getItem('pages'));
  const updateConfig = newConfig => {
    const newPages = ALL_PAGES?.map(page => {
      if (page?.page === newData?.page) {
        return {
          ...page,
          config: newConfig,
        };
      }
      return page;
    });

    localStorage.setItem('pages', JSON.stringify(newPages));
  };

  useEffect(() => {
    const trimmedInstruction = config?.instruction?.replace(/^\s+|\s+$/g, '');

    if (Object.keys(newData?.config || {})?.length === 0) {
      setConfig({});
      setError(false);
      setConfig({
        instruction: trimmedInstruction || defaultInstruction,
        title: 'Untitled',
        hideUpload: false,
      });
      setWidgetPages({
        ...newData,
        config: filteredConfig({
          instruction: trimmedInstruction || defaultInstruction,
          title: 'Untitled',
          hideUpload: true,
        }),
      });
      updateConfig(
        filteredConfig({
          instruction: trimmedInstruction || defaultInstruction,
          title: 'Untitled',
          hideUpload: true,
        }),
      );
    } else {
      setError(false);

      const newConfig = {
        instruction:
          newData?.config?.instruction ||
          trimmedInstruction ||
          defaultInstruction,
        title: newData?.config.title,
        hideUpload: newData?.config.hideUpload,
      };
      const filteredObj = filteredConfig({...config, ...newConfig});
      setConfig({
        ...config,
        ...newConfig,
      });
      setWidgetPages({
        ...newData,
        config: {
          ...filteredObj,
        },
      });
      updateConfig({
        ...filteredObj,
      });
    }

    return () => {
      setConfig({...config, title: ''});
    };
  }, [widgetPages?.length, Object.keys(config).length, widgetScreen?.pageId]);

  const handleUpdateConfig = useCallback(
    (key, value) => {
      if (name === 'instruction' && value.length === 0) {
        setError(true);
        return;
      }
      const newData = widgetPages?.find(
        data => data?.pageId === widgetScreen?.pageId,
      );
      if (!newData) {
        return toast.error(
          'Ops! Please toggle ON before configuring this widget',
        );
      }
      setError(false);

      const filteredObj = filteredConfig({...config, [key]: value});
      setConfig({...config, [key]: value});
      setWidgetScreen({
        ...widgetScreen,
        config: {...filteredObj},
      });
      setWidgetPages({
        ...newData,
        config: {...filteredObj},
      });
    },
    [widgetScreen],
  );

  return (
    <Modal
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[339px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Configuration"
    >
      <p className="text-left text-body text-sm mt-8 mb-2 font-medium">
        The preferred instruction you would like to pass across to your users
      </p>
      <textarea
        placeholder="Input instruction"
        name="instruction"
        onChange={e => handleUpdateConfig('instruction', e.target.value)}
        className={`placeholder:text-sm  resize-y min-h-[40px] max-h-[100px] ${
          error && 'border focus:outline-danger '
        } `}
        value={config?.instruction || ''}
        maxLength={80}
      ></textarea>
      {error && (
        <div className="flex  bg-statusBg-500 p-2 rounded">
          <img src={infoRedSm} alt="" />
          <p className="text-xs ml-3 text-body">
            Field is required. Please input instruction
          </p>
        </div>
      )}
      <p className="text-end text-body text-sm font-medium mt-2">
        {config?.instruction?.length || 0}/80
      </p>

      <div>
        <label htmlFor="title">Document name</label>
        <input
          type="text"
          name="title"
          id="title"
          placeholder="Input document name"
          className="w-full text-sm  rounded-md p-2 mt-2"
          onChange={e => handleUpdateConfig('title', e.target.value)}
          value={config?.title || ''}
        />
      </div>

      <div>
        <p className="text-left text-body text-xs mt-8 mb-2 font-medium uppercase">
          FILE UPLOAD
        </p>

        <div className="flex items-center gap-2 pl-4 pr-2 py-[10px] cursor-pointer">
          <VerificationToggle
            isChecked={!config?.hideUpload}
            onCheck={value => handleUpdateConfig('hideUpload', !value)}
          />
          <div className="flex justify-between w-48">
            <p className="text-xs leading-[18px] text-body">
              Enable file upload
            </p>
            {/*<p className="text-xs ml-4 leading-[18px] text-grey20">*/}
            {/*  {displayPrice(*/}
            {/*    widgetPricing && widgetPricing?.id?.services?.base / 100,*/}
            {/*  )}*/}
            {/*</p>*/}
          </div>
        </div>
        <p className="text-left text-grey40 text-xs mt-2 mb-2 leading-5 ">
          Please note that when toggled off user will ONLY be able to capture
          ID/Document with device camera
        </p>
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(UploadOtherIds);
