import {isObjectEmpty} from '../../helpers/isObjectEmpty';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import {pushObjectsIntoArray} from '../../helpers/pushOnlyFourItemsArrayOfArrays';
import {
  generateFooter,
  horizontalLine,
  // watermark,
  styles,
  generatePDFStructure,
} from '../../helpers/pdfUtils';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const generateHeader = ({lookupType, lookupImage, companyName}) => [
  {text: lookupType || 'Easy Lookup', style: 'type'},
  {
    text: `Requested by ${companyName}`,
    style: {alignment: 'center', fontSize: 11, color: '#4A4A68'},
    margin: [0, 0, 0, 30],
  },
  {
    text: 'Profile'.toUpperCase(),
    margin: [0, 10, 0, 7],
    style: {bold: true, fontSize: 10},
  },
  horizontalLine(),
  ...[
    lookupImage
      ? {
          image: lookupImage,
          width: 150,
          alignment: 'left',
          margin: [0, 0, 0, 20],
        }
      : {},
  ],
];

const generatePDFStructureForEasyLookup = ({
  lookupData,
  logo,
  lookupType,
  lookupImage,
  companyName,
}) => {
  const generateDocInfo = lookupData
    .filter(doc => !isObjectEmpty(doc))
    .map(doc => doc);

  const headerRow = generateHeader({
    logo,
    lookupType,
    lookupImage,
    companyName,
  });
  const content = [...headerRow];
  const finalContentStructure = generatePDFStructure(generateDocInfo);
  const dataColumns = pushObjectsIntoArray(finalContentStructure);
  content.push(dataColumns);
  return content;
};

const generatePDF = (contents, footerData, lookupType) => {
  const documentDefinition = {
    // watermark,
    content: [...contents],
    styles,
    footer: generateFooter(footerData),
  };

  try {
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.download(`${lookupType}.pdf`.toLowerCase());
  } catch (error) {
    console.error('PDF Generation Error:', {
      message: error.message,
      stack: error.stack,
      docDefinition: JSON.stringify(documentDefinition, null, 2),
    });

    // Log specific error types
    if (error.message.includes('not found in virtual file system')) {
      console.error('Image loading error - Check image paths and availability');
    }

    // Handle the error appropriately based on your application needs
    throw new Error(`PDF generation failed: ${error.message}`);
  }
};

export {generatePDFStructureForEasyLookup, generatePDF};
