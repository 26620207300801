import React, {useCallback, useState, memo} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
  useMutation,
  // useQuery
} from 'react-query';
import {
  // google,
  reconciliationAuthBg,
  reconciliationLogo,
} from '../../../../assets/images/images';
import {
  // ButtonLoader,
  PrimaryButton,
} from '../../../../components';
import {Constants} from '../../../../helpers';
import {signupUser} from '../../../../requests/queries/reconciloAuth';
import {toast} from 'react-toastify';
// import {googleSignin} from '../../../../requests';

const initialInputs = {
  first_name: '',
  last_name: '',
  company: '',
  email: '',
};

const Signup = memo(() => {
  const [inputs, setInputs] = useState(initialInputs);
  // const [googleLoading, setGoogleLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputs = useCallback(e => {
    const {name, value} = e.target;
    setInputs(prev => ({...prev, [name]: value}));
  }, []);

  const {mutateAsync, isLoading} = useMutation(
    'create-app',
    data => signupUser(data),
    {
      onSuccess: data => {
        toast.success(data?.message);
        setInputs(initialInputs);
        navigate('/signin');
      },
      onError: error => {
        toast.error(error.response.data?.error);
      },
    },
  );

  // const {refetch: googleSigninRefetch} = useQuery(
  //   'google-signin',
  //   () => googleSignin(),
  //   {
  //     enabled: false,
  //     onSuccess: data => {
  //       setGoogleLoading(false);
  //       const {url} = data;
  //       if (url) {
  //         window.location.href = url;
  //       }
  //     },
  //     onError: error => {
  //       setGoogleLoading(false);
  //       toast.error(error.message || 'Google sign-in failed');
  //     },
  //   },
  // );

  // const handleGoogleSignUp = () => {
  //   setGoogleLoading(true);
  //   googleSigninRefetch();
  // };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const data = {
        ...inputs,
      };

      await mutateAsync(data);
    } catch (e) {
      console.log(e);
    }
  };

  const isSubmitDisabled = Object.values(inputs).some(value => !value);

  return (
    <div
      className="w-full h-full flex justify-center items-center bg-cover bg-center bg-no-repeat min-h-screen"
      style={{backgroundImage: `url(${reconciliationAuthBg})`}}
    >
      <div className="py-[70px] px-5 lg:px-0">
        <div className="bg-white border m-auto border-batchBlue20 py-12 px-8 rounded-lg w-full md:w-[586px]">
          <div className="flex flex-col items-center gap-2 w-full">
            <img
              src={reconciliationLogo}
              alt="Dojah logo"
              className="w-[110px] h-[21px]"
            />
            <p className="text-sm text-body">
              Gain insight into your business&apos;s financial health with
              Renconcilo
            </p>
          </div>

          <div className="mt-8">
            <h2 className="text-deepBlue font-medium text-lg mb-[6px]">
              Create Account
            </h2>
            <p className="text-sm text-body">
              Provide the information below to create an account
            </p>

            <div className="flex flex-col md:flex-row items-center gap-4 mt-6">
              <div className="flex-1 w-full">
                <label htmlFor="first_name" className="block">
                  First name
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="mt-2 block w-full"
                    value={inputs?.first_name}
                    onChange={handleInputs}
                  />
                </label>
              </div>
              <div className="flex-1 w-full">
                <label htmlFor="last_name" className="block">
                  Last name
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    className="mt-2 block w-full"
                    value={inputs?.last_name}
                    onChange={handleInputs}
                  />
                </label>
              </div>
            </div>

            <label htmlFor="company" className="block mt-4">
              Company name
              <input
                type="text"
                name="company"
                id="company"
                className="mt-2"
                value={inputs?.company}
                onChange={handleInputs}
              />
            </label>
            <label htmlFor="email" className="block mt-4">
              Email address
              <input
                type="email"
                name="email"
                id="email"
                className="mt-2"
                value={inputs?.email}
                onChange={handleInputs}
              />
            </label>

            <PrimaryButton
              type="submit"
              buttonText="Sign Up"
              className="w-full mt-6"
              disabled={isSubmitDisabled}
              loading={isLoading}
              onClick={handleSubmit}
            />

            {/*<button*/}
            {/*  type="button"*/}
            {/*  disabled={googleLoading}*/}
            {/*  className="flex items-center justify-center w-full h-12 gap-2 p-4 mt-4 border rounded md:mt-6 border-body"*/}
            {/*  onClick={handleGoogleSignUp}*/}
            {/*>*/}
            {/*  {!googleLoading && (*/}
            {/*    <img src={google} alt="" width={20} height={20} />*/}
            {/*  )}*/}
            {/*  {googleLoading ? (*/}
            {/*    <ButtonLoader color="black" />*/}
            {/*  ) : (*/}
            {/*    <span className="self-start text-sm font-medium text-grey">*/}
            {/*      Sign up with Google*/}
            {/*    </span>*/}
            {/*  )}*/}
            {/*</button>*/}

            <p className="mt-6 text-sm text-body">
              By clicking the “Sign Up” button, you are creating a Dojah
              account, and you agree to Dojah’s{' '}
              <span className="text-brandBlue">
                <a
                  href="https://dojah.io/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
              </span>{' '}
              and{' '}
              <span className="text-brandBlue">
                <a
                  href="https://dojah.io/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </span>
            </p>

            <p className="text-sm font-medium text-body mt-6">
              Already have an account?{' '}
              <Link
                to={Constants.PAGES_URL.RECONCILIATION_SIGNIN}
                className="text-brandBlue"
              >
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Signup;
