import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {LineChart} from '../components/LineChart';
import {Period} from '../components/Period';
import {useAnalysisData} from '../hooks/useAnalysisData';

const ReconChart = () => {
  const {slug} = useParams();
  const [date, setDate] = useState('month');
  const queryParams = new URLSearchParams(window.location.search);

  const projectId = slug !== 'add-project' ? slug : '';
  const {
    chartAnalysis: {analysisChart, chartLoading},
  } = useAnalysisData({
    id: projectId,
    date,
    entity_id: 'project_id',
    // ...filters,
  });

  return (
    <div className="bg-white rounded-xl w-full mt-6 h-[444px]">
      <LineChart
        chartData={analysisChart}
        customElements={
          <Period
            date={date}
            setDate={setDate}
            datePeriod={queryParams.get('period')}
          />
        }
        periodType={date}
        loading={chartLoading}
      />
    </div>
  );
};
export default ReconChart;
