import React from 'react';
import OverviewLineChart from '../Overview/OverviewLineChart';

export const LineChart = ({
  customElements,
  chartData,
  periodType,
  loading,
  height,
  pageHeight,
  loaderHeight,
}) => (
  <div
    className={`bg-white relative rounded-xl w-full  h-[${
      pageHeight || '444'
    }px]`}
  >
    <div
      className={`flex absolute top-3 z-10 flex-col right-3 lg:flex-row gap-5 lg:gap-0 justify-between items-center   px-6`}
    >
      {customElements && chartData?.length > 0 && customElements}

      {/*<div className="border p-1 border-[#E1E4EA] rounded-xl bg-[#F7F7F7] flex items-center gap-2 text-xs lg:text-tiny font-medium ">*/}
      {/*  <div className="bg-white cursor-pointer text-black rounded-lg px-4 py-[10px]">*/}
      {/*    Transaction Amount*/}
      {/*  </div>*/}
      {/*  <div className="bg-transparent cursor-pointer text-[#868C98] rounded-lg px-4 py-[10px]">*/}
      {/*    Account Balance*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
    <OverviewLineChart
      data={chartData}
      periodType={periodType}
      loading={loading}
      height={height}
      pageHeight={pageHeight}
      loaderHeight={loaderHeight}
    />
  </div>
);
