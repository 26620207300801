import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import Layout from '../Layout';
import {Accordion, EmptyState, GoogleMap, Loader} from '../../../components';
import {
  base_address_marker,
  corruptedFile,
  false_address_marker,
  true_address_marker,
} from '../../../assets/images/images';
import {fetchAddress} from '../../../requests/queries/customer360';
import {IGNORE_ADDRESS_DATA_KEYS} from '../LayoutConstants';
import SingleAnalysisModal from '../../DocumentAnalysis/SingleAnalysisModal';

export default function AddressInfo() {
  const {slug} = useParams();

  const {data: addressData, isLoading: addressDataLoading} = useQuery(
    'address-data',
    () => fetchAddress(slug),
    {
      enabled: !!slug,
    },
  );

  const [openDocumentAnalysisModal, setOpenDAM] = useState(false);

  const ADDRESS_DATA = !addressDataLoading ? addressData : [];

  const baseAddress = ADDRESS_DATA?.data?.[0]?.base_address || {};
  const otherAddresses = ADDRESS_DATA?.data?.[0]
    ? Object.values(ADDRESS_DATA.data[0]).filter(
        address => address !== baseAddress,
      )
    : [];

  const formattedOtherAddresses = otherAddresses.map(address => ({
    lat: +address.latitude,
    lng: +address.longitude,
    name: address.name ? address.name : 'No address name specified',
    markerImage: address.name
      ? base_address_marker
      : address.match
      ? true_address_marker
      : false_address_marker,
  }));

  return (
    <Layout pageTitle="Address Info">
      <SingleAnalysisModal
        open={openDocumentAnalysisModal}
        setOpen={setOpenDAM}
        customer_id={slug}
      />
      {addressDataLoading ? (
        <Loader />
      ) : !addressDataLoading && ADDRESS_DATA?.data?.length === 0 ? (
        <div className="bg-white80 w-full">
          <EmptyState
            body="There is no information to display here at the moment"
            src={corruptedFile}
            height={45}
            imageHeight={48}
            imageWidth={48}
            bodyMaxWidth="max-w-[179px]"
            buttonText="Validate document"
            onClick={() => {
              setOpenDAM(true);
            }}
            noBtn
          />
        </div>
      ) : (
        <div className="space-y-4">
          <Accordion
            title="Address Info"
            border
            customClasses="rounded-xl"
            customChildrenClasses="mt-2 border-t border-grey-50"
            customPadding="px-6 py-5"
            noChildPadding
            customTitleSize="text-lg"
            customTitleColor="text-grey"
            isOpen={false}
          >
            <div className="w-full inline-grid grid-cols-[auto_auto] items-center gap-10 justify-normal pl-8 pr-10 pt-6 pb-6">
              {Object.entries(baseAddress).map(
                ([key, value], index) =>
                  !IGNORE_ADDRESS_DATA_KEYS.includes(key) &&
                  value && (
                    <div key={index} className="flex flex-col gap-2">
                      <p className="text-grey-700 text-xs font-medium uppercase">
                        {key === 'name' ? 'Inputted address' : key}
                      </p>
                      <p className="text-grey text-sm font-medium">{value}</p>
                    </div>
                  ),
              )}

              <div className="col-span-full">
                <GoogleMap
                  center={{
                    lat: Number(baseAddress.latitude),
                    lng: Number(baseAddress.longitude),
                  }}
                  zoom={8}
                  multipleMarkers={[
                    {
                      lat: Number(baseAddress.latitude),
                      lng: Number(baseAddress.longitude),
                      name: baseAddress.name
                        ? baseAddress.name
                        : 'No address name specified',
                      markerImage: baseAddress.name
                        ? base_address_marker
                        : false_address_marker,
                    },
                  ]}
                  customStyles={{
                    position: 'relative',
                    height: '192px',
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col items-center gap-10 pl-8 pr-10 pt-6 pb-6">
              {formattedOtherAddresses.map((address, index) => (
                <div
                  key={index}
                  className="w-full inline-grid grid-cols-[auto_auto] gap-10"
                >
                  <div className="flex flex-col gap-2">
                    <p className="text-grey-700 text-xs font-medium uppercase">
                      Address
                    </p>
                    <p className="text-grey text-sm font-medium">
                      {address.name}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-grey-700 text-xs font-medium uppercase">
                      Latitude
                    </p>
                    <p className="text-grey text-sm font-medium">
                      {address.lat}
                    </p>
                  </div>

                  <div className="flex flex-col gap-2">
                    <p className="text-grey-700 text-xs font-medium uppercase">
                      Longitude
                    </p>
                    <p className="text-grey text-sm font-medium">
                      {address.lng}
                    </p>
                  </div>

                  <div className="col-span-full">
                    <GoogleMap
                      zoom={8}
                      center={{lat: address.lat, lng: address.lng}}
                      multipleMarkers={[address]}
                      customStyles={{
                        position: 'relative',
                        height: '192px',
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Accordion>
        </div>
      )}
    </Layout>
  );
}
