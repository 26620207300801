import React, {useContext, useEffect, useState} from 'react';
import Layout from './Layout';
import {affordabilityEmptyState} from '../../assets/images/images';
import {PrimaryButton, RowData} from '../../components';
import GenerateAffordabilityModal from './GenerateAffordabilityModal';
import {PageContext} from '../../helpers/context';
import {formatNumberToCurrency} from '../../helpers/formatNumberToCurrency';
import {useQuery} from 'react-query';
import {performAffordability} from '../../requests/queries/creditChecks';
import {useLocation, useParams} from 'react-router-dom';

export default function EasyCreditAffordability() {
  const [openGenerateAffordabilityModal, setOpenGAM] = useState(false);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('creditcheck');
  }, [setPageName]);

  const {state} = useLocation();
  const {slug} = useParams();

  const {data, isLoading} = useQuery(
    [
      'perform-affordability',
      {
        app_id: state?.app_id,
        account_id: slug,
        debt_to_income: 0,
        loan_tenure: 0,
        get_record: true,
      },
    ],
    () =>
      performAffordability({
        app_id: state?.app_id,
        account_id: slug,
        debt_to_income: 0,
        loan_tenure: 0,
        get_record: true,
      }),
  );

  const AFFORDABILITY_DATA = (!isLoading && data && data?.entity) || {};

  const AFFORDABILITY = [
    {
      leftText: 'total affordability',
      rightText: formatNumberToCurrency(
        AFFORDABILITY_DATA?.affordability_amount || 0,
      ),
    },
    {
      leftText: 'monthly affordability',
      rightText: formatNumberToCurrency(
        AFFORDABILITY_DATA?.[
          'monthly_disposable_income/monthly_affordability_amount'
        ] || 0,
      ),
    },
    {
      leftText: 'monthly loan repayment',
      rightText: formatNumberToCurrency(
        AFFORDABILITY_DATA?.average_monthly_loan_repayment_amount || 0,
      ),
    },
    {
      leftText: 'dti',
      rightText: `${AFFORDABILITY_DATA?.dti || 0}%`,
    },
    {
      leftText: 'loan tenure',
      rightText: AFFORDABILITY_DATA?.loanTenure || '-',
    },
  ];

  return (
    <Layout>
      <GenerateAffordabilityModal
        open={openGenerateAffordabilityModal}
        setOpen={setOpenGAM}
      />

      <div className="flex flex-col ">
        {!AFFORDABILITY_DATA ? (
          <div className="flex flex-col items-center justify-center py-5 mt-6">
            <img src={affordabilityEmptyState} alt="" width={32} height={32} />
            <p className="mt-6 max-w-[260px] text-sm text-center text-body">
              Measure and compare affordability based on the uploaded bank
              statements
            </p>
            <PrimaryButton
              buttonText="Generate Affordability"
              className="mt-6"
              onClick={() => setOpenGAM(true)}
            />
          </div>
        ) : (
          AFFORDABILITY.map(({leftText, rightText}, i) => (
            <RowData
              key={i}
              leftText={leftText}
              rightText={rightText}
              boldFont
            />
          ))
        )}

        <>
          <PrimaryButton
            buttonText="Regenerate Affordability"
            className="mt-6"
            onClick={() => setOpenGAM(true)}
          />
        </>
      </div>
    </Layout>
  );
}
