import React, {useEffect, useState} from 'react';
import {Modal, PrimaryButton} from '../../components';
import {closeModal} from '../../assets/images/images';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import {toast} from 'react-toastify';
import {handleImage} from '../../helpers';

function EditAppModal({
  apps: {mAppLoading},
  updateApp,
  currentApp,
  open,
  setOpen,
}) {
  const defaultFilename = 'Click here to upload your app logo';
  const [state, setState] = useState();
  const [uploading, setLoading] = useState(null);
  const [icon_url, setIcon] = useState(null);
  const [filename, setFilename] = useState(defaultFilename);

  const handleChange = e => {
    e.persist();
    setState(prev => ({...prev, [e.target?.id]: e.target?.value}));
  };

  useEffect(() => {
    setState({
      name: currentApp && currentApp?.name,
      color_code: currentApp && currentApp?.color_code,
      id: currentApp && currentApp?._id,
    });
    setFilename((currentApp && 'Logo Uploaded') || defaultFilename);
  }, [currentApp]);

  const handleCallback = ({loading, icon, name}) => {
    setLoading(loading);
    setIcon(icon);
    setFilename(name);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (state?.name?.length < 3) {
      return toast.error(
        'The name of the app must be at least 3 characters long.',
      );
    }

    updateApp(
      state?.id,
      {
        name: state?.name,
        color_code: state?.color_code,
        ...(icon_url && {icon_url}),
      },
      setOpen,
    );
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-grey text-base font-medium">Edit App</h3>
          <button onClick={() => setOpen(false)}>
            <img src={closeModal} alt="" />
          </button>
        </header>

        <form className="mt-6 text-left">
          <label htmlFor="app_name_create" className="mt-6">
            <span className="text-sm ">Name of App</span>
            <input
              value={state?.name}
              type="text"
              id="name"
              name="name"
              className="mt-2"
              onChange={e => handleChange(e)}
            />
          </label>
          <label htmlFor="upload_logo" className="mt-6">
            Logo
            <input
              type="file"
              id="upload_logo"
              onChange={e =>
                handleImage(e.target.files[0], handleCallback, 'App logo')
              }
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
            <div className="cursor-pointer bg-white80 mt-2 py-[14px] text-center border border-brandBlue border-dashed rounded w-full font-normal">
              <p
                className={`text-body ${uploading ? 'italic' : 'text-black'} `}
              >
                {uploading ? 'Uploading icon...' : filename}
              </p>
            </div>
          </label>

          <label htmlFor="primary_color" className="mt-6">
            Primary Color
            <div className="flex gap-4 w-full">
              <input
                type="color"
                id="color_code"
                className="mt-2 p-0 max-w-[48px]"
                onChange={e => handleChange(e)}
                value={state?.color_code?.toLocaleUpperCase()}
              />
              <input
                value={state?.color_code?.toLocaleUpperCase()}
                type="text"
                id="color_code"
                className="mt-2"
                placeholder="#00000"
                onChange={e => handleChange(e)}
              />
            </div>
          </label>

          <div className="flex items-center mt-6">
            <PrimaryButton
              buttonText="Update App"
              className="w-full"
              onClick={handleSubmit}
              disabled={state?.name?.length === 0}
              loading={uploading || mAppLoading}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(EditAppModal);
