import React from 'react';
import {Menu} from '@headlessui/react';

export default function Dropdown({dropdownBtn, children, height}) {
  return (
    <Menu>
      <Menu.Button className={height ? "h-full" : ""}>{dropdownBtn}</Menu.Button>
      <Menu.Items>{children}</Menu.Items>
    </Menu>
  );
}
