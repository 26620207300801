import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {
  fetchSubscriptionInfo,
  fetchSubscriptionPaymentHistory,
} from '../../../../requests/queries/reconciliations';
import {useSelector} from 'react-redux';

export const useBillingInfo = ({isInfo = false}) => {
  const {userDetails} = useSelector(state => state?.auth);

  const {data: billingInfoData, isLoading: infoLoading} = useQuery(
    ['recon-billing', userDetails?.company?.id],
    () => fetchSubscriptionInfo(userDetails?.company?.id),
  );

  const {data: billingHistoryData, isLoading: historyLoading} = useQuery(
    ['recon-billing-history', userDetails?.company?.id],
    () => fetchSubscriptionPaymentHistory(userDetails?.company?.id),
    {enabled: !isInfo},
  );

  const billingHistory = useMemo(
    () => !historyLoading && billingHistoryData && billingHistoryData?.entity,
    [billingHistoryData, historyLoading],
  );
  const billingInfo = useMemo(
    () => !infoLoading && billingInfoData && billingInfoData?.entity,
    [billingInfoData, infoLoading],
  );

  localStorage.setItem('recon_plan', String(billingInfo?.plan));

  return {billingInfo, billingHistory, infoLoading, historyLoading};
};
