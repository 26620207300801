export const isObjectEmpty = obj => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
};
export const isObjectValueEmpty = obj =>
  Object.values(obj).every(value => value);
