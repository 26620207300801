import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {fetchAccounts} from '../../../../requests/queries/reconciliations';

export const useAccounts = ({slug}) => {
  const cleanSlug = slug && slug !== 'add-project' ? slug : '';
  const params = useMemo(
    () => ({
      project_id: cleanSlug,
    }),
    [cleanSlug],
  );
  const {data: accounts, isLoading} = useQuery(
    ['all-accounts', {params}],
    () => fetchAccounts({params}),
    {
      enabled: !!cleanSlug,
    },
  );

  const accountData = !isLoading && accounts && accounts?.rows;
  const accountSelectionData = useMemo(
    () =>
      (accountData &&
        accountData.map(account => ({
          label: `${account?.account_name} - ${account?.bank}`,
          value: account?.id,
          type: account?.account_type,
          currency: account?.currency,
          name: account?.account_name,
        }))) ||
      [],
    [accountData],
  );
  return {accountData, accountSelectionData, isLoading};
};
