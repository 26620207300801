import {
  AML_LOADING,
  GET_AML_HISTORY,
  GET_AML_HISTORIES,
  AML_LOOKUP_LOADING,
  GET_AML_PROFILE,
  AML_CONFIG_LOADING,
  GET_AML_CONFIG,
  GET_BATCH_AML_HISTORY,
} from '../types';

const initialState = {
  amlHistories: null,
  amlHistory: null,
  loading: null,
  lookupLoading: null,
  amlProfile: null,
  amlConfig: null,
  configLoading: null,
  batchAmlHistory: null,
};

export default function aml(state = initialState, action) {
  switch (action.type) {
    case AML_LOOKUP_LOADING:
      return {...state, lookupLoading: action.payload};
    case AML_CONFIG_LOADING:
      return {...state, configLoading: action.payload};
    case AML_LOADING:
      return {...state, loading: action.payload};
    case GET_AML_CONFIG:
      return {...state, amlConfig: action.payload};
    case GET_AML_PROFILE:
      return {...state, amlProfile: action.payload};
    case GET_AML_HISTORIES:
      return {...state, amlHistories: action.payload};
    case GET_AML_HISTORY:
      return {...state, amlHistory: action.payload};
    case GET_BATCH_AML_HISTORY:
      return {...state, batchAmlHistory: action.payload};

    default:
      return state;
  }
}
