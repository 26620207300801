import {useEffect, useState} from 'react';
import pollingService from '../../../../helpers/pollingService';

const useEndpointCaller = ({
  fetchFunc,
  canStart,
  setCanStart,
  interval = 5000,
  duration = 30000,
}) => {
  const [isPolling, setIsPolling] = useState(pollingService.getPollingStatus());

  useEffect(() => {
    const unsubscribe = pollingService.subscribe(pollingStatus => {
      setIsPolling(pollingStatus);
    });

    if (canStart && !pollingService.getPollingStatus()) {
      if (pollingService.getElapsedTime() < duration) {
        setCanStart(true);
      }
      pollingService.startPolling(
        fetchFunc,
        interval,
        duration - pollingService.getElapsedTime(),
      );
    }

    return () => unsubscribe();
  }, [fetchFunc, canStart, interval, duration, setCanStart]);

  useEffect(() => {
    if (
      pollingService.getElapsedTime() > duration &&
      pollingService.getPollingStatus()
    ) {
      pollingService.stopPolling();
      setCanStart(false);
    }
  }, [canStart, duration, setCanStart]);

  return {isRunning: isPolling};
};

export {useEndpointCaller};
