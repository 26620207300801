import React, {useEffect, useState} from 'react';

const GoogleTranslate = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const googleTranslateElementInit = () => {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            includedLanguages: 'pt,en',
            layout:
              window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            autoDisplay: true,
          },
          'google_translate_element',
        );
      }
    };

    const addScript = document.createElement('script');

    addScript.src =
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    addScript.async = true;
    addScript.onload = () => {
      setLoaded(true);
    };
    addScript.onerror = () => {
      console.error('Google Translate script loading failed');
    };
    document.body.appendChild(addScript);

    window.googleTranslateElementInit = googleTranslateElementInit;

    const googleTranslateElement = document.getElementById(
      'google_translate_element',
    );
    if (googleTranslateElement) {
      googleTranslateElement.addEventListener('click', event => {
        event.preventDefault();
      });
    }

    return () => {
      document.body.removeChild(addScript);
    };
  }, []);

  return (
    <div className="absolute z-20 right-[380px] top-[3px] w-[200px]  flex justify-center">
      <div id="google_translate_element" className=" google-translate-dropdown">
        {!loaded && <p>Loading translation options...</p>}
      </div>
    </div>
  );
};

export default GoogleTranslate;
