import React, {useCallback, useMemo, useState} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {
  CustomDateRadio,
  FilterWrapper,
  Radios,
  Checkboxes,
  FilterAccordion,
} from '../../../../components';
import {Constants} from '../../../../helpers';
import {customDateFilters} from '../../../../helpers/customDateFilters';

const STATUS_DATA = [
  {
    label: 'Allowed',
    value: 'allowed',
  },
  {
    label: 'Blocked',
    value: 'blocked',
  },
];

function WebhookFilter({...otherProps}) {
  const {
    openFilter,
    setOpenFilter,
    resetFilters,
    handleFilter,
    onCheck,
    appFilters,
    status = 'pending',
  } = otherProps;

  const [activeIndex, setActiveIndex] = useState(null);
  const [date, setDate] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [customDate, setCustomDate] = useState(false);
  const [dateOpen, setDateClick] = useState(false);
  const [count, setCount] = useState({});

  const maxDate = new Date();

  const handleClearFilters = useCallback(() => {
    setActiveIndex(null);
    setDate('');
    setStartDate(new Date());
    setEndDate(new Date());
    setCustomDate(false);
    setDateClick(false);
    resetFilters();
    setCount({});
  }, [resetFilters]);

  const onDateCheck = useCallback(
    e => {
      const {value} = e.target;
      customDateFilters({value, appFilters, onCheck});

      setDate(value);
      setCount({
        ...count,
        date: 1,
      });

      setCustomDate(false);
    },
    [appFilters, count, onCheck],
  );

  const onCustomDateCheck = e => {
    const {checked} = e.target;
    if (checked) {
      setDate(1);
      setCustomDate(true);
    }
  };

  const logsSearchInputs = useMemo(
    () => [
      {
        data: STATUS_DATA,
        filterName: 'status',
        onCheck: data => {
          onCheck(data);
        },
      },
    ],
    [onCheck],
  );
  return (
    <FilterWrapper
      resetFilters={handleClearFilters}
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      handleFilter={handleFilter}
      clearFilters
      appFilters={appFilters}
    >
      <FilterAccordion
        selectedCount={date === '' ? 0 : 1}
        filterName="date"
        onClick={() => setDateClick(!dateOpen)}
        activeIndex={dateOpen}
      >
        <Radios
          data={Constants.lookups.dates}
          onCheck={onDateCheck}
          radioValue={date}
        >
          <label
            htmlFor="custom_date"
            className="pl-4 relative flex flex-row items-center gap-2 font-normal transition ease-in-out duration-500"
          >
            <input
              type="radio"
              value="custom_date"
              id="custom_date"
              name="date"
              className="w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue"
              onClick={onCustomDateCheck}
            />
            <span className="text-sm text-grey">Specific date range</span>
          </label>
          {customDate && (
            <CustomDateRadio
              selectedStart={startDate}
              selectedEnd={endDate}
              onChangeStart={date => {
                const start = moment(date).format('YYYY-MM-DD HH:mm:ss');
                onCheck({...appFilters, start});
                setStartDate(date);
              }}
              onChangeEnd={date => {
                const end = moment(date).format('YYYY-MM-DD HH:mm:ss');
                onCheck({...appFilters, end});
                setEndDate(date);
              }}
              startDate={startDate}
              endDate={endDate}
              maxDate={maxDate}
              minDate={startDate}
            />
          )}
        </Radios>
      </FilterAccordion>

      {status.toLowerCase() !== 'pending' &&
        logsSearchInputs.map(({data, filterName, onCheck}, index) => {
          const isActive = activeIndex === index;

          return (
            <FilterAccordion
              activeIndex={isActive}
              key={filterName}
              filterName={filterName}
              selectedCount={count[filterName]}
              onClick={() => setActiveIndex(index)}
            >
              <Checkboxes
                data={data}
                values={data?.map(d => d.value) || []}
                onCheck={newData => {
                  const filterValues = Object.values(newData)[0]?.split(',');
                  onCheck(newData);
                  setCount({
                    ...count,
                    [filterName]: filterValues,
                  });
                }}
                filterName={filterName}
              />
            </FilterAccordion>
          );
        })}
    </FilterWrapper>
  );
}

export default connect(state => state, null)(WebhookFilter);
