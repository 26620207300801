import {
  useAnalysisChart,
  useOverview,
  useOverviewRecon,
} from './useReconQueries';

export const useAnalysisData = ({id, entity_id, date, ...rest}) => {
  const {overviewLoading, inFlow, outFlow} = useOverview({
    entity_id,
    id,
  });

  const {analysisChart, chartLoading} = useAnalysisChart({
    entity_id,
    id,
    period: date || 'day',
    ...rest,
  });
  const {analysisRecon, reconLoading} = useOverviewRecon({
    entity_id,
    id,
  });

  const getCount = type =>
    analysisRecon?.find(r => r?.transaction_recon?.toLowerCase() === type);

  const totalRecon = analysisRecon
    ?.map(({count}) => count)
    ?.reduce((a, b) => a + b, 0);

  return {
    overview: {overviewLoading, inFlow, outFlow},
    chartAnalysis: {analysisChart, chartLoading},
    reconAnalysis: {reconLoading, totalRecon, getCount},
  };
};
