import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {
  batchLookupImg,
  easyLookupIc,
  individualLookupImg,
} from '../../assets/images/images';
import * as Actions from '../../store/actions';
import {DashboardLayout, PrimaryButton} from '../../components';
import {PAGES_URL} from '../../helpers/constants';
import EasyLookupModals from './EasyLookupModals';
import {PageContext} from '../../helpers/context';

function EasyLookup({auth: {userDetails}}) {
  const [openIndividualLookup, setOpenIL] = useState(false);
  const [openBatchLookup, setOpenBL] = useState(false);

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('lookup');
  }, [setPageName]);

  const isOwner = userDetails?.permissions?.role === 'Owner';

  const {performSingleIndividualLookup, performBatchIndividualLookup} = useMemo(
    () => userDetails?.permissions?.lookupPermissions || {},
    [userDetails],
  );
  const performRight = isOwner ? false : !performSingleIndividualLookup;
  const performRightBatch = isOwner ? false : !performBatchIndividualLookup;

  const isCompanyVerified = userDetails?.company?.verified;

  return (
    <DashboardLayout
      bg="bg-white80"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyLookupIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Easy lookup</p>
        </div>
      }
    >
      <EasyLookupModals
        open={openIndividualLookup}
        setOpen={setOpenIL}
        lookupType="Individual"
        batch={false}
      />

      <EasyLookupModals
        open={openBatchLookup}
        setOpen={setOpenBL}
        lookupType="Batch"
        batch={true}
      />

      <div className="mt-4">
        <h1 className="text-grey text-xl font-medium">Easy Lookup</h1>
        <p className="mt-2 text-body text-sm">
          Lookup information about your users or businesses
        </p>
      </div>

      <div className="mt-12 flex flex-col sm:flex-row items-stretch gap-10 sm:gap-4 lg:gap-10">
        {performRight ? null : (
          <div className="bg-white rounded-3xl px-4 lg:px-6 pt-8 pb-0 flex flex-col items-start sm:max-w-[406px] w-full">
            <h2 className="text-grey text-lg font-semibold">
              Perform individual lookups
            </h2>
            <p className="mt-2 text-body">
              Verify your user identity with ease
            </p>
            <div className="w-full mt-10 flex flex-col lg:flex-row items-start lg:items-center gap-2">
              <PrimaryButton
                buttonText="Perform individual Lookup"
                onClick={() => {
                  if (!isCompanyVerified) {
                    toast.error(
                      'Your company has to be verified to perform a lookup',
                    );
                    setOpenIL(false);
                    return;
                  }

                  setOpenIL(true);
                }}
                className="w-full lg:w-fit"
              />
              <Link
                to={PAGES_URL.INDIVIDUAL_LOOKUP}
                className="w-full lg:w-auto text-sm text-grey font-medium bg-white80 pt-[18px] pb-[14px] flex items-center justify-center h-[46px] px-6 outline outline-1 outline-grey80 rounded"
              >
                View history
              </Link>
            </div>
            <div className="mt-auto lg:mt-14 flex justify-center w-full">
              <img src={individualLookupImg} alt="" width={347} height={224} />
            </div>
          </div>
        )}

        {performRightBatch ? null : (
          <div className="bg-white rounded-3xl px-4 lg:px-6 pt-8 pb-0 flex flex-col items-start sm:max-w-[406px] w-full">
            <h2 className="text-grey text-lg font-semibold">
              Perform Batch lookup
            </h2>
            <p className="mt-2 text-body">
              Upload and verify multiple users seamlessly
            </p>
            <div className="w-full mt-10 flex flex-col lg:flex-row items-start lg:items-center gap-2">
              <PrimaryButton
                buttonText="Perform batch Lookup"
                onClick={() => {
                  if (!isCompanyVerified) {
                    toast.error(
                      'Your company has to be verified to perform a lookup',
                    );
                    setOpenBL(false);
                    return;
                  }
                  setOpenBL(true);
                }}
                className="w-full lg:w-fit"
              />
              <Link
                to={PAGES_URL.BATCH_LOOKUP}
                className="w-full lg:w-auto text-sm text-grey font-medium bg-white80 pt-[18px] pb-[14px] flex items-center justify-center h-[46px] px-6 outline outline-1 outline-grey80 rounded"
              >
                View history
              </Link>
            </div>
            <div className="mt-14 flex justify-center w-full">
              <img src={batchLookupImg} alt="" width={329} height={236} />
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(EasyLookup);
