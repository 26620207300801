import React, {useState} from 'react';
import Layout from '../Layout';
import {
  Accordion,
  EmptyState,
  Loader,
  PrimaryButton,
} from '../../../components';
import {corruptedFile} from '../../../assets/images/images';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {fetchGovernmentData} from '../../../requests/queries/customer360';
import {
  getCountryLogo,
  getCountryName,
  IGNORE_GOV_DATA_KEYS,
} from '../LayoutConstants';
import EasyLookupModals from '../../EasyLookup/EasyLookupModals';
import moment from 'moment';

function GovernmentLookup() {
  const {slug} = useParams();

  const {data: governmentData, isLoading: governmentDataLoading} = useQuery(
    ['government-data', slug],
    () => fetchGovernmentData(slug),
    {
      enabled: !!slug,
      retry: false,
    },
  );

  const [openLookupModal, setOpenLookupModal] = useState(false);
  const GOVERNMENT_DATA = !governmentDataLoading && governmentData;

  const IGNORE_KEYS = ['global_aml', 'nigeria_verifi_service'];
  const IMG_KEYS = ['photo', 'image', 'image_url'];
  const DATE_KEYS = ['createdAt', 'updatedAt', 'date_created', 'date_updated'];
  const formatDate = (key, value) => {
    switch (key) {
      case 'createdAt':
        return {
          text: 'DATE/TIME CREATED',
          value: moment(value).format('DD MMM YYYY h:m a'),
        };
      case 'updatedAt':
        return {
          text: 'DATE/TIME UPDATED',
          value: moment(value).format('DD MMM YYYY h:m a'),
        };

      default:
        return {value: moment(value).format('DD MMM YYYY h:m a')};
    }
  };

  const imgSortFunction = ([a, b]) =>
    IMG_KEYS.includes(a) ? -1 : IMG_KEYS.includes(b) ? 1 : a - b;

  // we're filtering out the nigeria_verifi_service and global_aml keys
  const governmentDataLength = Object.keys(
    GOVERNMENT_DATA?.entity || {},
  ).filter(key => !IGNORE_KEYS.includes(key)).length;

  const countryName = getCountryName(governmentData?.entity);

  return (
    <Layout
      pageTitle="Government Lookup"
      action={
        <PrimaryButton
          buttonText="Perform Lookup"
          onClick={() => setOpenLookupModal(true)}
        />
      }
    >
      <EasyLookupModals
        open={openLookupModal}
        setOpen={setOpenLookupModal}
        lookupType="Individual"
        batch={false}
        customer360={true}
        customer_id={slug}
        customer360Dropdown
      />

      {governmentDataLoading ? (
        <Loader />
      ) : !governmentDataLoading && governmentDataLength === 0 ? (
        <div className="bg-white80 w-full">
          <EmptyState
            body="There is no information to display here at the moment"
            src={corruptedFile}
            height={45}
            imageHeight={48}
            imageWidth={48}
            bodyMaxWidth="max-w-[179px]"
            buttonText="Perform Lookup"
            onClick={() => setOpenLookupModal(true)}
          />
        </div>
      ) : (
        <div className="space-y-4">
          {GOVERNMENT_DATA &&
            Object.entries(GOVERNMENT_DATA?.entity)
              .filter(([key]) => !IGNORE_KEYS.includes(key))
              .map(([key, value], index) => {
                const fieldName = key.split('_')[1];
                const countryAbb = key.split('_')[0];
                const open = index !== 0;
                return (
                  value && (
                    <Accordion
                      key={index}
                      title={
                        fieldName === 'phone'
                          ? 'Phone Number'
                          : fieldName.toUpperCase()
                      }
                      border
                      customClasses="rounded-xl"
                      isOpen={open}
                      customComponent={
                        <div className="flex items-center gap-2 rounded-full px-2 py-1 outline outline-1 outline-grey-50 bg-white80">
                          <p className="text-deepBlue text-xs font-medium uppercase -mb-1">
                            {countryName}
                          </p>
                          <div className="w-5 h-5 flex items-center justify-center bg-blue-50 outline outline-1 outline-blue-100 rounded-full">
                            <img
                              src={getCountryLogo(countryAbb?.toLowerCase())}
                              alt=""
                              width={12}
                              height={8}
                            />
                          </div>
                        </div>
                      }
                      customChildrenClasses="mt-2 border-t border-grey-50"
                      customPadding="px-6 py-5"
                      noChildPadding
                      customTitleSize="text-lg"
                      customTitleColor="text-grey"
                    >
                      <div className="w-full inline-grid grid-cols-[auto_auto_auto_auto] items-center gap-10 justify-between pl-8 pr-10 pt-6 pb-14">
                        {Object.entries(value || {})
                          .sort(imgSortFunction)
                          ?.map(
                            ([key, value], index) =>
                              !IGNORE_GOV_DATA_KEYS.includes(key) &&
                              value && (
                                <div
                                  key={index}
                                  className="flex flex-col gap-2"
                                >
                                  {!IMG_KEYS.includes(key) && (
                                    <p className="text-grey-700 text-xs font-medium uppercase">
                                      {DATE_KEYS.includes(key)
                                        ? formatDate(key, value)?.text
                                        : key.replace(/_/g, ' ')}
                                    </p>
                                  )}

                                  {IMG_KEYS.includes(key) ? (
                                    <img
                                      src={
                                        value.includes('https')
                                          ? value
                                          : `data:image/jpeg;base64,${value}`
                                      }
                                      alt="Image"
                                      width={89}
                                      // height={89}
                                      className="rounded"
                                    />
                                  ) : (
                                    <p className="text-grey text-sm font-medium">
                                      {DATE_KEYS.includes(key)
                                        ? formatDate(key, value)?.value
                                        : value}
                                    </p>
                                  )}
                                </div>
                              ),
                          )}
                      </div>
                    </Accordion>
                  )
                );
              })}
        </div>
      )}
    </Layout>
  );
}

export default React.memo(GovernmentLookup);
