import {
  LOGOUT_USER,
  LOADING,
  SET_USER,
  UPDATED,
  SET_USER_PROFILE,
  REGENERATE_API_KEY,
  SET_AUDIT_TRAIL,
  SET_BILLINGS,
  GET_PAYMENT_DETAILS,
  GET_ACCOUNT_NUMBER,
  BILLINGS_API_CALLS,
  UPDATE_COMPANY_INFO,
  SET_USER_SETTINGS,
} from '../types';

const initialState = {
  loading: null,
  user: null,
  userDetails: null,
  regeneratedKey: null,
  auditTrail: null,
  billings: null,
  paymentDetails: null,
  billingsApiCalls: null,
  userSettings: null,
  wallet: null,
  updated: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {...state, loading: action.payload};
    case UPDATED:
      return {...state, updated: !state.updated};
    case SET_USER:
      return {...state, user: action.payload};
    case SET_BILLINGS:
      return {...state, billings: action.payload};
    case SET_USER_SETTINGS:
      return {...state, userSettings: action.payload};
    case BILLINGS_API_CALLS:
      return {...state, billingsApiCalls: action.payload};
    case UPDATE_COMPANY_INFO:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          company: {...state.userDetails.company, ...action.payload},
        },
      };
    case GET_PAYMENT_DETAILS:
      return {...state, paymentDetails: action.payload};
    case GET_ACCOUNT_NUMBER:
      return {...state, wallet: action.payload};
    case SET_AUDIT_TRAIL:
      return {...state, auditTrail: action.payload};
    case REGENERATE_API_KEY:
      return {
        ...state,
        regeneratedKey: {...state.regeneratedKey, ...action.payload},
      };

    case SET_USER_PROFILE:
      // setEnvCookie(action.payload?.company?.verified);
      return {...state, userDetails: action.payload};

    case LOGOUT_USER:
      return {...initialState};
    default:
      return state;
  }
}
