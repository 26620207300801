import React from 'react';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';
import moment from 'moment';
import {Modal, PrimaryButton, TableLayout} from '../../components';
import {closeModal} from '../../assets/images/images';
import {getStatusColor} from '../../helpers';
import {fetchBatchVerificationCount} from '../../requests/queries/address-verification';

export default function ViewBatchModal({open, setOpen, selectedData}) {
  const {data: batchVerificationCount, isLoading} = useQuery(
    ['batch-verification-count', {id: selectedData?.id}],
    () => fetchBatchVerificationCount(selectedData?.id),
    {
      enabled: selectedData?.id !== undefined && open,
      onError: error => {
        toast.error(error.response.data.error);
      },
    },
  );

  const percentage =
    batchVerificationCount?.completed === 0
      ? 0
      : (selectedData?.total_count / batchVerificationCount?.completed) * 100;

  const handleDownloadClick = () => {
    if (selectedData?.response_link === null) {
      toast.error("Verification is not complete. You can't download yet.");
    } else {
      const downloadLink = document.createElement('a');
      downloadLink.href = selectedData?.response_link;
      document.body.appendChild(downloadLink);
      downloadLink.download = selectedData?.file_name;

      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-4 pt-10 pb-4 sm:p-10">
        <header className="flex items-center justify-between">
          <div className="flex flex-wrap items-center gap-2">
            <h3 className="text-base font-medium text-grey">
              Batch Verification
            </h3>
            <div className="px-2 py-1 text-xs font-medium uppercase rounded-full text-brandBlue50 bg-brandBlue40 w-fit">
              Batch upload
            </div>
            <div
              className={`uppercase py-1 px-2 text-xs font-medium  rounded-full w-fit ${getStatusColor(
                selectedData?.status,
              )}`}
            >
              {selectedData?.status}
            </div>
          </div>
          <button
            onClick={() => {
              setOpen(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </header>
        <div
          className={`relative h-4 mt-4 mb-8 text-xs font-medium transition-all duration-300 ${
            percentage > 50 ? 'text-white' : ''
          }
         rounded-full bg-brandBlue40`}
        >
          <span className="absolute left-0 right-0 top-1">
            {isLoading ? '-' : percentage}%
          </span>
          <progress id="file" value={percentage} max="100"></progress>
        </div>
        <TableLayout>
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                number of address
              </th>
              <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                Last checked
              </th>
            </tr>
          </thead>
          <tbody className="text-sm">
            <tr className="text-left border-b cursor-pointer border-grey60">
              <td className="p-4 pl-0 whitespace-nowrap">
                {selectedData?.total_count}
              </td>
              <td className="p-4 pl-0 whitespace-nowrap">
                {moment(
                  selectedData?.date_updated === null
                    ? selectedData?.date_created
                    : selectedData?.date_updated,
                ).format('DD MMM YYYY HH:mm')}
              </td>
            </tr>
          </tbody>
        </TableLayout>
        <div className="print:flex print:items-center print:justify-between text-xs text-body uppercase mt-8 mb-3 px-[5.88%]">
          <span>Performed by: Ayomide Oso</span>
          <span className="ml-8">{selectedData?.dateUploaded}</span>
        </div>
        <div className="flex flex-col items-center gap-4 mt-6 sm:flex-row">
          <button className="flex items-center justify-between sm:justify-start gap-4 py-[14px] px-4 text-tiny text-grey80 bg-white80 outline outline-1 outline-grey60 rounded w-full sm:w-fit focus-visible:outline-brandBlue">
            XLSX
          </button>
          <PrimaryButton
            buttonText="Download"
            className="flex-1 w-full py-4 rounded sm:w-fit"
            onClick={handleDownloadClick}
          />
        </div>
      </div>
    </Modal>
  );
}
