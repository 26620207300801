/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import {connect} from 'react-redux';

const UploadOtherIds = ({
  widgets: {widgetPages, widgetScreen},
  customButton,
}) => {
  const widgetData =
    widgetPages &&
    widgetPages?.find(
      widget =>
        widget?.page === 'additional-document' &&
        widget?.pageId === widgetScreen?.pageId,
    );

  return (
    <>
      <header className="flex flex-col items-center my-4">
        <p className="mt-8 text-center font-medium text-grey">
          Upload document
        </p>
        <div className="flex items-center gap-2 pt-[5px] pb-[3px] px-2 bg-batchBlue20 mt-8 rounded-full">
          <p className="text-[11px] font-medium rounded-lg text-batchBlue">
            This looks good
          </p>
        </div>
      </header>
      <div className="border border-dotted border-brandBlue bg-white80 px-8 py-22 rounded max-w-[326px] w-full flex flex-col items-center justify-center">
        <p className="text-sm font-medium text-grey">
          Drag and drop your logo here
        </p>
        <p className="text-sm text-body">
          or <span className="text-brandBlue">click here to select</span> from
          your computer
        </p>
      </div>
      <div className=" w-full flex flex-col justify-center items-center ">
        <p className="mt-5 text-center text-body text-sm max-w-[326px]">
          {widgetData?.config?.instruction}
        </p>
        {customButton}
      </div>
    </>
  );
};

export default connect(state => state, null)(UploadOtherIds);
