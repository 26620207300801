import React, {useState} from 'react';
import cn from 'classnames';
import {dropdownGrey} from '../assets/images/images';

export default function Accordion({
  isOpen = true,
  border = false,
  title,
  onClick,
  children,
  customTitle,
  background = 'bg-white',
  customClasses = '',
  customChildrenClasses = '',
  customComponent,
  noChildPadding = false,
  customPadding = 'p-4',
  customTitleSize = 'text-sm',
  customTitleColor = 'text-body',

  ...props
}) {
  const [isActive, setIsActive] = useState(isOpen);

  const accordionClasses = cn(
    'flex',
    'flex-col',
    'rounded',
    {'pb-0': isActive, 'pb-4': !isActive},
    {border: border, 'border-grey-50': border},
    {background: background, 'bg-white': background === 'bg-white'},
    customClasses,
  );

  const containerClasses = cn(
    'flex',
    'items-center',
    'justify-between',
    'cursor-pointer',
    {'mb-2': !isActive, 'pb-0': !isActive},
    customPadding,
  );

  const childrenClasses = cn(
    background,
    {hidden: isActive},
    customChildrenClasses,
    {'px-4': !noChildPadding},
    {'p-0': noChildPadding},
  );

  return (
    <div
      className={accordionClasses}
      onClick={onClick ? () => onClick(isActive) : null}
      {...props}
    >
      <div
        tabIndex={0}
        className={containerClasses}
        onClick={() => setIsActive(!isActive)}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsActive(!isActive);
          }
        }}
      >
        {customTitle ? (
          customTitle
        ) : (
          <p
            className={`-mb-1 ${
              isActive ? customTitleColor : 'text-grey font-medium'
            } ${customTitleSize}
          `}
          >
            {title}
          </p>
        )}
        {customComponent ? (
          <div className="flex items-center gap-4">
            {customComponent}
            <img
              src={dropdownGrey}
              alt="dropdown"
              className={`${
                isActive ? 'rotate-0 ease-out' : 'rotate-180 ease-in'
              } duration-200 transition`}
              width={16}
              height={16}
            />
          </div>
        ) : (
          <img
            src={dropdownGrey}
            alt="dropdown"
            className={`${
              isActive ? 'rotate-0 ease-out' : 'rotate-180 ease-in'
            } duration-200 transition`}
            width={16}
            height={16}
          />
        )}
      </div>
      {/* {isActive && <div className={`px-4 ${background}`}>{children}</div>} */}
      <div className={childrenClasses}>{children}</div>
    </div>
  );
}
