import React, {useContext, useEffect} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {DashboardLayout, Loader, TableLayout} from '../../components';
import * as Actions from '../../store/actions';
import {
  amlScreeningIc,
  chevronLeft,
  corruptedFile,
  eye,
} from '../../assets/images/images';
import {connect} from 'react-redux';
import {Constants, getSubCategoriesColor} from '../../helpers';
import {PageContext} from '../../helpers/context';

function AmlSearchResults({dummyData, aml: {amlHistory}, loading}) {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.pathname?.includes('business') ? 'business' : 'user';

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('amllookup');
  }, [setPageName]);

  const individualName =
    amlHistory?.entity?.user &&
    `${amlHistory?.entity?.user?.first_name || '-'} ${
      amlHistory?.entity?.user?.middle_name || ' '
    } ${amlHistory?.entity?.user?.last_name || '-'}`;

  const businessName =
    amlHistory?.entity?.profile_ids && amlHistory?.entity?.profile_ids[0]?.name;

  const SearchResults = amlHistory?.entity?.profile_ids;

  const viewResult = result => {
    sessionStorage.setItem('amlType', type);
    navigate(`/aml-screening/result/${result.profile_id || result.ProfileId}`);
  };

  const renderCategories = result =>
    result.entry_category?.split(',').map((category, index, categories) => {
      const trimmedCategory = category.trim();
      if (index < 2) {
        return (
          <span
            key={index}
            className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit ${getSubCategoriesColor(
              trimmedCategory,
            )}`}
          >
            {trimmedCategory?.replace(/-/g, ' ') || '-'}
          </span>
        );
      } else if (index === 2 && categories.length > 2) {
        const remainingCount = categories.length - 2;
        return (
          <span
            key={index}
            className="px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit bg-statusBg-400 text-status-400"
          >
            +{remainingCount}
          </span>
        );
      }
      return null;
    });

  return (
    <DashboardLayout
      bg="bg-white80"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={amlScreeningIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">AML Screening</p>
        </div>
      }
    >
      <div className="flex items-center justify-between mt-6 sm:mt-0">
        <Link
          to={Constants.PAGES_URL.AML_BUSINESS_SCREENING}
          className="flex items-end gap-2"
        >
          <img src={chevronLeft} alt="" width={16} height={16} />
          <span className="text-sm font-medium text-grey80 -mb-1">Back</span>
        </Link>
      </div>
      {loading ? (
        <Loader height={25} />
      ) : (
        <div className="mt-4 flex flex-col gap-4">
          {!dummyData && (individualName || businessName) ? (
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1">
                <h3 className="text-lg font-medium text-grey">
                  {individualName || businessName}
                </h3>
              </div>
              <div className="flex items-center gap-1">
                <p className="text-sm text-[#868686]">Search results</p>
                <span className="w-5 h-5 bg-brandBlue flex items-center justify-center rounded-full text-white text-xs font-medium mb-1">
                  {amlHistory?.entity?.profile_ids?.length || 0}
                </span>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-1">
              <h3 className="text-lg font-medium text-grey">
                {dummyData || 'John Doe'}
              </h3>
              <span className="w-5 h-5 bg-brandBlue flex items-center justify-center rounded-full text-white text-xs font-medium">
                {0}
              </span>
            </div>
          )}

          <TableLayout className="mt-2" negativeMargins>
            <thead className="text-xs font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                  name
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  gender
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  date of birth
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  country
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  sub-categories
                </th>
                <th className="w-[18.69%]" />
              </tr>
            </thead>
            <tbody className="text-sm">
              {!dummyData && SearchResults?.length > 0 ? (
                SearchResults?.map((result, i) => (
                  <tr
                    className="border-b cursor-pointer border-grey60 bg-white"
                    key={i}
                  >
                    <td
                      className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body"
                      onClick={() => viewResult(result)}
                    >
                      {result?.name || result?.Name}
                    </td>
                    <td className="p-4 pl-0" onClick={() => viewResult(result)}>
                      {result?.gender || '-'}
                    </td>
                    <td
                      className="p-4 pl-0 text-tiny text-body"
                      onClick={() => viewResult(result)}
                    >
                      {result?.dob || '-'}
                    </td>
                    <td
                      className="p-4 pl-0 text-tiny text-body"
                      onClick={() => viewResult(result)}
                    >
                      {result.country
                        ? result?.country?.split(',')?.slice(0, 2)?.join(', ')
                        : '-'}
                      {result?.country?.split(',')?.length > 2 ? (
                        <>
                          {', '}
                          <span className="text-[#3F7CDB]">
                            +{result?.country?.split(',')?.length - 2}
                          </span>
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                    <td
                      className="p-4 pl-0 text-tiny text-body"
                      onClick={() => viewResult(result)}
                    >
                      <div className="flex items-center gap-1">
                        {renderCategories(result)}
                      </div>
                    </td>
                    <td className="p-4 pl-0" onClick={() => viewResult(result)}>
                      <div className="flex items-center gap-4">
                        <button
                          title="View"
                          onClick={() => viewResult(result)}
                          className="h-4 aspect-square"
                        >
                          <img src={eye} alt="" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="p-4 w-full">
                  <div className="flex flex-col items-center justify-center py-12 mt-6 bg-white80">
                    <img src={corruptedFile} alt="" width={48} height={48} />
                    <p className="max-w-[179px] mt-2 text-sm text-center text-body">
                      We did not find any information on this person
                    </p>
                  </div>
                </div>
              )}
            </tbody>
          </TableLayout>
        </div>
      )}
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(AmlSearchResults);
