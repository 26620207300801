import React from 'react';
import {connect} from 'react-redux';
import {Link, NavLink, useParams} from 'react-router-dom';
import {chevronRight, customer360Ic} from '../../assets/images/images';
import {DashboardLayout} from '../../components';
import {Constants} from '../../helpers';

import {ROUTES} from './LayoutConstants';
import ProfileAndCommentSection from './ProfileSection';
import {useQuery} from 'react-query';
import {fetchCustomerDetails} from '../../requests/queries/customer360';

function Layout({children, pageTitle = '', action}) {
  const {slug} = useParams();

  const {data: userDetails, isLoading: userDetailsLoading} = useQuery(
    'user-details',
    () => fetchCustomerDetails(slug),
    {retry: false},
  );

  return (
    <DashboardLayout
      xlLeftMargin="xl:ml-12"
      xlRightPadding="xl:pr-0"
      overFlow="overflow-none"
      breadCrumbs={
        <div className="flex items-center">
          <img src={customer360Ic} alt="" width={18} height={18} />
          <Link className="ml-2 -mb-1" to={Constants.PAGES_URL.CUSTOMER_360}>
            Customer 360
          </Link>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1 cursor-pointer">{pageTitle}</p>
        </div>
      }
    >
      <div className="flex items-center justify-between gap-6 mt-6 mb-6 md:gap-0 sm:mt-0 mr-[76px]">
        <h2 className="hidden text-lg font-medium text-grey sm:block">
          {pageTitle}
        </h2>
        <h2 className="block text-lg font-medium text-grey sm:hidden">
          {pageTitle}
        </h2>

        <div className="flex ">
          {action ?? null}
          {/*<DownloadPDF*/}
          {/*  companyLogo={appLogo}*/}
          {/*  profileData={USER_PROFILE}*/}
          {/*  GOVERNMENT_LOOKUP_DATA={pdfData?.GOVERNMENT_LOOKUP_DATA}*/}
          {/*  GOVERNMENT_ID_DATA={pdfData?.GOVERNMENT_ID_DATA}*/}
          {/*  IP_DATA={pdfData?.IP_DATA}*/}
          {/*  LIVENESS_DATA={pdfData?.LIVENESS_DATA}*/}
          {/*  isLoading={pdfData?.isLoading}*/}
          {/*/>*/}
        </div>
      </div>

      <div className="mt-4 rounded-xl bg-white p-1 border border-[#E1E4EA] flex items-center gap-2 overflow-x-auto mr-[76px]">
        {ROUTES.map((route, index) => (
          <NavLink
            key={index}
            end={false}
            to={route.path.replace(':slug', slug)}
            className={({isActive}) =>
              `pt-[13px] pb-[7px] px-4 text-sm  flex items-center justify-center rounded-lg shadow-[0px_4px_32px_-4px,rgba(199,196,196,0.18)] ${
                isActive
                  ? 'text-dojahBlue bg-blue-100'
                  : 'text-grey-500 bg-transparent'
              }`
            }
          >
            {route.name}
          </NavLink>
        ))}
      </div>

      <div className="mt-4 flex w-full">
        <div className="w-[65%]">{children}</div>
        <ProfileAndCommentSection
          userDetails={userDetails}
          userDetailsLoading={userDetailsLoading}
        />
      </div>
    </DashboardLayout>
  );
}

export default connect(state => state, null)(Layout);
