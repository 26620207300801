import {CREDIT_CHECKS_LOADING, GET_CREDIT_CHECKS} from '../types';
import reduxApi from '../../helpers/reduxApi';

export const getCreditChecks = () =>
  reduxApi(
    `/users/widget/lookup/individual/credit`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_CREDIT_CHECKS});
      },
    },
    {
      error: 'ERROR',
      loading: CREDIT_CHECKS_LOADING,
      responder: GET_CREDIT_CHECKS,
    },
  );
