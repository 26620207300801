import React from 'react';
import Lottie from 'lottie-react';
import comingSoonLottie from '../assets/lotties/coming-soon.json';
import {EmptyState} from '../components';
import DashboardLayout from '../components/DashboardLayout';

const style = {
  height: 174,
  width: 174,
};
export default function LinkAccounts() {
  return (
    <DashboardLayout bg="bg-white">
      <EmptyState
        noBtn
        heading="Coming soon"
        body="We are excited about what we are building and we are sure you would love it."
        bodyMaxWidth="max-w-[270px]"
        lottie={
          <Lottie loop={true} animationData={comingSoonLottie} style={style} />
        }
      />
    </DashboardLayout>
  );
}
