import React, {useCallback, useMemo, useState} from 'react';
import Select from 'react-select';
import {
  Modal,
  PrimaryButton,
  DropdownIndicator,
  customStyles,
} from '../../../components';
import {closeModal} from '../../../assets/images/images';
import {useMutation, useQueryClient} from 'react-query';
import {createFlow} from '../../../requests/queries/flows';
import {toast} from 'react-toastify';

export const USE_CASES = [
  {value: 'bank transaction', label: 'BANK TRANSACTION'},
  {value: 'card transaction', label: 'CARD TRANSACTION'},
];

const initialFlowData = {
  usecase: '',
  name: '',
  desc: '',
};

export default function CreateFlowModal({open, setOpen, companyId}) {
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [flowData, setFlowData] = useState({});
  const queryClient = useQueryClient();

  const {mutate, isLoading} = useMutation('create-e-flow', createFlow);

  const handleCreateFlow = useCallback(async () => {
    try {
      const data = {
        ...flowData,
        company_id: companyId,
      };
      await mutate(data, {
        onSuccess: () => {
          setSelectedUseCase(null);
          setFlowData(initialFlowData);
          queryClient?.invalidateQueries();
          setOpen(false);
          toast.success('Flow created Successfully!');
        },
        onError: error => {
          toast.error(error?.response?.data?.error);
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [companyId, flowData, mutate, queryClient, setOpen]);

  const isEmpty = useMemo(
    () => !flowData?.name || !flowData?.desc || !flowData?.usecase || isLoading,
    [flowData?.desc, flowData?.name, flowData?.usecase, isLoading],
  );

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-base font-medium text-grey">Create a flow</h3>
          <button onClick={() => setOpen(false)}>
            <img src={closeModal} alt="" width={24} height={24} />
          </button>
        </header>
        <div className="mt-6 text-left">
          <label htmlFor="flow_name" className="mt-6">
            <span className="text-sm ">Flow name</span>
            <input
              onChange={e => setFlowData({...flowData, name: e.target.value})}
              type="text"
              id="name"
              className="mt-2"
              required
              value={flowData?.name}
            />
          </label>

          <label htmlFor="flow_use_cases" className="mt-6 mb-2 relative">
            Use case
          </label>
          <Select
            defaultValue={selectedUseCase}
            onChange={data => {
              setSelectedUseCase(data);
              setFlowData({...flowData, usecase: data.value});
            }}
            options={USE_CASES}
            placeholder="Select a use case"
            className="basic-multi-select"
            classNamePrefix="react-select"
            components={{DropdownIndicator}}
            styles={customStyles}
          />

          <label htmlFor="flow_description" className="mt-6 relative">
            <p>
              Description
              {/*<span className="text-grey30">(optional)</span>*/}
            </p>
            <textarea
              id="description"
              className="mt-2 min-h-[100px] h-full"
              onChange={e => setFlowData({...flowData, desc: e.target.value})}
              value={flowData?.desc}
            />
          </label>

          <div className="flex items-center mt-6">
            <PrimaryButton
              onClick={handleCreateFlow}
              buttonText="Continue"
              className="w-full"
              loading={isLoading}
              disabled={isEmpty}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
