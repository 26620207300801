import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import {DashboardLayout, PrimaryButton} from '../../components';
import {PAGES_URL} from '../../helpers/constants';
import {PageContext} from '../../helpers/context';
import SingleAnalysisModal from './SingleAnalysisModal';

import {easyLookupIc} from '../../assets/images/images';
import {PerformBtn, ViewLink} from '../../components/lookupComponents';
import {DocumentAnalysis1} from './_DocumentAnalysisImage1';
import {DocumentAnalysis2} from './_DocumentAnalysisImage2';

function DocumentAnalysis({auth: {userDetails}}) {
  const [openSingleAnalysis, setOpenSA] = useState(false);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('document-analysis');
  }, [setPageName]);

  const isCompanyVerified = userDetails?.company?.verified;

  const performSingleAnalysis =
    userDetails?.permissions?.documentAnalysisPermissions?.performSingleLookup;

  const viewHistory =
    userDetails?.permissions?.documentAnalysisPermissions?.viewSingleLookup;

  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyLookupIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Document Analysis</p>
        </div>
      }
    >
      <SingleAnalysisModal open={openSingleAnalysis} setOpen={setOpenSA} />

      <div className="mt-4">
        <h1 className="text-grey text-xl font-medium">Document Analysis</h1>
      </div>

      <div className="mt-7 flex flex-col sm:flex-row items-stretch gap-10 sm:gap-4 lg:gap-10">
        <div className="bg-white80 rounded-3xl px-4 lg:px-6 pt-8 pb-0 flex flex-col items-start sm:max-w-[406px] w-full">
          <h2 className="text-status-400 text-lg font-semibold">
            Perform Single Analysis
          </h2>
          <p className="mt-2 text-grey20 tracking-tight">
            Verify your user identity with ease
          </p>
          <div className="w-full mt-10 flex flex-col lg:flex-row items-start lg:items-center gap-2">
            <PerformBtn
              setOpen={setOpenSA}
              permitted={performSingleAnalysis}
              isCompanyVerified={isCompanyVerified}
              btnText="Perform Single Analysis"
            />

            <ViewLink
              permitted={viewHistory}
              route={PAGES_URL.DOCUMENT_ANALYSIS_SINGLE}
            />
          </div>
          <div className="mt-auto lg:mt-14 flex justify-center w-full">
            <DocumentAnalysis1 />
          </div>
        </div>

        <div className="bg-white80 rounded-3xl px-4 lg:px-6 pt-8 pb-8 flex flex-col items-start sm:max-w-[406px] w-full">
          <div className="flex items-center gap-2 justify-between">
            <h2 className="text-status-400 text-lg font-semibold">
              Perform Batch Analysis
            </h2>
            <div className="px-1 pt-1 text-xs font-medium uppercase rounded-full w-fit text-batchBlue bg-batchBlue20">
              soon
            </div>
          </div>
          <p className="mt-2 text-grey20 tracking-tight">
            Upload and verify multiple users seamlessly
          </p>
          <div className="w-full mt-10 flex flex-col lg:flex-row items-start lg:items-center gap-2 pointer-events-none opacity-70">
            <PrimaryButton
              buttonText="Perform Batch Analysis"
              className="w-full lg:w-fit"
            />
            <Link
              to={PAGES_URL.DOCUMENT_ANALYSIS_BATCH}
              className="w-full lg:w-auto text-sm text-grey font-medium bg-white80 pt-[18px] pb-[14px] flex items-center justify-center h-[46px] px-6 outline outline-1 outline-grey80 rounded"
            >
              View History
            </Link>
          </div>
          <div className="mt-14 flex justify-center w-full opacity-70">
            <DocumentAnalysis2 />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(DocumentAnalysis);
