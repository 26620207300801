import React from 'react';
import {css} from '@emotion/react';
import {connect} from 'react-redux';
import PuffLoader from 'react-spinners/PuffLoader';
import {closeModal} from '../../assets/images/images';
import {Modal, SecondaryButton} from '../../components';
import * as Actions from '../../store/actions';

function ToggleOngoingMonitoring({
  open,
  setOpen,
  loading,
  toggleOngoingMonitoring,
}) {
  const amlData = JSON.parse(localStorage.getItem('amlData') || '{}');
  const isOn = amlData && amlData?.ongoing_monitoring;
  const handleConfirm = () => {
    if (amlData) {
      toggleOngoingMonitoring(
        {
          ...amlData,
          ongoing_monitoring: !amlData?.ongoing_monitoring,
        },
        setOpen,
      );
      localStorage.setItem(
        'amlData',
        JSON.stringify({
          ...amlData,
          ongoing_monitoring: !amlData?.ongoing_monitoring,
        }),
      );
    }
  };

  const override = css`
    border-color: #ffffff;
    position: absolute;
    background: transparent;
  `;

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-grey text-base font-medium">
            Confirm ongoing monitoring {isOn ? 'off' : 'on'}
          </h3>
          <button onClick={() => setOpen(false)}>
            <img src={closeModal} alt="" />
          </button>
        </header>

        <p className="mt-4 text-sm text-body text-left">
          By turning {isOn ? 'off' : 'on'} ongoing monitoring this means you
          will {isOn ? 'stop' : 'be'} getting updated information about the
          user(s)
        </p>

        <div className="mt-6 flex items-center gap-4">
          <SecondaryButton buttonText="Cancel" onClick={() => setOpen(false)} />
          <button
            onClick={handleConfirm}
            className={` ${
              loading
                ? 'flex items-center justify-center relative h-12 px-4 py-6'
                : ''
            } text-white text-sm font-medium bg-brandBlue py-4 px-[81px] rounded`}
          >
            {loading ? (
              <PuffLoader css={override} size={35} color="#ffffff" />
            ) : (
              'Confirm'
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, Actions)(ToggleOngoingMonitoring);
