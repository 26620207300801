import {
  CREDIT_CHECKS_LOADING,
  GET_CREDIT_CHECKS,
  CREDIT_CHECKS_SUMMARY,
  CREDIT_CHECKS_LOOKUP_LOADING,
} from '../types';

const initialState = {
  creditHistory: null,
  checksLoading: null,
  checksSummary: null,
  checksLookupLoading: null,
};

export default function creditCheck(state = initialState, action) {
  switch (action.type) {
    case CREDIT_CHECKS_LOADING:
      return {...state, checksLoading: action.payload};
    case CREDIT_CHECKS_LOOKUP_LOADING:
      return {...state, checksLookupLoading: action.payload};
    case GET_CREDIT_CHECKS:
      return {...state, creditHistory: action.payload};
    case CREDIT_CHECKS_SUMMARY:
      return {...state, checksSummary: action.payload};

    default:
      return state;
  }
}
