import React, {useState, useMemo, useEffect} from 'react';
import Select from 'react-select';
import {
  DropdownIndicator,
  customStyles,
  Modal,
  PrimaryButton,
} from '../../../components';
import DeactivateMemberModal from './DeactivateMemberModal';
import AssignApps from './AssignApps';

export default function EditMemberModal({
  open,
  setOpen,
  assignApp,
  roles,
  loading,
  removeTeamMember,
  selectedMember,
  deactivateModal,
  setDeactivateModal,
  editTeamMemberRole,
}) {
  const roleOptions = useMemo(
    () =>
      roles
        ? roles
            .filter(r => r.role !== 'Owner')
            .map(r => ({
              value: r.role,
              label: r.role,
            }))
        : null,
    [roles],
  );
  const [selectedRole, setSelectedRole] = useState(
    roleOptions ? roleOptions[0] : null,
  );
  const [selectedApps, setSelectedApps] = useState({});

  const handleSubmit = async () => {
    const assignUserApp = {
      user: selectedMember.user?._id,
      pushApps: selectedApps.pushApps,
      pullApps: selectedApps.pullApps,
    };
    (selectedApps?.pushApps?.length || selectedApps?.pushApps?.length) &&
      (await assignApp(assignUserApp));

    await editTeamMemberRole(
      {
        email: selectedMember.email,
        role: selectedRole.value,
      },
      () => setOpen(false),
    );
  };
  useEffect(() => {
    setSelectedRole({
      value: selectedMember?.role,
      label: selectedMember?.role,
    });
  }, [selectedMember?.role]);

  return (
    <Modal
      show={open}
      modalTitle="Team member"
      onClose={setOpen}
      extraModalClasses="max-w-[581px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      noBottomPadding
    >
      <DeactivateMemberModal
        loading={loading}
        selectedMember={selectedMember}
        removeTeamMember={removeTeamMember}
        deactivateModal={deactivateModal}
        setDeactivateModal={setDeactivateModal}
        setOpen={setOpen}
      />
      <section className="flex flex-col gap-4 mb-8">
        <div className="flex items-center justify-center mt-8 bg-brandBlue40 w-20 h-20 mx-auto rounded-full">
          <p className="text-brandBlue text-lg font-medium">
            {selectedMember?.user?.first_name
              ? selectedMember?.user?.first_name[0]
              : '-'}
            {'.'}
            {selectedMember?.user?.last_name
              ? selectedMember?.user?.last_name[0]
              : '-'}
          </p>
        </div>
        <p className="text-grey text-sm font-medium">
          {selectedMember?.user?.first_name} {selectedMember?.user?.last_name}
        </p>
      </section>

      <form className="mb-2 bg-white80 rounded p-6">
        <label htmlFor="oi">
          <span className="text-sm">Email address</span>
          <input
            type="email"
            id="oi"
            className="mt-2"
            defaultValue={selectedMember?.email}
            placeholder="example@gmail.com"
            required
            disabled
          />
        </label>
        <label htmlFor="role" className="mt-6 mb-2">
          Role
        </label>
        <Select
          defaultValue={selectedMember}
          value={selectedRole}
          onChange={setSelectedRole}
          options={roleOptions}
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="auto"
          className="text-left"
        />

        <AssignApps
          setAppsToAssign={data => {
            setSelectedApps(data);
          }}
          memberApps={selectedMember?.apps}
        />
      </form>

      <div
        className="-mx-6 flex items-center gap-4 p-6 overflow-x-hidden bg-white80"
        style={{boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)'}}
      >
        <PrimaryButton
          buttonText="Save changes"
          className="w-full"
          loading={loading}
          onClick={handleSubmit}
        />
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className="bg-statusBg-404 rounded h-12 text-sm text-danger font-medium p-4 whitespace-nowrap hover:opacity-90"*/}
        {/*  onClick={() => setDeactivateModal(true)}*/}
        {/*>*/}
        {/*  Deactivate user*/}
        {/*</button>*/}
      </div>
    </Modal>
  );
}
