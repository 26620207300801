import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {
  chevronRight,
  deleteIc,
  dropdownBlue,
  dropdownGrey,
  easyOnboardIc,
  edit,
  lookupEmpty,
} from '../../../assets/images/images';
import {
  DashboardLayout,
  EmptyState,
  Loader,
  PrimaryButton,
  // SearchInput,
  TableLayout,
} from '../../../components';
import * as Actions from '../../../store/actions';
import {Constants, capitalizeEveryWord} from '../../../helpers';
import AddToListModal from './AddToListModal';
import moment from 'moment/moment';

function FraudDetectionFlows({
  updateList,
  getList,
  caseLists: {listLoading, mutateLoading, list},
}) {
  const {slug} = useParams();
  const [addToListModal, setOpenAddToListModal] = useState(false);
  const [listItems, setListItems] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const {_id, __v, company, createdAt, count, updatedAt, ...data} = list || {};

  useEffect(() => {
    getList(slug);
  }, [getList, slug]);

  useEffect(() => {
    list && setListItems([...((list?.items && list?.items) || [])]);
  }, [list]);

  return (
    <DashboardLayout
      bg="bg-white"
      topPadding="pt-6"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyOnboardIc} alt="" width={18} height={18} />
          <Link to={Constants.PAGES_URL.CUSTOM_LISTS} className="ml-2 -mb-1">
            Custom Lists
          </Link>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1 cursor-pointer">
            {' '}
            {capitalizeEveryWord(list?.name)}
          </p>
        </div>
      }
    >
      <div className="flex items-center justify-between w-full mb-6">
        <h2 className="text-grey text-base sm:text-lg font-medium">
          {capitalizeEveryWord(list?.name)}
        </h2>
        <div className="flex items-center gap-4">
          <PrimaryButton
            buttonText="Add to list"
            onClick={() => setOpenAddToListModal(true)}
          />
          {/*<SearchInput />*/}
        </div>
      </div>
      <AddToListModal
        lists={listItems}
        listData={{...data}}
        open={addToListModal}
        setOpen={setOpenAddToListModal}
        updateList={updateList}
        setListItems={setListItems}
        loading={mutateLoading}
      />

      {listLoading ? (
        <Loader />
      ) : !listLoading && listItems?.length === 0 ? (
        <EmptyState
          buttonText="Create a new list"
          onClick={() => setOpenAddToListModal(true)}
          body="No list has been created yet, to get started click on the button below"
          maxWidth="max-w-[226px]"
          src={lookupEmpty}
        />
      ) : (
        <section className="flex flex-col">
          <TableLayout
            negativeMargins
            negativeRightMargin="-mr-4 xl:-mr-[76px]"
          >
            <thead className="text-xs font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>name</span>
                    <img src={dropdownBlue} alt="" width={16} height={16} />
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>date created</span>
                    <img src={dropdownGrey} alt="" width={16} height={16} />
                  </div>
                </th>
                <th className="min-w-[175px]"></th>
              </tr>
            </thead>
            <tbody className="text-sm bg-white">
              {list &&
                listItems?.map((item, i) => (
                  <tr key={i} className="border-b border-grey60">
                    <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap capitalize">
                      {item.length > 30 ? `${item.substring(0, 30)}...` : item}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {moment(list?.createdAt).format('Do MMMM YYYY hh:mm')}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap relative">
                      <div className="flex items-center gap-3 z-2 p-5 absolute top-0">
                        <button
                          onClick={() => setOpenAddToListModal(true)}
                          title="Edit"
                        >
                          <img src={edit} alt="" width={16} height={16} />
                        </button>
                        <button
                          onClick={() => {
                            const allList = [...listItems];
                            const updatedList = allList.filter(
                              list => list !== item,
                            );
                            setListItems(updatedList);
                            setTimeout(() => {
                              updateList({
                                listId: slug,
                                ...data,
                                items: updatedList,
                              });
                            }, 1000);
                          }}
                          title="Delete"
                        >
                          <img src={deleteIc} alt="" width={16} height={16} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableLayout>
        </section>
      )}
    </DashboardLayout>
  );
}
export default connect(state => state, Actions)(FraudDetectionFlows);
