import React, {useState, useEffect, useRef} from 'react';
import {dropdownNoFill} from '../assets/images/images';

function PageLimit({onLimitChange, className, limit}) {
  const [drop, setDrop] = useState(false);
  const [, setLimit] = useState(10);
  const ref = useRef();

  const changeLimit = num => {
    setLimit(num);
    onLimitChange(num);
  };

  useEffect(() => {
    const handleClickOutside = e => {
      if (!ref?.current?.contains(e.target)) {
        setDrop(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [ref]);

  return (
    <div
      className={`${className} page-limit flex items-center text-body font-inter text-tiny`}
    >
      <span>View</span>
      <button
        onClick={() => setDrop(!drop)}
        ref={ref}
        className="ml-1 flex items-center gap-2 bg-white80 p-[6px] border border-grey60 rounded"
      >
        {limit} <img src={dropdownNoFill} alt="" width={16} height={16} />
        {drop && (
          <div className="dropdown">
            <span onClick={() => changeLimit(10)}>10</span>
            <span onClick={() => changeLimit(20)}>20</span>
            <span onClick={() => changeLimit(50)}>50</span>
            <span onClick={() => changeLimit(100)}>100</span>
          </div>
        )}
      </button>
      <span className="ml-[11px]">results per page</span>
    </div>
  );
}

export default PageLimit;
