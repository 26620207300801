import React from 'react';
import {NavLink} from 'react-router-dom';

export default function ReusableLink({path, src, text}) {
  return (
    <div className="flex items-center gap-4 overflow-x-auto whitespace-nowrap">
      <NavLink
        end
        className={({isActive}) =>
          `rounded flex items-center gap-2 pl-2 py-3 relative font-medium text-sm ${
            isActive ? 'text-brandBlue' : 'text-grey40'
          }`
        }
        to={path}
        aria-current="page"
      >
        <img
          src={src}
          alt=""
          width={16}
          height={16}
          className="hidden md:block"
        />
        <span className="-mb-1">{text}</span>
      </NavLink>
    </div>
  );
}
