import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {connect} from 'react-redux';
import moment from 'moment';
import Layout from '../Layout';
import {
  Accordion,
  ButtonLoader,
  EmptyState,
  Loader,
  RowData,
  TableLayout,
} from '../../../components';
import {backNofill, corruptedFile, eye} from '../../../assets/images/images';
import {
  fetchCustomerAml,
  fetchCustomerDetails,
} from '../../../requests/queries/customer360';
import * as Actions from '../../../store/actions';
import {getSubCategoriesColor} from '../../../helpers';

function AmlCheck({
  performAMLLookup,
  getAMLProfile,
  aml: {amlProfile, lookupLoading, loading},
}) {
  const {slug} = useParams();
  const [amlPage, setAmlPage] = useState(1);

  const {data: userDetails, isLoading: userDetailsLoading} = useQuery(
    ['user-details', slug],
    () => fetchCustomerDetails(slug),
  );
  const {
    data: userAmlDetails,
    isLoading: userAmlDetailsLoading,
    refetch: refetchAml,
  } = useQuery(['user-aml-details', slug], () => fetchCustomerAml(slug));

  const USER_PROFILE = useMemo(
    () => !userDetailsLoading && userDetails && userDetails,
    [userDetails, userDetailsLoading],
  );

  const globalAml = (!userAmlDetailsLoading && userAmlDetails?.entity) || {};

  const {user: amlUser} = globalAml;

  const {first_name, last_name, middle_name, dob} = USER_PROFILE || {};

  const payload = {
    app_id: USER_PROFILE?.app_id,
    match_score: 80,
    customer_id: slug,
    ...(dob !== null && {date_of_birth: dob}),
    ...(first_name !== null && {first_name}),
    ...(middle_name !== null && {middle_name}),
    ...(last_name !== null && {last_name}),
  };

  const globalAmlObject = Object.keys(globalAml);

  // doing this to update the aml page to 2 if the globalAmlObject is not empty
  useEffect(() => {
    if (globalAmlObject?.length > 0 && amlPage < 2) {
      setAmlPage(2);
    }
  }, [globalAmlObject, amlPage]);

  const renderCategories = result =>
    result?.entry_category?.split(',').map((category, index, categories) => {
      const trimmedCategory = category.trim();
      if (index < 2) {
        return (
          <span
            key={index}
            className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit ${getSubCategoriesColor(
              trimmedCategory,
            )}`}
          >
            {trimmedCategory?.replace(/-/g, ' ') || '-'}
          </span>
        );
      } else if (index === 2 && categories.length > 2) {
        const remainingCount = categories.length - 2;
        return (
          <span
            key={index}
            className="px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit bg-statusBg-400 text-status-400"
          >
            +{remainingCount}
          </span>
        );
      }
      return null;
    });

  const handleAMLLookupAndRefetch = async () => {
    await performAMLLookup(payload, setAmlPage);
    refetchAml();
  };

  const handleGetAmlProfile = async profile => {
    await getAMLProfile({id: profile?.profile_id, appId: globalAml?.app_id});
    setAmlPage(3);
  };

  const PROFILES = amlProfile?.entity?.result;

  const PROFILE = [
    {
      leftText: 'Country',
      rightText: PROFILES?.countryName || '-',
    },
    {leftText: 'Category', rightText: PROFILES?.entryCategory || '-'},
    {leftText: 'Subcategory', rightText: PROFILES?.subCatDesc || '-'},
    {leftText: 'Date of birth', rightText: PROFILES?.dob || '-'},
    {leftText: 'Place of birth', rightText: PROFILES?.pob || '-'},
  ];

  const renderAmlContent = () => {
    switch (amlPage) {
      case 1:
        return renderEmptyState();
      case 2:
        return renderPage2Content();
      case 3:
        return renderPage3Content();
      default:
        return null;
    }
  };

  const renderEmptyState = () => (
    <div className="w-full pt-6 pb-6">
      <div className="bg-white80 w-full">
        <EmptyState
          body="You have not performed an AML check"
          src={corruptedFile}
          height={45}
          imageHeight={48}
          imageWidth={48}
          bodyMaxWidth="max-w-[179px]"
          buttonText="Perform AML Check"
          onClick={handleAMLLookupAndRefetch}
        />
      </div>
    </div>
  );

  const renderPage2Content = () => (
    <>
      {loading && (
        <div className="fixed  left-0 top-0 z-50  w-full h-full flex items-center justify-center">
          <ButtonLoader color="#3F7CDB" />
          <span className="text-sm mt-16 text-body font-medium italic">
            ...loading
          </span>
        </div>
      )}
      <div
        style={{opacity: loading && 0.3}}
        className="mt-8 flex flex-col gap-4"
      >
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-1">
            <h3 className="text-lg font-medium text-grey">
              {amlUser?.first_name || '-'} {amlUser?.last_name || '-'}{' '}
              {amlUser?.middle_name}
            </h3>
          </div>
          <div className="flex items-center gap-1">
            <p className="text-sm text-[#868686]">Search results</p>
            <span className="w-5 h-5 bg-brandBlue flex items-center justify-center rounded-full text-white text-xs font-medium mb-1">
              {globalAml?.profile_ids?.length || 0}
            </span>
          </div>
        </div>
        {globalAml?.profile_ids?.length !== 0 ? (
          <TableLayout className="mt-2 -mx-6">
            <thead className="text-xs font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-4 sm:pl-6 font-medium text-left text-xs text-grey whitespace-nowrap">
                  name
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  sub-categories
                </th>
                <th className="w-[18.69%]" />
              </tr>
            </thead>
            <tbody className="text-sm">
              {globalAml?.profile_ids?.map(profile => (
                <tr
                  className="border-b border-grey60 bg-white"
                  key={profile.profile_id}
                >
                  <td className="p-4 pl-4 sm:pl-6 text-tiny text-body">
                    {profile.name}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body">
                    <div className="flex items-center gap-1">
                      {renderCategories(profile)}
                    </div>
                  </td>
                  <td className="p-4 pl-0">
                    <div className="flex items-center gap-4">
                      <button
                        title="View"
                        className="h-4 aspect-square"
                        onClick={() => handleGetAmlProfile(profile)}
                      >
                        <img src={eye} alt="" width={16} height={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableLayout>
        ) : null}
      </div>
    </>
  );

  const renderPage3Content = () => (
    <div className="mt-8 flex flex-col">
      <button
        className="flex items-center gap-2 w-fit"
        onClick={() => setAmlPage(2)}
      >
        <img src={backNofill} alt="" width={16} height={16} />
        <span className="-mb-1 text-sm text-grey80 font-medium">Back</span>
      </button>
      <div className="mt-6">
        <p className="text-grey">{PROFILES?.name}</p>
        <h3 className="mt-5 mb-6 font-medium text-grey">Profile</h3>
        {PROFILE.map((profile, index) => (
          <RowData key={index} {...profile} alignStart width="w-96" />
        ))}
        <div className="flex flex-col gap-6 p-4 rounded-lg bg-white80">
          <div>
            <h3 className="mb-2 font-medium text-grey">Remarks</h3>
            <p className="text-sm text-body">{PROFILES?.remarks || '-'}</p>
          </div>
          <div>
            <h3 className="mb-2 font-medium text-grey">Source</h3>

            <ul className="flex flex-col gap-3 ml-4 h-[500px] overflow-y-auto list-none  md:max-w-full ">
              {PROFILES?.media?.map((item, i) => (
                <li key={i} className="bg-white p-3 rounded">
                  <p className="text-xs text-grey40 mb-2">
                    {moment(item.date).format('YYYY-MM-DD') || '-'}
                  </p>
                  <p className="  mb-3 text-grey">{item.title || '-'}</p>
                  <p className="text-sm text-grey40 mb-2">
                    {item.snippet || '-'}
                  </p>
                  <a
                    href={item?.url || '-'}
                    target="_blank"
                    rel="noreferrer"
                    className="text-brandBlue text-sm   flex items-center hover:underline "
                  >
                    <div className="h-[5px] w-[5px] mr-1 rounded-full bg-brandBlue" />{' '}
                    {item.url || '-'}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Layout pageTitle="AML Check">
      <div className="space-y-4">
        <Accordion
          title="AML"
          border
          customClasses="rounded-xl"
          customChildrenClasses="mt-2 border-t border-grey-50 px-6"
          customPadding="px-6 py-5"
          customTitleSize="text-lg"
          customTitleColor="text-grey"
          isOpen={false}
        >
          <>
            {userAmlDetailsLoading || lookupLoading ? (
              <Loader height={50} />
            ) : (
              renderAmlContent()
            )}
          </>
        </Accordion>
      </div>
    </Layout>
  );
}

export default connect(state => state, Actions)(AmlCheck);
