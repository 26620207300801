import chunk from 'chunk-date-range';
import moment from 'moment';

const getDifferenceInDays = (start, end) =>
  Math.ceil(Math.abs(new Date(start) - new Date(end)) / (1000 * 60 * 60 * 24));

const formatTimePeriod = (interval, chunks) => {
  switch (chunks) {
    case 'hour' || 0:
      return moment(interval.start).format('HH:mm');
    case 'day':
      return moment(interval.start).format('D MMM');
    case 'week':
      return `${moment(interval.start).format('D MMM')} - ${moment(
        interval.end,
      ).format('D MMM')}`;
    case 'month':
      return moment(interval.start).format('MMM YY');
    case 'year':
      return `${moment(interval.start).format('YYYY')}`;
    default:
      return moment(interval.start).format('YYYY/MM/DD');
  }
};

export const transformLogsGraphData = (logs, start, end) => {
  const startDate = new Date(
    moment(
      start ||
        (logs[logs?.length - 1]
          ? new Date(logs[logs?.length - 1]?.createdAt)
          : new Date()),
    ).format('YYYY-MM-DD'),
  );
  const endDate = end || (logs[0] ? new Date(logs[0].createdAt) : new Date());
  const timePeriodInDays = getDifferenceInDays(startDate, endDate);

  const chunks =
    timePeriodInDays <= 2
      ? 'hour'
      : timePeriodInDays <= 14
      ? 'day'
      : timePeriodInDays <= 60
      ? 'week'
      : 'month';
  const intervals = chunk(startDate, endDate, chunks);
  const period = intervals.map(interval => formatTimePeriod(interval, chunks));

  const successData = logs.filter(
    log => log.raw_status?.toLowerCase() === 'success',
  );
  const failedData = logs.filter(
    log => log.raw_status?.toLowerCase() === 'failed',
  );

  const newFormatted = [
    {data: [], name: 'Successful'},
    {data: [], name: 'Failed'},
  ];

  newFormatted.map(item => {
    intervals.map(interval => {
      let count = 0;
      const intervalStart = new Date(interval.start);
      const intervalEnd = new Date(interval.end);

      if (item.name === 'Successful') {
        successData.map(log => {
          const d = new Date(log.createdAt);
          if (d >= intervalStart && d <= intervalEnd) {
            count++;
          }
        });
        return item.data.push(count);
      }
      if (item.name === 'Failed') {
        failedData.map(log => {
          const d = new Date(log.createdAt);
          if (d >= intervalStart && d <= intervalEnd) {
            count++;
          }
        });
        return item.data.push(count);
      }
    });
  });

  const formattedData = [];
  const services = [];
  logs.map(log => {
    if (!services.includes(log?.service)) {
      services.push(log?.service);
      formattedData.push({data: [], name: log?.service});
    }

    return formattedData;
  });

  formattedData.map(item => {
    intervals.map(interval => {
      let count = 0;
      const intervalStart = new Date(interval.start);
      const intervalEnd = new Date(interval.end);

      logs.map(log => {
        const d = new Date(log.createdAt);
        if (
          log.service === item.name &&
          d >= intervalStart &&
          d <= intervalEnd
        ) {
          count++;
        }
      });
      item.data.push(count);
    });
  });

  return {
    formattedData,
    logs,
    newFormatted,
    period,
  };
};
