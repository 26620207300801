/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {v4} from 'uuid';

import {
  actionStepIc,
  andIcon,
  back,
  corruptedFile,
  cup,
  easyOnboardIc,
  flowLine,
  orIcon,
  startProcess,
  stepsPlus,
  trash,
} from '../../../../assets/images/images';
import {
  DashboardLayout,
  EmptyState,
  Loader,
  PrimaryButton,
} from '../../../../components';
import EndProcessModal from './EndProcessModal';
import RuleEditorModal from './RuleEditorModal';
import {defaultFlowProcess} from './defaultProcess';
import * as Actions from '../../../../store/actions';
import {toast} from 'react-toastify';
import {TriggerCard} from './_TriggerCard';
import {Steps} from './_Steps';
import {ConditionCard} from './_ConditionsCard';
import DeleteModal from './DeleteModal';
import {isNestedObjectTruthy} from '../../../../helpers/isNestedObjectTruthy';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  addTask,
  updateTask,
  fetchTasks,
  saveProcess,
} from '../../../../requests/queries/flows';
import moment from 'moment';
import {PageContext} from '../../../../helpers/context';
import {CreditCheckContext} from '../../../../helpers/context/CreditCheckContext';
import ButtonLoader from '../../../../components/ButtonLoader';

function FlowsEditProcess({
  createFlowProcess,
  flows: {flowProcess},
  apps: {live},
}) {
  const {slug} = useParams();
  const flowIndex = localStorage.getItem('ruleIndex');
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const {
    set: {setTempFlow, setOpenModals, setRuleState},
    get: {
      tempFlow,
      ruleState: {
        actionName,
        selectedCount,
        selectedPeriod,
        conditionType,
        currentRule,
      },
      openModals: {actionRule, lastRule, steps},
    },
    clearFlow,
    processArrayWithTypes,
  } = useContext(CreditCheckContext);

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  ////////////// REACT-QUERY /////////////////
  const {isLoading: gettingTasks, isFetching} = useQuery(
    ['all-tasks', slug],
    () => fetchTasks(slug),
    {
      placeholderData: useMemo(() => flowProcess?.process, [flowProcess]),
      onSuccess: allTasks => {
        const flowArray = [...(allTasks?.tasks || [])];
        const start = flowArray.shift() || defaultFlowProcess?.process[0];
        const end = flowArray.pop() || defaultFlowProcess?.process[2];

        const newTempFlow = flowArray.map(flow => ({
          key: v4(),
          ...flow,
          configurations: processArrayWithTypes(flow.configurations, true),
        }));

        const FLOW_PROCESS = [start, newTempFlow, end];
        setTempFlow(FLOW_PROCESS);
        createFlowProcess({process: FLOW_PROCESS});
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  const {mutateAsync: addNewTask, isLoading: addingTask} = useMutation(
    // eslint-disable-next-line no-unused-vars
    async ({key, ...task}) => await addTask(slug, {...task}),
    {
      onSuccess: task => {
        const newProcess = [...tempFlow];
        newProcess[1].forEach((element, index) => {
          if (index === +flowIndex) {
            newProcess[1][index] = {key: v4(), ...task?.task};
          }
        });

        setTempFlow(newProcess);
        createFlowProcess({process: newProcess});
        toast.success('Task added successfully');
      },
    },
  );
  const {mutateAsync: updateExistingTask, isLoading: updatingTask} =
    useMutation(task => updateTask(slug, task), {
      onSuccess: () => {
        toast.success('Task updated successfully');
        queryClient.invalidateQueries('all-tasks');
      },
    });
  const {mutateAsync: saveFlowProcess, isLoading: savingProcess} = useMutation(
    process => saveProcess(slug, process),
    {
      onSuccess: () => {
        toast.success('Process saved successfully');
      },
      onError: error => toast.error(error.response.data.error),
    },
  );

  const uniqueID = v4();
  const taskId = localStorage.getItem('taskId');
  const cardRef = useRef(null);
  const [action, setAction] = useState();
  const [buttonStyle, setButtonStyle] = useState({});
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [errors, setError] = useState({
    end: false,
    [taskId]: false,
  });
  const [addingOrUpdating, setAddingOrUpdating] = useState({
    [action]: true,
  });

  const handleAddSteps = () => {
    setOpenModals(prev => ({
      ...prev,
      lastRule: true,
      steps: true,
    }));
    setRuleState(prev => ({...prev, actionName: {}, currentRule: {}}));
  };

  const durationInHours = moment
    .duration(selectedCount[action]?.value || 0, selectedPeriod[action]?.value)
    .asHours();

  const flowData = useMemo(
    () => ({
      type: 'RULE',
      key: uniqueID,
      name: actionName[action] || '',
      period: durationInHours || 0,
      score: currentRule.score || 0,
    }),
    [action, actionName, currentRule.score, durationInHours],
  );

  const handleAddCondition = () => {
    const newProcess = [...tempFlow];
    newProcess[1].forEach(element => {
      if (element.key === currentRule.key) {
        const newCondition = {
          value: '',
          field: '',
          parent: '',
          operator: '',
          ...(currentRule?.configurations?.length >= 1 &&
          conditionType[action]?.value
            ? {type: ''}
            : {}),
          id: currentRule?.configurations?.length + 1 || 1,
        };
        newProcess[1][flowIndex] = {
          ...currentRule,
          configurations:
            currentRule?.configurations &&
            Array.isArray(currentRule?.configurations)
              ? // eslint-disable-next-line no-unsafe-optional-chaining
                [...currentRule?.configurations, newCondition]
              : (currentRule?.configurations && [
                  currentRule?.configurations,
                  newCondition,
                ]) || [newCondition],
        };

        setRuleState(prev => ({
          ...prev,
          currentRule: newProcess[1][flowIndex],
        }));
      }
    });
    setTempFlow(newProcess);
  };

  useEffect(() => {
    tempFlow?.length > 0 &&
      tempFlow[1].map(data => {
        setError({...errors, [+flowIndex]: !isNestedObjectTruthy(data)});
      });
    setAddingOrUpdating({[+flowIndex]: addingTask || updatingTask});
  }, [setError, tempFlow, addingTask, updatingTask]);

  const handleAddActionStep = useCallback(() => {
    setOpenModals({actionRule: false, lastRule: false, steps: false});
    setHoveredIndex(-1);
    const newProcess = [...tempFlow];
    const index = hoveredIndex;
    localStorage.removeItem('taskId');

    if (index === -1) {
      newProcess[1].push({
        ...flowData,
      });
    } else {
      newProcess[1].splice(index, 0, {
        ...flowData,
      });
    }
    setTimeout(() => {
      if (newProcess[1].length) {
        cardRef.current.click();
      }
    }, 500);
    setTempFlow(newProcess);
    setError({...errors, [flowIndex]: !isNestedObjectTruthy(flowData)});
  }, [tempFlow, flowData, action]);

  ////////////////////////// SAVE PROCESS ///////////////////////////
  const handleSaveProcess = useCallback(async () => {
    await saveFlowProcess(tempFlow);
  }, [tempFlow, slug, errors, flowData]);

  const handleMouseEnter = useCallback(
    (e, index) => {
      if (hoveredIndex !== index) {
        setHoveredIndex(index);
        const ADD_BUTTON_TO_POSITION = 30;
        const bounding = e.currentTarget.getBoundingClientRect();
        setButtonStyle({
          top: bounding.y - bounding.top + ADD_BUTTON_TO_POSITION,
          position: 'absolute',
          cursor: 'pointer',
          alignSelf: 'center',
          right: 16,
        });
      }
    },
    [hoveredIndex],
  );

  const handleMouseLeave = useCallback(() => {
    setHoveredIndex(-1);
    setOpenModals(prev => ({
      ...prev,
      lastRule: false,
      steps: false,
    }));
  }, []);

  //////// APPLY CHANGES  //////////////////////////////////////
  const applyFlow = useCallback(async () => {
    const newProcess = [...tempFlow];
    newProcess[1].forEach(element => {
      if (element.key === currentRule.key) {
        newProcess[1][flowIndex] = {
          ...flowData,
          ...currentRule,
          configurations: [
            ...((currentRule?.configurations && currentRule?.configurations) ||
              []),
          ],
        };
      }
    });
    setTempFlow(newProcess);
    createFlowProcess({process: newProcess});
    // eslint-disable-next-line no-unused-vars
    const {key, _id, __v, flow, updatedAt, createdAt, ...rest} = {
      ...currentRule,
      ...flowData,
    };
    const config = processArrayWithTypes(rest?.configurations);
    const ruleData = {...rest, configurations: config};
    const taskId = _id || localStorage.getItem('taskId');
    if (taskId === 'undefined') {
      await addNewTask({key, ...ruleData});
    } else {
      await updateExistingTask({id: taskId, ...ruleData});
    }
    setOpenModals({openRuleEditorModal: false});
    setError({...errors, [flowIndex]: !isNestedObjectTruthy(flowData)});
  }, [tempFlow, flowData, flowIndex]);

  return (
    <DashboardLayout
      bg="bg-[#DAE4F4]"
      fullScreen
      topPadding="pt-0"
      leftMargin="ml-0"
      xlLeftMargin="xl:ml-0"
      xlRightPadding="sm:pr-0"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyOnboardIc} alt="" />
          <p className="ml-2 -mb-1">Fraud detection</p>
        </div>
      }
    >
      <DeleteModal ruleIndex={hoveredIndex} />
      <EndProcessModal />
      <RuleEditorModal
        disabled={addingTask || updatingTask}
        action={action}
        onClick={applyFlow}
        handleAddCondition={handleAddCondition}
      />

      {isFetching && (
        <div className="fixed right-0 left-20 top-0 z-50  w-full h-full flex items-center justify-center">
          <ButtonLoader color="#3F7CDB" />
          <span className="text-sm mt-16 text-body font-medium italic">
            ...loading
          </span>
        </div>
      )}
      <section
        className="flex flex-col pb-20 relative "
        style={{opacity: isFetching && 0.5}}
      >
        <section className="bg-white80 py-2 px-12 w-[102%] flex items-center gap-4 justify-end sm:top-0 relative sm:absolute  top-[41px] ">
          <div
            className="mr-auto flex items-center cursor-pointer hover:underline"
            onClick={() => navigate(-1)}
          >
            <img src={back} alt="" />
            <span className="text-sm text-body">Back</span>
          </div>

          <PrimaryButton
            yPadding="py-2"
            xPadding="px-2"
            loading={savingProcess}
            fontSize="text-xs"
            buttonText="Save process"
            onClick={handleSaveProcess}
            disabled={tempFlow?.length < 3 || savingProcess}
          />
          <button
            onClick={clearFlow}
            disabled={!tempFlow[1]?.length > 0}
            className="text-xs text-white font-medium bg-danger rounded p-2 hover:opacity-95"
          >
            Clear process
          </button>
        </section>

        {!live ? (
          <EmptyState
            body={'Ops! You can only access this resource in live mode'}
            src={corruptedFile}
            noBtn
          />
        ) : gettingTasks ? (
          <Loader />
        ) : (
          <section className="flex flex-col items-center mt-16 gap-[6px]">
            {tempFlow.length > 0 &&
              tempFlow?.map((process, index) => {
                if (index === 0) {
                  return (
                    <Fragment key={index}>
                      <TriggerCard
                        title={process?.name}
                        subtitle="User begins process"
                        icon={startProcess}
                        isStarting
                      />
                    </Fragment>
                  );
                }
                if (index > 0 && index < tempFlow?.length - 1) {
                  return process?.map((rule, idx) => {
                    const ruleConditions =
                      rule?.configurations &&
                      Array.isArray(rule?.configurations)
                        ? // eslint-disable-next-line no-unsafe-optional-chaining
                          [...rule?.configurations]
                        : rule?.configurations &&
                          typeof rule?.configurations === 'object'
                        ? [rule?.configurations]
                        : [
                            {
                              value: '',
                              field: '',
                              parent: '',
                              operator: '',
                              id: 1,
                            },
                          ];
                    return (
                      <span
                        key={rule.key}
                        style={{
                          position: 'relative',
                          textAlign: 'center',
                        }}
                        className="flex flex-col items-center"
                      >
                        <div
                          onMouseEnter={e => handleMouseEnter(e, idx)}
                          onMouseLeave={handleMouseLeave}
                          className="w-full mb-1 flex flex-col items-center justify-center"
                        >
                          {!steps ? (
                            <div
                              className="flex py-1 items-center justify-center flex-col"
                              onClick={() => handleAddSteps()}
                            >
                              <span>
                                <img
                                  src={stepsPlus}
                                  alt=""
                                  width={20}
                                  height={20}
                                  title={steps ? '' : 'Add rules'}
                                  className="cursor-pointer"
                                />
                              </span>
                            </div>
                          ) : (
                            <Fragment>
                              {!actionRule && steps && idx === hoveredIndex && (
                                <>
                                  <img
                                    src={flowLine}
                                    alt=""
                                    width={8}
                                    height={4}
                                  />
                                  <Steps
                                    handleAddActionStep={handleAddActionStep}
                                    setAction={setAction}
                                    tempFlow={tempFlow}
                                    className="py-2"
                                  />
                                </>
                              )}
                            </Fragment>
                          )}
                          <img src={flowLine} alt="" width={8} height={4} />
                        </div>

                        <div
                          onMouseEnter={e => handleMouseEnter(e, idx)}
                          onMouseLeave={handleMouseLeave}
                          className="w-[400px] mb-1 flex items-center justify-center relative"
                        >
                          <TriggerCard
                            // isError={errors[idx]}
                            disabled={addingOrUpdating[idx]}
                            ref={cardRef}
                            icon={actionStepIc}
                            title={actionName[rule.key] || rule?.name || '-'}
                            // subtitle={`${
                            //   selectedField[rule.key]?.label !== undefined
                            //     ? selectedField[rule.key]?.label
                            //     : rule?.configurations?.field
                            //         ?.replaceAll('_', ' ')
                            //         ?.split(' ')
                            //         ?.join(' ')
                            //         .replace('is', '') || ''
                            // } ${
                            //   selectedOperator[rule.key]?.value !== undefined
                            //     ? selectedOperator[rule.key]?.value?.replaceAll(
                            //         '_',
                            //         ' ',
                            //       )
                            //     : rule?.configurations?.operator?.replaceAll(
                            //         '_',
                            //         ' ',
                            //       ) || ''
                            // } ${
                            //   actionValue[rule.key] !== undefined
                            //     ? actionValue[rule.key]
                            //     : rule?.configurations?.value?.toString() || ''
                            // }`}
                            height
                            onClick={() => {
                              setAction(rule.key);

                              setRuleState(prev => ({
                                ...prev,
                                actionName: {[rule.key]: rule?.name},
                                currentRule: {
                                  ...rule,
                                  configurations: ruleConditions,
                                },
                              }));
                              setOpenModals({openRuleEditorModal: true});
                              localStorage.setItem('ruleIndex', idx.toString());
                              localStorage.setItem('taskId', rule?._id);
                            }}
                          />

                          {!steps && idx === hoveredIndex && (
                            <div
                              style={buttonStyle}
                              onClick={() => {
                                setOpenModals({openDeleteAction: true});
                                localStorage.setItem(
                                  'ruleIndex',
                                  idx.toString(),
                                );
                                localStorage.setItem('taskId', rule?._id);
                              }}
                            >
                              <img src={trash} alt="" width={20} height={4} />
                            </div>
                          )}
                        </div>
                        {ruleConditions?.length >= 0 ? (
                          <>
                            <img src={flowLine} alt="" width={8} height={4} />
                            <div className="flex  items-center flex-col p-10  relative outline-1 outline-dashed rounded ">
                              <span className="text-xs text-body absolute left-2 top-2">
                                Conditions
                              </span>
                              <div className="flex ml-30 items-center gap-3  ">
                                {ruleConditions?.map((condition, index) => {
                                  const theme =
                                    condition?.type === 'AND'
                                      ? {icon: andIcon, color: '#9FBDED'}
                                      : {icon: orIcon, color: '#FB9855'};
                                  return (
                                    ruleConditions[index]?.id ===
                                      condition?.id && (
                                      <Fragment key={index}>
                                        <ConditionCard
                                          color={theme?.color || ''}
                                          key={index}
                                          icon={theme?.icon || actionStepIc}
                                          subtitle={`${
                                            condition?.field
                                              ?.replaceAll('_', ' ')
                                              ?.split(' ')
                                              ?.join(' ')
                                              .replace('is', '') || ''
                                          } ${
                                            condition?.operator?.replaceAll(
                                              '_',
                                              ' ',
                                            ) || ''
                                          } ${
                                            condition?.value?.toString() || ''
                                          }`}
                                          // height
                                        />
                                        {ruleConditions?.length > 1 &&
                                        index !== ruleConditions?.length - 1 ? (
                                          <div className="rotate-90 w-3">
                                            <img
                                              src={flowLine}
                                              alt=""
                                              width={8}
                                              height={4}
                                            />
                                          </div>
                                        ) : null}
                                      </Fragment>
                                    )
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        ) : null}
                      </span>
                    );
                  });
                }

                if (index === tempFlow?.length - 1) {
                  return (
                    <Fragment key={index}>
                      {!actionRule && !steps && (
                        <button
                          onClick={handleAddSteps}
                          className="w-5 h-5"
                          title={steps ? '' : 'Add steps'}
                        >
                          <img src={stepsPlus} alt="" width={20} height={20} />
                        </button>
                      )}
                      <img src={flowLine} alt="" width={6} height={44} />
                      {!actionRule && steps && lastRule ? (
                        <Steps
                          handleAddActionStep={handleAddActionStep}
                          setAction={setAction}
                          tempFlow={tempFlow}
                        />
                      ) : null}
                      <TriggerCard
                        subtitle={'User output'}
                        title={process?.name || 'Ending process'}
                        onClick={() =>
                          setOpenModals({openEndProcessModal: true})
                        }
                        icon={cup}
                        isEnding
                      />
                    </Fragment>
                  );
                }
              })}
          </section>
        )}
      </section>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(FlowsEditProcess);
