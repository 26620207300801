import React, {useRef, useState, useCallback, useMemo, useEffect} from 'react';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {useMutation} from 'react-query';
import {CreatePinInput} from '../CreatePinInput';
import {Loader, PrimaryButton} from '../../../../../components';
import {baasLock} from '../../../../../assets/images/images';
import {
  completeBAASTransfer,
  sendBAASOtp,
  validateBAASOtp,
} from '../../../../../requests/queries/baas';
import {OTPCountdownTimer} from './OtpTimer';
import {hideEmail} from '../hideEmail';

const initialTime = 300;
const OTP_LENGTH = 6;
const OTP_ARRAY = Array(OTP_LENGTH).fill('');
export const Otp = ({setWithdrawalSteps}) => {
  const {userDetails, user} = useSelector(state => state?.auth || {});
  const [otp, setOtp] = useState(OTP_ARRAY);
  const [timeLeft, setTimeLeft] = useState(initialTime);

  const otpRefs = useRef([]);

  const withdrawalID = sessionStorage?.getItem('withdrawal_id');
  const referenceID = sessionStorage?.getItem('reference_id');

  const handlePaste = useCallback((e, currentPin, setCurrentPin) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData('text')
      .replace(/\D/g, '')
      .slice(0, OTP_LENGTH);
    const newPin = [...currentPin];
    for (let i = 0; i < pastedData?.length; i++) {
      newPin[i] = pastedData[i];
    }
    setCurrentPin(newPin);
    if (pastedData?.length === OTP_LENGTH) {
      otpRefs.current[OTP_LENGTH - 1]?.focus();
    } else {
      otpRefs.current[pastedData?.length]?.focus();
    }
  }, []);

  const handleInputChange = useCallback(
    (e, index, currentPin, setCurrentPin) => {
      const value = e.target.value.replace(/\D/g, '');
      if (value.length <= 1) {
        const newPin = [...currentPin];
        newPin[index] = value;
        setCurrentPin(newPin);

        if (value && index < OTP_LENGTH - 1) {
          otpRefs.current[index + 1]?.focus();
        }
      }
    },
    [],
  );
  useEffect(() => {
    otpRefs.current[0]?.focus();
  }, []);

  const validateOtpMutation = useMutation(validateBAASOtp);
  const validateTransferMutation = useMutation(completeBAASTransfer, {
    onSuccess: data => {
      toast.success(data?.entity || 'Withdrawal Request is Approved');
      setWithdrawalSteps('complete');
      sessionStorage.removeItem('withdrawal_id');
      sessionStorage.removeItem('reference_id');
    },
  });

  const sendOtpMutation = useMutation(sendBAASOtp, {
    onSuccess: data => {
      sessionStorage.setItem(
        'reference_id',
        String(data?.entity?.reference_id),
      );
      setTimeLeft(initialTime);
    },
  });

  const isValid = useMemo(
    () => otp.join('').length === OTP_LENGTH && [otp],
    [otp],
  );

  const handleKeyDown = useCallback((e, index, currentPin) => {
    if (e.key === 'Backspace' && !currentPin[index] && index > 0) {
      otpRefs.current[index - 1]?.focus();
    }
  }, []);

  const resetPins = useCallback(
    error => {
      setOtp(OTP_ARRAY);
      if (error) {
        toast.error(error);
        if (error.includes('Insufficient balance')) {
          // onClose(false);
          setWithdrawalSteps('low-balance');
        }
      }
    },
    [setWithdrawalSteps],
  );

  const handleSubmit = useCallback(async () => {
    const newPin = otp.join('');
    if (newPin.length === OTP_LENGTH) {
      otpRefs.current[0]?.focus();
    } else {
      return toast.error('Please enter a valid 6-digit OTP');
    }

    try {
      await validateOtpMutation.mutateAsync({
        otp_code: newPin,
        company_id: userDetails?.company?.id,
        withdrawal_id: withdrawalID,
        reference_id: referenceID,
      });
      await validateTransferMutation.mutateAsync({
        company_id: userDetails?.company?.id,
        withdrawal_id: withdrawalID,
      });
    } catch (error) {
      return resetPins(error.response?.data?.error);
    }
  }, [
    otp,
    validateOtpMutation,
    userDetails?.company?.id,
    withdrawalID,
    referenceID,
    validateTransferMutation,
    resetPins,
  ]);

  const resendOtp = useCallback(async () => {
    try {
      await sendOtpMutation.mutateAsync({
        company_id: userDetails?.company?.id,
        withdrawal_id: withdrawalID,
        reference_id: referenceID,
      });
    } catch (error) {
      return resetPins(error.response?.data?.error);
    }
  }, [
    sendOtpMutation,
    userDetails?.company?.id,
    withdrawalID,
    referenceID,
    resetPins,
  ]);

  if (validateTransferMutation?.isLoading) {
    return <Loader height={38.7} />;
  }

  return (
    <div className="mt-6">
      <div className="flex flex-col items-center gap-3">
        <img src={baasLock} alt="lock icon" />
        <p className="font-inter text-base text-[#4F555F] text-center tracking-[-1%] max-w-[385px] m-auto">
          Enter the 6-digit OTP sent to your business email at{' '}
          {hideEmail(user?.email)}
        </p>
      </div>

      <CreatePinInput
        pin={otp}
        onChange={(e, index) => handleInputChange(e, index, otp, setOtp)}
        onKeyDown={(e, index) => handleKeyDown(e, index, otp)}
        onPaste={e => handlePaste(e, otp, setOtp)}
        pinRefs={otpRefs}
      />

      <OTPCountdownTimer
        timeLeft={timeLeft}
        setTimeLeft={setTimeLeft}
        resendOtp={resendOtp}
        initialTime={initialTime}
        loading={sendOtpMutation?.isLoading}
      />

      <div className="flex items-center mt-6">
        <PrimaryButton
          buttonText="Submit"
          className="w-full rounded-lg"
          onClick={handleSubmit}
          disabled={!isValid}
          loading={validateOtpMutation?.isLoading}
        />
      </div>
    </div>
  );
};
