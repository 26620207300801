import React, {useMemo} from 'react';
import Chart from 'react-apexcharts';
import {shortenLargeNumbers} from '../helpers/shortenLargeNumbers';

function VerificationAnalyticsPieChart({verifications}) {
  const options = {
    labels: ['Successful', 'Failed', 'Abandoned', 'In Progress', ''],
    colors: ['#87D4DB', '#F15D5F', '#2A5392', '#73739B'],
    dataLabels: {
      enabled: false,
    },
    legend: false,
    stroke: {
      width: 0,
    },
    tooltip: {
      theme: false,
    },
    chart: {
      width: 300,
      height: 300,
    },
  };
  const {Completed, Failed, Abandoned, Pending, Ongoing} = verifications || {};

  const series = useMemo(
    () => [Completed, Failed, Abandoned, (Pending || 0) + (Ongoing || 0)],
    [Abandoned, Completed, Failed, Ongoing, Pending],
  );

  const TOTAL_VERIFICATIONS = useMemo(
    () => series.reduce((sum, value) => sum + (value || 0), 0),
    [series],
  );

  const formatNumber = num => {
    const maxNumber = 1000;
    const wholeNumber = Math.floor(num / 1000) * 1000;
    return num > maxNumber && num > wholeNumber ? '+' : '';
  };

  return (
    <div className="relative flex justify-center items-center">
      <Chart type="donut" options={options} series={series} />
      <div
        className="absolute flex flex-col items-center w-fit"
        // style={{top: '34%'}}
      >
        <h1 className="font-semibold text-base xl:text-xxl text-grey">
          {shortenLargeNumbers(TOTAL_VERIFICATIONS || 0, null, {decimals: 0})}
          <span className="font-normal text-grey-500 text-xl">
            {formatNumber(TOTAL_VERIFICATIONS)}
          </span>
        </h1>
        <span className="text-[6px] xl:text-xs font-medium uppercase text-grey">
          Verifications
        </span>
      </div>
    </div>
  );
}

export default VerificationAnalyticsPieChart;
