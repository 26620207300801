const strictnessLevel = [
  {value: 'high', label: 'High', name: 'high'},
  {value: 'medium', label: 'Medium', name: 'medium'},
  {value: 'low', label: 'Low', name: 'low'},
];
const reviewProcess = [
  {value: 'Automatic', label: 'Automatic', name: 'automatic'},
  {value: 'Manual', label: 'Manual', name: 'manual'},
];
const amlData = [
  {value: 'yes', label: 'True', name: 'yes'},
  {value: '', label: 'False', name: 'no'},
];
const webhookData = [
  {value: 'yes', label: 'Yes', name: 'yes'},
  {value: '', label: 'No', name: 'no'},
];

const checkForDuplicates = [
  {label: 'Yes', value: 'yes'},
  {label: 'No', value: ''},
];

const directFeedback = [
  {label: 'Yes', value: 'yes'},
  {label: 'No', value: ''},
];

export {
  strictnessLevel,
  reviewProcess,
  amlData,
  webhookData,
  checkForDuplicates,
  directFeedback,
};
