import React from 'react';
import {SettingsToggle} from '../../../components';
import SettingsTeamLayout from './Layout';

const Owner = () => (
  <SettingsTeamLayout>
    <div className="mt-10 flex items-center gap-4 cursor-pointer">
      <p className="text-xs text-grey30 font-medium">All permissions</p>
      <SettingsToggle disabled isChecked={true} />
    </div>
  </SettingsTeamLayout>
);

export default Owner;
