import React, {useState} from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import {
  Modal,
  PrimaryButton,
  DropdownIndicator,
  customStyles,
} from '../../components';
import * as Actions from '../../store/actions';

function GenerateAffordabilityModal({open, setOpen, userApps}) {
  const [selectedApp, setSelectedApp] = useState(null);

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Generate Affordability"
    >
      <form className="mt-6 text-left">
        <label htmlFor="app" className="relative mt-6 mb-2">
          Select app
        </label>
        <Select
          defaultValue={selectedApp}
          value={selectedApp}
          onChange={setSelectedApp}
          options={userApps}
          placeholder="Select app"
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="auto"
          id="app"
        />

        <label htmlFor="dti" className="mt-6 relative">
          <span className="text-sm">DTI (%)</span>
          <input type="text" name="dti" id="dti" className="mt-2" />
        </label>
        <label htmlFor="loan_tenure" className="mt-6 relative">
          <span className="text-sm">Loan Tenure (months)</span>
          <input
            type="text"
            name="loan_tenure"
            id="loan_tenure"
            className="mt-2"
          />
        </label>
        <label htmlFor="monthly_repayment" className="mt-6 relative">
          <span className="text-sm">Monthly Loan Repayment</span>
          <input
            type="text"
            name="monthly_repayment"
            id="monthly_repayment"
            className="mt-2"
          />
        </label>

        <div className="flex items-center mt-6">
          <PrimaryButton
            buttonText="Generate"
            className="w-full"
            type="submit"
            onClick={() => {}}
          />
        </div>
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(GenerateAffordabilityModal);
