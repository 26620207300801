/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import {plus, times} from '../../assets/images/images';
import {
  customStyles,
  DropdownIndicator,
  Accordion,
  CreateAppForm,
} from '../../components';

import * as Actions from '../../store/actions';
import {EasyOnboardContext} from '../../helpers/context/EasyOnboardContext';
import {useMutation, useQueryClient} from 'react-query';
import {useAllApps} from '../../hooks/useApps';
import {updateApp} from '../../requests/queries/apps';
import {toast} from 'react-toastify';
import {useDebounce} from '../../hooks/useDebounce';
import {handleImage} from '../../helpers';

function Application({
  widgets: {singleWidget, singleApp, w_loading},
  setApp,
  setSingleApp,
  setAppOpen,
}) {
  const defaultFilename = 'Click here to upload your app logo';

  const [newAppForm, setNewAppForm] = useState(false);
  const [selectedApp, setSelectedApp] = useState({});
  const [state, setState] = useState('');
  const {setTrackChanges} = useContext(EasyOnboardContext);
  const [uploading, setLoading] = useState(null);
  const [appIcon, setIcon] = useState('');
  const [filename, setFilename] = useState(defaultFilename);

  const queryClient = useQueryClient();

  const appId =
    selectedApp?.value ||
    singleWidget?.widget?.app?._id ||
    singleApp?._id ||
    singleApp?.value;

  const {userApps: allApps, isLoading} = useAllApps();
  const {mutate, isLoading: updateLoading} = useMutation(
    'update-app',
    data => updateApp(appId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('all-apps');
        toast.success('App updated successfully');
      },
    },
  );

  const APP_DATA = useMemo(
    () =>
      (!isLoading &&
        allApps &&
        allApps?.map(app => ({
          label: app.name,
          value: app.value,
          name: app.name,
          color: app.color,
          logo: app.logo,
        }))) ||
      [],
    [allApps, isLoading],
  );
  const currentApp = useMemo(
    () => !isLoading && APP_DATA && APP_DATA.find(a => a?.value === appId),
    [APP_DATA, appId, isLoading],
  );

  const applyChanges = useCallback(
    ({icon, color}) => {
      setSingleApp({...singleApp, color: state || selectedApp?.color});
      mutate({
        name: selectedApp?.name,
        color_code:
          color ||
          state ||
          currentApp?.color ||
          selectedApp?.color ||
          singleApp?.color_code,
        icon_url: icon || currentApp?.logo || selectedApp?.logo,
      });
    },
    [state, appIcon],
  );

  const debounceFunc = useDebounce(applyChanges, 1000);

  const handleCallback = useCallback(
    ({loading, icon, name}) => {
      setLoading(loading);
      setIcon(icon);
      setFilename(name);
      debounceFunc({icon});
    },
    [appIcon],
  );

  useEffect(() => {
    if (currentApp) {
      setSingleApp(currentApp);
      setState(state || currentApp?.color);
      setIcon(currentApp?.logo);
      setApp(currentApp?.value);
      setFilename(currentApp?.logo ? 'Logo uploaded' : defaultFilename);
      setSingleApp({
        ...singleApp,
        ...currentApp,
        color: state || currentApp?.color,
        logo: appIcon || currentApp.logo,
        color_code: state || currentApp?.color,
      });
      setSelectedApp(currentApp || selectedApp);
    } else if (singleApp && singleApp?.value) {
      const {color_code, _id, name} = singleApp;
      const single = {
        color: color_code,
        value: _id,
        name,
        label: name,
      };
      setSingleApp(single);
      setState(state || single?.color);
      setIcon(single?.logo);
      setApp(currentApp?.value);
      setSelectedApp(selectedApp || single);
    } else if (APP_DATA !== null && APP_DATA[0]?.value) {
      setApp(APP_DATA[0]?.value);
      setSingleApp(APP_DATA[0]);
      setSelectedApp(APP_DATA[0]);
      setState(state || APP_DATA[0]?.color);
      setIcon(appIcon || APP_DATA[0]?.logo);
    }
  }, [currentApp, state, appIcon, APP_DATA && APP_DATA[0]?.value]);

  return (
    <Accordion
      noChildPadding
      customPadding="pl-0 pt-3 pr-4 pb-3"
      title="Application"
      onClick={setAppOpen}
      isOpen={!(!w_loading && !singleWidget?.widget?.app?._id)}
    >
      <p className="text-xs text-body leading-[18px]">
        Customize your widget with your brand logo and color
      </p>
      <div className=" border border-1 border-grey60 rounded mt-3  p-3">
        {!w_loading && !singleWidget?.widget?.app?._id ? (
          <>
            <label
              data-smallerlabel=""
              htmlFor="app"
              className="relative  mb-2"
            >
              Select app
            </label>
            <Select
              defaultValue={selectedApp || (APP_DATA && APP_DATA[0])}
              value={selectedApp || (APP_DATA && APP_DATA[0])}
              onChange={data => {
                setApp(data?.value || (APP_DATA && APP_DATA[0]?.value));
                setState(data.color);
                setSelectedApp(data);
                setSingleApp(data);
                setTrackChanges(true);
                setFilename(data?.logo ? 'Logo uploaded' : defaultFilename);
              }}
              options={APP_DATA}
              placeholder="Select app"
              components={{DropdownIndicator}}
              classNamePrefix="react-select"
              styles={customStyles}
              isSearchable={false}
              isDisabled={singleWidget?.widget?.app?._id}
            />
            <button
              className="flex items-center gap-[2px] ml-auto mt-2"
              onClick={() => setNewAppForm(prev => !prev)}
              type="button"
            >
              <img
                src={newAppForm ? times : plus}
                alt=""
                width={12}
                height={12}
              />
              <span className="text-xs font-medium text-body">
                {newAppForm ? 'Close' : 'Create a new app'}
              </span>
            </button>
          </>
        ) : (
          <div className="mt-4 bg-white80 p-4 border border-black25 rounded text-grey20 text-sm cursor-not-allowed">
            <p>{selectedApp?.name}</p>
          </div>
        )}

        {newAppForm && (
          <div className="p-6 mt-2 rounded bg-white80 animate-dropdown">
            <h3 className="text-base font-medium text-grey">Create App</h3>
            <CreateAppForm setOpen={() => setNewAppForm(prev => !prev)} />
          </div>
        )}

        <label data-smallerlabel="" htmlFor="upload_logo" className="mt-6">
          Logo
          <input
            type="file"
            id="upload_logo"
            onChange={e =>
              handleImage(e?.target?.files[0], handleCallback, 'App logo')
            }
            accept="image/png, image/gif, image/jpeg, image/jpg"
          />
          <div className="cursor-pointer bg-white80 mt-2 py-[14px] text-center border border-brandBlue border-dashed rounded w-full font-normal">
            <p className={`text-body ${uploading ? 'italic' : 'text-black'} `}>
              {uploading ? 'Uploading icon...' : filename}
            </p>
          </div>
        </label>

        <label data-smallerlabel="" htmlFor="theme_color" className="mt-6">
          Primary Color
          <div className="flex w-full gap-4">
            <input
              type="color"
              id="color_code"
              className="mt-2 p-0 max-w-[48px]"
              onChange={e => {
                setState(e.target.value);
                selectedApp?.color !== state &&
                  debounceFunc({color: e.target.value});
              }}
              value={state}
              disabled={updateLoading}
            />
            <input
              value={state}
              type="text"
              id="color_code"
              className="mt-2"
              placeholder="#00000"
              onChange={e => {
                setState(e.target.value);
                state !== e.target.value &&
                  debounceFunc({color: e.target.value});
              }}
              disabled={updateLoading}
            />
          </div>
        </label>
      </div>
    </Accordion>
  );
}

export default connect(state => state, Actions)(Application);
