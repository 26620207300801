import React from 'react';
import {
  Modal,
  DropdownIndicator,
  customStyles,
  PrimaryButton,
} from '../../../components';
import {closeModal} from '../../../assets/images/images';
import Select from 'react-select';

function PaymentInformationModal({show, onClose}) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <h3 className="font-medium text-base text-grey">
            Payment Information
          </h3>

          <button
            onClick={() => {
              onClose(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>

        <form className="mt-6">
          <label
            htmlFor="payment_information"
            className="relative text-body font-medium"
          >
            Payment method
          </label>
          <Select
            placeholder="Choose payment method"
            classNamePrefix="react-select"
            components={{DropdownIndicator}}
            styles={customStyles}
            className="text-start mt-2 mb-4 add_project_select !h-12"
            menuPlacement="auto"
          />

          <label htmlFor="full_name">
            <span className="text-sm text-body font-medium">Billed to</span>
            <input
              type="text"
              name="full_name"
              className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
              id="full_name"
              placeholder="Full name"
            />
          </label>

          <label htmlFor="email_address">
            <span className="text-sm text-body font-medium mt-6">
              Email address
            </span>
            <input
              type="text"
              name="email_address"
              className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
              id="email_address"
            />
          </label>

          <div className="flex items-center mt-6">
            <PrimaryButton
              buttonText="Save information"
              className="w-full rounded-lg"
              type="submit"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default PaymentInformationModal;
