import {FILTER_LOADING, APP_FILTERS, LOCATION} from '../types';

const initialState = {
  appFilters: null,
  location: null,
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case FILTER_LOADING:
      return {...state, loading: action.payload};
    case APP_FILTERS:
      return {...state, appFilters: action.payload};
    case LOCATION:
      return {...state, location: action.payload};

    default:
      return state;
  }
}
