import {logo, dojahFavicon, reconciloLogo} from '../assets/images/images';
import {setLocalStorageData} from './index';
import {MODULE} from '../store/types';

const DEFAULT_COLOR = '#3F7CDB';
const DEFAULT_TAGLINE = 'Generic company tagline';
const DOJAH_TAGLINE =
  'Verify Customer Identity Easily | KYC\nVerification | Dojah';

const updateTheme = ({colorCode, companyLogo, favicon, tagline, name}) => {
  document.title = name ? tagline || DEFAULT_TAGLINE : DOJAH_TAGLINE;
  document.body.style.setProperty('--color-primary', colorCode);
  setLocalStorageData('companyColor', colorCode);
  setLocalStorageData('companyLogo', companyLogo || logo);
  setLocalStorageData(
    'companyFavicon',
    name ? favicon || 'null' : dojahFavicon,
  );
};

export const getSubDomainTheme = async (subdomain, setLoading, dispatch) => {
  setLoading(true);
  const isReconciloSubdomain = subdomain.includes('reconcilo');

  if (isReconciloSubdomain) {
    setLocalStorageData('subdomain', subdomain);
    setLocalStorageData('companyLogo', reconciloLogo);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/users/company/context?subdomain=${subdomain}`,
    );
    const data = await response.json();

    if (data?.company?.name) {
      setLocalStorageData('subdomain', subdomain);
      const {
        color_code,
        logo,
        tagline,
        favicon,
        name,
        modularisation,
        is_partner_company,
      } = data.company;

      dispatch({
        type: MODULE,
        payload: {...modularisation, is_partner_company},
      });

      updateTheme({
        colorCode: color_code || DEFAULT_COLOR,
        companyLogo: isReconciloSubdomain ? reconciloLogo : logo,
        tagline,
        favicon,
        name,
      });
    } else {
      updateTheme({
        colorCode: DEFAULT_COLOR,
        companyLogo: isReconciloSubdomain ? reconciloLogo : logo,
      });
    }
  } catch (error) {
    console.error('Error fetching company theme:', error);
    updateTheme({
      colorCode: DEFAULT_COLOR,
      companyLogo: isReconciloSubdomain ? reconciloLogo : logo,
    });
  } finally {
    setLoading(false);
  }
};
