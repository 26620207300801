import {
  GET_FLOW,
  CASES_IDS,
  GET_FLOWS,
  GET_CASES,
  GET_EVENTS,
  FLOW_LOADING,
  GET_CASES_META,
  GET_TOTAL_EVENTS,
  GET_TOTAL_ALLOWED,
  GET_FLOW_OVERVIEW,
  GET_PENDING_CASES,
  FRAUD_FLOW_PROCESS,
  GET_TOTAL_PENDING,
  GET_TOTAL_BLOCKED,
  GET_FLOWS_THRESHOLD,
  CREATE_FLOW_PROCESS,
  GET_FLOW_OVERVIEW_GRAPH,
  FLOW_GRAPH_LOADING,
  NEXT_AND_PREV_CASE_ID,
  GET_CASES_COMMENTS,
  GET_CASES_DOCUMENTS,
  CASES_DOCUMENTS_UPLOAD,
  CASES_DOCUMENTS_DELETE,
  CASES_COMMENT_DELETE,
  CASES_COMMENT_SAVE,
  GET_CASES_COMMENTS_LOADING,
} from '../types';

const initialState = {
  flow: null,
  flows: null,
  cases: null,
  events: null,
  caseIds: null,
  loading: false,
  overview: null,
  cases_meta: null,
  totalEvents: null,
  flowProcess: null,
  totalAllowed: null,
  graphLoading: null,
  totalPending: null,
  totalBlocked: null,
  flowThreshold: null,
  pending_cases: null,
  overviewGraph: null,
  cases_comments: null,
  cases_documents: null,
  fraudFlowProcess: null,
  nextAndPrevious: null,
  uploadDocsLoading: null,
  deleteDocsLoading: null,
  saveCommentLoading: null,
  deleteCommentLoading: null,
  commentsLoading: null,
};

export default function flows(state = initialState, action) {
  switch (action.type) {
    case GET_FLOW:
      return {...state, flow: action.payload};
    case GET_TOTAL_EVENTS:
      return {...state, totalEvents: action.payload};
    case GET_TOTAL_ALLOWED:
      return {...state, totalAllowed: action.payload};
    case GET_TOTAL_PENDING:
      return {...state, totalPending: action.payload};
    case GET_TOTAL_BLOCKED:
      return {...state, totalBlocked: action.payload};
    case FLOW_LOADING:
      return {...state, loading: action.payload};
    case CASES_DOCUMENTS_DELETE:
      return {...state, deleteDocsLoading: action.payload};
    case CASES_COMMENT_DELETE:
      return {...state, deleteCommentLoading: action.payload};
    case CASES_COMMENT_SAVE:
      return {...state, saveCommentLoading: action.payload};
    case GET_CASES_COMMENTS_LOADING:
      return {...state, commentsLoading: action.payload};

    case NEXT_AND_PREV_CASE_ID:
      return {...state, nextAndPrevious: action.payload};

    case FLOW_GRAPH_LOADING:
      return {...state, graphLoading: action.payload};
    case CASES_DOCUMENTS_UPLOAD:
      return {...state, uploadDocsLoading: action.payload};
    case GET_FLOWS:
      return {...state, flows: action.payload};
    case GET_FLOWS_THRESHOLD:
      return {...state, flowThreshold: action.payload};
    case GET_EVENTS:
      return {...state, events: action.payload};
    case GET_CASES:
      return {...state, cases: action.payload};
    case CASES_IDS:
      return {...state, caseIds: action.payload};
    case GET_PENDING_CASES:
      return {...state, pending_cases: action.payload};
    case GET_CASES_META:
      return {...state, cases_meta: action.payload};
    case GET_CASES_COMMENTS:
      return {...state, cases_comments: action.payload};
    case GET_CASES_DOCUMENTS:
      return {...state, cases_documents: action.payload};
    case GET_FLOW_OVERVIEW:
      return {...state, overview: action.payload};
    case GET_FLOW_OVERVIEW_GRAPH:
      return {...state, overviewGraph: action.payload};
    case CREATE_FLOW_PROCESS:
      return {...state, flowProcess: action.payload};
    case FRAUD_FLOW_PROCESS:
      return {...state, fraudFlowProcess: action.payload};

    default:
      return state;
  }
}
