import {api} from '../../helpers';

function fetchCustomerPool(appIds, params) {
  return api
    .get(`/users/customer-pool${appIds && `?app_ids=${appIds}`}`, {params})
    .then(res => res.data);
}
function addComment(customer_id, data) {
  return api
    .post(`/users/customer-pool/${customer_id}/comments`, {
      ...data,
      type: 'customer',
    })
    .then(res => res.data);
}
function fetchComments(customer_id) {
  return api
    .get(`/users/customer-pool/${customer_id}/comments`)
    .then(res => res.data);
}
function fetchLiveness(customer_id) {
  return api
    .get(`/users/customer-pool/${customer_id}/liveness`)
    .then(res => res.data);
}
function fetchAddress(customer_id) {
  return api
    .get(`/users/customer-pool/${customer_id}/address`)
    .then(res => res.data);
}
function fetchDocuments(customer_id) {
  return api
    .get(`/users/customer-pool/${customer_id}/documents`)
    .then(res => res.data);
}
function fetchUserData(customer_id) {
  return api
    .get(`/users/customer-pool/${customer_id}/user-data`)
    .then(res => res.data);
}
function fetchIp(customer_id) {
  return api
    .get(`/users/customer-pool/${customer_id}/ip`)
    .then(res => res.data);
}
function fetchImageMatch(customer_id) {
  return api
    .get(`/users/customer-pool/${customer_id}/image-match`)
    .then(res => res.data);
}
function fetchCustomerDetails(userId) {
  return api.get(`/users/customer-pool/${userId}`).then(res => res.data);
}
function fetchCustomerAml(customer_id) {
  return api
    .get(`/users/customer-pool/${customer_id}/aml`)
    .then(res => res.data);
}
function deleteComment(customer_id, commentId) {
  return api
    .delete(`/users/customer-pool/${customer_id}/comments/${commentId}`)
    .then(res => res.data);
}
function fetchGovernmentData(customer_id) {
  return api
    .get(`/users/customer-pool/${customer_id}/entity`)
    .then(res => res.data);
}

export {
  fetchCustomerPool,
  addComment,
  fetchComments,
  deleteComment,
  fetchCustomerDetails,
  fetchLiveness,
  fetchAddress,
  fetchDocuments,
  fetchUserData,
  fetchIp,
  fetchImageMatch,
  fetchGovernmentData,
  fetchCustomerAml,
};
