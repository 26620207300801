import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';

import Select from 'react-select';
import {toast} from 'react-toastify';
import {
  Modal,
  PrimaryButton,
  DropdownIndicator,
  customStyles,
  MultiValueRemove,
} from '../../components';
import {signUpServices} from '../../helpers';

import * as Actions from '../../store/actions';

function AddCompanyModal({auth: {loading}, addNewCompany, open, setOpen}) {
  const [selectedServices, setSelectedServices] = useState(null);
  const [userCompany, setCompany] = useState(null);

  const isNotEmpty = userCompany && selectedServices?.length;

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      if (isNotEmpty) {
        const interests = {};

        for (let i = 0; i < selectedServices.length; i++) {
          const key = Object.values(selectedServices[i])[0];

          interests[key.replace(' ', '_')] = true;
        }
        const companyData = {
          company: userCompany,
          interests,
        };
        addNewCompany(companyData, setOpen);
      } else {
        toast.error('Please fill all the fields');
      }
    },
    [userCompany, isNotEmpty, selectedServices, addNewCompany, setOpen],
  );

  return (
    <Modal
      show={open}
      modalTitle="Add a new company"
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <form className="mt-6">
        <label htmlFor="company_name" className="mt-6 relative">
          Company name
          <input
            id="company_name"
            onChange={e => setCompany(e.target.value)}
            className="mt-2"
            placeholder="Dojah"
          />
        </label>

        <div className="text-left">
          <label htmlFor="services" className="mt-6 mb-2 relative">
            Services needed
          </label>
          <Select
            defaultValue={selectedServices}
            onChange={setSelectedServices}
            options={signUpServices}
            isMulti
            placeholder="Select service(s)"
            className="basic-multi-select"
            classNamePrefix="react-select"
            components={{DropdownIndicator, MultiValueRemove}}
            styles={customStyles}
            menuPlacement="auto"
          />
        </div>

        <PrimaryButton
          onClick={handleSubmit}
          loading={loading}
          disabled={loading || !isNotEmpty}
          buttonText="Create Company"
          className="w-full mt-6"
        />
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(AddCompanyModal);
