import React from 'react';
import VerificationAnalyticsLineChart from '../../components/VerificationAnalyticsLineChart';

export default function VerificationLineChart({
  chartData = [],
  customElements,
  periodType,
  loading,
}) {
  return (
    <div className="mt-6 bg-white px-[10px] py-4 rounded-lg">
      <div className="px-4 relative">
        <p className="text-base text-[#4A4A68] font-medium">
          No. of Verifications
        </p>

        <div
          className={`flex absolute top-[-12px] lg:top-3 z-10 flex-col right-[-12px] lg:right-3 lg:flex-row gap-5 lg:gap-0 justify-between items-center   px-6`}
        >
          {customElements}
        </div>
      </div>
      <VerificationAnalyticsLineChart
        data={chartData}
        periodType={periodType}
        loading={loading}
      />
    </div>
  );
}
