import React, {useEffect, useState} from 'react';
import {
  customStyles,
  DropdownIndicator,
  FilterButton,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../../components';
import ReconciliationExpectedPayments from './ReconciliationExpectedPayments';
import Select from 'react-select';
import {useParams} from 'react-router-dom';
import {useAccounts} from './hooks/useAccounts';

function Reconciliation() {
  const {slug} = useParams();
  const {accountSelectionData, isLoading} = useAccounts({slug});
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState();

  useEffect(() => {
    if (!isLoading && accountSelectionData.length > 0) {
      setSelectedAccount(accountSelectionData[0]);
    } else {
      setSelectedAccount({label: 'No account found', value: ''});
    }
  }, [accountSelectionData, isLoading]);

  return (
    <>
      <div className="pl-11 pr-8 mt-10">
        <div className="flex items-center mt-3 gap-4 mb-6 ">
          <div>
            <Select
              defaultValue={selectedAccount}
              value={selectedAccount}
              placeholder="Select an account"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              className="text-start mt-2 add_project_select w-[250px]"
              menuPlacement="auto"
              options={accountSelectionData}
              onChange={data => {
                setSelectedAccount(data);
              }}
            />
          </div>
          {/*<h1 className="text-[#080917] font-medium font-inter ml-auto">*/}
          {/*  Reconcile transaction*/}
          {/*</h1>*/}
          <PrimaryButton
            buttonText="Reconcile Transaction"
            className="rounded-lg ml-auto"
          />
        </div>

        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="bg-white border relative border-[#ececec] rounded-xl h-[750px] overflow-auto">
            <div className="flex items-center gap-4 py-5 px-4">
              <p className="font-inter text-[#080917] font-medium text-tiny">
                Transactions
              </p>
              <FilterButton
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                className="h-[30px]"
              />
            </div>
            <TableLayout className="mt-4 w-full font-inter">
              <thead className="text-small font-medium uppercase">
                <tr className="bg-white80 border border-transparent border-y-[#ececec]">
                  <th className="p-3 pl-4 font-medium text-left text-xs text-grey whitespace-nowrap w-[27%]">
                    date created
                  </th>
                  <th className="p-3 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap w-[19%]">
                    ref id
                  </th>
                  <th className="p-3 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap w-[22%]">
                    amount
                  </th>
                  <th className="p-3 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap w-[14%]">
                    type
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm text-body">
                <tr className="text-left border-b-[0.5px] bg-white cursor-pointer border-[#ECECEC] ">
                  <td className="p-4 pl-4 w-[27%] truncate max-w-[130px]">
                    12th, May 2022, 5:09pm
                  </td>
                  <td className="p-4 pl-0 truncate max-w-[90px] w-[19%]">
                    TXN24877844
                  </td>
                  <td className="p-4 pl-0 truncate max-w-[100px] w-[22%]">
                    ₦24,678.00
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap w-[14%]">
                    <span className="uppercase bg-[#F8EBEA] w-fit text-[#961D14] h-5 flex items-center justify-center font-medium text-xs rounded-full px-2 py-1">
                      debit
                    </span>
                  </td>
                </tr>
              </tbody>
            </TableLayout>

            <div className="flex items-center justify-between absolute bottom-0 w-full flex-wrap gap-4 px-3 my-6">
              <PageLimit total={100} length={10} limit={100} />

              <Pagination total={5} current={1} />
            </div>
          </div>

          <ReconciliationExpectedPayments />
        </div>
      </div>
    </>
  );
}

export default Reconciliation;
