import React from 'react';

export const Email = () => (
  <>
    <header className="flex flex-col items-center mt-2">
      <h4 className="mt-4 font-medium text-grey">Email</h4>
    </header>
    <form className="w-full max-w-sm">
      <label htmlFor="email" className="mt-4 mb-1">
        Email
        <input disabled type="email" id="email" className="mt-2 text-sm" />
      </label>
    </form>{' '}
  </>
);
