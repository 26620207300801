export const getGreetingTime = currentTime => {
  if (!currentTime || !currentTime.isValid()) {
    return 'Hello';
  }

  const splitAfternoon = 12;
  const splitEvening = 17;
  const currentHour = parseFloat(currentTime.format('HH'));

  if (currentHour >= splitAfternoon && currentHour < splitEvening) {
    return 'Good afternoon';
  } else if (currentHour >= splitEvening) {
    return 'Good evening';
  }

  return 'Good morning';
};

// const DATA = {
//   1: [
//     {
//       id: 'efdd2068-aa29-43a4-b485-128c85d16efd',
//       title: 'The Catcher in the Rye',
//       author: 'J.D. Salinger',
//       year: '1951',
//     },
//     {
//       id: '7561048f-2083-4d57-b9f9-d136f4285b04',
//       title: 'To Kill a Mockingbird',
//       author: 'Harper Lee',
//       year: '1960',
//     },
//     {
//       id: '03ed581e-6b5c-4e28-a2e1-7eed41ebe89b',
//       title: '1984',
//       author: 'George Orwell',
//       year: '1949',
//     },
//     {
//       id: '9ad2c855-19b1-48b7-a213-80b4399f9063',
//       title: 'The Great Gatsby',
//       author: 'F. Scott Fitzgerald',
//       year: '1925',
//     },
//     {
//       id: '9291d324-bb32-45ac-b1ed-9f3ce5a90556',
//       title: 'Pride and Prejudice',
//       author: 'Jane Austen',
//       year: '1813',
//     },
//   ],
//   2: [
//     {
//       id: 'f456bb7f-df40-4eca-83fa-350fe0f296dd',
//       title: 'Brave New World',
//       author: 'Aldous Huxley',
//       year: '1932',
//     },
//     {
//       id: '507b1c47-f48f-4e7a-a568-d3876bd2bfb0',
//       title: 'The Hobbit',
//       author: 'J.R.R. Tolkien',
//       year: '1937',
//     },
//     {
//       id: '1abd55fa-95c7-4b7c-b0d7-787993b04229',
//       title: 'The Lord of the Rings',
//       author: 'J.R.R. Tolkien',
//       year: '1954',
//     },
//     {
//       id: 'b035ba55-d1b5-4228-8e06-c0a765ba25ec',
//       title: "The Hitchhiker's Guide to the Galaxy",
//       author: 'Douglas Adams',
//       year: '1979',
//     },
//     {
//       id: 'f217b3da-b806-40cc-a8c5-a0f431588561',
//       title: 'One Hundred Years of Solitude',
//       author: 'Gabriel Garcia Marquez',
//       year: '1967',
//     },
//   ],
//   3: [
//     {
//       id: '386faa29-8c5e-4300-8eb3-85aecf15ce21',
//       title: 'The Alchemist',
//       author: 'Paulo Coelho',
//       year: '1988',
//     },
//     {
//       id: '8405e563-043d-4d19-8767-b008d4fc4801',
//       title: 'The Picture of Dorian Gray',
//       author: 'Oscar Wilde',
//       year: '1890',
//     },
//     {
//       id: '5b20bf94-af52-4dd3-a8ff-a20ea34f8abb',
//       title: 'The Adventures of Sherlock Holmes',
//       author: 'Arthur Conan Doyle',
//       year: '1892',
//     },
//     {
//       id: '45af51dc-a6fe-4be6-b68b-1b7b3193405e',
//       title: 'Jane Eyre',
//       author: 'Charlotte Bronte',
//       year: '1847',
//     },
//     {
//       id: 'e7db7888-7fc5-46ed-995b-70fb105a6f85',
//       title: 'Wuthering Heights',
//       author: 'Emily Bronte',
//       year: '1847',
//     },
//   ],
//   4: [
//     {
//       id: 'f42e8084-1ded-4d61-b2a2-25a5266eff34',
//       title: 'The Time Machine',
//       author: 'H.G. Wells',
//       year: '1895',
//     },
//     {
//       id: '5090b547-441c-4f24-a8ab-9842a05086a3',
//       title: 'The War of the Worlds',
//       author: 'H.G. Wells',
//       year: '1898',
//     },
//     {
//       id: 'fcb81880-4e5f-4bf2-8858-e8d59cc5cd88',
//       title: 'The Count of Monte Cristo',
//       author: 'Alexandre Dumas',
//       year: '1844',
//     },
//     {
//       id: '544bd629-42ec-452a-895d-6c546053d2e1',
//       title: 'Les Miserables',
//       author: 'Victor Hugo',
//       year: '1862',
//     },
//   ],
// };
