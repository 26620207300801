import React from 'react';
import Select from 'react-select';
import {
  eyeCapBlue,
  eyeCapGreen,
  // eyeCapRed,
} from '../../../assets/images/images';
import {Card, Wrapper} from '../FraudCheckScreens';
import {customStyles, DropdownIndicator} from '../../../components';
import {useFraudCheck, usePersistFraudData} from '../../../hooks/useFraudCheck';

export const DetectWithAmlScreening = () => {
  const {setSelectedStatus, selectedStatus, setConfig, config, STATUSES} =
    useFraudCheck({
      action_returned: 'pending',
    });
  const actionReturned = usePersistFraudData({
    config,
    selectedStatus,
    actionKey: 'action_returned',
    COLLECTION: STATUSES,
  });

  return (
    <Wrapper>
      <Card
        extension={
          <div className="flex flex-col gap-1">
            <p className="text-grey font-medium">AML Screening</p>
            <p className="text-body text-sm gap-1">
              Screen customers against global AML Watchlists.
            </p>
          </div>
        }
        src={eyeCapBlue}
      />
      <Card
        extension={<p className="text-grey font-medium">If info is returned</p>}
        src={eyeCapGreen}
      >
        <div className="text-start w-full">
          <label htmlFor="status" className="mb-2 relative">
            Set status to
          </label>
          {actionReturned !== undefined && (
            <Select
              defaultValue={actionReturned}
              value={actionReturned || STATUSES[0]}
              onChange={status => {
                setSelectedStatus(status);
                setConfig({...config, action_returned: status.value});
              }}
              options={STATUSES}
              className="basic-multi-select"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              menuPlacement="auto"
              isSearchable={false}
            />
          )}
        </div>
      </Card>
      {/*<Card*/}
      {/*  extension={*/}
      {/*    <p className="text-grey font-medi  um">If no info is returned</p>*/}
      {/*  }*/}
      {/*  src={eyeCapRed}*/}
      {/*>*/}
      {/*  <div className="text-start w-full">*/}
      {/*    <label htmlFor="status" className="mb-2 relative">*/}
      {/*      Set status to*/}
      {/*    </label>*/}
      {/*    {actionNotReturned !== undefined && (*/}
      {/*      <Select*/}
      {/*        defaultValue={actionNotReturned}*/}
      {/*        onChange={status => {*/}
      {/*          setSelectedStatus(status);*/}
      {/*          setConfig({...config, action_not_returned: status.value});*/}
      {/*        }}*/}
      {/*        options={STATUSES}*/}
      {/*        className="basic-multi-select"*/}
      {/*        classNamePrefix="react-select"*/}
      {/*        components={{DropdownIndicator}}*/}
      {/*        styles={customStyles}*/}
      {/*        menuPlacement="auto"*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*</Card>*/}
    </Wrapper>
  );
};
