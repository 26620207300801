import React from 'react';
import {connect} from 'react-redux';
import PuffLoader from 'react-spinners/PuffLoader';
import {css} from '@emotion/react';
import {useMutation, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Actions from '../../store/actions';
import {Modal, SecondaryButton} from '../../components';
import {deleteApiToken} from '../../requests/queries/tokens';

function DeleteTokenModal({open, setOpen, selectedToken}) {
  const queryClient = useQueryClient();

  const override = css`
    border-color: #ffffff;
    position: absolute;
    background: transparent;
  `;

  const {isLoading, mutate} = useMutation(data => {
    deleteApiToken(selectedToken?._id, data);
  });

  const handleSubmit = e => {
    e.preventDefault();

    mutate(
      {name: selectedToken?.name},
      {
        onSuccess: () => {
          toast.success('Token deleted successfully');
          setOpen(false);
          queryClient.invalidateQueries(['api-tokens']);
        },
        onError: error => {
          toast.error(error.response.data.error);
        },
      },
    );
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Delete token"
    >
      <form onSubmit={handleSubmit}>
        <p className="mt-4 text-sm text-body text-start">
          By deleting this token, you are deleting every API calls and
          identifications and verifications done with the token.
        </p>

        <div className="mt-4 flex items-center gap-4">
          <SecondaryButton buttonText="Cancel" onClick={() => setOpen(false)} />
          <button
            type="submit"
            className={` ${
              isLoading
                ? 'flex items-center justify-center relative h-12 px-4 py-6'
                : ''
            } text-white text-sm font-medium bg-danger py-4 px-[81px] rounded`}
          >
            {isLoading ? (
              <PuffLoader css={override} size={35} color="#ffffff" />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(DeleteTokenModal);
