import React, {useCallback, useEffect, useState} from 'react';
import {DashboardLayout, Modal, SecondaryButton} from '../../../components';
import {closeModal, projectsMobileNav} from '../../../assets/images/images';
import {useMutation, useQueryClient} from 'react-query';
import {createSubscription} from '../../../requests/queries/reconciliations';
import {billingFeatures} from './constants';
import {
  FeatureList,
  handleBillingPlans,
  PlanCard,
} from './components/BillingComponents';
import {toast} from 'react-toastify';
import StripeCheckoutForm from '../../../components/StripeCheckoutForm';
import {Elements} from '@stripe/react-stripe-js';
import {
  appearance,
  stripePromise,
} from '../../../helpers/constants/stripeConstants';
import {useBillingInfo} from './hooks/useBillingInfo';
import {useCancelSubscription} from './hooks/useCancelSubscription';

const BillingModal = ({
  options,
  open,
  setOpen,
  stripeReference,
  setClientSecret,
}) => {
  const handleClose = () => {
    setOpen(false);
    setClientSecret('');
  };

  return (
    <Modal
      show={open}
      onClose={handleClose}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-base font-medium text-grey">Stripe Payment</h3>

          <button onClick={handleClose}>
            <img src={closeModal} alt="" />
          </button>
        </header>
        <div className="flex flex-col gap-4 mt-6">
          <Elements options={options} stripe={stripePromise}>
            <StripeCheckoutForm
              noMessage
              setOpen={handleClose}
              reference={stripeReference}
            />
          </Elements>
        </div>
      </div>
    </Modal>
  );
};

function Billing() {
  const [clientSecret, setClientSecret] = useState();
  const [stripeReference, setStripeReference] = useState();
  const [currentPlan, setCurrentPlan] = useState('');
  const [open, setOpen] = useState();
  const queryClient = useQueryClient();

  const {billingInfo} = useBillingInfo({isInfo: true});

  const options = {
    clientSecret,
    appearance,
  };

  const billingMutation = useMutation(createSubscription, {
    onSuccess: data => {
      setClientSecret(data.clientSecret);
      setStripeReference(data.reference);
      queryClient.invalidateQueries();
    },
    onError: error => {
      console.error('Error making payment:', error);
      toast.error('An error occurred while processing your payment.');
    },
  });
  const {handleCancelSubscription, cancelSubscriptionMutation} =
    useCancelSubscription({billingInfo});

  useEffect(() => {
    if (clientSecret) {
      setOpen(true);
    }
  }, [clientSecret]);

  const handleBillingSubscription = useCallback(
    async (plan, amount) => {
      if (!plan) return;

      try {
        await billingMutation.mutateAsync(
          {plan, amount},
          {
            onSuccess: data => {
              console.log(data);
            },
          },
        );
      } catch (err) {
        toast.error(err.response.data.error);
      }
    },
    [billingMutation],
  );

  const billingPlans = handleBillingPlans(handleBillingSubscription);

  const currentBillingPlan =
    billingInfo?.plan?.toLowerCase() === 'basic'
      ? 'base'
      : billingInfo?.plan?.toLowerCase();

  const setActivePlan = plan =>
    !(
      (currentBillingPlan === 'base' && plan === 'base') ||
      (plan === currentBillingPlan && billingInfo?.billing_status) ||
      plan === currentBillingPlan
    );

  return (
    <DashboardLayout
      bg="bg-white80"
      topPadding="pt-8"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={projectsMobileNav} alt="Projects Mobile Navigation" />
          <p className="ml-2 -mb-1">Billing {'>'} Plan</p>
        </div>
      }
    >
      <p className="text-grey font-medium text-lg mt-10 lg:mt-0">
        Billing Plans
      </p>

      <div className="mt-7 bg-white border border-[#EFF2F5] rounded-2xl py-9 px-6 flex flex-col lg:flex-row lg:items-center justify-between">
        <div className="flex flex-col">
          <p className="text-body font-medium text-sm">Current Plan</p>
          <h3 className="text-grey font-semibold text-xxl capitalize">
            {billingInfo?.plan === 'Basic'
              ? 'Base'
              : billingInfo?.plan || 'Base'}{' '}
            tier
          </h3>
        </div>

        <BillingModal
          options={options}
          open={open}
          setOpen={setOpen}
          stripeReference={stripeReference}
          setClientSecret={setClientSecret}
        />
        <div className="flex items-center gap-2">
          {billingInfo?.billing_status && (
            <SecondaryButton
              buttonText="Cancel Subscription"
              className="text-xs !py-2 !px-3 h-fit rounded-lg font-inter text-error !outline-[#d7dde4]"
              loading={cancelSubscriptionMutation?.isLoading}
              onClick={handleCancelSubscription}
            />
          )}
        </div>
      </div>

      <div className="mt-6 flex flex-col lg:flex-row lg:items-center">
        <div className="flex flex-col pl-2 mr-9">
          <h3 className="font-semibold text-deepBlue text-[30px]">All Plans</h3>
          <p className="font-medium text-xs text-body">
            For all your reconciliation needs
          </p>
        </div>

        <div className="grid lg:grid-cols-4 grid-cols-1 gap-4 w-full mt-4 lg:mt-0">
          {billingPlans.map(plan => (
            <PlanCard
              key={plan?.title}
              title={plan.title}
              price={plan.price}
              plan={plan.plan}
              onChoose={() => {
                plan?.onChoose();
                setCurrentPlan(plan.title);
              }}
              isPrimary={setActivePlan(plan?.plan)}
              isLoading={
                plan.title === currentPlan ? billingMutation.isLoading : null
              }
            />
          ))}
        </div>
      </div>

      <div className="mt-2">
        <FeatureList features={billingFeatures} />
      </div>
    </DashboardLayout>
  );
}

export default Billing;
