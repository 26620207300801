import {setEnvCookie} from '../../helpers/setCookie';
import {
  GET_ALL_APPS,
  GET_APPS,
  CLEAR_ALL_APPS,
  SET_LIVE,
  UPDATED,
  LOGOUT,
  APP_LOADING,
  M_APP_LOADING,
} from '../types';

const initialState = {
  allApps: null,
  apps: null,
  live: null,
  updated: false,
  appLoading: false,
  mAppLoading: false,
};

export default function apps(state = initialState, action) {
  switch (action.type) {
    case UPDATED:
      return {...state, updated: !state.updated};
    case APP_LOADING:
      return {...state, appLoading: action.payload};
    case M_APP_LOADING:
      return {...state, mAppLoading: action.payload};
    case GET_ALL_APPS:
      return {...state, allApps: action.payload};
    case GET_APPS:
      return {...state, apps: action.payload};
    case CLEAR_ALL_APPS:
      return {...state, allApps: null};
    case SET_LIVE:
      setEnvCookie(action.payload);
      return {...state, live: action.payload};
    case LOGOUT:
      return {...initialState, live: state.live};
    default:
      return state;
  }
}
