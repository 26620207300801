import React, {useState} from 'react';
import {
  step1,
  step2,
  step3,
  welcomeScreen1,
  welcomeScreen2,
  welcomeScreen2Shadow,
  welcomeScreen3,
  welcomeScreen3Shadow,
  welcomeScreen4,
  welcomeScreen4Shadow,
  welcomeScreen5,
} from '../../assets/images/images';
import {Modal, PrimaryButton, SecondaryButton} from '../../components';
import {useMutation} from 'react-query';
import {updateUserProfile} from '../../requests/queries/auth';
import {SET_USER} from '../../store/types';
import {useDispatch} from 'react-redux';

function Wrapper({children, show, setOpen, onClose}) {
  return (
    <Modal
      modalBg="bg-white80"
      show={show}
      onClose={onClose}
      setOpen={setOpen}
      extraModalClasses="md:max-w-[427px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center items-center"
    >
      <div className="max-h-full h-[563px] flex flex-col justify-end items-center px-10 py-8">
        {children}
      </div>
    </Modal>
  );
}
const steps = [
  {
    title: 'Welcome to Dojah',
    image: welcomeScreen1,
    text: 'Take a tour of your dashboard for easy navigation',
    primaryButtonText: 'Take a tour',
    secondaryButtonText: 'Skip',
  },
  {
    title: 'Click here to switch between your accounts',
    image: welcomeScreen2,
    shadowImage: welcomeScreen2Shadow,
    text: 'You can create multiple companies and easily switch between them',
    primaryButtonText: 'Next',
    secondaryButtonText: 'Close',
    stepIndicator: step1,
  },
  {
    title: 'Access Dev tools',
    image: welcomeScreen3,
    shadowImage: welcomeScreen3Shadow,
    text: 'Create App, view your API call results and check the documentation for a more detailed guide on using Dojah',
    primaryButtonText: 'Next',
    secondaryButtonText: 'Close',
    stepIndicator: step2,
  },
  {
    title: 'Join our Slack',
    image: welcomeScreen4,
    shadowImage: welcomeScreen4Shadow,
    text: 'Join our slack channel to chat directly with customer and developer support and learn more about Dojah',
    primaryButtonText: 'Next',
    secondaryButtonText: 'Close',
    stepIndicator: step3,
  },
  {
    title: 'More info',
    image: welcomeScreen5,
    primaryButtonText: 'Complete tour',
  },
];

const WelcomeModals = ({open, setOpen, closeModal}) => {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const {mutate, isLoading} = useMutation('update-user', updateUserProfile, {
    onSuccess: data => {
      closeModal();
      dispatch({payload: data, type: SET_USER});
    },
  });

  const close = () => {
    closeModal();
    mutate({first_time_user: false});
  };

  const goToStep = newStep => {
    setStep(newStep);
  };

  if (step < steps.length) {
    const currentStep = steps[step];

    return (
      <Wrapper onClose={setOpen} setOpen={setOpen} show={open}>
        <p
          className={`mb-auto font-medium text-grey ${
            step === 0 ? '' : 'w-2/3'
          }`}
        >
          {currentStep.title}
        </p>
        {currentStep.shadowImage && (
          <div className="relative mt-8">
            <img src={currentStep.image} alt="" />
            <img
              src={currentStep.shadowImage}
              alt=""
              className={`absolute ${
                step === 2
                  ? '-left-[32px] -top-[5px]'
                  : step === 3
                  ? '-right-[-112px] -top-[52px]'
                  : '-right-[41px] -top-[52px]'
              } `}
            />
          </div>
        )}
        {!currentStep.shadowImage && <img src={currentStep.image} alt="" />}
        <p
          className="mt-6 mb-4 text-sm text-body text-center"
          style={{maxWidth: step === 0 ? '209px' : '371px'}}
        >
          {currentStep.text}
        </p>
        {currentStep.stepIndicator && (
          <img src={currentStep.stepIndicator} alt="" />
        )}
        {step < steps.length - 1 && (
          <div className="flex items-center w-full gap-4 mt-12">
            <PrimaryButton
              buttonText={currentStep.primaryButtonText}
              className="w-2/3"
              onClick={() => goToStep(step + 1)}
            />
            <SecondaryButton
              buttonText={currentStep.secondaryButtonText}
              className="w-1/3"
              onClick={close}
            />
          </div>
        )}
        {step === steps.length - 1 && (
          <div className="flex items-center w-full gap-4 mt-12">
            <PrimaryButton
              buttonText={currentStep.primaryButtonText}
              className="w-full"
              onClick={close}
              loading={isLoading}
            />
          </div>
        )}
      </Wrapper>
    );
  }

  return <Wrapper>{step}</Wrapper>;
};

export default WelcomeModals;
