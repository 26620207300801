import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import {toast} from 'react-toastify';
import {Accordion, DropdownIndicator, customStyles} from '../../components';
import * as Actions from '../../store/actions';
import {copyBlue} from '../../assets/images/images';

const INTEGRATION = [
  {label: 'Web SDK', value: 'web_sdk'},
  {label: 'Flow link', value: 'flow_link'},
  {label: 'Mobile', value: 'mobile'},
];

function Integration({
  setPage,
  setWidgetScreen,
  setScreenType,
  widgets: {screenType, singleWidget},
  auth: {userDetails},
}) {
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const hasWidgetUrl = !!localStorage.subdomain && !!userDetails?.widgetUrl;
  const customWidgetUrl = hasWidgetUrl
    ? userDetails?.widgetUrl
    : userDetails?.company?.widget_url;

  useEffect(() => {
    if (screenType === 'integration') {
      setWidgetScreen({page: 'web_sdk'});
    }
  }, [screenType, setWidgetScreen]);

  const handleSelectedIntegration = (value, page) => {
    setWidgetScreen({
      ...page,
      isActive: value?.value,
    });
    setPage(page?.page);
  };

  const copyText = text => {
    navigator.clipboard.writeText(text);
    toast.success('Link copied');
  };

  return (
    <Accordion
      title="Integration"
      onClick={() => {
        setScreenType('integration');
      }}
    >
      <p className="text-xs text-body leading-[18px]">
        Select your preferred integration.
      </p>

      <div className="relative mt-4">
        <Select
          defaultValue={selectedIntegration}
          value={selectedIntegration}
          options={INTEGRATION}
          onChange={int => {
            setSelectedIntegration(int);
            handleSelectedIntegration(int, int);
            setWidgetScreen({
              page: int.value,
            });
          }}
          placeholder="Select an integration type"
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="top"
        />
      </div>

      {selectedIntegration?.value === 'flow_link' ? (
        <div className="mt-4">
          <p className="text-sm text-body">Copy link</p>
          <div className="relative mt-1">
            <button
              onClick={() =>
                copyText(
                  `${
                    customWidgetUrl ?? process.env.REACT_APP_WIDGET_BASE_URL
                  }?widget_id=${singleWidget?.widget?._id}`,
                )
              }
              className="absolute flex items-center gap-2 p-2 -translate-y-1/2 border rounded top-2/4 right-4 border-brandBlue"
            >
              <img src={copyBlue} alt="" width={14} height={14} />
              <span className="text-xs font-medium text-brandBlue">Copy</span>
            </button>
            <input
              type="text"
              id="confidence_level"
              className="text-sm text-grey"
              value={
                singleWidget?.widget?._id
                  ? `${`${
                      customWidgetUrl ?? process.env.REACT_APP_WIDGET_BASE_URL
                    }?widget_id=${singleWidget?.widget?._id}`.substring(
                      0,
                      30,
                    )}...`
                  : 'Save widget to generate link'
              }
              readOnly
            />
          </div>
        </div>
      ) : null}
    </Accordion>
  );
}

export default connect(state => state, Actions)(Integration);
