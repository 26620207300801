import React, {useCallback, useState} from 'react';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import {Modal, PrimaryButton} from '../../../components';
import {checked, unchecked} from '../../../assets/images/images';
import {passwordCharacterCheck} from '../../../helpers';
import * as Actions from '../../../store/actions';

function ChangePasswordModal({open, setOpen, changePassword, auth: {loading}}) {
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);

  const [state, setState] = useState({
    old_password: '',
    new_password: '',
    cnew_password: '',
  });

  const handleChange = useCallback(e => {
    const {id, value} = e.target;
    setState(prev => ({...prev, [id]: value}));
  }, []);

  const isPasswordValid = passwordCharacterCheck.map(({check}) =>
    check(state.new_password),
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (state.new_password !== state.cnew_password) {
        toast.error('Passwords do not match');
      } else if (state.new_password.length < 8) {
        toast.error('Password must be greater than 8 characters');
      } else {
        changePassword(state, () => setOpen(false));
      }
    },
    [state, changePassword, setOpen],
  );

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Change password"
    >
      <form className="mt-10 text-left">
        <label htmlFor="old_password" className="mt-6 relative">
          <span className="text-sm ">Current password</span>
          <input
            type={currentPasswordShown ? 'text' : 'password'}
            id="old_password"
            className="mt-2"
            placeholder="********************************"
            value={state?.old_password}
            onChange={handleChange}
          />
          <span
            role="button"
            onClick={() => setCurrentPasswordShown(!currentPasswordShown)}
            className="absolute text-grey40 text-xs font-medium uppercase right-3 bottom-4 cursor-pointer"
          >
            {currentPasswordShown ? 'hide password' : 'show password'}
          </span>
        </label>

        <label htmlFor="new_password" className="mt-6 relative">
          <span className="text-sm ">New password</span>
          <input
            type={newPasswordShown ? 'text' : 'password'}
            id="new_password"
            className="mt-2"
            value={state?.new_password}
            onChange={handleChange}
          />
          <span
            role="button"
            onClick={() => setNewPasswordShown(!newPasswordShown)}
            className="absolute text-grey40 text-xs font-medium uppercase right-3 bottom-4 cursor-pointer"
          >
            {newPasswordShown ? 'hide password' : 'show password'}
          </span>
        </label>

        <label htmlFor="cnew_password" className="mt-6 relative">
          <span className="text-sm ">Confirm password</span>
          <input
            type={!state?.cnew_password ? 'text' : 'password'}
            id="cnew_password"
            className="mt-2"
            value={state?.cnew_password}
            onChange={handleChange}
          />
        </label>
        {state.cnew_password && state.new_password !== state.cnew_password ? (
          <p className="text-xs mt-2 text-red">Password did not match</p>
        ) : null}

        <div className="mt-2 hidden md:flex flex-wrap gap-2">
          {passwordCharacterCheck.map(({name, check}) => (
            <div
              key={name}
              className="w-fit flex items-center gap-2 bg-white80 py-2 px-4 rounded-2xl"
            >
              <img
                src={check(state?.new_password) ? checked : unchecked}
                alt=""
              />
              <span className="uppercase text-xs text-body font-medium mt-[3px]">
                {name}
              </span>
            </div>
          ))}
        </div>

        <PrimaryButton
          onClick={handleSubmit}
          buttonText="Change password"
          className="w-full mt-6"
          loading={loading}
          disabled={
            !state.old_password ||
            !state.new_password ||
            !state.cnew_password ||
            state.new_password !== state.cnew_password ||
            !isPasswordValid.every(Boolean)
          }
        />
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(ChangePasswordModal);
