import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Modal} from '../../components';
import ActivityLog from './ActivityLog';
import * as Actions from '../../store/actions';

function SessionsModal({verifications: {session_summary}, open, setOpen}) {
  const [session, setSession] = useState(
    session_summary?.data?.sessions[0] || null,
  );

  useEffect(() => {
    if (!session && session_summary)
      setSession(session_summary?.data?.sessions[0]);
  }, [session_summary, session, setSession]);

  return (
    <Modal
      modalBg="bg-white80"
      modalTitle="Sessions"
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="md:max-w-[408px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="flex items-center gap-2 p-4 mt-4 text-xs bg-white rounded text-body">
        <span className="font-medium uppercase">session ID:</span>
        <select
          style={{width: 'auto'}}
          data-smallerselect
          name=""
          id=""
          value={session}
          onChange={e => setSession(e.target.value)}
        >
          {session_summary?.data?.sessions?.map(session => (
            <option key={session} value={session}>
              {session}
            </option>
          ))}
        </select>
      </div>
      <ActivityLog session={session} />
    </Modal>
  );
}

export default connect(state => state, Actions)(SessionsModal);
