import React from 'react';
import {SkeletonLoader} from './index';

export default function Card({
  img = '',
  title = '',
  body = 0,
  className = {},
  loading = false,
  // count,
}) {
  const transformedBody =
    typeof Number(body) === 'number' && !isNaN(body)
      ? Number(body)?.toLocaleString()
      : // ? shortenLargeNumbers(body || 0, null, {startAt: 'B', decimals: 0})
        body;
  return (
    <article
      className={`flex relative items-center flex-none gap-3 px-4 py-6 rounded-lg md:w-full md:flex-1 md:p-6 bg-white80 ${className}`}
    >
      <img src={img} alt="" width={40} height={40} />
      <div className="flex flex-col">
        <span className="text-sm font-medium text-body">{title}</span>
        <span className="font-semibold text-xl mb-3 text-grey">
          {loading ? (
            <div className="mt-2">
              <SkeletonLoader
                style={{width: '100%', height: 20, maxWidth: 100}}
              />
            </div>
          ) : (
            transformedBody
          )}
        </span>
        {/*{!loading && count !== 0 && (*/}
        {/*  <p className="font-medium text-xs absolute right-[16px] bottom-[8px]">*/}
        {/*    Count: <span>{count || 0}</span>*/}
        {/*  </p>*/}
        {/*)}*/}
      </div>
    </article>
  );
}
