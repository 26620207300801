import React from 'react';
import {
  actionStepIc,
  conditionalStepIc,
  parallelStepIc,
} from '../../../../assets/images/images';
import {StepsCard} from './_StepsCard';

export const Steps = ({
  handleAddActionStep,
  setAction,
  tempFlow,
  className,
}) => (
  <>
    <div className={`flex flex-col gap-2 ${className}`}>
      <StepsCard
        icon={actionStepIc}
        title="Add an Action rule"
        subtitle="if applicable choose a complimentary condition"
        onClick={() => {
          handleAddActionStep();
          setAction(
            tempFlow[1].length === 0
              ? 0
              : tempFlow[1].length > 0
              ? tempFlow[1][tempFlow[1].length - 1]
              : 0,
          );
        }}
      />
      <StepsCard
        icon={parallelStepIc}
        title="Add a parallel step"
        subtitle="if applicable choose a complimentary condition"
        disabled
      />
      <StepsCard
        icon={conditionalStepIc}
        title="Add a conditional step"
        subtitle="if applicable choose a complimentary condition"
        disabled
      />
    </div>
  </>
);
