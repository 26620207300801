import React, {useState} from 'react';
import {connect} from 'react-redux';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  arrowDown,
  fancyCopy,
  logoIcon,
  widgetHomeScreen,
} from '../../../assets/images/images';

function WebSdk({widgets: {singleWidget}}) {
  const [copied, setCopied] = useState(false);

  const widgetId = singleWidget?.widget?._id;
  const scriptEnv = process.env.REACT_APP_WEBSDK_BASE_URL;
  const scriptUrl = `${scriptEnv}/websdk.js`;

  const codeString = `<script src="${scriptUrl}"></script>
<dojah-button
  widgetId="${widgetId}"
  text="Web"
  textColor="#FFFFFF"
  backgroundColor="#3977de">
</dojah-button>`;

  const copyCodeToClipboard = () => {
    navigator.clipboard
      .writeText(codeString)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      })
      .catch(err => {
        console.error('Failed to copy code to clipboard:', err);
      });
  };

  return (
    <div className="py-8 max-w-[480px] mx-auto flex flex-col gap-3">
      <div className="px-6 py-4 bg-white rounded-lg flex flex-col gap-3">
        <div className="flex items-center gap-3">
          <div className="font-sometype text-small tracking-tighter font-medium py-2 px-3 text-body bg-[#F6F6F6] rounded-lg">
            <p>Javascript library</p>
          </div>
          <button
            className="flex items-center justify-center gap-2 py-2 bg-[#3F7CDB] rounded-lg text-xs font-medium text-white w-24"
            onClick={copyCodeToClipboard}
          >
            <img src={fancyCopy} alt="" width={14} height={14} />
            <span className="-mb-[2px]">
              {copied ? 'Copied' : 'Copy script'}
            </span>
          </button>
        </div>
        <div className="bg-[#F6F6F6] rounded-xl highlighter">
          <SyntaxHighlighter
            language="javascript"
            style={docco}
            showLineNumbers
          >
            {codeString}
          </SyntaxHighlighter>
        </div>
      </div>
      <div className="px-12 py-7 bg-[#D9E5F8B2] rounded-lg flex flex-col items-center gap-3">
        <div className="flex items-center flex-col py-4 px-3 bg-white rounded-lg">
          <p className="text-grey font-medium">Dojah Button</p>
          <p className="text-grey40 text-xs text-center mt-2 leading-[18px]">
            Configure verification pages and paste the script code above to
            create a button that triggers the verification process when clicked
          </p>
          <button
            type="button"
            className="mt-4 flex items-center justify-center gap-2 bg-[#3F7CDB] text-white text-sm rounded py-2 w-full"
          >
            <img src={logoIcon} alt="" width={12} height={14.29} />
            <span className="-mb-1">Verify with dojah</span>
          </button>
        </div>
        <img src={arrowDown} alt="arrow down" width={16} height={39} />
        <img
          src={widgetHomeScreen}
          alt="widget get started screen"
          width={215}
          height={359}
        />
      </div>
    </div>
  );
}

export default connect(state => state, null)(WebSdk);
