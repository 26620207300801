import React from 'react';

export const StepsCard = ({title, subtitle, icon, onClick, disabled}) => (
  <button
    onClick={!disabled ? onClick : null}
    className={`flex items-center rounded-lg bg-white py-5 px-4 min-w-[314px] outline outline-1 outline-brandBlue60 border-l-4 ${
      disabled
        ? 'border-grey20 opacity-50 cursor-not-allowed'
        : 'border-brandBlue80'
    }  gap-5`}
  >
    <img src={icon} alt="" width={32} height={32} />
    <div className="flex flex-col gap-1 items-start">
      <span className="text-sm font-medium text-grey40">{title}</span>
      <span className="text-xs text-grey40">{subtitle}</span>
    </div>
  </button>
);
