/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo} from 'react';
import {connect} from 'react-redux';

const BusinessData = ({widgets: {widgetPages, widgetScreen}}) => {
  const widgetData =
    widgetPages &&
    widgetPages?.find(
      widget =>
        widget?.page === 'business-data' &&
        widget?.pageId === widgetScreen?.pageId,
    );

  const data = useMemo(
    () => ({
      ...widgetData?.config,
    }),
    [widgetData?.config?.cac, widgetData?.config?.tin],
  );
  return (
    <>
      <header className="flex flex-col items-center mt-2">
        <h4 className="mt-4 font-medium text-grey">Business Data</h4>
      </header>
      <form className="w-full max-w-sm mt-10 mb-5">
        <label htmlFor="cac" className="mb-1">
          Business data
        </label>
        <select name="" id="" className="text-sm font-medium text-body">
          {data?.cac && <option value="">Registered Company Number</option>}
          {data?.tin && <option value="">Tax Identification Number</option>}
        </select>
        <label htmlFor="id_type" className="mt-10 mb-1">
          ID Number
          <input
            type="text"
            id="id_type"
            value="123456"
            readOnly
            className="mt-2 text-sm"
          />
        </label>
        <label htmlFor="id_type" className="mt-10 mb-1">
          Business Name
          <input
            type="text"
            id="id_type"
            value="ABC Company lmt."
            readOnly
            className="mt-2 text-sm"
          />
        </label>
      </form>
    </>
  );
};

export default connect(state => state, null)(BusinessData);
