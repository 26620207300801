/* eslint-disable react/jsx-indent */
import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {plus, times} from '../../assets/images/images';
import {
  customStyles,
  DropdownIndicator,
  Loader,
  Modal,
  PrimaryButton,
} from '../../components';
import * as Actions from '../../store/actions';
import {toast} from 'react-toastify';
import Select from 'react-select';
import CreateAppForm from '../../components/CreateAppForm';
import NumberSlider from './NumberSlider';
import {AMLToggle} from '../../components/Toggle';
import {Constants} from '../../helpers';
import NoInfoModal from './NoInfoModal';

function PerformLookupModal({
  open,
  setOpen,
  step = 1,
  setStep,
  performAMLLookup,
  aml: {lookupLoading, amlHistory, loading},
  apps: {allApps},
  lookupType = 'AML',
}) {
  const navigate = useNavigate();
  const userApps =
    allApps &&
    allApps?.apps?.map(
      app =>
        ({
          value: app._id,
          label: app.name,
          name: app.name,
        } || []),
    );
  const initialData = {
    app_id: userApps && userApps[0]?.value,
    first_name: '',
    last_name: '',
    middle_name: '',
    date_of_birth: '',
    match_score: 80,
    // ongoing_monitoring: false,
  };

  const [lookupData, setLookupData] = useState(initialData);
  const [appData, setSelectApp] = useState({
    ...(userApps?.length && userApps[0]),
  });
  const [newAppForm, setNewAppForm] = useState(false);
  const [noInfoDialog, setNoInfoDialog] = useState(true);

  const onChange = useCallback(
    e => {
      e.preventDefault();
      const {name, value} = e.target;

      setLookupData({...lookupData, [name]: value});
    },
    [lookupData],
  );
  const handleLookup = e => {
    const {app_id, first_name, last_name} = lookupData;
    e.preventDefault();
    const isEmpty = !app_id || !first_name || !last_name;
    if (isEmpty) return toast.error('Opps!! sorry, names are required');
    localStorage.setItem('aml_app_id', app_id);
    localStorage.removeItem('organisation');
    if (lookupData?.middle_name === '') delete lookupData?.middle_name;
    if (lookupData?.date_of_birth === '') delete lookupData?.date_of_birth;
    performAMLLookup(lookupData, setStep);
    setLookupData(initialData);
  };

  if (step === 1) {
    return (
      <ModalWrapper
        open={open}
        setOpen={setOpen}
        modalTitle={`${lookupType} Lookup`}
      >
        {lookupLoading ? (
          <Loader />
        ) : (
          <form className="text-left">
            <label htmlFor="app" className="relative mt-6 mb-2">
              Select app
            </label>
            <Select
              defaultValue={appData}
              value={appData}
              onChange={app => {
                setSelectApp(app);
                setLookupData({...lookupData, app_id: app?.value});
              }}
              options={userApps}
              placeholder="Select app"
              components={{DropdownIndicator}}
              classNamePrefix="react-select"
              styles={customStyles}
              isSearchable={false}
            />
            <button
              className="flex items-center gap-[2px] ml-auto mt-2"
              onClick={() => setNewAppForm(prev => !prev)}
              type="button"
            >
              <img
                src={newAppForm ? times : plus}
                alt=""
                width={12}
                height={12}
              />
              <span className="text-xs font-medium text-body">
                {newAppForm ? 'Close' : 'Create a new app'}
              </span>
            </button>
            {newAppForm && (
              <div className="p-6 mt-2 rounded bg-white80 animate-dropdown">
                <h3 className="text-base font-medium text-grey">Create App</h3>
                <CreateAppForm setOpen={() => setNewAppForm(prev => !prev)} />
              </div>
            )}
            <label htmlFor="first_name" className="mt-6">
              <span className="text-sm">First name</span>
              <input
                type="text"
                id="first_name"
                name="first_name"
                className="mt-2"
                value={lookupData?.first_name}
                onChange={onChange}
              />
            </label>
            <label htmlFor="last_name" className="mt-6">
              <span className="text-sm">Last name</span>
              <input
                type="text"
                id="last_name"
                name="last_name"
                className="mt-2"
                value={lookupData?.last_name}
                onChange={onChange}
              />
            </label>
            <label htmlFor="middle_name" className="mt-6">
              <span className="text-sm">Middle name (Optional)</span>
              <input
                type="text"
                id="middle_name"
                name="middle_name"
                className="mt-2"
                value={lookupData?.middle_name}
                onChange={onChange}
              />
            </label>
            <label htmlFor="dob" className="mt-6">
              <span className="text-sm">Date of birth (Optional)</span>
              <input
                type="date"
                id="dob"
                name="date_of_birth"
                className="mt-2"
                value={lookupData?.date_of_birth}
                onChange={onChange}
                max={new Date().toISOString().split('T')[0]}
              />
            </label>{' '}
            <label htmlFor="dob" className="my-6">
              <span className="text-sm mb-4">Match score</span>
              <NumberSlider
                onChange={value =>
                  setLookupData({...lookupData, match_score: value})
                }
                value={lookupData?.match_score}
              />
            </label>
            <div className="flex items-center gap-2  pr-2 py-[10px]  mt-6 bg-white  rounded">
              <AMLToggle
                isChecked={lookupData.ongoing_monitoring}
                onCheck={v =>
                  setLookupData({...lookupData, ongoing_monitoring: v})
                }
              />
              <span className="text-sm leading-[18px] text-brandBlue50">
                enable ongoing monitoring
              </span>
            </div>
            <PrimaryButton
              buttonText="Look up"
              className="w-full mt-6"
              onClick={e => {
                handleLookup(e);
                // setStep(2);
              }}
              loading={loading}
            />
          </form>
        )}
      </ModalWrapper>
    );
  } else if (step === 2) {
    if (!amlHistory?.entity?.profile_ids?.length) {
      setOpen(false);
      return (
        <NoInfoModal
          open={noInfoDialog}
          setOpen={() => {
            setNoInfoDialog(false);
            setStep(1);
          }}
        />
      );
    }
    navigate(
      `${Constants.PAGES_URL.AML_USER_SCREENING_SEARCH_RESULTS.replace(
        ':slug',
        amlHistory?.entity?.lookup_id,
      )}`,
    );
    return null;
  }
}

function ModalWrapper({open, setOpen, children, modalTitle}) {
  return (
    <Modal
      modalTitle={modalTitle}
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      {children}
    </Modal>
  );
}

export default connect(state => state, Actions)(PerformLookupModal);
