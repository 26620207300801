import * as React from 'react';

export function ReorderIcon({dragControls, fill}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      className="cursor-grab"
      onPointerDown={event => dragControls.start(event)}
    >
      <title>Drag to move</title>
      <path
        d="M6.333 2C5.6 2 5 2.6 5 3.333c0 .734.6 1.334 1.333 1.334.734 0 1.334-.6 1.334-1.334C7.667 2.6 7.067 2 6.333 2Zm0 9.333c-.733 0-1.333.6-1.333 1.334C5 13.4 5.6 14 6.333 14c.734 0 1.334-.6 1.334-1.333 0-.734-.6-1.334-1.334-1.334Zm0-4.666C5.6 6.667 5 7.267 5 8s.6 1.333 1.333 1.333c.734 0 1.334-.6 1.334-1.333s-.6-1.333-1.334-1.333ZM10.667 2c-.733 0-1.333.6-1.333 1.333 0 .734.6 1.334 1.333 1.334S12 4.067 12 3.333C12 2.6 11.4 2 10.667 2Zm0 9.333c-.733 0-1.333.6-1.333 1.334 0 .733.6 1.333 1.333 1.333S12 13.4 12 12.667c0-.734-.6-1.334-1.333-1.334Zm0-4.666c-.733 0-1.333.6-1.333 1.333s.6 1.333 1.333 1.333S12 8.733 12 8s-.6-1.333-1.333-1.333Z"
        fill={fill ?? '#85858E'}
      />
    </svg>
  );
}
