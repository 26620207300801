import React, {useMemo} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';

function AddressLayout({children, auth: {userDetails}}) {
  const {
    performIndividualLookupAndViewHistory,
    performBatchLookupAndViewHistory,
  } =
    useMemo(
      () => userDetails?.permissions?.addressVerificationPermissions,
      [userDetails],
    ) || {};
  return (
    <>
      <div className="mt-6 md:mt-[32px] border-b border-grey60">
        <div className="flex items-center gap-4 overflow-x-auto settings-nav whitespace-nowrap">
          <NavLink
            end
            to={'/address-verification'}
            className={({isActive}) =>
              `font-medium text-sm py-2 ${
                isActive
                  ? 'text-brandBlue border-b-2 border-brandBlue'
                  : 'text-grey30'
              }`
            }
            style={{
              pointerEvents: performIndividualLookupAndViewHistory
                ? 'auto'
                : 'none',
            }}
          >
            Individual
          </NavLink>
          <NavLink
            to={`/address-verification/batch`}
            className={({isActive}) =>
              ` font-medium text-sm py-2 ${
                isActive
                  ? 'text-brandBlue border-b-2 border-brandBlue'
                  : 'text-grey30'
              }`
            }
            style={{
              pointerEvents: performBatchLookupAndViewHistory ? 'auto' : 'none',
            }}
          >
            Batch
          </NavLink>
        </div>
      </div>
      {children}
    </>
  );
}

export default connect(state => state, Actions)(AddressLayout);
