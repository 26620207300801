import React, {useState} from 'react';
import {downloadGrey, enlargeIc} from '../assets/images/images';

export const ImageComponent = ({imageUrl, space = '10'}) => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const toggleImageSize = () => {
    setIsEnlarged(prevState => !prevState);
  };

  return (
    <div className="relative w-full">
      <img
        src={imageUrl}
        alt=""
        className={`mx-auto w-full ${
          isEnlarged ? 'h-full' : 'max-h-[254px]'
        } object-cover rounded-lg`}
      />
      {imageUrl && (
        <>
          <div className={`absolute bottom-4 left-${space || '10'} flex gap-3`}>
            <button
              className="flex items-start gap-2 p-2 text-xs font-medium rounded text-grey bg-white80"
              onClick={toggleImageSize}
            >
              <span className="-mb-[2px]">
                {isEnlarged ? 'Shrink image' : 'Enlarge image'}
              </span>
              <img src={enlargeIc} alt="" className="-mt-[2px]" />
            </button>
          </div>
          <div
            className={`absolute bottom-4 right-${space || '10'} flex gap-2`}
          >
            <a
              href={imageUrl}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-start gap-2 p-2 text-xs font-medium rounded text-grey bg-white80"
            >
              <span className="-mb-[2px]">Download image</span>
              <img src={downloadGrey} alt="" className="-mt-[2px]" />
            </a>
          </div>
        </>
      )}
    </div>
  );
};
