import {logo} from '../assets/images/images';

export const convertSVGToBase64 = () =>
  new Promise((resolve, reject) => {
    const svgPath = logo;
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const base64URL = canvas.toDataURL('image/png');
      resolve(base64URL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = svgPath;
  });
