export const getPages = name => {
  switch (name) {
    case 'indemnity':
      return 'Disclaimer';
    case 'government-data':
      return 'Government Data';
    case 'user-data':
      return 'User Data';
    case 'business-data':
      return 'Business Data';
    case 'business-id':
      return 'Business ID';
    case 'phone-number':
      return 'Phone Number';
    case 'email':
      return 'Email';
    case 'address':
      return 'Address';
    case 'id':
      return 'Government Issued ID';
    case 'selfie':
      return 'Liveness';
    case 'fraud-check':
      return 'Fraud Check';
    case 'additional-document':
      return 'Upload “other IDs/Documents”';
    case 'signature':
      return 'Signature';
    case 'custom-questions':
      return 'Questions';

    default:
      break;
  }
};
export const getFraudPages = name => {
  switch (name) {
    case 'user_data':
      return 'Detect with user data';
    case 'age_limit':
      return 'Detect with age limit';
    case 'liveness_match':
      return 'Detect with Liveness';
    case 'aml_screening':
      return 'Detect with AML Screening';
    case 'duplicate_check':
      return 'Detect with Duplicate ID';
    case 'ip_screening':
      return 'Detect with IP/Device Screening';
    case 'blacklist':
      return 'Blacklist';

    default:
      break;
  }
};
