import {useCallback, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {hideEmail} from '../components/hideEmail';

export const useBaasExport = ({params, apiFunc}) => {
  const [exportData, setExportData] = useState(false);
  const {user} = useSelector(state => state?.auth);

  const exportParams = useMemo(
    () => ({
      export: true,
      file_type: 'excel',
      email: user?.email,
    }),
    [user?.email],
  );

  const {refetch} = useQuery(
    ['export', {params: {...params, ...exportParams}}],
    () => apiFunc({params: {...params, ...exportParams}}),
    {
      onSuccess: data => {
        toast.success(
          (typeof data?.entity === 'string' && data?.entity) ||
            `Exported data has been sent to ${hideEmail(user?.email)}`,
        );
      },
      enabled: exportData,
    },
  );

  const handleExport = useCallback(async () => {
    setExportData(prev => !prev);
    await refetch().finally(() => {
      setExportData(false);
    });
  }, [refetch]);

  return {exportData, handleExport};
};
