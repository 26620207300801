/* eslint-disable react-hooks/exhaustive-deps */

import React, {useCallback, useEffect, useState} from 'react';
import {css} from '@emotion/react';

import {Modal, SecondaryButton} from '../../components';
import {closeModal} from '../../assets/images/images';
import {connect, useDispatch} from 'react-redux';
import * as Actions from '../../store/actions';
import PuffLoader from 'react-spinners/PuffLoader';

import {deleteWebhookSubscription} from '../../store/actions';

function DeleteModal({open, bodyText, params, setOpen}) {
  const [data, setParams] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.values(params).length) {
      setParams(params);
    }
  }, [Object.values(params).length]);

  const handleModalDelete = useCallback(async () => {
    setLoading(true);
    if (Object.values(data).length) {
      await dispatch(deleteWebhookSubscription({...data}, setOpen));
      setLoading(false);
    }
  }, [dispatch, setOpen, data]);

  const override = css`
    border-color: #ffffff;
    position: absolute;
    background: transparent;
  `;

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-grey text-base font-medium">Are you sure?</h3>
          <button onClick={() => setOpen(false)}>
            <img src={closeModal} alt="" />
          </button>
        </header>

        <p className="mt-4 text-sm text-body text-start">{bodyText}</p>

        <div className="mt-4 flex items-center gap-4">
          <SecondaryButton buttonText="Cancel" onClick={() => setOpen(false)} />
          <button
            onClick={handleModalDelete}
            className={` ${
              loading
                ? 'flex items-center justify-center relative h-12 px-4 py-6'
                : ''
            } text-white text-sm font-medium bg-danger py-4 px-[81px] rounded`}
          >
            {loading ? (
              <PuffLoader css={override} size={35} color="#ffffff" />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(DeleteModal);
