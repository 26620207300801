import React, {memo, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Actions from '../store/actions';
import {ToggleHeader} from './Toggle';
import {hamburger, notification} from '../assets/images/images';
import SingleDropdown from './SingleDropdown';
import AddCompanyModal from '../routes/Dashboard/AddCompanyModal';
import Modal from './Modal';
import Sidebar from './Sidebar';

function Header({breadCrumbs}) {
  const {
    apps: {live},
    auth: {user, userDetails, loading},
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(userDetails?.company);
  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const [openNav, setOpenNav] = useState(false);

  const handleSwitchCompany = useCallback(
    data => {
      if (data) {
        dispatch(Actions?.switchCompany(data?._id));
      }
    },
    [dispatch],
  );
  return (
    <header className="fixed top-0 left-0 right-0 z-10 text-white bg-white h-16 flex items-center justify-between pl-4 sm:pl-6 lg:pl-12 pr-4 sm:pr-0 sm:border-b sm:border-grey60 lg:ml-[16.4%] print:hidden">
      <AddCompanyModal
        open={addCompanyModal}
        setOpen={() => setAddCompanyModal(!addCompanyModal)}
      />
      <div className="text-sm text-body">{breadCrumbs}</div>
      <div className="flex ml-auto">
        <div className="items-center hidden sm:flex">
          <span
            role="switch"
            className={`text-xs uppercase px-2 pt-[5px] pb-[3px] rounded-full mr-2 ${
              live
                ? '20 bg-success20 text-status-200'
                : 'bg-statusBg-500 text-status-500'
            } font-medium`}
          >
            {live ? 'live mode' : 'sandbox'}
          </span>
          <ToggleHeader />
          <span
            role="switch"
            className={`text-sm ml-2 mr-1  ${
              !live ? '20 text-grey40' : 'text-grey'
            } font-medium`}
          >
            Live
          </span>
          <div className="w-6 rotate-90 border-b border-body" />
        </div>

        {/* mobile menu */}
        <div className="flex items-center gap-2 sm:mr-4 lg:hidden">
          <div className="p-2 rounded bg-white80" role="button" tabIndex="0">
            <img src={notification} alt="" width={18} height={18} />
          </div>

          <button
            onClick={() => setOpenNav(!openNav)}
            className="flex items-center gap-2 px-3 py-2 rounded bg-white80"
          >
            <span className="pt-[3px] text-sm font-medium text-body">Menu</span>
            <img src={hamburger} alt="" width={18} height={18} />
          </button>

          <Modal
            enterFrom="opacity-0 -translate-x-40"
            leaveFrom="opacity-0 -translate-x-40"
            leaveTo="opacity-0 -translate-x-40"
            extraModalClasses="max-w-[352px]"
            show={openNav}
            onClose={setOpenNav}
            setOpen={setOpenNav}
            modalPosition="items-normal justify-start lg:hidden"
            modalPadding="pl-0"
          >
            <Sidebar openNav={openNav} setOpenNav={setOpenNav} />
          </Modal>
        </div>

        <div className="flex items-center">
          <div className="hidden ml-1 mr-4 md:flex items-center justify-center w-[26px] h-[26px] bg-brandBlue rounded-full">
            <span className="text-xs font-medium leading-3 text-white">{`${
              user?.first_name?.substr(0, 1) || '-'
            }${user?.last_name?.substr(0, 1) || ''}`}</span>
          </div>
          <div className="hidden md:block">
            <SingleDropdown
              // loading={appLoading}
              dropdownData={userDetails?.companies || []}
              selected={selected}
              setSelected={data => {
                setSelected(data);
                handleSwitchCompany(data);
              }}
              spanText={userDetails?.company?.name || selected?.name}
              width="w-[314px]"
            />
          </div>
        </div>
      </div>
      {loading || live ? null : (
        <div className="items-end justify-center h-[1px] w-full absolute left-0 bottom-0 bg-status-404 z-[-1] hidden lg:flex">
          <p className="text-status-404  text-sm font-medium px-4 pt-[5px] pb-[3px] rounded-t bg-statusBg-404 border-b  ">
            You are in sandbox mode
          </p>
        </div>
      )}
    </header>
  );
}

export default memo(Header);
