import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import {
  PrimaryButton,
  SettingsToggle,
  TertiaryButton,
  MultiValueRemove,
} from '../../../../components';
import {copyWhite, lock, profileIc} from '../../../../assets/images/images';
import Layout from '../Layout';
import * as Actions from '../../../../store/actions';
import {USE_CASES} from '../CreateFlowModal';
import {toast} from 'react-toastify';
import DeleteFlowModal from './DeleteFlowModal';
import {isObjectEmpty} from '../../../../helpers/isObjectEmpty';
import {PageContext} from '../../../../helpers/context';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  deleteFlow,
  fetchFlow,
  generateWebhookUrl,
  updateFlow,
} from '../../../../requests/queries/flows';

function FlowsSettings() {
  const navigate = useNavigate();
  const {slug} = useParams();
  const [openDeleteFlowModal, setOpenDeleteFlowModal] = useState(false);
  const {data: flowData, isLoading} = useQuery(['e-flow', slug], () =>
    fetchFlow(slug),
  );
  const flow = useMemo(
    () => !isLoading && flowData && {flow: flowData?.entity},
    [flowData, isLoading],
  );

  const queryClient = useQueryClient();

  const {mutate: updateCurrentFLow, isLoading: updateLoading} = useMutation(
    'update-flow',
    updateFlow,
  );
  const generateWebhookMutation = useMutation(
    'generate-webhook',
    generateWebhookUrl,
  );
  const {mutate: deleteCurrentFlow, isLoading: deleteLoading} = useMutation(
    'delete-flow',
    deleteFlow,
  );

  const [checkUpdate, sectCheck] = useState({
    receive_webhook:
      flow?.flow?.notification_emails &&
      flow?.flow?.notification_emails.length > 0,
  });
  const [updateData, setUpdate] = useState({});

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  useEffect(() => {
    setUpdate({
      name: flow?.flow?.name || '-',
      desc: flow?.flow?.desc || '-',
      webhook: flow?.flow?.webhook || '-',
      notification_emails: flow?.flow?.notification_emails || [],
      receive_webhook:
        flow?.flow?.notification_emails &&
        flow?.flow?.notification_emails.length > 0,
    });
    sectCheck({
      receive_webhook:
        flow?.flow?.notification_emails &&
        flow?.flow?.notification_emails.length > 0,
    });
  }, [flow]);

  const [inputValue, setInputValue] = useState('');
  const [noEmail, setNoEmail] = useState(false);

  const existingUseCase = useMemo(
    () => USE_CASES.find(_case => _case.value === flow?.flow?.usecase),
    [flow],
  );

  const copyText = text => {
    navigator.clipboard.writeText(text);
    toast.success('Link copied');
  };

  const ingestBaseUrl =
    process.env.REACT_APP_APP_ENV_MODE !== 'production'
      ? 'https://dev-treasury.dojah.services'
      : 'https://ingest.dojah.io';

  const realTimeUrl = useMemo(
    () => `${ingestBaseUrl}/ingest/${flow?.flow?.key}` || '-',
    [flow?.flow?.key, ingestBaseUrl],
  );

  const handleChange = e => {
    e.preventDefault();
    const {name, value} = e.target;
    setUpdate({...updateData, [name]: value});
    sectCheck({...checkUpdate, [name]: value});
  };

  const handleUpdate = useCallback(async () => {
    if (!isObjectEmpty(checkUpdate)) {
      try {
        await generateWebhookMutation?.mutate(
          {
            webhook_url: updateData?.webhook,
            project_id: slug,
            flow_name: updateData?.name,
          },
          {
            onSuccess: async data => {
              const webhookData = {
                name: updateData?.name,
                webhook: updateData?.webhook,
                notification_emails: updateData?.notification_emails,
                c_webhook: data?.convoy_url,
              };
              await updateCurrentFLow(
                {
                  id: slug,
                  data: {...webhookData},
                },
                {
                  onSuccess: data => {
                    queryClient?.invalidateQueries();
                    toast.success(data?.message || 'FLow updated successfully');
                  },
                  onError: error => {
                    toast.error(error?.response?.data?.error);
                  },
                },
              );
            },
            onError: error => {
              toast.error(error?.response?.data?.error);
            },
          },
        );
      } catch (e) {
        console.log(e);
      }
    }
  }, [
    checkUpdate,
    generateWebhookMutation,
    queryClient,
    slug,
    updateCurrentFLow,
    updateData?.name,
    updateData?.notification_emails,
    updateData?.webhook,
  ]);

  const validateEmail = email => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleKeyDown = event => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        // eslint-disable-next-line no-case-declarations
        const value = [...updateData.notification_emails];

        if (!validateEmail(inputValue)) {
          return toast.error('Invalid email address');
        }
        if (value.find(v => v.label === inputValue)) {
          return toast.error('Email already added');
        }
        if (value.length === 5) {
          return toast.error('Maximum of 5 emails allowed');
        }

        setUpdate({
          ...updateData,
          notification_emails: [...updateData.notification_emails, inputValue],
        });
        setNoEmail(false);
        setInputValue('');
        event.preventDefault();
    }
  };

  const handleToggleChange = data => {
    setUpdate(prevUpdateData => {
      const newUpdateData = {
        ...prevUpdateData,
        receive_webhook: data,
      };

      if (!data && prevUpdateData.receive_webhook) {
        newUpdateData.notification_emails = prevUpdateData.notification_emails;
      } else if (!data) {
        newUpdateData.notification_emails = [];
      }
      sectCheck({...checkUpdate, receive_webhook: data});
      return newUpdateData;
    });
  };

  const handleDelete = useCallback(async () => {
    try {
      await deleteCurrentFlow(slug, {
        onSuccess: () => {
          setOpenDeleteFlowModal(false);
          navigate('/flows');
          queryClient?.invalidateQueries();
        },
        onError: error => {
          toast.error(error?.response?.data?.error);
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [deleteCurrentFlow, navigate, queryClient, slug]);

  return (
    <Layout pageTitle="Settings" pageIcon={profileIc}>
      <DeleteFlowModal
        open={openDeleteFlowModal}
        setOpen={setOpenDeleteFlowModal}
        onClick={handleDelete}
        loading={deleteLoading}
      />
      <section className="mt-8 md:mt-10">
        <form className="flex flex-col w-full gap-6 md:flex-row md:items-start">
          <div className="md:w-1/2">
            <h3 className="text-xs font-medium uppercase text-body">
              EDIT FLOW
            </h3>

            <div className="p-4 mt-4 bg-white rounded md:mt-6 md:p-6">
              <label htmlFor="flow_name">
                Flow name
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={updateData?.name}
                  className="mt-2"
                  onChange={handleChange}
                />
              </label>
              <label htmlFor="flow_use_cases" className="mt-6 relative">
                Use cases
                <input
                  type="text"
                  id="use_case"
                  name="use_case"
                  className="mt-2"
                  defaultValue={existingUseCase?.label}
                  disabled
                />
                <img
                  src={lock}
                  alt=""
                  width={16}
                  height={16}
                  className="absolute bottom-4 right-4"
                  title="You can't edit this field"
                />
              </label>
              <label htmlFor="flow_description" className="mt-6 relative">
                <p>
                  Description <span className="text-grey30">(optional)</span>
                </p>
                <textarea
                  name="description"
                  className="mt-2 min-h-[100px] h-full"
                  value={updateData?.desc}
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="mt-10">
              <h3 className="text-xs font-medium uppercase text-body">
                CONFIGURATIONS
              </h3>

              <div className="p-4 mt-4 bg-white rounded md:mt-6 md:p-6">
                <label htmlFor="realtime_url" className="relative">
                  Realtime URL
                  <input
                    type="text"
                    id="realtime_url"
                    name="realtime_url"
                    placeholder={isLoading ? '...' : realTimeUrl}
                    className="mt-2"
                    disabled
                  />
                  <button
                    type="button"
                    onClick={e => {
                      e.preventDefault();
                      copyText(realTimeUrl);
                    }}
                    className="absolute flex items-center gap-2 p-2 rounded bottom-[9px] right-4 bg-brandBlue"
                  >
                    <img src={copyWhite} alt="" width={14} height={14} />
                    <span className="text-xs font-medium text-white">Copy</span>
                  </button>
                </label>
              </div>
            </div>
          </div>

          <div className="md:w-1/2">
            <h3 className="text-xs font-medium uppercase text-body">
              WEBHOOK INFO
            </h3>

            <div className="p-6 mt-6 bg-white rounded">
              <label htmlFor="webhook">
                WEBHOOK URL
                <input
                  type="text"
                  id="webhook"
                  name="webhook"
                  value={updateData?.webhook}
                  className="mt-2"
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="mt-10">
              <h3 className="text-xs font-medium uppercase text-body">
                EMAIL Notifications
              </h3>

              <div className="p-6 mt-6 bg-white rounded receive_webhook">
                <label htmlFor="receive_webhook">
                  Opt to receive email notifications whenever a new case emerges
                  <div className="mt-4 flex items-center gap-2 p-4 rounded bg-white80 w-full">
                    <SettingsToggle
                      title="receive_webhook"
                      isChecked={updateData?.receive_webhook}
                      onCheck={handleToggleChange}
                    />
                    <p className="text-grey text-sm -mb-1">Email</p>
                  </div>
                </label>

                {updateData?.receive_webhook ? (
                  <>
                    <label
                      htmlFor="notification_emails"
                      className="mt-4 mb-1 relative"
                    >
                      Add email address
                    </label>
                    <CreatableSelect
                      components={{
                        DropdownIndicator: null,
                        MultiValueRemove,
                      }}
                      inputValue={inputValue}
                      isClearable
                      isMulti
                      menuIsOpen={false}
                      onChange={newValue => {
                        if (newValue) {
                          setUpdate({
                            ...updateData,
                            notification_emails: newValue.map(v => v.label),
                          });
                          sectCheck({
                            ...checkUpdate,
                            notification_emails: newValue.map(v => v.label),
                          });
                        }
                      }}
                      onInputChange={newValue => {
                        setInputValue(newValue);
                      }}
                      onKeyDown={handleKeyDown}
                      placeholder="Type emails here and press enter or tab to add"
                      value={
                        updateData?.notification_emails.map(v => ({
                          label: v,
                          value: v,
                        })) || []
                      }
                      className={`creatable-multi-select rounded ${
                        noEmail ? 'border border-danger ' : ''
                      }`}
                      classNamePrefix="react-select"
                      multiValueLabel
                    />
                  </>
                ) : null}
              </div>
            </div>

            <div className="flex items-center gap-4 mt-8">
              <PrimaryButton
                loading={updateLoading || generateWebhookMutation?.isLoading}
                onClick={handleUpdate}
                buttonText="Save changes"
              />

              <TertiaryButton
                buttonText="Delete flow"
                onClick={() => setOpenDeleteFlowModal(true)}
              />
            </div>
          </div>
        </form>
      </section>
    </Layout>
  );
}

export default connect(state => state, Actions)(FlowsSettings);
