import React from 'react';

export default function TableLayout({
  children,
  className = '',
  negativeMargins = false,
  overflowX = true,
  negativeRightMargin = '-mr-6',
  noOverflow,
}) {
  return (
    <section className={`${className} antialiased`}>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto">
          <div
            className={`${
              noOverflow
                ? ''
                : overflowX
                ? 'overflow-x-auto'
                : 'overflow-x-hidden'
            } ${
              negativeMargins &&
              `-ml-4 sm:-ml-6 xl:-ml-12 ${negativeRightMargin}`
            }`}
          >
            <table className="w-full table-auto">{children}</table>
          </div>
        </div>
      </div>
    </section>
  );
}
