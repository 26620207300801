import React from 'react';
import {NavLink, useLocation, useParams} from 'react-router-dom';
import {chevronRight, easyOnboardIc} from '../../../assets/images/images';
import {DashboardLayout} from '../../../components';
import {Constants} from '../../../helpers';

const ROUTES = [
  {
    title: 'Analysis',
    to: Constants.PAGES_URL.FLOWS_ANALYTICS,
  },
  {
    title: 'Events',
    to: Constants.PAGES_URL.FLOWS_EVENTS,
  },
  {
    title: 'Cases',
    to: Constants.PAGES_URL.FLOWS_CASES,
  },
  {
    title: 'Accounts',
    to: Constants.PAGES_URL.FLOWS_ACCOUNT,
  },

  {
    title: 'Settings',
    to: Constants.PAGES_URL.FLOWS_SETTINGS,
  },
];

function Routes({title, to, slug, name}) {
  const path = `${to.replace('/:slug', '')}/${slug}`;
  return (
    <NavLink
      to={{pathname: path}}
      state={{flowName: name}}
      className={({isActive}) =>
        `font-medium text-sm py-2 ${
          isActive
            ? 'text-brandBlue border-b-2 border-brandBlue'
            : 'text-grey30'
        }`
      }
    >
      {title}
    </NavLink>
  );
}

function Layout({children, bg, pageTitle, action}) {
  const {slug} = useParams();
  const {state} = useLocation();

  return (
    <DashboardLayout
      bg={bg}
      xlLeftMargin="xl:ml-12"
      xlRightPadding="xl:pr-[76px]"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyOnboardIc} alt="" />
          <p className="ml-2 -mb-1">Fraud detection</p>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1">Flows</p>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1">{pageTitle}</p>
        </div>
      }
      action={action}
    >
      <div className="flex items-center justify-between gap-6 mt-6 mb-6 md:gap-0 sm:mt-0">
        <h2 className="hidden text-lg font-medium text-grey sm:block">
          {state?.flowName}
        </h2>
        {/*<Link to={`/flows/edit-process/${slug}`}>*/}
        {/*  <PrimaryButton*/}
        {/*    buttonText="Edit process"*/}
        {/*    xPadding="px-4"*/}
        {/*    className="hidden sm:flex"*/}
        {/*    disabled={!editProcess}*/}
        {/*  />*/}
        {/*</Link>*/}
      </div>

      <div className="mt-6 md:mt-[27px] border-b border-grey60">
        <div className="flex items-center gap-4 overflow-x-auto settings-nav whitespace-nowrap">
          {ROUTES.map((route, index) => (
            <Routes key={index} name={state?.flowName} {...route} slug={slug} />
          ))}
        </div>
      </div>
      {children}
    </DashboardLayout>
  );
}

export default Layout;
