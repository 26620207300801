/* eslint-disable react/jsx-indent */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import {
  customStyles,
  DropdownIndicator,
  Modal,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import * as Actions from '../../store/actions';

import {infoRedSm, plus, times} from '../../assets/images/images';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {AMLToggle} from '../../components/Toggle';
import {toast} from 'react-toastify';
import CreateAppForm from '../../components/CreateAppForm';

function OngoingMonitoringConfig({
  open = false,
  setOpen,
  amlConfigApi,
  getAllApps,
  aml: {amlConfig, configLoading},
  apps: {allApps},
}) {
  const userApps =
    (allApps &&
      allApps?.apps?.map(app => ({
        value: app._id,
        label: app.name,
        name: app.name,
      }))) ||
    [];

  const createOption = label => ({
    label,
    value: label,
  });

  const defaultConfig = useMemo(
    () => ({
      app_id:
        userApps &&
        (userApps?.find(
          app => app?.value === amlConfig?.entity?.app_id?.value,
        ) ||
          userApps[0]?.value),
      email_addresses: amlConfig?.entity?.email_addresses || [],
      email_notification: amlConfig?.entity?.email_notification || true,
      webhook_notification: amlConfig?.entity?.webhook_notification || false,
      duration: amlConfig?.entity?.duration || 'daily',
    }),
    [amlConfig, userApps],
  );

  const [duration, setDuration] = React.useState(
    createOption(defaultConfig.duration) || {
      label: 'Daily',
      value: 'daily',
    },
  );
  const [inputValue, setInputValue] = React.useState('');
  const [config, setConfig] = React.useState({});
  const [value, setValue] = React.useState([]);
  const [noEmail, setNoEmail] = React.useState(false);
  const [newAppForm, setNewAppForm] = useState(false);

  const [appData, setSelectApp] = useState({
    ...(userApps?.length &&
      (userApps.find(app => app?.value === amlConfig?.entity?.app_id) ||
        userApps[0])),
  });

  useEffect(() => {
    getAllApps({});
    setConfig({
      ...config,
      app_id: config?.app_id || appData?.value,
      email_addresses: config?.email_addresses || value?.map(v => v.label),
    });

    if (!config?.email_notification) {
      delete config.email_addresses;
      setValue([]);
      setNoEmail(false);
      setConfig({...config});
    }
    if (!config?.webhook_notification) {
      delete config.app_id;
      setConfig({...config});
    }
  }, [config?.email_notification, config?.webhook_notification, value?.length]);

  useEffect(() => {
    if (!configLoading && amlConfig && amlConfig !== 'create') {
      setConfig({
        app_id:
          userApps &&
          (userApps?.find(
            app => app?.value === amlConfig?.entity?.app_id?.value,
          ) ||
            userApps[0]?.value),
        email_addresses: amlConfig?.entity?.email_addresses || [],
        email_notification: amlConfig?.entity?.email_notification,
        webhook_notification: amlConfig?.entity?.webhook_notification,
        duration: amlConfig?.entity?.duration || 'daily',
      });
      setValue([
        ...(amlConfig?.entity?.email_addresses || []).map(email =>
          createOption(email),
        ),
      ]);
      setDuration(createOption(amlConfig?.entity?.duration || 'daily'));
      setSelectApp({
        ...(userApps?.length &&
          userApps.find(app => app?.value === amlConfig?.entity?.app_id)),
      });
    } else {
      !configLoading && setConfig({...defaultConfig});
    }
  }, [amlConfig, open]);

  useEffect(() => {
    if (
      !configLoading &&
      amlConfig === 'create' &&
      userApps &&
      userApps.length > 0 &&
      defaultConfig?.email_addresses?.length > 0
    ) {
      return amlConfigApi(defaultConfig, 'POST', setOpen);
    } else {
      amlConfigApi({}, 'GET');
    }
    return () => {
      amlConfigApi({}, 'GET');
    };
  }, [amlConfigApi]);

  const validateEmail = email => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleKeyDown = event => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ',':
      case ' ':
        if (!validateEmail(inputValue)) {
          return toast.error('Invalid email address');
        }
        if (value.find(v => v.label === inputValue)) {
          return toast.error('Email already added');
        }
        if (value.length === 5) {
          return toast.error('Maximum of 5 emails allowed');
        }
        setConfig({
          ...config,
          email_addresses: [...(config?.email_addresses || []), inputValue],
        });
        setNoEmail(false);
        setValue(prev => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };
  const data = useMemo(
    () => ({
      ...(config.webhook_notification ? {app_id: config.app_id || ''} : {}),
      ...(config.email_notification
        ? {email_addresses: config.email_addresses || []}
        : {}),
      duration: config.duration,
    }),
    [
      config.email_notification,
      config.webhook_notification,
      config.duration,
      config.email_addresses,
      config.app_id,
    ],
  );

  // const changedProperties = {};
  //
  // for (const [key, value] of Object.entries(config)) {
  //   if (defaultConfig[key] !== value) {
  //     changedProperties[key] = value;
  //   }
  // }
  {
    return (
      <ModalWrapper
        open={open}
        setOpen={setOpen}
        modalTitle="Ongoing monitoring settings"
      >
        <div className="flex flex-col">
          <p className="text-sm mt-10 text-body ">
            Set when and how you would like to get latest information about your
            user(s).
          </p>

          <div className="flex  mt-6 bg-statusBg-500 p-2 rounded">
            <img src={infoRedSm} alt="" />
            <p className="text-xs ml-3 text-body">
              Configurations would affect your lookup globally
            </p>
          </div>

          <form className="text-left mt-4">
            <label
              htmlFor="app"
              className="relative mt-6 mb-2 text-xs text-body"
            >
              Choose duration
            </label>

            <Select
              defaultValue={duration}
              value={duration}
              onChange={duration => {
                setDuration(duration);
                setConfig({...config, duration: duration.value});
              }}
              options={[
                {label: 'Daily', value: 'daily'},
                {label: 'Weekly', value: 'weekly'},
                {label: 'Bi-weekly', value: 'bi-weekly'},
                {label: 'Monthly', value: 'monthly'},
                {label: 'Quarterly', value: 'quaterly'},
              ]}
              placeholder="Choose duration"
              components={{DropdownIndicator}}
              classNamePrefix="react-select"
              styles={customStyles}
              isSearchable={false}
              menuPlacement="auto"
            />

            <label
              htmlFor="app"
              className="relative mt-6 mb-2 text-xs text-body"
            >
              Choose where to receive it
            </label>
            <div className="flex items-center gap-2 pl-4 pr-2 py-[10px]  bg-white  p-4 rounded">
              <AMLToggle
                isChecked={config.email_notification}
                onCheck={email => {
                  setConfig({...config, email_notification: email});
                }}
              />
              <span className="text-xs leading-[18px] text-body">Email</span>
            </div>

            {/*
              ADD EMAIL INPUT HERE WHEN EMAIL IS SELECTED
            */}

            {config.email_notification && (
              <div className="my-4 ">
                <label
                  htmlFor="app"
                  className="relative mt-6 mb-2 text-xs text-body"
                >
                  Add email address
                </label>
                <CreatableSelect
                  components={{
                    DropdownIndicator: null,
                  }}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={newValue => {
                    setValue(newValue);
                    setConfig({
                      ...config,
                      email_addresses: newValue?.map(v => v.label),
                    });
                  }}
                  onInputChange={newValue => {
                    setInputValue(newValue);
                  }}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter emails here and press space bar or enter or tab or comma..."
                  value={value}
                  className={`basic-multi-select apps-multi-select rounded ${
                    config.email_notification && noEmail
                      ? 'border border-danger '
                      : ''
                  }}`}
                  classNamePrefix="react-select"
                  multiValueLabel
                />
              </div>
            )}

            <div className="flex items-center gap-2 pl-4 pr-2 py-[10px]  mt-6 bg-white  p-4 rounded">
              <AMLToggle
                isChecked={config.webhook_notification}
                onCheck={webhook => {
                  setConfig({...config, webhook_notification: webhook});
                }}
              />
              <span className="text-xs leading-[18px] text-body">Webhook</span>
            </div>

            {config.webhook_notification && (
              <>
                <div className="my-4 ">
                  <label
                    htmlFor="app"
                    className="relative mt-6 mb-2 text-xs text-body"
                  >
                    Select an app
                  </label>
                  <Select
                    defaultValue={appData}
                    value={appData}
                    onChange={app => {
                      setSelectApp(app);
                      setConfig({...config, app_id: app.value});
                    }}
                    options={userApps}
                    placeholder="Select app"
                    components={{DropdownIndicator}}
                    classNamePrefix="react-select"
                    styles={customStyles}
                    isSearchable={false}
                    menuPlacement="auto"
                    noOptionsMessage={() =>
                      'Sorry no app found, please create an app first'
                    }
                  />
                </div>

                <button
                  className="flex items-center gap-[2px] ml-auto mt-2"
                  onClick={() => setNewAppForm(prev => !prev)}
                  type="button"
                >
                  <img
                    src={newAppForm ? times : plus}
                    alt=""
                    width={12}
                    height={12}
                  />
                  <span className="text-xs font-medium text-body">
                    {newAppForm ? 'Close' : 'Create a new app'}
                  </span>
                </button>

                {newAppForm && (
                  <div className="p-6 mt-2 rounded bg-white80 animate-dropdown">
                    <h3 className="text-base font-medium text-grey">
                      Create App
                    </h3>
                    <CreateAppForm
                      setOpen={() => setNewAppForm(prev => !prev)}
                    />
                  </div>
                )}
              </>
            )}

            <div className="flex mt-8 gap-2">
              <PrimaryButton
                loadColor="#2A5392"
                disabled={
                  !Object.values(data).every(obj =>
                    Array.isArray(obj) ? obj.length > 0 : obj,
                  ) || configLoading
                }
                loading={configLoading}
                buttonText="Save settings"
                className="flex-1  "
                onClick={() => {
                  if (amlConfig && amlConfig?.entity?.id) {
                    delete config?.company_id;
                    // delete config.app_id;
                    delete config?.id;
                    return amlConfigApi(
                      {
                        ...config,
                        id: amlConfig?.entity?.id,
                      },
                      'PUT',
                      setOpen,
                    );
                  } else {
                    return amlConfigApi(config, 'POST', setOpen);
                  }
                }}
              />
              <SecondaryButton
                onClick={() => {
                  // setConfig({...defaultConfig});
                  setOpen(false);
                }}
                buttonText="Cancel"
                className="w-[160px]"
              />
            </div>
          </form>
        </div>
      </ModalWrapper>
    );
  }
}

function ModalWrapper({open, setOpen, children, modalTitle}) {
  return (
    <Modal
      modalTitle={modalTitle}
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[528px] bg-white80 overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      {children}
    </Modal>
  );
}

export default connect(state => state, Actions)(OngoingMonitoringConfig);
