/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useCallback, useContext} from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  PhoneNumberInput,
} from '../../../components';
import {lock, profileIc} from '../../../assets/images/images';
import ChangePasswordModal from './ChangePasswordModal';
import SettingsLayout from '../SettingsLayout';
import {connect} from 'react-redux';
import * as Actions from '../../../store/actions';
import SetPasswordModal from './SetPasswordModal';
import {PageContext} from '../../../helpers/context';

function SettingsProfile({auth: {user, loading}, updateUserProfile}) {
  const [phone, setPhone] = useState('');
  const [openChangePasswordModal, setOpenCPM] = useState(false);
  const [openSetPasswordModal, setOpenSPM] = useState(false);
  const [inputs, setUserInputs] = useState({
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
  });

  const handleChange = e => {
    setUserInputs({...inputs, [e.target.name]: e.target.value});
  };

  useEffect(() => {
    if (inputs?.first_name || inputs?.last_name) {
      setUserInputs(prev => ({
        ...user,
        ...prev,
        mobile: phone,
      }));
    }
    setPhone(user?.mobile);
  }, [user, inputs?.first_name, inputs?.last_name]);

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('profile');
  }, [setPageName]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (inputs) {
        updateUserProfile({
          first_name: inputs.first_name,
          last_name: inputs.last_name,
          mobile: phone,
        });
      }
    },
    [phone, updateUserProfile, inputs],
  );

  // const canEditProfile =
  //   userDetails?.permissions?.settingsPermissions?.editProfile;

  return (
    <SettingsLayout
      pageTitle="Profile"
      pageIcon={profileIc}
      action={
        <PrimaryButton
          onClick={handleSubmit}
          buttonText="Save Changes"
          xPadding="px-4"
          className="hidden sm:flex"
          loading={loading}
          disabled={loading}
        />
      }
    >
      <ChangePasswordModal
        open={openChangePasswordModal}
        setOpen={setOpenCPM}
      />
      <SetPasswordModal open={openSetPasswordModal} setOpen={setOpenSPM} />
      <section className="mt-8 md:mt-10">
        <form className="flex flex-col w-full gap-6 md:flex-row md:items-start">
          <div className="md:w-1/2">
            <h3 className="text-xs font-medium uppercase text-body">
              personal information
            </h3>

            <div className="p-4 mt-4 bg-white rounded md:mt-6 md:p-6">
              <label htmlFor="first_name">
                First name
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="mt-2"
                  placeholder={inputs?.first_name || 'John'}
                  onChange={handleChange}
                  value={inputs?.first_name || ''}
                />
              </label>
              <label htmlFor="last_name" className="mt-6">
                Last name
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  className="mt-2"
                  placeholder={inputs?.last_name || 'Doe'}
                  onChange={handleChange}
                  value={inputs?.last_name || ''}
                />
              </label>
              <label htmlFor="foom" className="mt-6 mb-6">
                Email address
                <input
                  type="email"
                  name="email"
                  id="foom"
                  className="mt-2 cursor-not-allowed"
                  placeholder={inputs?.email || 'me@example.com'}
                  disabled
                  value={inputs?.email || ''}
                />
              </label>
              <PhoneNumberInput phone={phone} setPhone={setPhone} />
            </div>
          </div>
          <div className="rounded md:w-1/2">
            <h3 className="text-xs font-medium uppercase text-body">
              security
            </h3>

            <div className="p-6 mt-3 bg-white">
              <SecondaryButton
                buttonText={
                  user?.hasPassword ? 'Change password' : 'Set password'
                }
                className="flex gap-2 py-2 bg-white align-start text-grey80"
                xPadding="px-3"
                src={lock}
                alt="lock"
                width={16}
                height={16}
                onClick={
                  user?.hasPassword
                    ? () => setOpenCPM(true)
                    : () => setOpenSPM(true)
                }
                disabled={loading}
              />
            </div>
          </div>

          <PrimaryButton
            buttonText="Save Changes"
            xPadding="block sm:hidden w-full"
            onClick={handleSubmit}
            loading={loading}
          />
        </form>
      </section>
    </SettingsLayout>
  );
}

export default connect(state => state, Actions)(SettingsProfile);
