import React from 'react';
import {connect} from 'react-redux';
import {Modal, SecondaryButton, TertiaryButton} from '../../components';
import * as Actions from '../../store/actions';
import {useMutation, useQueryClient} from 'react-query';
import {deleteAWidget} from '../../requests/queries/widgets';
import {useNavigate} from 'react-router-dom';

function DeleteFlowModal({
  flowId,
  deleteModal,
  setDeleteModal,
  redirect = false,
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {mutate, isLoading} = useMutation(
    ['delete-widget', flowId],
    deleteAWidget,
    {
      onSuccess: () => {
        setDeleteModal(false);
        queryClient.invalidateQueries('all-widgets');
        if (redirect) {
          navigate('/easy-onboard');
        }
      },
    },
  );
  const handleDelete = () => {
    mutate(flowId);
  };

  return (
    <Modal
      show={deleteModal}
      modalTitle="Are you sure you want to delete this flow?"
      onClose={setDeleteModal}
      extraModalClasses="max-w-[512px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="mt-4">
        <p className="text-start text-sm text-body font-medium">
          By deleting this flow, you would not be able to retrieve it again.
        </p>
      </div>

      <div className="mt-4 flex items-center gap-4">
        <SecondaryButton
          onClick={() => setDeleteModal(false)}
          buttonText="Cancel"
        />
        <TertiaryButton
          onClick={handleDelete}
          buttonText="Delete flow"
          className="grow"
          loading={isLoading}
          disabled={isLoading}
        />
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(DeleteFlowModal);
