import React, {useCallback, useState} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {
  allowedEvents,
  blockedEvents,
  calendar,
  pendingEvents,
  profileIc,
  totalEvents as totalIcon,
} from '../../../../assets/images/images';
import {Card, CustomDateRadio, Dropdown, Radios} from '../../../../components';
import {Constants} from '../../../../helpers';
import {CheckboxContextProvider} from '../../../../helpers/context/CheckboxContext';
import {customDateFilters} from '../../../../helpers/customDateFilters';
import {
  DashboardPieChartLeft,
  DashboardPieChartRight,
} from '../../Overview/DonutCharts';
import Layout from '../Layout';
import * as Actions from '../../../../store/actions';
import {convertToPercentage} from '../../../../helpers/convertToPercentage';
import {useFlowOverview} from '../hooks/useOverview';

function CustomDate({...otherProps}) {
  const {setFilters, filters: currentFilters} = otherProps;
  const [date, setDate] = useState(0);
  const [startDate, setStartDate] = useState(new Date('01/08/2021'));
  const [endDate, setEndDate] = useState(new Date());
  const [customDate, setCustomDate] = useState(false);
  const [dateCount, setDateCount] = useState(0);
  const [dateOpen] = useState(false);
  const maxDate = new Date();

  const onCustomDateCheck = e => {
    const {checked} = e.target;
    if (checked) {
      setDate(1);
      setCustomDate(true);
    }
  };

  const onDateCheck = useCallback(
    e => {
      const {value} = e.target;
      customDateFilters({
        value,
        appFilters: currentFilters,
        onCheck: setFilters,
      });

      setDate(value);
      setDateCount(1);

      setCustomDate(false);
    },
    [currentFilters, setFilters],
  );
  return (
    <CheckboxContextProvider>
      <div className="hidden w-fit ml-auto mt-8 rounded-lg md:block ">
        <div className="flex items-center justify-end gap-4 mb-4 mr-8">
          <div className="relative">
            <Dropdown
              dropdownBtn={
                <div
                  className={`flex gap-8 items-center border border-grey4 py-[11px] transition-all ease-in-out ${
                    dateCount > 0 ? 'bg-brandBlue' : 'bg-white80'
                  }  px-4 rounded cursor-pointer`}
                >
                  <span
                    className={`text-xs font-medium ${
                      dateCount > 0 && 'text-white'
                    } uppercase text-body`}
                  >
                    Date
                  </span>
                  {dateCount > 0 ? (
                    <span className="flex items-center justify-center text-xs text-brandBlue w-[18px]  h-[18px] bg-white rounded-full">
                      {dateCount}
                    </span>
                  ) : (
                    <img src={calendar} alt="" />
                  )}
                </div>
              }
            >
              <div className="absolute z-10 w-full mt-2">
                <Radios
                  className="absolute z-10 w-64 mt-2 shadow-sm"
                  data={Constants.lookups.dates}
                  filterName="date"
                  onCheck={onDateCheck}
                  selectedCount={date === '' ? 0 : 1}
                  onClick={onDateCheck}
                  activeIndex={dateOpen}
                  radioValue={date}
                >
                  <label
                    htmlFor="custom_date"
                    className="relative flex flex-row items-center gap-2 pl-4 font-normal transition duration-500 ease-in-out"
                  >
                    <input
                      type="radio"
                      value="custom_date"
                      id="custom_date"
                      name="date"
                      className="w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue"
                      onClick={onCustomDateCheck}
                    />
                    <span className="text-sm text-grey">
                      Specific date range
                    </span>
                  </label>
                  {customDate && (
                    <CustomDateRadio
                      selectedStart={startDate}
                      selectedEnd={endDate}
                      onChangeStart={date => {
                        const start = moment(date).format(
                          'YYYY-MM-DD HH:mm:ss',
                        );
                        setFilters({...currentFilters, start});
                        setStartDate(date);
                      }}
                      onChangeEnd={date => {
                        const end = moment(date).format('YYYY-MM-DD HH:mm:ss');
                        setFilters({...currentFilters, end});
                        setEndDate(date);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={maxDate}
                      minDate={startDate}
                    />
                  )}
                </Radios>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </CheckboxContextProvider>
  );
}

function FlowsAnalysis() {
  const [filters, setFilters] = useState({
    start: null,
    end: null,
  });
  const {
    TOTAL_NON_FLAGGED,
    TOTAL_RESOLVED_BLOCKED,
    TOTAL_RESOLVED_ALLOWED,
    TOTAL_EVENTS,
    TOTAL_FLAGGED,
    TOTAL_RESOLVED,
    TOTAL_BLOCKED,
    TOTAL_ALLOWED,
    TOTAL_PENDING,
    isLoading,
  } = useFlowOverview({filters});

  return (
    <Layout bg="bg-white" pageTitle="Analysis" pageIcon={profileIc}>
      <CustomDate setFilters={setFilters} />
      <div className="flex flex-col lg:flex-row items-center gap-4 mt-6 mb-12">
        <Card
          className="w-full"
          img={totalIcon}
          title="Total Events"
          body={TOTAL_EVENTS?.count || 0}
          loading={isLoading}
          count={TOTAL_EVENTS?.count}
        />
        <Card
          className="w-full"
          img={pendingEvents}
          title="Pending Cases"
          body={TOTAL_PENDING?.count || 0}
          loading={isLoading}
          count={TOTAL_PENDING?.count}
        />
        <Card
          className="w-full"
          img={allowedEvents}
          title="Allowed Cases"
          body={TOTAL_ALLOWED?.count || 0}
          loading={isLoading}
          count={TOTAL_ALLOWED?.count}
        />
        <Card
          className="w-full"
          img={blockedEvents}
          title="Blocked Cases"
          body={TOTAL_BLOCKED?.count || 0}
          loading={isLoading}
          count={TOTAL_BLOCKED?.count}
        />
      </div>

      <div className="flex gap-6 mt-8 basis-full">
        <div className="bg-white80 rounded-lg pt-[26px] px-3 sm:px-[22px] pb-5 basis-full sm:basis-1/2">
          <h3 className="mb-4 text-lg font-medium text-center sm:text-left text-grey">
            Resolved cases
          </h3>
          <div className="flex flex-col items-center gap-3 sm:flex-row">
            <DashboardPieChartLeft
              allowedPercentage={
                +convertToPercentage(
                  TOTAL_RESOLVED_ALLOWED?.count || 0,
                  TOTAL_RESOLVED?.count || 0,
                )?.replaceAll('%', '')
              }
              blockedPercentage={
                +convertToPercentage(
                  TOTAL_RESOLVED_BLOCKED?.count || 0,
                  TOTAL_RESOLVED?.count || 0,
                ).replaceAll('%', '')
              }
              total={TOTAL_RESOLVED?.count}
              loading={isLoading}
            />
            <div className="flex gap-2 sm:gap-8 sm:ml-6 sm:flex-col">
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-status-400" />
                <div className="flex flex-col gap-[5px]">
                  <p className="text-xs font-medium uppercase text-body">
                    Allowed
                  </p>
                  <p className="text-xl font-medium text-grey">
                    {isLoading
                      ? '0%'
                      : convertToPercentage(
                          TOTAL_RESOLVED_ALLOWED?.count || 0,
                          TOTAL_RESOLVED?.count || 0,
                        )}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-[#3F7CDB]" />
                <div className="flex flex-col gap-[5px]">
                  <p className="text-xs font-medium uppercase text-body">
                    Blocked
                  </p>
                  <p className="text-xl font-medium text-grey">
                    {isLoading
                      ? '0%'
                      : convertToPercentage(
                          TOTAL_RESOLVED_BLOCKED?.count || 0,
                          TOTAL_RESOLVED?.count || 0,
                        )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white80 rounded-lg pt-[26px] px-[22px] pb-5 basis-1/2 hidden md:block">
          <h3 className="mb-4 text-lg font-medium text-grey">Flagged events</h3>
          <div className="flex items-center gap-12">
            <DashboardPieChartRight
              flaggedPercentage={
                +convertToPercentage(
                  TOTAL_FLAGGED?.count,
                  TOTAL_EVENTS?.count,
                ).replaceAll('%', '')
              }
              nonFlaggedPercentage={
                +convertToPercentage(
                  TOTAL_NON_FLAGGED,
                  TOTAL_EVENTS?.count,
                ).replaceAll('%', '')
              }
              flagged={TOTAL_FLAGGED?.count}
              loading={isLoading}
            />
            <div className="flex gap-2 sm:gap-8 sm:ml-6 sm:flex-col">
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-status-404" />
                <div className="flex flex-col gap-[5px]">
                  <p className="text-xs font-medium uppercase text-body">
                    FLAGGED EVENTS
                  </p>
                  <p className="text-xl font-medium text-grey">
                    {convertToPercentage(
                      TOTAL_FLAGGED?.count,
                      TOTAL_EVENTS?.count,
                    )}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-red" />
                <div className="flex flex-col gap-[5px]">
                  <p className="text-xs font-medium uppercase text-body">
                    NON FLAGGED EVENTS
                  </p>
                  <p className="text-xl font-medium text-grey">
                    {convertToPercentage(
                      TOTAL_NON_FLAGGED,
                      TOTAL_EVENTS?.count,
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default connect(state => state, Actions)(FlowsAnalysis);
