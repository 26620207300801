import reduxApi from '../../helpers/reduxApi';
import {toast} from 'react-toastify';
import {
  WIDGETS,
  W_LOADING,
  SINGLE_APP,
  CREATE_WIDGETS,
  UPDATE_WIDGET,
  SET_WIDGET_SCREEN,
  SET_WIDGET_PAGES,
  GET_SINGLE_WIDGET,
  CLEAR_WIDGET_PAGES,
  SET_WIDGET_DEFAULT,
  MUTATE_WIDGET_PAGES,
  PREVIEW_WIDGET_CODE,
  SET_SCREEN_TYPE,
  SET_FRAUD_DEFAULT_PAGES,
  UPDATED,
  SET_FRAUD_RULES,
  PRICING,
  SET_WIDGET_CURRENT_PRICING,
  SET_WIDGET_TOTAL_CURRENT_PRICING,
} from '../types';
import {Constants} from '../../helpers';
import {getCookieValue} from '../../helpers/getCookie';
import {toggleStringConversion} from '../../routes/EasyOnboard/widgetHelpers';

export const getAllWidgets = ({page, limit, status, start, end, appIds}) =>
  reduxApi(
    `/users/widget/kyc?limit=${limit || 10}&page=${page || 1}${
      status ? `&status=${status}` : ''
    }${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}${
      appIds ? `&app_ids=${appIds}` : ''
    }`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        const noFilter = !status && !start && !end;
        const widgetData =
          !noFilter && data?.widgets.length === 0 ? undefined : data;
        dispatch({payload: widgetData, type: WIDGETS});
      },
    },
    {error: 'ERROR', loading: W_LOADING, responder: WIDGETS},
  );

export const createWidget = widgetData =>
  reduxApi(
    `/users/widget/kyc`,
    'POST',
    {app: widgetData?.app, ...widgetData?.update},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: CREATE_WIDGETS});
        const {message, type, preview, navigate, to} = widgetData;

        if (preview === 'preview') {
          dispatch(getWidget(data?.widget?._id));
          dispatch(previewWidgetCode(data?.widget?._id));

          return;
        }
        if (to) {
          navigate(to);
          return;
        }
        toast.success(message);

        if (type && type !== 'changes') {
          setTimeout(() => {
            window.location.href = '/easy-onboard';
          }, 1000);
        } else {
          dispatch({type: UPDATED});
          navigate({
            pathname: Constants.PAGES_URL.EASY_ONBOARD_UPDATE_FLOW,
            search: `?id=${data?.widget?._id}`,
          });
        }

        // dispatch(
        //   updateWidget(
        //     {id: data?.widget?._id, ...widgetData?.update},
        //     widgetData?.message,
        //     widgetData?.type,
        //     widgetData?.preview,
        //     widgetData?.navigate,
        //   ),
        // );
      },
    },
    {error: 'ERROR', loading: W_LOADING, responder: CREATE_WIDGETS},
  );

export const updateWidget = (
  {id, to, navigate, ...widgetData},
  message,
  type,
  preview,
) =>
  reduxApi(
    `/users/widget/kyc/${id}`,
    'PUT',
    {...widgetData},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: UPDATE_WIDGET});
        if (to) {
          navigate(to);
          return;
        }

        if (preview === 'preview') {
          dispatch(getWidget(id));
          dispatch(previewWidgetCode(id));

          return;
        }

        toast.success(message);
        if (type && type !== 'changes') {
          setTimeout(() => {
            window.location.href = '/easy-onboard';
          }, 1000);
        } else {
          dispatch({type: UPDATED});
          navigate({
            pathname: Constants.PAGES_URL.EASY_ONBOARD_UPDATE_FLOW,
            search: `?id=${id}`,
          });
        }
      },
    },
    {error: 'ERROR', loading: W_LOADING, responder: UPDATE_WIDGET},
  );

export const previewWidgetCode = (id, customWidgetUrl) =>
  reduxApi(
    `/users/widget/kyc/${id}/preview`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: PREVIEW_WIDGET_CODE});

        window.open(
          `${
            customWidgetUrl ?? process.env.REACT_APP_WIDGET_BASE_URL
          }?widget_id=${id}&token=${data?.previewCode}`,
          '_blank',
        );

        window.location.replace(
          `${Constants.PAGES_URL.EASY_ONBOARD_UPDATE_FLOW}?id=${id}`,
        );
      },
    },
    {error: 'ERROR', loading: W_LOADING, responder: PREVIEW_WIDGET_CODE},
  );

export const deleteWidget = id =>
  reduxApi(
    `/users/widget/kyc/${id}`,
    'DELETE',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({type: UPDATED});
        toast.success(data?.message);
        setTimeout(() => {
          window.location.href = '/easy-onboard';
        }, 1000);
      },
    },
    {error: 'ERROR', loading: W_LOADING, responder: UPDATED},
  );

export const getWidget = (id, navigate, temp) =>
  reduxApi(
    `/users/widget/kyc/${id}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        if (data) {
          const {
            cost,
            country,
            env,
            pages,
            name,
            direct_feedback,
            duplicate_check,
            review_process,
            aml_check,
          } = data?.widget || {};

          !temp
            ? dispatch({payload: data, type: UPDATE_WIDGET})
            : dispatch({
                payload: {
                  widget: {
                    cost,
                    country,
                    env,
                    pages,
                    name: name?.replace(/ -.*$/, ''),
                    direct_feedback,
                    duplicate_check,
                    review_process,
                    aml_check,
                  },
                },
                type: UPDATE_WIDGET,
              });
          const informationLines = toggleStringConversion(
            data?.widget?.index_instructions || [],
            true,
          );

          const pagesArray = [
            {
              page: 'indemnity',
              config: {index_instructions: informationLines},
            },
            ...(data?.widget?.pages || []),
          ];
          const value = pagesArray?.map((page, index) => ({
            ...page,
            [`${page?.page}-${index}`]: !!page?.page,
            pageId: index,
          }));

          const rules =
            data?.widget?.rules &&
            Object.entries(data?.widget?.rules).map(([page, value]) => ({
              page,
              [page]: {...value},
              isActive: true,
            }));
          rules && dispatch(setFraudRules(rules));

          value && dispatch(setWidgetPages(value));
          value && dispatch(setWidgetDefaultPages(value));
          navigate({
            pathname: Constants.PAGES_URL.EASY_ONBOARD_UPDATE_FLOW,
            search: !temp
              ? `?id=${id}`
              : `?name=${data?.widget.name?.replace(/ -.*$/, '')}`,
          });
        }
      },
    },
    {error: 'ERROR', loading: W_LOADING, responder: UPDATE_WIDGET},
  );
export const getWidgetTemplate = (data, navigate) => async dispatch => {
  dispatch({payload: null, type: UPDATE_WIDGET});

  const value = data?.widget.pages?.map((page, index) => ({
    ...page,
    [`${page?.page}-${index}`]: !!page?.page,
    pageId: index,
  }));
  if (value) {
    await dispatch(setWidgetPages(value));
    await dispatch(setWidgetDefaultPages(value));
    await dispatch({payload: data, type: UPDATE_WIDGET});
    await localStorage.setItem('pages', JSON.stringify(value));

    navigate({
      pathname: Constants.PAGES_URL.EASY_ONBOARD_UPDATE_FLOW,
      search: `?id=${data?.widget.name?.replace(' ', '-')}`,
    });
  }
};

///////////////// PRICING START //////////////////

function setAllValuesToZero(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      setAllValuesToZero(obj[key]);
    } else {
      obj[key] = 0;
    }
  }
  return obj;
}

const envData = getCookieValue('env');

export const getWidgetPricing = () =>
  reduxApi(
    `/users/widget/kyc/pricing`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        if (data) {
          const pricing = {
            'user-data': {services: {...data?.userData}},
            'government-data': {services: {...data?.governmentData}},
            'business-data': {services: {...data?.businessData}},
            'business-id': {services: {...data?.businessId}},
            'phone-number': {services: {...data?.phoneNumber}},
            'additional-document': {services: {...data?.additionalDocs}},
            email: {services: {...data?.email}},
            address: {services: {...data?.address}},
            selfie: {services: {...data?.liveness}},
            id: {services: {...data?.governmentId}},
            aml: {services: {...data?.userData}},
          };

          if (envData === 'Production') {
            dispatch({type: PRICING, payload: pricing});
          } else {
            const sandBoxPricing = setAllValuesToZero(pricing);
            dispatch({type: PRICING, payload: sandBoxPricing});
          }
        }
      },
    },
    {error: 'ERROR', loading: W_LOADING, responder: PRICING},
  );

export const setWidgetCurrentPricing = data => dispatch => {
  dispatch({payload: data, type: SET_WIDGET_CURRENT_PRICING});
};
export const setWidgetTotalCurrentPricing = data => dispatch => {
  dispatch({payload: data, type: SET_WIDGET_TOTAL_CURRENT_PRICING});
};

///////////////// PRICING END //////////////////

export const setWidgetDefaultPages = data => dispatch => {
  dispatch({payload: data, type: SET_WIDGET_DEFAULT});
};
export const setFraudDefaultPages = data => async dispatch => {
  await dispatch({payload: null, type: SET_FRAUD_DEFAULT_PAGES});
  dispatch({payload: data, type: SET_FRAUD_DEFAULT_PAGES});
};
export const setWidgetScreen = data => dispatch => {
  dispatch({payload: data, type: SET_WIDGET_SCREEN});
};
export const setScreenType = data => dispatch => {
  dispatch({payload: data, type: SET_SCREEN_TYPE});
};
export const mutateWidgetPages = data => dispatch => {
  dispatch({payload: data, type: MUTATE_WIDGET_PAGES});
  data?.type === 'add' && toast.success('Widget pages updated');
};
export const setSingleWidget = data => dispatch => {
  dispatch({payload: data, type: GET_SINGLE_WIDGET});
};
export const setSingleApp = data => dispatch => {
  dispatch({payload: data, type: SINGLE_APP});
};
export const setWidgetPages = data => dispatch => {
  // dispatch(setWidgetTotalCurrentPricing({}));
  dispatch({payload: data, type: SET_WIDGET_PAGES});
};
export const setFraudRules = data => dispatch => {
  dispatch({payload: data, type: SET_FRAUD_RULES});
};
export const clearWidgetPages = () => dispatch => {
  dispatch({type: CLEAR_WIDGET_PAGES});
};
