/* eslint-disable react-hooks/exhaustive-deps */

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Modal} from '../../../components';
import {connect} from 'react-redux';
import * as Actions from '../../../store/actions';
import {infoRedSm} from '../../../assets/images/images';
import {
  disclaimerPageDefaultInfo as defaultInformation,
  toggleStringConversion,
} from '../widgetHelpers';
import {EasyOnboardContext} from '../../../helpers/context/EasyOnboardContext';

function ConfigDisclaimerModal({
  widgets: {singleWidget},
  setWidgetScreen,
  setWidgetPages,
  ...otherProps
}) {
  const {open, setOpen} = otherProps;
  const [error, setError] = useState(false);
  const [config, setConfig] = useState({});
  const {widgetData, setWidgetData} = useContext(EasyOnboardContext);

  useEffect(() => {
    const trimmedInformation = (
      config?.index_instructions || defaultInformation
    )?.replace(/^\s+|\s+$/g, '');

    if (!singleWidget || !singleWidget?.widget?.index_instructions) {
      setError(false);
      setConfig({
        index_instructions: trimmedInformation,
      });
      const informationLines = toggleStringConversion(trimmedInformation);
      setWidgetData({...widgetData, index_instructions: informationLines});
    } else {
      setError(false);
      const newConfig = {
        index_instructions: !widgetData?.index_instructions
          ? trimmedInformation
          : toggleStringConversion(widgetData?.index_instructions, true) ||
            trimmedInformation,
      };
      const informationLines = toggleStringConversion(
        newConfig?.index_instructions,
      );

      setWidgetData({...widgetData, index_instructions: informationLines});
      setConfig({...config, ...newConfig});
    }
    return () => {
      setConfig({
        index_instructions:
          toggleStringConversion(widgetData?.index_instructions, true) ||
          defaultInformation,
      });
    };
  }, [Object.keys(config).length, widgetData?.index_instructions?.length]);

  const handleUpdateConfig = useCallback(
    e => {
      const {value, name} = e.target;
      if (value?.length > 350) {
        setError(true);
      } else {
        setError(false);
        const newConfig = {...config, [name]: value};
        setConfig(newConfig);
        const informationLines = toggleStringConversion(
          newConfig?.index_instructions,
        );
        setWidgetData(prev => ({
          ...prev,
          index_instructions: informationLines,
        }));
      }
    },
    [config],
  );

  return (
    <Modal
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[339px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Configuration"
    >
      <p className="text-left text-body text-sm mt-8 mb-2 font-medium">
        Disclaimer Instructions
      </p>
      <textarea
        placeholder="Input Instructions"
        name="index_instructions"
        onChange={e => handleUpdateConfig(e)}
        className={`placeholder:text-sm  py-3 resize-y min-h-[40px] max-h-[100px] ${
          error && 'border-0  focus:outline-danger'
        } `}
        value={config?.index_instructions}
        maxLength={2000}
      ></textarea>
      {error && (
        <div className="flex  bg-statusBg-500 p-2 rounded">
          <img src={infoRedSm} alt="" />
          {config.index_instructions?.length === 0 ? (
            <p className="text-xs ml-3 text-body">
              Field is required. Please input Instructions with no more than 350
              characters.
            </p>
          ) : (
            <p className="text-xs ml-3 text-body">
              Please Instructions should not be more than 350 characters.
            </p>
          )}
        </div>
      )}
      <p className="text-end text-body text-sm font-medium mt-2">
        {toggleStringConversion(widgetData?.index_instructions, true)?.length ||
          0}
        /350
      </p>
    </Modal>
  );
}

export default connect(state => state, Actions)(ConfigDisclaimerModal);
