import React, {useContext, useEffect, useMemo, useState} from 'react';
import * as Actions from '../../../../store/actions';
import {
  EmptyState,
  Loader,
  PageLimit,
  Pagination,
  TableLayout,
} from '../../../../components';
import {profileIc} from '../../../../assets/images/images';
import Layout from '../Layout';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';

import moment from 'moment';
import {PageContext} from '../../../../helpers/context';
import {useQuery} from 'react-query';
import {fetchEvents} from '../../../../requests/queries/flows';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';
import {getStatusTextAndStyles} from '../../../../helpers/getStatusTextAndStyles';

function FlowsEvents() {
  const {slug} = useParams();
  const [currentPage, setCurrentPage] = useState('1');
  const [limit, setLimit] = useState(10);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  const params = useMemo(
    () => ({
      project_id: slug,
      limit,
      page: currentPage,
    }),
    [currentPage, limit, slug],
  );

  const {data: flowEvents, isLoading} = useQuery(
    ['flow-events', {params}],
    () => fetchEvents({params}),
  );

  const EVENTS = useMemo(
    () => !isLoading && flowEvents && flowEvents?.rows,
    [flowEvents, isLoading],
  );

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  return (
    <Layout pageTitle="Events" pageIcon={profileIc}>
      {isLoading ? (
        <Loader height={65} />
      ) : !isLoading && EVENTS?.length === 0 ? (
        <EmptyState body="No event(s) found" noBtn height={65} />
      ) : (
        <section>
          <div className="flex items-center justify-between py-7 bg-white -ml-12 pl-12 -mr-4 xl:mr-[-76px] pr-7">
            <h2 className="hidden text-lg font-medium text-grey sm:block">
              Events
            </h2>
          </div>
          <TableLayout
            negativeMargins
            negativeRightMargin="-mr-4 xl:-mr-[76px]"
          >
            <thead className="text-xs font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>Transaction id</span>
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>AMOUNT</span>
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>score</span>
                  </div>
                </th>

                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>outcome</span>
                  </div>
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>transaction type</span>
                  </div>
                </th>
                {/*<th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">*/}
                {/*  <div className="flex items-end gap-1">*/}
                {/*    <span>USER</span>*/}
                {/*    <img src={dropdownGrey} alt="" width={16} height={16} />*/}
                {/*  </div>*/}
                {/*</th>*/}
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  <div className="flex items-end gap-1">
                    <span>date created</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm bg-white">
              {EVENTS &&
                EVENTS.map((event, i) => (
                  <tr className="border-b border-grey60" key={i}>
                    <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                      {event?.id || '-'}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {shortenLargeNumbers(event?.amount || 0, true, {
                        startAt: 'B',
                      })}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {shortenLargeNumbers(event?.score || 0, null, {
                        startAt: 'B',
                        decimals: 1,
                      })}
                    </td>

                    <td>
                      <div
                        className={`uppercase py-1 px-2  text-xs font-medium ${
                          getStatusTextAndStyles(event?.outcome)?.styles
                        }  rounded-full flex items-center w-fit`}
                      >
                        <span className="-mb-[2px]">
                          {event?.outcome || '-'}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        className={`uppercase py-1 px-2  text-xs font-medium ${
                          getStatusTextAndStyles(event?.transaction_type)?.solid
                        }  rounded-full flex items-center w-fit`}
                      >
                        <span className="-mb-[2px]">
                          {event?.transaction_type || '-'}
                        </span>
                      </div>
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {moment(event?.createdAt).format('Do MMM YYYY hh:mm') ||
                        '-'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableLayout>

          <div className="flex items-center justify-between flex-wrap gap-4 sm:gap-0 mt-8">
            <div className="flex items-center text-body text-tiny">
              <PageLimit
                onLimitChange={setLimit}
                total={flowEvents && flowEvents?.count}
                length={EVENTS.length}
                limit={limit}
              />
            </div>

            <div className="mb-8 sm:mb-0">
              <Pagination
                total={flowEvents && Math.ceil(flowEvents?.pages / limit)}
                current={+currentPage}
                onPageChange={activePage => {
                  localStorage.setItem('page', String(activePage));
                  pageClick(activePage);
                }}
              />
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
}

export default connect(state => state, Actions)(FlowsEvents);
