import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {
  DashboardLayout,
  FilterButton,
  PrimaryButton,
} from '../../../components';
import {
  transactionsMobileNav,
  overviewCashflow,
  feesCharged,
  overviewInflow,
  overviewOutflow,
} from '../../../assets/images/images';
import UploadTransactionsModal from './Projects/UploadTransactionsModal';
import ManualTransactionModal from './Projects/ManualTransactionModal';
import TransactionDetailsModal from './TransactionDetailsModal';
import {TransactionsTable} from './TransactionsTable';
import ExternalTransactionModal from './Projects/ExternalTransactionModal';
import {TransactionTabs} from './TransactionTabs';
import ExternalTransactionUploadOptions from './ExternalTransactionUploadOptions';
import {useOverview, useTransactions} from './hooks/useReconQueries';
import {OverviewCards} from './components/OverviewCards';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';

function Transactions() {
  const [openFilter, setOpenFilter] = useState(false);
  const [tabType, setTabType] = useState('internal');
  const [fileType, setFileType] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const {userDetails} = useSelector(state => state?.auth);
  const [isUploadTransactions, setIsUploadTransactions] = useState(false);
  const [externalTransaction, setExternalTransaction] = useState(false);
  const [openExternal, setOpenExternal] = useState(false);
  const [createManualTransactions, setCreateManualTransactions] =
    useState(false);
  const [transactionDetails, setTransactionDetails] = useState(false);
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  const {tableData, isLoading} = useTransactions({
    entity_id: 'company_id',
    id: userDetails?.company?.id,
    tabType,
    ...query,
  });

  const {overviewLoading, inFlow, outFlow} = useOverview({
    entity_id: 'company_id',
    id: userDetails?.company?.id,
  });
  const openTransactionDetails = () => {
    setTransactionDetails(true);
  };

  const closeTransactionDetails = () => {
    setTransactionDetails(false);
  };

  const openUploadTransactionsModal = () => {
    setIsUploadTransactions(true);
  };

  const closeUploadTransactionsModal = () => {
    setIsUploadTransactions(false);
  };

  const openCreateManualTransactionsModal = () => {
    setCreateManualTransactions(true);
    closeUploadTransactionsModal();
  };

  const closeCreateManualTransactionsModal = () => {
    setCreateManualTransactions(false);
  };

  const flows = [
    {
      icon: overviewCashflow,
      alt: 'cash-flow',
      title: 'Net Cash Flow',
      count: inFlow?.count - outFlow?.count || '0',
      value: shortenLargeNumbers(inFlow?.amount - outFlow?.amount, true, {
        startAt: 'B',
      }),
    },
    {
      icon: overviewInflow,
      alt: 'in-flow',
      title: 'Total Inflow',
      count: inFlow?.count || '0',
      value: shortenLargeNumbers(inFlow?.amount, true, {startAt: 'B'}),
    },
    {
      icon: overviewOutflow,
      alt: 'out-flow',
      title: 'Total Outflow',
      count: outFlow?.count || '0',
      value: shortenLargeNumbers(outFlow?.amount, true, {startAt: 'B'}),
    },
    {
      icon: feesCharged,
      alt: 'fees',
      title: 'Total Fees Charged',
      value: shortenLargeNumbers(
        outFlow?.fee_amount + inFlow?.fee_amount,
        true,
        {startAt: 'B'},
      ),
    },
  ];

  return (
    <DashboardLayout
      bg="bg-white80"
      overFlow="!pr-0"
      leftMargin="!ml-0"
      breadCrumbs={
        <div className="flex items-center">
          <img src={transactionsMobileNav} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer ">Transactions</p>
        </div>
      }
    >
      <UploadTransactionsModal
        show={isUploadTransactions}
        onClose={closeUploadTransactionsModal}
        openCreateManualTransactionsModal={openCreateManualTransactionsModal}
        openExternal={() => {
          closeUploadTransactionsModal();
          setExternalTransaction(!externalTransaction);
        }}
      />

      <ExternalTransactionUploadOptions
        show={externalTransaction}
        onClose={() => setExternalTransaction(!externalTransaction)}
        openUploadModal={() => {
          setExternalTransaction(!externalTransaction);
          setOpenExternal(!openExternal);
        }}
        setFileType={setFileType}
        goBack={openUploadTransactionsModal}
      />

      <TransactionDetailsModal
        show={transactionDetails}
        onClose={closeTransactionDetails}
        transactionId={transactionId}
      />
      <ManualTransactionModal
        show={createManualTransactions}
        onClose={closeCreateManualTransactionsModal}
        goBack={openUploadTransactionsModal}
      />

      <ExternalTransactionModal
        fileType={fileType}
        show={openExternal}
        onClose={setOpenExternal}
        goBack={() => setExternalTransaction(!externalTransaction)}
      />
      <div className="pl-11 pr-8">
        <div className="flex items-center justify-between w-full">
          <h3 className="font-medium text-lg text-grey">
            Transactions
            <span className="italic text-sm text-grey40 font-thin">
              {' '}
              - {tabType}
            </span>
          </h3>

          <div className="items-center hidden gap-4 sm:flex">
            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
            />
            <PrimaryButton
              buttonText="Add Transactions"
              className="rounded-lg"
              onClick={openUploadTransactionsModal}
            />{' '}
          </div>
        </div>
        <div className="w-full flex justify-start mt-5">
          <TransactionTabs
            setTabType={setTabType}
            tabType={tabType}
            setQuery={setQuery}
            query={query}
          />
        </div>

        <OverviewCards overviewLoading={overviewLoading} cardData={flows} />
      </div>
      <TransactionsTable
        transactionsLoading={isLoading}
        openUploadTransactionsModal={openUploadTransactionsModal}
        openTransactionDetails={openTransactionDetails}
        transactions={tableData}
        tabType={tabType}
        setId={setTransactionId}
        setQuery={setQuery}
        query={query}
      />
    </DashboardLayout>
  );
}

export default Transactions;
