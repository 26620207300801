import React, {useState} from 'react';
import AttentionLottie from '../../assets/lotties/AttentionLottie';
import {useTour} from '../../hooks';
import Modal from '../Modal';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';

const steps = [
  {
    id: 1,
    title: 'Manage Teams & Permissions',
    description:
      'Add new roles, update existing roles and grant your team members permission for application use.',
    paddingRight: 'pr-[226px]',
  },
  {
    id: 2,
    title: 'Invite a member',
    description: 'Interview your team members to collaborate with you.',
    paddingRight: 'pr-[76px]',
  },
];

export default function TeamTour({updateTourState, loading, DATA}) {
  const {tourModal, setTourModal, handleTourComplete} = useTour(
    'team_tour',
    loading,
    DATA,
    updateTourState,
  );

  const [step, setStep] = useState(1);

  const handleNext = () => {
    if (step < steps.length) {
      setStep(step + 1);
    }
  };

  const handlePrev = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <Modal
      show={tourModal}
      onClose={setTourModal}
      extraModalClasses="max-w-fit overflow-y-auto h-fit max-h-[95%] sm:max-h-full absolute bottom-0 sm:relative text-left overflow-y-hidden"
      modalPosition="justify-center sm:justify-end"
      modalPadding={`p-6 pt-22 pr-[226px] ${steps[step - 1].paddingRight}`}
      noBottomPadding
      borderRadius="rounded"
      modalBg="bg-transparent"
      pointerEventsNone
      overlayBackground
      hideOnSmallerDevices
    >
      <div className="flex relative outline-none flex-col" tabIndex={0}>
        {step === 1 ? (
          <SecondaryButton
            buttonText="Manage Teams & Permissions"
            className="w-fit self-end pointer-events-none"
          />
        ) : step === 2 ? (
          <PrimaryButton
            buttonText="Invite a member"
            className="w-fit self-end pointer-events-none"
          />
        ) : null}
        <div className="flex gap-8 items-start">
          <div className="p-2 flex flex-col h-fit items-start gap-2 bg-white rounded mt-5 mr-[75px]">
            <p className="text-sm font-medium text-grey">
              {steps[step - 1].title}
            </p>
            <p className="text-sm text-body max-w-[266px]">
              {steps[step - 1].description}
            </p>
            <div className="flex items-center justify-between w-full">
              <button
                className="text-danger text-xs font-medium focus-visible:outline-danger"
                onClick={handleTourComplete}
              >
                Dismiss
              </button>
              <div className="flex items-center gap-2">
                <button
                  className="text-brandBlue text-xs font-medium focus-visible:outline-brandBlue"
                  onClick={handlePrev}
                >
                  Prev
                </button>
                <button
                  className="text-brandBlue text-xs font-medium focus-visible:outline-brandBlue"
                  onClick={
                    step === steps.length ? handleTourComplete : handleNext
                  }
                >
                  {step === steps.length ? 'Finish' : 'Next'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <AttentionLottie
          style={{
            width: '48px',
            height: '48px',
            transform: 'matrix(0, 1, 1, 0, 0, 0)',
          }}
          className="self-end mr-10 absolute top-12"
        />
      </div>
    </Modal>
  );
}
