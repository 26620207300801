/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {
  amlScreeningIc,
  eye,
  infoBlue,
  infoGreen,
  infoRed,
  lookupEmpty,
  // settingsIcon,
} from '../../assets/images/images';
import {
  DashboardLayout,
  EmptyState,
  Pagination,
  PrimaryButton,
  // SecondaryButton,
  TableLayout,
} from '../../components';
import PerformLookupModal from './PerformLookupModal';
import * as Actions from '../../store/actions';
import Loader from '../../components/Loader';
import PageLimit from '../../components/PageLimit';
import moment from 'moment';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {PageContext, TourContext} from '../../helpers/context';
import {AMLScreeningTour} from '../../components/Tour';
// import {AMLToggle} from '../../components/Toggle';
// import OngoingMonitoringConfig from './OngoingMonitoringConfig';
import ToggleOngoingMonitoring from './ToggleOngoingMonitoring';
import ChooseAmlType from './ChooseAmlTypeModal';
import BusinessLookupModal from './BusinessLookupModal';

function AMLScreening({
  getAMLHistories,
  getAllApps,
  getAMLHistory,
  amlConfigApi,
  auth: {userDetails, updated},
  aml: {amlHistories, loading, configLoading},
  apps: {allApps},
}) {
  const [currentPage, setCurrentPage] = useState('1');
  const [resultPage, setResultPage] = useState(false);
  const [limit, setLimit] = useState(10);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [chooseOpen, setChooseOpen] = useState(false);
  const [businessOpen, setBusinessOpen] = useState(false);

  const [open, setOpen] = useState(false);

  const [step, setStep] = useState(1);
  const [dummyData, setDummyData] = useState('');
  const navigate = useNavigate();

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('amllookup');
  }, [setPageName]);

  const appIds =
    useMemo(
      () => allApps?.apps && allApps?.apps?.map(app => app?._id)?.toString(),
      [allApps],
    ) || localStorage.getItem('appIds');

  useEffect(() => {
    getAllApps({});
    userDetails?.company?.id &&
      getAMLHistories({
        page: currentPage,
        companyId: userDetails?.company?.id,
        limit,
        appIds,
      });
  }, [
    getAMLHistories,
    userDetails?.company?.id,
    amlConfigApi,
    limit,
    currentPage,
    getAllApps,
    updated,
    Boolean(appIds?.split(',')?.length),
  ]);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const getStatusInfo = status => {
    switch (status) {
      case 'failed':
        return 'something went wrong';

      case 'available':
        return 'Info available';

      case 'not_availbale':
        return 'No info returned';
      default:
        return 'No info returned';
    }
  };

  const DATA = amlHistories?.records;

  const {tourState, updateTourState} = useContext(TourContext);

  const canPerformAndViewAML =
    userDetails?.permissions?.amlPermissions?.performLookupAndViewHistory;

  // const canConfigureOngoingMonitoring =
  //   userDetails?.permissions?.amlPermissions?.ongoingMonitoring;

  const handleViewAMLDetails = useCallback(
    history => {
      if (
        history?.status === 'not_available' ||
        history?.status === 'not_availbale'
      ) {
        setOpen(true);
        setStep(2);
        setDummyData(
          `${history?.first_name || history?.business_name || '-'} ${
            history?.middle_name || ''
          } ${history?.last_name || ''}`,
        );

        return;
      }
      if (!history?.aml_entity_lookup_id) return;
      if (appIds && history?.id) {
        getAMLHistory({
          id: history?.aml_entity_lookup_id,
          watchlist_history_id: history?.id,
          entityType: history.entity_type,
          navigate,
        });
        localStorage.setItem('aml_app_id', history?.app_id);
        history.entity_type === 'business'
          ? localStorage.setItem('organisation', 'organisation')
          : localStorage.removeItem('organisation');
        localStorage.setItem(
          'amlData',
          JSON.stringify({
            watchlist_id: history?.aml_user_lookup,
            company_id: history?.company_id,
            ongoing_monitoring: history?.ongoing_monitoring || false,
            watchlist_history_id: history?.id,
            no_of_times_checked: history?.no_of_times_checked,
            date_created: history?.date_created,
          }),
        );
        setResultPage(true);
        setStep(2);
        return;
      }
      toast.error(
        <div>
          Please create an app{' '}
          <span
            className="underline font-bold	"
            onClick={() => navigate('/developers/configuration')}
          >
            {' '}
            HERE{' '}
          </span>{' '}
          to view details
        </div>,
      );
    },
    [appIds],
  );

  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={amlScreeningIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">AML Screening</p>
        </div>
      }
    >
      {tourState?.aml_screening_tour ? (
        <AMLScreeningTour
          updateTourState={updateTourState}
          DATA={DATA}
          loading={loading}
        />
      ) : null}
      {/*<OngoingMonitoringConfig setOpen={setOnGoing} open={onGoing} />*/}
      <ToggleOngoingMonitoring
        loading={configLoading}
        setOpen={setOpenConfirm}
        open={openConfirm}
      />
      <PerformLookupModal
        open={open}
        setOpen={setOpen}
        step={step}
        setStep={setStep}
        dummyData={dummyData}
      />

      <ChooseAmlType
        chooseOpen={chooseOpen}
        setChooseOpen={setChooseOpen}
        setBusinessOpen={() => {
          setBusinessOpen(true);
          setChooseOpen(false);
          setStep(1);
        }}
        setIndividualOpen={() => {
          setOpen(true);
          setStep(1);
          setChooseOpen(false);
        }}
      />

      <BusinessLookupModal
        setBusinessOpen={setBusinessOpen}
        businessOpen={businessOpen}
        setStep={setStep}
        setOpen={setOpen}
        step={step}
        dummyData={dummyData}
      />

      {loading || resultPage ? (
        <Loader />
      ) : !loading && DATA?.length === 0 ? (
        <EmptyState
          noBtn
          className="w-full"
          src={lookupEmpty}
          body="Perform multiple identity lookup with ease."
          maxWidth="max-w-[500px]"
          customBtn={
            <div className="flex gap-6 mt-2 w-full justify-center">
              <PrimaryButton
                buttonText="Perform AML Lookup"
                onClick={() => {
                  setChooseOpen(true);
                  setStep(1);
                }}
              />
              {/*{canConfigureOngoingMonitoring && (*/}
              {/*  <SecondaryButton*/}
              {/*    buttonText="Ongoing monitoring settings"*/}
              {/*    onClick={() => setOnGoing(!onGoing)}*/}
              {/*    className="w-full outline-grey5 text-grey80"*/}
              {/*  />*/}
              {/*)}*/}
            </div>
          }
        />
      ) : (
        <>
          <div className="flex pt-5 flex-col items-start justify-between gap-6 mt-4 mb-6 md:flex-row md:items-center md:gap-0 sm:mt-0">
            <h2 className="text-base  relative flex items-center font-medium text-grey sm:text-lg">
              AML Screening
            </h2>
            {/*<h2*/}
            {/*  onMouseEnter={() => setTooltip(true)}*/}
            {/*  onMouseLeave={() => setTooltip(false)}*/}
            {/*  className="text-base  relative flex items-center font-medium text-grey sm:text-lg"*/}
            {/*>*/}
            {/*  AML Screening*/}
            {/*  {canConfigureOngoingMonitoring && (*/}
            {/*    <>*/}
            {/*      {' '}*/}
            {/*      <div*/}
            {/*        className="  ml-4 h-8 w-10 bg-brandBlue4 flex justify-center items-center rounded-full cursor-pointer"*/}
            {/*        onClick={() => setOnGoing(!onGoing)}*/}
            {/*      >*/}
            {/*        <img className="m-4" src={settingsIcon} alt="" />*/}
            {/*      </div>*/}
            {/*      {toolTip && (*/}
            {/*        <span*/}
            {/*          className={`absolute -right-[164px]   w-fit bg-brandBlue4 text-brandBlue50 p-2 rounded-full  text-xs `}*/}
            {/*        >*/}
            {/*          <span className="z-30 relative text-center">*/}
            {/*            Ongoing monitoring settings*/}
            {/*          </span>*/}
            {/*          <div className="h-4 w-4 bg-brandBlue4 z-0 -left-[3px]  absolute top-[6px] rotate-45 "></div>*/}
            {/*        </span>*/}
            {/*      )}*/}
            {/*    </>*/}
            {/*  )}*/}
            {/*</h2>*/}
            <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
              <PrimaryButton
                buttonText="Perform AML Lookup"
                xPadding="px-4"
                className="hidden sm:flex"
                onClick={() => {
                  setChooseOpen(true);
                  setStep(1);
                }}
                disabled={!canPerformAndViewAML}
                title={
                  !canPerformAndViewAML
                    ? 'You do not have permission to do this'
                    : ''
                }
              />
              <div className="fixed bottom-0 left-0 right-0 z-10 px-4 py-2 overflow-x-hidden sm:hidden bg-white80">
                <PrimaryButton
                  buttonText="Perform AML Lookup"
                  xPadding="p-4"
                  className="w-full bottom-2"
                  onClick={() => {
                    setChooseOpen(true);
                    setStep(1);
                  }}
                />
              </div>
            </div>
          </div>

          <TableLayout negativeMargins>
            <thead className="text-xs font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                  name
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  AML Status
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  Type
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  Date created
                </th>{' '}
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  Entity type
                </th>
                {/*<th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">*/}
                {/*  ongoing monitoring*/}
                {/*</th>*/}
                <th className="w-[18.69%]" />
              </tr>
            </thead>
            <tbody className="text-sm">
              {!loading &&
                DATA?.map((history, i = 0) => (
                  <tr
                    className={`border-b border-grey60 ${
                      history?.aml_entity_lookup_id &&
                      history?.status === 'available'
                        ? 'cursor-pointer'
                        : history?.status === 'not_availbale'
                        ? 'cursor-pointer'
                        : 'cursor-default'
                    }`}
                    key={i}
                  >
                    <td
                      className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body"
                      onClick={() => handleViewAMLDetails(history)}
                    >
                      {`${
                        history?.first_name || history?.business_name || '-'
                      } ${history?.middle_name || ''} ${
                        history?.last_name || ''
                      }`}
                    </td>
                    <td
                      className="p-4 pl-0"
                      onClick={() => handleViewAMLDetails(history)}
                    >
                      <div
                        className={`flex items-center gap-2 uppercase py-1 px-2 text-xs ${
                          history?.status === 'failed'
                            ? 'text-danger  bg-statusBg-500'
                            : history?.status === 'available'
                            ? 'text-success  bg-statusBg-200'
                            : 'text-brandBlue50  bg-brandBlue40'
                        } font-medium  rounded-full w-fit`}
                      >
                        <img
                          src={
                            history?.status === 'failed'
                              ? infoRed
                              : history?.status === 'available'
                              ? infoGreen
                              : infoBlue
                          }
                          alt=""
                          width={12}
                          height={12}
                        />
                        <span className="mt-1">
                          {getStatusInfo(history?.status)}{' '}
                        </span>
                      </div>
                    </td>
                    <td
                      className="min-w-[100px]"
                      onClick={() => handleViewAMLDetails(history)}
                    >
                      <p
                        className={`p-2   text-xs font-medium  w-fit uppercase rounded-full ${
                          history?.call_type?.toLowerCase() === 'no-code'
                            ? 'text-success  bg-statusBg-200'
                            : history?.call_type?.toLowerCase() === 'api'
                            ? 'text-brandBlue50  bg-brandBlue40'
                            : 'text-success  bg-statusBg-200'
                        }`}
                      >
                        {history?.call_type || 'no-code'}
                      </p>
                    </td>

                    <td
                      className="p-4 pl-0 text-tiny text-body"
                      onClick={() => handleViewAMLDetails(history)}
                    >
                      {moment(history?.date_created).format(
                        'Do, MMM YYYY, hh:mm a',
                      )}
                    </td>

                    <td
                      className="min-w-[100px]"
                      onClick={() => handleViewAMLDetails(history)}
                    >
                      <p
                        className={`p-2   text-xs font-medium  w-fit uppercase rounded-full ${
                          history?.entity_type?.toLowerCase() === 'business'
                            ? 'text-brandBlue50  bg-brandBlue40'
                            : 'text-danger  bg-statusBg-500'
                        }`}
                      >
                        {history?.entity_type || 'Individual'}
                      </p>
                    </td>

                    {/*<td className="p-4 pl-0 text-tiny text-body">*/}
                    {/*  <AMLToggle*/}
                    {/*    isChecked={history?.ongoing_monitoring}*/}
                    {/*    onCheck={() => {*/}
                    {/*      localStorage.setItem(*/}
                    {/*        'amlData',*/}
                    {/*        JSON.stringify({*/}
                    {/*          watchlist_id: history?.watchlist_id,*/}
                    {/*          company_id: history?.company_id,*/}
                    {/*          ongoing_monitoring: history?.ongoing_monitoring,*/}
                    {/*          watchlist_history_id: history?.id,*/}
                    {/*          date_created: history?.date_created,*/}
                    {/*        }),*/}
                    {/*      );*/}
                    {/*      setOpenConfirm(true);*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*</td>*/}
                    <td
                      className="p-4 pl-0"
                      onClick={() => handleViewAMLDetails(history)}
                    >
                      <button
                        className={
                          history?.aml_entity_lookup_id &&
                          history?.status === 'not_available'
                            ? 'cursor-pointer'
                            : history?.status === 'not_availbale'
                            ? 'cursor-pointer'
                            : 'cursor-default'
                        }
                        title={
                          !canPerformAndViewAML
                            ? 'You do not have permission to view'
                            : 'View'
                        }
                        onClick={() => {
                          if (!canPerformAndViewAML) return;
                          if (
                            history?.status === 'not_available' ||
                            history?.status === 'not_availbale'
                          ) {
                            setOpen(true);
                            setStep(2);
                            setDummyData(
                              `${history?.first_name || '-'} ${
                                history?.last_name || '-'
                              }`,
                            );
                            return;
                          }
                          if (!history?.aml_entity_lookup_id) return;

                          handleViewAMLDetails(history);
                          // getAMLHistory({id: history?.aml_user_lookup});
                          // localStorage.setItem('aml_app_id', history?.app_id);
                          setResultPage(true);
                        }}
                        disabled={!canPerformAndViewAML}
                      >
                        <img src={eye} alt="" width={16} height={16} />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableLayout>

          {!loading && amlHistories?.totalRecords > 0 && (
            <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
              <div className="flex items-center text-body text-tiny">
                <PageLimit
                  limit={limit}
                  onLimitChange={setLimit}
                  total={amlHistories && amlHistories?.totalRecords}
                  length={amlHistories && amlHistories?.records?.length}
                />
              </div>

              <div className="mb-8 sm:mb-0">
                <Pagination
                  total={
                    amlHistories &&
                    Math.ceil(amlHistories?.totalRecords / limit)
                  }
                  current={+currentPage}
                  onPageChange={activePage => {
                    localStorage.setItem('page', String(activePage));
                    pageClick(activePage);
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(AMLScreening);
