import React, {createContext, useEffect, useState} from 'react';
import {useQuery, useMutation} from 'react-query';
import {api} from '../index';

const TourContext = createContext();

function TourProvider(props) {
  const [tourState, setTourState] = useState({});

  const {data: user} = useQuery(
    'user',
    () => api.get('/users/me/profile').then(res => res.data),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (user?.user) {
      const {
        aml_screening_tour,
        developers_tour,
        easy_lookup_tour,
        easy_onboard_tour,
        easy_onboard_tour_2,
        verifications_tour,
        team_tour,
      } = user.user;
      setTourState({
        aml_screening_tour,
        developers_tour,
        easy_lookup_tour,
        easy_onboard_tour,
        easy_onboard_tour_2,
        verifications_tour,
        team_tour,
      });
    }
  }, [user]);

  const updateTourStateMutation = useMutation(
    updatedState => api.put('/users/user', updatedState).then(res => res.data),
    {
      onSuccess: data => {
        if (user) {
          const {
            aml_screening_tour,
            developers_tour,
            easy_lookup_tour,
            easy_onboard_tour,
            easy_onboard_tour_2,
            verifications_tour,
            team_tour,
          } = data;
          setTourState({
            aml_screening_tour,
            developers_tour,
            easy_lookup_tour,
            easy_onboard_tour,
            easy_onboard_tour_2,
            verifications_tour,
            team_tour,
          });
        }
      },
    },
  );

  const updateTourState = updatedState => {
    updateTourStateMutation.mutate(updatedState);
  };

  return (
    <TourContext.Provider value={{tourState, updateTourState}}>
      {props.children}
    </TourContext.Provider>
  );
}

export {TourProvider, TourContext};
