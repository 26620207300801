import {
  TEAM_LOADING,
  GET_TEAM_MEMBERS,
  GET_TEAM_ROLES,
  EDIT_TEAM_ROLE_LOADING,
  DELETE_TEAM_ROLE_LOADING,
  TRANSFER_OWNERSHIP_LOADING,
} from '../types';

const initialState = {
  loading: false,
  members: null,
  roles: null,
  editTeamRoleLoading: false,
  deleteTeamRoleLoading: false,
  transferOwnershipLoading: false,
};

export default function team(state = initialState, action) {
  switch (action.type) {
    case TEAM_LOADING:
      return {...state, loading: action.payload};
    case EDIT_TEAM_ROLE_LOADING:
      return {...state, editTeamRoleLoading: action.payload};
    case DELETE_TEAM_ROLE_LOADING:
      return {...state, deleteTeamRoleLoading: action.payload};
    case GET_TEAM_MEMBERS:
      return {...state, members: action.payload};
    case GET_TEAM_ROLES:
      return {...state, roles: action.payload?.roles};
    case TRANSFER_OWNERSHIP_LOADING:
      return {...state, transferOwnershipLoading: action.payload};
    default:
      return state;
  }
}
