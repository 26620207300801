import React from 'react';
import Select from 'react-select';
import {monitorBlue, monitorRed} from '../../../assets/images/images';
import {customStyles, DropdownIndicator} from '../../../components';
import {useFraudCheck, usePersistFraudData} from '../../../hooks/useFraudCheck';
import {Card, Wrapper} from '../FraudCheckScreens';

export const DetectWithIpDeviceScreening = () => {
  const {setSelectedStatus, selectedStatus, setConfig, config, STATUSES} =
    useFraudCheck({
      action_blacklisted: '',
      action_not_blacklisted: '',
    });

  const actionBlacklisted = usePersistFraudData({
    config,
    selectedStatus,
    actionKey: 'action_blacklisted',
    COLLECTION: STATUSES,
  });

  return (
    <Wrapper>
      <Card
        extension={
          <div className="flex flex-col gap-1">
            <p className="text-grey font-medium">IP/Device Screening</p>
            <p className="text-body text-sm gap-1">
              Detecting if IP/Device is blacklisted or not.
            </p>
          </div>
        }
        src={monitorBlue}
      />
      <Card
        extension={
          <p className="text-grey font-medium">
            If IP is listed in the blacklisted IP Database
          </p>
        }
        src={monitorRed}
      >
        <div className="text-start w-full">
          <label htmlFor="status" className="mb-2 relative">
            Set status to
          </label>
          {actionBlacklisted !== undefined && (
            <Select
              defaultValue={actionBlacklisted}
              value={actionBlacklisted || STATUSES[0]}
              onChange={status => {
                setSelectedStatus(status);
                setConfig({...config, action_blacklisted: status.value});
              }}
              options={STATUSES}
              className="basic-multi-select"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              menuPlacement="auto"
              isSearchable={false}
            />
          )}
        </div>
      </Card>
    </Wrapper>
  );
};
