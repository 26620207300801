export const OPERATORS = [
  {value: 'greater_than', label: '>= Greater than'},
  {value: 'greater', label: '> Greater'},
  {value: 'lesser', label: '< Lesser'},
  {value: 'less_than', label: '<= Less than'},
  {value: 'equals_to', label: '= Equals to'},
  {value: 'not_equals_to', label: '≠ Not equals to'},
  {value: 'in', label: 'In'},
  {value: 'like', label: 'Like'},
  {value: 'not_in', label: 'Not in'},
  {value: 'not_like', label: 'Not like'},
  {value: 'is_null', label: 'Is null'},
  {value: 'is_not_null', label: 'Is not null'},
];

export const CONDITION_TYPE = [
  {value: 'AND', label: 'AND'},
  {value: 'OR', label: 'OR'},
];

export const COUNTS = [];

for (let i = 0; i <= 31; i++) {
  const label = i > 9 ? i.toString() : `0${i.toString()}`;
  COUNTS.push({
    value: i,
    label,
  });
}
export const PERIOD = [
  {value: 'hours', label: 'Hours'},
  {value: 'days', label: 'Days'},
  {value: 'weeks', label: 'Weeks'},
  {value: 'months', label: 'Months'},
];
export const IS_TRUE_OR_FALSE = [
  {value: true, label: 'True'},
  {value: false, label: 'False'},
];

// BANK TRANSACTION

export const BANK_TRANSACTION_PARENT = [
  {value: 'BANK_TRANSACTION_TRANSACTION', label: 'Transaction'},
  {value: 'BANK_TRANSACTION_USER', label: 'User'},
  {value: 'BANK_TRANSACTION_RECEIVER', label: 'Receiver'},
  {value: 'BANK_TRANSACTION_SENDER', label: 'Sender'},
  {value: 'BANK_TRANSACTION_DEVICE', label: 'Device'},
  {value: 'BANK_TRANSACTION_ADDRESS', label: 'Address'},
  {value: 'BANK_TRANSACTION_SCREENING', label: 'Screening'},
];

const BANK_TRANSACTION_TRANSACTION = [
  {value: 'amount', label: 'Amount'},
  {value: 'currency', label: 'Currency'},
  {value: 'type', label: 'Type'},
  {value: 'channel', label: 'Channel'},
  {value: 'reference', label: 'Reference'},
  {value: 'total_count_volume', label: 'Total Count/Volume'},
  {value: 'transaction_amount_value', label: 'Total Amount/Value'},
];
const BANK_TRANSACTION_USER = [
  {value: 'email', label: 'Email'},
  {value: 'name', label: 'Name'},
  {value: 'mobile', label: 'Mobile'},
  {value: 'first_name', label: 'First Name'},
  {value: 'last_name', label: 'Last Name'},
  {value: 'kyc_level', label: 'KYC Level'},
  {value: 'risk_level', label: 'Risk Level'},
];

const BANK_TRANSACTION_RECEIVER = [
  {value: 'receiver_account_name', label: 'Account Name'},
  {value: 'receiver_bank_name', label: 'Bank Name'},
  {value: 'receiver_account_number', label: 'Account Number'},
  {value: 'receiver_country', label: 'Country'},
];

const BANK_TRANSACTION_SENDER = [
  {value: 'sender_account_name', label: 'Account Name'},
  {value: 'sender_bank_name', label: 'Bank Name'},
  {value: 'sender_account_number', label: 'Account Number'},
  {value: 'sender_country', label: 'Country'},
];

const BANK_TRANSACTION_DEVICE = [
  {value: 'mobile', label: 'Mobile'},
  {value: 'os', label: 'Operating System'},
  {value: 'language', label: 'Language'},
  {value: 'ip_address', label: 'Ip Address'},
  {value: 'device_id', label: 'Device Id'},
];

const BANK_TRANSACTION_ADDRESS = [
  {value: 'city', label: 'City'},
  {value: 'street', label: 'Street'},
  {value: 'country', label: 'Country'},
];
const BANK_TRANSACTION_SCREENING = [
  {value: 'is_pep', label: 'Pep'},
  {value: 'is_sanction_list', label: 'Sanction List'},
];

export const BANK_GROUP = {
  BANK_TRANSACTION_SENDER,
  BANK_TRANSACTION_RECEIVER,
  BANK_TRANSACTION_DEVICE,
  BANK_TRANSACTION_ADDRESS,
  BANK_TRANSACTION_USER,
  BANK_TRANSACTION_TRANSACTION,
  BANK_TRANSACTION_SCREENING,
};

// CARD TRANSACTIONS

export const CARD_TRANSACTION_PARENT = [
  {value: 'CARD_TRANSACTION_TRANSACTION', label: 'Transaction'},
  {value: 'CARD_TRANSACTION_USER', label: 'User'},
  {value: 'CARD_TRANSACTION_DETAILS', label: 'Card Details'},
  {value: 'CARD_TRANSACTION_DEVICE', label: 'Device'},
  {value: 'CARD_TRANSACTION_ADDRESS', label: 'Address'},
  {value: 'CARD_TRANSACTION_SCREENING', label: 'Screening'},
];
const CARD_TRANSACTION_TRANSACTION = [
  {value: 'amount', label: 'Amount'},
  {value: 'currency', label: 'Currency'},
  {value: 'type', label: 'Type'},
  {value: 'gateway', label: 'Gateway'},
  {value: 'reference', label: 'Reference'},
  {value: 'total_count_volume', label: 'Total Count/Volume'},
  {value: 'transaction_amount_value', label: 'Total Amount/Value'},
];

const CARD_TRANSACTION_USER = [
  {value: 'email', label: 'Email'},
  {value: 'name', label: 'Name'},
  {value: 'mobile', label: 'Mobile'},
  {value: 'first_name', label: 'First Name'},
  {value: 'last_name', label: 'Last Name'},
  {value: 'kyc_level', label: 'KYC Level'},
  {value: 'risk_level', label: 'Risk Level'},
];

const CARD_TRANSACTION_DETAILS = [
  {value: 'card_fingerprint', label: 'Card Fingerprint'},
  {value: 'scheme', label: 'Scheme'},
  {value: 'issuing_country', label: 'Issuing Country'},
  {value: 'three_d_check', label: 'Three D Check'},
  {value: 'name_on_card', label: 'Name on Card'},
];

const CARD_TRANSACTION_DEVICE = [
  {value: 'type', label: 'Type'},
  {value: 'os', label: 'Operating System'},
  {value: 'model', label: 'Model'},
  {value: 'language', label: 'Language'},
  {value: 'ip_address', label: 'Ip Address'},
  {value: 'device_id', label: 'Device Id'},
];

const CARD_TRANSACTION_ADDRESS = [
  {value: 'city', label: 'City'},
  {value: 'street', label: 'Street'},
  {value: 'country', label: 'Country'},
];
const CARD_TRANSACTION_SCREENING = [
  {value: 'is_pep', label: 'Pep'},
  {value: 'is_sanction_list', label: 'Sanction List'},
];

export const CARD_GROUP = {
  CARD_TRANSACTION_ADDRESS,
  CARD_TRANSACTION_DEVICE,
  CARD_TRANSACTION_DETAILS,
  CARD_TRANSACTION_USER,
  CARD_TRANSACTION_TRANSACTION,
  CARD_TRANSACTION_SCREENING,
};
