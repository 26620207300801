/* eslint-disable no-unused-vars */

import React from 'react';
import {dummy_id} from '../../../assets/images/images';

export const BusinessID = () => (
  <>
    <div className="mt-8 overflow-y-auto">
      <p className="text-center font-medium text-grey">Capture the document</p>
      <div className="mx-auto mt-8 mb-6 w-fit rounded-full bg-batchBlue20 text-center">
        <span className="px-2 py-1 text-xs font-medium text-batchBlue">
          This looks good
        </span>
      </div>
      <div className="max-w-sm flex flex-col justify-center items-center">
        <img
          src={dummy_id}
          alt=""
          width={326}
          height={224}
          className="rounded-lg border-2 border-grey"
        />
        <p className="mt-10 text-center text-body text-sm">
          Make sure your document is properly placed, and hold it still for a
          few seconds
        </p>
      </div>
    </div>

    {/* <div className="flex flex-col justify-center items-center w-full max-w-sm mt-3 gap-4">
      <button
        style={{
          backgroundColor: color || 'red',
        }}
        className="text-small text-white font-medium h-12 bg-brandBlue rounded w-full"
        disabled
      >
        Capture
      </button>
      <button
        className="text-small text-grey font-medium h-12 bg-white80 rounded w-full border border-grey80"
        disabled
      >
        Upload instead
      </button>
    </div> */}
  </>
);
