import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {fetchDocuments} from '../../../requests/queries/customer360';
import {IGNORE_KEYS} from '../LayoutConstants';
import Layout from '../Layout';
import {
  Accordion,
  EmptyState,
  Loader,
  PrimaryButton,
} from '../../../components';
import {
  corruptedFile,
  downloadGrey,
  enlargeIc,
  nigeriaIc,
  placeholder,
  southAfricaIc,
} from '../../../assets/images/images';
import {useParams} from 'react-router-dom';
import SingleAnalysisModal from '../../DocumentAnalysis/SingleAnalysisModal';

const ImageComponent = ({imageUrl}) => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const toggleImageSize = () => {
    setIsEnlarged(prevState => !prevState);
  };

  return (
    <div className="relative w-full">
      <img
        src={imageUrl}
        alt=""
        className={`mx-auto w-full ${
          isEnlarged ? 'h-full' : 'max-h-[192px]'
        } object-cover rounded-lg`}
      />
      {imageUrl && (
        <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex items-center justify-center gap-2">
          <div className="flex gap-2">
            <button
              className="flex items-start gap-2 p-2 text-xs font-medium rounded text-grey bg-white80"
              onClick={toggleImageSize}
            >
              <span className="-mb-[2px]">
                {isEnlarged ? 'Shrink image' : 'Enlarge image'}
              </span>
              <img src={enlargeIc} alt="" className="-mt-[2px]" />
            </button>
          </div>
          <div className="flex gap-2">
            <a
              href={imageUrl}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-start gap-2 p-2 text-xs font-medium rounded text-grey bg-white80"
            >
              <span className="-mb-[2px]">Download image</span>
              <img src={downloadGrey} alt="" className="-mt-[2px]" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default function GovernmentID() {
  const {slug} = useParams();

  const {data: documentsData, isLoading: documentsDataLoading} = useQuery(
    ['govt-documents', slug],
    () => fetchDocuments(slug),
    {
      enabled: !!slug,
    },
  );

  const getCountryLogo = country =>
    ({
      NGA: nigeriaIc,
      'south-africa': southAfricaIc,
    }[country || 'NGA']);

  const [openDocumentAnalysisModal, setOpenDAM] = useState(false);

  return (
    <Layout
      pageTitle="Government ID"
      action={
        <PrimaryButton
          buttonText="Validate document"
          onClick={() => setOpenDAM(true)}
        />
      }
    >
      <SingleAnalysisModal
        open={openDocumentAnalysisModal}
        setOpen={setOpenDAM}
        customer_id={slug}
      />
      {documentsDataLoading ? (
        <Loader />
      ) : !documentsDataLoading && documentsData?.data?.length === 0 ? (
        <div className="bg-white80 w-full">
          <EmptyState
            body="There is no information to display here at the moment"
            src={corruptedFile}
            height={45}
            imageHeight={48}
            imageWidth={48}
            bodyMaxWidth="max-w-[179px]"
            buttonText="Validate document"
            onClick={() => {
              setOpenDAM(true);
            }}
          />
        </div>
      ) : (
        <div className="space-y-4">
          {!documentsDataLoading &&
            documentsData &&
            documentsData?.data.map(
              (data, index) =>
                data && (
                  <Accordion
                    key={index}
                    title={data?.document_type || '-'}
                    border
                    customClasses="rounded-xl"
                    isOpen={index >= 1}
                    customComponent={
                      <div className="flex items-center gap-2 rounded-full px-2 py-1 outline outline-1 outline-grey-50 bg-white80">
                        <p className="text-deepBlue text-xs font-medium uppercase -mb-1">
                          {data.nationality || '-'}
                        </p>
                        <div className="w-5 h-5 flex items-center justify-center bg-blue-50 outline outline-1 outline-blue-100 rounded-full">
                          <img
                            src={getCountryLogo(data?.country_code)}
                            alt=""
                            width={12}
                            height={8}
                          />
                        </div>
                      </div>
                    }
                    noChildPadding
                    customTitleSize="text-lg"
                    customTitleColor="text-grey"
                  >
                    <div className="pl-8 pr-10 pt-6 pb-6">
                      <div className="ml-auto w-fit flex items-center gap-2 rounded-full px-2 py-1 bg-grey60 text-grey text-xs font-medium uppercase">
                        <span className="-mb-[2px]">95% image match</span>
                      </div>
                      <div className="mt-6 flex items-center gap-14">
                        <ImageComponent
                          imageUrl={data?.id_url || placeholder}
                        />
                      </div>

                      <div className="mt-16 w-full inline-grid grid-cols-[auto_auto_auto_auto] items-center gap-10 justify-between">
                        {Object.entries(data || {}).map(
                          ([key, value], index) =>
                            !IGNORE_KEYS.includes(key) &&
                            value && (
                              <div key={index} className="flex flex-col gap-2">
                                <p className="text-grey-700 text-xs font-medium uppercase">
                                  {key.replaceAll('_', ' ')}
                                </p>
                                <p className="text-grey text-sm font-medium">
                                  {value}
                                </p>
                              </div>
                            ),
                        )}
                      </div>
                    </div>
                  </Accordion>
                ),
            )}
        </div>
      )}
    </Layout>
  );
}
