import React from 'react';
import {NavLink} from 'react-router-dom';

export default function VerificationLayout({children}) {
  return (
    <>
      <div className="mt-6 md:mt-[27px] border-b border-grey60 mb-4">
        <div className="flex items-center gap-4 overflow-x-auto settings-nav whitespace-nowrap">
          <NavLink
            end
            to={'/verifications'}
            className={({isActive}) =>
              ` font-medium text-sm py-2 ${
                isActive
                  ? 'text-brandBlue border-b-2 border-brandBlue'
                  : 'text-grey30'
              }`
            }
          >
            All Verifications
          </NavLink>
          <NavLink
            to={`/verifications/pending`}
            className={({isActive}) =>
              ` font-medium text-sm py-2 ${
                isActive
                  ? 'text-brandBlue border-b-2 border-brandBlue'
                  : 'text-grey30'
              }`
            }
          >
            My Pending Verifications
          </NavLink>
        </div>
      </div>
      {children}
    </>
  );
}
