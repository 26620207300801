import {useState} from 'react';

function useDebounce(callback, delay) {
  const [timeoutId, setTimeoutId] = useState(null);

  return function debouncedFunction(...args) {
    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
    setTimeoutId(newTimeoutId);
  };
}

export {useDebounce};
