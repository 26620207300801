export const defaultFlowProcess = {
  process: [
    {
      type: 'INPUT',
      name: 'Starting trigger',
      description: 'User begins process ',
    },
    [],
    {
      type: 'ACTION',
      name: 'Review',
      description: 'User output',
    },
  ],
};
