export const debounceFunction = (func, delay) => {
  let timer;

  return function () {
    const self = this;
    const args = arguments;

    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(self, args);
    }, delay);
  };
};

// function debounceFunction(func, wait, immediate) {
//   let timeout;
//
//   return function executedFunction() {
//     const context = this;
//     const args = arguments;
//
//     const later = function () {
//       timeout = null;
//       if (!immediate) func.apply(context, args);
//     };
//
//     const callNow = immediate && !timeout;
//
//     clearTimeout(timeout);
//
//     timeout = setTimeout(later, wait);
//
//     if (callNow) func.apply(context, args);
//   };
// }
//
// export {debounceFunction};
