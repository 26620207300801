import {
  startOfDay,
  endOfDay,
  subDays,
  subMonths,
  startOfMonth,
  endOfMonth,
  format,
} from 'date-fns';
const formatDate = date => format(date, 'yyyy-MM-dd HH:mm:ss');

const getDateRange = days => ({
  start: formatDate(startOfDay(subDays(new Date(), days))),
  end: formatDate(endOfDay(new Date())),
});

const dateRanges = {
  today: () => ({
    start: formatDate(startOfDay(new Date())),
    end: formatDate(endOfDay(new Date())),
  }),
  yesterday: () => ({
    start: formatDate(startOfDay(subDays(new Date(), 1))),
    end: formatDate(endOfDay(subDays(new Date(), 1))),
  }),
  seven_days: () => getDateRange(6),
  fifteen_days: () => getDateRange(14),
  last_month: () => ({
    start: formatDate(startOfMonth(subMonths(new Date(), 1))),
    end: formatDate(endOfMonth(subMonths(new Date(), 1))),
  }),
  last_three_month: () => ({
    start: formatDate(startOfMonth(subMonths(new Date(), 3))),
    end: formatDate(endOfMonth(subMonths(new Date(), 1))),
  }),
  last_twelve_month: () => ({
    start: formatDate(startOfMonth(subMonths(new Date(), 12))),
    end: formatDate(endOfMonth(subMonths(new Date(), 1))),
  }),
};

export {getDateRange, dateRanges};
