import {toast} from 'react-toastify';
import reduxApi from '../../helpers/reduxApi';
import {getUserProfile} from './auth';
import {
  TEAM_LOADING,
  GET_TEAM_MEMBERS,
  GET_TEAM_ROLES,
  DO_NOTHING,
  EDIT_TEAM_ROLE_LOADING,
  DELETE_TEAM_ROLE_LOADING,
  TRANSFER_OWNERSHIP_LOADING,
} from '../types';

export const getTeamMembers = query =>
  reduxApi(
    `/users/company/team${query ? `?${query}` : ''}`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: TEAM_LOADING, responder: GET_TEAM_MEMBERS},
  );

export const inviteTeamMember = (data, onSuccess) =>
  reduxApi(
    `/users/company/invite`,
    'POST',
    data,
    {
      success: (data, dispatch) => {
        dispatch(getTeamMembers());
        toast.success(data.message);
        onSuccess();
      },
    },
    {error: 'ERROR', loading: TEAM_LOADING, responder: DO_NOTHING},
  );

export const editTeamMemberRole = (data, onSuccess) =>
  reduxApi(
    `/users/company/team`,
    'PUT',
    data,
    {
      success: (data, dispatch) => {
        dispatch(getTeamMembers());
        dispatch(getUserProfile());

        toast.success(data.message);
        onSuccess();
      },
    },
    {error: 'ERROR', loading: TEAM_LOADING, responder: DO_NOTHING},
  );

export const removeTeamMember = (id, onSuccess) =>
  reduxApi(
    `/users/company/team/${id}`,
    'DELETE',
    null,
    {
      success: (data, dispatch) => {
        dispatch(getTeamMembers());
        toast.success(data.message);
        onSuccess();
      },
    },
    {error: 'ERROR', loading: DO_NOTHING, responder: DO_NOTHING},
  );

export const assignApp = data =>
  reduxApi(
    `/apps/assign-user`,
    'PUT',
    {...data},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch(getTeamMembers());
      },
    },
    {error: 'ERROR', loading: TEAM_LOADING, responder: DO_NOTHING},
  );

export const getTeamRoles = onSuccess =>
  reduxApi(
    `/users/company/permissions`,
    'GET',
    null,
    {
      success: onSuccess
        ? ({roles}) => {
            onSuccess(roles);
          }
        : () => {},
    },
    {error: 'ERROR', loading: TEAM_LOADING, responder: GET_TEAM_ROLES},
  );

export const addTeamRole = (data, onSuccess) =>
  reduxApi(
    `/users/company/permissions`,
    'POST',
    data,
    {
      success: (data, dispatch) => {
        dispatch(getTeamRoles(onSuccess));
        toast.success('New role created');
      },
    },
    {error: 'ERROR', loading: TEAM_LOADING, responder: DO_NOTHING},
  );

export const editTeamRole = (id, data, onSuccess) =>
  reduxApi(
    `/users/company/permissions/${id}`,
    'PUT',
    data,
    {
      success: (data, dispatch) => {
        dispatch(getTeamRoles(onSuccess));
        dispatch(getUserProfile());
        toast.success('Role has been updated');
      },
    },
    {error: 'ERROR', loading: EDIT_TEAM_ROLE_LOADING, responder: DO_NOTHING},
  );

export const deleteTeamRole = (id, onSuccess) =>
  reduxApi(
    `/users/company/permissions/${id}`,
    'DELETE',
    null,
    {
      success: (data, dispatch) => {
        dispatch(getTeamRoles(onSuccess));
        dispatch(getUserProfile());

        toast.success(data.message);
      },
    },
    {error: 'ERROR', loading: DELETE_TEAM_ROLE_LOADING, responder: DO_NOTHING},
  );

export const transferOwnership = (data, onSuccess) =>
  reduxApi(
    '/users/company/abdicate',
    'PUT',
    data,
    {
      success: (data, dispatch) => {
        dispatch(getTeamRoles(onSuccess));
        dispatch(getUserProfile());
        toast.success('Ownership transferred successfully');
      },
    },
    {
      error: 'ERROR',
      loading: TRANSFER_OWNERSHIP_LOADING,
      responder: DO_NOTHING,
    },
  );
