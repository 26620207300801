import {
  GET_CASE_LISTS,
  GET_CASE_LIST,
  ADD_CASE_LIST,
  UPDATE_CASE_LIST,
  DELETE_CASE_LIST,
  LISTS_LOADING,
  MUTATE_LIST_LOADING,
} from '../types';
import reduxApi from '../../helpers/reduxApi';

export const getLists = ({page, limit}) =>
  reduxApi(
    `/users/fraud-detection/cases/lists?page=${page}&limit=${limit}`,
    'GET',
    null,
    {},
    {
      error: 'ERROR',
      loading: LISTS_LOADING,
      responder: GET_CASE_LISTS,
    },
  );
export const getList = listId =>
  reduxApi(
    `/users/fraud-detection/cases/lists/${listId}`,
    'GET',
    null,
    {},
    {
      error: 'ERROR',
      loading: LISTS_LOADING,
      responder: GET_CASE_LIST,
    },
  );
export const addList = data =>
  reduxApi(
    `/users/fraud-detection/cases/lists`,
    'POST',
    {...data},
    {},
    {
      error: 'ERROR',
      loading: MUTATE_LIST_LOADING,
      responder: ADD_CASE_LIST,
    },
  );
export const updateList = ({listId, ...data}) =>
  reduxApi(
    `/users/fraud-detection/cases/lists/${listId}`,
    'PUT',
    {...data},
    {},
    {
      error: 'ERROR',
      loading: MUTATE_LIST_LOADING,
      responder: UPDATE_CASE_LIST,
    },
  );
export const deleteList = listId =>
  reduxApi(
    `/users/fraud-detection/cases/lists/${listId}`,
    'DELETE',
    null,
    {
      updated: true,
    },
    {
      error: 'ERROR',
      loading: MUTATE_LIST_LOADING,
      responder: DELETE_CASE_LIST,
    },
  );
