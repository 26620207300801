import React, {useContext, useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import Layout from './Layout';
import platform from 'platform';

import {RowData} from '../../components';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import {capitalizeEveryWord} from '../../helpers';
import {PageContext} from '../../helpers/context';

function IpDeviceCheck({getIpDeviceCheck, verifications: {deviceIp}}) {
  const {slug} = useParams();
  useEffect(() => {
    const verificationId = localStorage.getItem('verificationId');

    getIpDeviceCheck(slug || verificationId);
  }, [getIpDeviceCheck, slug]);

  const device = platform.parse(deviceIp?.device);
  const ipData = deviceIp?.data;

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('verifications');
  }, [setPageName]);

  const IP_DATA = useMemo(
    () => [
      {leftText: 'OS', rightText: device?.os?.family},
      {leftText: 'model', rightText: `x${device?.os?.architecture}`},
      {leftText: 'browser', rightText: device?.description.split(' ')[0]},
      {leftText: 'Browser version', rightText: device?.version},
      {leftText: 'platform', rightText: ipData?.ip?.mobile ? 'Mobile' : 'Web'},
    ],
    [device, ipData],
  );
  const IP_DATA_2 = useMemo(
    () => [
      {leftText: 'Ip Address', rightText: ipData?.ip?.query},
      {leftText: 'Country', rightText: ipData?.ip?.country},
      {leftText: 'Region Name', rightText: ipData?.ip?.regionName},
      {leftText: 'City', rightText: ipData?.ip?.city},
      {leftText: 'LAT', rightText: ipData?.ip?.lat},
      {leftText: 'LON', rightText: ipData?.ip?.lon},
      {leftText: 'isp', rightText: ipData?.ip?.isp},
      {
        leftText: 'Mobile network',
        rightText: capitalizeEveryWord(ipData?.ip?.mobile?.toString()),
      },
      {
        leftText: 'vpn Proxy',
        rightText: capitalizeEveryWord(ipData?.ip?.proxy?.toString()),
      },
      {
        leftText: 'server hosting',
        rightText: capitalizeEveryWord(ipData?.ip?.hosting?.toString()),
      },
    ],
    [ipData],
  );
  // const center = {
  //   lat: Number(ipData?.ip?.lat || 0),
  //   lng: Number(ipData?.ip?.lon || 0),
  // };
  return (
    <Layout>
      <div>
        <p className="font-medium text-grey">Device Info</p>
        <div className="flex flex-col mt-4">
          {IP_DATA.map(({leftText, rightText}, i) => (
            <RowData key={i} leftText={leftText} rightText={rightText} />
          ))}
        </div>
        <div className="mt-10">
          <div className="flex items-center justify-between">
            <p className="font-medium text-grey">IP Information</p>
          </div>
          {/*<div className="relative">*/}
          {/*  <GoogleMap center={center} />*/}
          {/*</div>*/}
        </div>

        <div className="flex flex-col mt-4">
          {IP_DATA_2.map(({leftText, rightText}, i) => (
            <RowData key={i} leftText={leftText} rightText={rightText} />
          ))}
        </div>
      </div>
    </Layout>
  );
}

export default connect(state => state, Actions)(IpDeviceCheck);
