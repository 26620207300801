import React, {Fragment} from 'react';
import {Popover, Transition} from '@headlessui/react';

export const PopOver = ({header, show, children}) => (
  <div className="">
    <Popover className="relative">
      {() => (
        <>
          {header && (
            <Popover.Button className="outline-none">{header}</Popover.Button>
          )}
          {children && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
              show={show}
            >
              <Popover.Panel className="absolute  left-1/2 w-auto z-10 mt-3  max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="absolute h-8 w-8 bg-danger rotate-45 bottom-8 drop-shadow-md -z-10 left-1/2" />
                <div className="overflow-hidden rounded-lg shadow-lg w-80  bg-white p-4 right-10 relative outline-none">
                  {children}
                </div>
              </Popover.Panel>
            </Transition>
          )}
        </>
      )}
    </Popover>
  </div>
);
