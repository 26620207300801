import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {Link, useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import * as Actions from '../../store/actions';
import {fetchGlobalSearch} from '../../requests/queries/lookup';
import {
  ButtonLoader,
  DashboardLayout,
  RowData,
  SecondaryButton,
} from '../../components';
import {chevronRight, easyLookupIc} from '../../assets/images/images';
import {generatePDF as downloadPdf} from '../EasyLookup/generatePDFStructureForEasyLookup';
import {generateGlobalCheckPDF} from '../AMLScreening/generatePDF';
import {convertSVGToBase64} from '../../helpers/convertSVGToBase64';
import {Constants} from '../../helpers';

function GlobalSearchResults({lookup: {lookup}, auth: {userDetails}}) {
  const {slug} = useParams();
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const app_id = parameters.get('app_id');
  const appId = localStorage.getItem('aml_app_id') || app_id;
  const storedAppIds = localStorage.getItem('appIds');
  const firstAppId = storedAppIds ? storedAppIds.split(',')[0] : null;

  const [base64URL, setBase64URL] = useState('');

  const SearchResults =
    lookup?.global_search?.entity || lookup?.company_search?.detail;

  const item = SearchResults?.find(item => item.internationalNumber === slug);

  const {data, isLoading} = useQuery(
    [
      'global_search',
      {
        country_code: item?.countryCode,
        international_number: item?.internationalNumber,
        app_id: appId || firstAppId,
      },
    ],
    () =>
      fetchGlobalSearch({
        country_code: item?.countryCode,
        international_number: item?.internationalNumber,
        app_id: appId || firstAppId,
      }),
  );

  const businessInfo = data?.entity;

  const statusValue = businessInfo?.statuses?.[0]?.providerId || '-';
  const isActive = statusValue?.toUpperCase() === 'ACTIVE';

  const statusElement = (
    <div
      className={`w-fit text-xs font-medium ${
        isActive
          ? 'bg-statusBg-200 text-status-200'
          : 'bg-status-404 text-statusBg-200'
      } px-2 pt-[5px] pb-[3px] rounded-full ml-auto`}
    >
      {statusValue}
    </div>
  );

  const rawPaidShareCapital = businessInfo?.paidShareCapital;
  const amount = rawPaidShareCapital?.match(/\d+/);
  const currency = rawPaidShareCapital?.match(/[A-Za-z]+/);
  const formattedPaidShareCapital = amount
    ? `${parseFloat(amount[0]).toLocaleString('en-US')} ${
        currency ? currency[0] : '-'
      }`
    : '-';

  const PROFILE = [
    {
      leftText: 'Business Name',
      rightText: businessInfo?.name || '-',
    },
    {
      leftText: 'International Number',
      rightText: businessInfo?.internationalNumber || '-',
    },
    {
      leftText: 'Local Number',
      rightText: businessInfo?.localNumber || '-',
    },
    {leftText: 'Address', rightText: businessInfo?.address || '-'},
    {
      leftText: 'Paid shares Capital',
      rightText: formattedPaidShareCapital,
    },
    {leftText: 'Shares Value', rightText: businessInfo?.sharesValue || '-'},
    {leftText: 'Shares Issued', rightText: businessInfo?.sharesIssued || '-'},
    {
      leftText: 'Description',
      rightText: businessInfo?.activityDescription || '-',
    },
    {
      leftText: 'type',
      rightText: businessInfo?.type?.type || '-',
    },
    {
      leftText: 'status',
      rightText: statusElement,
    },
    {
      leftText: 'date created',
      rightText:
        moment(businessInfo?.dateCreated).format('MMM DD, YYYY') || '-',
    },
  ];

  const countryInfo = data?.entity?.country;

  const COUNTRY = [
    {
      leftText: 'Country name',
      rightText: countryInfo?.name || '-',
    },
    {
      leftText: 'Country code',
      rightText: countryInfo?.code || '-',
    },
    {
      leftText: 'Currency',
      rightText: countryInfo?.currency || '-',
    },
  ];

  const personsData = businessInfo?.persons || [];

  const uniquePersonsData = Array.from(
    new Set(personsData?.map(person => person?.person?.id)),
  ).map(id => personsData?.find(person => person?.person?.id === id));

  const PERSONS = uniquePersonsData?.flatMap(person => [
    {leftText: 'Role', rightText: person?.role?.name || ''},
    {
      leftText: 'ID',
      rightText: person?.person?.id || '-',
    },
    {
      leftText: 'country id',
      rightText: person?.person?.countryId || '-',
    },
    {
      leftText: 'occupation',
      rightText: person?.person?.occupation || '-',
    },
    {
      leftText: 'nationality',
      rightText: person?.person?.nationality || '-',
    },
    {
      leftText: 'Address',
      rightText: person?.person?.address || '-',
    },
    {
      leftText: 'Country of residence',
      rightText: person?.person?.countryOfResidence || '-',
    },
    {
      leftText: 'Honorific',
      rightText: person?.person?.honorific || '-',
    },
    {
      leftText: 'Number',
      rightText: person?.person?.number || '-',
    },
    {
      leftText: 'email',
      rightText: person?.person?.email || '-',
    },
    {
      leftText: 'Phone number',
      rightText: person?.person?.phone || '-',
    },
    {
      leftText: 'Country code',
      rightText: person?.person?.countryCode || '-',
    },
    {
      leftText: 'Nature of control',
      rightText:
        person?.person?.naturesOfControl?.map(item => item.name) || '-',
    },
    {
      leftText: 'Person shares',
      rightText: person?.person?.personShares?.map(item => item.name) || '-',
    },
  ]);

  const CONTACTS = [
    ...(businessInfo?.companyContacts?.email ?? []).map((email, index) => ({
      leftText: `Email ${index + 1}`, // Display Email 1, Email 2, etc.
      rightText: email || '-', // Display the email address or '-'
    })),
    ...(businessInfo?.companyContacts?.phone ?? []).map((phone, index) => ({
      leftText: `Phone ${index + 1}`, // Display Phone 1, Phone 2, etc.
      rightText: phone || '-', // Display the phone number or '-'
    })),
  ];

  const activitiesData = businessInfo?.activities;
  const ACTIVITIES = activitiesData?.flatMap(activity => [
    {
      leftText: 'Provider ID',
      rightText: activity.providerId || '',
    },
    {
      leftText: 'Provider Name',
      rightText: activity.providerName || '',
    },
    {
      leftText: 'Type',
      rightText: activity.type?.name || '',
    },
  ]);

  useEffect(() => {
    convertSVGToBase64().then(logo => setBase64URL(logo));
  }, []);

  const statusText = statusElement.props.children;

  const transformedProfile = PROFILE?.reduce((acc, item) => {
    const {leftText, rightText} = item;
    acc[leftText.toLowerCase().replaceAll(' ', '_')] =
      rightText === statusElement ? statusText : rightText;
    return acc;
  }, {});

  const transformedCountry = COUNTRY?.reduce((acc, item) => {
    const {leftText, rightText} = item;
    acc[leftText.toLowerCase().replaceAll(' ', '_')] = rightText;
    return acc;
  }, {});

  const transformedContacts = CONTACTS?.reduce((acc, contact, index) => {
    const {leftText, rightText} = contact;
    acc[`${leftText.toLowerCase().replaceAll(' ', '_')}_${index + 1}`] =
      rightText;
    return acc;
  }, {});

  const transformedPersons = personsData.map(person => ({
    name: person?.person?.name || '',
    data: PERSONS.reduce((acc, {leftText, rightText}) => {
      acc[leftText] = rightText;
      return acc;
    }, {}),
  }));

  const transformedActivities = ACTIVITIES?.reduce((acc, activity) => {
    acc[activity.leftText] = activity.rightText;
    return acc;
  }, {});

  const pdfData = [
    {
      title: 'Profile',
      data: transformedProfile,
    },
    {
      title: 'Country',
      data: transformedCountry,
    },
    {
      title: 'Contacts',
      data: transformedContacts,
    },
    {
      title: 'Activities',
      data: transformedActivities,
    },
  ];

  const pdfArray = [
    {
      title: 'Persons',
      data: transformedPersons,
    },
  ];

  const handlePdfDownload = () => {
    const companyName = userDetails?.company?.name;
    const content = generateGlobalCheckPDF({
      pdfData,
      logo: base64URL,
      pdfArray,
      companyName,
    });
    const pdfFooter = {
      name: lookup && lookup?.company_search?.caller_name,
      generated: new Date(),
      logo: base64URL,
    };
    downloadPdf(content, pdfFooter, 'Global Check');
  };

  return (
    <div>
      <DashboardLayout
        topPadding="pt-0"
        bg="bg-white80"
        xlLeftMargin="xl:ml-12"
        overFlow=""
        breadCrumbs={
          <div className="flex items-center">
            <img src={easyLookupIc} alt="" width={18} height={18} />
            <Link
              to={Constants.PAGES_URL.BUSINESS_LOOKUP_SINGLE}
              className="ml-2 -mb-1 cursor-pointer"
            >
              Easy Lookup
            </Link>
            <img src={chevronRight} alt="" width={18} height={18} />
            <p className="-mb-1 cursor-pointer">Global Check</p>
          </div>
        }
      >
        {isLoading && (
          <div className="fixed  left-0 top-0 z-50  w-full h-full flex items-center justify-center">
            <ButtonLoader color="#3F7CDB" />
            <span className="text-sm mt-16 text-body font-medium italic">
              ...loading
            </span>
          </div>
        )}
        <div
          style={{opacity: isLoading && 0.3}}
          className="flex-wrap items-start min-h-full xl:flex xl:flex-nowrap basis-full print:hidden"
        >
          <div className="basis-[17.58%] flex flex-col gap-6 pt-16 sm:pt-6 xl:pt-[35px] xl:sticky xl:top-[35px]">
            <h2 className="hidden text-base font-medium sm:block text-grey sm:text-lg whitespace-nowrap">
              Global Check
            </h2>

            <div className="flex flex-col gap-6 mt-11">
              <p className="flex items-center text-sm text-grey40 ">
                {`Search Results>>`}
                <span className="flex ml-2 items-center justify-center w-4 h-4 text-xs font-medium text-white rounded-full bg-brandBlue">
                  {SearchResults?.length || 0}
                </span>
              </p>

              {SearchResults &&
                SearchResults?.map((item, index) => (
                  <Link
                    to={`/easy-lookup/business/global-search/result/${item.internationalNumber}`}
                    key={index}
                    className={`${
                      item.internationalNumber === slug
                        ? 'text-brandBlue border-l-2 border-brandBlue pl-3'
                        : ''
                    } flex flex-col gap-2 text-body font-medium`}
                  >
                    <p className="text-sm">{item.name || item.Name}</p>
                    <div className="flex">
                      {item.countryCode
                        ?.split(',')
                        ?.slice(0, 2)
                        ?.map((type, idx) => (
                          <p
                            key={idx}
                            className={`p-[4px] mr-2 px-2  text-[8px] font-medium  w-fit uppercase rounded-full ${
                              type?.toLowerCase() === 'pep'
                                ? 'text-success  bg-statusBg-200'
                                : type?.toLowerCase() === 'adverse-media'
                                ? 'text-brandBlue50  bg-brandBlue40'
                                : 'bg-brandBlue4'
                            }`}
                          >
                            {type.replace('-', ' ')}
                          </p>
                        ))}
                    </div>
                  </Link>
                ))}
            </div>
          </div>

          <div className="flex flex-col xl:basis-[45.67%]">
            <div className="xl:ml-8 xl:basis-[45.67%] mt-6 xl:mt-0 px-4 grow xl:grow-0 xl:p-8 pt-4 xl:pt-[36px] bg-white80 xl:bg-white">
              <div className="flex items-center justify-between">
                <p className="text-grey">{data?.entity?.name}</p>
              </div>
              <div className="mt-5 mb-6">
                <h3 className="mb-6 font-medium text-grey">Business Profile</h3>
                {PROFILE.map((profile, index) => (
                  <RowData key={index} {...profile} alignStart width="w-96" />
                ))}
              </div>
              <div className="mt-10 mb-6">
                <h3 className="mb-6 font-medium text-grey">Country</h3>
                {COUNTRY.map((country, index) => (
                  <RowData key={index} {...country} alignStart width="w-96" />
                ))}
                <div className="flex flex-col gap-6 p-4 rounded-lg bg-white80">
                  <div>
                    <h3 className="mb-2 font-medium text-grey">Sources</h3>

                    <ul className="flex flex-col gap-2 ml-4 text-sm list-disc text-brandBlue md:max-w-full">
                      {countryInfo?.sources?.map((item, i) => (
                        <li key={i}>
                          <a
                            href={item?.url || '-'}
                            target="_blank"
                            rel="noreferrer"
                            className="underline"
                          >
                            {item.name || '-'}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-10 mb-6 flex flex-col gap-4">
                <h3 className="font-medium text-grey">
                  Persons -{' '}
                  <span className="text-brandBlue font-medium">
                    {personsData.length}
                  </span>
                </h3>
                {personsData.map((person, index) => (
                  <div
                    key={`${person.person.id}-${person.person.name}-${index}`}
                  >
                    <p className="text-body text-tiny capitalize font-medium mb-2">
                      {person.person?.name}
                    </p>
                    {PERSONS?.map((item, index) => (
                      <RowData
                        key={index}
                        leftText={item.leftText}
                        rightText={item.rightText}
                        alignStart
                        width="w-96"
                      />
                    ))}
                  </div>
                ))}
              </div>
              {CONTACTS?.length > 0 ? (
                <div className="mt-10 mb-6">
                  <h3 className="mb-6 font-medium text-grey">
                    Company Contacts
                  </h3>
                  {CONTACTS.map((contact, index) => (
                    <RowData key={index} {...contact} alignStart width="w-96" />
                  ))}
                </div>
              ) : null}
              {activitiesData?.length > 0 ? (
                <div className="mt-10 mb-6">
                  <h3 className="mb-4 font-medium text-grey">Activities</h3>
                  {ACTIVITIES.map((rowData, rowIndex) => (
                    <RowData
                      key={rowIndex}
                      {...rowData}
                      alignStart
                      width="w-96"
                    />
                  ))}
                </div>
              ) : null}
            </div>
          </div>

          <div className="xl:basis-[36%] xl:px-6 pt-16 sm:pt-6 xl:pt-[3px] xl:sticky xl:top-[15px] rounded">
            <div className="flex bg-white p-4 mt-4 rounded print:hidden">
              <SecondaryButton
                buttonText="Download report"
                className="w-full"
                onClick={handlePdfDownload}
              />
            </div>
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
}

export default connect(state => state, Actions)(GlobalSearchResults);
