import React from 'react';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import Select from 'react-select';
import {customStyles, DropdownIndicator} from '../ReactSelect';
import {Constants} from '../../helpers';

function Zimbabwe({lookupInputs, setLookupInputs}) {
  // first_name
  // date_of_birth
  // gender
  // marital_status
  // mobile

  return (
    <>
      <div
        className={`${
          lookupInputs.type === null ? 'hidden' : 'block'
        } mt-8 sm:bg-white80 sm:p-6 rounded`}
      >
        <label htmlFor="idType" className="relative">
          {lookupInputs?.type?.label}
          <input
            id="idType"
            className="mt-2"
            placeholder={`Enter ${lookupInputs?.type?.label} here`}
            type="text"
            onChange={e =>
              setLookupInputs({
                ...lookupInputs,
                lookupValue: e.target.value,
              })
            }
          />
        </label>
        {lookupInputs?.type?.value === 'fcb' && (
          <div className="w-full">
            <label htmlFor="fist_name" className="relative mt-5">
              First name
              <input
                id="fist_name"
                name="fist_name"
                className="mt-2"
                placeholder="Dojah"
                type="text"
                onChange={e =>
                  setLookupInputs({
                    ...lookupInputs,
                    firstname: e.target.value,
                  })
                }
              />
            </label>
            <label htmlFor="last_name" className="relative mt-5">
              Last name
              <input
                id="last_name"
                name="last_name"
                className="mt-2"
                placeholder="Dojah"
                type="text"
                onChange={e =>
                  setLookupInputs({
                    ...lookupInputs,
                    lastname: e.target.value,
                  })
                }
              />
            </label>
            <label htmlFor="date_of_birth" className="relative mt-5">
              Date of birth
              <input
                id="date_of_birth"
                name="date_of_birth"
                className="mt-2"
                placeholder="Dojah"
                type="date"
                onChange={e =>
                  setLookupInputs({
                    ...lookupInputs,
                    date_of_birth: e.target.value,
                  })
                }
                min={new Date().getDay()}
              />
            </label>
            <label htmlFor="mobile" className="relative mt-5">
              Mobile
              <input
                id="mobile"
                name="mobile"
                className="mt-2"
                placeholder="Dojah"
                type="text"
                onChange={e =>
                  setLookupInputs({
                    ...lookupInputs,
                    mobile: e.target.value,
                  })
                }
              />
            </label>
            <label htmlFor="gender" className="relative mt-5 w-full">
              Gender
              <Select
                defaultValue={lookupInputs?.gender}
                value={lookupInputs?.gender}
                onChange={gender => setLookupInputs({...lookupInputs, gender})}
                options={Constants.lookups.GENDER}
                placeholder="Select your gender"
                components={{DropdownIndicator}}
                classNamePrefix="react-select"
                styles={customStyles}
                isSearchable={false}
                className="w-full"
              />
            </label>
            <label htmlFor="marital_status" className="relative mt-5 w-full">
              Marital status
              <Select
                defaultValue={lookupInputs?.marital_status}
                value={lookupInputs?.marital_status}
                onChange={marital_status =>
                  setLookupInputs({...lookupInputs, marital_status})
                }
                options={Constants.lookups.MARITAL_STATUS}
                placeholder="Select your marital status"
                components={{DropdownIndicator}}
                classNamePrefix="react-select"
                styles={customStyles}
                isSearchable={false}
                className="w-full"
              />
            </label>
          </div>
        )}
      </div>
    </>
  );
}

export default connect(state => state, Actions)(Zimbabwe);
