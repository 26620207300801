import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {FilterButton, Loader, PrimaryButton} from '../../../../components';
import {
  failed,
  overviewCashflow,
  overviewCustomers,
  overviewInflow,
  overviewOutflow,
  projectsMobileNav,
  successful,
} from '../../../../assets/images/images';
import ReconciliationLayout from '../ReconciliationLayout';

import AddAccountModal from './AddAccountModal';
import ManuallyAddAccount from './ManuallyAddAccount';
import AutomaticallyAddBankModal from './AutomaticallyAddBankModal';
import AutomaticallyAddAccountModal from './AutomaticallyAddAccountModal';
import {useAnalysisData} from '../hooks/useAnalysisData';
import {convertToPercentage} from '../../../../helpers/convertToPercentage';
import AccountDetailsPieChart from './AccountDetailsPieChart';
import {formatNumberToCurrency} from '../../../../helpers/formatNumberToCurrency';
import {LineChart} from '../components/LineChart';
import {Period} from '../components/Period';
import {OverviewCards} from '../components/OverviewCards';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';

function ProjectOverview() {
  const [openFilter, setOpenFilter] = useState(false);
  const [date, setDate] = useState('month');
  const [selectedBank, setSelectedBank] = useState({});
  const [isAddAccount, setIsAddAccount] = useState(false);
  const [isManuallyAddAccount, setIsManuallyAddAccount] = useState(false);
  const [automaticallyAddBank, setAutomaticallyAddBank] = useState(false);
  const [automaticallyAddAccount, setAutomaticallyAddAccount] = useState(false);
  const {slug} = useParams();

  const {
    overview: {overviewLoading, inFlow, outFlow},
    chartAnalysis: {analysisChart, chartLoading},
    reconAnalysis: {reconLoading, totalRecon, getCount},
  } = useAnalysisData({id: slug, date, entity_id: 'project_id'});

  const openAddAccountModal = () => {
    setIsManuallyAddAccount(false);
    setAutomaticallyAddBank(false);
    setIsAddAccount(true);
  };

  const closeAddAccountModal = () => {
    setIsAddAccount(false);
  };

  const openManuallyAddAccountModal = () => {
    closeAddAccountModal();
    setIsManuallyAddAccount(true);
  };

  const closeManuallyAddAccountModal = () => {
    setIsManuallyAddAccount(false);
  };

  const openAutomaticallyAddBankModal = () => {
    closeAddAccountModal();
    setAutomaticallyAddBank(true);
  };

  const closeAutomaticallyAddBankModal = () => {
    setAutomaticallyAddBank(false);
  };

  const openAutomaticallyAddAccountModal = () => {
    closeAutomaticallyAddBankModal(true);
    setAutomaticallyAddAccount(true);
  };

  const closeAutomaticallyAddAccountModal = () => {
    setAutomaticallyAddAccount(false);
  };

  const flows = [
    {
      icon: overviewCashflow,
      alt: 'net-cash flow',
      title: 'Net CashFlow',
      value: formatNumberToCurrency(inFlow?.amount - outFlow?.amount),
    },
    {
      icon: overviewInflow,
      alt: 'in-flow',
      title: 'Total Inflow',
      count: inFlow?.count || '0',
      value: formatNumberToCurrency(inFlow?.amount),
    },
    {
      icon: overviewOutflow,
      alt: 'out-flow',
      title: 'Total Outflow',
      count: outFlow?.count || '0',
      value: formatNumberToCurrency(outFlow?.amount),
    },
    {
      icon: overviewCustomers,
      alt: 'customers',
      title: 'No. of Customers',
      value: shortenLargeNumbers(0, null, {decimals: 0}),
    },
  ];

  const accountDetails = [
    {
      title: 'Current Balance',
      value: '₦259,200',
      textColor: 'text-deepBlue300',
      valueColor: 'text-deepBlue',
    },
    {
      title: 'Average Closing Balance',
      value: '₦30,390,290',
      textColor: 'text-deepBlue300',
      valueColor: 'text-deepBlue',
    },
    {
      title: 'Total No. of Transactions',
      value: '839',
      textColor: 'text-deepBlue300',
      valueColor: 'text-deepBlue',
    },
    {
      title: 'Total Fees Charged',
      value: '₦203,290',
      textColor: 'text-error-300',
      valueColor: 'text-error',
    },
  ];

  return (
    <ReconciliationLayout
      bg="bg-white80"
      pageTitle="Project A"
      pageIcon={projectsMobileNav}
      action={
        <div className="items-center hidden gap-4 sm:flex">
          <FilterButton openFilter={openFilter} setOpenFilter={setOpenFilter} />
          <PrimaryButton
            buttonText="Add Account"
            className="py-[14px] px-[44px] rounded-lg"
            onClick={openAddAccountModal}
          />
        </div>
      }
    >
      <AddAccountModal
        show={isAddAccount}
        onClose={closeAddAccountModal}
        openManuallyAddAccountModal={openManuallyAddAccountModal}
        openAutomaticallyAddBankModal={openAutomaticallyAddBankModal}
      />

      <ManuallyAddAccount
        show={isManuallyAddAccount}
        onClose={closeManuallyAddAccountModal}
        goBack={openAddAccountModal}
      />

      <AutomaticallyAddBankModal
        show={automaticallyAddBank}
        onClose={closeAutomaticallyAddBankModal}
        goBack={openAddAccountModal}
        openAutomaticallyAddAccountModal={openAutomaticallyAddAccountModal}
        setSelectedBank={setSelectedBank}
      />

      <AutomaticallyAddAccountModal
        show={automaticallyAddAccount}
        onClose={closeAutomaticallyAddAccountModal}
        selectedBank={selectedBank}
        goBack={() => setAutomaticallyAddBank(!automaticallyAddBank)}
      />

      <div className="mt-10 pl-11 pr-8">
        <OverviewCards overviewLoading={overviewLoading} cardData={flows} />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-6">
          <div className="p-6 bg-white rounded-xl flex flex-col gap-6 items-start">
            {accountDetails.map(({title, value, textColor, valueColor}) => (
              <div key={title} className="flex flex-col gap-1 items-start">
                <p className={`text-small ${textColor} font-inter`}>{title}</p>
                <h1 className={`${valueColor} font-semibold text-xxl`}>
                  {value}
                </h1>
              </div>
            ))}
          </div>

          <div className="p-6 bg-white rounded-xl">
            <h3 className="text-grey text-lg font-medium mb-6">
              Reconciliation
            </h3>

            {reconLoading ? (
              <Loader height={22} />
            ) : (
              <div className="flex flex-col lg:flex-row items-center gap-6">
                <AccountDetailsPieChart
                  totalRecon={totalRecon}
                  recon={getCount('reconciled')?.count}
                  unrecon={getCount('unreconciled')?.count}
                />

                <div className="flex flex-col gap-8 right-10">
                  <div className="flex items-center gap-2">
                    <img src={successful} alt="" width={40} height={40} />
                    <div className="flex flex-col gap-1">
                      <p className="text-xs font-medium uppercase text-body">
                        Reconciled
                      </p>
                      <p className="text-xl font-medium text-grey">
                        {convertToPercentage(
                          getCount('reconciled')?.count,
                          totalRecon,
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={failed} alt="" width={40} height={40} />
                    <div className="flex flex-col gap-1">
                      <p className="text-xs font-medium uppercase text-body">
                        Unreconciled
                      </p>
                      <p className="text-xl font-medium text-grey">
                        {convertToPercentage(
                          getCount('unreconciled')?.count,
                          totalRecon,
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="bg-white rounded-xl w-full mt-6 h-[444px]">
          <LineChart
            chartData={analysisChart}
            customElements={<Period date={date} setDate={setDate} />}
            periodType={date}
            loading={chartLoading}
          />
        </div>
      </div>
    </ReconciliationLayout>
  );
}

export default ProjectOverview;
