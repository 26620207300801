import React from 'react';
import {Modal, PrimaryButton} from '../../../components';
import {closeModal} from '../../../assets/images/images';

function ReconcileTransactionModal({show, onClose}) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <h3 className="font-medium text-base text-grey">
            Reconcile Transaction
          </h3>

          <button
            onClick={() => {
              onClose(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>

        <form className="mt-6">
          <label htmlFor="comments" className="mt-6">
            <span className="text-sm text-body font-medium">Comments</span>
            <textarea
              id="comments"
              className="mt-2 h-40 placeholder:text-grey-200 placeholder:font-normal"
              name="project_description"
              placeholder="Input comments"
            />
          </label>

          <div className="flex items-center mt-6">
            <PrimaryButton
              buttonText="Submit"
              className="w-full rounded-lg"
              type="submit"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ReconcileTransactionModal;
