import {PrimaryButton} from '../../../../components';
import {NoTick} from '../../../../assets/images/images';
import React from 'react';

const PlanCard = ({title, price, isPrimary, onChoose, isLoading, plan}) => {
  const canSubscribe = isPrimary && plan !== 'base';
  return (
    <div
      className={`w-full rounded-2xl border border-[#d7dde4] py-[18px] px-6 ${
        isPrimary ? 'bg-deepBlue text-white' : 'bg-white text-deepBlue'
      }`}
    >
      <h4 className="text-[22px] font-semibold">{title}</h4>
      {price ? (
        <p
          className={`text-body font-medium text-xs mb-3 ${
            isPrimary ? 'text-grey5' : ''
          }`}
        >
          {price}
        </p>
      ) : (
        <div className="mb-5" />
      )}
      {canSubscribe && (
        <PrimaryButton
          buttonText={plan === 'enterprise' ? 'CONTACT US' : 'Choose Plan'}
          className="text-xs !py-2 !px-8 rounded-lg"
          onClick={onChoose}
          loading={isLoading}
        />
      )}
    </div>
  );
};

const FeatureList = ({features}) => (
  <div className="bg-white rounded-2xl p-6 border border-[#d7dde4] flex flex-col gap-4 lg:gap-5">
    {features.map(({feature, ticks}, index) => (
      <div key={index} className="flex flex-col md:flex-row lg:gap-12 gap-2">
        <div className="md:min-w-[250px] font-inter flex items-center">
          <p className="text-small text-deepBlue">{feature}</p>
        </div>
        <div className="flex items-center justify-between w-full lg:pr-24">
          {ticks.map((tick, tickIndex) => (
            <img
              key={tickIndex}
              src={tick}
              alt={tick === NoTick ? 'no_tick' : 'tick'}
            />
          ))}
        </div>
      </div>
    ))}
  </div>
);

const handleReconciloEnterprise = () => {
  window.open(
    `mailto:info@reconcilo.com?subject=${encodeURIComponent(
      'Reconcilo Enterprise',
    )}`,
    '_blank',
  );
};

const handleBillingPlans = handleBillingSubscription => [
  {
    title: 'Base tier',
    price: '$0 per month/ One account',
    isPrimary: false,
    onChoose: () => handleBillingSubscription('base', 0),
    plan: 'base',
  },
  {
    title: 'Standard tier',
    price: '$99 per month/ One account',
    isPrimary: true,
    onChoose: () => handleBillingSubscription('standard', 99),
    plan: 'standard',
  },
  {
    title: 'Premium tier',
    price: '$199 per month/ One account',
    isPrimary: true,
    onChoose: () => handleBillingSubscription('premium', 199),
    plan: 'premium',
  },
  {
    title: 'Enterprise tier',
    // price: '$1000 per month/ One account',
    isPrimary: true,
    onChoose: () => handleReconciloEnterprise(),
    plan: 'enterprise',
  },
];

export {PlanCard, FeatureList, handleBillingPlans};
