import React from 'react';
import {closeModal} from '../assets/images/images';
import Modal from './Modal';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

export default function FiltersWrapper({
  openFilter,
  setOpenFilter,
  children,
  handleFilter,
  clearFilters,
  onResetFilters,
  customClasses,
}) {
  return (
    <Modal
      show={openFilter}
      onClose={setOpenFilter}
      {...(customClasses
        ? {...customClasses}
        : {
            extraModalClasses:
              'overflow-y-auto h-fit max-h-[95%] sm:max-h-full max-w-full sm:max-w-[352px] rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal',
            modalPosition: 'justify-center sm:justify-end',
          })}
    >
      <div className="px-[19px] py-6 sm:px-6">
        <header className="flex items-center justify-between pb-4">
          <h3 className="text-grey text-base font-medium">Filter</h3>
          <button onClick={() => setOpenFilter(false)}>
            <img src={closeModal} alt="" width={24} height={24} />
          </button>
        </header>

        <div className="flex flex-col gap-2">{children}</div>
        <div className="flex items-center sm:justify-center gap-2 mt-6">
          <PrimaryButton
            buttonText="Filter"
            className="w-full"
            onClick={() => {
              handleFilter();
            }}
          />
          <SecondaryButton
            buttonText="Reset all"
            className="whitespace-nowrap cursor-pointer"
            onClick={() => {
              onResetFilters();
            }}
            clearFilters={clearFilters}
          />
        </div>
      </div>
    </Modal>
  );
}
