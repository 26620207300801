import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {accountRetrieval} from '../../../../requests/queries/baas';

const ACCOUNT_NUMBER_MAX = 10;
export const useRetrieveUserBankName = ({accountNumber, bankCode}) => {
  const params = useMemo(
    () => ({
      bank_code: bankCode,
      account_number: accountNumber,
    }),
    [bankCode, accountNumber],
  );

  const {data: userBankDetails, isLoading: isBankDetailsLoading} = useQuery(
    ['bank-details', {params}],
    () => accountRetrieval({params}),
    {
      enabled:
        accountNumber?.length === ACCOUNT_NUMBER_MAX && bankCode?.length > 0,
    },
  );

  return {userBankDetails, isBankDetailsLoading};
};
