/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {Checkbox, Modal, VerificationToggle} from '../../../components';
import * as Actions from '../../../store/actions';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../../helpers/formatNumberToCurrency';

function Email({
  widgets: {widgetScreen, widgetPages, widgetPricing},
  auth: {userDetails, userSettings},
  setWidgetCurrentPricing,
  setWidgetScreen,
  setWidgetPages,
  ...otherProps
}) {
  const {open, setOpen} = otherProps;
  const [update, setUpdate] = useState(true);
  const [pricing, setPricing] = useState({
    services: {},
  });

  const [config, setConfig] = useState({
    verification: true,
    freeProvider: true,
    disposable: false,
  });

  const displayPrice = price =>
    userDetails && userDetails.company && userDetails.company.currency === 'USD'
      ? formatUsd(price || 0)
      : formatNumberToCurrency(
          price || 0,
          userDetails && userDetails.company && userDetails.company.currency,
          userSettings &&
            userSettings.settings &&
            userSettings.settings.dollar_exchange_rate,
        );

  useEffect(() => {
    const isActive =
      widgetPages &&
      widgetPages?.find(
        data => data?.page === 'email' && data[`${data?.page}-${data?.pageId}`],
      );
    if (isActive) {
      for (const key in config) {
        if (typeof config[key] === 'boolean') {
          setPricing(prev => {
            const price = widgetPricing && widgetPricing?.email?.services[key];
            if (config[key] === true) {
              return {
                services: {
                  ...{
                    ...prev.services,
                    [key]: price / 100,
                  },
                },
              };
            } else {
              delete prev.services[key];
              return prev;
            }
          });
        }
      }

      setWidgetCurrentPricing({
        email: pricing,
      });
    } else {
      setWidgetCurrentPricing({
        email: {},
      });
    }
    return () => setWidgetCurrentPricing({email: {}});
  }, [widgetScreen, update, widgetPages, setWidgetCurrentPricing, config]);

  const foundWidget = useMemo(
    () =>
      widgetPages &&
      widgetPages?.find(
        data => data?.pageId === widgetScreen?.pageId && data?.page === 'email',
      ),
    [widgetPages, widgetScreen],
  );
  const ALL_PAGES = JSON.parse(localStorage.getItem('pages'));
  const updateConfig = newConfig => {
    const newPages = ALL_PAGES.map(page => {
      if (page?.page === foundWidget?.page) {
        return {
          ...page,
          config: newConfig,
        };
      }
      return page;
    });

    localStorage.setItem('pages', JSON.stringify(newPages));
  };

  useEffect(() => {
    if (foundWidget) {
      if (Object.values(foundWidget?.config || {})?.length === 0) {
        setConfig({...config});
        setUpdate(false);
        setWidgetPages({
          ...foundWidget,
          config: {...config},
        });
        updateConfig({...config});
      } else {
        setConfig({...config, ...foundWidget?.config});
        setWidgetPages({
          ...foundWidget,
          config: {...config, ...foundWidget?.config},
        });
        updateConfig({...config, ...foundWidget?.config});
      }
    }
  }, [widgetScreen, update]);

  const checkFreeProvider = (type, value, newData) => {
    const {config: currentConfig} = widgetScreen;
    const updatedConfig = {
      ...currentConfig,
      [type]: value,
      ...(type === 'freeProvider' && !value ? {disposable: false} : {}),
      ...(type === 'disposable' && value ? {freeProvider: value} : {}),
    };

    setConfig(updatedConfig);
    setWidgetScreen({...widgetScreen, config: updatedConfig});
    setWidgetPages({...newData, config: updatedConfig});
  };

  const handleUpdateConfig = useCallback(
    (type, value) => {
      const newData = widgetPages?.find(
        data => data?.pageId === widgetScreen?.pageId,
      );
      if (!newData) {
        return toast.error(
          'Ops! Please toggle ON before configuring this widget',
        );
      } else {
        checkFreeProvider(type, value, newData);
      }
    },
    [config],
  );

  return (
    <Modal
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[339px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Configuration"
    >
      <>
        <p className="text-left text-body text-xs mt-8 mb-2 font-medium uppercase">
          Mode of verification
        </p>
        <Checkbox
          label="OTP Verification"
          className="py-[10px]"
          labelStyle="text-xs text-body leading-[18px]"
          align="items-start"
          isChecked={config?.verification}
          onChange={e => handleUpdateConfig('verification', e.target.checked)}
          otherText={displayPrice(
            widgetPricing && widgetPricing?.email?.services?.verification / 100,
          )}
        />
      </>{' '}
      <>
        <p className="text-left text-body text-xs mt-6 mb-1 font-medium uppercase">
          Configure what type of email you want
        </p>
        <>
          <div className="flex items-center gap-2 pl-4 pr-2 py-[10px] cursor-pointer">
            <VerificationToggle
              isChecked={config.disposable}
              onCheck={value => handleUpdateConfig('disposable', value)}
            />
            <p className="text-xs leading-[18px] text-body">Disposable Email</p>
          </div>
          <p className="text-xs text-grey40 w-64 text-left pl-4 leading-4">
            Users have the option to enter temporary email addresses or easily
            disposable ones, such as. example123@mailinator.com
          </p>
        </>{' '}
        <>
          <div className="flex items-center gap-2 mt-4 pl-4 pr-2 py-[10px] cursor-pointer">
            <VerificationToggle
              isChecked={config.freeProvider}
              onCheck={value => handleUpdateConfig('freeProvider', value)}
            />
            <p className="text-xs leading-[18px] text-body">Free Provider</p>
          </div>
          <p className="text-xs text-grey40 w-64 text-left pl-4 leading-4">
            Users have the flexibility to enter email addresses from platforms
            such as Gmail or Yahoo, which are often not business emails.
          </p>
        </>
      </>
    </Modal>
  );
}

export default connect(state => state, Actions)(Email);
