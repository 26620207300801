import {Link} from 'react-router-dom';
import {PrimaryButton} from './index';
import {toast} from 'react-toastify';
import React from 'react';

const ViewLink = ({permitted, route}) => (
  <Link
    style={{
      pointerEvents: permitted ? 'auto' : 'none',
    }}
    to={route}
    className={`w-full lg:w-auto text-sm ${
      permitted
        ? 'text-grey outline-grey80'
        : 'text-grey-100 outline-grey-200 cursor-not-allowed'
    } font-medium bg-white80 pt-[18px] pb-[14px] flex items-center justify-center h-[46px] px-6 outline outline-1  rounded`}
  >
    View history
  </Link>
);

const PerformBtn = ({setOpen, isCompanyVerified, permitted, btnText}) => (
  <PrimaryButton
    buttonText={btnText}
    onClick={() => {
      if (!isCompanyVerified) {
        toast.error('Your company has to be verified to perform a lookup');
        setOpen(false);
        return;
      }

      setOpen(true);
    }}
    className="w-full lg:w-fit"
    disabled={!permitted}
  />
);
export {ViewLink, PerformBtn};
