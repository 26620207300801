export const removeDuplicates = data => {
  const mergeNestedObject = (existingObj, newObj) => {
    for (const key in newObj) {
      if (typeof newObj[key] === 'object' && newObj[key] !== null) {
        existingObj[key] = mergeNestedObject(
          existingObj[key] || {},
          newObj[key],
        );
      } else {
        existingObj[key] = newObj[key];
      }
    }
    return existingObj;
  };

  return data.reduce((acc, curr) => {
    const existingPage = acc.find(item => item.page === curr.page);
    if (!existingPage) {
      acc.push(curr);
    } else {
      let hasNestedObjects = false;
      for (const key in curr) {
        if (
          typeof curr[key] === 'object' &&
          Object.values(curr[key]).some(v => v)
        ) {
          hasNestedObjects = true;
          break;
        }
      }
      if (!('isActive' in curr)) curr.isActive = false;
      if (hasNestedObjects) mergeNestedObject(existingPage, curr);
    }
    return acc;
  }, []);
};
