import React, {useEffect, useState} from 'react';
import CreatableSelect from 'react-select/creatable';
import {
  Modal,
  customStyles,
  PrimaryButton,
  MultiValueRemove,
} from '../../../components';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';

export default function AddToListModal({
  updateList,
  open,
  setOpen,
  lists = [],
  listData = {},
  setListItems,
  loading,
}) {
  const [currentName, setCurrentName] = useState('');
  const [names, setNames] = useState([]);
  const {slug} = useParams();

  const handleKeyDown = event => {
    if (!currentName) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (names.includes(currentName.toLowerCase()))
          return toast.error(`${currentName} already exist in the list`);
        // eslint-disable-next-line no-case-declarations
        const updatedNames = [...names, currentName.toLowerCase()];
        setNames(updatedNames);

        setCurrentName('');
        event.preventDefault();
    }
  };

  useEffect(() => {
    lists && setNames([...lists]);
  }, [lists]);

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Add to list"
    >
      <form className="mt-6 text-left">
        <div className="mt-6 rounded bg-white80 p-4 flex flex-col gap-4">
          <div>
            <label
              htmlFor="add_to_list"
              className="relative flex flex-row items-end justify-between"
            >
              <p>Add to list</p>
              <p className="text-xs font-medium text-grey20">
                Click enter to add individuals
              </p>
            </label>

            <CreatableSelect
              components={{
                DropdownIndicator: null,
                MultiValueRemove,
              }}
              inputValue={currentName}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={newValue => {
                if (Array.isArray(newValue)) {
                  setNames(newValue.map(val => val.value));
                }
              }}
              onInputChange={newValue => setCurrentName(newValue)}
              onKeyDown={handleKeyDown}
              value={names.map((name, index) => ({
                label: name,
                value: name,
                key: `${name}-${index}`,
              }))}
              placeholder="Add name"
              className="mt-2 creatable-multi-select basic-multi-select"
              classNamePrefix="react-select"
              styles={customStyles}
              aria-label="Add name"
              id="add_to_list"
            />
          </div>
        </div>

        <div className="flex items-center mt-6">
          <PrimaryButton
            onClick={async () => {
              setListItems([...names]);
              await updateList({listId: slug, ...listData, items: names});
              setOpen(false);
            }}
            buttonText="Save"
            className="w-full"
            loading={loading}
            disabled={loading}
          />
        </div>
      </form>
    </Modal>
  );
}
