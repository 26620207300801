import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  customStyles,
  DropdownIndicator,
  Modal,
  PrimaryButton,
} from '../../../../components';
import {
  automaticSuccess,
  // automaticSuccess,
  closeModal,
} from '../../../../assets/images/images';
import Select from 'react-select';
import {useParams} from 'react-router-dom';
import {useMutation, useQueryClient} from 'react-query';
import {linkAccount} from '../../../../requests/queries/reconciliations';
import {GoBack} from '../components/GoBack';

function removeArrayKeysFromFields(obj) {
  const newObj = {...obj};
  if (newObj.fields && typeof newObj.fields === 'object') {
    Object.keys(newObj).forEach(key => {
      if (Array.isArray(newObj[key]) && key in newObj.fields) {
        delete newObj.fields[key];
      }
    });
  }
  return newObj;
}

function AutomaticallyAddBankModal({show, onClose, goBack, selectedBank = {}}) {
  const [inputs, setInputs] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedCurrency, setSelectCurrency] = useState({});
  const {slug} = useParams();
  const qClient = useQueryClient();

  const {mutate, isLoading} = useMutation('link-account', linkAccount, {
    onSuccess: () => {
      setShowSuccess(true);
      qClient.invalidateQueries();
    },
  });

  useEffect(() => {
    !isLoading &&
      showSuccess &&
      setTimeout(() => {
        onClose(false);
        setShowSuccess(false);
      }, 3000);
  }, [isLoading, onClose, showSuccess]);

  const filteredSelectedBank = removeArrayKeysFromFields(selectedBank);
  const formFields = useMemo(
    () =>
      Object.entries(filteredSelectedBank?.fields || {}).map(([key]) => ({
        key: key,
      })),
    [filteredSelectedBank?.fields],
  );

  const currencies = useMemo(
    () =>
      selectedBank &&
      selectedBank?.currency &&
      selectedBank?.currency.map(currency => ({
        label: currency,
        value: currency,
      })),
    [selectedBank],
  );

  const handleSubmit = useCallback(() => {
    if (
      Object.keys(inputs || {}).length === formFields?.length &&
      !Object.values(inputs || {}).some(e => !e)
    ) {
      mutate({
        provider: selectedBank?.name,
        project_id: slug,
        fields: {
          ...inputs,
          ...(selectedBank?.currency
            ? {currency: selectedCurrency?.value}
            : {}),
        },
      });
    }
  }, [
    inputs,
    formFields?.length,
    mutate,
    selectedBank?.name,
    selectedBank?.currency,
    slug,
    selectedCurrency?.value,
  ]);

  return (
    <Modal
      show={show}
      onClose={() => {
        onClose(false);
        setShowSuccess(false);
      }}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <GoBack
            goBack={() => {
              goBack();
              onClose(false);
            }}
          />

          <button
            onClick={() => {
              onClose(false);
              setShowSuccess(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>
        <h3 className="font-medium text-left mt-6 text-grey capitalize">
          {selectedBank?.name || '-'}
        </h3>

        {showSuccess && (
          <div className="flex flex-col items-center mt-4 max-w-[242px] mx-auto">
            <h2 className="text-base text-black mt-2 font-medium">
              Account added successfully
            </h2>
            <p className="text-body text-small mb-4 mx-5">
              You have successfully added an account
            </p>
            <img src={automaticSuccess} alt="success" />
          </div>
        )}

        {!showSuccess && (
          <>
            {formFields &&
              formFields?.map((field, index) => (
                <form key={index} className="mt-6">
                  <label htmlFor={field?.key} className="mb-6">
                    <span className="text-sm text-body font-medium capitalize">
                      {field?.key?.replaceAll('_', ' ')}
                    </span>
                    <input
                      type="text"
                      name={field?.key}
                      className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
                      id={field?.key}
                      placeholder={`Enter ${field?.key?.replaceAll(
                        '_',
                        ' ',
                      )} here...`}
                      value={index[field?.key]}
                      onChange={e =>
                        setInputs(prev => ({
                          ...prev,
                          [field?.key]: e.target.value,
                        }))
                      }
                      required
                    />
                  </label>
                </form>
              ))}

            {currencies?.length > 0 && (
              <>
                <label htmlFor="currency" className="relative mt-6 mb-2">
                  Select currency
                </label>
                <Select
                  defaultValue={selectedCurrency}
                  onChange={currency => setSelectCurrency({currency})}
                  options={currencies}
                  placeholder="Select currency"
                  components={{DropdownIndicator}}
                  classNamePrefix="react-select"
                  className="text-left"
                  styles={customStyles}
                />
              </>
            )}

            <div className="flex items-center mt-6">
              <PrimaryButton
                onClick={handleSubmit}
                buttonText="Submit"
                className="w-full rounded-lg"
                type="submit"
                loading={isLoading}
                disabled={
                  Object.keys(inputs || {}).length !== formFields.length ||
                  Object.values(inputs || {}).some(e => !e)
                }
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default AutomaticallyAddBankModal;
