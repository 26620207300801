import React, {forwardRef} from 'react';

export const TriggerCard = forwardRef(
  (
    {
      disabled,
      isStarting,
      isEnding,
      title,
      subtitle,
      onClick,
      height,
      icon,
      ...rest
    },
    ref,
  ) => (
    <button
      onClick={data => {
        if (disabled) return;
        onClick && onClick(data);
      }}
      className={`flex items-center rounded-lg bg-white py-5 px-4 min-w-[314px] outline outline-1 ${
        // isError
        //   ? 'outline-danger border-danger'
        //   :

        disabled
          ? 'border-grey20 opacity-50 cursor-not-allowed'
          : isStarting
          ? 'border-success outline-success'
          : isEnding
          ? 'border-status-404 outline-status-404'
          : 'outline-brandBlue60'
      } border-l-4 ${height ? 'h-[77px]' : ''} gap-5 `}
      ref={ref}
      {...rest}
    >
      {icon && <img src={icon} alt="" width={42} height={42} />}
      <div className="flex flex-col gap-1 items-start">
        <span>{title}</span>
        <span className="text-sm text-grey40 capitalize">{subtitle}</span>
      </div>
    </button>
  ),
);
