import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import {Modal, PrimaryButton} from '../../../components';
import * as Actions from '../../../store/actions';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../../helpers/formatNumberToCurrency';

function PaymentLimitModal({
  auth: {userDetails, loading, userSettings},
  updateCompanyInfo,
  ...otherProps
}) {
  const {open, setOpen} = otherProps;

  const [amount, setAmount] = useState('');

  const handleSubmit = e => {
    e.preventDefault();

    if (amount < 0 || !amount) {
      toast.error('Please enter a valid amount');
      return;
    }

    updateCompanyInfo({wallet_limit: amount});
    setAmount('');
    !loading &&
      setTimeout(() => {
        setOpen(false);
      }, 1500);
  };

  return (
    <Modal
      modalTitle="Payment threshold"
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <p className="mt-4 text-sm text-start text-body">
        Set an account limit so that when your balance runs low you will get an
        email or sms notification to remind you to top-up your account, you can
        set your limit to as low as any amount to get notified
      </p>

      <form className="mt-10 text-left">
        <label htmlFor="limit_amount">
          <span className="text-sm ">Enter amount</span>
          <input
            className="mt-2"
            placeholder={
              userDetails &&
              userDetails.company &&
              userDetails.company.currency === 'USD'
                ? `${formatUsd(userDetails.company.wallet_limit / 100)}`
                : `${formatNumberToCurrency(
                    userDetails ? userDetails.company.wallet_limit / 100 : 500,
                    userDetails &&
                      userDetails.company &&
                      userDetails.company.currency,
                    userSettings &&
                      userSettings.settings &&
                      userSettings.settings.dollar_exchange_rate,
                  )}`
            }
            id="amount"
            type="number"
            value={amount}
            onChange={e => setAmount(e.target.value)}
          />
        </label>

        <PrimaryButton
          loading={loading}
          onClick={handleSubmit}
          buttonText="Set limit"
          className="w-full mt-6"
        />
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(PaymentLimitModal);
