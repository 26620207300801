import {useDispatch} from 'react-redux';
import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {fetchAllWidgets} from '../requests/queries/widgets';

export const useWidgetFlows = () => {
  const dispatch = useDispatch();

  const {data, isLoading, isFetching} = useQuery(
    ['all-widgets', {limit: 10000, page: 1}],
    () => fetchAllWidgets({limit: 10000, page: 1}, dispatch),
  );

  const widgetFlows = useMemo(
    () =>
      (!isLoading &&
        data &&
        data?.widgets?.map(widget => ({
          value: widget._id,
          label: widget.name,
          name: widget.name,
        }))) ||
      [],
    [data, isLoading],
  );

  return {widgetFlows, isLoading: isLoading || isFetching};
};
