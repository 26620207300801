import {api} from '../../helpers';

function fetchReport(appIds, params) {
  return api
    .get(`/utils/messaging/logs${appIds && `?app=${appIds}`}`, {params})
    .then(res => res.data);
}

function fetchMessageDetail(params) {
  return api.get('/utils/kedesa/sms/getstatus', {params}).then(res => res.data);
}

export {fetchReport, fetchMessageDetail};
