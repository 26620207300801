import React from 'react';
import {downloadIc} from '../../../../assets/images/images';
import {generatePDF, generatePDFStructure} from './generatePDFStructure';

function GeneratePDF({documentData, subTitle}) {
  const content = generatePDFStructure(documentData);
  return (
    <button
      onClick={() => generatePDF(content, subTitle)}
      className="flex items-center gap-2 text-white text-sm font-medium bg-brandBlue h-12 w-fit hover:bg-btnHover duration-200 transition rounded px-6"
    >
      <img src={downloadIc} alt="" width={18} height={18} />
      <p className="-mb-1">Download SAR</p>
    </button>
  );
}

export default GeneratePDF;
