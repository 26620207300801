import React from 'react';
const PIN_LENGTH = 4; // Assuming PIN is 4 digits

export const PinInput = ({label, name, value, onChange, isError}) => (
  <label htmlFor={name}>
    <span className="text-sm">{label}</span>
    <input
      type="password"
      inputMode="numeric"
      id={name}
      name={name}
      className={`mt-2 ${isError ? 'outline outline-1 outline-danger' : ''}`}
      // placeholder={`Enter ${PIN_LENGTH}-digit ${label}`}
      placeholder={`****`}
      value={value}
      onChange={e => onChange(name, e.target.value)}
      maxLength={PIN_LENGTH}
      pattern="\d*"
      autoComplete="off"
    />
  </label>
);
