export const getStatusCodeDescription = statusCode => {
  switch (statusCode) {
    case '200':
      return {color: '#1EAE98', statusLabel: 'Successful'};
    case '400':
      return {color: '#A9DFFF', statusLabel: 'Bad request'};
    case '402':
      return {color: '#A9DFFF', statusLabel: 'Balance is low'};
    case '404':
    case '408':
      return {color: '#F1D7D1', statusLabel: 'Not found'};
    case '500':
      return {color: '#DFDFFC', statusLabel: 'Server error'};
    case '403':
      return {color: '#F1D7D1', statusLabel: 'Session not found'};
    case '424':
    case '503':
      return {color: '#FCEBDB', statusLabel: 'Unable to reach service'};
    case '401':
      return {
        color: '#ABD7F1',
        statusLabel: "Secret key couldn't be authorized",
      };
    case '502':
    case '504':
      return {color: '#AABDDE', statusLabel: 'Gateway timeout'};
    case '405':
      return {color: '#FDCA40', statusLabel: 'Method not allowed'};
    case '201':
      return {color: '#D6D6FF', statusLabel: 'Empty string'};

    default:
      return 'Unknown';
  }
};
