import React from 'react';
import {Dropdown, Radios} from '../../../../components';
import {chartPeriod} from '../constants';
import {CalenderIcon} from './CalenderIcon';
export const Period = ({date, setDate, datePeriod, otherChartPeriod}) => (
  <div className="relative">
    <Dropdown
      dropdownBtn={
        <div
          className={`${
            date
              ? 'bg-brandBlue border-brandBlue'
              : 'border-grey-100 hover:bg-white'
          } flex  border  items-center gap-3  py-[11px] px-3 transition-all rounded-[10px]  cursor-pointer`}
        >
          <span
            className={`text-sm w-fit font-inter font-medium capitalize text-body ${
              date && 'text-white'
            }`}
          >
            {`${date || 'Period'} ${datePeriod ? '-' : ''} ${
              datePeriod?.replaceAll('_', ' ') || ''
            }`}
          </span>

          <CalenderIcon color={date && 'white'} />
        </div>
      }
    >
      <div className="absolute z-10 -right-6 top-9">
        <Radios
          className="w-64 mt-2 p-3 px-3 rounded"
          data={otherChartPeriod || chartPeriod}
          filterName="date"
          onCheck={e => setDate(e.target.value)}
          onClick={e => setDate(e.target.value)}
          radioValue={date}
          padding="px-3"
        >
          {date && (
            <div
              className="mt-3 mx-4 cursor-pointer  w-fit p-2 py-1 border border-grey-100  rounded-[6px] "
              onClick={() => setDate(null)}
            >
              <p className="text-sm text-body">Reset</p>
            </div>
          )}
        </Radios>
      </div>
    </Dropdown>
  </div>
);
