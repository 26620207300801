import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {fetchProjects} from '../../../../requests/queries/reconciliations';
import {useSelector} from 'react-redux';
import {getCookieValue} from '../../../../helpers/getCookie';

export const useProjects = (limit = 10) => {
  const {userDetails} = useSelector(state => state?.auth);
  const env = getCookieValue('env');
  const environment = env === 'Production' ? 'live' : env;
  const companyId = useMemo(
    () => userDetails && userDetails?.company?.id,
    [userDetails],
  );

  const params = useMemo(
    () => ({
      company_id: companyId,
      env: environment?.toLowerCase(),
      limit,
    }),
    [companyId, environment, limit],
  );
  const {data: projects, isLoading} = useQuery(
    ['projects', {...params}],
    () => fetchProjects({params}),
    {
      enabled: companyId?.length > 0,
    },
  );

  const projectData = useMemo(
    () => !isLoading && projects && projects?.rows,
    [isLoading, projects],
  );
  const projectSelectionData =
    projectData &&
    projectData.map(project => ({
      name: project?.name,
      value: project?.id,
      type: project?.type,
    }));
  return {projectData, projectSelectionData, isLoading};
};
