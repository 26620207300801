import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';

const AssessmentGaugeChart = ({score}) => (
  <div className="flex items-center flex-col justify-center rounded bg-white80 xl:bg-white  w-full">
    <div className="flex w-full justify-end px-10 pt-2">
      <p className="text-[40px]">{score}</p>
    </div>
    <ReactSpeedometer
      value={score}
      needleHeightRatio={0.7}
      labelFontSize={'12px'}
      valueTextFontSize={'23px'}
      textColor="grey"
      height={250}
      width={350}
      ringWidth={35}
      paddingHorizontal={10}
      forceRender={true}
      needleColor="#333333"
      minValue={100}
      maxValue={700}
    />
  </div>
);
export default AssessmentGaugeChart;
