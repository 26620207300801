import React, {createContext, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {fetchCompanyPrimaryColor} from '../fetchCompanyLogoAndColor';
import {Loader} from '../../components';

const ColorContext = createContext();

function ColorProvider({children}) {
  const {data: color = '#3F7CDB', status} = useQuery(
    'companyPrimaryColor',
    fetchCompanyPrimaryColor,
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (status === 'success') {
      const storedColor = localStorage.getItem('companyColor');

      if (!storedColor || storedColor !== color) {
        document.body.style.setProperty('--color-primary', color);
        localStorage.setItem('companyColor', color);
      } else {
        document.body.style.setProperty('--color-primary', storedColor);
      }

      setIsLoading(false);
    }
  }, [color, status]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ColorContext.Provider value={color}>{children}</ColorContext.Provider>
  );
}

export {ColorContext, ColorProvider};
