import React, {useEffect, useRef, useState} from 'react';
import {Modal, PrimaryButton, SecondaryButton} from '../../components';
import {
  camera,
  check,
  infoCircle,
  infoCircleRed,
} from '../../assets/images/images';
import {toast} from 'react-toastify';

function ModalWrapper({children, open, setOpen, modalTitle}) {
  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[339px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center items-center"
      modalTitle={modalTitle ?? 'Capture documents'}
    >
      {children}
    </Modal>
  );
}

const CaptureIdComponent = ({
  open,
  setOpen,
  setUploadType,
  setStep,
  setCapturedImage,
  capturedImage,
  imageRef,
  title,
  type,
}) => {
  const videoRef = useRef(null);
  const [isCapturing, setIsCapturing] = useState(true);

  const startCapture = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({video: true});
      videoRef.current.srcObject = stream;
      setIsCapturing(true);
    } catch (error) {
      console.error('Error accessing camera:', error);

      if (error.name === 'NotAllowedError' || error.name === 'NotFoundError') {
        // User denied access to the camera or camera not found
        const permissionDeniedMessage =
          'Access to the camera is required to capture images.';
        const cameraNotFoundMessage =
          'No camera found. Please make sure your camera is connected and try again.';

        const errorMessage =
          error.name === 'NotAllowedError'
            ? permissionDeniedMessage
            : cameraNotFoundMessage;

        // You can display the errorMessage to the user or use another UI component like a toast
        // Example using toast:
        toast.error(errorMessage);

        // Optionally, you can prompt the user to check their camera settings
        // Example using window.confirm:
        const checkCameraSettings = window.confirm(
          'Please check your camera settings and try again.',
        );

        if (checkCameraSettings) {
          // You can redirect the user to their browser's camera settings or provide instructions
          // window.location.href = 'browser-camera-settings-url';
        }
      }
    }
  };

  const stopCapture = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
    setIsCapturing(false);
  };

  const captureImage = async () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;

      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      const capturedImageData = canvas.toDataURL('image/jpeg');
      setCapturedImage(capturedImageData);
      stopCapture();
    }
  };

  useEffect(() => {
    startCapture();

    return () => {
      stopCapture();
    };
  }, []);

  return (
    <ModalWrapper open={open} setOpen={setOpen} modalTitle={title}>
      <div className="mt-6 flex flex-col">
        <video
          ref={videoRef}
          autoPlay
          muted
          style={{display: isCapturing ? 'block' : 'none'}}
          className="rounded-lg h-[289px] object-cover"
        ></video>

        {capturedImage && (
          <img
            ref={imageRef}
            src={capturedImage}
            alt="Captured Preview"
            className="rounded-lg h-[289px] object-cover"
          />
        )}

        {type === 'front' ? (
          <div className="mt-6 flex items-center gap-2 pt-[5px] pb-[3px] px-2 rounded-full bg-success20">
            <img src={infoCircle} alt="" width={20} height={20} />
            <span className="text-success text-xs leading-normal">
              Make sure your document is properly placed, and hold it still for
              a few seconds
            </span>
          </div>
        ) : (
          <div className="mt-6 flex items-center gap-2 pt-[5px] pb-[3px] px-2 rounded-full bg-statusBg-404">
            <img src={infoCircleRed} alt="" width={20} height={20} />
            <span className="text-status-404 text-xs leading-normal">
              Please make sure you are in a well lit environment
            </span>
          </div>
        )}

        <div className="mt-6 flex flex-col items-center gap-2 w-full">
          <PrimaryButton
            type="submit"
            className="w-full"
            buttonText="Capture"
            onClick={() => {
              captureImage();
              setStep(prevStep => prevStep + 1);
            }}
          />
          <SecondaryButton
            type="button"
            className="w-full"
            buttonText="Upload instead"
            onClick={() => setUploadType('upload')}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

function PreviewId({
  open,
  setOpen,
  setStep,
  capturedImage,
  setCapturedImage,
  imageRef,
  submitId,
  disabled,
  loading,
}) {
  return (
    <ModalWrapper open={open} setOpen={setOpen} modalTitle="Preview your ID">
      <div className="mt-6 flex flex-col">
        {capturedImage && (
          <img
            ref={imageRef}
            src={capturedImage}
            alt="Captured Preview"
            className="rounded-lg h-[289px] object-cover"
          />
        )}

        <div className="text-start mt-6 rounded flex flex-col gap-3">
          <div className="flex items-center gap-2">
            <img src={check} alt="" width={16} height={16} />
            <span className="text-body text-sm">
              Please take a moment to review the captured image to ensure it is
              clear and readable.
            </span>
          </div>
          <div className="flex items-center gap-2">
            <img src={check} alt="" width={16} height={16} />
            <span className="text-body text-sm">
              Make sure the entire document or ID card is fully visible within
              the frame.
            </span>
          </div>
          <div className="flex items-center gap-2">
            <img src={check} alt="" width={16} height={16} />
            <span className="text-body text-sm">
              Look out for any reflections that could obstruct the document’s
              visibility.
            </span>
          </div>
        </div>

        <div className="mt-6 flex flex-col items-center gap-2 w-full">
          <PrimaryButton
            type="submit"
            className="w-full"
            buttonText="Continue"
            onClick={submitId}
            loading={loading}
            disabled={disabled}
          />
          <SecondaryButton
            type="button"
            className="w-full"
            buttonText="Retake"
            onClick={() => {
              setCapturedImage(null);
              setStep(prevStep => prevStep - 1);
            }}
          />
        </div>
      </div>
    </ModalWrapper>
  );
}

export default function CaptureModal({open, setOpen, setUploadType, ...props}) {
  const {
    capturedFrontImage,
    setCapturedFrontImage,
    capturedBackImage,
    setCapturedBackImage,
    lookupInputs,
    handleAnalysis,
    disabled,
    loading,
  } = props;
  const [step, setStep] = useState(1);
  const frontImageRef = useRef(null);
  const backImageRef = useRef(null);

  const handleCapture = (imageRef, setCapturedImage, capturedImage, type) => (
    <CaptureIdComponent
      open={open}
      setOpen={setOpen}
      setUploadType={setUploadType}
      setStep={setStep}
      setCapturedImage={setCapturedImage}
      capturedImage={capturedImage}
      imageRef={imageRef}
      title={`Capture the ${type} of your ID`}
      type={type}
    />
  );

  switch (step) {
    case 2:
      return handleCapture(
        frontImageRef,
        setCapturedFrontImage,
        capturedFrontImage,
        'front',
        () => setStep(3),
      );

    case 3:
      return (
        <PreviewId
          open={open}
          setOpen={setOpen}
          setStep={setStep}
          capturedImage={capturedFrontImage}
          setCapturedImage={setCapturedFrontImage}
          imageRef={frontImageRef}
          lookupInputs={lookupInputs}
          submitId={async () => {
            if (lookupInputs.type?.value === 'dl') {
              setStep(nextStep => nextStep + 1);
            } else {
              await handleAnalysis();
            }
          }}
          disabled={disabled}
          loading={loading}
        />
      );

    case 4:
      return handleCapture(
        backImageRef,
        setCapturedBackImage,
        capturedBackImage,
        'back',
        () => setStep(5),
      );

    case 5:
      return (
        <PreviewId
          open={open}
          setOpen={setOpen}
          setStep={setStep}
          capturedImage={capturedBackImage}
          setCapturedImage={setCapturedBackImage}
          imageRef={backImageRef}
          lookupInputs={lookupInputs}
          submitId={async () => {
            await handleAnalysis();
          }}
          disabled={disabled}
          loading={loading}
        />
      );

    default:
      return (
        <ModalWrapper
          open={open}
          setOpen={setOpen}
          modalTitle="Capture documents"
        >
          <div className="mt-6 flex flex-col">
            <img
              src={camera}
              alt=""
              width={57}
              height={57}
              className="self-center"
            />

            <div className="text-start mt-6 p-4 bg-white80 rounded flex flex-col gap-3">
              <p className="text-grey text-sm font-medium">
                Please note the following
              </p>
              <div className="flex items-center gap-2">
                <img src={check} alt="" width={16} height={16} />
                <span className="text-body text-sm">
                  You must be in a well lit environment before getting started.
                </span>
              </div>
              <div className="flex items-center gap-2">
                <img src={check} alt="" width={16} height={16} />
                <span className="text-body text-sm">
                  Avoid wearing anything that hinders or hides your face from
                  being seen.
                </span>
              </div>
              <div className="flex items-center gap-2">
                <img src={check} alt="" width={16} height={16} />
                <span className="text-body text-sm">
                  All parts of your identification must be shown properly and
                  must be clear
                </span>
              </div>
            </div>
          </div>

          <div className="mt-6 flex flex-col items-center gap-2 w-full">
            <PrimaryButton
              type="submit"
              className="w-full"
              buttonText="Continue"
              onClick={() => setStep(2)}
            />
            <SecondaryButton
              type="button"
              className="w-full"
              buttonText="Upload instead"
              onClick={() => setUploadType('upload')}
            />
          </div>
        </ModalWrapper>
      );
  }
}
