/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Listbox, Transition} from '@headlessui/react';
import PuffLoader from 'react-spinners/PuffLoader';
import {css} from '@emotion/react';
import {dropdownGrey} from '../../../../assets/images/images';
import {useProjects} from '../hooks/useProjects';

export default function ProjectDropdown({children}) {
  const override = css`
    border-color: #ffffff;
    position: absolute;
    background: transparent;
    padding-right: 50px;
  `;
  const [selected, setSelected] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const {
    isLoading: projectLoading,
    projectSelectionData,
    projectData,
  } = useProjects(10000);

  const currentId = sessionStorage.getItem('project_id');

  const foundData = useMemo(
    () =>
      !projectLoading &&
      currentId &&
      projectData?.find(data => data?.id === currentId),
    [currentId, projectLoading, projectData],
  );

  useEffect(() => {
    if (foundData) {
      setSelected(foundData);
    } else if (!foundData && projectSelectionData?.length > 0) {
      setSelected(projectSelectionData[0]);
    }
  }, [foundData]);

  return (
    <div className="max-w-72">
      <Listbox
        value={selected}
        onChange={data => {
          setSelected(data);
          sessionStorage.setItem('project_id', String(data?.value));
          const url = location.pathname.replace(/[^/]+$/, data?.value);
          navigate(url);
        }}
      >
        <div className="relative">
          <Listbox.Button className="relative w-full flex gap-2 items-center justify-between rounded-t-lg md:rounded cursor-pointer bg-white py-[10px] px-[14px] text-left border border-[#CECED2]">
            {projectLoading ? (
              <div className="flex items-center justify-center ml-5 ">
                <PuffLoader css={override} size={35} color="#ffffff" />
              </div>
            ) : (
              <span className="font-medium text-sm text-grey font-inter">
                {foundData?.name || selected?.name || 'No Project Found'}
              </span>
            )}
            <img src={dropdownGrey} alt="" />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`w-[195px] max-h-[300px] overflow-y-scroll listbox-scroll md:absolute md:left-0 z-10 md:mt-[2px] flex flex-col rounded-b-lg bg-white border border-[#CECED2] py-4 px-4 rounded-lg`}
            >
              {projectSelectionData?.length > 0 && (
                <p className="hidden text-sm text-black mb-4 font-medium md:block border-b border-b-white">
                  Switch project
                </p>
              )}
              {!projectLoading &&
                projectSelectionData &&
                projectSelectionData?.map((data, idx) => (
                  <Listbox.Option
                    key={idx}
                    className={style =>
                      `relative flex items-center justify-between cursor-pointer font-normal select-none my-1 rounded ${
                        style?.active || data.name === selected?.name
                          ? 'bg-white'
                          : ''
                      } `
                    }
                    value={data}
                  >
                    <p
                      className={`w-full text-sm px-4 py-2 text-[#242424] truncate hover:text-brandBlue ${
                        data.name === selected?.name
                          ? 'text-brandBlue '
                          : 'md:text-[#242424]'
                      }`}
                    >
                      {data.name || 'Select project'}
                    </p>
                  </Listbox.Option>
                ))}
              {children}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
