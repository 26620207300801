export const collapseNestedObject = (obj = {}) => {
  const result = {};
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      const nestedObject = collapseNestedObject(obj[key]);
      for (const nestedKey in nestedObject) {
        result[`${nestedKey}`] = nestedObject[nestedKey];
      }
    } else {
      result[key] = obj[key];
    }
  }

  return result;
};
