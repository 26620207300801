/* eslint-disable react/jsx-indent */

import React, {useMemo, useState} from 'react';
import {connect} from 'react-redux';
// import pdfMake from 'pdfmake/build/pdfmake';
import {closeModal, enlargeIc, placeholder} from '../../assets/images/images';
import * as Actions from '../../store/actions';
import {
  Loader,
  Modal,
  // PrimaryButton
} from '../../components';
// import {convertSVGToBase64} from '../../helpers/convertSVGToBase64';
// import {generateAmlPDF} from '../AMLScreening/generatePDF';
// import {generatePDF} from '../EasyLookup/generatePDFStructureForEasyLookup';

// async function imageUrlToBase64(url) {
//   return fetch(url)
//     .then(response => response.blob())
//     .then(
//       blob =>
//         new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = () => resolve(reader.result);
//           reader.onerror = reject;
//           reader.readAsDataURL(blob);
//         }),
//     );
// }

const ImageComponent = ({imageUrl, imageText}) => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const toggleImageSize = () => {
    setIsEnlarged(prevState => !prevState);
  };

  return (
    <div className="relative w-full">
      {imageText && (
        <p className="text-xs mb-2 text-left font-medium uppercase">
          {imageText}
        </p>
      )}

      <img
        src={imageUrl}
        alt=""
        className={`mx-auto w-full ${
          isEnlarged ? 'h-full' : 'max-h-[130px]'
        } object-cover rounded-lg`}
      />
      {imageUrl && (
        <div className="absolute bottom-2 right-2 flex gap-2">
          <button
            className="flex items-start gap-2 p-1 text-xs font-medium rounded text-grey bg-white80"
            onClick={toggleImageSize}
          >
            <span className="-mb-[2px]">
              {isEnlarged ? 'Shrink image' : 'Enlarge image'}
            </span>
            <img src={enlargeIc} alt="" className="-mt-[2px]" />
          </button>
        </div>
      )}
    </div>
  );
};

const sortData = (arr = [], sortList = [], sortKey = '') =>
  arr?.sort(
    (a, b) => sortList?.indexOf(a[sortKey]) - sortList?.indexOf(b[sortKey]),
  );

const ResultsModal = ({
  open,
  setOpen,
  // auth: {user},
  results,
  lookup: {loading},
}) => {
  // const [base64URL, setBase64URL] = useState('');
  // const [frontImage, setFrontImage] = useState(null);
  // const [backImage, setBackImage] = useState(null);
  // const [parsingImage, setParsingImage] = useState(true);

  // useEffect(() => {
  //   convertSVGToBase64().then(logo => setBase64URL(logo));
  // }, []);

  const entity = results?.entity || results?.entity?.detail;
  const document_type = entity?.document_type || entity?.detail?.document_type;
  const status = entity?.status || entity?.detail?.status;
  const images = entity?.document_images || entity?.detail?.document_images;
  const text_data = entity?.text_data || entity?.detail?.text_data;
  const filterData = useMemo(
    () => [
      'first_name',
      'last_name',
      'middle_name',
      'given_names',
      'address',
      'nationality',
      'place_of_birth',
      'dob',
      'age',
      'sex',
    ],
    [],
  );
  const filterStatusData = useMemo(
    () => ['overall_status', 'reason', 'document_images'],
    [],
  );
  const arrangeStatus = useMemo(() => ['document_type', 'expiry', 'text'], []);

  const customerInfo = useMemo(() => {
    const customerData = text_data?.filter(data =>
      filterData.includes(data?.field_key),
    );
    return customerData?.reduce((acc, item) => {
      acc[item?.field_key] = item?.value;
      return acc;
    }, {});
  }, [filterData, text_data]);
  const documentInfo = useMemo(() => {
    const docData = text_data?.filter(
      data => !filterData.includes(data?.field_key),
    );
    return docData?.reduce((acc, item) => {
      acc[item?.field_key] = item?.value;
      return acc;
    }, {});
  }, [filterData, text_data]);

  const statusData = Object.entries(status || {})
    .filter(([key]) => !filterStatusData.includes(key))
    .map(([key, value]) => ({
      [key]: value,
      sortKey: key,
    }));

  const statusInfo = useMemo(
    () =>
      sortData(statusData, arrangeStatus, 'sortKey')?.reduce((acc, item) => {
        const itemKey =
          item['sortKey'] === 'expiry'
            ? 'document_expired'
            : item['sortKey'] === 'text'
            ? 'text_fields'
            : item['sortKey'];
        acc[itemKey] = item[item.sortKey] || '-';
        return acc;
      }, {}),
    [statusData, arrangeStatus],
  );

  const customerInfoFormatted = useMemo(() => {
    if (customerInfo) {
      return [
        {
          leftText: 'Full Name',
          rightText:
            customerInfo?.given_names ||
            `${customerInfo?.first_name || ''} ${
              customerInfo?.last_name || ''
            } ${customerInfo?.middle_name || ''}` ||
            '-',
        },
        {
          leftText: 'Sex',
          rightText: customerInfo?.sex || '-',
        },
        {
          leftText: 'Date of birth',
          rightText: customerInfo?.dob || '-',
        },
        {
          leftText: 'Age',
          rightText: customerInfo?.age || '-',
        },
        {
          leftText: 'Address',
          rightText: customerInfo?.address || '-',
        },
        {
          leftText: 'Place of birth',
          rightText: customerInfo?.place_of_birth || '-',
        },
        {
          leftText: 'Nationality',
          rightText: customerInfo?.nationality || '-',
        },
      ];
    }
  }, [customerInfo]);

  const documentInfoFormatted = useMemo(() => {
    if (documentInfo) {
      return [
        {
          leftText: 'Document Name',
          rightText:
            documentInfo?.document_name || document_type?.document_name || '-',
        },
        {
          leftText: 'Document number',
          rightText: documentInfo?.document_number || '-',
        },
        {
          leftText: 'date of expiry',
          rightText: documentInfo?.expiry_date || '-',
        },
        {
          leftText: 'Date issued',
          rightText: documentInfo?.issue_date || '-',
        },
        {
          leftText: 'Country',
          rightText: documentInfo?.issuing_state_name || '-',
        },
        {
          leftText: 'Country code',
          rightText: documentInfo?.issuing_state_code || '-',
        },
      ];
    }
  }, [documentInfo, document_type]);

  // useEffect(() => {
  //   async function getImages() {
  //     setParsingImage(true);
  //
  //     const frontImageUrl = images?.images_front_side_url || entity?.front_url;
  //     const backImageUrl = images?.images_back_side_url || entity?.back_url;
  //
  //     const imagePromises = [];
  //
  //     if (frontImageUrl) {
  //       imagePromises.push(
  //         imageUrlToBase64(frontImageUrl).then(frontImage =>
  //           setFrontImage(frontImage),
  //         ),
  //       );
  //     }
  //
  //     if (backImageUrl) {
  //       imagePromises.push(
  //         imageUrlToBase64(backImageUrl).then(backImage =>
  //           setBackImage(backImage),
  //         ),
  //       );
  //     }
  //
  //     try {
  //       await Promise.all(imagePromises);
  //     } finally {
  //       setParsingImage(false);
  //     }
  //   }
  //
  //   getImages();
  // }, [
  //   entity?.back_url,
  //   entity?.front_url,
  //   images?.images_back_side_url,
  //   images?.images_front_side_url,
  // ]);

  // const pdfData = [
  //   {
  //     title: 'Document',
  //     data: documentInfo,
  //   },
  //   {
  //     title: 'Profile',
  //     data: customerInfo,
  //   },
  // ];

  // pdfMake.pageLayout = {
  //   height: 792,
  //   width: 612,
  //   orientation: 'portrait',
  //   margins: [40, 40, 40, 40],
  // };

  // const handlePdfDownload = () => {
  //   const documentName = document_type?.document_name;
  //   const content = generateAmlPDF({
  //     pdfData,
  //     pdfArray: pdfData,
  //     page: documentName,
  //   });
  //
  //   const headerEndIndex = content.findIndex(item => item.style === 'type');
  //
  //   const frontImageUrl = images?.images_front_side_url || entity?.front_url;
  //   const backImageUrl = images?.images_back_side_url || entity?.back_url;
  //   const imgColumns = [];
  //
  //   if (frontImageUrl) {
  //     if (backImageUrl) {
  //       const gap = 16;
  //
  //       if (frontImage) {
  //         const frontImageColumn = {
  //           image: frontImage,
  //           width:
  //             pdfMake.pageLayout.width / 2 -
  //             pdfMake.pageLayout.margins[1] -
  //             gap,
  //         };
  //
  //         imgColumns.push(frontImageColumn);
  //       }
  //
  //       if (backImage) {
  //         const backImageColumn = {
  //           image: backImage,
  //           width:
  //             pdfMake.pageLayout.width / 2 -
  //             pdfMake.pageLayout.margins[3] -
  //             gap,
  //         };
  //         imgColumns.push(backImageColumn);
  //       }
  //       content.splice(headerEndIndex + 1, 0, {
  //         columns: imgColumns,
  //         columnGap: 20,
  //       });
  //     } else {
  //       if (frontImage) {
  //         const frontImageColumn = {
  //           image: frontImage,
  //           width: pdfMake.pageLayout.width / 2 - pdfMake.pageLayout.margins[1],
  //         };
  //         imgColumns.push(frontImageColumn);
  //
  //         content.splice(headerEndIndex + 1, 0, {columns: imgColumns});
  //       }
  //     }
  //   }
  //
  //   const pdfFooter = {
  //     name: user && `${user?.first_name} ${user?.last_name}`,
  //     generated: new Date(),
  //     logo: base64URL,
  //   };
  //
  //   generatePDF(content, pdfFooter, 'Document Analysis');
  // };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] rounded-t-none sm:rounded-lg absolute bottom-0 sm:relative h-fit max-h-full overflow-y-auto lookup-modal"
      modalPosition="justify-center sm:justify-end"
      fullScreenModal
    >
      {loading ? (
        <Loader height={25} />
      ) : (
        <div className="py-6 sm:py-12 px-5 sm:px-6">
          <header className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <h3 className="text-grey text-base font-medium capitalize">
                {document_type?.document_name}
              </h3>
              <div
                className={`uppercase pt-[5px] pb-[3px] px-2 text-xs font-medium rounded-full w-fit ${
                  status?.overall_status === 1
                    ? 'text-success bg-success20'
                    : status?.overall_status === 0
                    ? 'text-status-404 bg-statusBg-404'
                    : 'bg-grey5'
                }`}
              >
                <span>{status?.reason}</span>
              </div>
            </div>
            <button onClick={() => setOpen(false)}>
              <img src={closeModal} alt="" />
            </button>
          </header>
          <div className="mt-8 flex items-center gap-4">
            <ImageComponent
              imageText="front of document"
              imageUrl={
                images?.images_front_side_url ||
                images?.document_front_side ||
                entity?.front_url
              }
            />

            {images?.image_back_side_url ||
            images?.document_back_side ||
            entity?.back_url ? (
              <ImageComponent
                imageText="back of document"
                imageUrl={
                  images?.image_back_side_url ||
                  images?.document_back_side ||
                  entity?.back_url
                }
              />
            ) : null}
          </div>
          <div className="mt-8 px-4 py-4 sm:p-6 bg-white80 -mx-4 sm:mx-0 rounded">
            <p className="text-sm text-left ">Overall status</p>
            {Object.entries(statusInfo || {}).map(([key, value]) => (
              <div
                key={key}
                className="py-4 px-4 sm:px-6 flex items-center justify-between border-b border-grey60"
              >
                <p className="text-xs text-grey font-medium uppercase text-left">
                  {key.replaceAll('_', ' ')}
                </p>

                <p className="text-small text-body w-100 text-right">
                  {value || '-'}
                </p>
              </div>
            ))}
          </div>
          <div className="mt-8 px-4 py-4 sm:p-6 bg-white80 -mx-4 sm:mx-0 rounded">
            <div className="flex items-center justify-center mb-5 rounded">
              <img
                src={
                  (images?.portrait &&
                    `data:image/png;base64,${images?.portrait || ''}`) ||
                  placeholder
                }
                alt="Photo"
                width={100}
                height={100}
                className="text-small rounded"
              />
            </div>
            <p className="text-sm text-left mb-5">Customer information</p>
            {customerInfoFormatted?.map((item, index) => (
              <div
                key={index}
                className="py-4 px-4 sm:px-6 flex items-center justify-between border-b border-grey60"
              >
                <p className="text-xs text-grey font-medium uppercase text-left">
                  {item?.leftText}
                </p>

                <p className="text-small text-body w-100 text-right">
                  {item?.rightText || '-'}
                </p>
              </div>
            ))}
          </div>{' '}
          <div className="mt-8 px-4 py-4 sm:p-6 bg-white80 -mx-4 sm:mx-0 rounded">
            <p className="text-sm text-left mb-5">Document information</p>
            {documentInfoFormatted?.map((item, index) => (
              <div
                key={index}
                className="py-4 px-4 sm:px-6 flex items-center justify-between border-b border-grey60"
              >
                <p className="text-xs text-grey font-medium uppercase text-left">
                  {item?.leftText}
                </p>
                <p className="text-small text-body w-100 text-right">
                  {item?.rightText || '-'}
                </p>
              </div>
            ))}
          </div>
          {/*<PrimaryButton*/}
          {/*  buttonText="Download"*/}
          {/*  className="mt-8 w-full"*/}
          {/*  onClick={handlePdfDownload}*/}
          {/*  loading={parsingImage}*/}
          {/*/>*/}
        </div>
      )}
    </Modal>
  );
};
export default connect(state => state, Actions)(ResultsModal);
