import React, {useState} from 'react';
import {Modal, PrimaryButton} from '../../components';
import {closeModal} from '../../assets/images/images';
import * as Actions from '../../store/actions';
import {connect} from 'react-redux';
import {encryptData} from '../../helpers/encryptData';

function RevealKeyModal({
  auth: {loading},
  open,
  setOpen,
  setRevealKey,
  viewSecret,
}) {
  const [password, setPassword] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();

    if (password) {
      const encryptedLoginData = encryptData(
        JSON.stringify({
          password,
        }),
      );
      await viewSecret({payload: encryptedLoginData}, () => {
        setRevealKey(true);
        setOpen(false);
      });
    }
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-grey text-base font-medium">
            Please confirm password to continue
          </h3>
          <button onClick={() => setOpen(false)}>
            <img src={closeModal} alt="" />
          </button>
        </header>

        <form onSubmit={handleSubmit} className="mt-6 text-left">
          <label htmlFor="dwa" className="mt-6">
            <span className="text-sm ">Confirm password</span>
            <input
              onChange={e => setPassword(e.target.value)}
              type="password"
              id="dwa"
              className="mt-2"
            />
          </label>

          <div className="flex flex-col items-center mt-6">
            <PrimaryButton
              type="submit"
              loading={loading}
              buttonText="Confirm password"
              className="w-full"
            />
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="mt-[10.5px] md:mt-4 py-4 md:py-0 text-grey80 text-sm md:text-base font-medium w-full"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(RevealKeyModal);
