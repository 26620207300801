import React from 'react';
import {widgetSelfie, widgetSteps} from '../../../assets/images/images';

export const Selfie = () => (
  <div className="mt-8 overflow-y-auto">
    <p className="text-center font-medium text-grey">Say cheese!</p>
    <div className="mx-auto mt-8 mb-4 w-fit rounded-full bg-batchBlue20 text-center">
      <span className="px-2 py-1 text-xs font-medium text-batchBlue">
        This looks good
      </span>
    </div>
    <div className="mt-10 flex flex-col items-center w-full gap-6">
      <img src={widgetSelfie} alt="" width={246.88} height={246.06} />
      <img src={widgetSteps} alt="" width={232.5} height={18} />
    </div>
  </div>
);
