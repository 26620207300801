/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import {connect, useDispatch} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  copyGrey,
  corruptedFile,
  easyOnboardIc,
  edit,
  link,
  trashRed,
} from '../../assets/images/images';
import {
  EmptyState,
  Filter,
  FilterButton,
  PageLimit,
  Pagination,
  PrimaryButton,
  SearchInput,
  TableLayout,
} from '../../components';
import DashboardLayout from '../../components/DashboardLayout';
import {Constants, filterOutEmptyValues, parseUrlParams} from '../../helpers';
import * as Actions from '../../store/actions';
import Loader from '../../components/Loader';
import {DEFAULT_PAGES} from './defaultPages';
import {EasyOnboardTour} from '../../components/Tour';
import {PageContext, TourContext} from '../../helpers/context';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../helpers/formatNumberToCurrency';
import {getCookieValue} from '../../helpers/getCookie';
import Dropdown from '../../components/TeamsDropdown';
import {Menu} from '@headlessui/react';
import DeleteFlowModal from './ConfirmFlowDelete';
import {useQuery} from 'react-query';
import {fetchAllWidgets} from '../../requests/queries/widgets';
import {useOnboardFlowApps} from '../../hooks/useApps';
import {EasyOnboardContext} from '../../helpers/context/EasyOnboardContext';

const filterOptions = [
  {
    title: 'Status',
    name: 'status',
    values: [
      {label: 'Draft', value: 'draft', name: 'draft'},
      {label: 'Published', value: 'published', name: 'published'},
      {label: 'Unpublished', value: 'unpublished', name: 'published'},
    ],
  },
];
function EasyOnboard({
  widgets: {defaultWigetsPages, w_loading, singleWidget},
  auth: {userDetails, userSettings},
  getWidgetPricing,
  getWidget,
  setWidgetDefaultPages,
  setFraudDefaultPages,
  clearWidgetPages,
  setFraudRules,
  setSingleWidget,
  setWidgetPages,
}) {
  const navigate = useNavigate();
  const {setQuestions} = useContext(EasyOnboardContext);
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [widgetId, setWidgetId] = useState(false);
  const [filterSelectedOptions, setFilterSelectedOptions] = useState({});
  const {setPageName} = useContext(PageContext);
  const [selectedApp, setSelectedApp] = useState(0);

  useEffect(() => {
    setPageName('easyonboard');
  }, [setPageName]);

  const envData = getCookieValue('env');

  const {userApps, isLoading: appLoading} = useOnboardFlowApps();

  useEffect(() => {
    if (userApps.length > 0 && !selectedApp) {
      setSelectedApp(userApps[0]);
    }
  }, [userApps, selectedApp]);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const apiParams = parseUrlParams();
  apiParams.company_id = userDetails?.company?.id;
  apiParams.app_ids = selectedApp?.value;
  apiParams.limit = limit;

  const filteredOptions = filterOutEmptyValues(filterSelectedOptions);
  // const queryParams = generateQueryParams(filteredOptions);
  const filters = Object.entries(filteredOptions).reduce(
    (acc, [key, value]) => ({...acc, [key]: value.toLocaleString()}),
    {},
  );
  const {data: allWidgets, isLoading} = useQuery(
    ['all-widgets', {...apiParams, ...filters}],
    () => fetchAllWidgets({...apiParams, ...filters}, dispatch),
  );
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(parseInt(pageParam));
      apiParams.page = parseInt(pageParam);
    } else {
      apiParams.page = currentPage;
    }
    getWidgetPricing();
    if (
      Object.keys(apiParams).length === 2 &&
      'limit' in apiParams &&
      'page' in apiParams
    ) {
      setFiltersApplied(false);
    } else {
      setFiltersApplied(true);
    }
  }, [
    currentPage,
    getWidgetPricing,
    limit,
    userDetails?.company?.id,
    selectedApp.value,
  ]);

  const handleView = id => {
    setSingleWidget(null);
    setWidgetPages({});
    setQuestions([]);
    setWidgetDefaultPages([]);
    id && getWidget(id, navigate);
  };
  const UPDATE_DEFAULT_PAGES = useMemo(() => DEFAULT_PAGES, []);
  useEffect(() => {
    const basePage = defaultWigetsPages?.length
      ? defaultWigetsPages
      : UPDATE_DEFAULT_PAGES;
    setWidgetDefaultPages(basePage);
    setFraudRules([]);
    setFraudDefaultPages(null);
    !singleWidget?.widget?.pages && clearWidgetPages();
  }, [
    setWidgetDefaultPages,
    clearWidgetPages,
    singleWidget?.widget?.pages,
    UPDATE_DEFAULT_PAGES,
    defaultWigetsPages,
    setFraudDefaultPages,
    setFraudRules,
  ]);
  const handleFilter = selectedOptions => {
    setCurrentPage(1);
    // navigate(`?${queryParams.toString()}&page=1`);
    setFilterSelectedOptions(selectedOptions);
    setFiltersApplied(true);
    setOpenFilter(false);
  };
  const handleResetFilter = () => {
    navigate('?page=1');
    setCurrentPage(1);
    setOpenFilter(true);
    setFilterSelectedOptions({});
  };
  const copyText = text => {
    navigator.clipboard.writeText(text);
    toast.success('Link copied');
  };
  const {manageFlows: canManageFlows} =
    useMemo(
      () => userDetails?.permissions?.onboardPermissions,
      [userDetails],
    ) || {};
  const {tourState, updateTourState} = useContext(TourContext);
  const displayPrice = (condition, price) =>
    condition &&
    userDetails &&
    userDetails.company &&
    userDetails.company.currency === 'USD'
      ? formatUsd(price || 0)
      : formatNumberToCurrency(
          price || 0,
          userDetails && userDetails.company && userDetails.company.currency,
          userSettings &&
            userSettings.settings &&
            userSettings.settings.dollar_exchange_rate,
        );
  const hasWidgetUrl = !!localStorage.subdomain && !!userDetails?.widgetUrl;
  const customWidgetUrl = hasWidgetUrl
    ? userDetails?.widgetUrl
    : userDetails?.company?.widget_url;
  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div
          className="flex items-center"
          onClick={() => navigate('/easy-onboard')}
        >
          <img src={easyOnboardIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Easy onboard</p>
        </div>
      }
    >
      {tourState?.easy_onboard_tour ? (
        <EasyOnboardTour
          updateTourState={updateTourState}
          DATA={allWidgets?.widgets}
          loading={isLoading}
        />
      ) : null}
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
      />
      <DeleteFlowModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        flowId={widgetId}
      />

      {isLoading && appLoading ? (
        <Loader height={85} />
      ) : filtersApplied && !isLoading && allWidgets === undefined ? (
        <EmptyState
          body="This filter did not return any data, try using different values."
          src={corruptedFile}
          imageHeight={48}
          imageWidth={48}
          noBtn
          customBtn={
            <button
              onClick={handleResetFilter}
              className="text-brandBlue p-4 text-sm font-medium"
            >
              Update preferences
            </button>
          }
          maxWidth="max-w-[212px]"
        />
      ) : (
        <>
          <div className="flex flex-col items-start justify-between gap-6 mb-7 md:flex-row md:items-center md:gap-0 overflow-none">
            <div>
              <h2 className="text-base font-medium text-grey sm:text-lg">
                Easy Onboard
              </h2>
              <p className="text-tiny text-grey20">
                View and configure your flows with ease
              </p>
            </div>

            <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
              <SearchInput
                onChange={value =>
                  setFilterSelectedOptions({
                    ...filterSelectedOptions,
                    name: value,
                  })
                }
                className="outline-white80"
              />
              <FilterButton
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
              />
              <Link
                to={Constants.PAGES_URL.EASY_ONBOARD_CREATE_FLOW}
                className="hidden sm:block"
                onClick={() => {
                  setSingleWidget(null);
                  setWidgetPages({});
                  setWidgetDefaultPages(defaultWigetsPages);
                }}
              >
                <PrimaryButton
                  buttonText="Create a flow"
                  xPadding="p-[35px]"
                  disabled={!canManageFlows}
                  style={{cursor: !canManageFlows && 'not-allowed'}}
                  title={
                    canManageFlows
                      ? 'Create a flow'
                      : 'You do not have permission to Create a flow'
                  }
                />
              </Link>
              <Link
                to={Constants.PAGES_URL.EASY_ONBOARD_CREATE_FLOW}
                className="fixed bottom-0 left-0 right-0 z-10 px-4 py-2 overflow-x-hidden sm:hidden bg-white80"
                style={{boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)'}}
                onClick={() => setSingleWidget(null)}
              >
                <PrimaryButton
                  buttonText="Create a flow"
                  xPadding="p-[35px]"
                  className="w-full bottom-2"
                  disabled={!canManageFlows}
                  style={{cursor: !canManageFlows && 'not-allowed'}}
                  title={
                    canManageFlows
                      ? 'Create a flow'
                      : 'You do not have permission to Create a flow'
                  }
                />
              </Link>
            </div>
          </div>

          <div className="flex items-start w-full gap-5">
            <div className="min-w-[183px]">
              <h2 className="text-base font-medium text-grey py-5 h-[52px] flex items-center bg-white80 px-4 uppercase">
                App Name
              </h2>

              {!appLoading && userApps && (
                <div className="mt-[2px] flex flex-col gap-2 p-2 bg-white80 rounded-lg overflow-y-auto h-[calc(100vh-220px)] userApp">
                  {userApps.map((app, index) => (
                    <div
                      key={index}
                      className={`py-3 px-2 rounded-md cursor-pointer ${
                        app.value === selectedApp?.value ? 'text-dojahBlue' : ''
                      }`}
                      onClick={() => setSelectedApp(app)}
                    >
                      <button className="text-[13px] font-medium text-start">
                        {app.label}
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {isLoading ? (
              <div className="w-full h-[65vh]">
                <Loader height={85} />
              </div>
            ) : (
              <div className="w-full mb-6">
                <TableLayout noOverflow className="w-full">
                  <thead className="text-xs font-semibold uppercase">
                    <tr className="bg-white80">
                      <th className="p-5 pl-4 text-xs font-medium text-left sm:pl-6 xl:pl-12 text-grey whitespace-nowrap w-[24.9%]">
                        <div className="flex items-end gap-1">
                          <span>Flow Name</span>
                        </div>
                      </th>
                      <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap w-[18.8%]">
                        <div className="flex items-end gap-1">
                          <span>Status</span>
                        </div>
                      </th>
                      <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap w-[22.9%]">
                        <div className="flex items-end gap-1">
                          <span>date created</span>
                        </div>
                      </th>
                      <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap w-[20%]">
                        <div className="flex items-end gap-1">
                          <span>Cost</span>
                        </div>
                      </th>

                      <th className="w-[9.1%]"></th>
                    </tr>
                  </thead>

                  <tbody
                    className="text-sm bg-white"
                    style={{opacity: w_loading ? 0.4 : 1}}
                  >
                    {allWidgets &&
                      allWidgets?.widgets?.map((widget, i) => (
                        <tr
                          className="border-b  border-grey60"
                          key={i}

                          // onClick={() => setOpen(!open)}
                        >
                          <td
                            onClick={() => {
                              handleView(widget?._id);
                            }}
                            className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap cursor-pointer w-[24.9%]"
                          >
                            {widget?.name || '-'}
                          </td>
                          <td
                            onClick={() => {
                              handleView(widget?._id);
                            }}
                            className="p-4 pl-0 text-sm text-body whitespace-nowrap cursor-pointer w-[18.8%]"
                          >
                            <div
                              className={`px-2 py-1 pt-[7px] text-xs font-medium uppercase rounded-full  w-fit ${
                                widget?.published
                                  ? 'text-success20 bg-success'
                                  : 'text-white bg-black'
                              }`}
                            >
                              {widget?.published ? 'Published' : 'Draft'}
                            </div>
                          </td>
                          <td
                            onClick={() => {
                              handleView(widget?._id);
                            }}
                            className="p-4 pl-0 text-tiny text-body whitespace-nowrap cursor-pointer w-[22.9%]"
                          >
                            {moment(widget?.createdAt).format(
                              'MMM DD, YYYY hh:mm A',
                            ) || '-'}
                          </td>
                          <td
                            onClick={() => {
                              handleView(widget?._id);
                            }}
                            className="p-4 pl-0 text-tiny text-body whitespace-nowrap cursor-pointer w-[20%]"
                          >
                            {envData === 'Production'
                              ? widget?.cost?.min === widget?.cost?.max
                                ? `
                       
                             ${displayPrice(
                               widget?.cost?.max,
                               widget?.cost?.max / 100,
                             )}`
                                : ` 
                              ${displayPrice(
                                widget?.cost?.min,
                                widget?.cost?.min / 100,
                              )} - 
                             ${displayPrice(
                               widget?.cost?.max,
                               widget?.cost?.max / 100,
                             )}`
                              : `${displayPrice(true, 0)}`}
                          </td>

                          <td>
                            <div className="flex justify-center w-[9.1%]">
                              <Dropdown>
                                <Menu.Item>
                                  {() => (
                                    <button
                                      onClick={() => {
                                        handleView(widget?._id);
                                      }}
                                      className="z-40 flex items-center gap-4 w-full font-medium text-gray-500 text-tiny"
                                    >
                                      <img
                                        src={edit}
                                        alt=""
                                        width={14}
                                        height={14}
                                      />
                                      {!canManageFlows ? 'View' : 'Edit'}
                                    </button>
                                  )}
                                </Menu.Item>
                                {widget?.published && (
                                  <Menu.Item>
                                    {() => (
                                      <button
                                        onClick={() =>
                                          window.open(
                                            `${
                                              customWidgetUrl ??
                                              process.env
                                                .REACT_APP_WIDGET_BASE_URL
                                            }?widget_id=${widget?._id}`,
                                            '_blank',
                                          )
                                        }
                                        className="z-40 flex items-center gap-4 w-full font-medium text-gray-500 text-tiny"
                                      >
                                        <img
                                          src={link}
                                          alt=""
                                          width={14}
                                          height={14}
                                        />
                                        Visit
                                      </button>
                                    )}
                                  </Menu.Item>
                                )}
                                <Menu.Item>
                                  {() => (
                                    <button
                                      onClick={() =>
                                        copyText(
                                          `${
                                            customWidgetUrl ??
                                            process.env
                                              .REACT_APP_WIDGET_BASE_URL
                                          }?widget_id=${widget?._id}`,
                                        )
                                      }
                                      className="z-40 flex items-center gap-4 w-full font-medium text-gray-500 text-tiny"
                                    >
                                      <img
                                        src={copyGrey}
                                        alt=""
                                        width={14}
                                        height={14}
                                      />
                                      Copy
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {() => (
                                    <button
                                      onClick={() => {
                                        setDeleteModal(true);
                                        setWidgetId(widget?._id);
                                      }}
                                      className="z-40 flex  items-center gap-4 w-full font-medium text-danger text-tiny"
                                    >
                                      <img
                                        src={trashRed}
                                        alt=""
                                        width={14}
                                        height={14}
                                      />
                                      Delete
                                    </button>
                                  )}
                                </Menu.Item>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </TableLayout>

                {!isLoading && allWidgets?.widgets?.length !== 0 && (
                  <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
                    <div className="flex items-center text-body text-tiny">
                      <PageLimit
                        onLimitChange={setLimit}
                        // className="mt-3 mt-sm-0"
                        total={allWidgets && allWidgets?.totalRecords}
                        length={allWidgets?.widgets?.length}
                        limit={limit}
                      />
                    </div>

                    <div className="mb-8 sm:mb-0">
                      <Pagination
                        total={
                          allWidgets &&
                          Math.ceil(allWidgets?.totalRecords / limit)
                        }
                        current={+currentPage}
                        onPageChange={activePage => {
                          pageClick(activePage);
                          const searchParams = new URLSearchParams(
                            document.location.search,
                          );
                          searchParams.set('page', activePage);
                          const newSearchParams = searchParams.toString();
                          navigate(`?${newSearchParams}`);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(EasyOnboard);
