/* eslint-disable  react-hooks/exhaustive-deps */

import React, {useContext, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {
  allowedEvents,
  blockedEvents,
  dashboardIc,
  pendingEvents,
  totalEvents as totalIcon,
} from '../../../assets/images/images';
import {Card, DashboardLayout} from '../../../components';
import {DashboardPieChartLeft, DashboardPieChartRight} from './DonutCharts';
import LineChart from './LineChart';
import * as Actions from '../../../store/actions';
import {convertToPercentage} from '../../../helpers/convertToPercentage';
import {PageContext} from '../../../helpers/context';
import {useFlowOverview} from '../Flows/hooks/useOverview';

function Overview({flows: {overviewGraph, loading, flows, graphLoading}}) {
  const [filters, setFilters] = useState({
    start: null,
    end: null,
  });

  const {
    TOTAL_NON_FLAGGED,
    TOTAL_RESOLVED_ALLOWED,
    TOTAL_RESOLVED_BLOCKED,
    TOTAL_EVENTS,
    TOTAL_FLAGGED,
    TOTAL_RESOLVED,
    TOTAL_BLOCKED,
    TOTAL_ALLOWED,
    TOTAL_PENDING,
    isLoading,
  } = useFlowOverview({filters});

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  const graphContent = useMemo(
    () => overviewGraph?.data?.result?.content,
    [overviewGraph],
  );

  return (
    <DashboardLayout
      bg="bg-white"
      topPadding="pt-16 sm:pt-6"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={dashboardIc} alt="" />
          <p className="ml-2 -mb-1">Overview</p>
        </div>
      }
    >
      <>
        <div className="flex items-center justify-between">
          <h1 className="text-grey text-xl font-medium">Overview</h1>
        </div>

        <div className="flex flex-col lg:flex-row items-center gap-4 my-6">
          <Card
            className="w-full"
            img={totalIcon}
            title="Total Events"
            body={TOTAL_EVENTS?.amount || 0}
            loading={loading}
            count={TOTAL_EVENTS?.count}
          />
          <Card
            className="w-full"
            img={pendingEvents}
            title="Pending Cases"
            body={TOTAL_PENDING?.amount || 0}
            loading={loading}
            count={TOTAL_PENDING?.count}
          />
          <Card
            className="w-full"
            img={allowedEvents}
            title="Allowed Cases"
            body={TOTAL_ALLOWED?.amount || 0}
            loading={loading}
            count={TOTAL_ALLOWED?.count}
          />
          <Card
            className="w-full"
            img={blockedEvents}
            title="Blocked Cases"
            body={TOTAL_BLOCKED?.amount || 0}
            loading={loading}
            count={TOTAL_BLOCKED?.count}
          />
        </div>

        <div className="mb-4">
          <p className="text-grey text-lg font-medium">Cases</p>
        </div>

        <LineChart
          loading={graphLoading}
          setFilters={setFilters}
          graphContent={graphContent}
          flows={flows}
        />

        <div className="flex gap-6 mt-8 basis-full">
          <div className="bg-white80 rounded-lg pt-[26px] px-3 sm:px-[22px] pb-5 basis-full sm:basis-1/2">
            <h3 className="mb-4 text-lg font-medium text-center sm:text-left text-grey">
              Resolved cases
            </h3>
            <div className="flex flex-col items-center gap-3 sm:flex-row">
              <DashboardPieChartLeft
                allowedPercentage={
                  +convertToPercentage(
                    TOTAL_RESOLVED_ALLOWED?.count || 0,
                    TOTAL_RESOLVED?.count || 0,
                  )?.replaceAll('%', '')
                }
                blockedPercentage={
                  +convertToPercentage(
                    TOTAL_RESOLVED_BLOCKED?.count || 0,
                    TOTAL_RESOLVED?.count || 0,
                  ).replaceAll('%', '')
                }
                total={TOTAL_RESOLVED?.count}
                loading={isLoading}
              />

              <div className="flex gap-2 sm:gap-8 sm:ml-6 sm:flex-col">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 rounded-full bg-status-400" />
                  <div className="flex flex-col gap-[5px]">
                    <p className="text-xs font-medium uppercase text-body">
                      Allowed
                    </p>
                    <p className="text-xl font-medium text-grey">
                      {convertToPercentage(
                        TOTAL_RESOLVED_ALLOWED?.count,
                        TOTAL_RESOLVED?.count,
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 rounded-full bg-[#3F7CDB]" />
                  <div className="flex flex-col gap-[5px]">
                    <p className="text-xs font-medium uppercase text-body">
                      Blocked
                    </p>
                    <p className="text-xl font-medium text-grey">
                      {convertToPercentage(
                        TOTAL_RESOLVED_BLOCKED?.count,
                        TOTAL_RESOLVED?.count,
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white80 rounded-lg pt-[26px] px-[22px] pb-5 basis-1/2 hidden md:block">
            <h3 className="mb-4 text-lg font-medium text-grey">
              Flagged events
            </h3>
            <div className="flex items-center gap-12">
              <DashboardPieChartRight
                flaggedPercentage={TOTAL_EVENTS?.count}
                nonFlaggedPercentage={TOTAL_NON_FLAGGED}
                flagged={TOTAL_FLAGGED?.count}
                loading={loading}
              />
              <div className="flex gap-2 sm:gap-8 sm:ml-6 sm:flex-col">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 rounded-full bg-status-404" />
                  <div className="flex flex-col gap-[5px]">
                    <p className="text-xs font-medium uppercase text-body">
                      FLAGGED events
                    </p>
                    <p className="text-xl font-medium text-grey">
                      {convertToPercentage(
                        TOTAL_FLAGGED?.count,
                        TOTAL_EVENTS?.count,
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 rounded-full bg-red" />
                  <div className="flex flex-col gap-[5px]">
                    <p className="text-xs font-medium uppercase text-body">
                      NON FLAGGED events
                    </p>
                    <p className="text-xl font-medium text-grey">
                      {convertToPercentage(
                        TOTAL_NON_FLAGGED,
                        TOTAL_EVENTS?.count,
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(Overview);
