import moment from 'moment';

export const convertToDurationObject = hours => {
  const duration = moment.duration(hours, 'hours');

  let count, period;

  if (duration.asMonths() >= 1) {
    count = Math.ceil(duration.asMonths());
    period = 'months';
  } else if (duration.asWeeks() >= 1) {
    count = Math.ceil(duration.asWeeks());
    period = 'weeks';
  } else if (duration.asDays() >= 1) {
    count = Math.ceil(duration.asDays());
    period = 'days';
  } else {
    count = Math.ceil(duration.asHours());
    period = 'hours';
  }

  return {count, period};
};
