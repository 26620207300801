import React, {useContext, useEffect} from 'react';
import Layout from './Layout';
// import {corruptedFile} from '../../assets/images/images';
import {Loader, RowData} from '../../components';
import {useQuery} from 'react-query';
import {fetchKYCInformation} from '../../requests/queries/creditChecks';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {PageContext} from '../../helpers/context';
import {CreditCheckEmptyState} from '../../components/CreditCheckEmptyState';

export default function EasyCreditKycInformation() {
  const {slug} = useParams();

  const {data, isLoading, isFetching} = useQuery(
    ['kyc-info', {customer_id: slug, entity_type: 'bvn_check'}],
    () => fetchKYCInformation({customer_id: slug, entity_type: 'bvn_check'}),
  );

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('creditcheck');
  }, [setPageName]);

  const KYC_INFORMATION =
    (!isLoading &&
      data?.entity &&
      Object.entries(data?.entity)
        .map(([key, value]) => {
          if (value && !['image_url', 'image'].includes(key)) {
            return {
              leftText: key?.replaceAll('_', ' ')?.toUpperCase(),
              rightText: ['created_date', 'updated_date'].includes(key)
                ? moment(value).format('Do MMM YYYY  hh:mm a')
                : value.toString(),
            };
          }
        })
        .filter(Boolean)) ||
    [];

  return (
    <Layout>
      {isLoading ? (
        <Loader height={55} />
      ) : !isLoading && KYC_INFORMATION?.length === 0 ? (
        <CreditCheckEmptyState
          bodyText={'We did not find any KYC information on this person'}
        />
      ) : (
        <div className="mt-6" style={{opacity: isFetching ? 0.3 : 1}}>
          {KYC_INFORMATION.map(({leftText, rightText}, i) => (
            <RowData key={i} leftText={leftText} rightText={rightText} />
          ))}
        </div>
      )}
    </Layout>
  );
}
