import React from 'react';

export default function Checkbox({
  label,
  name,
  value,
  onChange,
  isChecked,
  noLeftPadding = false,
  className = '',
  labelStyle,
  align = 'items-center',
  otherText = '',
  disabled = false,
}) {
  return (
    <div className={`${!noLeftPadding ? 'pl-4' : ''} ${className}`}>
      <label
        htmlFor={value}
        className={`relative flex flex-row ${align} gap-2 font-normal transition duration-500 ease-in-out ${
          disabled ? 'cursor-not-allowed' : ''
        }`}
      >
        <input
          type="checkbox"
          name={name}
          id={value}
          value={value}
          onChange={onChange}
          checked={isChecked ?? false}
          className={`w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue ${
            disabled ? 'cursor-not-allowed' : ''
          }`}
          disabled={disabled}
        />
        <span className={`${!labelStyle ? 'text-sm text-grey' : labelStyle}`}>
          {otherText ? (
            <div className="flex justify-between w-48">
              <p className="text-xs leading-[18px] text-body">{label}</p>
              {otherText && (
                <p className="text-xs  leading-[18px] text-grey20">
                  {otherText}
                </p>
              )}
            </div>
          ) : (
            label
          )}
        </span>
      </label>
    </div>
  );
}
