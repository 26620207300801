import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {userBlue} from '../../assets/images/images';
import {Checkbox} from '../../components';

import * as Actions from '../../store/actions';
import {DetectWithAgeLimit} from './FraudCheckConfigScreens/DetectWIthAgeLimit';
import {DetectWithUserData} from './FraudCheckConfigScreens/DetectWIthUserData';
import {DetectWithAmlScreening} from './FraudCheckConfigScreens/DetectWithAMLScreening';
import {DetectWithDuplicateID} from './FraudCheckConfigScreens/DetectWIthDuplicateID';
import {DetectWithLivenessMatch} from './FraudCheckConfigScreens/DetectWithLivenessMatch';
import {DetectWithIpDeviceScreening} from './FraudCheckConfigScreens/DetectWithIpDeviceScreening';
import {BlackList} from './FraudCheckConfigScreens/Blacklist';

export const AGES = [
  {value: '18', label: '18'},
  {value: '24', label: '24'},
  {value: '30', label: '30'},
  {value: '40', label: '40'},
  {value: '56', label: '56'},
  {value: '60', label: '60+'},
];

export const STATUSES = [
  {value: 'pending', label: 'Pending'},
  {value: 'blocked', label: 'Failed'},
];

export const CHECKBOXES = [
  {
    label: 'Government ID',
    value: 'government_id',
  },
  {
    label: 'Government Data',
    value: 'government_data',
  },
];

export function CheckboxGroup({options, selected, onChange}) {
  const handleChange = useCallback(
    event => {
      const {value} = event.target;
      if (selected.includes(value)) {
        onChange(selected.filter(item => item !== value));
      } else {
        onChange([...selected, value]);
      }
    },
    [selected, onChange],
  );

  return (
    <>
      {options.map(option => (
        <Checkbox
          key={option.value}
          label={option.label}
          value={option.value}
          isChecked={selected.includes(option.value)}
          onChange={handleChange}
        />
      ))}
    </>
  );
}

export function Wrapper({children}) {
  return (
    <div className="flex justify-center items-start h-screen px-10 w-full py-6 mx-auto">
      <div className="flex w-full flex-col gap-4">{children}</div>
    </div>
  );
}

export function Card({src, children, extension}) {
  return (
    <article className="bg-white p-4 rounded-lg flex flex-col items-start gap-4">
      <div className="flex items-center gap-4 w-full">
        <img alt="" src={src ?? userBlue} width={40} height={40} />
        {extension}
      </div>
      {children}
    </article>
  );
}

export default {
  DetectWithAgeLimit: connect(state => state, Actions)(DetectWithAgeLimit),
  Blacklist: connect(state => state, Actions)(BlackList),
  DetectWithUserData: connect(state => state, Actions)(DetectWithUserData),
  DetectWithAmlScreening: connect(
    state => state,
    Actions,
  )(DetectWithAmlScreening),
  DetectWithDuplicateID: connect(
    state => state,
    Actions,
  )(DetectWithDuplicateID),
  DetectWithLivenessMatch: connect(
    state => state,
    Actions,
  )(DetectWithLivenessMatch),
  DetectWithIpDeviceScreening: connect(
    state => state,
    Actions,
  )(DetectWithIpDeviceScreening),
};
