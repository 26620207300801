import React, {useCallback, useState} from 'react';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import {Modal, PrimaryButton} from '../../../components';
import {checked, unchecked} from '../../../assets/images/images';
import {passwordCharacterCheck} from '../../../helpers';
import * as Actions from '../../../store/actions';

function SetPasswordModal({open, setOpen, setPassword, auth: {loading}}) {
  const [passwordShown, setPasswordShown] = useState(false);
  const [state, setState] = useState({
    password: '',
    c_password: '',
  });
  const handleChange = useCallback(e => {
    const {id, value} = e.target;
    setState(prev => ({...prev, [id]: value}));
  }, []);

  const isPasswordValid = passwordCharacterCheck.map(({check}) =>
    check(state.password),
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (state.password !== state.c_password) {
        toast.error('Passwords do not match');
      } else if (state.password.length < 8) {
        toast.error('Password must be greater than 8 characters');
      } else {
        setPassword(
          {
            password: state.password,
          },
          () => setOpen(false),
        );
      }
    },
    [setOpen, setPassword, state],
  );

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Set password"
    >
      <form className="mt-10 text-left">
        <label htmlFor="password" className="mt-6 relative">
          <span className="text-sm ">Password</span>
          <input
            type={passwordShown ? 'text' : 'password'}
            id="password"
            className="mt-2"
            value={state?.password}
            onChange={handleChange}
          />
          <span
            role="button"
            onClick={() => setPasswordShown(!passwordShown)}
            className="absolute text-grey40 text-xs font-medium uppercase right-3 bottom-4 cursor-pointer"
          >
            {passwordShown ? 'hide password' : 'show password'}
          </span>
        </label>

        <label htmlFor="c_password" className="mt-6 relative">
          <span className="text-sm ">Confirm password</span>
          <input
            type={!state?.c_password ? 'text' : 'password'}
            id="c_password"
            className="mt-2"
            value={state?.c_password}
            onChange={handleChange}
          />
        </label>
        {state.c_password && state.password !== state.c_password ? (
          <p className="text-xs mt-2 text-red">Password did not match</p>
        ) : null}

        <div className="mt-2 hidden md:flex flex-wrap gap-2">
          {passwordCharacterCheck.map(({name, check}) => (
            <div
              key={name}
              className="w-fit flex items-center gap-2 bg-white80 py-2 px-4 rounded-2xl"
            >
              <img src={check(state?.password) ? checked : unchecked} alt="" />
              <span className="uppercase text-xs text-body font-medium mt-[3px]">
                {name}
              </span>
            </div>
          ))}
        </div>

        <PrimaryButton
          onClick={handleSubmit}
          buttonText="Set password"
          className="w-full mt-6"
          loading={loading}
          disabled={
            !state.password ||
            !state.c_password ||
            state.password !== state.c_password ||
            !isPasswordValid.every(Boolean)
          }
        />
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(SetPasswordModal);
