export function pushObjectsIntoArray(objects) {
  const result = [];
  let tempArray = [];

  for (let i = 0; i < objects?.length; i++) {
    tempArray.push(objects[i]);

    if (tempArray.length === 2 || i === objects?.length - 1) {
      result.push({columns: tempArray});
      tempArray = [];
    }
  }
  return result;
}
