import React from 'react';
import {NavLink} from 'react-router-dom';
import {PAGES_URL} from '../../../helpers/constants';
import {connect} from 'react-redux';
import * as Actions from '../../../store/actions';

const ROUTES = [
  {
    name: 'Transaction History',
    path: PAGES_URL.SETTINGS_BILLING,
    permission: 'viewHistory',
  },
  {
    name: 'API Calls',
    path: PAGES_URL.SETTINGS_BILLING_PRICING,
    permission: 'viewLogs',
  },
];

function BillingsLayout({auth: {userDetails}, children}) {
  return (
    <>
      <div className="mt-6 md:mt-[27px] border-b border-grey60">
        <div className="flex items-center gap-4 overflow-x-auto settings-nav whitespace-nowrap">
          {ROUTES.map((route, index) => (
            <NavLink
              key={index}
              end
              to={route.path}
              className={({isActive}) =>
                `font-medium text-sm py-2 ${
                  isActive
                    ? 'text-brandBlue border-b-2 border-brandBlue'
                    : 'text-grey-500 bg-transparent'
                }`
              }
              style={{
                pointerEvents: userDetails?.permissions?.billingPermissions[
                  route.permission
                ]
                  ? 'auto'
                  : 'none',
              }}
            >
              {route.name}
            </NavLink>
          ))}
        </div>
      </div>
      {children}
    </>
  );
}

export default connect(state => state, Actions)(BillingsLayout);
