import {useEffect, useMemo} from 'react';
import {getAllApps} from '../store/actions';
import {useSelector} from 'react-redux';

export const useApps = () => {
  const {allApps} = useSelector(state => state.apps);
  useEffect(() => {
    getAllApps({limit: 10000});
  }, []);

  const allAppsData = useMemo(
    () => (allApps && allApps?.apps) || [],
    [allApps],
  );

  return {allAppsData};
};
