import React, {useMemo} from 'react';
import Chart from 'react-apexcharts';
import {getPeriod} from '../constants';
import {Loader} from '../../../../components';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';

function OverviewLineChart({
  data = [],
  periodType,
  loading,
  height,
  loaderHeight,
}) {
  const sortedData = data.sort(
    (a, b) => new Date(a.transaction_time) - new Date(b.transaction_time),
  );
  const series = [
    {
      name: 'Credit',
      data: sortedData.map(item => parseInt(item.credit)),
    },
    {
      name: 'Debit',
      data: sortedData.map(item => parseInt(item.debit)),
    },
  ];

  const cat = useMemo(
    () => sortedData.map(item => getPeriod(item?.transaction_time, periodType)),
    [periodType, sortedData],
  );

  const options = {
    chart: {
      height: height || 420,
      stacked: false,

      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: cat.length > 0 ? cat : ['N/A', 'N/A', 'N/A'],
      position: 'bottom',
      labels: {
        style: {
          colors: '#CFCFDD',
        },
      },
    },
    yaxis: [
      {
        labels: {
          formatter: value => shortenLargeNumbers(value, true),
          style: {
            colors: '#5C688E',
          },
        },
      },
      // {opposite: true, show: false},
    ],
    stroke: {
      width: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetY: -20,
      offsetX: -30,
      width: 450,
      height: 60,
    },
    markers: {
      size: 3,
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
          strokeColor: '#CECED2',
        },
      },
      yaxis: {
        lines: {
          show: true,
          strokeColor: '#CECED2',
        },
      },
    },
    colors: ['#6FCBD4', '#F15D5F'],
  };

  const dummySeries = [
    {
      name: 'Inflow',
      data: [0, 0, 0],
    },
    {
      name: 'Outflow',
      data: [0, 0, 0],
    },
  ];

  return (
    <div>
      {loading ? (
        <Loader height={loaderHeight || 25} />
      ) : (
        <Chart
          options={options}
          series={series[0]?.data?.length > 0 ? series : dummySeries}
          height={height || 420}
          className="project_line_chart"
          type="bar"
        />
      )}
    </div>
  );
}

export default OverviewLineChart;
