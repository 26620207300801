import React from 'react';
export default function RowData({
  leftText,
  rightText,
  bottomBorderOnly = false,
  xPadding = false,
  bolderText = false,
  boldFont = false,
  smallerText = false,
  customUI,
  leftCustomUI,
  alignStart = false,
  paddingY,
  width,
  ...props
}) {
  return (
    <div
      className={`flex justify-between whitespace-nowrap gap-10 print:px-0 px-4 ${
        paddingY ? paddingY : 'py-4'
      } border-grey60 last:border-y ${
        bottomBorderOnly ? 'border-b' : 'border-t'
      } ${xPadding ? 'md:px-6' : 'md:px-0'} ${
        alignStart ? 'items-start gap-10' : 'items-center'
      } `}
      {...props}
    >
      {leftText ? (
        <span className="text-xs font-medium uppercase text-grey">
          {leftText}
        </span>
      ) : (
        leftCustomUI
      )}
      {rightText ? (
        <span
          className={`text-sm text-body capitalize whitespace-pre-line text-end ${width} ${
            bolderText ? 'font-medium' : ''
          } ${boldFont ? 'font-bold' : ''} ${smallerText ? 'text-small' : ''}`}
        >
          {rightText}
        </span>
      ) : (
        customUI
      )}
    </div>
  );
}
