import React, {useContext, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {batchAmlImg, singleAmlImg} from '../../../assets/images/images';
import * as Actions from '../../../store/actions';
import {Loader} from '../../../components';
import {PAGES_URL} from '../../../helpers/constants';
import PerformLookupModal from '../PerformLookupModal';
import BatchAmlModal from './BatchAmlModal';
import {PageContext} from '../../../helpers/context';
import Layout from '../Layout';
import {PerformBtn, ViewLink} from '../../../components/lookupComponents';

function UserScreening({auth: {userDetails}, apps: {allApps}, getAllApps}) {
  const [openSingleAml, setOpenSingleAml] = useState(false);
  const [singleAmlStep, singleAmlSetStep] = useState(1);
  const [openBatchAml, setOpenBatchAml] = useState(false);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('amllookup');
  }, [setPageName]);

  useEffect(() => {
    getAllApps({limit: 10000});
  }, [getAllApps]);

  const {
    performSingleIndividualLookup,
    viewSingleIndividualLookup,
    performBatchIndividualLookup,
    viewBatchIndividualLookup,
  } =
    useMemo(
      () => userDetails?.permissions?.amlScreeningPermissions,
      [userDetails],
    ) || {};

  const isCompanyVerified = userDetails?.company?.verified;

  return (
    <Layout>
      <PerformLookupModal
        open={openSingleAml}
        setOpen={setOpenSingleAml}
        lookupType="Single"
        step={singleAmlStep}
        setStep={singleAmlSetStep}
      />

      <BatchAmlModal open={openBatchAml} setOpen={setOpenBatchAml} />

      {!allApps ? (
        <Loader height={35} />
      ) : (
        <>
          <div className="mt-4">
            <h1 className="text-grey text-xl font-medium">User screening</h1>
            <p className="mt-2 text-body text-sm">
              Screen information about your users
            </p>
          </div>

          <div className="mt-12 flex flex-col sm:flex-row items-stretch gap-10 sm:gap-4 lg:gap-10">
            <div className="bg-white rounded-3xl px-4 lg:px-6 pt-8 pb-0 flex flex-col items-start sm:max-w-[406px] w-full">
              <h2 className="text-grey text-lg font-semibold">
                Perform Single AML
              </h2>
              <p className="mt-2 text-grey20 tracking-tight">
                Screen your user effortlessly
              </p>

              <div className="w-full mt-10 flex flex-col lg:flex-row items-start lg:items-center gap-2">
                <PerformBtn
                  btnText="Perform Single AML"
                  permitted={performSingleIndividualLookup}
                  setOpen={setOpenSingleAml}
                  isCompanyVerified={isCompanyVerified}
                />
                <ViewLink
                  permitted={viewSingleIndividualLookup}
                  route={PAGES_URL.AML_USER_SCREENING_SINGLE}
                />
              </div>

              <div className="mt-auto lg:mt-14 flex justify-center w-full">
                <img src={singleAmlImg} alt="" width={347} height={224} />
              </div>
            </div>

            <div className="bg-white rounded-3xl px-4 lg:px-6 pt-8 pb-0 flex flex-col items-start sm:max-w-[406px] w-full">
              <h2 className="text-grey text-lg font-semibold">
                Perform Batch AML
              </h2>
              <p className="mt-2 text-grey20 tracking-tight">
                Upload and examine multiple users
              </p>

              <div className="w-full mt-10 flex flex-col lg:flex-row items-start lg:items-center gap-2">
                <PerformBtn
                  btnText="Perform Batch AML"
                  permitted={performBatchIndividualLookup}
                  setOpen={setOpenBatchAml}
                  isCompanyVerified={isCompanyVerified}
                />
                <ViewLink
                  permitted={viewBatchIndividualLookup}
                  route={PAGES_URL.AML_USER_SCREENING_BATCH}
                />
              </div>

              <div className="mt-14 flex justify-center w-full">
                <img src={batchAmlImg} alt="" width={329} height={236} />
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}

export default connect(state => state, Actions)(UserScreening);
