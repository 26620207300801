import React from 'react';
import {connect} from 'react-redux';
import {
  businessAvatar,
  chevronRight,
  closeModal,
  userAvatar,
} from '../../assets/images/images';
import {Modal} from '../../components';
import * as Actions from '../../store/actions';

function ChooseAmlType({
  chooseOpen,
  setChooseOpen,
  setIndividualOpen,
  setBusinessOpen,
}) {
  const amlType = [
    {
      type: 'Lookup individual',
      icon: userAvatar,
      onClick: setIndividualOpen,
    },
    {
      type: 'Lookup business',
      icon: businessAvatar,
      onClick: setBusinessOpen,
    },
  ];
  return (
    <Modal
      show={chooseOpen}
      onClose={setChooseOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-grey text-base font-medium">Choose lookup</h3>
          <button onClick={() => setChooseOpen(false)}>
            <img src={closeModal} alt="" />
          </button>
        </header>

        <p className="mt-4 text-sm text-body text-left">
          Select the type of lookup you would like to perform
        </p>

        <div className="mt-6 flex  flex-col items-center gap-4 p-4 px-6 bg-white80 rounded-b-none sm:rounded-lg">
          {amlType.map((item, index) => (
            <button
              key={item.type}
              className="bg-white hover:shadow-sm  transition p-4 w-full rounded-lg flex items-center justify-start"
              onClick={item.onClick}
            >
              <img src={item?.icon} alt="" />
              <p className="text-sm text-body mr-auto ml-3">{item.type}</p>
              <img src={chevronRight} alt="" />
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, Actions)(ChooseAmlType);
