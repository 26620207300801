import {api} from '../../helpers';

function fetchBusinessPool(appIds, params) {
  return api
    .get(`/users/customer-pool/businesses${appIds && `?app_id=${appIds}`}`, {
      params,
    })
    .then(res => res.data);
}
function fetchBusinessEntity(business_id) {
  return api
    .get(`/users/customer-pool/businesses/${business_id}/entity`)
    .then(res => res.data);
}
function fetchBusinessID(business_id, id) {
  return api
    .get(`/users/customer-pool/businesses/${business_id}/${id}`)
    .then(res => res.data);
}
function addComment(business_id, data) {
  return api
    .post(`/users/customer-pool/${business_id}/comments`, {
      ...data,
      type: 'business',
    })
    .then(res => res.data);
}
function fetchComments(business_id) {
  return api
    .get(`/users/customer-pool/${business_id}/comments`)
    .then(res => res.data);
}

function fetchBusinessDetails(businessId) {
  return api
    .get(`/users/customer-pool/businesses/${businessId}`)
    .then(res => res.data);
}
function deleteComment(business_id, commentId) {
  return api
    .delete(`/users/customer-pool/${business_id}/comments/${commentId}`)
    .then(res => res.data);
}

export {
  fetchBusinessPool,
  addComment,
  fetchComments,
  deleteComment,
  fetchBusinessDetails,
  fetchBusinessEntity,
  fetchBusinessID,
};
