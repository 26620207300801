import React from 'react';
import Select from 'react-select';
import {Constants} from '../helpers';
import {customStyles, DropdownIndicator} from './ReactSelect';

export default function CountryInput({
  defaultValue,
  options,
  onChange,
  label,
  lookupType,
  ...rest
}) {
  const filterOutAngolaForBatch =
    lookupType === 'Batch'
      ? Constants.lookups.countries.filter(e => e.value !== 'angola')
      : Constants.lookups.countries;

  return (
    <>
      <label htmlFor="country" className="mb-1 relative">
        {label}
      </label>
      <Select
        defaultValue={defaultValue || Constants.lookups.countries[0]}
        onChange={onChange}
        options={options || filterOutAngolaForBatch}
        placeholder="Select country"
        components={{DropdownIndicator}}
        classNamePrefix="react-select"
        styles={customStyles}
        getOptionLabel={e => (
          <div style={{display: 'flex', alignItems: 'center'}}>
            {e.icon}
            <span style={{marginLeft: 8, marginBottom: -4}}>{e.label}</span>
          </div>
        )}
        isSearchable={false}
        {...rest}
      />
    </>
  );
}
