import React, {useState} from 'react';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import {
  businessAvatar,
  dropdownNoFill,
  userAvatar,
} from '../../assets/images/images';
import {Modal} from '../../components';
import EasyLookupModals from '../EasyLookup/EasyLookupModals';
import SingleAnalysisModal from '../DocumentAnalysis/SingleAnalysisModal';

function PerformLookupModal({open, setOpen}) {
  const [openEasyLookupModal, setOpenELM] = useState(false);
  const [openDocumentAnalysisModal, setOpenDAM] = useState(false);

  return (
    <>
      <Modal
        show={open}
        onClose={() => setOpen(false)}
        extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
        modalPosition="justify-center sm:justify-end"
        modalTitle="Choose action"
      >
        <EasyLookupModals
          open={openEasyLookupModal}
          setOpen={setOpenELM}
          lookupType="Individual"
          batch={false}
          customer360={true}
          customer360Dropdown
        />
        <SingleAnalysisModal
          open={openDocumentAnalysisModal}
          setOpen={setOpenDAM}
          customer360={true}
        />

        <p className="mt-1 text-start text-sm text-body">
          What would you like to do?
        </p>

        <div className="mt-6 px-6 py-5 bg-white80 flex flex-col gap-6 rounded-xl text-sm font-medium text-grey">
          <button
            onClick={() => {
              setOpenELM(true);
            }}
            className="flex items-center gap-4 bg-white p-6 pr-4 rounded"
          >
            <img src={userAvatar} alt="" width={29} height={29} />
            <p className="grow text-start">Perform Lookup</p>
            <img
              src={dropdownNoFill}
              alt=""
              width={20}
              height={20}
              className="transform -rotate-90"
            />
          </button>
          <button
            onClick={() => {
              setOpenDAM(true);
            }}
            className="flex items-center gap-4 bg-white p-6 pr-4 rounded"
          >
            <img src={businessAvatar} alt="" width={29} height={29} />
            <p className="grow text-start">Validate Document</p>
            <img
              src={dropdownNoFill}
              alt=""
              width={20}
              height={20}
              className="transform -rotate-90"
            />
          </button>
        </div>
      </Modal>
    </>
  );
}

export default connect(state => state, Actions)(PerformLookupModal);
