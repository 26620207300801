const projectTypeOptions = [
  {value: 'banking', label: 'Banking'},
  {value: 'fintech', label: 'Fintech'},
];
const accountType = [
  {value: 'savings', label: 'Savings'},
  {value: 'current', label: 'Current'},
  {value: 'checking', label: 'Checking'},
];

const accountTransactionType = [
  {value: 'credit', label: 'Credit'},
  {value: 'debit', label: 'Debit'},
];

export {projectTypeOptions, accountType, accountTransactionType};
