import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Modal, SecondaryButton, TertiaryButton} from '../../../components';
import * as Actions from '../../../store/actions';

function DeactivateMemberModal({
  removeTeamMember,
  selectedMember,
  deactivateModal,
  setDeactivateModal,
  setOpen,
}) {
  const [isLoading, setLoading] = useState(false);
  const handleDelete = () => {
    setLoading(true);
    removeTeamMember(selectedMember._id || selectedMember.id, () => {
      setDeactivateModal(false);
      setOpen(false);
      setLoading(false);
    });
  };

  return (
    <Modal
      show={deactivateModal}
      modalTitle="Are you sure?"
      onClose={setDeactivateModal}
      extraModalClasses="max-w-[512px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="mt-4">
        <p className="text-start text-sm text-body font-medium">
          By deactivating this user, you are removing the user from accessing
          the Dojah application
        </p>
      </div>

      <div className="mt-4 flex items-center gap-4">
        <SecondaryButton
          onClick={() => setDeactivateModal(false)}
          buttonText="Cancel"
        />
        <TertiaryButton
          onClick={handleDelete}
          buttonText="Deactivate user"
          className="grow"
          loading={isLoading}
          disabled={isLoading}
        />
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(DeactivateMemberModal);
