import {useCallback} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {cancelSubscription} from '../../../../requests/queries/reconciliations';
import {toast} from 'react-toastify';

export const useCancelSubscription = ({billingInfo}) => {
  const queryClient = useQueryClient();

  const cancelSubscriptionMutation = useMutation(cancelSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      toast.success('Subscription has been canceled successfully!');
    },
    onError: error => {
      console.error('Error canceling subscription:', error);
      toast.error('An error occurred while canceling subscription.');
    },
  });

  const handleCancelSubscription = useCallback(async () => {
    if (!billingInfo?.subscription_id) {
      return;
    }
    try {
      const data = {
        subscription_id: billingInfo?.subscription_id,
      };

      await cancelSubscriptionMutation?.mutateAsync(data);
    } catch (error) {
      console.log(error);
    }
  }, [billingInfo?.subscription_id, cancelSubscriptionMutation]);

  return {handleCancelSubscription, cancelSubscriptionMutation};
};
