import React, {useState, useMemo, useEffect} from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import moment from 'moment';
import * as Actions from '../../../store/actions';
import {
  DropdownIndicator,
  customStyles,
  Modal,
  PrimaryButton,
} from '../../../components';

function TransferOwnership({
  open,
  setOpen,
  roles,
  selectedMember,
  transferOwnership,
  team: {transferOwnershipLoading, members},
}) {
  const roleOptions = useMemo(
    () =>
      roles
        ? roles
            .filter(r => r.role !== 'Owner')
            .map(r => ({
              value: r.role,
              label: r.role,
            }))
        : null,
    [roles],
  );
  const [selectedRole, setSelectedRole] = useState(
    roleOptions ? roleOptions[0] : null,
  );
  const [email, setEmail] = useState(null);

  // filter users who've been added within the last 24hrs
  const currentTime = moment();
  const filteredMembers = members?.team.filter(member => {
    const memberCreatedAt = moment(member.createdAt);
    const duration = moment.duration(currentTime.diff(memberCreatedAt));
    const hoursDifference = duration.asHours();
    return hoursDifference > 24 && member.permissions.role !== 'Owner';
  });

  const currentOwnerEmail = members?.team.find(
    member => member.permissions.role === 'Owner',
  )?.email;

  const emailOptions = filteredMembers
    ?.filter(
      member =>
        member?.permissions.role !== 'Owner' &&
        member?.email !== currentOwnerEmail,
    )
    .map(member => ({
      value: member?.email,
      label: member?.email,
    }));

  useEffect(() => {
    setSelectedRole({
      value: selectedMember?.role,
      label: selectedMember?.role,
    });
  }, [selectedMember?.role]);

  const [step, setStep] = useState(1);

  function modalTitles() {
    switch (step) {
      case 1:
        return 'Choose your new role';
      case 2:
        return 'Transfer admin ownership';
      case 3:
        return 'Admin Ownership Transferred';
      default:
        return '';
    }
  }

  function handleNext() {
    setStep(step + 1);
  }

  function paragraphTexts() {
    switch (step) {
      case 1:
        return (
          <p className="text-body font-medium text-start">
            As the admin is being transferred, it&apos;s time to select your new
            role within the dashboard. Browse the available roles below and
            choose one that best aligns with your responsibilities and access
            requirements.
          </p>
        );
      case 2:
        return (
          <p className="text-body font-medium text-start">
            You are about to transfer your admin privileges to another user
            within the dashboard. This process ensures a smooth transition by
            allowing you to select the desired user and confirm the transfer
            with ease.
          </p>
        );
      case 3:
        return (
          <p className="text-body font-medium text-start">
            Congratulations! Admin ownership has now been successfully
            transferred to the selected user. They will receive an email
            notification with the necessary details and access to their new
            admin privileges.
          </p>
        );
      default:
        return null;
    }
  }

  function forms() {
    switch (step) {
      case 1:
        return (
          <form className="mt-6">
            <label htmlFor="role" className="mb-2">
              Role
            </label>
            <Select
              defaultValue={selectedMember}
              value={selectedRole}
              onChange={setSelectedRole}
              options={roleOptions}
              components={{DropdownIndicator}}
              classNamePrefix="react-select"
              styles={customStyles}
              menuPlacement="auto"
              className="text-left"
              required
            />
          </form>
        );
      case 2:
        return (
          <form className="mt-6">
            <label htmlFor="new_owner_email" className="mb-2">
              Email address of new owner
            </label>
            <Select
              defaultValue={emailOptions?.[0]?.value}
              value={email}
              onChange={setEmail}
              options={emailOptions}
              components={{DropdownIndicator}}
              classNamePrefix="react-select"
              styles={customStyles}
              menuPlacement="auto"
              className="text-left"
              required
            />
          </form>
        );
      case 3:
        return;
      default:
        return null;
    }
  }
  const handleTransferOwnership = () => {
    const payload = {
      email: email?.value,
      role: selectedRole?.value,
    };

    transferOwnership(payload, handleNext);
  };

  function actions() {
    switch (step) {
      case 1:
        return (
          <PrimaryButton
            buttonText="Done"
            className="w-full"
            onClick={handleNext}
            disabled={!selectedRole?.value}
          />
        );
      case 2:
        return (
          <PrimaryButton
            buttonText="Continue"
            className="w-full"
            onClick={handleTransferOwnership}
            loading={transferOwnershipLoading}
            disabled={!email}
          />
        );
      case 3:
        return (
          <PrimaryButton
            buttonText="Continue"
            className="w-full"
            onClick={() => {
              setOpen(false);
              window.location.reload();
            }}
          />
        );
      default:
        return null;
    }
  }

  return (
    <Modal
      show={open}
      modalTitle={modalTitles()}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center items-center"
      noBottomPadding
      customHeadingSize
    >
      <section className="flex flex-col gap-4 mt-6">{paragraphTexts()}</section>

      {forms()}

      <div className="-mx-6 flex items-center gap-4 p-6 pb-12 overflow-x-hidden">
        {actions()}
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(TransferOwnership);
