import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import {token, Constants} from '../helpers';
import NotFound from '../routes/NotFound';

class PrivateRoutes extends Component {
  render() {
    return token.get('user:token') ? (
      <Route {...this.props} />
    ) : (
      <Route path={Constants.PAGES_URL.SIGNIN} element={<NotFound />} />
    );
  }
}

export default PrivateRoutes;
