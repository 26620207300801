import React, {forwardRef} from 'react';

export const ConditionCard = forwardRef(
  (
    {
      disabled,
      isStarting,
      isEnding,
      title,
      subtitle,
      onClick,
      height,
      icon,
      color,
      ...rest
    },
    ref,
  ) => (
    <button
      onClick={data => {
        if (disabled) return;
        onClick && onClick(data);
      }}
      className={`flex items-center rounded-lg bg-white py-3 px-4 min-w-[214px]  outline-1 outline-dashed  border-l-4  ${
        height ? 'h-[77px]' : ''
      } gap-2 `}
      ref={ref}
      {...rest}
      style={{outlineColor: color, borderColor: color}}
    >
      {icon && <img src={icon} alt="" width={32} height={32} />}
      <div className="flex flex-col gap-1 items-start">
        {/*<span>{title}</span>*/}
        <span className="text-sm text-grey40 capitalize">{subtitle}</span>
      </div>
    </button>
  ),
);
