import React, {useState} from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import Loader from './Loader';

const containerStyle = {
  position: 'relative',
  height: '600px',
};

function GoogleMapComponent({center, zoom, multipleMarkers, customStyles}) {
  const [activeMarker, setActiveMarker] = useState(null);
  const {isLoaded, loadError} = useJsApiLoader({
    googleMapsApiKey:
      process.env.REACT_APP_GOOGLE_MAP_API_KEY ||
      'AIzaSyAM1hg9be5WiIXnupnuM5BBicdXnMDaxRw',
  });

  const handleActiveMarker = marker => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const renderMap = () => (
    <GoogleMap
      mapContainerStyle={customStyles ?? containerStyle}
      center={center}
      zoom={zoom ?? 14}
      clickableIcons
    >
      {multipleMarkers ? (
        multipleMarkers.map((marker, i) => (
          <Marker
            key={marker.lat + i}
            onClick={() => handleActiveMarker(marker)}
            title={marker?.name || 'No Name'}
            position={{
              lat: marker?.lat,
              lng: marker?.lng,
            }}
            icon={{
              anchor: new window.google.maps.Point(32, 32),
              scaledSize: new window.google.maps.Size(34, 34),
              url: marker?.markerImage,
            }}
          >
            <InfoWindow
              anchor={marker}
              position={{
                lat: marker?.lat,
                lng: marker?.lng,
              }}
              onCloseClick={() => setActiveMarker(null)}
            >
              <div>
                <p>{marker?.name}</p>
              </div>
            </InfoWindow>
          </Marker>
        ))
      ) : (
        <Marker position={center} />
      )}
    </GoogleMap>
  );

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <Loader height={35} />;
}

export default React.memo(GoogleMapComponent);
