import {api, token} from '.';
import {logo} from '../assets/images/images';

async function fetchDataFromProfile(apiEndpoint, defaultValue) {
  try {
    const userToken = token.get();
    if (userToken) {
      const response = await api.get('/users/me/profile');
      const {company} = response.data;
      return company?.[apiEndpoint] || defaultValue;
    }
  } catch (error) {
    console.error(`Error fetching ${apiEndpoint}:`, error);
    return defaultValue;
  }
}

async function fetchCompanyPrimaryColor() {
  return await fetchDataFromProfile('color_code', '#3F7CDB');
}

async function fetchCompanyLogo() {
  return await fetchDataFromProfile('logo', logo);
}

export {fetchCompanyPrimaryColor, fetchCompanyLogo};
