class PollingService {
  constructor() {
    this.isPolling = false;
    this.intervalRef = null;
    this.timeoutRef = null;
    this.elapsedTime = 0;
    this.startTime = null;
    this.subscribers = new Set();
  }

  startPolling(fetchFunc, interval = 5000, duration = 30000) {
    if (this.isPolling) return;

    this.isPolling = true;
    this.startTime = Date.now();
    this.elapsedTime = 0;
    this.notifySubscribers();
    fetchFunc();
    console.log('Initial fetch executed');

    this.intervalRef = setInterval(() => {
      fetchFunc();
      console.log('Fetching transaction data in progress....');
    }, interval);

    this.timeoutRef = setTimeout(() => {
      this.stopPolling();
    }, duration);
  }

  stopPolling() {
    if (this.intervalRef) clearInterval(this.intervalRef);
    if (this.timeoutRef) clearTimeout(this.timeoutRef);

    this.isPolling = false;
    this.elapsedTime = Date.now() - this.startTime;
    this.notifySubscribers();
    console.log('Fetch completed!');
    sessionStorage.removeItem('tabType');
  }

  subscribe(callback) {
    this.subscribers.add(callback);
    return () => this.subscribers.delete(callback);
  }

  notifySubscribers() {
    this.subscribers.forEach(callback => callback(this.isPolling));
  }

  getPollingStatus() {
    return this.isPolling;
  }

  getElapsedTime() {
    return this.elapsedTime;
  }
}

const pollingService = new PollingService();
export default pollingService;
