import {api} from '../../helpers';

function googleSignin() {
  api.get('/users/login/google').then(res => {
    const {url} = res.data;
    if (url) {
      window.location.href = url;
    }
  });
}
function signupUser(data) {
  return api.post('/users/signup', data).then(res => res.data);
}

export {signupUser};
