/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {STATUSES} from '../routes/EasyOnboard/FraudCheckScreens';
import {setFraudRules, setWidgetScreen} from '../store/actions';

export const useFraudCheck = initialData => {
  const dispatch = useDispatch();
  const [selectedAge, setSelectedAge] = useState(16);
  const [selectedStatus, setSelectedStatus] = useState();
  const [config, setConfig] = useState(initialData || {});
  const {fraudRules, widgetScreen} = useSelector(state => state.widgets);

  useEffect(() => {
    if (fraudRules?.length) {
      const updatedRules = fraudRules?.map(rule => {
        if (rule.page === widgetScreen?.page) {
          return {
            ...rule,
            [rule.page]: config,
          };
        }
        return rule;
      });

      dispatch(setFraudRules(updatedRules));
    }

    setSelectedAge(config?.limit);
    setSelectedStatus(STATUSES[0]);
  }, [config, STATUSES, setSelectedStatus, setSelectedAge]);

  useEffect(() => {
    const foundRule = fraudRules.find(
      rule => rule?.page === widgetScreen?.page,
    );
    if (foundRule) {
      dispatch(setWidgetScreen(foundRule));
      setConfig(foundRule[foundRule?.page]);
    }
  }, [fraudRules?.length]);

  return {
    setSelectedStatus,
    selectedStatus,
    setSelectedAge,
    selectedAge,
    setConfig,
    config,
    STATUSES,
  };
};

export const usePersistFraudData = ({
  COLLECTION,
  config,
  selectedStatus,
  actionKey,
}) => {
  const [data, setData] = useState();

  useLayoutEffect(() => {
    const actionValue = config[actionKey]?.toString();
    if (Array.isArray(COLLECTION)) {
      const action = COLLECTION.find(status => status.value === actionValue);
      if (action === undefined) {
        setData(selectedStatus);
      } else {
        setData(action);
      }
    } else {
      setData(actionValue);
    }
  }, [config[actionKey], selectedStatus]);

  return data;
};
