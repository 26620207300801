import React from 'react';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';

function SouthAfrica({lookupInputs, setLookupInputs}) {
  return (
    <>
      <div
        className={`${
          lookupInputs.type === null ? 'hidden' : 'block'
        } mt-8 sm:bg-white80 sm:p-6 rounded`}
      >
        <label htmlFor="idType" className="relative">
          {lookupInputs?.type?.label}
          <input
            id="idType"
            className="mt-2"
            placeholder={`Enter ${lookupInputs?.type?.label} here`}
            type="text"
            onChange={e =>
              setLookupInputs({
                ...lookupInputs,
                lookupValue: e.target.value,
              })
            }
          />
        </label>
      </div>
    </>
  );
}

export default connect(state => state, Actions)(SouthAfrica);
