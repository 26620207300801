import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  corruptedFile,
  onboardEmpty,
  ongoingIc,
} from '../../assets/images/images';
import {
  Filter,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  SearchInput,
  TableLayout,
} from '../../components';
import {
  Constants,
  filterOutEmptyValues,
  generateQueryParams,
  parseUrlParams,
} from '../../helpers';
import * as Actions from '../../store/actions';

import {PageContext, TourContext} from '../../helpers/context';
import EmptyState from '../../components/EmptyState';
import VerificationWrapper from './VerificationWrapper';
import {VerificationsTour} from '../../components/Tour';
import {getStatusTextAndStyles} from '../../helpers/getStatusTextAndStyles';
import VerificationLayout from './VerificationLayout';
import {useWidgetFlows} from '../../hooks/useWidgetFlows';
import {useAllApps} from '../../hooks/useApps';
import {
  getFilterOptions,
  getParams,
  handleSetIds,
  NoSearchResults,
  useClearSearchOnBackButton,
} from './utils';

// custom hook to clear search on back button click

function Verifications({children, isChild = false}) {
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const navigate = useNavigate();
  const {widgetFlows, isLoading} = useWidgetFlows();
  const dispatch = useDispatch();
  const {setPageName} = useContext(PageContext);
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const search = query.get('search');
  const [searchText, setSearchText] = useState(search);

  const {
    verifications: {verifications, loading, exportLoading},
    auth: {userDetails},
  } = useSelector(state => ({
    verifications: state?.verifications,
    auth: state?.auth,
  }));
  const {tourState, updateTourState} = useContext(TourContext);

  const getFlowName = useCallback(
    flowId => widgetFlows?.find(flow => flow.value === flowId)?.name,
    [widgetFlows],
  );
  useEffect(() => {
    setPageName('verifications');
  }, [setPageName]);
  const pageClick = selected => {
    setCurrentPage(selected);
  };
  const {userApps, appIds, isLoading: appsLoading} = useAllApps();

  useEffect(() => {
    const apiParams = getParams({
      limit,
      appIds,
      currentPage,
      setCurrentPage,
      setSearchText,
      setFiltersApplied,
    });

    !appsLoading &&
      userApps &&
      appIds &&
      !isChild &&
      dispatch(Actions?.getVerifications(apiParams));
  }, [userApps, currentPage, limit, appsLoading, appIds, isChild, dispatch]);

  const verificationsData = useMemo(
    () => (verifications ? verifications?.data : []),
    [verifications],
  );

  const filterOptions = useMemo(() => getFilterOptions(userApps), [userApps]);

  const handleFilter = useCallback(
    selectedOptions => {
      const {...filteredOptions} = filterOutEmptyValues(selectedOptions);
      const queryParams = generateQueryParams(filteredOptions);

      const apiParams = {
        page: 1,
        limit,
        appIds,
        ...filteredOptions,
      };

      if (filteredOptions?.app_ids?.length > 0) {
        apiParams.appIds = filteredOptions?.app_ids;
      } else {
        apiParams.appIds = appIds;
      }

      setCurrentPage(1);
      navigate(`?${queryParams}&page=1`);

      appIds && dispatch(Actions?.getVerifications(apiParams));
      setFiltersApplied(true);
      setOpenFilter(false);
    },
    [appIds, dispatch, limit, navigate],
  );

  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);

    dispatch(Actions?.getVerifications({page: 1, limit, appIds: appIds}));
    setSearchText('');
    setOpenFilter(true);
  };

  const getVerificationsWithSearch = (text, appIds) => {
    const apiParams = {
      page: 1,
      limit,
      term: text,
      appIds,
    };
    setCurrentPage(1);

    dispatch(Actions?.getVerifications(apiParams));
    setOpenFilter(false);
  };

  const clearSearch = () => {
    setSearchText('');
    setCurrentPage(1);
    navigate('');
    getVerificationsWithSearch('', appIds);
  };

  // if user clicks back button on browser, clear search
  useClearSearchOnBackButton(clearSearch);

  const canExport =
    userDetails?.permissions?.onboardPermissions?.exportVerifications;

  return (
    <VerificationWrapper
      action={
        <Filter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          handleFilter={handleFilter}
          resetUrl={handleResetFilter}
          filterOptions={filterOptions}
        />
      }
    >
      {tourState?.verifications_tour ? (
        <VerificationsTour
          updateTourState={updateTourState}
          DATA={verificationsData}
          loading={loading}
        />
      ) : null}

      <VerificationLayout>
        {children ?? (
          <>
            <div className="flex flex-col items-start justify-between gap-6 mt-4 mb-6 md:flex-row md:items-center md:gap-0 sm:mt-0">
              <h2 className="text-base font-medium text-grey sm:text-lg">
                All Verifications
              </h2>
              <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
                <SearchInput
                  onChange={text => {
                    setSearchText(text);
                    if (text) {
                      getVerificationsWithSearch(text, appIds);
                      navigate(`?search=${text}`);
                    } else {
                      clearSearch();
                    }
                  }}
                  defaultValue={searchText}
                  placeholder="Search by name, or, reference ID"
                />
                <FilterButton
                  openFilter={openFilter}
                  setOpenFilter={setOpenFilter}
                />

                <PrimaryButton
                  buttonText="Export"
                  xPadding="px-[65px]"
                  className="hidden sm:flex"
                  onClick={() => {
                    const apiParams = parseUrlParams();
                    apiParams.limit = limit;
                    apiParams.appIds = appIds;

                    dispatch(Actions?.exportVerifications(apiParams));
                  }}
                  loading={exportLoading}
                  disabled={exportLoading || !canExport}
                />

                <div
                  className="fixed bottom-0 left-0 right-0 z-10 px-4 py-2 overflow-x-hidden sm:hidden bg-white80"
                  style={{boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)'}}
                >
                  <PrimaryButton
                    buttonText="Export"
                    xPadding="p-[35px]"
                    className="w-full bottom-2"
                    disabled
                  />
                </div>
              </div>
            </div>
            {loading || isLoading ? (
              <Loader />
            ) : !loading && !verificationsData?.length && search !== null ? (
              !filtersApplied ? (
                <EmptyState body="No data found" src={corruptedFile} noBtn />
              ) : (
                <EmptyState
                  body="No results found"
                  noBtn
                  customBtn={
                    <button
                      onClick={handleResetFilter}
                      className="text-brandBlue p-4 text-sm font-medium"
                    >
                      Update preferences
                    </button>
                  }
                />
              )
            ) : !loading &&
              !verificationsData.length &&
              search === null &&
              (!verifications || !appIds) ? (
              // eslint-disable-next-line react/jsx-indent
              <EmptyState
                body="Identify your users by viewing their verification details"
                src={onboardEmpty}
                noBtn
              />
            ) : (
              <>
                {!verificationsData.length && search !== null ? (
                  <NoSearchResults />
                ) : (
                  <TableLayout negativeMargins>
                    <thead className="font-semibold uppercase text-small">
                      <tr className="bg-white80">
                        <th className="pt-[22px] pb-[18px] pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-grey whitespace-nowrap">
                          <div className="flex items-center gap-1">
                            <span>name</span>
                          </div>
                        </th>
                        <th className="pt-[22px] pb-[18px] pl-0 font-medium text-left text-grey whitespace-nowrap">
                          <div className="flex items-center gap-1">
                            <span>Flows</span>
                          </div>
                        </th>
                        <th className="pt-[22px] pb-[18px] pl-0 font-medium text-left text-grey whitespace-nowrap">
                          <div className="flex items-center gap-1">
                            <span>Reference ID</span>
                          </div>
                        </th>
                        <th className="pt-[22px] pb-[18px] pl-0 font-medium text-left text-grey whitespace-nowrap">
                          <div className="flex items-center gap-1">
                            <span>status</span>
                          </div>
                        </th>

                        <th className="pt-[22px] pb-[18px] pl-0 font-medium text-left text-grey whitespace-nowrap">
                          <div className="flex items-center gap-1">
                            <span>Date/Time created</span>
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody className="text-sm">
                      {verificationsData &&
                        verificationsData.map((verification, i) => (
                          <tr
                            className="text-sm border-b cursor-pointer border-grey60"
                            key={i}
                            onClick={() => {
                              navigate(
                                Constants.PAGES_URL.VERIFICATIONSBIODATA.replace(
                                  ':slug',
                                  verification?.verification_id,
                                ),
                                {
                                  state: {
                                    sessionId: verification?.verification_id,
                                  },
                                },
                              );
                              handleSetIds(verification);
                            }}
                          >
                            <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-body whitespace-nowrap">
                              {verification.first_name
                                ? `${verification.first_name} ${verification.last_name} ${verification.middle_name}`
                                : verification.business_name
                                ? verification.business_name
                                : '-'}
                            </td>
                            <td className="p-4 pl-0 text-body whitespace-nowrap">
                              {getFlowName(verification?.flow_id) ||
                                verification?.flow_id ||
                                '-'}
                            </td>
                            <td className="p-4 pl-0 text-body whitespace-nowrap">
                              {verification?.reference_id}
                            </td>
                            <td className="p-4 pl-0 whitespace-nowrap">
                              <div
                                className={`uppercase pt-[5px] pb-[3px] px-2 text-xs font-medium   rounded-full w-fit flex items-start gap-[2px] ${
                                  getStatusTextAndStyles(
                                    verification?.status?.toLowerCase(),
                                  ).styles
                                }`}
                              >
                                {
                                  getStatusTextAndStyles(
                                    verification?.status?.toLowerCase(),
                                  ).text
                                }
                                {verification?.status?.toLowerCase() ===
                                  'ongoing' && <img src={ongoingIc} alt="" />}
                                {/* <img src={expiredIc} alt="" /> */}
                              </div>
                            </td>{' '}
                            <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                              {moment(verification?.datetime).format(
                                'Do MMM, YYYY h:mm A',
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </TableLayout>
                )}

                {!loading && verificationsData?.length !== 0 && (
                  <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
                    <div className="flex items-center text-body text-tiny">
                      <PageLimit
                        onLimitChange={setLimit}
                        // className="mt-3 mt-sm-0"
                        total={
                          verifications && verifications?.meta?.total_count
                        }
                        limit={verificationsData && verificationsData?.length}
                      />
                    </div>
                    <div className="mb-8 sm:mb-0">
                      <Pagination
                        total={
                          verifications &&
                          Math.ceil(verifications?.meta?.total_count / limit)
                        }
                        current={+currentPage}
                        onPageChange={activePage => {
                          pageClick(activePage);
                          const searchParams = new URLSearchParams(
                            document.location.search,
                          );
                          searchParams.set('page', activePage);
                          const newSearchParams = searchParams.toString();
                          navigate(`?${newSearchParams}`);
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </VerificationLayout>
    </VerificationWrapper>
  );
}

export default Verifications;
