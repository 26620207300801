import moment from 'moment/moment';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';

import {isObjectEmpty} from '../../../../helpers/isObjectEmpty';
import {capitalizeEveryWord} from '../../../../helpers';
import {formatNumberToCurrency} from '../../../../helpers/formatNumberToCurrency';
import {watermark} from '../../../../helpers/pdfUtils';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const generatePDFStructure = data => {
  const generateDocInfo = data
    .filter(doc => !isObjectEmpty(doc.data))
    .map(doc => doc);

  const content = [];

  generateDocInfo.forEach(section => {
    const headerText = {text: section.title, style: 'header'};
    const headerCell = {text: '', border: [false, false, false, true]};
    const headerRow = [headerText, headerCell];
    const dataRows = [];

    Object.entries(section.data).forEach(([key, value]) => {
      if (!['transaction_id'].includes(key)) {
        const pair = [
          {
            width: 200,
            bold: true,
            margin: [0, 0, 20, 10],
            text: capitalizeEveryWord(`${key}:`.replaceAll('_', ' ')),
          },
          {
            width: '*',
            margin: [0, 0, 20, 0],
            text: key.includes('time')
              ? moment(+value * 1000).format('HH:mm a')
              : key === 'amount'
              ? formatNumberToCurrency(value)
              : value,
          },
        ];

        dataRows.push(pair);
      }
    });

    content.push({
      style: 'tableExample',
      table: {widths: [200, '*'], body: [headerRow]},
    });

    content.push({
      style: 'columnStyle',
      layout: 'noBorders',
      table: {widths: [120, 420], body: [...dataRows]},
    });
  });

  return content;
};

const createHeader = subTitle => [
  {
    text: 'SUSPICIOUS ACTIVITY REPORT',
    fontSize: 22,
    bold: true,
    alignment: 'center',
    margin: [0, 0, 0, 4],
  },
  {
    text: subTitle,
    fontSize: 14,
    alignment: 'center',
  },
];

const createSuspiciousActivityTable = () => ({
  style: 'tableExample',
  table: {
    widths: [200, '*'],
    body: [
      [
        {
          text: 'Suspicious Activity',
          style: 'header',
        },
        {
          text: '',
          border: [false, false, false, true],
        },
      ],
    ],
  },
});

const createSuspiciousActivityText = () => ({
  text: `This transaction has been flagged due to the fact that the sender is part of a PEP List
      in Nigeria and also the Receiver is from an high-risk country`,
  margin: [0, 10],
});

const createConclusionTable = () => ({
  style: 'tableExample',
  table: {
    widths: [200, '*'],
    body: [
      [
        {
          text: 'Conclusion',
          style: 'header',
        },
        {
          text: '',
          border: [false, false, false, true],
        },
      ],
    ],
  },
});

const createConclusionText = () => ({
  text: `This Account is recommended for immediate reporting, continuous surveillance or closure`,
  margin: [0, 10],
  bold: true,
});

const createFooter = () => ({
  text: `The unedited pdf was generated automatically by Dojah ${moment(
    new Date(),
  ).format('MMM DD YYYY @hh:mm A')}`,
  style: 'footerStyle',
});

const styles = {
  tableExample: {
    margin: [0, 40, 0, 0],
  },
  header: {
    bold: true,
    fontSize: 15,
    margin: [0, 3, 0, 3],
    alignment: 'center',
  },
  columnStyle: {
    margin: [0, 10, 0, 0],
  },
  footerStyle: {
    alignment: 'center',
    fontSize: 10,
    margin: [0, 20],
    italics: true,
    color: 'grey',
  },
};

const generatePDF = (contents, subTitle) => {
  const documentDefinition = {
    watermark,
    content: [
      ...createHeader(subTitle),
      ...contents,
      createSuspiciousActivityTable(),
      createSuspiciousActivityText(),
      createConclusionTable(),
      createConclusionText(),
    ],
    footer: createFooter(),
    styles,
  };

  try {
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.download('SUSPICIOUS ACTIVITY REPORT.pdf'.toLowerCase());
  } catch (error) {
    console.error('PDF Generation Error:', {
      message: error.message,
      stack: error.stack,
      docDefinition: JSON.stringify(documentDefinition, null, 2),
    });
    // Log specific error types
    if (error.message.includes('not found in virtual file system')) {
      console.error('Image loading error - Check image paths and availability');
    }

    // Handle the error appropriately based on your application needs
    throw new Error(`PDF generation failed: ${error.message}`);
  }
};

export {generatePDFStructure, generatePDF};
