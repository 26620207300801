import React from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Modal, PrimaryButton, SecondaryButton} from '../../components';
import * as Actions from '../../store/actions';

function ConfirmUnSaveFlowModal({
  handleSave,
  alertModal = false,
  setAlertModal,
  isLoading,
  handleExit,
}) {
  const navigate = useNavigate();
  return (
    <Modal
      show={alertModal}
      modalTitle="You have unsaved actions"
      onClose={setAlertModal}
      extraModalClasses="max-w-[512px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="mt-4">
        <p className="text-start text-sm text-body font-medium">
          Your unsaved changes will be lost. Save changes before closing?
        </p>
      </div>

      <div className="mt-4 flex items-center gap-4">
        <SecondaryButton
          onClick={handleExit}
          buttonText="Exit &  lose changes"
          className="pt-[14px]"
        />
        <PrimaryButton
          onClick={handleSave}
          buttonText="Save"
          className="grow"
          loading={isLoading}
          disabled={isLoading}
        />
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(ConfirmUnSaveFlowModal);
