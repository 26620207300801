import React, {useCallback, useState} from 'react';

export default function CustomRadio({options, onChange, value = ''}) {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = useCallback(
    e => {
      const newValue = e.target.value;
      setSelectedValue(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <div className="flex flex-col gap-2">
      {options.map(option => (
        <label
          key={option.value}
          className="relative flex flex-row items-center gap-2 font-normal transition ease-in-out duration-500 bg-white80 pl-2 pr-6 py-[11px] rounded"
        >
          <input
            type="radio"
            value={option.value}
            name={option.name || 'radio'}
            checked={selectedValue === option.value}
            onChange={handleChange}
            className="w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue"
          />
          <span className="text-sm text-body -mb-1">{option.label}</span>
          {process.env.REACT_APP_APP_ENV_MODE !== 'production' && (
            <p className="text-xs ml-auto  leading-[18px] text-grey20">
              {option.price}
            </p>
          )}
        </label>
      ))}
    </div>
  );
}
