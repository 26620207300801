import React, {useCallback, useState, useContext, useMemo, memo} from 'react';
import {Link} from 'react-router-dom';
import {closeMenuGrey} from '../assets/images/images';
import * as Actions from '../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import SingleDropdown from './SingleDropdown';
import AddCompanyModal from '../routes/Dashboard/AddCompanyModal';
import {ToggleHeader} from './Toggle';
import {LogoContext} from '../helpers/context';
import {SubSidebar} from './SubDomainSideBars';
const SideMenu = memo(SubSidebar);

function Sidebar({setOpenNav, openNav}) {
  const logo = useContext(LogoContext);
  const {
    apps: {live},
    auth: {userDetails},
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const [selected, setSelected] = useState(userDetails?.company);

  const handleSwitchCompany = useCallback(
    data => {
      if (data) {
        dispatch(Actions?.switchCompany(data?._id));
      }
    },
    [dispatch],
  );

  const isPartnerCompany =
    selected?.is_partner_company || userDetails?.company?.is_partner_company;

  const appLogo = useMemo(
    () =>
      isPartnerCompany || localStorage.subdomain
        ? localStorage?.companyLogo
        : logo,
    [isPartnerCompany, logo],
  );

  // console.log('Rendered - Sidebar', '>>');

  return (
    <>
      <AddCompanyModal
        open={addCompanyModal}
        setOpen={() => setAddCompanyModal(!addCompanyModal)}
      />
      <aside className="fixed flex-auto h-full w-full lg:w-[16.4%] bg-white80 lg:bg-sidebarBlue px-4 lg:px-0 pt-[31px] pb-12 overflow-auto">
        <Link
          to="/"
          className="hidden text-white h-[27px] lg:block ml-[34px] mb-[27px] w-fit"
        >
          <img src={appLogo} alt="company's logo" style={{maxHeight: '40px'}} />
        </Link>
        <div className="flex fixed z-10 top-0 left-0 items-center drop-shadow p-4 justify-between lg:hidden w-[350px] bg-white">
          <p className="items-center font-medium text-grey">Menu</p>
          <div className="flex items-center sm:hidden">
            <span
              role="switch"
              className={`text-xs uppercase px-2 pt-[5px] pb-[3px] rounded-full mr-2 ${
                live
                  ? '20 bg-success20 text-status-200'
                  : 'bg-statusBg-500 text-status-500'
              } font-medium`}
            >
              {live ? 'live mode' : 'sandbox'}
            </span>
            <ToggleHeader />
            <span
              role="switch"
              className={`text-sm ml-2 mr-1  ${
                !live ? '20 text-grey40' : 'text-grey'
              } font-medium`}
            >
              Live
            </span>
          </div>
          <img
            src={closeMenuGrey}
            alt=""
            width={24}
            height={24}
            onClick={() => setOpenNav(!openNav)}
          />
        </div>

        <div
          className="mt-6 md:hidden"
          style={{visibility: openNav ? 'visible' : 'hidden'}}
        >
          <SingleDropdown
            dropdownData={userDetails?.companies || []}
            selected={selected}
            setSelected={data => {
              setSelected(data);
              handleSwitchCompany(data);
            }}
            spanText={userDetails?.company?.name || selected?.name}
            width="w-full"
          />
        </div>
        <div className={`w-60 flex-shrink-0 ${!openNav && 'hidden'} lg:block`}>
          <SideMenu />
        </div>
      </aside>
    </>
  );
}

export default memo(Sidebar);
