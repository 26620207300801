import React from 'react';
import {Modal} from '../../../../components';
import {
  closeModal,
  externalRecords,
  internalRecords,
} from '../../../../assets/images/images';

function UploadTransactionsModal({
  show,
  onClose,
  openCreateManualTransactionsModal,
  openExternal,
}) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <h3 className="font-medium text-base text-grey">Add Transactions</h3>

          <button
            onClick={() => {
              onClose(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>{' '}
        <div className="mt-6 flex flex-col gap-4 font-inter">
          <button
            onClick={openCreateManualTransactionsModal}
            className="p-6 flex items-center gap-4 bg-white80 rounded"
          >
            <img src={internalRecords} alt="internal-records" />
            <p className="text-grey font-medium text-sm">Expected Payment</p>
          </button>
          <button
            onClick={openExternal}
            className="p-6 flex items-center gap-4 bg-white80 rounded"
          >
            <img src={externalRecords} alt="external-records" />
            <p className="text-grey font-medium text-sm">Transactions</p>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default UploadTransactionsModal;
