import React from 'react';
import Select from 'react-select';
import {userGreen, userRed} from '../../../assets/images/images';
import {customStyles, DropdownIndicator} from '../../../components';
import {useFraudCheck, usePersistFraudData} from '../../../hooks/useFraudCheck';
import {Card, Wrapper} from '../FraudCheckScreens';

export const DetectWithAgeLimit = () => {
  const {
    setSelectedStatus,
    selectedStatus,
    setSelectedAge,
    setConfig,
    config,
    STATUSES,
  } = useFraudCheck({
    limit: 16,
    action: 'pending',
  });

  const ageData = usePersistFraudData({
    config,
    selectedStatus,
    actionKey: 'limit',
  });

  const statusData = usePersistFraudData({
    config,
    selectedStatus,
    actionKey: 'action',
    COLLECTION: STATUSES,
  });

  return (
    <Wrapper>
      <Card
        extension={
          <div className="flex flex-col gap-1">
            <p className="text-grey font-medium">Age limit</p>
            <p className="text-body text-sm gap-1">
              If the age picked from the response is less than the age limit set
              or doesn’t match the response
            </p>
          </div>
        }
      />
      <Card
        extension={<p className="text-grey font-medium">Age limit</p>}
        src={userGreen}
      >
        <div className="text-start w-full">
          <label htmlFor="age_limit" className="relative">
            Set age limit
            {ageData !== undefined && (
              <input
                type="number"
                id="age_limit"
                name="age_limit"
                className="mt-2"
                value={ageData}
                onChange={event => {
                  const value = event.target.value;

                  setSelectedAge(value);
                  setConfig({...config, limit: value});
                }}
              />
            )}
          </label>
        </div>
      </Card>
      <Card
        extension={
          <p className="text-grey font-medium">
            If the age limit is less or does not match
          </p>
        }
        src={userRed}
      >
        <div className="text-start w-full">
          <label htmlFor="status" className="mb-2 relative">
            Set status to
          </label>
          {statusData !== undefined && (
            <Select
              defaultValue={statusData}
              value={statusData || STATUSES[0]}
              onChange={status => {
                setSelectedStatus(status);
                setConfig({...config, action: status.value});
              }}
              options={STATUSES}
              className="basic-multi-select"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              menuPlacement="auto"
              isSearchable={false}
            />
          )}
        </div>
      </Card>
    </Wrapper>
  );
};
