export const checkKey = key => {
  switch (key.toLowerCase()) {
    case 'firstname':
      return 'First Name';
    case 'lastname':
      return 'Last Name';
    case 'birthdate':
      return 'Date of Birth';
    case 'birthlga':
      return 'LGA of Birth';
    case 'birthstate':
      return 'State of Birth';
    case 'birthcountry':
      return 'Country of Birth';
    case 'middlename':
      return 'Middle Name';
    case 'maidenname':
      return 'Maiden Name';
    case 'centralid':
      return 'Central ID';
    case 'documentno':
      return 'Document Number';
    case 'educationallevel':
      return 'Educational Level';
    case 'employmentstatus':
      return 'Employment Status';
    case 'maritalstatus':
      return 'Marital Status';
    case 'othername':
      return 'Other Name';
    case 'pfirstname':
      return 'P First Name';
    case 'pmiddlename':
      return 'P Middle Name';
    case 'psurname':
      return 'P Surname';
    case 'nspokenlang':
      return 'N Spoken Language';
    case 'ospokenlang':
      return 'O Spoken Language';
    case 'residencestatus':
      return 'Residence Status';
    case 'trackingid':
      return 'Tracking ID';
    case 'telephoneno':
      return 'Mobile Number';
    case 'residenceaddressline1':
      return 'Residence Address Line 1';
    case 'residencelga':
      return 'Residence Lga';
    case 'residencestate':
      return 'Residence State';
    case 'residencetown':
      return 'Residence Town';
    case 'selforiginlga':
      return 'Self Origin Lga';
    case 'selforiginplace':
      return 'Self Origin Place';
    case 'selforiginstate':
      return 'Self Origin State';
    case 'customer':
      return 'Customer';
    case 'nationality':
      return 'Nationality';

    default:
      return key.split('_').join(' ');
  }
};
