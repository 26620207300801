import {useContext, useEffect, useState} from 'react';
import {EasyOnboardContext} from '../helpers/context/EasyOnboardContext';

export function useBlockNavigation() {
  const [alertModal, setAlertModal] = useState(false);
  const [nextLocation, setNextLocation] = useState('');
  const {trackChanges} = useContext(EasyOnboardContext);

  useEffect(() => {
    const handleNavigation = (event, link) => {
      if (trackChanges) {
        event.preventDefault();
        setAlertModal(true);
        const urlString = link.toString();
        const url = new URL(urlString);
        const route = url.pathname;
        setNextLocation(route);
      }
    };

    const sidebarLinks = document.querySelectorAll('.nav-item');
    if (trackChanges) {
      sidebarLinks.forEach(link => {
        link.addEventListener('click', e => handleNavigation(e, link));
      });

      window.onbeforeunload = function (e) {
        e.preventDefault();
        e.returnValue =
          'You have unsaved changes. Are you sure you want to leave this page?';
      };
    } else {
      sidebarLinks.forEach(link => {
        link.removeEventListener('click', e => handleNavigation(e, link));
      });
      window.onbeforeunload = null;
    }

    return () => {
      sidebarLinks.forEach(link => {
        link.removeEventListener('click', e => handleNavigation(e, link));
      });
      window.onbeforeunload = null;
    };
  }, [trackChanges, nextLocation]);

  return {alertModal, setAlertModal, nextLocation, setNextLocation};
}
