/* eslint-disable react-hooks/exhaustive-deps */

import React, {useContext, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {Loader, RowData} from '../../../../components';
import * as Actions from '../../../../store/actions';
import {isObjectEmpty} from '../../../../helpers/isObjectEmpty';
import Layout from './Layout';
import {collapseNestedObject} from '../../../../helpers/collapseNestedObject';
import {PageContext} from '../../../../helpers/context';
import {useQuery} from 'react-query';
import {fetchCase} from '../../../../requests/queries/flows';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';

function CasesDetails({flows: {cases_meta, cases}, getNextAndPrevId}) {
  const {slug, id} = useParams();

  const {data: caseData, isLoading} = useQuery(['a_case_details', slug], () =>
    fetchCase(slug),
  );

  const caseDetails = useMemo(
    () => !isLoading && caseData && caseData?.entity,
    [caseData, isLoading],
  );

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  useEffect(() => {
    getNextAndPrevId({
      currentCases: cases?.data,
      currentId: slug,
      page: cases?.current_page,
      allPages: cases?.total_of_page,
    });
  }, [getNextAndPrevId, id, slug]);

  const USER = useMemo(() => caseDetails?.transaction?.esd_user, [caseDetails]);
  const TRANSACTION = useMemo(() => caseDetails?.transaction, [caseDetails]);
  const ADDRESS = useMemo(() => cases_meta?.address, [cases_meta]);
  const PAYMENT = useMemo(() => cases_meta?.payment, [cases_meta]);
  const SENDER = useMemo(() => cases_meta?.sender, [cases_meta]);
  const RECEIVER = useMemo(() => cases_meta?.receiver, [cases_meta]);
  const META = useMemo(
    () => JSON.parse(caseDetails?.transaction?.meta || '{}'),
    [caseDetails],
  );

  const USER_DETAILS = useMemo(
    () => [
      {
        leftText: 'Full name',
        rightText: USER?.user_name || '-',
      },
      {
        leftText: 'email address',
        rightText: USER?.user_email || '-',
      },
      {
        leftText: 'phone number',
        rightText: USER?.user_mobile || '-',
      },
    ],
    [USER],
  );

  const TRANSACTION_DETAILS = useMemo(
    () => [
      {
        leftText: 'Transaction gateway',
        rightText: TRANSACTION?.channel.replaceAll('_', ' ') || '-',
      },
      {
        leftText: 'transaction time',
        rightText:
          moment(+TRANSACTION?.transaction_time).format('HH:mm a') || '-',
      },
      {
        leftText: 'Amount',
        rightText: shortenLargeNumbers(
          TRANSACTION?.amount || 0,
          TRANSACTION?.currency,
        ),
      },
      {
        leftText: 'Currency',
        rightText: TRANSACTION?.currency || '-',
      },
    ],
    [TRANSACTION],
  );

  const ADDRESS_DETAILS = [
    {
      leftText: 'STREET',
      rightText: ADDRESS?.street || '-',
    },
    {
      leftText: 'COUNTRY',
      rightText: ADDRESS?.country || '-',
    },
    {
      leftText: 'CITY',
      rightText: ADDRESS?.city || '-',
    },
  ];

  const metaDetails = Array.isArray(META)
    ? Object.entries(collapseNestedObject(META) || {})
    : Object.entries(collapseNestedObject(META) || {});

  const OTHER_DETAILS = metaDetails?.map(d => ({
    leftText: d[0].replaceAll('_', ' '),
    rightText: d[1]?.toString() || '-',
  }));

  const CARD = [
    {
      leftText: 'card last four',
      rightText: PAYMENT?.card_last_four || '-',
    },
    {
      leftText: 'expired date',
      rightText: PAYMENT?.expired_date || '-',
    },
    {
      leftText: 'name of card',
      rightText: PAYMENT?.name_of_card || '-',
    },
    {
      leftText: 'payment method type',
      rightText: PAYMENT?.payment_method_type || '-',
    },
    {
      leftText: 'scheme',
      rightText: PAYMENT?.scheme || '-',
    },
  ];
  const BANK_SENDER = [
    {
      leftText: 'Account name',
      rightText: SENDER?.account_name || '-',
    },
    {
      leftText: 'Bank name',
      rightText: SENDER?.bank_name || '-',
    },
    {
      leftText: 'Account number',
      rightText: SENDER?.account_number || '-',
    },
    {
      leftText: 'Country',
      rightText: SENDER?.country || '-',
    },
  ];
  const BANK_RECEIVER = [
    {
      leftText: 'Account name',
      rightText: RECEIVER?.account_name || '-',
    },
    {
      leftText: 'Bank name',
      rightText: RECEIVER?.bank_name || '-',
    },
    {
      leftText: 'Account number',
      rightText: RECEIVER?.account_number || '-',
    },
    {
      leftText: 'Country',
      rightText: RECEIVER?.country || '-',
    },
  ];

  return (
    <Layout>
      <div className=" xl:ml-4 xl:basis-[43.91%] mt-6 xl:mt-0 px-4 grow xl:grow-0 xl:p-8 pt-4 xl:pt-[36px] bg-white80 xl:bg-white">
        {isLoading ? (
          <Loader height={40} />
        ) : (
          <div className="flex flex-col gap-8">
            <div>
              <div className="flex justify-between">
                <h2 className="font-medium">User Data</h2>
              </div>
              <div className="flex flex-col mt-6">
                {USER_DETAILS?.map((item, index) => (
                  <RowData key={index} {...item} />
                ))}
              </div>
            </div>

            <div>
              <div className="flex justify-between items-center">
                <h2 className="font-medium">Transactions details</h2>
                {/*<SecondaryButton*/}
                {/*  className="flex items-start gap-2 mt-4"*/}
                {/*  fontSize="text-xs"*/}
                {/*  xPadding="px-2"*/}
                {/*  yPadding="pt-[9px] pb-[7px]"*/}
                {/*  btnHeight="h-7"*/}
                {/*  buttonText="View Transactions"*/}
                {/*  onClick={() =>*/}
                {/*    navigate(*/}
                {/*      `${Constants.PAGES_URL.FLOWS_ACCOUNT_TRANSACTIONS.replace(*/}
                {/*        '/:slug',*/}
                {/*        '',*/}
                {/*      )}/${id}`,*/}
                {/*      {state: {name: USER?.name}},*/}
                {/*    )*/}
                {/*  }*/}
                {/*/>*/}
              </div>
              <div className="flex flex-col mt-6">
                {TRANSACTION_DETAILS?.map((item, index) => (
                  <RowData key={index} {...item} />
                ))}
              </div>
            </div>
            <div>
              <h2 className="font-medium">Address details</h2>
              <div className="flex flex-col mt-6">
                {ADDRESS_DETAILS?.map((item, index) => (
                  <RowData key={index} {...item} />
                ))}
              </div>
            </div>

            {!isObjectEmpty(PAYMENT) && (
              <div>
                <h2 className="font-medium">Card Transaction</h2>
                <div className="flex flex-col mt-6">
                  {CARD?.map((item, index) => (
                    <RowData key={index} {...item} />
                  ))}
                </div>
              </div>
            )}

            {!isObjectEmpty(SENDER) && (
              <div>
                <h2 className="font-medium">Sender</h2>
                <div className="flex flex-col mt-6">
                  {BANK_SENDER?.map((item, index) => (
                    <RowData key={index} {...item} />
                  ))}
                </div>
              </div>
            )}

            {!isObjectEmpty(RECEIVER) && (
              <div>
                <h2 className="font-medium">Receiver</h2>
                <div className="flex flex-col mt-6">
                  {BANK_RECEIVER?.map((item, index) => (
                    <RowData key={index} {...item} />
                  ))}
                </div>
              </div>
            )}
            {!isObjectEmpty(META) && (
              <div>
                <h2 className="font-medium">Meta</h2>
                <div className="flex flex-col mt-6">
                  {OTHER_DETAILS?.map((item, index) => (
                    <RowData key={index} {...item} />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}

export default connect(state => state, Actions)(CasesDetails);
