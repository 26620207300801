import React from 'react';
import {connect} from 'react-redux';
import {DashboardLayout} from '../../components';
import {verificationsIc} from '../../assets/images/images';

function VerificationWrapper({children, action}) {
  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={verificationsIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Verifications</p>
        </div>
      }
    >
      <div className="flex items-center justify-between gap-6 mt-6 mb-6 md:gap-0 sm:mt-0">
        {action}
      </div>

      {children}
    </DashboardLayout>
  );
}

export default connect(state => state, null)(VerificationWrapper);
