import {Constants} from '../../helpers';
import {getCookieValue} from '../../helpers/getCookie';
import reduxApi from '../../helpers/reduxApi';
import store from '../index';
import {
  GET_AML_HISTORY,
  GET_AML_HISTORIES,
  AML_LOADING,
  AML_LOOKUP_LOADING,
  UPDATED,
  GET_AML_PROFILE,
  AML_CONFIG_LOADING,
  GET_AML_CONFIG,
  BATCH_AML_LOOKUP,
  GET_BATCH_AML_HISTORY,
} from '../types';
import {toast} from 'react-toastify';

const sandboxEnv = getCookieValue('env') === 'Sandbox';
const SANDBOX_APP_ID =
  store.getState()?.apps?.allApps?.apps[0]?._id || 'no-app-created';

export const getAMLHistories = ({page, limit, companyId, start, end}) =>
  reduxApi(
    `/users/kyc-dashboard/aml/history?company_id=${companyId || ''}&limit=${
      limit || 10
    }${page ? `&page=${page}` : '&page=1'}${start ? `&start=${start}` : ''}${
      end ? `&end=${end}` : ''
    }`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: null, type: GET_AML_HISTORIES});
        dispatch({payload: data, type: GET_AML_HISTORIES});
      },
    },
    {error: 'ERROR', loading: AML_LOADING, responder: GET_AML_HISTORIES},
  );
export const getBusinessAMLHistories = ({page, limit, companyId, start, end}) =>
  reduxApi(
    `/users/kyc-dashboard/aml/history?model_type=business&company_id=${
      companyId || ''
    }&limit=${limit || 10}${page ? `&page=${page}` : '&page=1'}${
      start ? `&start=${start}` : ''
    }${end ? `&end=${end}` : ''}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: null, type: GET_AML_HISTORIES});
        dispatch({payload: data, type: GET_AML_HISTORIES});
      },
    },
    {error: 'ERROR', loading: AML_LOADING, responder: GET_AML_HISTORIES},
  );
export const getIndividualAMLHistories = ({
  page,
  limit,
  companyId,
  start,
  end,
}) =>
  reduxApi(
    `/users/kyc-dashboard/aml/history?model_type=individual&company_id=${
      companyId || ''
    }&limit=${limit || 10}${page ? `&page=${page}` : '&page=1'}${
      start ? `&start=${start}` : ''
    }${end ? `&end=${end}` : ''}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: null, type: GET_AML_HISTORIES});
        dispatch({payload: data, type: GET_AML_HISTORIES});
      },
    },
    {error: 'ERROR', loading: AML_LOADING, responder: GET_AML_HISTORIES},
  );
export const getAMLHistory = ({
  id,
  watchlist_history_id,
  verification,
  appId,
  slug,
  lookup_id,
  navigate,
  selectedProfileId,
  entityType,
  page,
}) =>
  reduxApi(
    `/users/kyc-dashboard/aml/history/${id}?watchlist_history_id=${
      watchlist_history_id || ''
    }${entityType ? `&entity_type=${entityType}` : ''}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: null, type: GET_AML_HISTORY});

        const profileId = data?.entity?.profile_ids[0]?.profile_id;
        dispatch({payload: data, type: GET_AML_HISTORY});

        if (!profileId) {
          dispatch({payload: null, type: GET_AML_PROFILE});
          return;
        }

        if (verification) {
          dispatch(getAMLProfile({id: selectedProfileId || profileId, appId}));
          if (window.location.pathname?.includes(`=${profileId}`)) return;

          !selectedProfileId &&
            navigate(`/verifications/aml-check/${slug}=${profileId}`);
        } else {
          navigate(`${page.replace(':slug', lookup_id)}`);
        }
      },
      error: (data, dispatch) => {
        dispatch({payload: null, type: GET_AML_PROFILE});
        dispatch({payload: null, type: GET_AML_HISTORY});
      },
    },
    {error: 'ERROR', loading: AML_LOADING, responder: GET_AML_HISTORY},
  );
export const getAMLProfile = ({id, appId}, organisation) =>
  reduxApi(
    `/users/kyc-dashboard/aml${organisation ? '/organisation' : ''}?app_id=${
      sandboxEnv ? SANDBOX_APP_ID : appId
    }&profile_id=${id}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_AML_PROFILE});
      },
      error: (data, dispatch) => {
        dispatch({payload: null, type: GET_AML_PROFILE});
      },
    },
    {error: 'ERROR', loading: AML_LOADING, responder: GET_AML_PROFILE},
  );
export const getAMLProfileByName = data =>
  reduxApi(
    `/users/kyc-dashboard/aml/lookup/relative`,
    'POST',
    {...data},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_AML_PROFILE});
      },
      error: (data, dispatch) => {
        dispatch({payload: null, type: GET_AML_PROFILE});
      },
    },
    {error: 'ERROR', loading: AML_LOADING, responder: GET_AML_PROFILE},
  );
export const performAMLLookup = (amlData, callback) =>
  reduxApi(
    `/users/kyc-dashboard/aml/lookup`,
    'POST',
    {...amlData},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_AML_HISTORY});
        dispatch({type: UPDATED});
        callback(2);
      },
    },
    {error: 'ERROR', loading: AML_LOOKUP_LOADING, responder: GET_AML_HISTORY},
  );
export const performBusinessAMLLookup = (amlData, callback) =>
  reduxApi(
    `/users/kyc-dashboard/aml/lookup/organisation`,
    'POST',
    {...amlData},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_AML_HISTORY});
        dispatch({type: UPDATED});
        callback();
      },
    },
    {error: 'ERROR', loading: AML_LOOKUP_LOADING, responder: GET_AML_HISTORY},
  );
export const amlConfigApi = (amlConfigData, method = 'POST', callback) =>
  reduxApi(
    `/users/kyc-dashboard/aml/config`,
    method,
    {...(method === 'POST' || method === 'PUT' ? amlConfigData : {})},
    {
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_AML_CONFIG});
        callback && callback(false);
      },
      error: (data, dispatch) => {
        if (data?.error === 'Record not found') {
          dispatch({payload: 'create', type: GET_AML_CONFIG});
        } else {
          toast.error(data?.error);
        }
      },
    },
    {
      error: 'ERROR',
      loading: AML_CONFIG_LOADING,
      responder: GET_AML_CONFIG,
      report: true,
      dontRun: true,
    },
  );

export const toggleOngoingMonitoring = (amlData, callback) =>
  reduxApi(
    `/users/kyc-dashboard/aml/subscribe`,
    'POST',
    {...amlData},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({type: UPDATED});
        callback(false);
      },
    },
    {error: 'ERROR', loading: AML_CONFIG_LOADING, responder: 'v'},
  );
export const performAmlBatchLookup = (formData, callback, closeModalCallback) =>
  reduxApi(
    '/users/kyc-dashboard/aml/lookup/batch',
    'POST',
    {...formData},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: BATCH_AML_LOOKUP});
        dispatch({type: UPDATED});
        toast.success('Bulk upload successful');
        callback(Constants.PAGES_URL.AML_USER_SCREENING_BATCH);
        closeModalCallback(false);
      },
    },
    {error: 'ERROR', loading: AML_LOOKUP_LOADING, responder: GET_AML_HISTORY},
  );
export const getBatchAmlHistory = ({
  company_id,
  page,
  limit,
  status,
  country,
  start,
  end,
  apps,
  download,
  caller_name,
  caller_email,
}) => {
  const apiParams = `company_id=${company_id}&model_type=individual${
    page ? `&page=${page}` : ''
  }${limit ? `&limit=${limit}` : ''}${status ? `&status=${status}` : ''}${
    country ? `&country=${country}` : ''
  }${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}${
    apps ? `&app_id=${apps}` : ''
  }${download ? `&download=${download}` : ''}${
    caller_email ? `&caller_email=${caller_email}` : ''
  }${caller_name ? `&caller_name=${caller_name}` : ''}`;

  return reduxApi(
    `/users/widget/lookup/batch?${apiParams}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        toast.success(data.message);
        dispatch({payload: data, type: GET_BATCH_AML_HISTORY});
      },
      error: (data, dispatch) => {
        dispatch({payload: null, type: GET_BATCH_AML_HISTORY});
      },
    },
    {error: 'ERROR', loading: AML_LOADING, responder: GET_BATCH_AML_HISTORY},
  );
};
export const downloadAmlBatchLookup = (id, callback) =>
  reduxApi(
    `/users/widget/lookup/batch/download/${id}?model_type=individual`,
    'GET',
    null,
    {
      report: true,
      error: err => console.error(err),
      success: data => {
        window.open(data?.url, '_blank');
        callback();
      },
    },
    {error: 'ERROR', loading: AML_LOOKUP_LOADING, responder: GET_AML_HISTORY},
  );
