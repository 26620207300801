import React from 'react';

export const CreatePinInput = ({
  pin,
  onChange,
  onKeyDown,
  onPaste,
  pinRefs,
}) => (
  <div className="flex justify-center mt-10 items-center gap-3">
    {pin.map((digit, index) => (
      <div key={index} className="relative">
        <input
          type="text"
          inputMode="numeric"
          value={digit}
          onChange={e => onChange(e, index)}
          onKeyDown={e => onKeyDown(e, index)}
          onPaste={e => onPaste(e, index)}
          maxLength={1}
          className="w-16 h-16 text-center rounded-xl text-transparent caret-black"
          ref={el => (pinRefs.current[index] = el)}
        />
        <span
          className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-3 h-3 rounded-full ${
            digit ? 'bg-black' : 'bg-transparent'
          } pointer-events-none`}
        />
      </div>
    ))}
  </div>
);
