import React, {useState, createContext} from 'react';

const CheckboxContext = createContext(false);

function CheckboxContextProvider({children}) {
  const [isChecked, setIsChecked] = useState({});

  return (
    <CheckboxContext.Provider value={{isChecked, setIsChecked}}>
      {children}
    </CheckboxContext.Provider>
  );
}

export {CheckboxContext, CheckboxContextProvider};
