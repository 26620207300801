import {Constants} from '../../helpers';

const COUNTRIES = Constants.lookups.countries.map(country => ({
  value: country.label,
  label: country.label,
  name: country.label,
}));

const defaultFilterOptions = [
  {
    title: 'Apps',
    name: 'apps',
    values: [],
  },
  {
    title: 'Model type',
    name: 'model_type',
    values: [],
  },
  {
    title: 'Status',
    name: 'status',
    values: [],
  },
  {
    title: 'Country',
    name: 'country',
    values: [],
  },
];

const tableHeader = [
  {
    label: 'Name',
    key: 'first_name',
  },
  {
    label: 'ID type',
    key: 'model_type',
  },
  {
    label: 'status',
    key: 'status',
  },
  {
    label: 'Date uploaded',
    key: 'date_created',
  },
];

const singleIndividualLookupTableHeader = [
  {
    label: 'Name',
    key: 'first_name',
  },
  {
    label: 'ID type',
    key: 'model_type',
  },
  {
    label: 'ID number',
    key: 'id_number',
  },
  {
    label: 'status',
    key: 'status',
  },
  {
    label: 'Date uploaded',
    key: 'date_created',
  },
];

const ignoreKeys = [
  'caller_name',
  'caller_email',
  'date_created',
  'photo',
  'image',
  'image_url',
  'app_id',
  'customer',
  'imagereport',
  'picture',
  'number_of_affiliates',
];

const affiliateskeysOfInterest = [
  'name',
  'occupation',
  'address',
  'dateOfBirth',
  'identityType',
  'identityNumber',
  'dateOfAppointment',
  'dateOfTermination',
  'email',
  'phoneNumber',
];

const lookupNames = {
  lastNames: [
    'surname',
    'last_name',
    'LAST_NAME',
    'lastName',
    'company_name',
    'Name_of_Company',
    'other_names',
  ],
  firstNames: [
    'firstname',
    'first_name',
    'FIRST_NAME',
    'firstName',
    'company_name',
    'Name_of_Company',
  ],
};

const getIDType = type =>
  ({
    vnin: 'Virtual NIN',
    passport: 'International Passport',
    bvn: 'BVN Advance',
    basic_phone: 'Basic Phone Number',
  }[type] || type);

const hiddenTypes = [
  'vnin',
  'nin',
  'tin',
  'cac',
  'cac_advance',
  'nuban',
  'account_number',
  'bvn',
  'phone_number',
  'passport',
  'basic',
  'advance',
  'global_search',
  undefined,
];

const numberInputs = ['cac_advance'];

export {
  COUNTRIES,
  tableHeader,
  defaultFilterOptions,
  ignoreKeys,
  lookupNames,
  hiddenTypes,
  numberInputs,
  getIDType,
  affiliateskeysOfInterest,
  singleIndividualLookupTableHeader,
};
