import React, {useState} from 'react';
import {
  easyOnboardTourFive,
  easyOnboardTourFour,
  easyOnboardTourOne,
  easyOnboardTourSix,
  easyOnboardTourThree,
  easyOnboardTourTwo,
} from '../../assets/images/images';
import AttentionLottie from '../../assets/lotties/AttentionLottie';
import {useTour} from '../../hooks';
import Modal from '../Modal';
import PrimaryButton from '../PrimaryButton';
import {PlayIc} from '../images';

const steps = [
  {
    step: 1,
    title: 'Application',
    description:
      'Select an app or create an app to successfully create an onboarding flow',
    img: easyOnboardTourOne,
    paddingTop: 'pt-[102px]',
  },
  {
    step: 2,
    title: 'Configuration',
    description:
      'Decide if the verification should be manual or automatic, amongst other settings',
    img: easyOnboardTourTwo,
    paddingTop: 'pt-[140px]',
  },
  {
    step: 3,
    title: 'Verification pages',
    description:
      'Select your verification flow and arrange them for easy identity verification',
    img: easyOnboardTourThree,
    paddingTop: 'pt-[180px]',
  },
  {
    step: 4,
    title: 'Fraud check',
    description:
      'Set rules to easily detect fraud through age limit, user data, AML screening & IP/Device screening',
    img: easyOnboardTourSix,
    paddingTop: 'pt-[200px]',
  },
  {
    step: 5,
    title: 'Confirmation',
    description:
      'Confirm your verification pages by using default or share a redirection link',
    img: easyOnboardTourFour,
    paddingTop: 'pt-[220px]',
  },
  {
    step: 6,
    title: 'Generate link',
    description: 'Save your flow to generate a link',
    img: easyOnboardTourFive,
    paddingTop: 'pt-[240px]',
  },

  {
    step: 7,
    title: 'Preview',
    description:
      'Preview your created flow in sandbox and test with provided data',
    paddingTop: 'pt-[72px]',
  },
  {
    step: 8,
    title: 'Publish',
    description:
      'Publish your widget live and start onboarding your customers seamlessly',
    paddingTop: 'pt-[72px]',
  },
];

export default function EasyOnboardTour2({updateTourState, loading, DATA}) {
  const {tourModal, setTourModal, handleTourComplete} = useTour(
    'easy_onboard_tour_2',
    loading,
    DATA,
    updateTourState,
  );

  const [step, setStep] = useState(1);

  const handleNext = () => {
    if (step < steps.length) {
      setStep(step + 1);
    }
  };

  const handlePrev = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <Modal
      show={tourModal}
      onClose={setTourModal}
      extraModalClasses="max-w-fit overflow-y-auto h-fit max-h-[95%] sm:max-h-full absolute bottom-0 sm:relative text-left overflow-y-hidden"
      modalPosition={`justify-center ${
        step === 7 || step === 8 ? 'sm:justify-end' : 'sm:justify-start'
      }`}
      modalPadding={`p-6 pl-[19.72%] ${steps[step - 1].paddingTop} ${
        step === 8 ? 'pr-[150px]' : ''
      }`}
      noBottomPadding
      borderRadius="rounded"
      modalBg="bg-transparent"
      pointerEventsNone
      overlayBackground
      hideOnSmallerDevices
    >
      <div
        className={`flex relative outline-none
      ${step === 7 || step === 8 ? 'flex-col' : ''}
      `}
        tabIndex={0}
      >
        {step === 7 ? (
          <button className="px-2 pt-[9px] pb-[7px] flex items-end gap-1 text-xs font-medium rounded text-brandBlue bg-white80 w-fit self-end">
            <PlayIc />
            Preview widget
          </button>
        ) : step === 8 ? (
          <PrimaryButton
            buttonText="Publish"
            xPadding="px-2"
            yPadding="pt-[9px] pb-[7px]"
            fontSize="text-xs"
            className="w-fit self-end"
          />
        ) : null}
        <div className="flex gap-8 items-start">
          <div className="w-full max-w-[355px] 2xl:max-w-[400px] 3xl:max-w-[430px] mt-8">
            <img src={steps[step - 1].img} alt="" />
          </div>
          <div
            className={`p-2 flex flex-col h-fit items-start gap-2 bg-white rounded ${
              step === 7 || step === 8 ? 'mt-5 mr-[75px]' : ''
            }`}
          >
            <div className="flex items-center gap-2">
              {Array.from({length: 8}).map((_, index) => (
                <div
                  key={index + 1}
                  className={`${
                    index + 1 <= step ? 'bg-success80' : 'bg-success20'
                  } w-[29.47px] h-[7px] rounded-full transition-all duration-300 ease-in-out`}
                />
              ))}
            </div>
            <p className="text-sm font-medium text-grey">
              {steps[step - 1].title}
            </p>
            <p className="text-sm text-body max-w-[286px]">
              {steps[step - 1].description}
            </p>
            <div className="flex items-center justify-between w-full">
              <button
                className="text-danger text-xs font-medium focus-visible:outline-danger"
                onClick={handleTourComplete}
              >
                Dismiss
              </button>
              <div className="flex items-center gap-2">
                <button
                  className="text-brandBlue text-xs font-medium focus-visible:outline-brandBlue"
                  onClick={handlePrev}
                >
                  Prev
                </button>
                <button
                  className="text-brandBlue text-xs font-medium focus-visible:outline-brandBlue"
                  onClick={
                    step === steps.length ? handleTourComplete : handleNext
                  }
                >
                  {step === steps.length ? 'Finish' : 'Next'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <AttentionLottie
          style={{
            width: '48px',
            height: '48px',
            transform:
              step === 7 || step === 8
                ? 'matrix(0, 1, 1, 0, 0, 0)'
                : 'matrix(0.71, -0.71, -0.71, -0.71, 0, 0)',
          }}
          className={`absolute ${
            step === 7 || step === 8
              ? 'mr-10 top-5 self-end'
              : 'top-0 -rotate-[130deg] right-[43%] 3xl:right-[39%]'
          }`}
        />
      </div>
    </Modal>
  );
}
