import React, {useCallback, useMemo} from 'react';
import {NavLink} from 'react-router-dom';
import {PAGES_URL} from '../../helpers/constants';
import {DashboardLayout} from '../../components';
import {amlScreeningIc} from '../../assets/images/images';
import {connect} from 'react-redux';

const ROUTES = [
  {
    name: 'User Screening',
    path: PAGES_URL.AML_USER_SCREENING,
    key: 'user',
  },
  {
    name: 'Business Screening',
    path: PAGES_URL.AML_BUSINESS_SCREENING,
    key: 'business',
  },
];

function Layout({children, auth: {userDetails}, customBg}) {
  const {
    performSingleBusinessLookup,
    viewSingleBusinessLookup,
    performBatchBusinessLookup,
    viewBatchBusinessLookup,
    performSingleIndividualLookup,
    viewSingleIndividualLookup,
    performBatchIndividualLookup,
    viewBatchIndividualLookup,
  } =
    useMemo(
      () => userDetails?.permissions?.amlScreeningPermissions,
      [userDetails],
    ) || {};

  const canPerformBusinessLookup =
    performSingleBusinessLookup &&
    viewSingleBusinessLookup &&
    performBatchBusinessLookup &&
    viewBatchBusinessLookup;

  const canPerformIndividualLookup =
    performSingleIndividualLookup &&
    viewSingleIndividualLookup &&
    performBatchIndividualLookup &&
    viewBatchIndividualLookup;

  const getPermission = useCallback(
    key =>
      ({
        user: canPerformIndividualLookup,
        business: canPerformBusinessLookup,
      }[key]),
    [canPerformBusinessLookup, canPerformIndividualLookup],
  );

  return (
    <DashboardLayout
      bg={customBg || 'bg-white80'}
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={amlScreeningIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">AML Screening</p>
        </div>
      }
    >
      <div className="mb-6 rounded-xl bg-white p-1 border border-[#E1E4EA] flex items-center gap-2 overflow-x-auto mr-[76px] w-fit">
        {ROUTES.map((route, index) => (
          <NavLink
            key={index}
            end
            to={route.path}
            className={({isActive}) =>
              `pt-[13px] pb-[7px] px-4 text-sm  flex items-center justify-center rounded-lg shadow-[0px_4px_32px_-4px,rgba(199,196,196,0.18)] ${
                isActive
                  ? 'text-dojahBlue bg-blue-100'
                  : 'text-grey-500 bg-transparent'
              }`
            }
            style={{
              pointerEvents: getPermission(route?.key) ? 'auto' : 'none',
            }}
          >
            {route.name}
          </NavLink>
        ))}
      </div>
      {children}
    </DashboardLayout>
  );
}

export default connect(state => state, null)(Layout);
