import {PAGES_URL} from '../../helpers/constants';
import {nigeriaIc, southAfricaIc} from '../../assets/images/images';
import {separateCamelCaseWithCaps} from '../../helpers';
import platform from 'platform';
import moment from 'moment/moment';

const ROUTES = [
  {
    name: 'Government Lookup',
    path: PAGES_URL.CUSTOMER_360_GOVT_LOOKUP,
  },
  {
    name: 'Government ID',
    path: PAGES_URL.CUSTOMER_360_GOVT_ID,
  },
  {
    name: 'Validation Info',
    path: PAGES_URL.CUSTOMER_360_VALIDATION_INFO,
  },
  {
    name: 'AML Check',
    path: PAGES_URL.CUSTOMER_360_AML_CHECK,
  },
  {
    name: 'Address Info',
    path: PAGES_URL.CUSTOMER_360_ADDRESS_INFO,
  },
  {
    name: 'IP & Device Info',
    path: PAGES_URL.CUSTOMER_360_IP_DEVICE_INFO,
  },
  {
    name: 'Liveness Check',
    path: PAGES_URL.CUSTOMER_360_LIVENESS_CHECK,
  },
];

const PROFILE_IGNORE = [
  'image',
  'last_name_check',
  'first_name_check',
  'middle_name_check',
  'image_check',
  'dob_check',
  'aml_is_check',
  'entity_status',
  'entity_types',
  'app_id',
  'integrity_check',
];

const IGNORE_KEYS = [
  'image',
  'image_url',
  'entity_status',
  'image_check',
  'dob_check',
  'image_url',
  'app_id',
  'app_name',
  'app_name',
  'confidence',
  'customer_id',
  'dob_check',
  'environment',
  'first_name_check',
  'id',
  'id_image_url',
  'id_number',
  'id_type',
  'id_url',
  'back_url',
  'image_check',
  'last_name_check',
  'middle_name_check',
  'provider',
  'reference_id',
  'selfie_url',
  'document_type',
  'date_created',
  'date_updated',
  'portrait_url',
  'integrity_check',
  'entity_types',
  'countries',
  'createdAt',
  'updatedAt',
];

const IGNORE_GOV_DATA_KEYS = [
  'id',
  'app_id',
  'watch_listed',
  'customer',
  'enrollment_bank',
  'enrollment_branch',
  'last_updated_date',
  'level_of_account',
  'lga_of_origin',
  'lga_of_residence',
  'name_on_card',
  'nationality',
  'nin',
  'registration_date',
  'residential_address',
  'state_of_residence',
  'title',
  'phone_number2',
  'image',
];

const IGNORE_ADDRESS_DATA_KEYS = ['session_id'];

const TIMELINE = [
  {
    datetime: '2021-08-12 12:00:00+00:00',
    event_value: 'Verification Started',
  },
  {
    datetime: '2021-08-12 12:00:00+00:00',
    event_value: 'Index Step Completed',
  },
  {
    datetime: '2021-08-12 12:00:00+00:00',
    event_value: 'Countries Step Completed',
  },
  {
    datetime: '2021-08-12 12:00:00+00:00',
    event_value: 'User Data Collected',
  },
  {
    datetime: '2021-08-12 12:00:00+00:00',
    event_value: 'User Data Collected',
  },
];

const companyID = localStorage.getItem('company_id');
const LAPO_COMPANY_ID = '63da7dcf146e720034b8a994';

const customer360LookupDropdownOptions = [
  {value: 'nin', label: 'NIN', name: 'nigeria_id'},
  {value: 'bvn', label: 'BVN Basic', name: 'nigeria_id'},
  ...(companyID === LAPO_COMPANY_ID
    ? []
    : [{value: 'advance', label: 'BVN Advance', name: 'nigeria_id'}]),
  {value: 'phone_number', label: 'Advance Phone Number', name: 'nigeria_id'},
];

const getCountryLogo = country =>
  ({
    ng: nigeriaIc,
    sa: southAfricaIc,
  }[country || 'nigeria']);

const getIDType = type =>
  typeof type === 'string' && type.split('_').includes('basic')
    ? 'Basic'
    : 'Advance';

const getCountryName = data => {
  if (data) {
    const firstUnderscoreIndex = Object.keys(data)[0]?.indexOf('_');
    if (firstUnderscoreIndex !== -1) {
      const countryAbb = Object.keys(data)[0].substring(
        0,
        firstUnderscoreIndex,
      );

      return {
        ng: 'Nigeria',
        sa: 'South Africa',
      }[countryAbb];
    }
  }
  return 'Country';
};

const fullDetails = data =>
  Object.entries(data || {})
    .map(
      ([key, value]) =>
        value &&
        typeof value !== 'object' && {
          title: separateCamelCaseWithCaps(key) || key.replaceAll('_', ' '),
          value: typeof value === 'boolean' ? value.toString() : value,
        },
    )
    .filter(Boolean);

const getIPDeviceData = ip => {
  const device = platform.parse(ip?.device);
  const ipDate = moment(ip?.datetime).format('Do MMMM YYYY, h:mmA');

  const ipData = JSON.parse(
    ip.ip
      ?.replace(/'/g, '"')
      ?.replace(/True/g, 'true')
      ?.replace(/False/g, 'false'),
  );
  const deviceData = {
    OS: device?.os?.family,
    model: device?.os?.architecture,
    browser: device?.description.split(' ')[0],
    'Browser version': device?.version,
    platform: ipData?.mobile ? 'Mobile' : 'Web',
  };

  return {ipData, deviceData, ipDate};
};

export {
  TIMELINE,
  IGNORE_KEYS,
  ROUTES,
  IGNORE_GOV_DATA_KEYS,
  IGNORE_ADDRESS_DATA_KEYS,
  customer360LookupDropdownOptions,
  PROFILE_IGNORE,
  getCountryLogo,
  getCountryName,
  fullDetails,
  getIPDeviceData,
  getIDType,
};
