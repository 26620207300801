import React, {useCallback, useState} from 'react';
import Password from './Passwords';
import {useNavigate} from 'react-router-dom';
import {useMutation} from 'react-query';
import {confirmPassword} from '../../../../requests/queries/auth';
import {passwordCharacterCheck} from '../../../../helpers';
import {toast} from 'react-toastify';

const initialInputs = {password: '', confirmPassword: ''};
function ResetPassword() {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState(initialInputs);
  const token = location.pathname.split('/')[2];

  const {isLoading, mutateAsync} = useMutation(
    'reset-password',
    data => confirmPassword(data),
    {
      onSuccess: data => {
        toast.success(data.message);
        navigate('/signin');
      },
      onError: error => {
        toast.error(error.response.data.error);
      },
    },
  );

  const passwordCheck = passwordCharacterCheck.map(({check}) =>
    check(inputs.password),
  );

  const handleSubmit = useCallback(
    async e => {
      try {
        e.preventDefault();
        const {password, confirmPassword} = inputs;
        if (!passwordCheck.every(Boolean)) {
          toast.error('Password must match all the criteria');
          return;
        }
        if (!password || password !== confirmPassword) {
          toast.error('Passwords do not match');
          return;
        }
        const data = {
          new_password: password,
          cnew_password: confirmPassword,
          token,
        };

        await mutateAsync(data);
      } catch (error) {
        console.error('Password update error:', error);
      }
    },
    [inputs, mutateAsync, passwordCheck, token],
  );

  return (
    <>
      <Password
        headerText="Create a new password"
        buttonText="Reset Password"
        subHeaderText="Create a new password in order to access your account"
        inputs={inputs}
        setInputs={setInputs}
        buttonType={'"Reset Password"'}
        handleSubmit={handleSubmit}
        loading={isLoading}
      />
    </>
  );
}

export default ResetPassword;
