export default function inverseColor(baseColor) {
  const hex = baseColor.replace('#', '');
  const red = parseInt(hex.substring(0, 2), 16);
  const green = parseInt(hex.substring(2, 4), 16);
  const blue = parseInt(hex.substring(4, 6), 16);

  const relativeLuminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

  return relativeLuminance > 0.5 ? '#000000' : '#ffffff';
}
