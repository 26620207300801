import React, {useState} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import {toast} from 'react-toastify';
import {
  Modal,
  DropdownIndicator,
  customStyles,
  PrimaryButton,
} from '../../components';
import * as Actions from '../../store/actions';

const REVIEW_USER = [
  {value: 'successful', label: 'Successful'},
  {value: 'failed', label: 'Failed'},
];

function ReviewUserModal({
  reviewSession,
  open,
  setOpen,
  verifications: {loading},
}) {
  const [reviewUser, setReviewUser] = useState(null);
  // const [comment, setComment] = useState('');
  const sessionId = localStorage.getItem('sessionId');
  const referenceId = localStorage.getItem('referenceId');

  const handleSubmit = () => {
    if (reviewUser === null) return toast.error('Please select status!');
    // if (reviewUser?.value === 'failed' && !comment)
    //   return toast.error('Comments are required for failed reviews');
    const reviewData = {
      approval_status: reviewUser?.value,
      // ...(reviewUser?.value === 'failed' && {comment}),
    };

    reviewSession({reviewData, sessionId, referenceId}, () => setOpen(false));
  };

  return (
    <Modal
      modalTitle="Review"
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="md:max-w-[408px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="text-start">
        <label htmlFor="review_user" className="relative mt-5 mb-1">
          Status
        </label>
        <Select
          defaultValue={reviewUser}
          value={reviewUser}
          onChange={setReviewUser}
          options={REVIEW_USER}
          placeholder="Select status"
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          isSearchable={false}
        />
        {/*{reviewUser?.value === 'failed' && (*/}
        {/*  <>*/}
        {/*    <label htmlFor="comment" className="relative mt-4 mb-1">*/}
        {/*      Comment*/}
        {/*    </label>*/}
        {/*    <input*/}
        {/*      value={comment}*/}
        {/*      onChange={e => setComment(e.target.value)}*/}
        {/*      placeholder="Comment"*/}
        {/*      style={{*/}
        {/*        backgroundColor: '#F9F9F9',*/}
        {/*        border: '1px solid #CECED2',*/}
        {/*        boxShadow: 'none',*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
      <PrimaryButton
        onClick={handleSubmit}
        buttonText="Save"
        className="w-full mt-4"
        disabled={!reviewUser}
        loading={loading}
      />
    </Modal>
  );
}

export default connect(state => state, Actions)(ReviewUserModal);
