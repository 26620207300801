import React, {useRef, useState, useCallback, useMemo, useEffect} from 'react';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {useMutation} from 'react-query';
import {CreatePinInput} from '../CreatePinInput';
import {Loader, PrimaryButton} from '../../../../../components';
import {baasLock} from '../../../../../assets/images/images';
import {
  sendBAASOtp,
  validateBAASPin,
} from '../../../../../requests/queries/baas';

const PIN_LENGTH = 4;
const PIN_ARRAY = Array(PIN_LENGTH).fill('');
export const Pin = ({setWithdrawalSteps}) => {
  const {company} = useSelector(state => state?.auth?.userDetails || {});
  const [pin, setPin] = useState(PIN_ARRAY);
  const pinRefs = useRef([]);

  const withdrawalID = sessionStorage?.getItem('withdrawal_id');

  const handleInputChange = useCallback(
    (e, index, currentPin, setCurrentPin) => {
      const value = e.target.value.replace(/\D/g, '');
      if (value.length <= 1) {
        const newPin = [...currentPin];
        newPin[index] = value;
        setCurrentPin(newPin);

        if (value && index < PIN_LENGTH - 1) {
          pinRefs.current[index + 1]?.focus();
        }
      }
    },
    [],
  );
  useEffect(() => {
    pinRefs.current[0]?.focus();
  }, []);

  const validatePinMutation = useMutation(validateBAASPin);
  const sendOtpMutation = useMutation(sendBAASOtp, {
    onSuccess: data => {
      sessionStorage.setItem(
        'reference_id',
        String(data?.entity?.reference_id),
      );
      setWithdrawalSteps('otp');
    },
  });

  const isValid = useMemo(
    () => pin.join('').length === PIN_LENGTH && [pin],
    [pin],
  );

  const handleKeyDown = useCallback((e, index, currentPin) => {
    if (e.key === 'Backspace' && !currentPin[index] && index > 0) {
      pinRefs.current[index - 1]?.focus();
    }
  }, []);

  const resetPins = useCallback(error => {
    setPin(PIN_ARRAY);
    if (error) {
      toast.error(error);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    const newPin = pin.join('');
    if (newPin.length === PIN_LENGTH) {
      pinRefs.current[0]?.focus();
    } else {
      return toast.error('Please enter a valid 4-digit PIN');
    }

    try {
      await validatePinMutation.mutateAsync({
        pin: newPin,
        company_id: company?.id,
        withdrawal_id: withdrawalID,
      });

      await sendOtpMutation.mutateAsync({
        company_id: company?.id,
        withdrawal_id: withdrawalID,
      });
    } catch (error) {
      return resetPins(error.response?.data?.error || 'Failed to validate PIN');
    }
  }, [
    pin,
    validatePinMutation,
    company?.id,
    sendOtpMutation,
    withdrawalID,
    resetPins,
  ]);

  if (sendOtpMutation?.isLoading) {
    return <Loader height={38.7} />;
  }

  return (
    <div className="mt-6">
      <div className="flex flex-col items-center gap-3">
        <img src={baasLock} alt="lock icon" />
        <p className="font-inter text-base text-[#4F555F] text-center tracking-[-1%] max-w-[385px] m-auto">
          Input your 4-digit transaction PIN
        </p>
      </div>

      <CreatePinInput
        pin={pin}
        onChange={(e, index) => handleInputChange(e, index, pin, setPin)}
        onKeyDown={(e, index) => handleKeyDown(e, index, pin)}
        pinRefs={pinRefs}
      />

      <div className="flex items-center mt-6">
        <PrimaryButton
          buttonText="Submit"
          className="w-full rounded-lg"
          onClick={handleSubmit}
          disabled={!isValid}
          loading={validatePinMutation?.isLoading}
        />
      </div>
    </div>
  );
};
