// const getClosestNumberToHighest = numbers => {
//   const values = Object.values(numbers) || [];
//   return values.reduce((acc, val) => acc + val);
// };

export function calculatePricing(data = {}, serviceName = '') {
  let min = Infinity;
  let max = -Infinity;
  // let vMin = Infinity;
  // let vMax = -Infinity;

  const services = data?.services;
  const verification = data?.verification;

  if (services) {
    for (const key in services) {
      if (services[key] > 0) {
        const value = services[key];
        min = Math.min(min, value) || 0;
        max = Math.max(max, value);
      }
    }
  }

  if (verification && Object.values(verification).length > 0) {
    // const closestNumber = getClosestNumberToHighest(verification);
    const values = Object.values(verification) || [];
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    if (minValue === maxValue) {
      max = max + maxValue;
      min = min + maxValue;
    } else {
      min = min + minValue;
      max = max + maxValue;
    }
    // vMin = Math.min(vMin);
    // vMax = Math.max(vMax, closestNumber);
    // min = min
    // max = max + vMax;
  }

  return {
    [serviceName]: {
      min: min !== Infinity ? min : 0,
      max: max !== -Infinity ? max : 0,
    },
  };
}

export function calculateTotalMinMax(data) {
  let totalMin = 0;
  let totalMax = 0;
  for (const key in data) {
    const {min, max} = data[key];

    if (min === undefined && max === undefined) {
      return {
        totalMin: 0,
        totalMax: 0,
      };
    }
    totalMin += +min;
    totalMax += +max;
  }

  return {totalMin, totalMax};
}
