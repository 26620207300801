import {
  API_LOGS,
  API_LOGS_SUMMARY,
  API_LOGS_LOADING,
  API_LOGS_GRAPH,
  API_LOGS_FILTERS,
  EXPORT_LOADING,
} from '../types';

const initialState = {
  logs: null,
  summary: null,
  loading: null,
  exportLoading: null,
  graph: null,
};

export default function apiLogs(state = initialState, action) {
  switch (action.type) {
    case API_LOGS_LOADING:
      return {...state, loading: action.payload};
    case EXPORT_LOADING:
      return {...state, exportLoading: action.payload};

    case API_LOGS:
      return {...state, logs: action.payload};

    case API_LOGS_SUMMARY:
      return {...state, summary: action.payload};

    case API_LOGS_FILTERS:
      return {...state, filters: action.payload};

    case API_LOGS_GRAPH:
      return {...state, graph: action.payload};

    default:
      return state;
  }
}
