import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {DashboardLayout} from '../../../components';
import {chevronRight} from '../../../assets/images/images';
import {Constants} from '../../../helpers';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  addProject,
  fetchProject,
} from '../../../requests/queries/reconciliations';
import {useProjects} from './hooks/useProjects';
import ProjectDropdown from './components/ProjectsDropdown';
import AddProjectsModal from './Projects/AddProjectsModal';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';

const navLinks = [
  // {
  //   label: 'Overview',
  //   path: '/reconciliation/overview/:slug',
  // },
  {label: 'Accounts', path: '/reconciliation/projects/accounts/:slug'},
  {label: 'Transactions', path: '/reconciliation/projects/transactions/:slug'},
  {
    label: 'Reconciliation',
    path: '/reconciliation/projects/reconciliation/:slug',
  },
  {label: 'Customers', path: '/reconciliation/projects/customers/:slug'},
  {label: 'Project Settings', path: '/reconciliation/projects/settings/:slug'},
];

export default function ReconciliationLayout({
  children,
  action,
  pageIcon,
  isReconciliationPage = true,
  bg,
}) {
  const {userDetails} = useSelector(state => state?.auth);
  const initialRender = useRef(true);
  const location = useLocation();
  const navigate = useNavigate();
  const {slug} = useParams();
  const [isAddProject, setIsAddProject] = useState(false);
  const {isLoading: projectLoading, projectData} = useProjects();
  const queryClient = useQueryClient();

  const currentProject = useMemo(
    () => !projectLoading && projectData,
    [projectData, projectLoading],
  );
  const projectId = useMemo(
    () =>
      !projectLoading && slug && ![':slug', 'add-project']?.includes(slug)
        ? slug
        : currentProject?.length > 0
        ? currentProject[0]?.id
        : 'add-project',
    [currentProject, projectLoading, slug],
  );
  const projectSlug = projectId !== 'add-project' ? projectId : '';

  const {data: project, isLoading} = useQuery(
    ['project-overview', projectSlug],
    () => fetchProject(projectSlug),
    {enabled: !!projectSlug},
  );

  const currentProjectId = sessionStorage.getItem('project_id');
  const {mutate} = useMutation('add_project', addProject, {
    onSuccess: data => {
      toast.success('A Default project has been added successfully');
      queryClient.invalidateQueries('projects');
      sessionStorage.setItem('project_id', String(data?.entity?.id));
      const url = location.pathname.replace(/[^/]+$/, data?.entity?.id);
      navigate(url);
    },
  });

  const addDefaultProject = useCallback(async () => {
    try {
      const data = {
        company_id: userDetails && userDetails?.company?.id,
        name: 'Default Project',
        desc: 'Default Project Description',
      };

      await mutate(data);
    } catch (e) {
      console.log(e);
    }
  }, [mutate, userDetails]);

  useEffect(() => {
    if (
      slug === 'add-project' &&
      !projectLoading &&
      projectData?.length === 0 &&
      initialRender.current
    ) {
      addDefaultProject();
      initialRender.current = false;
    }
  }, [
    addDefaultProject,
    initialRender,
    projectData?.length,
    projectLoading,
    slug,
  ]);

  useEffect(() => {
    if (slug === 'add-project' && !projectLoading && projectData?.length > 0) {
      sessionStorage.setItem('project_id', String(projectSlug));
      const url = location?.pathname.replace('add-project', projectSlug);
      navigate(url, {
        state: {
          allProjects: projectData,
          isLoading: projectLoading,
        },
      });
    }
  }, [
    currentProject,
    currentProjectId,
    location?.pathname,
    navigate,
    projectData,
    projectId,
    projectLoading,
    projectSlug,
    slug,
    userDetails,
  ]);

  const projectName = !isLoading && project && project?.entity?.name;

  const isOverviewOrTransactionsPage = useMemo(
    () =>
      [
        '/reconciliation/overview',
        '/reconciliation/projects/transactions',
        '/reconciliation/projects/settings',
        '/reconciliation/projects/reconciliation',
        '/reconciliation/projects/transactions/add-project',
      ].includes(location.pathname),
    [location.pathname],
  );
  const renderNavLinks = useCallback(
    () =>
      navLinks.map(({label, path}) => (
        <NavLink
          key={path}
          end={path === '/overview'}
          to={path.replace(':slug', projectId)}
          className={({isActive}) =>
            `font-medium text-sm py-2 ${
              isActive
                ? 'text-brandBlue border-b-2 border-brandBlue'
                : 'text-grey30'
            }`
          }
          state={{
            projectData:
              ['settings', 'overview'].includes(label?.toLowerCase()) &&
              project,
            allProjects: projectData,
          }}
        >
          {label}
        </NavLink>
      )),
    [projectId, project, projectData],
  );
  return (
    <DashboardLayout
      bg={bg || isOverviewOrTransactionsPage ? 'bg-white80' : 'bg-white'}
      overFlow="!pr-0"
      leftMargin="!ml-0"
      breadCrumbs={
        <div className="flex items-center">
          <img src={pageIcon} alt="" width={18} height={18} />
          <Link
            className="ml-2 -mb-1"
            to={Constants.PAGES_URL.RECONCILIATION_PROJECTS}
          >
            {isReconciliationPage ? 'Projects' : ''}
          </Link>
          {isReconciliationPage && (
            <img src={chevronRight} alt="" width={18} height={18} />
          )}
          <p className="-mb-1 cursor-pointer">{projectName}</p>
        </div>
      }
    >
      <AddProjectsModal
        show={isAddProject}
        onClose={() => setIsAddProject(false)}
      />
      <div className="relative">
        <div className="pl-11 pr-8">
          <div className="flex items-center h-12 justify-between gap-6 mt-4 mb-8">
            {/*<h2 className="text-lg font-medium text-grey">{projectName}</h2>*/}
            <div className="text-lg font-medium text-grey">
              {!projectLoading && (
                <ProjectDropdown>
                  <button
                    onClick={() => setIsAddProject(!isAddProject)}
                    className="text-[14px] w-full py-[10px] px-8 mt-5 font-inter font-medium bg-brandBlue rounded-lg text-white"
                  >
                    Add Project
                  </button>
                </ProjectDropdown>
              )}
            </div>
            {action}
          </div>

          <div className="mt-6 md:mt-[27px] border-b border-grey60 mb-4">
            <div className="flex items-center gap-4 overflow-x-auto settings-nav whitespace-nowrap">
              {renderNavLinks()}
            </div>
          </div>
        </div>

        {children}
      </div>
    </DashboardLayout>
  );
}
