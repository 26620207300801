import React from 'react';
import {info} from '../../../assets/images/images';

export const UserData = () => (
  <>
    <header className="flex flex-col items-center mt-2">
      <h4 className="mt-4 font-medium text-grey">User data</h4>
      <div className="flex items-center gap-2 p-2 mt-2 bg-white80">
        <img src={info} alt="" width={13} height={13} className="" />
        <p className="text-sm rounded-lg text-grey">
          Fill the form as it appears on your valid ID
        </p>
      </div>
    </header>
    <form className="w-full max-w-sm">
      <label htmlFor="first_name" className="mt-4 mb-1">
        First name
        <input disabled type="text" id="first_name" className="mt-2 text-sm" />
      </label>
      <label htmlFor="middle_name" className="mt-4 mb-1">
        Middle name
        <input disabled type="text" id="middle_name" className="mt-2 text-sm" />
      </label>
      <label htmlFor="last_name" className="mt-4 mb-1">
        Last name
        <input disabled type="text" id="last_name" className="mt-2 text-sm" />
      </label>
      <label htmlFor="dob" className="mt-4 mb-1">
        Date of birth
        <input disabled type="date" id="dob" className="mt-2 text-sm" />
      </label>
    </form>
  </>
);
