import React, {useContext, useEffect, useState} from 'react';
import Layout from './Layout';
import {corruptedFile} from '../../assets/images/images';
import {RowData, Accordion, PrimaryButton} from '../../components';
import UploadPdfModal from './UploadPdfModal';
// import ExpensesPatternChart from './ExpensesPatternChart';
import {useLocation} from 'react-router-dom';
import {PageContext} from '../../helpers/context';
import {formatNumberToCurrency} from '../../helpers/formatNumberToCurrency';

export default function EasyCreditBehaviouralAnalysis() {
  const [open, setOpen] = useState(false);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('creditcheck');
  }, [setPageName]);

  const {state} = useLocation();
  const ANALYSIS = state?.analysisData;

  const incomeAnalysis = (ANALYSIS && ANALYSIS?.cashFlowAnalysis) || {};
  const spendAnalysis = (ANALYSIS && ANALYSIS?.spendAnalysis) || {};
  const transactionPatternAnalysis =
    (ANALYSIS && ANALYSIS?.transactionPatternAnalysis) || {};

  const INCOME_PATTERN = [
    {
      leftText: 'closing Balance',
      rightText: incomeAnalysis?.closingBalance,
    },
    {
      leftText: 'average Monthly Balance',
      rightText: incomeAnalysis?.averageMonthlyBalance,
    },
    {
      leftText: 'average Weekly Balance',
      rightText: incomeAnalysis?.averageWeeklyBalance,
    },
  ];
  const EXPENSES_PATTERN = [
    {
      leftText: 'average spend',
      rightText: spendAnalysis?.averageMonthlySpendOnTransfer,
    },
    {
      leftText: 'Average spend via ATM and POS',
      rightText: spendAnalysis?.averageMonthlySpendOnAtmAndPOS,
    },
    {
      leftText: 'Average spend via USSD',
      rightText: spendAnalysis?.averageMonthlySpendOnUSSD,
    },
    {
      leftText: 'Average spend via gambling',
      rightText: spendAnalysis?.averageMonthlySpendOnGambling,
    },
    {
      leftText: 'Average spend on Airtime and Data',
      rightText: spendAnalysis?.averageMonthlySpendOnAirtimeAndData,
    },
    {
      leftText: 'Average spend on Utilities',
      rightText: spendAnalysis?.averageMonthlySpendOnUtilities,
    },
    {
      leftText: 'Average spend on Online and web',
      rightText: spendAnalysis?.averageMonthlySpendOnOnlineAndWeb,
    },
    {
      leftText: 'Average spend on hospitality and food',
      rightText: spendAnalysis?.averageMonthlySpendOnHospitalityAndFood,
    },
    {
      leftText: 'Average spend on Charges and stamp duty',
      rightText: spendAnalysis?.averageMonthlySpendOnChargesAndStampDuty,
    },
    {
      leftText: 'Average spend on others',
      rightText: spendAnalysis?.averageMonthlySpendOnOthers,
    },
    {
      leftText: 'Average spend on Transportation',
      rightText: spendAnalysis?.averageMonthlySpendOnTransportation,
    },
    {
      leftText: 'Average spend on Travel',
      rightText: spendAnalysis?.averageMonthlySpendOnTravel,
    },
    {
      leftText: 'Average spend on Entertainment',
      rightText: spendAnalysis?.averageMonthlySpendOnEntertainment,
    },
    {
      leftText: 'Average spend on Rent',
      rightText: spendAnalysis?.averageMonthlySpendOnRent,
    },
    {
      leftText: 'Average spend on Savings And Investments',
      rightText: spendAnalysis?.averageMonthlySpendOnSavingsAndInvestments,
    },
    {
      leftText: 'Average spend on Crypto',
      rightText: spendAnalysis?.averageMonthlySpendOnCrypto,
    },
    {
      leftText: 'Average spend on Insurance',
      rightText: spendAnalysis?.averageMonthlySpendOnInsurance,
    },
    {
      leftText: 'Average spend on Health',
      rightText: spendAnalysis?.averageMonthlySpendOnHealth,
    },
  ];
  const USER_BEHAVIOUR = [
    {
      leftText: 'Month with the Highest Spend',
      rightText: spendAnalysis.monthWithHighestSpend,
    },
    {
      leftText: 'highest Spend',
      rightText: formatNumberToCurrency(+spendAnalysis?.highestSpend || 0),
    },
    {
      leftText: 'most Frequent Expense',
      rightText: spendAnalysis?.mostFrequentExpense,
    },
    {
      leftText: 'last Day Of Credit',
      rightText: transactionPatternAnalysis?.lastDayOfCredit,
    },
    {
      leftText: 'last Day Of Debit',
      rightText: transactionPatternAnalysis?.lastDayOfDebit,
    },
    {
      leftText: 'percentage of Debit Transactions',
      rightText: `${
        transactionPatternAnalysis?.percentDebitTransactions || 0
      }%`,
    },
    {
      leftText: 'total Number Of Transactions',
      rightText: transactionPatternAnalysis?.totalNumberOfTransactions,
    },
    {
      leftText: 'Most Frequent Balance Range',
      rightText: transactionPatternAnalysis?.mostFrequentBalanceRange,
    },
  ];

  return (
    <Layout behavioralAnalysisSideContent>
      <UploadPdfModal open={open} setOpen={setOpen} />
      {Object.entries(ANALYSIS || {})?.length === 0 ? (
        <div className="flex flex-col items-center justify-center py-12 mt-6 bg-white80">
          <img src={corruptedFile} alt="" width={48} height={48} />
          <p className="max-w-[179px] mt-2 text-sm text-center text-body">
            There is no bank statement uploaded yet
          </p>
          <PrimaryButton
            buttonText="Upload PDF"
            className="mt-6"
            onClick={() => setOpen(true)}
          />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-3">
            <Accordion title="Income Pattern" border>
              <div className="flex flex-col mt-4">
                {INCOME_PATTERN.map(({leftText, rightText}, i) => (
                  <RowData
                    key={i}
                    leftText={leftText}
                    rightText={formatNumberToCurrency(Number(rightText) || 0)}
                    boldFont
                  />
                ))}
              </div>
            </Accordion>
            <Accordion
              customTitle={
                <div className="flex items-center">
                  <p className="text-body  text-sm">Expenses Pattern</p>
                  <div
                    className={`px-2 pt-[5px] pb-[3px] ml-2 text-xs font-medium uppercase rounded-full w-fit bg-brandBlue text-white`}
                  >
                    Monthly
                  </div>
                </div>
              }
              title="Expenses Pattern"
              border
            >
              <div className="flex flex-col mt-4">
                {/*<ExpensesPatternChart />*/}
                <div className="flex flex-col mt-4">
                  {EXPENSES_PATTERN.map(({leftText, rightText}, i) => (
                    <RowData
                      key={i}
                      leftText={leftText}
                      rightText={formatNumberToCurrency(Number(rightText) || 0)}
                      boldFont
                    />
                  ))}
                </div>
              </div>
            </Accordion>
            <Accordion title="User Behaviour" border>
              <div className="flex flex-col mt-4">
                {USER_BEHAVIOUR.map(({leftText, rightText}, i) => (
                  <RowData
                    key={i}
                    leftText={leftText}
                    rightText={rightText || '-'}
                    boldFont
                  />
                ))}
                Expand Down
              </div>
            </Accordion>
          </div>
        </>
      )}
    </Layout>
  );
}
