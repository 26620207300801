import React, {useState} from 'react';
import {
  reconciliationAuthBg,
  reconciliationLogo,
} from '../../../../assets/images/images';
import {PrimaryButton} from '../../../../components';
import {addCompany} from '../../../../requests';
import {onSignupSuccessHelper} from '../../../../helpers';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

function GoogleSignup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [company, setCompany] = useState('');

  const {isLoading: sLoading, mutate} = useMutation(data => addCompany(data), {
    onSuccess: data => {
      onSignupSuccessHelper(data, dispatch, navigate);
      sessionStorage.removeItem('tempToken');
    },
    onError: error => {
      toast.error(error.response.data.error);
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    mutate({company});
  };

  return (
    <div
      className="w-full h-full bg-cover bg-center bg-no-repeat min-h-screen"
      style={{backgroundImage: `url(${reconciliationAuthBg})`}}
    >
      <div className="py-[70px] px-5 lg:px-0">
        <div className="bg-white border m-auto border-batchBlue20 py-12 px-8 rounded-lg w-full md:w-[586px]">
          <div className="flex flex-col items-center gap-2 w-full">
            <img
              src={reconciliationLogo}
              alt="Dojah logo"
              className="w-[110px] h-[21px]"
            />
            <p className="text-sm text-body">
              Gain insight into your business&apos;s financial health with
              Renconcilo
            </p>
          </div>

          <div className="mt-8">
            <h2 className="text-deepBlue font-medium text-lg mb-[6px]">
              Sign up with google
            </h2>
            <p className="text-sm text-body">
              Create a new account by signing up with google
            </p>

            <form className="mt-6" onSubmit={handleSubmit}>
              <label htmlFor="companyName" className="block mt-4">
                Company name
                <input
                  type="text"
                  name="companyName"
                  id="companyName"
                  className="mt-2"
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                  required
                />
              </label>

              <PrimaryButton
                buttonText="Continue"
                className="w-full mt-6"
                type="submit"
                disabled={sLoading}
                loading={sLoading}
              />
            </form>

            <p className="mt-6 text-sm text-body">
              By clicking the &quot;Sign Up&quot; button, you are creating a
              Dojah account, and you agree to Dojah&apos;s{' '}
              <span className="text-brandBlue">
                <a
                  href="https://dojah.io/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
              </span>{' '}
              and{' '}
              <span className="text-brandBlue">
                <a
                  href="https://dojah.io/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleSignup;
