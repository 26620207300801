import React from 'react';
import ReactSlider from 'react-slider';
import cn from 'classnames';

const RangeSlider = _props => {
  const isVertical = _props.orientation === 'vertical';

  return (
    <ReactSlider
      {..._props}
      // pearling
      ariaLabel={['Allow', 'Pending', 'Blocked']}
      renderThumb={(props, state) => {
        const isFirstThumb = state.index === 0;
        const isLastThumb = state.index === state.value.length - 1;

        // Calculate the thumb color based on its position
        let thumbColor;
        let tooltipBackground;
        let tooltipText;
        if (isFirstThumb) {
          thumbColor = 'bg-success'; // First thumb color
          tooltipBackground = 'bg-success'; // First tooltip background
          tooltipText = 'Allow';
        } else if (isLastThumb) {
          thumbColor = 'bg-body'; // Last thumb color
          tooltipBackground = 'bg-body'; // Last tooltip background
          tooltipText = 'Pending';
        } else {
          thumbColor = 'bg-indigo-500'; // Middle thumb color
          tooltipBackground = 'bg-body'; // Middle tooltip background
          tooltipText = ''; // Empty tooltip for middle thumbs
        }

        return (
          <div {...props} className="relative">
            <div
              className={cn({
                'aspect-square h-[19.02px] w-[19.02px] rounded-full text-xs text-white flex items-center justify-center cursor-grab px-1': true,
                [thumbColor]: true,
              })}
            >
              {state.valueNow}
            </div>
            <div
              className={cn({
                "absolute bottom-[118%] w-[54.8px] left-1/2 -translate-x-1/2 px-2 pt-[5px] pb-[3px] text-white text-xs rounded after:content-[''] after:absolute after:block after:w-0 after:h-0 after:border-l-transparent after:border-l-4 after:border-b-4 after:border-r-transparent after:border-r-4 after:rotate-180 after:left-1/2 after:-translate-x-1/2 after:-bottom-[3px]": true,
                [tooltipBackground]: true,
                'after:border-b-success': isFirstThumb,
                'after:border-b-body': isLastThumb,
              })}
            >
              {tooltipText}
            </div>
          </div>
        );
      }}
      renderTrack={(props, state) => {
        const points = Array.isArray(state.value) ? state.value.length : null;
        const isMulti = points && points > 0;
        const isLast = isMulti ? state.index === points : state.index !== 0;
        const isFirst = state.index === 0;

        // Calculate the track color based on the track index
        let trackColor;
        if (isMulti) {
          if (isFirst) {
            trackColor = 'bg-success'; // First track color
          } else if (isLast) {
            trackColor = 'bg-status-404'; // Last track color
          } else {
            trackColor = 'bg-body'; // Middle track color
          }
        } else {
          // Fallback to using the default color for single track
          trackColor = 'bg-grey';
        }

        return (
          <div
            {...props}
            className={cn({
              'h-1/4 top-1/2 -translate-y-1/2': !isVertical,
              'w-1/4 left-1/2 -translate-x-1/2': isVertical,
              'rounded-full': true,
              'bg-gray-200': isMulti ? isFirst || isLast : isLast,
              [trackColor]: true,
            })}
          ></div>
        );
      }}
    />
  );
};

export default RangeSlider;
