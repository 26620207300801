import React, {useState, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';
import {PrimaryButton} from '../../../../components';
import {updateBAASPin} from '../../../../requests/queries/baas';
import {PinInput} from './PinInput';

const PIN_LENGTH = 4; // Assuming PIN is 4 digits

export const UpdatePin = () => {
  const {company} = useSelector(state => state?.auth?.userDetails || {});
  const [pins, setPins] = useState({
    oldPin: '',
    newPin: '',
    confirmPin: '',
  });

  const updatePinMutation = useMutation(updateBAASPin);

  const handlePinChange = useCallback((name, value) => {
    setPins(prev => ({...prev, [name]: value}));
  }, []);

  const isValid = useMemo(() => {
    const {oldPin, newPin, confirmPin} = pins;
    return (
      oldPin.length === PIN_LENGTH &&
      newPin.length === PIN_LENGTH &&
      confirmPin.length === PIN_LENGTH &&
      newPin === confirmPin
    );
  }, [pins]);

  const handleSubmit = useCallback(async () => {
    if (!isValid) return;

    try {
      await updatePinMutation.mutateAsync({
        old_pin: pins.oldPin,
        new_pin: pins.newPin,
        confirm_pin: pins.confirmPin,
        company_id: company?.id,
      });

      toast.success('PIN updated successfully');
      setPins({oldPin: '', newPin: '', confirmPin: ''});
    } catch (error) {
      setPins({oldPin: '', newPin: '', confirmPin: ''});
      toast.error(error.response?.data?.error || 'Failed to update PIN');
    }
  }, [
    isValid,
    company?.id,
    pins.oldPin,
    pins.newPin,
    pins.confirmPin,
    updatePinMutation,
  ]);

  return (
    <div className="lg:w-1/2 w-full ">
      <h2 className="text-body text-xs font-medium mb-6 uppercase">PIN</h2>
      <div className="bg-white p-6 rounded flex flex-col gap-6">
        <PinInput
          label="Current PIN"
          name="oldPin"
          value={pins.oldPin}
          onChange={handlePinChange}
        />
        <PinInput
          label="New PIN"
          name="newPin"
          value={pins.newPin}
          onChange={handlePinChange}
          isError={pins.newPin !== pins.confirmPin}
        />
        <PinInput
          label="Confirm New PIN"
          name="confirmPin"
          value={pins.confirmPin}
          onChange={handlePinChange}
          isError={pins.newPin !== pins.confirmPin}
        />
        <PrimaryButton
          buttonText="Confirm PIN Change"
          className="w-[181px]"
          loading={updatePinMutation.isLoading}
          onClick={handleSubmit}
          disabled={!isValid}
        />
      </div>
    </div>
  );
};
