import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';

export const useGovtLookupHomeLayout = () => {
  const userDetails = useSelector(state => state?.auth?.userDetails);

  const {
    performSingleBusinessLookup,
    viewSingleBusinessLookup,
    performBatchBusinessLookup,
    viewBatchBusinessLookup,
    performSingleIndividualLookup,
    viewSingleIndividualLookup,
    performBatchIndividualLookup,
    viewBatchIndividualLookup,
  } = useMemo(
    () => userDetails?.permissions?.lookupPermissions || {},
    [userDetails],
  );
  const canPerformBusinessLookup = useMemo(
    () =>
      performSingleBusinessLookup &&
      viewSingleBusinessLookup &&
      performBatchBusinessLookup &&
      viewBatchBusinessLookup,
    [
      performBatchBusinessLookup,
      performSingleBusinessLookup,
      viewBatchBusinessLookup,
      viewSingleBusinessLookup,
    ],
  );

  const canPerformIndividualLookup = useMemo(
    () =>
      performSingleIndividualLookup &&
      viewSingleIndividualLookup &&
      performBatchIndividualLookup &&
      viewBatchIndividualLookup,
    [
      performBatchIndividualLookup,
      performSingleIndividualLookup,
      viewBatchIndividualLookup,
      viewSingleIndividualLookup,
    ],
  );

  return useCallback(
    key =>
      ({
        business: canPerformBusinessLookup,
        individual: canPerformIndividualLookup,
      }[key]),
    [canPerformBusinessLookup, canPerformIndividualLookup],
  );
};
