import React, {useMemo} from 'react';
import ReactApexChart from 'react-apexcharts';
import {getPeriod} from '../routes/FraudDetection/Reconciliation/constants';
import {Loader} from './index';
import {processData} from '../routes/Verifications/utils';

const VerificationAnalyticsBarChart = ({data = [], periodType, loading}) => {
  const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));

  const chartData = processData(sortedData);

  const series = useMemo(
    () => [
      {
        name: 'Successful',
        data: chartData.map(d => d.Completed),
      },
      {
        name: 'Failed',
        data: chartData.map(d => d.Failed),
      },
      {
        name: 'Abandoned',
        data: chartData.map(d => d.Abandoned),
      },
    ],
    [chartData],
  );

  const categories = useMemo(
    () => chartData.map(d => getPeriod(d?.date, periodType)),
    [chartData, periodType],
  );

  const options = {
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: categories || ['N/A', 'N/A', 'N/A'],
      labels: {
        rotate: -45,
        trim: false,
        hideOverlappingLabels: false,
      },
    },

    colors: ['#87D4DB', '#F15D5F', '#2A5392'],
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetY: -20,
      offsetX: -30,
      width: 450,
      height: 60,
    },
    markers: {
      size: 3,
    },
    fill: {
      opacity: 1,
    },
    // grid: {
    //   show: false,
    // },

    grid: {
      xaxis: {
        lines: {
          show: true,
          strokeColor: '#CECED2',
        },
      },
      yaxis: {
        lines: {
          show: true,
          strokeColor: '#CECED2',
        },
      },
    },
  };

  const dummySeries = [
    {
      name: 'Successful',
      data: [0, 0, 0],
    },
    {
      name: 'Failed',
      data: [0, 0, 0],
    },
    {
      name: 'Abandoned',
      data: [0, 0, 0],
    },
  ];

  return (
    <div id="chart">
      {loading ? (
        <Loader height={25} />
      ) : (
        <ReactApexChart
          className="project_line_chart bg-white"
          options={options}
          series={series[0]?.data?.length > 0 ? series : dummySeries}
          type="bar"
          height={350}
        />
      )}
    </div>
  );
};

export default VerificationAnalyticsBarChart;
