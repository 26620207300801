import React from 'react';

export const CalenderIcon = ({color = '#4A4A68'}) => (
  <div>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      // xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.333 1.99984H13.9997C14.1765 1.99984 14.3461 2.07008 14.4711 2.1951C14.5961 2.32012 14.6663 2.48969 14.6663 2.6665V13.3332C14.6663 13.51 14.5961 13.6796 14.4711 13.8046C14.3461 13.9296 14.1765 13.9998 13.9997 13.9998H1.99967C1.82286 13.9998 1.65329 13.9296 1.52827 13.8046C1.40325 13.6796 1.33301 13.51 1.33301 13.3332V2.6665C1.33301 2.48969 1.40325 2.32012 1.52827 2.1951C1.65329 2.07008 1.82286 1.99984 1.99967 1.99984H4.66634V0.666504H5.99967V1.99984H9.99967V0.666504H11.333V1.99984ZM2.66634 5.99984V12.6665H13.333V5.99984H2.66634ZM3.99967 7.33317H5.33301V8.6665H3.99967V7.33317ZM7.33301 7.33317H8.66634V8.6665H7.33301V7.33317ZM10.6663 7.33317H11.9997V8.6665H10.6663V7.33317Z"
        fill={color || '#4A4A68'}
      />
    </svg>
  </div>
);
