import moment from 'moment';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {connect} from 'react-redux';
import {
  apiCalls,
  dashboardIc,
  failed,
  successful,
} from '../../assets/images/images';
import {
  Card,
  SecondaryButton,
  DashboardLayout,
  TopupModal,
} from '../../components';
import {getStatusCodeDescription} from '../../helpers/getStatusCodeDescription';
import {
  fetchApiLogsFilters,
  fetchApiLogsGraph,
} from '../../requests/queries/dashboard';
import {DashboardPieChartLeft, DashboardPieChartRight} from './ApiCallsChart';
import LineChart from './LineChart';
import * as Actions from '../../store/actions';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../helpers/formatNumberToCurrency';
import {getGreetingTime} from '../../helpers/greetTime';
import {convertToPercentage} from '../../helpers/convertToPercentage';
// import {start, end} from '../../store/actions';
import {PageContext} from '../../helpers/context';

function ApiFailures({backgroundColor, failure, value, failed}) {
  return (
    <div className="flex items-center gap-3 w-[212px]">
      <div
        style={{
          backgroundColor,
          borderRadius: '3px',
          height: '12px',
          width: '12px',
        }}
      />
      <p className="text-xs font-medium uppercase text-body">{failure}</p>
      <p className="ml-auto text-sm font-bold text-grey">
        {convertToPercentage(value, failed)}
      </p>
    </div>
  );
}

function Dashboard({auth: {user, userDetails, userSettings}}) {
  const [openTopUp, setOpenTopUp] = useState(false);
  const [filters, setFilters] = useState({
    start: null,
    end: null,
  });

  const {data: appFilters, isLoading: appFiltersLoading} = useQuery(
    ['filters'],
    fetchApiLogsFilters,
  );

  const {data: graph, isLoading: graphLoading} = useQuery(
    ['api-logs-graph', {...filters}],
    () => fetchApiLogsGraph({...filters}),
  );

  const breakdown = useMemo(
    () =>
      appFilters?.breakdown?.map(({status_code, count}) => ({
        color: getStatusCodeDescription(status_code)?.color || '#F1D7D1',
        count,
        label: getStatusCodeDescription(status_code)?.statusLabel || 'Unknown',
      })),
    [appFilters],
  );

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('dashboard');
  }, [setPageName]);

  const {
    total: TOTAL,
    failed: FAILED,
    successful: SUCCESS,
  } = (!graphLoading && graph && graph?.summary) || {};

  const aggregate = breakdown
    ?.map(({count}) => count)
    ?.reduce((a, b) => a + b, 0);
  const remainder = FAILED - (aggregate || 0);

  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={dashboardIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Dashboard</p>
        </div>
      }
    >
      <>
        <TopupModal open={openTopUp} setOpen={setOpenTopUp} />
        <div className="flex items-center justify-between">
          <h3
            data-testid="user-greeting"
            className="text-xl font-medium text-grey"
          >
            {`${getGreetingTime(moment(new Date()))}${
              user?.first_name ? `, ${user?.first_name}!` : ''
            }`}
          </h3>
          <div className="items-center hidden gap-4 sm:flex">
            <div className="bg-white80 flex items-center gap-2 px-2 pt-[6px] pb-[2px] rounded-full">
              <span
                data-testid="wallet-balance"
                className="text-sm font-medium text-grey"
              >
                Wallet Balance:{' '}
                {userDetails &&
                userDetails?.company &&
                userDetails?.company?.currency === 'USD'
                  ? formatUsd(userDetails?.company?.wallet_balance / 100 ?? 0)
                  : formatNumberToCurrency(
                      userDetails?.company?.wallet_balance / 100 ?? 0,
                    )}
              </span>
            </div>
            <SecondaryButton
              buttonText="Quick top-up"
              className="text-xs leading-3"
              xPadding="px-2"
              yPadding="py-2"
              btnHeight="h-auto"
              onClick={() => setOpenTopUp(!openTopUp)}
            />
          </div>
        </div>

        <div className="flex items-center gap-4 my-6 overflow-x-auto whitespace-nowrap">
          <Card
            img={apiCalls}
            title="Total API calls"
            body={TOTAL || 0}
            loading={graphLoading}
          />
          <Card
            img={successful}
            title="Successful API Calls"
            body={SUCCESS || 0}
            loading={graphLoading}
          />
          <Card
            img={failed}
            title="Failed API Calls"
            body={FAILED || 0}
            loading={graphLoading}
          />
        </div>

        <LineChart
          graphData={graph}
          start={filters.start}
          end={filters.end}
          filters={filters}
          setFilters={setFilters}
          appFilters={appFilters}
          isLoading={appFiltersLoading}
        />

        <div className="flex gap-6 mt-8 basis-full">
          <div className="bg-white80 rounded-lg pt-[26px] px-3 sm:px-[22px] pb-5 basis-full sm:basis-1/2">
            <h3 className="mb-4 text-lg font-medium text-center sm:text-left">
              API Calls
            </h3>
            <div className="flex flex-col items-center gap-3 sm:flex-row">
              <DashboardPieChartLeft
                data={
                  !graphLoading && {
                    total: TOTAL,
                    failed: FAILED,
                    successful: SUCCESS,
                  }
                }
              />
              <div className="flex gap-2 sm:gap-10 sm:ml-6 sm:flex-col">
                <div className="flex items-center gap-4">
                  <img src={successful} alt="" width={40} height={40} />
                  <div className="flex flex-col gap-[5px]">
                    <p className="text-xs font-medium uppercase text-body">
                      Successful api calls
                    </p>
                    <p className="text-xl font-medium text-grey">
                      {convertToPercentage(SUCCESS, TOTAL)}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <img src={failed} alt="" />
                  <div className="flex flex-col gap-[5px]">
                    <p className="text-xs font-medium uppercase text-body">
                      Failed api calls
                    </p>
                    <p className="text-xl font-medium text-grey">
                      {convertToPercentage(FAILED, TOTAL)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white80 rounded-lg pt-[26px] px-[22px] pb-5 basis-1/2 hidden md:block">
            <h3 className="mb-4 text-lg font-medium">
              Reasons for API failure
            </h3>
            <div className="flex items-center gap-12">
              <DashboardPieChartRight
                totalFailedApiCalls={FAILED || 0}
                breakdown={breakdown}
                loading={appFiltersLoading}
                remainder={remainder}
              />
              <div className="flex flex-col gap-2">
                {breakdown?.length ? (
                  breakdown?.map(({color, count, label}) => (
                    <ApiFailures
                      backgroundColor={color}
                      failure={label}
                      value={count}
                      key={label}
                      failed={FAILED}
                    />
                  ))
                ) : (
                  <div className="d-flex align-items-center justify-content-center flex-column w-100">
                    <p>Nothing here yet</p>
                  </div>
                )}
                {remainder ? (
                  <ApiFailures
                    backgroundColor="#999999"
                    failure="Others"
                    value={remainder}
                    failed={FAILED}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(Dashboard);
