/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {Checkbox, Modal} from '../../../components';
import * as Actions from '../../../store/actions';
import {toast} from 'react-toastify';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../../helpers/formatNumberToCurrency';
import OTPVerificationMode from '../../../components/OTPVerificationMode';

function PhoneNumber({
  widgets: {widgetScreen, widgetPages, widgetPricing},
  auth: {userDetails, userSettings},
  setWidgetCurrentPricing,
  setWidgetScreen,
  setWidgetPages,
  ...otherProps
}) {
  const {open, setOpen} = otherProps;
  const [update, setUpdate] = useState(true);
  const [pricing, setPricing] = useState({
    services: {},
  });

  const [config, setConfig] = useState({
    verification: true,
    otp: true,
    whatsappVerification: false,
  });

  const displayPrice = price =>
    userDetails && userDetails.company && userDetails.company.currency === 'USD'
      ? formatUsd(price || 0)
      : formatNumberToCurrency(
          price || 0,
          userDetails && userDetails.company && userDetails.company.currency,
          userSettings &&
            userSettings.settings &&
            userSettings.settings.dollar_exchange_rate,
        );
  useEffect(() => {
    const isActive =
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.page === 'phone-number' &&
          data[`${data?.page}-${data?.pageId}`],
      );
    if (isActive) {
      for (const key in config) {
        if (typeof config[key] === 'boolean') {
          setPricing(prev => {
            const price =
              widgetPricing && widgetPricing['phone-number']?.services[key];
            if (config[key] === true) {
              return {
                services: {
                  ...{
                    ...prev.services,
                    [key]: price / 100,
                  },
                },
              };
            } else {
              delete prev.services[key];
              return prev;
            }
          });
        }
      }
      setWidgetCurrentPricing({
        'phone-number': pricing,
      });
    } else {
      setWidgetCurrentPricing({
        'phone-number': {},
      });
    }
    return () =>
      setWidgetCurrentPricing({
        'phone-number': {},
      });
  }, [widgetScreen, update, widgetPages, setWidgetCurrentPricing, config]);
  const foundWidget = useMemo(
    () =>
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.pageId === widgetScreen?.pageId &&
          data?.page === 'phone-number',
      ),
    [widgetPages, widgetScreen],
  );
  const ALL_PAGES = JSON.parse(localStorage.getItem('pages'));
  const updateConfig = newConfig => {
    const newPages = ALL_PAGES.map(page => {
      if (page.page === foundWidget.page) {
        return {
          ...page,
          config: newConfig,
        };
      }
      return page;
    });
    localStorage.setItem('pages', JSON.stringify(newPages));
  };
  useEffect(() => {
    if (foundWidget) {
      if (Object.values(foundWidget?.config || {}).length === 0) {
        setConfig({...config});
        setUpdate(false);
        setWidgetPages({
          ...foundWidget,
          config: {...config},
        });
        updateConfig({...config});
      } else {
        setConfig({...config, ...foundWidget?.config});
        setWidgetPages({
          ...foundWidget,
          config: {...config, ...foundWidget?.config},
        });
        updateConfig({...config, ...foundWidget?.config});
      }
    }
  }, [widgetScreen, update]);

  const newData = widgetPages?.find(
    data => data?.pageId === widgetScreen?.pageId,
  );

  const handleUpdateConfig = (key, value) => {
    if (!newData) {
      return toast.error(
        'Ops! Please toggle ON before configuring this widget',
      );
    } else {
      setConfig({...widgetScreen?.config, [key]: value});
      setWidgetScreen({
        ...widgetScreen,
        config: {...widgetScreen.config, [key]: value},
      });

      setWidgetPages({
        ...newData,
        config: {...newData?.config, [key]: value},
      });
    }
  };
  return (
    <Modal
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[339px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Configuration"
    >
      <p className="text-left text-body text-xs mt-8 mb-2 font-medium uppercase">
        Mode of verification
      </p>

      <div className="bg-white80 rounded-lg p-2">
        <Checkbox
          label="OTP Verification"
          className="mb-2 !pl-0"
          labelStyle="text-xs text-body leading-[18px]"
          align="items-start"
          isChecked={config?.verification}
          onChange={e => handleUpdateConfig('verification', e.target.checked)}
        />

        <OTPVerificationMode
          handleUpdateConfig={handleUpdateConfig}
          isConfigurable={!newData}
          config={config}
          whatsapp={'whatsappVerification'}
          pricing={{
            sms: displayPrice(
              widgetPricing &&
                widgetPricing['phone-number']?.services?.verification / 100,
            ),
            whatsapp: displayPrice(
              widgetPricing &&
                widgetPricing['phone-number']?.services?.whatsappVerification /
                  100,
            ),
          }}
        />
      </div>
    </Modal>
  );
}
export default connect(state => state, Actions)(PhoneNumber);
