import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {SecondaryButton} from '../../components';
import * as Actions from '../../store/actions';

const timelineLimit = 4;

function ActivityLog({verifications: {timeline}, getTimeline, session}) {
  const [next, setNext] = useState(timelineLimit);
  const {slug} = useParams();

  useEffect(() => {
    if (slug) {
      getTimeline(slug);
    }
  }, [getTimeline, slug]);

  const timeLineData = timeline?.data;

  const handleLoadMore = () => {
    setNext(next + timelineLimit);
  };

  return (
    <div className="p-4 mt-4 rounded bg-white80 lg:bg-white grow lg:grow-0 text-start">
      <p className="text-xs font-medium uppercase text-grey">Activity log</p>
      <div className="mt-4">
        <div className="grid">
          {timeLineData?.slice(0, next)?.map(
            (time, index) =>
              (!session || session === time.session_id) && (
                <div
                  key={index}
                  className="activity-parent grid gap-[6px] grid-rows-[2rem] h-full py-[4px]"
                >
                  <span
                    className="relative grid items-center text-xs font-medium text-body activity-time
              after:content-[''] after:absolute after:border-r after:border-dashed after:border-grey60 after:h-[40px] after:right-[-3px]"
                  >
                    {time.datetime.split(' ').pop()?.replace('+00:00', '')}
                  </span>
                  <span
                    className="text-sm lowercase  veri text-body activity-description leading-[12px]  relative grid items-center pl-2
              before:content-[''] before:absolute before:bg-brandBlue before:rounded-full before:w-[5px] before:h-[5px] before:top-1/2 before:left-[-3px] before:-mt-[1px] "
                  >
                    {time?.event_value ?? '-'}
                  </span>
                </div>
              ),
          )}
        </div>

        {next < timeLineData?.length && (
          <SecondaryButton
            className="flex items-start gap-2 mt-4"
            fontSize="text-xs"
            xPadding="px-2"
            yPadding="pt-[9px] pb-[7px]"
            btnHeight="h-7"
            buttonText="Load more"
            onClick={handleLoadMore}
          />
        )}
      </div>
    </div>
  );
}

export default connect(state => state, Actions)(ActivityLog);
