// noinspection LoopStatementThatDoesntLoopJS,HtmlUnknownTarget

import React from 'react';
import {Accordion} from '../../components';
import {getFraudPages, getPages} from '../../helpers/getPages';
import {useSelector} from 'react-redux';
import {formatNumberToCurrency} from '../../helpers/formatNumberToCurrency';
import {Constants} from '../../helpers';
import {getPageCost} from '../../helpers/getWidgetCost';
import {getCookieValue} from '../../helpers/getCookie';

const CostBreakdown = () => {
  const {
    widgets: {widgetPages, widgetPricing, fraudRules},
    auth: {userDetails},
  } = useSelector(state => state);

  const VERIFICATION_PAGES = (widgetPages && widgetPages) || [];
  const FRAUD_PAGES = (fraudRules && fraudRules) || [];
  const isAmlActive = FRAUD_PAGES.find(p => p.page === 'aml_screening');
  const WIDGET_PAGES = [
    ...VERIFICATION_PAGES,
    ...(isAmlActive ? [isAmlActive] : []),
  ];
  const data = WIDGET_PAGES?.map(p => getPageCost(p, widgetPricing));
  const envData = getCookieValue('env');

  function removeDuplicateObjects(arr = []) {
    if (!arr) return;
    const uniqueObjects = new Set(arr.filter(Boolean).map(JSON.stringify));
    return Array.from(uniqueObjects).map(JSON.parse);
  }

  {
    return (
      <Accordion title="Cost breakdown">
        <p className="text-xs text-body leading-[18px]">
          View breakdown of cost per onboarding flow
        </p>

        <div className="mt-6">
          {WIDGET_PAGES &&
            WIDGET_PAGES.filter(p => p.page !== 'user-data')?.map(
              (page, idx) => {
                page.page === 'aml_screening'
                  ? (page.config = {aml: true})
                  : page.config;

                const BREAK = Object.entries({
                  ...page.config,
                }).map(([name, value]) => {
                  for (const d of data) {
                    if (name === 'type') return;
                    const configName = name === 'version' ? 'selfie' : name;
                    if (envData === 'Sandbox') {
                      return {
                        name: configName,
                        value: 0,
                      };
                    }

                    if (!value) return;
                    const pageName =
                      page?.page === 'selfie' ? 'liveness' : page?.page;
                    if (
                      [
                        'title',
                        'hideUpload',
                        'information',
                        'instruction',
                        'index_instructions',
                        'custom-questions',
                        'questions',
                      ].includes(configName) ||
                      !d?.pricingSchema[pageName][configName]
                    )
                      return;

                    return {
                      name: configName,
                      value: d?.pricingSchema[pageName][configName] / 100,
                    };
                  }
                });

                const displayPrice = price =>
                  userDetails &&
                  userDetails?.company &&
                  formatNumberToCurrency(
                    price || 0,
                    userDetails?.company && userDetails?.company?.currency,
                  );

                const displayService = key =>
                  Constants.lookups.nigeriaId?.find(id => id.value === key)
                    ?.label;
                const COST_BREAKDOWN = removeDuplicateObjects(BREAK || []);
                return (
                  <div
                    key={`${page.pageId}-${page.page}` || idx}
                    className="mt-2"
                  >
                    <Accordion
                      background="bg-white80"
                      title={getPages(page.page) || getFraudPages(page.page)}
                    >
                      {/*{Array.from({length: 5}, (v, i) => (*/}
                      {COST_BREAKDOWN && COST_BREAKDOWN?.length > 0 ? (
                        COST_BREAKDOWN.map((price, i) => (
                          <span key={i}>
                            {price && (
                              <div className="flex justify-between mt-4">
                                <p className="capitalize text-sm text-body leading-[18px]">
                                  {page?.page === 'business-id' &&
                                  price?.name === 'base'
                                    ? 'price'
                                    : displayService(price?.name) ||
                                      price?.name ||
                                      '-'}
                                </p>
                                <p className="text-sm text-body text-brandBlue leading-[18px]">
                                  {displayPrice(price?.value) || 0}
                                </p>
                              </div>
                            )}
                          </span>
                        ))
                      ) : (
                        <p
                          key={page.page}
                          className="text-sm text-body leading-[18px] text-center py-6"
                        >
                          No cost breakdown for this page{' '}
                          <span className="text-brandBlue">
                            [ {getPages(page.page)} ]
                          </span>{' '}
                        </p>
                      )}
                    </Accordion>
                  </div>
                );
              },
            )}
        </div>
      </Accordion>
    );
  }
};
export default CostBreakdown;
