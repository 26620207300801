import React from 'react';
import {fancyCopy, logoIcon} from '../../../assets/images/images';

export default function FlowLink() {
  return (
    <div
      className="py-8 max-w-[480px] mx-auto flex flex-col gap-3 pointer-events-none"
      aria-disabled="true"
      disabled
      style={{opacity: 0.3}}
    >
      <div className="px-6 py-4 bg-white rounded-lg flex flex-col gap-3">
        <div className="flex items-center gap-3">
          <div className="font-sometype text-small tracking-tighter font-medium py-2 px-3 text-body bg-[#F6F6F6] rounded-lg">
            <p>Javascript library</p>
          </div>
          <button className="flex items-center justify-center gap-2 py-2 bg-[#3F7CDB] rounded-lg text-xs font-medium text-white w-24">
            <img src={fancyCopy} alt="" width={14} height={14} />
            <span className="-mb-[2px]">Copy script</span>
          </button>
        </div>
        <div className="bg-[#F6F6F6] rounded-xl">
          <pre className="pre">
            <code className="text-secondaryBlue font-sometype">
              <span className="relative before:absolute before:top-0 before:-left-8 before:content-['1.']">{`<script src`}</span>
              <span className="text-[#3F7CDB]">{`="https://……./button.js">`}</span>
              <br />
              <span className="relative before:absolute before:top-0 before:-left-8 before:content-['2.']">{`</script>`}</span>
              <br />
              <span className="text-[#3F7CDB] relative before:absolute before:top-0 before:-left-8 before:content-['3.']">{`dojahbutton`}</span>
              <br />
              <span className="relative before:absolute before:top-0 before:-left-8 before:content-['4.']">{`widgetID=`}</span>
              <span className="text-[#3F7CDB]">{`"64d602cf6c3d0b001af9b043"`}</span>
              <br />
              <span className="relative before:absolute before:top-0 before:-left-8 before:content-['5.']">{`referenceID=`}</span>
              <span className="text-[#3F7CDB]">{`"64d602cf6c3d0b001af96042"`}</span>
              <br />
              <span className="relative before:absolute before:top-0 before:-left-8 before:content-['6.']">{`metadata=`}</span>
              <span className="text-[#3F7CDB]">{`'{"key": "value"}*`}</span>
              <br />
              <span className="relative before:absolute before:top-0 before:-left-8 before:content-['7.']">{`/>`}</span>
            </code>
          </pre>
        </div>
      </div>
      <div className="px-12 py-7 bg-[#D9E5F8B2] rounded-lg flex flex-col items-center gap-3">
        <div className="flex items-center flex-col py-4 px-3 bg-white rounded-lg">
          <p className="text-grey font-medium">Dojah Button</p>
          <p className="text-grey40 text-xs text-center w-4/5 mt-2">
            Something on what the dojah button does and how they can configure
            and edit it?
          </p>
          <button className="mt-4 flex items-center justify-center gap-2 bg-[#3F7CDB] text-white text-sm rounded py-2 w-full">
            <img src={logoIcon} alt="" width={12} height={14.29} />
            <span className="-mb-1">Verify with dojah</span>
          </button>
        </div>
      </div>
    </div>
  );
}
