export const getDownloadUrl = type => {
  switch (type) {
    case 'basic':
    case 'phone_number':
      return 'https://files.dojah.io/1719845570820-phone_number_bulk_1633670498919811.xlsx';

    case 'dl':
      return 'https://files.dojah.io/1719845502638-driver_license_bulk_1633670498919811.xlsx';

    case 'bvn':
      return 'https://files.dojah.io/1719846125345-bvn_bulk_1633670498919811.xlsx';

    case 'account_number':
      return 'https://files.dojah.io/1719845944377-nuban_bulk_16336704989198.xlsx';

    case 'vnin':
      return 'https://files.dojah.io/1719845428755-vnin_bulk_1633670498919811.xlsx';

    case 'nin':
      return 'https://files.dojah.io/1719846095283-nin_bulk_163367049892334.xlsx';

    case 'advance':
      return 'https://files.dojah.io/1719846125345-bvn_bulk_1633670498919811.xlsx';

    case 'cac':
      return 'https://files.dojah.io/1719845464306-cac_bulk_1633670498919811.xlsx';

    case 'batch-aml':
      return 'https://files.dojah.io/1719849174722-aml_batch_ind.xlsx';

    case 'recon-transaction':
      return 'https://docs.google.com/spreadsheets/d/1jH4fg84pcZEJudy2f4VjuEr9AVPI4oS8oYgp5Q6VBRQ/edit#gid=0';

    default:
      break;
  }
};
