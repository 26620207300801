/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import {connect} from 'react-redux';
import {signaturePageDefaultInfo as defaultInformation} from '../widgetHelpers';

const SignaturePage = ({widgets: {widgetPages, widgetScreen}}) => {
  const widgetData =
    widgetPages &&
    widgetPages?.find(
      widget =>
        widget?.page === 'signature' && widget?.pageId === widgetScreen?.pageId,
    );

  return (
    <>
      <header className="flex flex-col items-center my-4">
        <p className="mt-8 text-center font-medium text-grey">
          Sign and confirm document
        </p>
      </header>

      <p className="mt-5 text-sm w-[367px] text-justify text-grey80 max-h-[200px] overflow-y-auto">
        {widgetData?.config?.information || defaultInformation}
      </p>

      <div className="w-[73%] mt-10">
        <label htmlFor="title">Input name to sign and confirm</label>
        <input
          type="text"
          name="title"
          id="title"
          placeholder="Input document name"
          className="w-full text-sm  rounded-md p-2 mt-2"
          value="John Doe"
          disabled
        />
      </div>
    </>
  );
};

export default connect(state => state, null)(SignaturePage);
