import axiosLib from 'axios';
import {toast} from 'react-toastify';
import {token} from './index';
import {getCookieValue} from './getCookie';

let axios = axiosLib;
const reduxApi =
  (url, method, data, {success, error, updated}, actionTypes) =>
  (dispatch, mockAxios) => {
    const {
      responder,
      loading,
      error: errorConstant,
      report,
      dontRun,
    } = actionTypes;
    if (mockAxios.get) axios = mockAxios;

    dispatch({
      payload: url,
      type: loading,
    });

    if (loading) {
      dispatch({
        payload: null,
        type: responder,
      });
    }

    const envData = getCookieValue('env');

    axios.defaults.headers.common.Authorization = `Bearer ${token.get()}`;
    axios.defaults.headers.common.Environment =
      envData || token.get('env') || 'Sandbox';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    return axios[method.toLowerCase()](
      `${process.env.REACT_APP_BASE_URL}${url}`,
      data,
    )
      .then(res => {
        dispatch({
          payload: '',
          type: loading,
        });
        dispatch({
          payload: res.data,
          type: responder,
        });

        if (updated) {
          window.location.reload();
        }

        if (typeof success === 'function' && report !== false) {
          success(res.data, dispatch);
        }

        return true;
      })
      .catch(e => {
        dispatch({
          payload: '',
          type: loading,
        });
        dispatch({
          errorObj: e,
          payload: e.response && e.response.data,
          type: errorConstant,
        });
        if (report !== false) {
          if (
            e.response?.data?.error !==
            'Your company must be verified to access this resource'
          ) {
            !dontRun && toast.error(e.response?.data?.error);
          } else {
            toast.error('Please complete your registration process');
          }
        }

        if (typeof error === 'function' && report !== false) {
          error(e.response && e.response.data, dispatch);
        }

        if (
          e.response &&
          e.response.status === 401 &&
          (error?.response?.data?.error?.name === 'JsonWebTokenError' ||
            error?.response?.data?.error?.message === 'jwt malformed')
        ) {
          token.remove();
          window.location = '/signin';
        }

        return false;
      });
  };

export default reduxApi;
