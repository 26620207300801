import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import {css} from '@emotion/react';

const override = css`
  border-color: #ffffff;
  position: absolute;
  background: transparent;
`;

const ButtonLoader = ({size, color}) => (
  <PuffLoader css={override} size={size || 35} color={color || '#ffffff'} />
);

export default ButtonLoader;
