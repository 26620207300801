import React from 'react';

const PlayIc = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      style={{
        fill: 'var(--color-primary)',
      }}
      d="m11.3 7.243-6.183 4.121a.292.292 0 0 1-.453-.242V2.878a.292.292 0 0 1 .453-.243L11.3 6.758a.291.291 0 0 1 0 .486Z"
    />
  </svg>
);

export {PlayIc};
