import React from 'react';
import Select from 'react-select';
import {blacklistRed} from '../../../assets/images/images';
import {customStyles, DropdownIndicator} from '../../../components';
import {useFraudCheck, usePersistFraudData} from '../../../hooks/useFraudCheck';
import {Card, Wrapper} from '../FraudCheckScreens';

export const BlackList = () => {
  const {setSelectedStatus, selectedStatus, setConfig, config, STATUSES} =
    useFraudCheck({
      action_blacklisted: '',
    });

  const actionBlacklisted = usePersistFraudData({
    config,
    selectedStatus,
    actionKey: 'action_blacklisted',
    COLLECTION: STATUSES,
  });

  return (
    <Wrapper>
      <Card
        extension={
          <p className="text-grey font-medium">If user is blacklisted</p>
        }
        src={blacklistRed}
      >
        <div className="text-start w-full">
          <label htmlFor="status" className="mb-2 relative">
            Set status to
          </label>
          {actionBlacklisted !== undefined && (
            <Select
              defaultValue={actionBlacklisted}
              value={actionBlacklisted || STATUSES[0]}
              onChange={status => {
                setSelectedStatus(status);
                setConfig({...config, action_blacklisted: status.value});
              }}
              options={STATUSES}
              className="basic-multi-select"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              menuPlacement="auto"
            />
          )}
        </div>
      </Card>
    </Wrapper>
  );
};
