import React from 'react';
import moment from 'moment/moment';
import {
  EmptyState,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../../components';
import {corruptedFile} from '../../../assets/images/images';
import {formatNumberToCurrency} from '../../../helpers/formatNumberToCurrency';
import {getStatusColor} from '../../../helpers';
import {getReconColor} from '../../../helpers/getStatusColor';

export const TransactionsTable = ({
  transactionsLoading,
  transactions,
  openUploadTransactionsModal,
  openTransactionDetails,
  setQuery,
  query,
  tabType,
  component,
  setId,
}) => {
  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };

  return (
    <div>
      {transactionsLoading ? (
        <Loader height={35} />
      ) : !transactionsLoading && transactions?.rows?.length === 0 ? (
        <EmptyState
          noBtn
          src={corruptedFile}
          className="!h-[calc(50vh-200px)]"
          maxWidth="max-w-[289px]"
          body={`No ${tabType} transaction(s) was found for this ${
            component || 'company'
          }.`}
          customBtn={
            component ? null : (
              <PrimaryButton
                buttonText="Add Transactions"
                className="py-[14px] px-[47px] rounded-lg mt-6"
                onClick={openUploadTransactionsModal}
              />
            )
          }
        />
      ) : (
        <TableLayout className="w-full mt-6 font-inter">
          <thead className="text-small font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">transaction date</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">ref id</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">amount</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">type</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">status</span>
              </th>{' '}
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">Recon. status</span>
              </th>
            </tr>
          </thead>
          <tbody className="text-sm text-body bg-white">
            {!transactionsLoading &&
              transactions &&
              transactions?.rows?.map(transaction => (
                <tr
                  key={transaction?.id}
                  className="text-left border-b cursor-pointer border-grey60"
                  onClick={() => {
                    setId(transaction?.id);
                    openTransactionDetails(true);
                  }}
                >
                  <td className="p-4 pl-12 whitespace-nowrap">
                    {(transaction?.transaction_time &&
                      moment(transaction?.transaction_time).format(
                        'Do MMM YYYY h:m a',
                      )) ||
                      '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {transaction?.transaction_ref || '-'}
                  </td>

                  <td className="p-4 pl-0 whitespace-nowrap">
                    {formatNumberToCurrency(transaction?.transaction_amount)}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <span
                      className={`uppercase w-fit ${getStatusColor(
                        transaction?.transaction_type,
                      )} h-5 flex items-center justify-center  font-medium text-xs rounded-full px-2 py-1`}
                    >
                      {transaction?.transaction_type || '-'}
                    </span>
                  </td>

                  <td className="p-4 pl-0 whitespace-nowrap">
                    <span className="uppercase w-fit rounded-full px-2 py-1 h-5 flex items-center justify-center text-white bg-success text-xs">
                      {transaction?.transaction_status || '-'}
                    </span>
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <span
                      className={`${getReconColor(
                        transaction?.transaction_recon,
                      )} uppercase w-fit rounded-full px-2 py-1 h-5 flex items-center justify-center text-white text-xs`}
                    >
                      {transaction?.transaction_recon || '-'}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>
      )}

      {!transactionsLoading && transactions?.rows?.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
          <PageLimit
            onLimitChange={limit => {
              setQuery(prevState => ({
                ...prevState,
                limit,
                page: 1,
              }));
            }}
            limit={query?.limit}
            total={transactions?.pages}
            length={transactions?.rows?.length}
          />

          <Pagination
            total={transactions?.pages}
            current={Number(query?.page)}
            onPageChange={activePage => {
              pageClick(activePage);
              const searchParams = new URLSearchParams(
                document.location.search,
              );
              searchParams.set('page', activePage);
            }}
          />
        </div>
      )}
    </div>
  );
};
