import React, {createContext, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {fetchCompanyLogo} from '../fetchCompanyLogoAndColor';
import {Loader} from '../../components';
import {reconciloLogo} from '../../assets/images/images';

const LogoContext = createContext(null);

function LogoProvider({children}) {
  const {data: logo = '', status} = useQuery('companyLogo', fetchCompanyLogo);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (status === 'success') {
      const storedLogo = localStorage.getItem('companyLogo');
      const isReconcilo = localStorage
        .getItem('subdomain')
        ?.includes('reconcilo');

      if (!storedLogo || (storedLogo !== reconciloLogo && isReconcilo)) {
        localStorage.setItem('companyLogo', reconciloLogo);
      } else if (!storedLogo || storedLogo !== logo) {
        localStorage.setItem('companyLogo', logo);
      }

      setIsLoading(false);
    }
  }, [logo, status]);

  if (isLoading) {
    return <Loader />;
  }

  return <LogoContext.Provider value={logo}>{children}</LogoContext.Provider>;
}

export {LogoContext, LogoProvider};
