// USER AUTH
export const ERROR = 'ERROR';
export const LOGOUT = 'LOGOUT';
export const LOADING = 'LOADING';
export const SET_USER = 'SET_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_BILLINGS = 'SET_BILLINGS';
export const SET_AUDIT_TRAIL = 'SET_AUDIT_TRAIL';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const BILLINGS_API_CALLS = 'BILLINGS_API_CALLS';
export const REGENERATE_API_KEY = 'REGENERATE_API_KEY';
export const UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO';

// SUBDOMAIN
export const MODULE = 'MODULE';

// DATA
export const LOCATION = 'LOCATION';
export const APP_FILTERS = 'APP_FILTERS';
export const FILTER_LOADING = 'FILTER_LOADING';

// PAYMENTS
export const GET_PAYMENT_DETAILS = 'GET_PAYMENT_DETAILS';
export const GET_ACCOUNT_NUMBER = 'GET_ACCOUNT_NUMBER';

// CREDIT CHECKS
export const GET_CREDIT_CHECKS = 'GET_CREDIT_CHECKS';
export const CREDIT_CHECKS_LOADING = 'CREDIT_CHECKS_LOADING';
export const CREDIT_CHECKS_SUMMARY = 'CREDIT_CHECKS_SUMMARY';
export const CREDIT_CHECKS_LOOKUP_LOADING = 'CREDIT_CHECKS_LOOKUP_LOADING';

// WEBHOOK
export const GET_WEBHOOK_SUBSCRIPTIONS = 'GET_WEBHOOK_SUBSCRIPTIONS';
export const WEBHOOK_LOADING = 'WEBHOOK_LOADING';
export const WEBHOOK_PORTAL_URL = 'WEBHOOK_PORTAL_URL';

// FRAUD DETECTION
export const CASES_IDS = 'CASES_IDS';
export const GET_FLOW = 'GET_FLOW';
export const NEXT_AND_PREV_CASE_ID = 'NEXT_AND_PREV_CASE_ID';
export const GET_CASES = 'GET_CASES';
export const GET_FLOWS = 'GET_FLOWS';
export const GET_EVENTS = 'GET_EVENTS';
export const CREATE_FLOW = 'CREATE_FLOW';
export const FLOW_LOADING = 'FLOW_LOADING';
export const FLOW_GRAPH_LOADING = 'FLOW_GRAPH_LOADING';
export const GET_CASES_META = 'GET_CASES_META';
export const GET_CASES_COMMENTS = 'GET_CASES_COMMENTS';
export const GET_CASES_DOCUMENTS = 'GET_CASES_DOCUMENTS';
export const CASES_DOCUMENTS_UPLOAD = 'CASES_DOCUMENTS_UPLOAD';
export const CASES_DOCUMENTS_DELETE = 'CASES_DOCUMENTS_DELETE';
export const CASES_COMMENT_DELETE = 'CASES_COMMENT_DELETE';
export const CASES_COMMENT_SAVE = 'CASES_COMMENT_SAVE';
export const GET_CASES_COMMENTS_LOADING = 'GET_CASES_COMMENTS_LOADING';
export const GET_TOTAL_EVENTS = 'GET_TOTAL_EVENTS';
export const GET_TOTAL_PENDING = 'GET_TOTAL_PENDING';
export const GET_TOTAL_BLOCKED = 'GET_TOTAL_BLOCKED';
export const GET_TOTAL_ALLOWED = 'GET_TOTAL_ALLOWED';
export const GET_PENDING_CASES = 'GET_PENDING_CASES';
export const GET_FLOW_OVERVIEW = 'GET_FLOW_OVERVIEW';
export const FRAUD_FLOW_PROCESS = 'FRAUD_FLOW_PROCESS';
export const GET_FLOWS_THRESHOLD = 'GET_FLOWS_THRESHOLD';
export const CREATE_FLOW_PROCESS = 'CREATE_FLOW_PROCESS';
export const ADD_FLOW_PROCESS_RULE = 'ADD_FLOW_PROCESS_RULE';
export const GET_FLOW_OVERVIEW_GRAPH = 'GET_FLOW_OVERVIEW_GRAPH';
export const SET_FRAUD_DEFAULT_PAGES = 'SET_FRAUD_DEFAULT_PAGES';

////////// CASE LISTS //////////////////////
export const GET_CASE_LISTS = 'GET_CASE_LISTS';
export const LISTS_LOADING = 'LISTS_LOADING';
export const GET_CASE_LIST = 'GET_CASE_LIST';
export const ADD_CASE_LIST = 'ADD_CASE_LIST';
export const MUTATE_LIST_LOADING = 'MUTATE_LIST_LOADING';
export const UPDATE_CASE_LIST = 'UPDATE_CASE_LIST';
export const DELETE_CASE_LIST = 'DELETE_CASE_LIST';

// DASHBOARD
export const DASHBOARD_DATA = 'DASHBOARD_DATA';

// LOOKUP
export const LOOKUP = 'LOOKUP';
export const H_LOADING = 'H_LOADING';
export const L_LOADING = 'L_LOADING';
export const BANK_CODES = 'BANK_CODES';
export const LOOKUP_PAGE = 'LOOKUP_PAGE';
export const DOWNLOADING = 'DOWNLOADING';
export const SINGLE_BATCH = 'SINGLE_BATCH';
export const BATCH_LOOKUP = 'BATCH_LOOKUP';
export const LOOKUP_APP_ID = 'LOOKUP_APP_ID';
export const LOOKUP_HISTORY = 'LOOKUP_HISTORY';
export const BUSINESS_LOOKUP_HISTORY = 'BUSINESS_LOOKUP_HISTORY';
export const BATCH_BUSINESS_LOOKUP = 'BATCH_BUSINESS_LOOKUP';

//TEAMS
export const TEAM_LOADING = 'TEAM_LOADING';
export const GET_TEAM_ROLES = 'GET_TEAM_ROLES';
export const GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS';
export const EDIT_TEAM_ROLE_LOADING = 'EDIT_TEAM_ROLE_LOADING';
export const DELETE_TEAM_ROLE_LOADING = 'DELETE_TEAM_ROLE_LOADING';
export const TRANSFER_OWNERSHIP_LOADING = 'TRANSFER_OWNERSHIP_LOADING';

// AML
export const GET_AML_HISTORIES = 'GET_AML_HISTORIES';
export const GET_AML_PROFILE = 'GET_AML_PROFILE';
export const GET_AML_HISTORY = 'GET_AML_HISTORY';
export const AML_LOOKUP_LOADING = 'AML_LOOKUP_LOADING';
export const AML_LOADING = 'AML_LOADING';
export const AML_CONFIG_LOADING = 'AML_CONFIG_LOADING';
export const GET_AML_CONFIG = 'GET_AML_CONFIG';
export const BATCH_AML_LOOKUP = 'BATCH_AML_LOOKUP';
export const AML_LOOKUP_APP_ID = 'AML_LOOKUP_APP_ID';
export const GET_BATCH_AML_HISTORY = 'GET_BATCH_AML_HISTORY';

// DEVELOPER -> APPS
export const UPDATED = 'UPDATED';
export const SET_LIVE = 'SET_LIVE';
export const GET_APPS = 'GET_APPS';
export const APP_LOADING = 'APP_LOADING';
export const GET_ALL_APPS = 'GET_ALL_APPS';
export const M_APP_LOADING = 'M_APP_LOADING';
export const CLEAR_ALL_APPS = 'CLEAR_ALL_APPS';

// DEVELOPER -> API LOGS
export const API_LOGS = 'API_LOGS';
export const API_LOGS_GRAPH = 'API_LOGS_GRAPH';
export const EXPORT_LOADING = 'EXPORT_LOADING';
export const API_LOGS_FILTERS = 'API_LOGS_FILTERS';
export const API_LOGS_SUMMARY = 'API_LOGS_SUMMARY';
export const API_LOGS_LOADING = 'API_LOGS_LOADING';

// WIDGETS
export const WIDGETS = 'WIDGETS';
export const W_LOADING = 'W_LOADING';
export const W_UPDATED = 'W_UPDATED';
export const U_LOADING = 'U_LOADING';
export const SINGLE_APP = 'SINGLE_APP';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const DELETE_WIDGET = 'DELETE_WIDGET';
export const CREATE_WIDGETS = 'CREATE_WIDGETS';
export const SET_FRAUD_RULES = 'SET_FRAUD_RULES';
export const SET_SCREEN_TYPE = 'SET_SCREEN_TYPE';
export const SET_WIDGET_STATE = 'SET_WIDGET_STATE';
export const SET_WIDGET_PAGES = 'SET_WIDGET_PAGES';
export const SET_WIDGET_SCREEN = 'SET_WIDGET_SCREEN';
export const GET_SINGLE_WIDGET = 'GET_SINGLE_WIDGET';
export const SET_WIDGET_DEFAULT = 'SET_WIDGET_DEFAULT';
export const CLEAR_WIDGET_PAGES = 'CLEAR_WIDGET_PAGES';
export const SET_WIDGET_SCREENS = 'SET_WIDGET_SCREENS';
export const PREVIEW_WIDGET_CODE = 'PREVIEW_WIDGET_CODE';
export const MUTATE_WIDGET_PAGES = 'MUTATE_WIDGET_PAGES';

//////////////// WIDGET PRICING //////////////////////////
export const PRICING = 'PRICING';
export const SET_WIDGET_CURRENT_PRICING = 'SET_WIDGET_CURRENT_PRICING';
export const SET_WIDGET_TOTAL_CURRENT_PRICING =
  'SET_WIDGET_TOTAL_CURRENT_PRICING';

export const DO_NOTHING = 'DO_NOTHING';

// VERIFICATIONS
export const ADDRESS = 'ADDRESS';
export const TIMELINE = 'TIMELINE';
export const DEVICE_IP = 'DEVICE_IP';
export const KYC_CUSTOMER = 'KYC_CUSTOMER';
export const KYC_BUSINESS = 'KYC_BUSINESS';
export const VERIFICATIONS = 'VERIFICATIONS';
export const SESSION_SUMMARY = 'SESSION_SUMMARY';
export const VERIFICATIONS_LOADING = 'VERIFICATIONS_LOADING';
export const EXPORT_VERIFICATIONS_LOADING = 'EXPORT_VERIFICATIONS_LOADING';
