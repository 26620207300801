import {v4} from 'uuid';

import {
  CASES_IDS,
  CREATE_FLOW_PROCESS,
  FLOW_GRAPH_LOADING,
  FLOW_LOADING,
  FRAUD_FLOW_PROCESS,
  GET_CASES,
  GET_CASES_META,
  GET_EVENTS,
  GET_FLOW,
  GET_FLOW_OVERVIEW,
  GET_FLOW_OVERVIEW_GRAPH,
  GET_FLOWS,
  GET_FLOWS_THRESHOLD,
  GET_PENDING_CASES,
  GET_TOTAL_ALLOWED,
  GET_TOTAL_BLOCKED,
  GET_TOTAL_EVENTS,
  GET_TOTAL_PENDING,
  NEXT_AND_PREV_CASE_ID,
  GET_CASES_COMMENTS,
  GET_CASES_DOCUMENTS,
  CASES_DOCUMENTS_UPLOAD,
  CASES_DOCUMENTS_DELETE,
  CASES_COMMENT_SAVE,
  CASES_COMMENT_DELETE,
  GET_CASES_COMMENTS_LOADING,
} from '../types';
import reduxApi from '../../helpers/reduxApi';
import {toast} from 'react-toastify';
import {defaultFlowProcess} from '../../routes/FraudDetection/Flows/EditProcess/defaultProcess';
import {memoize} from '../../helpers/memoizeFunction';
import store from '../index';

// const uniqueID = v4();

export const getFlows = memoize(({page, limit, status, start, end}) =>
  reduxApi(
    `/users/fraud-detection?page=${page}&limit=${limit}${
      status !== undefined ? `&status=${status}` : ''
    }${start !== undefined ? `&start=${start}` : ''}${
      end !== undefined ? `&end=${end}` : ''
    }`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_FLOWS});
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_FLOWS,
    },
  ),
);

export const getFlowsThreshold = memoize(({flowId = '', METHOD = '', data}) =>
  reduxApi(
    `/users/fraud-detection/${flowId}/threshold`,
    METHOD,
    METHOD === 'PUT' ? {...data} : null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_FLOWS_THRESHOLD});
        if (METHOD === 'PUT') {
          toast.success(data?.message || 'Successful');
        }
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_FLOWS_THRESHOLD,
    },
  ),
);
export const getFlow = id =>
  reduxApi(
    `/users/fraud-detection/${id}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_FLOW});

        if (data?.flow && data?.flow?.process) {
          const flowArray = [...(data?.flow?.process || [])];
          const start = flowArray.shift();
          const end = flowArray.pop();

          const newTempFlow = flowArray.map(flow => ({
            key: v4(),
            ...flow,
          }));

          const FLOW_PROCESS = {process: [start, newTempFlow, end]};
          dispatch({payload: FLOW_PROCESS, type: CREATE_FLOW_PROCESS});
          dispatch({payload: '', type: FLOW_LOADING});
        } else {
          dispatch(createFlowProcess(defaultFlowProcess));
        }
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_FLOW,
    },
  );
export const getEvents = ({id, page, limit}) =>
  reduxApi(
    `/users/fraud-detection/${id}/events?page=${page || 1}&limit=${
      limit || 10
    }`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_EVENTS});
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_EVENTS,
    },
  );

const casesUrl = (id, type, page) => {
  if (id) {
    return `/users/fraud-detection/cases/${type}?flow=${id}${
      page ? `&page=${page}` : ''
    }`;
  } else {
    return `/users/fraud-detection/cases/${type}${page ? `?page=${page}` : ''}`;
  }
};
export const getCases = payload =>
  reduxApi(
    `${casesUrl(payload?.id, payload.type, payload.page)}${
      payload?.limit ? `&limit=${payload?.limit}` : ''
    }${payload?.flow ? `&flow=${payload?.flow}` : ''}${
      payload?.status ? `&status=${payload?.status}` : ''
    }${payload?.start ? `&start=${payload.start}` : ''}${
      payload?.end ? `&end=${payload.end}` : ''
    }`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        payload?.id
          ? dispatch({payload: data, type: GET_CASES})
          : dispatch({payload: data, type: GET_PENDING_CASES});
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_CASES,
    },
  );

export const updateCases = ({transaction_id, data}) =>
  reduxApi(
    `/users/fraud-detection/cases/${transaction_id}`,
    'PUT',
    {...data},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: null, type: FLOW_LOADING});
        dispatch({payload: data, type: 'GET_CASES__'});
        toast.success(data);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: 'GET_CASES__',
    },
  );
export const getCasesMeta = ({transaction_id}) =>
  reduxApi(
    `/users/fraud-detection/cases/${transaction_id}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_CASES_META});
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_CASES_META,
    },
  );

/////////////      CASES DOCUMENTS      ////////////////
export const getCasesDocuments = memoize(transaction_id =>
  reduxApi(
    `/users/fraud-detection/cases/${transaction_id}/document`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_CASES_DOCUMENTS});
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_CASES_DOCUMENTS,
    },
  ),
);
export const uploadCasesDocuments = memoize(({id, data}, callback) =>
  reduxApi(
    `/users/fraud-detection/cases/${id}/document`,
    'POST',
    {...data},
    {
      report: true,
      success: (data, dispatch) => {
        callback(true);
        setTimeout(() => {
          dispatch(getCasesDocuments(id));
          toast.success('Document uploaded successfully');
        }, 1000);
      },
    },
    {
      error: 'ERROR',
      loading: CASES_DOCUMENTS_UPLOAD,
      responder: '_',
    },
  ),
);
export const deleteCasesDocument = memoize(({id, docId}) =>
  reduxApi(
    `/users/fraud-detection/cases/${id}/document/${docId}`,
    'DELETE',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        setTimeout(() => {
          dispatch(getCasesDocuments(id));
          toast.success('Document deleted successfully');
        }, 1000);
      },
    },
    {
      error: 'ERROR',
      loading: CASES_DOCUMENTS_DELETE,
      responder: '_',
    },
  ),
);

///////////////   CASES COMMENTS     ///////////////////
export const getCasesComments = memoize(transaction_id =>
  reduxApi(
    `/users/fraud-detection/cases/${transaction_id}/comment`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_CASES_COMMENTS});
      },
    },
    {
      error: 'ERROR',
      loading: GET_CASES_COMMENTS_LOADING,
      responder: GET_CASES_COMMENTS,
    },
  ),
);
export const makeCommentOnACase = memoize(({id, comment}) =>
  reduxApi(
    `/users/fraud-detection/cases/${id}/comment`,
    'POST',
    {...comment},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch(getCasesComments(id));
      },
    },
    {
      error: 'ERROR',
      loading: CASES_COMMENT_SAVE,
      responder: '-',
    },
  ),
);

export const deleteCasesComment = memoize(({id, commentId}) =>
  reduxApi(
    `/users/fraud-detection/cases/${id}/comment/${commentId}`,
    'DELETE',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        setTimeout(() => {
          dispatch(getCasesComments(id));
          toast.success('Comment deleted successfully');
        }, 1000);
      },
    },
    {
      error: 'ERROR',
      loading: CASES_COMMENT_DELETE,
      responder: '_',
    },
  ),
);
export const getOverview = () =>
  reduxApi(
    `/users/fraud-detection/insights/summary`,
    'GET',
    null,
    {},
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_FLOW_OVERVIEW,
    },
  );
export const getTotalEvents = flowId =>
  reduxApi(
    `/users/fraud-detection/insights/get-total-events${
      flowId ? `?flow=${flowId}` : ''
    }`,
    'GET',
    null,
    {},
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_TOTAL_EVENTS,
    },
  );
export const getTotalAllowed = flowId =>
  reduxApi(
    `/users/fraud-detection/insights/get-total-allowed${
      flowId ? `?flow=${flowId}` : ''
    }`,
    'GET',
    null,
    {},
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_TOTAL_ALLOWED,
    },
  );
export const getTotalPending = flowId =>
  reduxApi(
    `/users/fraud-detection/insights/get-total-pending${
      flowId ? `?flow=${flowId}` : ''
    }`,
    'GET',
    null,
    {},
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_TOTAL_PENDING,
    },
  );
export const getTotalBlocked = flowId =>
  reduxApi(
    `/users/fraud-detection/insights/get-total-blocked${
      flowId ? `?flow=${flowId}` : ''
    }`,
    'GET',
    null,
    {},
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_TOTAL_BLOCKED,
    },
  );
export const getOverviewGraph = data => {
  const url = `/users/fraud-detection/insights/graph?page=${
    data?.page || 1
  }&limit=${data?.limit || 10}${data?.flow ? `${data.flow}` : ''}${
    data?.start ? `&start=${data.start}` : ''
  }${data?.end ? `&end=${data.end}` : ''}`;
  return reduxApi(
    url,
    'GET',
    null,
    {},
    {
      error: 'ERROR',
      loading: FLOW_GRAPH_LOADING,
      responder: GET_FLOW_OVERVIEW_GRAPH,
    },
  );
};

export const createFraudFlowProcess = ({id, flowProcess}) => {
  const FLOW = [...flowProcess];
  const startProcess = FLOW[0];
  const endProcess = FLOW[FLOW.length - 1];

  const finalProcess = {
    process: [startProcess, ...FLOW[1], endProcess].map(process => {
      if (process?.configurations) {
        return {
          configurations: process?.configurations,
          type: process.type,
          name: process.name,
        };
      } else {
        return {
          type: process.type,
          name: process.name,
        };
      }
    }),
  };
  return reduxApi(
    `/users/fraud-detection/${id}/process`,
    'PUT',
    {...finalProcess},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: FRAUD_FLOW_PROCESS});
        toast.success('Flow process rules added successfully');
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: FRAUD_FLOW_PROCESS,
    },
  );
};

///////////// TASKS //////////////////////////////////

export const getTasks = id =>
  reduxApi(
    `/users/fraud-detection/${id}/tasks`,
    'GET',
    {},
    {
      report: true,
      success: (data, dispatch) => {
        if (data?.tasks && data?.tasks?.length > 0) {
          dispatch({payload: '', type: FLOW_LOADING});
          const flowArray = [...(data?.tasks || [])];
          const start = flowArray.shift() || defaultFlowProcess?.process[0];
          const end = flowArray.pop() || defaultFlowProcess?.process[2];

          const newTempFlow = flowArray.map(flow => ({
            key: v4(),
            ...flow,
          }));

          const FLOW_PROCESS = {process: [start, newTempFlow, end]};
          dispatch({payload: FLOW_PROCESS, type: CREATE_FLOW_PROCESS});
        } else {
          dispatch(createFlowProcess(defaultFlowProcess));
        }
      },
    },
    {
      error: 'ERROR',
      loading: 'FLOW_LOADING--',
      responder: CREATE_FLOW_PROCESS,
    },
  );
export const addTask = (id, data) =>
  reduxApi(
    `/users/fraud-detection/${id}/tasks`,
    'POST',
    {...data},
    {
      report: true,
      success: (data, dispatch) => {
        if (data) {
          toast.success('Task added successfully');
          dispatch(getTasks(id));
        }
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: 'CREATE_FLOW_PROCESSdd',
    },
  );

export const updateTask = (id, data, taskId) =>
  reduxApi(
    `/users/fraud-detection/${id}/tasks/${taskId}`,
    'PUT',
    {...data},
    {
      report: true,
      success: data => {
        if (data) {
          toast.success('Task updated successfully');
          // dispatch(getTask(id));
        }
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: 'CREATE_FLOW_PROCESSdd',
    },
  );
export const deleteTask = (id, taskId) =>
  reduxApi(
    `/users/fraud-detection/${id}/tasks/${taskId}`,
    'DELETE',
    {},
    {
      report: true,
      success: data => {
        if (data) {
          toast.success(`${data?.message} successfully`);
          // dispatch(getTask(id));
        }
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: 'CREATE_FLOW_PROCESSdd',
    },
  );

export const saveTaskProcess = ({id, flowProcess}) => {
  const FLOW = [...flowProcess, ...flowProcess[1]];

  const finalProcess = {
    process: FLOW.map(process => process?._id).filter(process => !!process),
  };

  return reduxApi(
    `/users/fraud-detection/${id}/process`,
    'PUT',
    {...finalProcess},
    {
      report: true,
      success: data => {
        if (data) {
          toast.success(`Process saved successfully`);
          // dispatch(getTask(id));
        }
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: 'CREATE_FLOW_PROCESSdd',
    },
  );
};

export const updateFlow = (id, data) =>
  reduxApi(
    `/users/fraud-detection/${id}`,
    'PUT',
    {...data},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_FLOW});
        window.location.reload();
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: GET_FLOW,
    },
  );
export const createFlow = (flowData, callback) =>
  reduxApi(
    `/users/fraud-detection`,
    'POST',
    {...flowData},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch(getFlows({page: 1}));
        callback();
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: '-',
    },
  );

export const deleteFlow = (id, callback) =>
  reduxApi(
    `/users/fraud-detection/${id}`,
    'DELETE',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        callback(false);
        dispatch(getFlows({page: 1}));
        toast.success(data.message);
        setTimeout(() => {
          window.location.href = '/flows';
        }, 2000);
      },
    },
    {
      error: 'ERROR',
      loading: FLOW_LOADING,
      responder: '-',
    },
  );

export const createFlowProcess = data => dispatch => {
  dispatch({payload: data, type: CREATE_FLOW_PROCESS});
};

export const getIDs = memoize((data = []) => dispatch => {
  const ids = data
    ?.filter(aCase => aCase.status.toLowerCase() === 'pending')
    .map(aCase => aCase.id);
  dispatch({payload: ids, type: CASES_IDS});
});

export const reFetchCases = memoize(currentPage => dispatch => {
  dispatch(getCases({page: currentPage, limit: 10}));
  const {caseIds = [], cases} = store.getState().flows;
  localStorage.setItem('casesPageNumber', String(currentPage));
  dispatch(getIDs(cases?.data?.data));

  return caseIds;
});

export const getPreviousCaseId = memoize(
  ({currentPage, navigate, id}) =>
    dispatch => {
      const caseIds = dispatch(reFetchCases(currentPage - 1));
      if (caseIds.length > 0) {
        id === undefined
          ? navigate(`/pending-cases/details/${caseIds[caseIds?.length - 1]}`)
          : navigate(
              `/flows/cases/${id}/details/${caseIds[caseIds?.length - 1]}`,
            );
        dispatch({
          payload: {nextCaseId: '', prevCaseId: caseIds[caseIds?.length - 2]},
          type: NEXT_AND_PREV_CASE_ID,
        });
      }
    },
);

export const getNextCaseId = memoize(
  ({currentPage, navigate, id}) =>
    dispatch => {
      const caseIds = dispatch(reFetchCases(currentPage + 1));
      if (caseIds.length > 0) {
        id === undefined
          ? navigate(`/pending-cases/details/${caseIds[0]}`)
          : navigate(`/flows/cases/${id}/details/${caseIds[0]}`);
        dispatch({
          payload: {nextCaseId: caseIds[1], prevCaseId: ''},
          type: NEXT_AND_PREV_CASE_ID,
        });
      }
    },
);

export const getNextAndPrevId = memoize(
  ({currentCases = [], currentId, page, allPages}) =>
    dispatch => {
      dispatch(getIDs(currentCases));

      if (page === allPages) return;
      const {caseIds = []} = store.getState().flows;

      const currentIndex = caseIds.indexOf(currentId);
      if (caseIds?.length > 0) {
        const nextIndex = currentIndex + 1;
        const prevIndex = currentIndex - 1;
        const nextId =
          currentIndex === -1
            ? caseIds[caseIds?.length - 1]
            : nextIndex < caseIds?.length
            ? caseIds[nextIndex]
            : '';
        const prevId =
          currentIndex === -1 && page === 1
            ? caseIds[caseIds?.length - 2]
            : prevIndex >= 0
            ? caseIds[prevIndex]
            : '';
        dispatch({
          payload: {nextCaseId: nextId, prevCaseId: prevId},
          type: NEXT_AND_PREV_CASE_ID,
        });
      }
    },
);
