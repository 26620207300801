/* eslint-disable react-hooks/exhaustive-deps */

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Modal} from '../../../components';
import {connect} from 'react-redux';
import * as Actions from '../../../store/actions';
import {toast} from 'react-toastify';
import {infoRedSm} from '../../../assets/images/images';
import {signaturePageDefaultInfo as defaultInformation} from '../widgetHelpers';

function SignaturePage({
  widgets: {widgetScreen, widgetPages, widgetPricing},
  setWidgetScreen,
  setWidgetPages,
  setWidgetCurrentPricing,
  ...otherProps
}) {
  const {open, setOpen} = otherProps;

  const [error, setError] = useState(false);
  const [config, setConfig] = useState({});
  const [pricing, setPricing] = useState({
    services: {},
  });

  const filteredConfig = useCallback(
    data =>
      Object.fromEntries(
        Object.entries({
          ...data,
        }).filter(([, value]) => !!value),
      ),
    [config],
  );

  useEffect(() => {
    const isActive =
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.page === 'signature' && data[`${data?.page}-${data?.pageId}`],
      );
    if (isActive) {
      setPricing(prev => {
        const price =
          (widgetPricing && widgetPricing?.signature?.services?.base) || 0;

        return {
          services: {
            ...{
              ...prev.services,
              docs: price / 100,
            },
          },
        };
      });

      setWidgetCurrentPricing({
        signature: pricing,
      });
    } else {
      setWidgetCurrentPricing({
        signature: {},
      });
    }
    return () =>
      setWidgetCurrentPricing({
        signature: {},
      });
  }, [widgetScreen, Object.keys(pricing?.services).length > 0]);

  const newData = useMemo(
    () =>
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.pageId === widgetScreen?.pageId && data?.page === 'signature',
      ),
    [widgetPages, widgetScreen],
  );

  const ALL_PAGES = JSON.parse(localStorage.getItem('pages'));
  const updateConfig = newConfig => {
    const newPages = ALL_PAGES?.map(page => {
      if (page?.page === newData?.page) {
        return {
          ...page,
          config: newConfig,
        };
      }
      return page;
    });

    localStorage.setItem('pages', JSON.stringify(newPages));
  };

  useEffect(() => {
    const trimmedInformation = config?.information?.replace(/^\s+|\s+$/g, '');

    if (Object.keys(newData?.config || {}).length === 0) {
      setConfig({});
      setError(false);
      setConfig({
        information: trimmedInformation || defaultInformation,
      });
      setWidgetPages({
        ...newData,
        config: filteredConfig({
          information: trimmedInformation || defaultInformation,
        }),
      });
      updateConfig(
        filteredConfig({
          information: trimmedInformation || defaultInformation,
        }),
      );
    } else {
      setError(false);

      const newConfig = {
        information:
          newData?.config?.information ||
          trimmedInformation ||
          defaultInformation,
      };
      const filteredObj = filteredConfig({...config, ...newConfig});
      setConfig({
        ...config,
        ...newConfig,
      });
      setWidgetPages({
        ...newData,
        config: {
          ...filteredObj,
        },
      });
      updateConfig({
        ...filteredObj,
      });
    }

    return () => {
      setConfig({
        information: newData?.config?.information || defaultInformation,
      });
    };
  }, [widgetPages?.length, Object.keys(config).length, widgetScreen?.pageId]);

  const handleUpdateConfig = useCallback(
    e => {
      const {value, name} = e.target;
      if (
        name === 'information' &&
        (value.length < 1 || config.information.length < 1)
      ) {
        setError(true);
      } else {
        setError(false);
      }
      if (!newData) {
        return toast.error(
          'Ops! Please toggle ON before configuring this widget',
        );
      }

      const filteredObj = filteredConfig({...config, [name]: value});
      setConfig({...config, [name]: value});
      setWidgetScreen({
        ...widgetScreen,
        config: {...filteredObj},
      });
      setWidgetPages({
        ...newData,
        config: {...filteredObj},
      });
    },
    [widgetScreen, config],
  );

  return (
    <Modal
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[339px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Configuration"
    >
      <p className="text-left text-body text-sm mt-8 mb-2 font-medium">
        Signature information
      </p>
      <textarea
        placeholder="Input information"
        name="information"
        onChange={e => handleUpdateConfig(e)}
        className={`placeholder:text-sm  py-3 resize-y min-h-[40px] max-h-[100px] ${
          error && 'border-0  focus:outline-danger'
        } `}
        value={config?.information}
        maxLength={3000}
      ></textarea>
      {error && (
        <div className="flex  bg-statusBg-500 p-2 rounded">
          <img src={infoRedSm} alt="" />
          <p className="text-xs ml-3 text-body">
            Field is required. Please input information
          </p>
        </div>
      )}
      <p className="text-end text-body text-sm font-medium mt-2">
        {config?.information?.length || 0}/3000
      </p>
    </Modal>
  );
}

export default connect(state => state, Actions)(SignaturePage);
