/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {Accordion, VerificationToggle} from '../../../components';
import {getFraudPages} from '../../../helpers/getPages';
import {removeDuplicates} from '../../../helpers/removeArrayDuplictes';

import * as Actions from '../../../store/actions';
import {DEFAULT_FRAUD_PAGES} from '../defaultPages';
import {EasyOnboardContext} from '../../../helpers/context/EasyOnboardContext';
import {toast} from 'react-toastify';

function FraudCheckAccordion({
  setPage,
  setFraudRules,
  setScreenType,
  setWidgetScreen,
  setFraudDefaultPages,
  widgets: {
    fraudDefaultPages,
    screenType,
    widgetScreen,
    fraudRules,
    widgetPages,
  },
}) {
  const {widgetData, setWidgetData, setTrackChanges} =
    useContext(EasyOnboardContext);

  const DEFAULT_PAGES = removeDuplicates([
    ...((fraudDefaultPages && fraudDefaultPages) || []),
    ...((fraudRules && fraudRules) || []),
  ]);

  const updatedPages = useMemo(
    () =>
      DEFAULT_PAGES.map(obj => {
        if (!fraudRules?.find(el => el?.page === obj?.page)) {
          obj.isActive = false;
          if (obj[obj?.page]?.fields) {
            obj[obj?.page].fields = ['first_name'];
          }
        }
        return obj;
      }),
    [DEFAULT_PAGES, fraudRules],
  );

  const [items, setItems] = useState([...DEFAULT_PAGES]);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    setItems(updatedPages || DEFAULT_FRAUD_PAGES);
    fraudDefaultPages === null && setFraudDefaultPages(DEFAULT_FRAUD_PAGES);
  }, [setFraudDefaultPages, fraudDefaultPages, updatedPages?.length]);

  useEffect(() => {
    if (screenType === 'fraud') {
      setWidgetScreen(items[pageIndex]);
    }
  }, [screenType, items, pageIndex, setWidgetScreen]);

  const foundLivenessOrSelfie = widgetPages?.filter(
    page =>
      page?.page === 'selfie' ||
      (page?.page === 'government-data' && page?.config?.selfie === true),
  );

  const handleCheck = useCallback(
    (value, page) => {
      if (
        page?.page === 'liveness_match' &&
        value === true &&
        foundLivenessOrSelfie?.length === 0
      ) {
        return toast.error(
          'Please enable Govt Data with selfie OR enable Liveness on Verification pages',
        );
      }

      setWidgetScreen({...page, isActive: value});
      setPage(page?.page);
      setFraudRules({...page, isActive: value});
      if (page?.page === 'aml_screening') {
        setWidgetData({...widgetData, aml_check: value});
      }
      if (page.page === 'duplicate_check') {
        setWidgetData({...widgetData, duplicate_check: value});
      }

      const newData =
        items?.length &&
        items?.map(data => {
          if (page?.page === data?.page) {
            return {
              ...data,
              isActive: value,
            };
          } else {
            return data;
          }
        });
      const newRules = [...newData];
      setFraudDefaultPages(newRules);
      setItems(newRules);
      setTrackChanges(true);
    },
    [
      items,
      setFraudDefaultPages,
      setPage,
      setWidgetScreen,
      setFraudRules,
      widgetData,
      foundLivenessOrSelfie,
    ],
  );

  return (
    <Accordion
      title="Fraud check"
      onClick={() => {
        setScreenType('fraud');
      }}
    >
      <p className="text-xs text-body leading-[18px]">
        Detect suspicious actions by setting rules and conditions that would
        serve as blockers to monitor fraud.
      </p>

      <div className="mt-4 flex flex-col gap-2">
        {items &&
          items.map((fraud, index) => (
            <div
              key={index}
              className={`bg-white80 pl-4 pr-2 py-[10px] flex justify-between rounded cursor-pointer hover:outline hover:outline-1 ${
                fraud?.page === widgetScreen?.page &&
                'outline outline-1 outline-brandBlue '
              }`}
              tabIndex={0}
              onClick={() => {
                setWidgetScreen(fraud);
                setPage(fraud?.page);
                setPageIndex(index);
              }}
            >
              <div className="flex items-center gap-2">
                <VerificationToggle
                  isChecked={fraud?.isActive}
                  onCheck={value => handleCheck(value, fraud)}
                />
                <span className="text-xs leading-[18px] text-brandBlue50">
                  {getFraudPages(fraud?.page)}
                </span>
              </div>
            </div>
          ))}
      </div>
    </Accordion>
  );
}

export default connect(state => state, Actions)(FraudCheckAccordion);
