/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {Radios, Modal, VerificationToggle} from '../../../components';
import * as Actions from '../../../store/actions';
import {toast} from 'react-toastify';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../../helpers/formatNumberToCurrency';
import {EasyOnboardContext} from '../../../helpers/context/EasyOnboardContext';

function Selfie({
  widgets: {widgetScreen, widgetPages, widgetPricing},
  auth: {userDetails, userSettings},
  setWidgetScreen,
  setWidgetPages,
  setWidgetCurrentPricing,
  ...otherProps
}) {
  const {open, setOpen} = otherProps;
  const [selfieConfig, setSelfieConfig] = useState({
    type: 'basic',
    version: 3,
    brightnessThreshold: 40,
    glassesCheck: true,
    flipCamera: false,
  });

  const [pricing, setPricing] = useState({
    services: {},
  });
  const [updated, setUpdated] = useState(true);

  const {detectWithLiveness, setDetectWithLiveness} =
    useContext(EasyOnboardContext);

  const displayPrice = price =>
    userDetails && userDetails.company && userDetails.company.currency === 'USD'
      ? formatUsd(price || 0)
      : formatNumberToCurrency(
          price || 0,
          userDetails && userDetails.company && userDetails.company.currency,
          userSettings &&
            userSettings.settings &&
            userSettings.settings.dollar_exchange_rate,
        );
  useEffect(() => {
    const isActive =
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.page === 'selfie' && data[`${data?.page}-${data?.pageId}`],
      );
    if (isActive) {
      for (const key in selfieConfig) {
        if (typeof selfieConfig[key] === 'string') {
          setPricing(() => {
            const type = selfieConfig[key] === 'basic' ? 'selfie' : 'video';
            const price =
              widgetPricing && widgetPricing?.selfie?.services[type];
            if (selfieConfig[key]) {
              return {
                services: {
                  ...{
                    [type]: price / 100,
                  },
                },
              };
            }
          });
        }
      }

      setWidgetCurrentPricing({
        selfie: pricing,
      });
    } else {
      setWidgetCurrentPricing({
        selfie: {services: {selfie: 20}},
      });
    }
    return () =>
      setWidgetCurrentPricing({
        selfie: {},
      });
  }, [widgetScreen, widgetPages, setWidgetCurrentPricing, selfieConfig]);

  const newData = useMemo(
    () =>
      widgetPages &&
      widgetPages?.find(
        data =>
          data?.pageId === widgetScreen?.pageId && data?.page === 'selfie',
      ),
    [widgetPages, widgetScreen],
  );
  const ALL_PAGES = JSON.parse(localStorage.getItem('pages'));
  const updateConfig = newConfig => {
    const newPages = ALL_PAGES?.map(page => {
      if (page?.page === newData?.page) {
        return {
          ...page,
          config: newConfig,
        };
      }
      return page;
    });

    localStorage.setItem('pages', JSON.stringify(newPages));
  };

  useEffect(() => {
    if (newData) {
      setSelfieConfig({
        ...selfieConfig,
        ...newData?.config,
        brightnessThreshold:
          newData.config?.brightnessThreshold ||
          detectWithLiveness?.brightnessThreshold ||
          40,
        glassesCheck:
          newData.config?.glassesCheck ||
          detectWithLiveness?.glassesCheck ||
          true,
      });
      setDetectWithLiveness({
        brightnessThreshold:
          newData.config?.brightnessThreshold ||
          detectWithLiveness?.brightnessThreshold ||
          40,
        glassesCheck:
          newData.config?.glassesCheck ||
          detectWithLiveness?.glassesCheck ||
          true,
      });
      setWidgetPages({
        ...newData,
        config: {...selfieConfig, ...newData?.config, ...detectWithLiveness},
      });
      updateConfig({...selfieConfig, ...newData?.config, detectWithLiveness});
    }
  }, [updated, setUpdated, widgetScreen]);

  const handleUpdateConfig = (value, key) => {
    const newData = widgetPages?.find(
      data => data?.pageId === widgetScreen?.pageId,
    );
    if (!newData) {
      return toast.error(
        'Oops! Please toggle ON before configuring this widget',
      );
    } else {
      setSelfieConfig({
        ...selfieConfig,
        [key]: value,
        ...(value === 'advanced' ? {version: 2} : {version: 3}),
      });
      setWidgetScreen({
        ...widgetScreen,
        config: {
          ...selfieConfig,
          [key]: value,
          ...(value === 'advanced' ? {version: 2} : {version: 3}),
        },
      });

      setWidgetPages({
        ...newData,
        config: {
          ...selfieConfig,
          [key]: value,
          ...(value === 'advanced' ? {version: 2} : {version: 3}),
        },
      });
    }
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[339px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Configuration"
    >
      <p className="text-left text-body text-xs mt-8 mb-2 font-medium uppercase">
        Type
      </p>
      <Radios
        data={[
          {
            value: 'basic',
            label: 'Selfie',
            name: 'selfie-type',
            price: displayPrice(
              widgetPricing && widgetPricing?.selfie?.services?.selfie / 100,
            ),
          },
          // {
          //   value: 'advanced',
          //   label: 'Video',
          //   name: 'selfie-type',
          //   price: displayPrice(
          //     widgetPricing &&
          //       widgetPricing['selfie']['services']['video'] / 100,
          //   ),
          // },
        ]}
        onCheck={e => {
          handleUpdateConfig(e.target.value, 'type');
          if (typeof e.target.value === 'string') {
            const type = e.target.value === 'basic' ? 'selfie' : 'video';
            setPricing(prev => {
              const price =
                widgetPricing && widgetPricing?.selfie?.services[type];
              if (e.target.value) {
                return {
                  services: {
                    ...{
                      ...prev.services,
                      [type]: price / 100,
                    },
                  },
                };
              } else {
                delete prev.services[type];
                return prev;
              }
            });

            setWidgetCurrentPricing({
              selfie: pricing,
            });
          } else {
            setWidgetCurrentPricing({
              selfie: {},
            });
          }
        }}
        radioValue={selfieConfig.type}
      />
      <div>
        <p className="text-left text-body text-xs mt-8 mb-2 font-medium uppercase">
          CAMERA FLIP OPTION
        </p>

        <div className="flex items-center gap-2 pl-4 pr-2 py-[10px] cursor-pointer">
          <VerificationToggle
            isChecked={selfieConfig?.flipCamera}
            onCheck={value => handleUpdateConfig(value, 'flipCamera')}
          />
          <div className="flex justify-between w-48">
            <p className="text-xs leading-[18px] text-body">
              Flip to back Camera
            </p>
            {/*<p className="text-xs ml-4 leading-[18px] text-grey20">*/}
            {/*  {displayPrice(*/}
            {/*    widgetPricing && widgetPricing?.id?.services?.base / 100,*/}
            {/*  )}*/}
            {/*</p>*/}
          </div>
        </div>
        <p className="text-left text-grey40 text-xs mt-2 mb-2 leading-5 ">
          Toggle on to add camera icon to the widget and easily flip to back
          camera
        </p>
      </div>
    </Modal>
  );
}

export default connect(state => state, Actions)(Selfie);
