import {
  GET_WEBHOOK_SUBSCRIPTIONS,
  WEBHOOK_LOADING,
  WEBHOOK_PORTAL_URL,
} from '../types';

const initialState = {
  loading: false,
  webhooks: null,
  portalUrl: null,
};

export default function webhook(state = initialState, action) {
  switch (action.type) {
    case WEBHOOK_LOADING:
      return {...state, loading: action.payload};
    case GET_WEBHOOK_SUBSCRIPTIONS:
      return {...state, webhooks: action.payload};
    case WEBHOOK_PORTAL_URL:
      return {...state, portalUrl: action.payload};

    default:
      return state;
  }
}
