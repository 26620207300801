import React from 'react';
import {Modal, PrimaryButton} from '../../../components';
import {autoLink, closeModal, manualLink} from '../../../assets/images/images';
import {GoBack} from './components/GoBack';

function ExternalTransactionUploadOptions({
  show,
  onClose,
  openUploadModal,
  setFileType,
  goBack,
}) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <GoBack
            goBack={() => {
              goBack();
              onClose(false);
            }}
          />

          <button
            onClick={() => {
              onClose(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>

        <h3 className="font-medium text-left mt-6 text-base text-grey">
          Upload Transaction
        </h3>

        <div className="flex flex-col mt-9">
          <div className="flex items-center justify-center px-12 py-8 rounded-2xl">
            <div className="flex flex-col items-center gap-2">
              <img src={manualLink} alt="manual" className="w-14 h-14" />
              <h1 className="text-secondaryBlue font-medium text-[18px]">
                Upload a CSV or .XLSX
              </h1>
              <p className="text-grey40 text-sm">Upload a csv or .xlsx</p>
              <PrimaryButton
                buttonText="CSV Upload"
                className="px-[40px] rounded-lg"
                onClick={() => {
                  openUploadModal();
                  setFileType('CSV, .XLS or .XLSX');
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-center px-12 py-8 bg-batchBlue20 rounded-2xl">
            <div className="flex flex-col items-center gap-2">
              <img src={autoLink} alt="auto" className="w-14 h-14" />
              <h1 className="text-secondaryBlue font-medium text-[18px]">
                Upload a bank statement PDF
              </h1>
              <p className="text-grey40 text-sm">Upload a bank statement</p>
              <PrimaryButton
                buttonText="PDF Upload"
                className="px-[40px] rounded-lg"
                onClick={() => {
                  openUploadModal();
                  setFileType('PDF');
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ExternalTransactionUploadOptions;
