import React from 'react';
import {Link} from 'react-router-dom';
import {
  actionsLookup,
  actionsOnboard,
  actionsTopup,
} from '../../assets/images/images';
import quickActionsGif from '../../assets/images/kycWidgetGif.gif';
import QuickActionsWrapper from './QuickActionsWrapper';
import QuickActionsCard from './QuickActionsCard';
import QuickActionsDynamicCard from './QuickActionsDynamicCard';

export default function QuickActionsExisting() {
  return (
    <QuickActionsWrapper>
      <div className="mt-8 flex flex-col gap-8 sm:gap-6">
        <div className="flex gap-4 flex-wrap sm:flex-nowrap">
          <QuickActionsCard
            image={actionsTopup}
            heading="Top up wallet"
            paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Libero
                sed malesuada etiam."
            linkText="Top up"
            link="/settings/billings"
          />
          <QuickActionsCard
            image={actionsLookup}
            heading="Easy Lookup"
            paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Libero
                sed malesuada etiam."
            linkText="Perform a Lookup"
            link="/easy-lookup/individual"
          />
          <QuickActionsCard
            image={actionsOnboard}
            heading="Easy Onboard"
            paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Libero
                sed malesuada etiam."
            linkText="Create a flow"
            link="/easy-onboard"
          />
        </div>

        <div className="flex gap-6 sm:gap-4 flex-col md:flex-row basis-full">
          <div className="py-6 px-4 lg:px-8 md:py-[21px] bg-white rounded basis-[67.2%]">
            <div className="flex flex-wrap sm:flex-nowrap items-center gap-8 justify-center sm:justify-start h-full">
              <div className="">
                <h4 className="text-base text-body font-medium">Quick Demo</h4>
                <p className="mt-6 text-grey text-lg font-medium">
                  Verify and onboard genuine users fast
                </p>
                <p className="mt-2 text-sm text-body">
                  With our Identity Verification Widget, you can automatically
                  analyze a wide range of identity documents, detect fraud and
                  sign up real users fast.
                </p>

                <Link
                  to="/"
                  className="inline-block mt-6 text-brandBlue text-sm font-medium py-2 px-3 border border-brandBlue rounded"
                >
                  Quick Demo
                </Link>
              </div>

              <div className="aspect-square w-full">
                <img src={quickActionsGif} alt="" />
              </div>
            </div>
          </div>

          <QuickActionsDynamicCard />
        </div>
      </div>
    </QuickActionsWrapper>
  );
}
