import React, {useState} from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import {
  Modal,
  PrimaryButton,
  DropdownIndicator,
  customStyles,
} from '../../components';
import * as Actions from '../../store/actions';
import {copyWhite} from '../../assets/images/images';
import {useQuery, useQueryClient} from 'react-query';
import {performAssessment} from '../../requests/queries/creditChecks';
import {toast} from 'react-toastify';

const ASSESSMENT_MODES = [
  {value: 'bvn', label: 'BVN'},
  {value: 'link', label: 'Assessment link'},
];

function PerformAssessmentModal({open, setOpen, userApps = []}) {
  const [selectedApp, setSelectedApp] = useState(null);
  const [bvn, setBvn] = useState('');
  const [fetch, setFetch] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const queryClient = useQueryClient();

  const {isLoading, refetch} = useQuery(
    ['perform-assessment', {app_id: selectedApp?.value, bvn}],
    () => performAssessment({app_id: selectedApp.value, bvn}),
    {
      onSuccess: data => {
        setFetch(false);
        toast.success(data?.entity?.message || 'Credit Assessment Started');
        queryClient.invalidateQueries(['credit_checks']);
      },
      onError: () => {
        setFetch(false);
        queryClient.invalidateQueries(['credit_checks']);
      },
      enabled: fetch,
      retry: false,
    },
  );

  const handlePerformAssessment = async () => {
    setFetch(true);
    await refetch();
    setFetch(false);
    setBvn('');
    setSelectedAssessment(null);
    setOpen(false);
  };
  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Perform assessment"
    >
      <form className="mt-6 text-left" onSubmit={e => e.preventDefault()}>
        <label htmlFor="app" className="relative mt-6 mb-2">
          Select app
        </label>
        <Select
          defaultValue={selectedApp}
          value={selectedApp}
          onChange={setSelectedApp}
          options={userApps || []}
          placeholder="Select app"
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="auto"
          id="app"
        />
        <label htmlFor="assessment" className="relative mt-6 mb-2">
          Mode of Assessment
        </label>
        <Select
          defaultValue={selectedAssessment}
          value={selectedAssessment}
          onChange={setSelectedAssessment}
          options={ASSESSMENT_MODES}
          placeholder="Select mode of assessment"
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="auto"
          id="assessment"
        />

        {selectedAssessment?.value === 'bvn' ? (
          <div className="mt-4 sm:bg-white80 sm:p-6 rounded">
            <label htmlFor="bvn" className="mt-6 sm:mt-0">
              <span className="text-sm">BVN</span>
              <input
                type="number"
                name="bvn"
                id="bvn"
                className="mt-2"
                value={bvn}
                placeholder="Enter BVN here..."
                onChange={e => setBvn(e.target.value)}
              />
            </label>
          </div>
        ) : selectedAssessment?.value === 'link' ? (
          <div className="mt-4 sm:bg-white80 sm:p-6 rounded">
            <label htmlFor="link" className="mt-6 sm:mt-0 relative">
              <span className="text-sm">Link</span>
              <input
                type="text"
                value="https://example.com/apparatus."
                name="link"
                id="link"
                className="mt-2"
              />
              <button className="absolute flex items-center gap-2 p-2 rounded bottom-[9px] right-4 bg-brandBlue">
                <img src={copyWhite} alt="" width={14} height={14} />
                <span className="text-xs font-medium text-white">Copy</span>
              </button>
            </label>
          </div>
        ) : null}

        <div className="flex items-center mt-6">
          <PrimaryButton
            buttonText="Look up"
            className="w-full"
            type="submit"
            onClick={handlePerformAssessment}
            loading={isLoading}
          />
        </div>
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(PerformAssessmentModal);
