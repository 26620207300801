import React, {memo} from 'react';
import Header from './Header';
const MemoizedHeader = memo(Header);
function DashboardLayout({
  children,
  action,
  headerText = '',
  topPadding = 'pt-16 sm:pt-6',
  leftMargin = 'ml-4 sm:ml-6 lg:ml-12',
  xlLeftMargin = 'xl:ml-22',
  xlRightPadding = 'xl:pr-6',
  bg = 'bg-white80',
  fullScreen,
  breadCrumbs,
  overFlow = 'overflow-x-hidden',
  className,
}) {
  return (
    <div className="flex flex-col min-h-screen">
      <main
        className={`lg:ml-[16.4%] ${bg} min-h-screen pr-4 ${overFlow} sm:pr-6 ${xlRightPadding}`}
      >
        <MemoizedHeader headerText={headerText} breadCrumbs={breadCrumbs} />
        <section
          className={`${topPadding} mt-6 sm:mt-16 ${leftMargin} ${xlLeftMargin} ${
            fullScreen ? 'mb-10 md:mb-0' : 'mb-16'
          } relative ${className}`}
        >
          {action ? (
            <div className="flex items-center justify-between gap-6 mt-6 mb-6 md:gap-0 sm:mt-0">
              {action}
            </div>
          ) : null}
          {children}
        </section>
      </main>
    </div>
  );
}

export default memo(DashboardLayout);
