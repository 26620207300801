import React, {useMemo} from 'react';
import {Modal, SkeletonLoader} from '../../../components';
import {closeModal, failed_t} from '../../../assets/images/images';
import {useQuery} from 'react-query';
import {fetchBAASTransaction} from '../../../requests/queries/baas';
import moment from 'moment';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import {getStatusTextAndStyles} from '../../../helpers/getStatusTextAndStyles';
import {getInitials, getStatusColor} from '../../../helpers';
import {CopyIcon} from '../../../components/images';
import {copyText} from './constants';

function BaasTransactionModal({show, onClose, transaction}) {
  const params = useMemo(
    () => ({
      transaction_id: (transaction && transaction?.transaction_id) || '',
    }),
    [transaction],
  );

  const {data: transaction_details, isLoading} = useQuery(
    ['baas-transaction-details', {params}],
    () => fetchBAASTransaction({params}),
    {
      enabled: !!(transaction && transaction?.transaction_id),
    },
  );

  const transactionDetailsData = useMemo(
    () => !isLoading && transaction_details && transaction_details?.entity,
    [isLoading, transaction_details],
  );
  const transactionType =
    transactionDetailsData?.transaction_type === 'transfer'
      ? 'debit'
      : 'credit';
  const receiverDetails = [
    {
      label: 'Account Name',
      value:
        transactionType === 'debit'
          ? transactionDetailsData?.recipient_account_name
          : transactionDetailsData?.sender_account_name || '-',
    },
    {
      label: 'Bank Name',
      value: transactionDetailsData?.recipient_bank_name || '-',
    },
    {
      label: 'Account Number',
      value: transactionDetailsData?.recipient_account_number || '-',
    },
  ];

  const transactionDetails = [
    {
      label: 'Transaction Time',
      value:
        transactionDetailsData?.date_created &&
        moment(transactionDetailsData?.date_created).format('DD MMM YYYY h:m'),
    },
    {
      label: 'Session ID',
      value:
        transactionDetailsData?.transaction_session_id ||
        '090110241028181800970723062795',
    },
    {
      label: 'Transaction Amount',
      value: shortenLargeNumbers(
        transactionDetailsData?.transaction_amount,
        'NGN',
        {startAt: 'B'},
      ),
    },
    {
      label: 'Currency',
      value: 'NGN',
    },
    {
      label: 'Type',
      value: (
        <span
          className={`uppercase w-fit  h-5 flex items-center justify-center font-medium text-xs rounded-full px-2 py-1 ${getStatusColor(
            transactionType,
          )}`}
        >
          {transactionType}
        </span>
      ),
    },
    {
      label: 'Status',
      value: (
        <span
          className={`uppercase w-fit rounded-full px-2 py-1 h-5 font-inter flex items-center justify-center  text-xs ${
            getStatusTextAndStyles(transactionDetailsData?.transaction_status)
              ?.solid
          }`}
        >
          {
            getStatusTextAndStyles(transactionDetailsData?.transaction_status)
              ?.text
          }
        </span>
      ),
    },
  ];

  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[579px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <h3 className="font-medium text-base text-grey">
            Transaction Details
          </h3>

          <button
            onClick={() => {
              onClose(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>
        <div className="mt-6 bg-white80 rounded-xl flex items-center p-6 gap-4">
          <div className="rounded-full bg-white border border-[#ECECEC] w-[68px] h-[68px] flex items-center justify-center">
            <p className="text-deepBlue text-md font-medium">
              {getInitials(transactionDetailsData?.wallet_name) || '--'}
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <h1 className="text-deepBlue font-semibold text-lg">
                {transactionDetailsData?.wallet_name || '-'}
              </h1>
            </div>
            <div className="flex items-center gap-1">
              {/*<div className="flex items-center px-[10px] gap-1 mr-1 py-[6px] bg-white rounded-full text-deepBlue font-medium text-tiny">*/}
              {/*  <img src={baasMail} alt="mail" />*/}
              {/*  <p className="mt-[3px]">*/}
              {/*    {transactionDetailsData?.sender_account_email || '-'}*/}
              {/*  </p>*/}
              {/*</div>*/}

              <div className="flex items-center px-[10px] py-[6px] bg-white rounded-full text-deepBlue font-medium text-tiny">
                <p>{transactionDetailsData?.wallet_phone || '-'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <p className="text-base font-medium text-[#727272] text-left">
            Account Details
          </p>
          <div className="mt-2 p-6 rounded-xl bg-white80">
            {receiverDetails.map(({label, value}, index) => (
              <div key={label}>
                <div className="flex items-center justify-between relative">
                  <p className="text-tiny text-[#868C98]">{label}</p>
                  {isLoading ? (
                    <SkeletonLoader
                      style={{
                        width: 150 * Math.random(index + 1),
                        height: 20,
                        position: 'absolute',
                        top: 0,
                        right: 0,
                      }}
                    />
                  ) : (
                    <p className="text-tiny text-black font-medium">{value}</p>
                  )}
                </div>
                {index !== receiverDetails.length - 1 && (
                  <hr className="my-4 text-[#E1E4EA]" />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="mt-6">
          <p className="text-base font-medium text-[#727272] text-left">
            Transaction Details
          </p>
          <div className="mt-2 p-6 rounded-xl bg-white80">
            {transactionDetails.map(({label, value}, index) => (
              <div key={label}>
                <div className="flex items-center justify-between relative">
                  <p className="text-tiny text-[#868C98]">{label}</p>
                  {isLoading ? (
                    <SkeletonLoader
                      style={{
                        width: 150 * Math.random(index + 1),
                        height: 20,
                        position: 'absolute',
                        top: 0,
                        right: 0,
                      }}
                    />
                  ) : (
                    <p
                      className={`text-tiny text-black font-medium ${
                        label === 'Session ID' &&
                        'flex items-center gap-2 cursor-pointer hover:text-brandBlue'
                      }`}
                      onClick={() => label === 'Session ID' && copyText(value)}
                    >
                      {value} {label === 'Session ID' && <CopyIcon />}
                    </p>
                  )}
                </div>
                {index !== transactionDetails.length - 1 && (
                  <hr className="my-4 text-[#E1E4EA]" />
                )}
              </div>
            ))}
          </div>
        </div>{' '}
        {transactionDetailsData?.transaction_status === 'Failed' && (
          <div className="mt-6">
            <p className="text-base font-medium text-[#727272] text-left">
              Reason for failed verification
            </p>
            <div className="mt-2 flex items-center  gap-3 p-6 rounded-xl bg-white80">
              <img src={failed_t} width={25} alt="failed verification" />
              <p className="text-body text-base">
                {transactionDetailsData?.transaction_reason}
              </p>
            </div>
          </div>
        )}
      </div>{' '}
    </Modal>
  );
}

export default BaasTransactionModal;
