import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {chevronRight} from '../../assets/images/images';
import {DashboardLayout} from '../../components';
import {Constants} from '../../helpers';
import {SubDomainSettingsLayout} from '../../components/SubDomainSideBars';

function SettingsLayout({
  children,
  pageTitle,
  action,
  pageIcon,
  isSettingsPage = true,
}) {
  return (
    <DashboardLayout
      xlLeftMargin="xl:ml-12"
      xlRightPadding="xl:pr-[76px]"
      overFlow="overflow-none"
      breadCrumbs={
        <div className="flex items-center">
          <img src={pageIcon} alt="" width={18} height={18} />
          <Link
            className="ml-2 -mb-1"
            to={Constants.PAGES_URL.SETTINGS_PROFILE}
          >
            {isSettingsPage ? 'Settings' : ''}
          </Link>
          {isSettingsPage && (
            <img src={chevronRight} alt="" width={18} height={18} />
          )}
          <p className="-mb-1 cursor-pointer">{pageTitle}</p>
        </div>
      }
    >
      <div className="flex items-center justify-between gap-6 mt-6 mb-6 md:gap-0 sm:mt-0">
        <h2 className="hidden text-lg font-medium text-grey sm:block">
          {pageTitle}
        </h2>
        <h2 className="block text-lg font-medium text-grey sm:hidden">
          Settings
        </h2>
        {action}
      </div>

      <SubDomainSettingsLayout />

      {children}
    </DashboardLayout>
  );
}

export default connect(state => state, null)(SettingsLayout);
