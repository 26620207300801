/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import {Combobox, Transition} from '@headlessui/react';
import countryList from 'react-select-country-list';
import {checkedBox, search, uncheckedBox} from '../../assets/images/images';
import {connect} from 'react-redux';

function CountryCombobox({onChange, countryData}) {
  const options = useMemo(() => countryList().getData(), []);
  const [selected, setSelected] = useState(options);
  const [query, setQuery] = useState('');
  const [all, setAll] = useState(true);
  const [temp, setAData] = useState(false);

  console.log(all, '>>>');

  useEffect(() => {
    if (all) {
      setSelected(options);
      options && onChange(options);
    } else {
      onChange([]);
      setSelected([]);
    }
  }, [all]);

  useEffect(() => {
    const widgetCountries = countryData;
    setAData(true);
    if (widgetCountries === undefined || widgetCountries?.length === 0) {
      setAll(true);
      setSelected(options);
      onChange(options);
    } else if (typeof widgetCountries === 'string') {
      const userCountries = options?.find(
        option => option.label === widgetCountries,
      );
      setSelected([userCountries]);
      setAData(true);
      setAll(false);
    } else if (Array.isArray(widgetCountries) && widgetCountries.length) {
      const userCountries =
        widgetCountries &&
        widgetCountries?.map(country =>
          options?.find(option => option.value === country),
        );
      setSelected(userCountries);
      setAData(true);
      setAll(false);
    } else {
      setSelected(options);
      onChange(options);
      setAData(true);
      setAll(false);
    }
  }, [temp]);

  const filteredCountry =
    query === ''
      ? options
      : options.filter(country =>
          country.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')),
        );

  function getSelectedCountry() {
    if (selected?.length === 1) {
      return selected[0]?.label;
    }
    if (selected?.length === 2) {
      return selected.map(s => s.label).join(', ');
    }
    if (selected?.length > 2) {
      return `${selected?.length} countries selected`;
    }
    return '';
  }

  return (
    <div className="relative w-full ">
      <Combobox
        value={selected}
        onChange={data => {
          setSelected(data);
          !data?.includes('all') && onChange(data);
        }}
        multiple
      >
        <div className="relative">
          <Combobox.Input
            className="h-fit text-sm py-4"
            // displayValue={() => getSelectedCountry()}
            onChange={event => setQuery(event.target.value)}
            placeholder={getSelectedCountry()}
          />
          <Combobox.Button className="absolute h-4 w-4 top-1/2 -translate-y-1/2 right-4 flex items-center">
            <img
              src={search}
              alt=""
              width={16}
              height={16}
              title="Toggle dropdown"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="absolute mt-2 z-10 max-h-64 w-full overflow-auto rounded bg-white80 border border-grey60 p-2 text-sm">
            {filteredCountry.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              <>
                <Combobox.Option
                  className={({active}) =>
                    `flex items-center gap-2 relative cursor-pointer select-none p-2 py-4${
                      active ? 'text-body' : 'text-grey'
                    } ${all ? 'bg-brandBlue text-white' : 'text-grey'}`
                  }
                  value={'all'}
                  onClick={() => setAll(!all)}
                  style={{marginBottom: 25, borderBottom: '1px solid #eaeaea'}}
                >
                  {({selected}) => (
                    <>
                      <img
                        src={all ? checkedBox : uncheckedBox}
                        alt=""
                        width={16}
                        height={16}
                      />
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        Select all countries
                      </span>
                    </>
                  )}
                </Combobox.Option>
                {filteredCountry.map(country => (
                  <Combobox.Option
                    key={country.value}
                    className={({active, selected}) =>
                      `flex items-center gap-2 relative cursor-pointer select-none p-2 ${
                        active ? 'text-body' : 'text-grey'
                      } ${selected ? 'bg-brandBlue text-white' : 'text-grey'}`
                    }
                    value={country}
                  >
                    {({selected}) => (
                      <>
                        <img
                          src={selected ? checkedBox : uncheckedBox}
                          alt=""
                          width={16}
                          height={16}
                        />
                        <span
                          className={`block truncate pt-1 ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          <span className="mr-2">
                            {getUnicodeFlagIcon(country.value)}
                          </span>
                          {country.label}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </>
            )}
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
}

export default connect(state => state, null)(CountryCombobox);
