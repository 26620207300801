import React, {useState} from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {useMutation, useQueryClient} from 'react-query';
import {
  Modal,
  PrimaryButton,
  DropdownIndicator,
  customStyles,
} from '../../components';
import * as Actions from '../../store/actions';
import {createApiToken} from '../../requests/queries/tokens';

function CreateTokenModal({open, setOpen, userApps}) {
  const queryClient = useQueryClient();

  const [selectedApp, setSelectedApp] = useState(null);
  const [tokenName, setTokenName] = useState('');

  const {isLoading, mutate} = useMutation(data => createApiToken(data));

  const handleSubmit = e => {
    e.preventDefault();

    if (!tokenName || !selectedApp) {
      toast.error('Please fill all fields');
      return;
    } else {
      mutate(
        {
          name: tokenName,
          app: selectedApp.value,
        },
        {
          onSuccess: () => {
            toast.success('Token created successfully');
            setOpen(false);
            setTokenName('');
            setSelectedApp(null);
            queryClient.invalidateQueries(['api-tokens']);
          },
          onError: error => {
            toast.error(error.response.data.error);
          },
        },
      );
    }
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Create token"
    >
      <form onSubmit={handleSubmit} className="mt-6 text-left">
        <label htmlFor="token_name" className="mt-6">
          <span className="text-sm">Token name</span>
          <input
            type="text"
            name="token_name"
            id="token_name"
            className="mt-2"
            onChange={e => setTokenName(e.target.value)}
          />
        </label>

        <label htmlFor="app" className="relative mt-6 mb-2">
          Select app
        </label>
        <Select
          defaultValue={selectedApp}
          value={selectedApp}
          onChange={setSelectedApp}
          options={userApps}
          placeholder="Select app"
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="auto"
        />

        <div className="flex items-center mt-6">
          <PrimaryButton
            buttonText="Create Token"
            className="w-full"
            type="submit"
            loading={isLoading}
            aria-disabled={isLoading}
          />
        </div>
      </form>
    </Modal>
  );
}

export default connect(state => state, Actions)(CreateTokenModal);
