import React, {useState} from 'react';
import {toast} from 'react-toastify';

import useDragAndDrop from '../hooks/useDragAndDrop';

const DragAndDrop = ({handleFileUpload, text, name, accept, mt, disabled, error, fileName}) => {
  const [file, setFile] = useState(null);
  const {dragOver, setDragOver, onDragOver, onDragLeave} = useDragAndDrop();

  const onDrop = e => {
    e.preventDefault();
    setDragOver(false);
    if(disabled) return;
    const selectedFile = e.dataTransfer.files[0];
    if (
      !['image/png', 'image/jpeg', 'application/pdf'].includes(
        selectedFile.type,
      )
    )
      return toast.error('Unsupported file type');
    setFile(selectedFile.name);
    handleFileUpload(e, name, selectedFile.name);
  };

  const handleChange = e => {
    const selectedFile = e.target.files[0];
    if (
      !['image/png', 'image/jpeg', 'application/pdf'].includes(
        selectedFile.type,
      )
    )
      return toast.error('Unsupported file type');
    setFile(selectedFile.name);
    handleFileUpload(e, name, selectedFile.name);
  };

  return (
    <div
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      style={{
        border: `${dragOver ? '3px dashed #33333322' : error ? '1px dashed #F3787A' : '1px dashed #3F7CDB'}`,
      }}
      className={`mt-${
        mt || 6
      } py-[22px] flex flex-col items-center gap-[2px] text-center border border-brandBlue border-dashed rounded bg-white80 w-full`}
    >
      <div className="flex-col items-center hidden sm:flex">
        {/* {!file && <img src={uploadCsv} alt="" width={48} height={48} />} */}
        <p className="text-body text-sm">
          {dragOver ? (
            'Please drop file here'
          ) : (
            <span style={{color: file ? '#00000032' : ''}}>
              Drag and drop {text || 'a file'} here
            </span>
          )}
        </p>
      </div>
      {(file || fileName) ? (
        <div className="text-body text-sm flex my-2 gap-[3px] items-baseline">
          <input
            type="file"
            id={name}
            onChange={handleChange}
            accept={accept}
            name={name}
            disabled={disabled}
          />
          <label htmlFor={name}>Change file</label>
        </div>
      ) : (
        <div className="text-body text-sm flex gap-[3px] items-baseline">
          <span className="hidden sm:block">or</span>
          <input
            type="file"
            id={name}
            onChange={handleChange}
            accept={accept}
            name={name}
            disabled={disabled}
          />
          <label htmlFor={name}>click here to select</label>
          from your device
        </div>
      )}
      <p className="text-body text-sm flex gap-[3px] items-baseline">
        {(file || fileName) ? (
          <>
            File: <span className="font-bold text-black ">{file || fileName}</span>
          </>
        ) : (
          ''
        )}
      </p>
    </div>
  );
};

export default DragAndDrop;
