import React, {useMemo, useState} from 'react';
import {Loader, Modal} from '../../../../components';
import {
  activeCheck,
  closeModal,
  emptyState,
  search,
} from '../../../../assets/images/images';
import {useQuery} from 'react-query';
import {fetchLinkableAccounts} from '../../../../requests/queries/reconciliations';
import {GoBack} from '../components/GoBack';
import {getCookieValue} from '../../../../helpers/getCookie';

function AutomaticallyAddBankModal({
  show,
  onClose,
  openAutomaticallyAddAccountModal,
  setSelectedBank,
  goBack,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeBank, setActiveBank] = useState(null);
  const env = getCookieValue('env');

  const {data: linkableAccounts, isLoading} = useQuery(
    'linkable-accounts',
    fetchLinkableAccounts,
  );

  const linkableBanks = useMemo(
    () =>
      (!isLoading &&
        Object.entries((linkableAccounts && linkableAccounts[0]) || []).map(
          ([key, value]) => ({
            name: key,
            logo: value?.meta?.icon,
            enable: value?.enable,
            fields: {...value.fields},
            currency: value?.meta?.currency,
          }),
        )) ||
      [],
    [isLoading, linkableAccounts],
  );

  const banks = useMemo(
    () =>
      searchTerm !== ''
        ? linkableBanks.filter(d => d.name.includes(searchTerm))
        : linkableBanks,
    [linkableBanks, searchTerm],
  );

  const handleBankClick = bank => {
    setActiveBank(bank);
    openAutomaticallyAddAccountModal();
    setSelectedBank(bank);
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center mb-5 justify-between w-full">
          <GoBack
            goBack={() => {
              goBack();
              onClose(false);
            }}
          />

          <button
            onClick={() => {
              onClose(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>

        <h3 className="font-medium text-left mt-6 text-grey">
          Select your bank
        </h3>

        {isLoading ? (
          <Loader height={35} />
        ) : !isLoading && banks?.length === 0 ? (
          <div className="h-[200px] flex flex-col gap-5 pt-5 items-center justify-center">
            <img src={emptyState} alt="not found" width={60} height={60} />
            <p className="text-body text-sm">
              Ops! sorry, no linkable bank was found.
            </p>
            <p
              onClick={() => {
                goBack();
                onClose(false);
              }}
              className="text-brandBlue border py-2 px-8 rounded border-[#8D8D91]  text-tiny cursor-pointer"
            >
              Go Back
            </p>
          </div>
        ) : (
          <form className="mt-6 relative">
            <div>
              <input
                name="search"
                className="mt-2 relative placeholder:text-grey-200 !px-4 !py-2 bg-[#F6F6F8] outline-none placeholder:font-normal"
                id="bank_name"
                placeholder="Search"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              <img
                src={search}
                alt="search"
                className="absolute right-6 top-6 w-4 h-4"
              />
            </div>

            <div className="mt-6 flex flex-col gap-4">
              {!isLoading &&
                banks &&
                banks?.map((bank, index) => (
                  <div
                    key={index}
                    className={`flex items-center justify-between w-full h-12  pl-2 pr-2  rounded-md ${
                      activeBank?.name === bank?.name
                        ? 'bg-[#EBF1FC] rounded-md'
                        : ''
                    } transition-all ${
                      bank?.enable && env !== 'Sandbox'
                        ? 'hover:bg-[#EBF1FC] cursor-pointer '
                        : 'bg-grey4 cursor-not-allowed opacity-60'
                    }`}
                    {...(bank?.enable && env !== 'Sandbox'
                      ? {onClick: () => handleBankClick(bank)}
                      : {title: 'Not available'})}
                  >
                    <div className="flex items-center gap-5">
                      <img
                        src={bank.logo}
                        alt="logo"
                        className="w-10 h-10 rounded "
                        {...((!bank.enable || env === 'Sandbox') && {
                          style: {filter: 'grayscale(100%)', opacity: 0.6},
                        })}
                      />
                      <p className="capitalize text-black text-base">
                        {bank.name}
                      </p>
                    </div>
                    {activeBank?.name === bank?.name && (
                      <img src={activeCheck} alt="active" />
                    )}
                  </div>
                ))}
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
}

export default AutomaticallyAddBankModal;
