export const capitalizeEveryWord = text => {
  if (typeof text === 'string') {
    if (text.length === 3) {
      return text.toUpperCase();
    } else {
      return text.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
        letter.toUpperCase(),
      );
    }
  }
};
