import React, {useCallback, useState} from 'react';
import {Switch} from '@headlessui/react';
import * as Actions from '../store/actions';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {setEnvCookie} from '../helpers/setCookie';

function SettingsToggle({disabled, onCheck, isChecked, title}) {
  const [enabled, setEnabled] = useState(false);

  const handleChange = useCallback(
    data => {
      onCheck ? onCheck(data) : setEnabled(data);
    },
    [onCheck],
  );

  const checked = isChecked ? isChecked : enabled;

  return (
    <Switch
      title={title}
      checked={checked}
      onChange={handleChange}
      className={`${
        checked
          ? `${
              !disabled
                ? 'bg-brandBlue border-brandBlue'
                : 'bg-brandBlue20 border-brandBlue20'
            }`
          : 'bg-white border-grey30'
      } items-center h-[11.67px] w-[18.33px] relative inline-flex shrink-0 cursor-pointer  rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">Toggle service on/off</span>
      <span
        aria-hidden="true"
        className={`${
          checked ? 'translate-x-2 bg-white' : 'translate-x-0.5 bg-grey30'
        }
          pointer-events-none inline-block h-[5px] w-[5px] absolute transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
}
function AMLToggle({disabled, onCheck, isChecked, title}) {
  const [enabled, setEnabled] = useState(false);

  const handleChange = useCallback(
    data => {
      onCheck ? onCheck(data) : setEnabled(data);
    },
    [onCheck],
  );

  const checked = isChecked ? isChecked : enabled;

  return (
    <Switch
      title={title}
      checked={checked}
      onChange={handleChange}
      className={`${
        checked
          ? `${
              !disabled
                ? 'bg-brandBlue border-brandBlue'
                : 'bg-brandBlue border-brandBlue'
            }`
          : 'bg-white border-grey30'
      } items-center h-[14px] w-[24px] relative inline-flex shrink-0 cursor-pointer  rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">Toggle Ongoing monitoring on/off</span>
      <span
        aria-hidden="true"
        className={`${
          checked ? 'translate-x-3 bg-white' : 'translate-x-0.5 bg-grey30'
        }
          pointer-events-none inline-block h-[7px] w-[7px] absolute transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
}

function VerificationToggle({
  disabled,
  onCheck,
  isChecked,
  blurredOut = false,
}) {
  const [enabled, setEnabled] = useState(false);

  const handleChange = useCallback(
    data => {
      onCheck ? onCheck(data) : setEnabled(data);
    },
    [onCheck],
  );

  const checked = isChecked ? isChecked : enabled;

  return (
    <div className="flex items-center justify-center px-[1px] py-[5px]">
      <Switch
        checked={checked}
        onChange={handleChange}
        className={`${
          checked
            ? `${
                !disabled
                  ? 'bg-brandBlue50 border-brandBlue50'
                  : 'bg-brandBlue20 border-brandBlue20'
              }`
            : !blurredOut
            ? 'bg-white border-grey30'
            : 'border-grey60'
        } items-center h-[14px] w-[22px] relative inline-flex shrink-0 cursor-pointer  rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Toggle on/off</span>
        <span
          aria-hidden="true"
          className={`${
            checked
              ? 'translate-x-2.5 bg-white'
              : !blurredOut
              ? 'translate-x-[2.5px] bg-grey30'
              : 'translate-x-[2.5px] bg-grey60'
          }
          pointer-events-none inline-block h-[5px] w-[5px] absolute transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </div>
  );
}

function HeaderToggle({setLive, apps: {live}, auth: {userDetails}}) {
  const isVerified = userDetails && userDetails?.company?.verified;

  const handleSetLive = value => {
    if (isVerified) {
      setLive(value);
      setEnvCookie(value);
    } else {
      toast.error('You must be verified to change the environment');
    }
  };

  return (
    <Switch
      checked={live}
      onChange={handleSetLive}
      className={`${
        live ? 'bg-success80 border-success80' : 'bg-white border-grey30'
      } items-center h-[11.67px] w-[18.33px] relative inline-flex shrink-0 cursor-pointer  rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">Toggle environment between Sandbox/Live</span>
      <span
        className={`${
          live ? 'translate-x-2 bg-white' : 'translate-x-0.5 bg-grey30'
        }  pointer-events-none inline-block h-[5px] w-[5px] absolute top-[1.1px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
}

const ToggleHeader = connect(state => state, Actions)(HeaderToggle);

export {SettingsToggle, ToggleHeader, VerificationToggle, AMLToggle};
