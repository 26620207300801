import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import moment from 'moment';

import {PrimaryButton, SecondaryButton, SkeletonLoader} from '../../components';
import {matched, placeholder, unmatched} from '../../assets/images/images';
import {IGNORE_KEYS} from './LayoutConstants';
import {getInitials, getStatusColor} from '../../helpers';
import {
  addComment,
  deleteComment,
  fetchComments,
} from '../../requests/queries/customer360';

const ProfileAndCommentSection = ({userDetails, userDetailsLoading}) => {
  const [comment, setComment] = useState('');
  const [next, setNext] = useState(3);
  const [comment_id, setCommentId] = useState('');
  const queryClient = useQueryClient();
  const {slug} = useParams();

  const {data: comments, isLoading: commentsLoading} = useQuery(
    ['comments'],
    () => fetchComments(slug),
    {retry: false},
  );

  const USER_PROFILE = useMemo(
    () => !userDetailsLoading && userDetails && userDetails,
    [userDetails, userDetailsLoading],
  );

  const {isLoading: addCommentLoading, mutate: addUserComment} = useMutation(
    ['add-comments', slug],
    data => addComment(slug, data),
    {
      onSuccess: () => {
        toast.success('Comment added successfully');
        queryClient.invalidateQueries('comments');
      },
    },
  );

  const {
    dob_check,
    image_check,
    gender_check,
    last_name_check,
    first_name_check,
    middle_name_check,
  } = USER_PROFILE?.integrity_check || {};

  const {isLoading: deleteCommentLoading, mutate: deleteUserComment} =
    useMutation(
      'delete-comments',
      commentId => deleteComment(slug, commentId),
      {
        onSuccess: () => {
          toast.success('Comment deleted successfully');
          queryClient.invalidateQueries('comments');
        },
      },
    );

  const handleAddComment = useCallback(() => {
    if (!comment) {
      return toast.error('Please enter a comment');
    }
    if (comment.length) {
      addUserComment({comment});
      setComment('');
    }
  }, [comment, addUserComment]);
  const handleLoadMore = () => {
    setNext(next + 3);
  };

  const getStatus = status =>
    status === 'matched'
      ? {
          text: 'Valid',
          styles: getStatusColor('verified'),
        }
      : {
          text: 'Invalid',
          styles: getStatusColor('not verified'),
        };

  const renderCheckIcon = (key, check) => {
    if (
      (key === 'first_name' && check) ||
      (key === 'last_name' && check) ||
      (key === 'middle_name' && check) ||
      (key === 'date_of_birth' && check) ||
      (key === 'gender' && check)
    ) {
      return (
        <span className="text-sm text-body text-right ml-1 -mt-[2px]">
          <img src={matched} width={16} height={16} alt="image" />
        </span>
      );
    } else if (
      (key === 'first_name' && !check) ||
      (key === 'last_name' && !check) ||
      (key === 'middle_name' && !check) ||
      (key === 'date_of_birth' && !check) ||
      (key === 'gender' && check)
    ) {
      return (
        <span className="text-sm text-body text-right ml-1 -mt-[2px]">
          <img src={unmatched} width={16} height={16} alt="image" />
        </span>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="w-[35%] p-6 bg-white rounded-xl flex flex-col gap-4 border border-grey-50">
      <div className="p-6 flex flex-col items-center gap-2 bg-white80 rounded">
        {userDetailsLoading ? (
          <SkeletonLoader
            style={{
              height: 85,
              width: 85,
              borderRadius: '50%',
            }}
          />
        ) : (
          <div className="relative">
            <img
              src={
                !userDetailsLoading &&
                (USER_PROFILE?.image || USER_PROFILE?.photo)
                  ? (!USER_PROFILE?.image.startsWith('https') &&
                      `${`data:image/png;base64,${
                        USER_PROFILE?.image || USER_PROFILE?.photo
                      }`}`) ||
                    USER_PROFILE?.image ||
                    USER_PROFILE?.photo
                  : placeholder
              }
              alt="Photo"
              width={89}
              height={89}
              className="rounded-full h-[100px] w-[100px] object-cover"
            />

            <img
              src={image_check ? matched : unmatched}
              width={16}
              height={16}
              className="absolute bottom-0 right-0"
              alt="image"
            />
          </div>
        )}
        {userDetailsLoading ? (
          [1, 2, 3].map(d => (
            <div
              key={d}
              className="py-[14px] flex items-center justify-between border-b border-grey-100"
            >
              <SkeletonLoader />
            </div>
          ))
        ) : (
          <div className="rounded w-full">
            {Object.entries(USER_PROFILE || {})?.map(
              ([key, value], index) =>
                !IGNORE_KEYS.includes(key) &&
                value && (
                  <div
                    key={index}
                    className="py-[14px] flex items-center justify-between border-b border-grey-100"
                  >
                    <p className="text-xs text-grey font-medium uppercase text-left">
                      {key.replaceAll('_', ' ') || '-'}
                    </p>

                    {key === 'country' ? (
                      <div>
                        {JSON.parse(value)?.map((country, i) => (
                          <span
                            key={country}
                            className={`text-sm text-body  text-right ${
                              i === JSON.parse(value)?.length - 1 ? '' : 'mr-1'
                            }`}
                          >
                            {country || '-'}
                            {i === JSON.parse(value)?.length - 1 ? '' : ','}
                          </span>
                        ))}
                      </div>
                    ) : key === 'status' ? (
                      <div
                        className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit ${
                          getStatus(value)?.styles
                        }`}
                      >
                        {getStatus(value)?.text}
                      </div>
                    ) : (
                      <p className="text-sm text-body w-100 text-right inline-flex">
                        {value || '-'}

                        {Object.entries(USER_PROFILE.integrity_check)
                          .map(
                            () =>
                              key !== 'image' && (
                                <Fragment key={key}>
                                  {renderCheckIcon(
                                    key,
                                    first_name_check ||
                                      last_name_check ||
                                      middle_name_check ||
                                      dob_check,
                                    gender_check,
                                  )}
                                </Fragment>
                              ),
                          )
                          .splice(0, 1)}
                      </p>
                    )}
                  </div>
                ),
            )}
          </div>
        )}
      </div>

      <div className="bg-white80 rounded p-4 flex items-start gap-6 justify-between">
        <div className="flex flex-col gap-2">
          <p className="text-xs font-medium uppercase text-grey-500">
            date/time created
          </p>
          <p className="font-medium text-grey text-small">
            {userDetailsLoading
              ? '-'
              : moment(USER_PROFILE?.createdAt).format('Do MMMM YYYY, h:mmA')}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-xs font-medium uppercase text-grey-500">
            date/time updated
          </p>
          <p className="font-medium text-grey text-small">
            {userDetailsLoading
              ? '-'
              : moment(USER_PROFILE?.updatedAt).format('Do MMMM YYYY, h:mmA')}
          </p>
        </div>
      </div>

      <div className="p-4 rounded bg-white80 grow xl:grow-0">
        <div className="flex items-center gap-1">
          <p className="text-xs font-medium uppercase text-grey">Comments</p>
          <div className="flex items-center justify-center w-4 h-4 pt-[2px] text-xs text-white rounded-full bg-brandBlue">
            {comments && comments?.comments?.length}
          </div>
        </div>
        <div className="relative mt-4">
          <textarea
            onChange={e => setComment(e.target.value)}
            value={comment}
            name="comment"
            id="comment"
            data-smallertextarea={''}
          />
          <PrimaryButton
            buttonText={addCommentLoading ? 'sending...' : 'Send'}
            fontSize="text-xs"
            xPadding="px-2"
            yPadding="pt-[9px] pb-[7px]"
            className="absolute right-2 bottom-4"
            onClick={handleAddComment}
            disabled={!comment || addCommentLoading}
          />
        </div>

        <div
          style={{
            opacity: addCommentLoading || commentsLoading ? 0.4 : 1,
          }}
        >
          {(!addCommentLoading || !commentsLoading) &&
            comments &&
            comments?.comments?.slice(0, next)?.map((comment, index, data) => (
              <div
                key={comment?._id}
                className={`flex flex-col gap-1 mt-3 pb-2 ${
                  index === data.length - 1 ? '' : 'border-b border-b-[#e9e9e9]'
                }`}
                onMouseEnter={() => setCommentId(comment?._id)}
                onMouseLeave={() => setCommentId('')}
              >
                <div className="flex items-center gap-2">
                  <div className="w-6 h-6 bg-brandBlue40 text-brandBlue flex items-center justify-center rounded-full text-xs font-medium">
                    {getInitials(
                      `${comment?.author.first_name} ${comment?.author.last_name}`,
                    ) || '-'}
                  </div>
                  <p className="font-medium text-sm text-grey">
                    {`${comment?.author.first_name || '-'}
                          ${comment?.author.last_name || ''}`}
                  </p>
                </div>
                <p className="text-sm  text-body">{comment?.comment || '-'}</p>
                <div className="relative">
                  <p className="text-[9px] text-grey40 font-medium uppercase">
                    {moment(comment.createdAt).fromNow() || ''}
                  </p>
                  {comment_id === comment._id && (
                    <SecondaryButton
                      buttonText={
                        comment_id === comment._id && deleteCommentLoading
                          ? 'deleting...'
                          : 'delete'
                      }
                      fontSize="text-[9px]"
                      btnHeight="h-7"
                      xPadding="px-2"
                      yPadding="pt-[4px] pb-[5px]"
                      className="absolute right-2 bottom-2 text-danger"
                      onClick={() => {
                        setCommentId(comment?._id);
                        deleteUserComment(comment?._id);
                      }}
                      disabled={
                        comment_id === comment._id && deleteCommentLoading
                      }
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
        {!commentsLoading && next < comments?.comments?.length && (
          <SecondaryButton
            className="flex items-start gap-2 mt-4"
            fontSize="text-xs"
            xPadding="px-2"
            yPadding="pt-[9px] pb-[7px]"
            btnHeight="h-7"
            buttonText="Load more"
            onClick={handleLoadMore}
          />
        )}
      </div>

      {/*<div className="p-4 rounded bg-white80 grow lg:grow-0 text-start">*/}
      {/*  <p className="text-xs font-medium uppercase text-grey">Activity log</p>*/}
      {/*  <div className="mt-4">*/}
      {/*    <div className="grid">*/}
      {/*      {TIMELINE?.slice(0, next)?.map((time, index) => (*/}
      {/*        <div*/}
      {/*          key={index}*/}
      {/*          className="activity-parent grid gap-[6px] grid-rows-[2rem] h-full py-[4px]"*/}
      {/*        >*/}
      {/*          <span className="relative grid items-center text-xs font-medium text-body activity-time after:content-[''] after:absolute after:border-r after:border-dashed after:border-grey60 after:h-[40px] after:right-[-3px]">*/}
      {/*            {time.datetime.split(' ').pop()?.replace('+00:00', '')}*/}
      {/*          </span>*/}
      {/*          <span className="text-sm lowercase  veri text-body activity-description leading-[12px]  relative grid items-center pl-2 before:content-[''] before:absolute before:bg-brandBlue before:rounded-full before:w-[5px] before:h-[5px] before:top-1/2 before:left-[-3px] before:-mt-[1px]">*/}
      {/*            {time?.event_value ?? '-'}*/}
      {/*          </span>*/}
      {/*        </div>*/}
      {/*      ))}*/}
      {/*    </div>*/}

      {/*    {next < TIMELINE?.length && (*/}
      {/*      <SecondaryButton*/}
      {/*        className="flex items-start gap-2 mt-4"*/}
      {/*        fontSize="text-xs"*/}
      {/*        xPadding="px-2"*/}
      {/*        yPadding="pt-[9px] pb-[7px]"*/}
      {/*        buttonText="Load more"*/}
      {/*        btnHeight="h-7"*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default React.memo(ProfileAndCommentSection);
