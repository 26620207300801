export const SANDBOX_DUMMY = {
  birthDate: '11-11-1972',
  birthLga: 'Ikeja',
  birthState: 'Lagos',
  educationalLevel: 'Higher',
  employmentStatus: '25 ikeja',
  firstName: 'John',
  gender: 'Male',
  height: '5 9',
  lastName: 'Doe',
  lga: 'Ikeja',
  maritalStatus: 'Married',
  middleName: 'Doe',
  msisdn: '09011111111',
  vnin: '111111111111111',
  nspokenLang: 'English',
  ospokenlang: 'Yoruba',
  profession: 'Tailor',
  religion: 'Muslim',
  residenceAddressLine1: '25 Agege motor ways',
  residenceLga: 'Ikeja',
  residenceState: 'Lagos',
  residenceStatus: 'Occupant',
  residenceTown: 'Oshogbo',
  selfOriginLga: 'Lagos Island',
  selfOriginPlace: 'Yaba',
  selfOriginState: 'Muslim',
  state: 'Lagos',
  status: 200,
  title: 'Mr',
};
