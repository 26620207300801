import {
  GET_CASE_LISTS,
  GET_CASE_LIST,
  LISTS_LOADING,
  MUTATE_LIST_LOADING,
} from '../types';

const initialState = {
  lists: null,
  list: null,
  listLoading: false,
  mutateLoading: false,
};

export default function caseList(state = initialState, action) {
  switch (action.type) {
    case LISTS_LOADING:
      return {...state, listLoading: action.payload};
    case MUTATE_LIST_LOADING:
      return {...state, mutateLoading: action.payload};

    case GET_CASE_LISTS:
      return {...state, lists: action.payload};
    case GET_CASE_LIST:
      return {...state, list: action.payload};

    default:
      return state;
  }
}
