import {combineReducers} from 'redux';

import auth from './auth';
import apps from './apps';
import lookup from './lookup';
import apiLogs from './apiLogs';
import team from './team';
import data from './data';
import aml from './aml';
import widgets from './widgets';
import verifications from './verifications';
import webhook from './webhook';
import flows from './flows';
import caseLists from './caseLists';
import creditCheck from './creditCheck';
import subdomain from './subdomain';

export default combineReducers({
  aml,
  auth,
  apps,
  data,
  lookup,
  apiLogs,
  team,
  widgets,
  verifications,
  webhook,
  flows,
  caseLists,
  creditCheck,
  subdomain,
});
